import {
    animate,
    AnimationTriggerMetadata,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const CharmModalAnimation: AnimationTriggerMetadata[] = [
    trigger('CharmModal', [
        transition('void => *', [
            style({
                top: '-100%',
            }),
            animate('300ms linear'),
        ]),
    ]),
];
