<mp-actionbar *ngIf="!isExpressApproval()">
    <prince-actionbar-checkout
        [selectedPlan]="getPlanCheckoutTitle()"
    ></prince-actionbar-checkout>
</mp-actionbar>
<mp-actionbar *ngIf="isExpressApproval()">
    <mp-actionbar-back-title
        [title]="'modules.main.pages.payment.product_types.express_approval' | translate"
    ></mp-actionbar-back-title>
</mp-actionbar>
<div
    class="payment-checkout"
    *ngIf="userOriginResolved"
>
    <div class="payment-checkout__header">
        <div class="payment-checkout__header__selected-plan">
            {{ getSelectedPlan() }}
        </div>
        <div class="payment-checkout__header__price">
            {{ getSpotPrice(price$ | async) }}
        </div>
        <div
            *ngIf="canBePaidWithInstallments()"
            class="payment-checkout__header__conditions"
        >
            {{ getInstallmentsInterestWarning() | translate }}
        </div>
    </div>
    <ng-container *ngIf="canShowBoletoPayment$() | async">
        <div class="payment-checkout__payment-method-selector">
            <button
                class="payment-checkout__payment-method-selector__button"
                [ngClass]="getActiveMethodButtonModifierClass(PAYMENT_CARD)"
                (click)="changePaymentType(PAYMENT_CARD)"
            >
                {{ "modules.main.pages.payment.methods.card" | translate }}
            </button>
            <button
                class="payment-checkout__payment-method-selector__button"
                [ngClass]="getActiveMethodButtonModifierClass(PAYMENT_BOLETO)"
                (click)="changePaymentType(PAYMENT_BOLETO)"
            >
            {{ "modules.main.pages.payment.methods.boleto" | translate }}
            </button>
        </div>
    </ng-container>
    <div
        class="payment-container"
        *ngIf="(price$ | async) as price"
    >
        <mp-card-selector
            [time]="period"
            [price]="price"
            [type]="product.identifier"
            *ngIf="isCardPayment()"
        ></mp-card-selector>
        <mp-boleto-selector
            [time]="period"
            [price]="price"
            [type]="product.identifier"
            *ngIf="!isCardPayment()"
        ></mp-boleto-selector>
        <div class="payment-checkout__methods">
            <prince-payment-methods></prince-payment-methods>
        </div>
        <span class="payment-checkout__divider"></span>
        <div class="payment-checkout__additional-information">
            <lib-payment-security-warnings
                [billDescription]="billDescription"
            ></lib-payment-security-warnings>
        </div>
    </div>
    <div class="payment-checkout__contract-details">
        <div
            class="payment-checkout__contract-details__satisfaction-guaranteed"
            *ngIf="!isExpressApproval()"
        >
            <p>
                <strong>
                    {{ 'modules.main.pages.payment.blurb8title' | translate }}
                </strong>
                <br>
                {{ 'modules.main.pages.payment.blurb8' | translate }}
            </p>
        </div>
        <div
            class="payment-checkout__contract-details__card-recurrence-warning"
            *ngIf="!isExpressApproval()"
        >
            <p [innerHTML]="'modules.main.pages.payment.blurb1' | translate"></p>
            <p>
                {{ 'modules.main.pages.payment.blurb2' | translate }}
            </p>
        </div>
        <div
            class="payment-checkout__contract-details__boleto-details"
            *ngIf="!isExpressApproval()"
        >
            <p [innerHTML]="'modules.main.pages.payment.blurb4' | translate"></p>
        </div>
    </div>
</div>
