import { MembershipType } from '@libs/shared/membership/membership.common';
import { arrayFind } from '@libs/utils/array-functions';

export interface IPaymentOption {
    membership_type_id: MembershipType;
    price: number;
    period: number;
    units: 'months' | 'days';
    discount?: number;
    totalByMonth?: number;
    uuid?: string;
    preferred_plan?: boolean;
    prize_uuid?: string;
}

export class PaymentOption {
    public static calculateDiscount(
        paymentOptions: IPaymentOption[],
        value: number,
        period: number,
        membershipTypeId: MembershipType,
    ): number {
        const plansByMembership = paymentOptions.filter((plan: IPaymentOption): boolean =>
            plan.membership_type_id === membershipTypeId,
        );
        const cheapestValue = Math.min(...plansByMembership.map((plan): number => plan.price));
        const cheapestPlan = arrayFind(
            plansByMembership,
            (plan): boolean => plan.price === cheapestValue,
        );

        return 1 - (value / (period * cheapestPlan.period) / cheapestValue);
    }
}
