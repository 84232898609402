import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { Injectable, Injector, OnDestroy, Type, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';

import {
    SlidableMenuServiceCommon,
} from '@libs/modules/main/services/slidable-menu/slidable-menu.service.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { closeSlidableMenu, openSlidableMenu } from '@libs/store/slidable-menu/action';

import { ButtonPhotoVerifyComponent } from '@prince/modules/main/shared/button-photo-verify/button-photo-verify.component';
import { SlidableRef } from '@prince/modules/main/shared/slidable-menu/slidable-ref';
import { SubscriptionButtonComponent } from '@prince/modules/main/shared/subscription-button/subscription-button.component';
import { AuthenticationService } from '@prince/services/authentication.service';
import { InjectComponentService } from '@prince/services/inject-component.service';

@Injectable({
    providedIn: 'root',
})
export class SlidableMenuService extends SlidableMenuServiceCommon implements OnDestroy {
    protected overlayRef: OverlayRef;
    protected slidableRef: SlidableRef;
    protected user$: Observable<IProfile> = of();
    protected user: UserCommon;
    protected subscriptions: Subscription[] = [];

    constructor(
        protected overlay: Overlay,
        protected injector: Injector,
        protected platform: Platform,
        protected auth: AuthenticationService,
        protected injectComponentService: InjectComponentService,
        protected store: Store<IApplicationState>,
    ) {
        super(auth);
        this.user$ = this.store.select('user');
        this.subscriptions.push(
            this.user$.subscribe({
                next: (user: UserCommon): void => {
                    this.user = user;
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(
            (subscription: Subscription): void => subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    public openSlidableMenu(): void {
        this.store.dispatch(openSlidableMenu());
    }

    public closeSlidableMenu(): void {
        this.store.dispatch(closeSlidableMenu());
    }

    public injectSubscribedStatus(viewContainerReference: ViewContainerRef): void {
        this.injectComponentService.inject(
            SubscriptionButtonComponent,
            viewContainerReference,
            undefined,
        );
    }

    public injectUserButtons(viewContainerReference: ViewContainerRef): void {
        const userButtons: Type<ButtonPhotoVerifyComponent>[] = [
            ButtonPhotoVerifyComponent,
        ];

        if (this.hasPhotoVerified()) {
            return;
        }

        this.injectComponentService.injectMultiple(
            userButtons,
            viewContainerReference,
        );
    }

    protected hasPhotoVerified(): boolean {
        return UserCommon.isPhotoVerified(this.user);
    }
}
