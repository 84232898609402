export class CouponCommon {
    public static readonly COUPON_INVALID: string = 'invalid.coupon.code';
    public static readonly COUPON_INVALID_STR: string = 'shared.coupon.coupon_invalid';
    public static readonly CANT_REDEEM_COUPON: string = 'coupon.cannot.be.redeemed';
    public static readonly CANT_REDEEM_COUPON_STR: string = 'shared.coupon.cant_redeem_coupon';
    public static readonly ALREADY_REDEEMED: string = 'coupon.already.redeemed';
    public static readonly ALREADY_REDEEMED_STR: string = 'shared.coupon.already_redeemed';
    public static readonly COUPON_ISNT_ACTIVE: string = 'coupon.isnt.active';
    public static readonly COUPON_ISNT_ACTIVE_STR: string = 'shared.coupon.isnt_active';
    public static readonly COUPON_IS_EXPIRED: string = 'coupon.is.expired';
    public static readonly COUPON_IS_EXPIRED_STR: string = 'shared.coupon_is_expired';
    public static readonly COUPON_REDEEM_DATE_IS_VALID: string = 'coupon.cannot.be.redeemed.at.this.time';
    public static readonly COUPON_REDEEM_DATE_IS_VALID_STR: string = 'shared.coupon.cannot_be_redeemed_at_this_time';
    public static readonly COUPON_MAX_TIMES_REDEEMED_EXCEED: string = 'coupon.redeem.times.exceeded';
    public static readonly COUPON_MAX_TIMES_REDEEMED_EXCEED_STR: string = 'shared.coupon.redeem_times_exceeded';

    public static getTranslateResponse(responseCode: string): string {
        if (CouponCommon.COUPON_INVALID === responseCode) {
            return CouponCommon.COUPON_INVALID_STR;
        }

        if (CouponCommon.CANT_REDEEM_COUPON === responseCode) {
            return CouponCommon.CANT_REDEEM_COUPON_STR;
        }

        if (CouponCommon.ALREADY_REDEEMED === responseCode) {
            return CouponCommon.ALREADY_REDEEMED_STR;
        }

        if (CouponCommon.COUPON_ISNT_ACTIVE === responseCode) {
            return CouponCommon.COUPON_ISNT_ACTIVE_STR;
        }

        if (CouponCommon.COUPON_IS_EXPIRED === responseCode) {
            return CouponCommon.COUPON_IS_EXPIRED_STR;
        }

        if (CouponCommon.COUPON_REDEEM_DATE_IS_VALID === responseCode) {
            return CouponCommon.COUPON_REDEEM_DATE_IS_VALID_STR;
        }

        if (CouponCommon.COUPON_MAX_TIMES_REDEEMED_EXCEED === responseCode) {
            return CouponCommon.COUPON_MAX_TIMES_REDEEMED_EXCEED_STR;
        }

        return 'common.request_error';
    }
}
