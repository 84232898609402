import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    PaymentServiceCommon,
} from '@libs/modules/main/services/payment/payment.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { MembershipType } from '@libs/shared/membership/membership.common';

@Directive()
export abstract class AllCashServiceCommon extends PaymentServiceCommon {
    public static readonly SUCCESSFUL_PARAM_VALUE: string = 'yes';

    public static isValidParamValue(
        value: string,
    ): boolean {
        return value === this.SUCCESSFUL_PARAM_VALUE;
    }

    public getCardBrand(cardNumber: string): string {
        const cardType = super.getCardBrand(cardNumber);

        const moipToAllCash = {
            MASTERCARD: 'MASTER',
            VISA: 'VISA',
            AMEX: 'AMEX',
            DINERS: 'DINERS',
            HIPERCARD: 'HIPERCARD',
            ELO: 'ELO',
        };

        if (cardType === '' || moipToAllCash[cardType] === undefined) {
            return '';
        }

        return moipToAllCash[cardType];
    }

    public generateBoleto(
        membershipId: MembershipType,
        price: number,
        cpf: string,
        interest: number,
    ): Observable<IAuthResponse> {
        let endpoint: string = `${this.getConfig().serverIp}payment/boleto`;
        endpoint += `?membership_id=${membershipId}&price=${price}&cpf=${cpf}&interest=${interest}`;

        return this.authHttp.get(endpoint).pipe(
            catchError(this.throwErrors),
        );
    }

    protected getHeaders(params: HttpParams): HttpHeaders {
        return new HttpHeaders()
            .append('Content-Length', params.toString().length.toString())
            .append('Content-Type', 'application/x-www-form-urlencoded');
    }

    protected getAuthenticationHeaders(params: HttpParams): HttpHeaders {
        return this.getHeaders(params).append('Authorization', 'Bearer ' + this.getConfig().payment.token);
    }
}
