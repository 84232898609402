import { Directive, Input } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import {
    PaymentInfoServiceCommon,
} from '@libs/modules/main/services/payment/payment-info.service.common';
import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import {
    EXPRESS_APPROVAL_ID,
} from '@libs/modules/main/services/payment/payment.service.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { Interest } from '@libs/shared/money/interest/interest';
import { Money } from '@libs/shared/money/money';
import { Trial } from '@libs/shared/trial/trial';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoSelectors } from '@libs/store/payment-info';

@Directive()
export abstract class PaymentCardComponentCommon {
    static readonly ONE_TIME: number = 1;
    static readonly ONE_MONTH_PERIOD: number = 1;

    @Input() price: number;
    @Input() time: number;
    @Input() type: number;

    constructor(
        protected translate: TranslateService,
        protected userService: UserServiceCommon,
        protected store: Store<IApplicationState>,
        protected paymentInfoService: PaymentInfoServiceCommon,
        protected router: Router,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        //
    }

    abstract getHolder(): string;

    abstract getCardNumber(): string;

    abstract getCvv(): string;

    abstract getCPF(): string;

    abstract getDate(): string;

    abstract getInstallments(): number;

    getCardBrand(cardNumber: string): string {
        return this.paymentHelpersService.getCardBrand(cardNumber);
    }

    isElitePayment(): boolean {
        return MembershipCommon.isEliteType(this.type);
    }

    isExpressApproval(): boolean {
        return this.type === EXPRESS_APPROVAL_ID;
    }

    getPriceDescription(): string {
        return 'R$ ' + this.price.toFixed(2);
    }

    isValidCardInput(): boolean {
        return this.paymentHelpersService.isValidCardInput(
            this.getCardNumber(),
        );
    }

    isValidCardNumber(): boolean {
        return this.paymentHelpersService.isValidCardNumber(
            this.getCardNumber(),
        );
    }

    isValidDateInput(): boolean {
        return this.paymentHelpersService.isValidCardInput(
            this.getDate(),
        );
    }

    isValidCVV(): boolean {
        return this.paymentHelpersService.isValidCVV(
            this.getCvv(),
            this.getCardNumber(),
        );
    }

    isValidHolder(): boolean {
        return this.paymentHelpersService.isValidHolder(
            this.getHolder(),
        );
    }

    isValidCPFInput(): boolean {
        return this.paymentHelpersService.isValidCPFInput(
            this.getCPF(),
        );
    }

    isValidCPFField(): boolean {
        return this.paymentHelpersService.isValidCPFField(
            this.getCPF(),
        );
    }

    isProcessingPayment$(): Observable<boolean> {
        return this.store.pipe(
            select(PaymentInfoSelectors.selectIsPaymentProcessing),
        );
    }

    getPlanDescription(): any {
        let productType: string = 'express_approval';

        if (!this.isExpressApproval()) {
            productType = MembershipCommon.getMachineMembership(this.type) + '_member';
        }

        const plan = this.translate.instant(
            `modules.main.pages.payment.product_types.${productType}`,
        );

        if (!this.time) {
            return plan;
        }

        return this.translate.instant('modules.main.pages.payment.plan_description', {
            plan,
            duration: this.getPlanDuration(),
        });
    }

    getPlanName(): string {
        let productType: string = 'express_approval';

        if (!this.isExpressApproval()) {
            productType = MembershipCommon.getMachineMembership(this.type) + '_member';
        }

        return this.translate.instant(
            `modules.main.pages.payment.product_types.${productType}`,
        );
    }

    getPlanDuration(): string {
        if (Trial.isAtTrialLocation(this.router.url)) {
            return '';
        }

        return this.translate.instant(
            'modules.main.pages.payment.plan_duration.months',
            { period: this.time },
        );
    }

    canShowDuration(): boolean {
        return this.time > Trial.TRIAL_TIME;
    }

    getPlanPeriod(): number {
        return this.time;
    }

    isInstallmentsAllowed(): boolean {
        if (
            this.time === PaymentCardComponentCommon.ONE_TIME ||
            this.isExpressApproval() ||
            PaymentCommon.isTrialPayment(this.type, this.time)
        ) {
            return false;
        }

        return true;
    }

    getNumberOfInstallments(): number {
        if (!this.isInstallmentsAllowed()) {
            return PaymentCardComponentCommon.ONE_MONTH_PERIOD;
        }

        return this.time;
    }

    getTotalAmountWithInterest(installment: number): Money {
        const interest: number = Interest.InstalmentToInterest[installment];

        if (installment === PaymentCardComponentCommon.ONE_TIME) {
            return new Money(
                this.price,
            );
        }

        return new Interest(
            new Money(
                this.price,
            ),
            interest,
        ).getTotal();
    }

    isProcessing(isProcessing: boolean): boolean {
        return isProcessing;
    }

    abstract goBack(): void;
}
