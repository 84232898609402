import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IProfilesState, profilesEntityAdapter } from './state';

const selectProfilesV2Feature = createFeatureSelector<IProfilesState>('profiles');

const {
    selectEntities,
    selectIds,
    selectAll,
} = profilesEntityAdapter.getSelectors(selectProfilesV2Feature);

export const selectProfilesEntities = selectEntities;
export const selectProfilesIds = selectIds as (state: object) => number[];
export const selectAllProfiles = selectAll;

export const selectProfileById = (profileId: number) => createSelector(
    selectProfilesEntities,
    (entities) => entities[profileId],
);

export const selectProfilesForSwipe = createSelector(
    selectProfilesV2Feature,
    profilesEntityAdapter.getSelectors().selectAll,
);
