import { Injectable } from '@angular/core';

import { AddressService, IAddress } from '@prince/services/address/address.service';
import {
    IBillingAddress,
    ICardInfo,
    IFrameAddressOptions,
    IFrameInputFields,
    IFrameValidations,
    IValidations,
    IValidationsFields,
} from '@prince/services/allcash-card.component.interfaces';

@Injectable({
    providedIn: 'root',
})
export class AllCashIframeService {
    readonly POSTCODE_FIELD: string = 'postcode';
    readonly INPUT_ERROR_CLASS: string = 'wpwl-has-error';
    readonly ERROR: string = 'error';

    public input: HTMLInputElement;
    public className: string;
    public inputElementList: NodeListOf<HTMLInputElement>;

    protected cardInfo: ICardInfo = {
        expiry: '',
        cardHolder: '',
        taxNumber: '',
    };

    iframeAddressOptions: IFrameAddressOptions = {
        billingAddress: {
            postcode: '',
            country: '',
            state: '',
            city: '',
            street1: '',
            street2: '',
        },
        mandatoryBillingFields: {
            country: true,
            state: true,
            city: true,
            postcode: true,
            street1: true,
            street2: false,
        },
    };

    public iframeInputFieldsValidations: IFrameValidations = {
        billing: {
            expiry: {
                label: 'expiry',
                validation: /\d{2}\s*\/\s*\d{2}/,
                filterOff: /[^\d\s\/]+/,
                validationRequired: false,
            },
            cardHolder: {
                label: 'cardHolder',
                validation: /^([a-z'-à-ü\s]){3,}\s*$/i,
                filterOff: /[^a-z\s'-à-ü]+/i,
                validationRequired: false,
            },
            taxNumber: {
                label: 'taxNumber',
                validation: /^\d{11}$/,
                filterOff: /[^\d]+/,
                validationRequired: true,
            },
        },
        address: {
            state: {
                label: 'state',
                validation: /^[a-zà-ü\s]{2,45}$/i,
                filterOff: /[^a-zà-ü\s]+/i,
                validationRequired: true,
            },
            city: {
                label: 'city',
                validation: /^[a-zà-ü\s]{2,45}$/i,
                filterOff: /[^a-zà-ü\s]+/i,
                validationRequired: true,
            },
            postcode: {
                label: 'postcode',
                validation: /^\d{8}$/,
                filterOff: /[^\d]+/,
                validationRequired: false,
            },
            street1: {
                label: 'street1',
                validation: /^([^0-9][a-zà-ü]+\s?){2,}/i,
                filterOff: /[^a-z0-9à-ü\s]+/i,
                validationRequired: true,
            },
        },
    };

    protected iframeInputFields: IFrameInputFields = {
        cardInfo: this.cardInfo,
        billingAddress: this.iframeAddressOptions.billingAddress,
    };

    constructor(
        protected addressService: AddressService,
    ) {
        //
    }

    initService(
        input: HTMLInputElement,
        className: string,
        inputElementList: NodeListOf<HTMLInputElement>,
    ): void {
        this.input = input;
        this.className = className;
        this.inputElementList = inputElementList;
    }

    getIframeInputElementList(): NodeListOf<HTMLInputElement> {
        return document.querySelector('iframe')
            .contentDocument
            .querySelectorAll('input:not([type="hidden"])');
    }

    getIframeFormElement(): HTMLFormElement {
        return document.querySelector('iframe')
            .contentDocument
            .querySelector('form');
    }

    keepFieldsValuesBetweenIframeInstances(): void {
        this.input.value = this.getInputFieldInItsObject(this.className);

        this.input.addEventListener('change', (): void => {
            this.setInputFieldInItsObject(this.className, this.input.value);
        });
    }

    validateFields(
        fieldsValidation: IValidationsFields,
        className: string,
    ): void {
        const validation = (event: KeyboardEvent): void => {
            event.preventDefault();

            const inputElement: HTMLInputElement = <HTMLInputElement> event.target;

            if (this.hasClassName(inputElement, this.INPUT_ERROR_CLASS)) {
                return;
            }

            if (
                fieldsValidation[className] === null ||
                fieldsValidation[className] === undefined
            ) {
                return;
            }

            inputElement.value = inputElement.value.replace(
                fieldsValidation[className].filterOff,
                '',
            );

            if (fieldsValidation[className].validationRequired
                && fieldsValidation[className].validation.test(inputElement.value)
            ) {
                this.removeErrorClass(inputElement);
            }

            if (this.hasClassName(inputElement, this.ERROR)) {
                return;
            }

            if (fieldsValidation[className].validationRequired
                && !fieldsValidation[className].validation.test(inputElement.value)
            ) {
                this.addErrorClass(inputElement);
            }
        };

        this.input.addEventListener('keyup', validation);
        this.input.addEventListener('focusout', validation);
    }

    fillAddressFieldsViaPostCode(
        className: string,
    ): void {
        if (className !== this.POSTCODE_FIELD) {
            return;
        }

        this.input.addEventListener('keyup', (event: KeyboardEvent): void => {
            event.preventDefault();
            const inputElement: HTMLInputElement = <HTMLInputElement> event.target;

            if (!this.iframeInputFieldsValidations.address.postcode.validation.test(inputElement.value)) {
                return;
            }

            this.addressService.consultCEP(inputElement.value).subscribe((address: IAddress): void => {
                this.iframeInputWalk(
                    this.inputElementList,
                    (input: HTMLInputElement, inputClassName: string): void => {
                        const addressValidation: IValidations = this.iframeInputFieldsValidations.address[inputClassName];

                        if (
                            addressValidation !== null &&
                            addressValidation !== undefined &&
                            this.hasClassName(input, inputClassName)
                        ) {
                            const label: string = addressValidation.label;

                            input.value = address[label];
                        }
                    },
                );
            });
        });
    }

    iframeInputWalk(
        inputElementList: NodeListOf<HTMLInputElement>,
        callback: (
            input: HTMLInputElement,
            className: string,
        ) => void,
    ): void {
        inputElementList.forEach(
            (
                input: HTMLInputElement,
            ): void => {
                this.getPpayIframeInputClassNames().forEach((className: string): void => {
                    if (!this.hasClassName(input, className)) {
                        return;
                    }

                    callback(input, className);
                });
            },
        );
    }

    iframeFormEventListenerCallback(formElement: HTMLFormElement, callback: () => void): void {
        formElement.addEventListener('submit', (): void => {
            callback();
        });
    }

    getPpayIframeInputClassNames(): string[] {
        let inputObjectsClasses: string[] = [];

        this.IframeInputObjectWalk((inputGroupObj: ICardInfo | IBillingAddress): void => {
            inputObjectsClasses = inputObjectsClasses.concat(
                Object.keys(inputGroupObj));
        });

        return inputObjectsClasses;
    }

    setInputFieldInItsObject(key: string, value: string): void {
        this.IframeInputObjectWalk((inputGroupObj: ICardInfo | IBillingAddress): void => {
            if (inputGroupObj.hasOwnProperty(key)) {
                inputGroupObj[key] = value;
            }
        });
    }

    getInputFieldInItsObject(key: string): string {
        let inputField: string;

        this.IframeInputObjectWalk((inputGroupObj: ICardInfo | IBillingAddress): void => {
            if (inputGroupObj.hasOwnProperty(key)) {
                inputField = inputGroupObj[key];
            }
        });

        return inputField;
    }

    IframeInputObjectWalk(
        callback: (
            inputGroupObj: ICardInfo | IBillingAddress,
        ) => void,
    ): void {
        Object.keys(this.iframeInputFields).forEach((key: string): void => {
            callback(this.iframeInputFields[key]);
        });
    }

    addErrorClass(input: HTMLInputElement): void {
        input.classList.add(this.INPUT_ERROR_CLASS);
    }

    removeErrorClass(input: HTMLInputElement): void {
        input.classList.remove(this.INPUT_ERROR_CLASS);
    }

    hasClassName(input: HTMLInputElement, className: string): boolean {
        const classListString: string = input.classList.value;
        const matching: string = className.replace(/\W*/g, '');

        const classNameMatchRegex = new RegExp(matching, 'ig');

        return !!classListString.match(classNameMatchRegex);
    }
}
