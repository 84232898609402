<mp-allcash-card
    [time]="time"
    [price]="price"
    [type]="type"
    *ngIf="isAllCash$() | async"
></mp-allcash-card>
<mp-moip-card
    [time]="time"
    [price]="price"
    [type]="type"
    *ngIf="isPagSeguro$() | async"
></mp-moip-card>
