import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';

export abstract class BlockServiceCommon {
    readonly ENDPOINT_BLOCK: string = 'profile/block/';

    constructor(
        protected store: Store<IApplicationState>,
        protected authHttp: AuthHttpServiceCommon,
    ) {
        //
    }

    public abstract blockUser(profileId: number): Observable<IAuthResponse>;

    public abstract unblockUser(profileId: number): Observable<IAuthResponse>;
}
