import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ForgotPasswordControlName, LoginFormControlNames } from '@prince/services/login-form-manager/login-form-control-names';

@Injectable({
    providedIn: 'root',
})
export class LoginFormManagerService {
    protected loginForm: FormGroup = new FormGroup({ });
    protected forgotPasswordForm: FormGroup = new FormGroup({ });

    constructor(
        protected formBuilder: FormBuilder,
    ) {
        //
    }

    public setupLoginForm(): void {
        this.loginForm = this.formBuilder.group({
            [LoginFormControlNames.EMAIL]: ['', Validators.required],
            [LoginFormControlNames.PASSWORD]: ['', Validators.required],
        });
    }

    public setupForgotPasswordForm(): void {
        this.forgotPasswordForm = this.formBuilder.group({
            [ForgotPasswordControlName.EMAIL]: ['', Validators.required],
        });
    }

    public getLoginForm(): FormGroup {
        return this.loginForm;
    }

    public getForgotPasswordForm(): FormGroup {
        return this.forgotPasswordForm;
    }
}
