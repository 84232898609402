import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IPaymentOptionBrandData } from '@libs/modules/main/pages/payment-method-option/payment-option-brand-data';
import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';

import { Config } from '@prince/config';

@Component({
    selector: 'prince-payment-methods',
    templateUrl: 'payment-methods.html',
    styleUrls: ['payment-methods.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodsComponent {
    public getPaymentMethods(): IPaymentOptionBrandData[] {
        const paymentMethods: string[] = [
            ...Config.paymentBrands,
            PaymentMethods.PAYMENT_BOLETO,
        ];

        return paymentMethods.map(
            (method: string): IPaymentOptionBrandData => {
                return {
                    name: method.toLowerCase(),
                    format: '.gif',
                };
            },
        );
    }

    public getMethodIcon(brand: IPaymentOptionBrandData): string {
        return `${ Config.imagesFolderBaseUrl }${ brand.name }${ brand.format }`;
    }
}
