import { Action, ActionReducerMap } from '@ngrx/store';

import { IModalViewProps } from '@libs/components/modal-view/interface/modal-view-props';
import { ILists, INITIAL_DM_STATE } from '@libs/modules/main/services/download-manager.service.common';
import { INewRegistrationPayload } from '@libs/modules/registration/domain/interfaces/new-registration-payload.interface';
import { INewRegistrationPayload as IPrinceRegistrationPayload } from '@libs/registration-prince/domain/interfaces/new-registration-payload.interface';
import { ICity } from '@libs/services/location/city/city';
import { ICountry } from '@libs/services/location/country/country';
import { IRegion } from '@libs/services/location/region/region';
import { IBlogPost } from '@libs/shared/interfaces/blog-post.interface';
import { ILoginTokenData } from '@libs/shared/interfaces/login-token-data.interface';
import { IImageRegistrationPayload } from '@libs/shared/profile/image-registration';
import { IRegistrationPayload } from '@libs/shared/profile/registration';
import { IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';
import { photoUploaded } from '@libs/store/authentication/reducers/photo-uploaded.reducer';
import { stats } from '@libs/store/authentication/reducers/stats.reducer';
import { INITIAL_TOKEN_STATUS, token } from '@libs/store/authentication/reducers/token.reducer';
import { user } from '@libs/store/authentication/reducers/user.reducer';
import { blog } from '@libs/store/blog/reducers/blog-posts.reducer';
import {
    boostInitialState,
    BoostReducer,
} from '@libs/store/boost';
import {
    boostLastViewserInitialState,
    IBoostLastViewersState,
    BoostLastViewersReducer,
} from '@libs/store/boost-last-viewers';
import { boostPackagesInitialState, BoostPackagesReducer, IBoostPackagesState } from '@libs/store/boost-packages';
import {
    boostToastInitialState,
    BoostToastReducer,
    IBoostToastState,
} from '@libs/store/boost-toast';
import { IBoostState } from '@libs/store/boost/interfaces/boost-state.interface';
import {
    boostedProfilesInitialState, BoostedProfilesReducer, IBoostedProfilesState,
} from '@libs/store/boosted-profiles';
import {
    ConversationInitialState,
    ConversationReducer,
    IConversationState,
} from '@libs/store/conversations';
import {
    ConversationWritingInitialState,
    ConversationWritingReducer,
    IConversationWritingState,
} from '@libs/store/conversations-writing';
import {
    deactivateReasonInitialState,
    DeactivateReasonReducer,
    IDeactivateReasonsState,
} from '@libs/store/deactivate-reason';
import { downloadManager } from '@libs/store/download-manager/reducers/download-manager.reducer';
import {
    favoriteToastInitialState,
    FavoriteToastReducer,
    IFavoriteToastState,
} from '@libs/store/favorite-toast';
import { locationStoreCities } from '@libs/store/location/reducers/cities.reducer';
import { locationStoreCountries } from '@libs/store/location/reducers/countries.reducer';
import { locationStoreRegions } from '@libs/store/location/reducers/regions.reducer';
import { LoginReducer } from '@libs/store/login';
import { ILoginState } from '@libs/store/login/interfaces/login-state.interface';
import { loginInitialState } from '@libs/store/login/state';
import {
    IMeltProfilesState,
    meltProfilesInitialState,
    MeltProfilesReducer,
} from '@libs/store/melt-profiles';
import {
    IMeltToastState,
    meltToastInitialState,
    MeltToastReducer,
} from '@libs/store/melt-toast';
import { MembershipReducer } from '@libs/store/membership';
import { IMembershipState, membershipInitialState } from '@libs/store/membership/state';
import {
    IMessageToastState,
    messageToastInitialState,
    MessageToastReducer,
} from '@libs/store/message-toast';
import {
    IMessageState,
    MessageInitialState,
    MessageReducer,
} from '@libs/store/messages';
import {
    modalViewPropsInitialState,
    ModalViewReducer,
} from '@libs/store/modal-view';
import { UiReducer } from '@libs/store/new-ui';
import { INewUiState } from '@libs/store/new-ui/interfaces/new-ui-state.interface';
import { newUiInitialState } from '@libs/store/new-ui/state';
import {
    INITIAL_NOTIFICATION_SETTINGS,
    INotificationSettings,
} from '@libs/store/notifications/actions/notification-setting-changed.action';
import { notificationToken } from '@libs/store/notifications/reducers/notification.reducer';
import { notificationSettings } from '@libs/store/notifications/reducers/settings.reducer';
import { OlarkReducer } from '@libs/store/olark';
import { IOlarkState } from '@libs/store/olark/interfaces/olark-state.interface';
import { olarkInitialState } from '@libs/store/olark/state';
import {
    IPaymentInfoState,
    paymentInfoInitialState,
    PaymentInfoReducer,
} from '@libs/store/payment-info';
import {
    initialPaymentOptions,
    IPaymentOptionState,
    reducer as paymentOptionsReducer,
} from '@libs/store/payment/reducers';
import {
    IPendingMessageState,
    PendingMessageInitialState,
    PendingMessageReducer,
} from '@libs/store/pending-messages';
import { PhotoVerifyReducer } from '@libs/store/photo-verify';
import { IPhotoVerifyState } from '@libs/store/photo-verify/interfaces/photo-verify.interface';
import { photoVerifyInitialState } from '@libs/store/photo-verify/state';
import {
    IProductState,
    productInitialState,
    ProductReducer,
} from '@libs/store/product';
import { ProfileSwipeReducer } from '@libs/store/profile-swipe';
import { IProfileSwipeState } from '@libs/store/profile-swipe/interfaces/profile-swipe-state.interface';
import { profileSwipeInitialState } from '@libs/store/profile-swipe/state';
import { storeProfileBlockedUsers } from '@libs/store/profiles/reducers/blocked.reducer';
import { storeFavMeTTL } from '@libs/store/profiles/reducers/fav-me-ttl.reducer';
import { storeFavMeProfiles } from '@libs/store/profiles/reducers/fav-me.reducer';
import { storeFavMyTTL } from '@libs/store/profiles/reducers/fav-my-ttl.reducer';
import { storeMyFavProfiles } from '@libs/store/profiles/reducers/fav-my.reducer';
import { storeFeaturedTTL } from '@libs/store/profiles/reducers/featured-ttl.reducer';
import { storeFeaturedProfiles } from '@libs/store/profiles/reducers/featured.reducer';
import { storeNearByTTL } from '@libs/store/profiles/reducers/nearby-ttl.reducer';
import { storeNearbyProfiles } from '@libs/store/profiles/reducers/nearby.reducer';
import { storeNewTTL } from '@libs/store/profiles/reducers/new-ttl.reducer';
import { storeNewUserProfiles } from '@libs/store/profiles/reducers/new.reducer';
import { storeProfilesPrivatePhotoIGaveAccess } from '@libs/store/profiles/reducers/private-photo-gave.reducer';
import { storeProfilesPrivatePhotoIHaveAccess } from '@libs/store/profiles/reducers/private-photo-have.reducer';
import { storeSearchProfiles } from '@libs/store/profiles/reducers/search.reducer';
import {
    INITIAL_VERIFY_PHOTO_STATUS,
    IVerifyPhotoTTLStatus,
    storeVerifyPhotoTTL,
} from '@libs/store/profiles/reducers/verify-photo-ttl.reducer';
import { storeViewedTTL } from '@libs/store/profiles/reducers/viewed-ttl.reducer';
import { storeViewedMeProfiles } from '@libs/store/profiles/reducers/viewed.reducer';
import { SearchV2Reducer } from '@libs/store/recommended-list';
import {
    IReferralState,
    referralInitialState,
    ReferralReducer,
} from '@libs/store/referral';
import {
    IReferralStatusState,
    referralStatusInitialState,
    ReferralStatusReducer,
} from '@libs/store/referral-status';
import { RegistrationReducer } from '@libs/store/registration';
import { RegistrationPrinceReducer } from '@libs/store/registration-prince';
import { registrationPrinceInitialState } from '@libs/store/registration-prince/state';
import { storeImageOnRegistration } from '@libs/store/registration/reducers/frictionless-image.reducer';
import { registration } from '@libs/store/registration/reducers/registration.reducer';
import { registrationInitialState } from '@libs/store/registration/state';
import { ISearchState, searchStateInitialState, SearchStateReducer } from '@libs/store/search';
import {
    TrialReducer,
} from '@libs/store/trial';
import { ITrialState } from '@libs/store/trial/interfaces/trial-state.interface';
import { TrialInitialState } from '@libs/store/trial/state';
import { INITIAL_PREMIUM_SETTINGS, IPremiumSettings } from '@libs/store/ui/premium-settings';
import { premiumSettings } from '@libs/store/ui/reducers/premium-settings.reducer';
import { uiState } from '@libs/store/ui/reducers/ui-state.reducer';
import { INITIAL_UI_STATE, IUiState } from '@libs/store/ui/ui-state';
import { UpgradeAccountScreenReducer } from '@libs/store/upgrade-account';
import { IUpgradeAccountState } from '@libs/store/upgrade-account/interfaces/upgrade-account-state.interface';
import { upgradeAccountInitialState } from '@libs/store/upgrade-account/state';
import {
    IViewToastState,
    viewToastInitialState,
    ViewToastReducer,
} from '@libs/store/view-toast';
import { WaitingListReducer } from '@libs/store/waiting-list';
import { IWaitingListState } from '@libs/store/waiting-list/interfaces/waiting-list-state';
import { waitingListInitialState } from '@libs/store/waiting-list/state';

import { ProfilesReducer } from './profiles-v2';
import { IProfilesState, profilesEntitiesInitialState } from './profiles-v2/state';

export interface IApplicationState {
    photoVerify: IPhotoVerifyState;
    login: ILoginState;
    newUiState: INewUiState;
    uiState: IUiState;
    downloadManager: ILists;
    deactivateReason: IDeactivateReasonsState;
    searchState: ISearchState;
    storeVerifyPhotoTTL: IVerifyPhotoTTLStatus;
    storeConversationEntities: IConversationState;
    storeConversationWritingEntities: IConversationWritingState;
    storePendingMessageEntities: IPendingMessageState;
    storeMessageEntities: IMessageState;
    storeProfileBlockedUsers: number[];
    storeFavMeProfiles: number[];
    storeMyFavProfiles: number[];
    storeFeaturedProfiles: number[];
    storeNearbyProfiles: number[];
    storeNewUserProfiles: number[];
    storeProfilesPrivatePhotoIGaveAccess: number[];
    storeProfilesPrivatePhotoIHaveAccess: number[];
    storeViewedMeProfiles: number[];
    storeSearchProfiles: number[];
    storeReferralsEntity: IReferralState;
    storeReferralStatusEntity: IReferralStatusState;
    storeTrials: ITrialState;
    storeImageOnRegistration: IImageRegistrationPayload;
    photoUploaded: number;
    product: IProductState;
    meltProfiles: IMeltProfilesState;
    meltToast: IMeltToastState;
    favoriteToast: IFavoriteToastState;
    viewToast: IViewToastState;
    messageToast: IMessageToastState;
    boost: IBoostState;
    boostToast: IBoostToastState;
    boostedProfiles: IBoostedProfilesState;
    boostLastViewers: IBoostLastViewersState;
    boostPackages: IBoostPackagesState;
    token: ILoginTokenData;
    user: UserCommon;
    registration: IRegistrationPayload;
    registrationNew: INewRegistrationPayload;
    registrationPrince: IPrinceRegistrationPayload;
    profiles: IProfilesState;
    blog: IBlogPost[];
    stats: IStats;
    modalViewProps: IModalViewProps;
    membership: IMembershipState;
    notificationToken: string;
    paymentInfoEntity: IPaymentInfoState;
    notificationSettings: INotificationSettings;
    storeFeaturedTTL: number;
    storeNearByTTL: number;
    storeNewTTL: number;
    storeFavMeTTL: number;
    storeFavMyTTL: number;
    storeViewedTTL: number;
    locationStoreCountries: ICountry[];
    locationStoreRegions: IRegion[];
    locationStoreCities: ICity[];
    paymentOptionsEntity: IPaymentOptionState;
    premiumSettings: IPremiumSettings;
    waitingList: IWaitingListState;
    olark: IOlarkState;
    upgradeAccountScreen: IUpgradeAccountState;
    profileSwipe: IProfileSwipeState;
    recommendedList: number[];
}

export interface IUnsafeAction extends Action {
    payload?: any;
}

export const reducers: ActionReducerMap<IApplicationState> = {
    photoVerify: PhotoVerifyReducer.reducer,
    login: LoginReducer.reducer,
    newUiState: UiReducer.reducer,
    uiState,
    downloadManager,
    deactivateReason: DeactivateReasonReducer.reducer,
    storeConversationEntities: ConversationReducer.reducer,
    storeConversationWritingEntities: ConversationWritingReducer.reducer,
    storePendingMessageEntities: PendingMessageReducer.reducer,
    storeMessageEntities: MessageReducer.reducer,
    storeProfileBlockedUsers,
    storeFavMeProfiles,
    storeMyFavProfiles,
    storeFeaturedProfiles,
    storeNearbyProfiles,
    storeNewUserProfiles,
    storeProfilesPrivatePhotoIGaveAccess,
    storeProfilesPrivatePhotoIHaveAccess,
    storeViewedMeProfiles,
    searchState: SearchStateReducer.reducer,
    storeReferralsEntity: ReferralReducer.reducer,
    storeReferralStatusEntity: ReferralStatusReducer.reducer,
    storeSearchProfiles,
    storeImageOnRegistration,
    storeTrials: TrialReducer.reducer,
    storeVerifyPhotoTTL,
    photoUploaded,
    profiles: ProfilesReducer.reducer,
    product: ProductReducer.reducer,
    meltProfiles: MeltProfilesReducer.reducer,
    meltToast: MeltToastReducer.reducer,
    favoriteToast: FavoriteToastReducer.reducer,
    viewToast: ViewToastReducer.reducer,
    messageToast: MessageToastReducer.reducer,
    boost: BoostReducer.reducer,
    boostToast: BoostToastReducer.reducer,
    boostLastViewers: BoostLastViewersReducer.reducer,
    boostedProfiles: BoostedProfilesReducer.reducer,
    boostPackages: BoostPackagesReducer.reducer,
    token,
    user,
    modalViewProps: ModalViewReducer.reducer,
    registration,
    registrationNew: RegistrationReducer.reducer,
    registrationPrince: RegistrationPrinceReducer.reducer,
    blog,
    stats,
    notificationToken,
    paymentInfoEntity: PaymentInfoReducer.reducer,
    membership: MembershipReducer.reducer,
    notificationSettings,
    storeFeaturedTTL,
    storeNearByTTL,
    storeNewTTL,
    storeFavMeTTL,
    storeFavMyTTL,
    storeViewedTTL,
    locationStoreCountries,
    locationStoreRegions,
    locationStoreCities,
    paymentOptionsEntity: paymentOptionsReducer,
    premiumSettings,
    waitingList: WaitingListReducer.reducer,
    olark: OlarkReducer.reducer,
    upgradeAccountScreen: UpgradeAccountScreenReducer.reducer,
    profileSwipe: ProfileSwipeReducer.reducer,
    recommendedList: SearchV2Reducer.reducer,
};

export const initialValue: IApplicationState = {
    photoVerify: photoVerifyInitialState,
    login: loginInitialState,
    newUiState: newUiInitialState,
    uiState: INITIAL_UI_STATE,
    downloadManager: INITIAL_DM_STATE,
    deactivateReason: deactivateReasonInitialState,
    storeConversationEntities: ConversationInitialState,
    storeConversationWritingEntities: ConversationWritingInitialState,
    storePendingMessageEntities: PendingMessageInitialState,
    storeMessageEntities: MessageInitialState,
    storeProfileBlockedUsers: [],
    storeFavMeProfiles: [],
    storeMyFavProfiles: [],
    storeFeaturedProfiles: [],
    storeNearbyProfiles: [],
    storeNewUserProfiles: [],
    storeProfilesPrivatePhotoIGaveAccess: [],
    storeProfilesPrivatePhotoIHaveAccess: [],
    storeViewedMeProfiles: [],
    storeReferralsEntity: referralInitialState,
    storeReferralStatusEntity: referralStatusInitialState,
    searchState: searchStateInitialState,
    storeSearchProfiles: [],
    storeImageOnRegistration: { },
    storeVerifyPhotoTTL: INITIAL_VERIFY_PHOTO_STATUS,
    storeTrials: TrialInitialState,
    photoUploaded: 0,
    profiles: profilesEntitiesInitialState,
    meltProfiles: meltProfilesInitialState,
    meltToast: meltToastInitialState,
    favoriteToast: favoriteToastInitialState,
    viewToast: viewToastInitialState,
    messageToast: messageToastInitialState,
    boost: boostInitialState,
    boostToast: boostToastInitialState,
    boostedProfiles: boostedProfilesInitialState,
    boostLastViewers: boostLastViewserInitialState,
    boostPackages: boostPackagesInitialState,
    token: INITIAL_TOKEN_STATUS,
    user: new UserCommon(0),
    modalViewProps: modalViewPropsInitialState,
    registration: { },
    registrationNew: registrationInitialState,
    registrationPrince: registrationPrinceInitialState,
    blog: [],
    stats: { },
    notificationToken: '',
    paymentInfoEntity: paymentInfoInitialState,
    product: productInitialState,
    membership: membershipInitialState,
    notificationSettings: INITIAL_NOTIFICATION_SETTINGS,
    storeFeaturedTTL: 0,
    storeNearByTTL: 0,
    storeNewTTL: 0,
    storeFavMeTTL: 0,
    storeFavMyTTL: 0,
    storeViewedTTL: 0,
    locationStoreCountries: [],
    locationStoreRegions: [],
    locationStoreCities: [],
    paymentOptionsEntity: initialPaymentOptions,
    premiumSettings: INITIAL_PREMIUM_SETTINGS,
    waitingList: waitingListInitialState,
    olark: olarkInitialState,
    upgradeAccountScreen: upgradeAccountInitialState,
    profileSwipe: profileSwipeInitialState,
    recommendedList: [],
};
