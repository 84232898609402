import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
} from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserMenuService } from '@prince/services/user-menu/user-menu.service';

@Component({
    selector: 'mp-user-menu',
    templateUrl: './user-menu.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class UserMenuComponent implements OnDestroy {
    public showItems: boolean = false;
    protected subscription: Subscription;

    constructor(
        protected router: Router,
        protected userMenuService: UserMenuService,
    ) {
        this.router.events.subscribe((event: Event): void => {
            if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                this.userMenuService.checkRoute(event.url);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    showMenuItems(): boolean {
        return !this.userMenuService.responsiveMode();
    }
}
