import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { IGetPaymentHistoryResponse } from '@libs/modules/main/services/payment/interfaces/get-payment-history-response.interface';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentActions } from '@libs/store/payment';
import { IPaymentInfo, PaymentInfoActions, PaymentInfoStatus } from '@libs/store/payment-info';
import { IPaymentProvider } from '@libs/store/payment/interfaces/payment-provider';

export abstract class PaymentInfoServiceCommon {
    public readonly MILLISECONDS_DELAY_TO_CHECK_PAYMENT_HISTORY: number = 3000;

    constructor(
        protected authHttp: AuthHttpServiceCommon,
        protected store: Store<IApplicationState>,
    ) {
        //
    }

    public abstract getPaymentHistory(): Observable<IAuthResponse<IGetPaymentHistoryResponse>>;

    public handlePaymentInfoHistory(): void {
        this.store.dispatch(PaymentInfoActions.fetchPaymentHistory());
    }

    public isPaymentOk(paymentStatus: PaymentStatus): boolean {
        return paymentStatus === PaymentStatus.PAYMENT_OK;
    }

    public isNonElitePaymentOk(paymentStatus: PaymentStatus, user: UserCommon): boolean {
        return !UserCommon.isPendingElite(user) &&
            this.isPaymentOk(paymentStatus);
    }

    public isPaymentAwaiting(paymentStatus: PaymentStatus): boolean {
        return paymentStatus === PaymentStatus.PAYMENT_AWAITING;
    }

    public isPaymentError(paymentStatus: PaymentStatus): boolean {
        return paymentStatus === PaymentStatus.PAYMENT_ERROR;
    }

    public isPaymentProcessing(paymentStatus: PaymentStatus): boolean {
        return paymentStatus === PaymentStatus.PAYMENT_PROCESSING;
    }

    public isPaymentDone(paymentStatus: PaymentStatus): boolean {
        return paymentStatus !== PaymentStatus.PAYMENT_PROCESSING &&
            paymentStatus !== PaymentStatus.PAYMENT_ERROR &&
            paymentStatus !== PaymentStatus.PAYMENT_NONE;
    }

    public isPaymentInfoStatusFailed(status: PaymentInfoStatus): boolean {
        return status === PaymentInfoStatus.StatusFailed;
    }

    public isPaymentInfoStatusApproval(status: PaymentInfoStatus): boolean {
        return status === PaymentInfoStatus.StatusApproval;
    }

    public getLatestPaymentInfo(paymentInfo: IPaymentInfo[]): IPaymentInfo {
        return paymentInfo.reduce(
            (previousInfo: IPaymentInfo, currentInfo: IPaymentInfo): IPaymentInfo => {
                if (previousInfo.fin_sale_id > currentInfo.fin_sale_id) {
                    return previousInfo;
                }

                return currentInfo;
            },
            { },
        );
    }

    public canHandleUpdatedPaymentResponse(
        paymentStatus: PaymentStatus,
        latestStoredPaymentInfo: IPaymentInfo,
        updatedPaymentInfo: IPaymentInfo,
    ): boolean {
        return this.isPaymentProcessing(paymentStatus) && (
            this.itContainsFirstPayment(latestStoredPaymentInfo, updatedPaymentInfo)
        ) || (
            latestStoredPaymentInfo.fin_sale_id < updatedPaymentInfo.fin_sale_id
        );
    }

    public shouldKeepPolling(
        paymentStatus: PaymentStatus,
        latestStoredPaymentInfo: IPaymentInfo,
        updatedPaymentInfo: IPaymentInfo,
    ): boolean {
        return updatedPaymentInfo.payment_type === 'boleto' ||
            Object.keys(updatedPaymentInfo).length === 0 ||
            (
                this.isPaymentProcessing(paymentStatus) &&
                latestStoredPaymentInfo.fin_sale_id === updatedPaymentInfo.fin_sale_id
            );
    }

    protected itContainsFirstPayment(
        latestStoredPaymentInfo: IPaymentInfo,
        updatedPaymentInfo: IPaymentInfo,
    ): boolean {
        return Object.keys(latestStoredPaymentInfo).length === 0 &&
            Object.keys(updatedPaymentInfo).length > 0;
    }

    public abstract loadPaymentProvider(): Observable<IAuthResponse<IPaymentProvider>>;

    public setDefaultPaymentConfiguration() {
        this.setProvider(PaymentCommon.PROVIDER_PAGSEGURO);
        this.setPaymentTypesAvailable([
            PaymentMethods.PAYMENT_CREDIT_CARD,
            PaymentMethods.PAYMENT_BOLETO,
        ]);
    }

    public setProvider(provider: string) {
        this.store.dispatch(
            PaymentActions.setSelectedProvider(provider),
        );
    }

    public setPaymentTypesAvailable(paymentTypes: string[]) {
        this.store.dispatch(
            PaymentActions.setProviderPaymentTypes({
                payment_types: paymentTypes,
            }),
        );
    }
}
