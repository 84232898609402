<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="center">{{ 'common.report' | translate }}</h3>
        </div>
        <div class="modal-body">
            <p>
                {{ 'modules.main.pages.report.intro1' | translate }}
            </p>
            <p>
                {{ 'modules.main.pages.report.intro2' | translate:profile }}
            </p>
            <div class="row">
                <div class="form-group col-xs-12">
                    <textarea
                        class="form-control"
                        required
                        rows="6"
                        placeholder="{{ 'modules.main.pages.report.body' | translate }}"
                        [(ngModel)]="reason"
                    ></textarea>
                </div>
                <div class="form-group col-xs-12 alert">
                    <div
                        class="alert alert-warning alert-dismissible off"
                        role="alert"
                        [ngClass]="{'in': !isOk}"
                        *ngIf="!isOk"
                    >
                        <button
                            class="close"
                            type="button"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {{ 'common.all_fields_required' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-default"
                type="button"
                (click)="close()"
            >
                {{ 'common.close' | translate }}
            </button>
            <button
                class="btn btn-danger"
                type="button"
                (click)="submit()"
            >
                {{ 'common.submit' | translate }}
            </button>
        </div>
    </div>
</div>
