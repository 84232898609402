<button
    id="header_avatar_open-slidable-menu"
    class="actionbar-user-image"
    type="button"
    aria-haspopup="true"
    aria-expanded="true"
    (click)="pictureAction()"
>
    <mp-img-srcset
        url="{{ getProfilePicture() }}"
        imgClass="avatar"
        place="profile-header"
    ></mp-img-srcset>
</button>
