import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import {
    DailyMessageLimitModalComponentCommon,
} from '@libs/modules/shared/modals/daily-message-limit/daily-message-limit-modal.component.common';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';

import { AuthenticationService } from '@prince/services/authentication.service';

interface IModalData {
    isConversation?: boolean;
}

@Component({
    selector: 'mp-daily-message-limit-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './daily-message-limit-modal.html',
})
export class DailyMessageLimitModalComponent extends DailyMessageLimitModalComponentCommon implements IModalComponent<IModalData> {
    constructor(
        protected auth: AuthenticationService,
        protected modalRef: ModalRef,
        @Inject(MODAL_DATA) public data: IModalData,
    ) {
        super(auth);
        this.isConversation = data && data.isConversation;
    }

    public closeModal(): void {
        this.modalRef.close();
    }
}
