import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, of } from 'rxjs';

import {
    PaymentCardComponentCommon,
} from '@libs/modules/main/pages/payment-card/payment-card.component.common';
import {
    PaymentInfoServiceCommon,
} from '@libs/modules/main/services/payment/payment-info.service.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import {
    CardNumberValidatons,
    CPFValidations,
    CVVValidations,
    DateValidations,
    HolderValidations,
    InstallmentValidations,
    getNestableControl,
} from '@libs/services/payment/validators';
import { Interest } from '@libs/shared/money/interest/interest';
import { Money } from '@libs/shared/money/money';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoSelectors } from '@libs/store/payment-info';

@Directive()
export abstract class PagSeguroCardComponentCommon extends PaymentCardComponentCommon implements OnDestroy {
    @Output() public emitInstallments: EventEmitter<number> = new EventEmitter<number>();

    public paymentGroup: FormGroup = this.formBuilder.group({ });

    public validCardNumber: boolean = true;
    public validDate: boolean = true;
    public validCVV: boolean = true;
    public validHolder: boolean = true;
    public validCPF: boolean = true;
    public validInstallment: boolean = true;

    public installmentsWithInterest: Money;

    public previousInstallmentsOption: number = 0;

    readonly HOLDER: string = 'holder';
    readonly CARD_NUMBER: string = 'cardNumber';
    readonly DATE: string = 'date';
    readonly CPF: string = 'cpf';
    readonly CVV: string = 'cvv';
    readonly INSTALLMENTS: string = 'installments';

    readonly INVALID_CVV: string = 'invalidCv';

    readonly PLACEHOLDER_OPTION: number = 0;
    readonly FIRST_INSTALLMENT_OPTION: number = 1;
    readonly ONE_INSTALLMENT: number = 1;

    protected interest: number = 0;
    protected previousCPF: string = '';

    public isPaying: Observable<boolean> = of(false);

    protected subscriptions: Subscription[] = [];

    constructor(
        protected translate: TranslateService,
        protected store: Store<IApplicationState>,
        protected formBuilder: FormBuilder,
        protected userService: UserServiceCommon,
        protected paymentInfoService: PaymentInfoServiceCommon,
        protected router: Router,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        super(
            translate,
            userService,
            store,
            paymentInfoService,
            router,
            paymentHelpersService,
        );

        this.isPaying = this.store.pipe(
            select(PaymentInfoSelectors.selectIsPaying),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public abstract getFirstOption(): number;

    public abstract canSubmit(): boolean;

    public abstract handleDisabledInstallments(index: number): boolean;

    public getErrorMessage(controlName: string): string {
        return this.translate.instant(
            this.getValidationMessage(controlName),
        );
    }

    getValidationMessage(controlName: string): string {
        const errors = getNestableControl(
            controlName,
            this.paymentGroup,
        ).errors;

        const fieldsTovalidate = {
            [this.HOLDER]: HolderValidations.validations.messages,
            [this.CARD_NUMBER]: CardNumberValidatons.validations.messages,
            [this.DATE]: DateValidations.validations.messages,
            [this.CPF]: CPFValidations.validations.messages,
            [this.CVV]: CVVValidations.validations.messages,
            [this.INSTALLMENTS]: InstallmentValidations.validations.messages,
        };

        if (fieldsTovalidate[controlName] !== undefined) {
            return fieldsTovalidate[controlName](errors);
        }

        return '';
    }

    getHolder(): string {
        return this.paymentGroup.get(this.HOLDER).value.toString();
    }

    getCardNumber(): string {
        return this.paymentGroup.get(this.CARD_NUMBER).value.toString();
    }

    getCvv(): string {
        return this.paymentGroup.get(this.CVV).value.toString();
    }

    getCPF(): string {
        return this.paymentGroup.get(this.CPF).value.toString();
    }

    getDate(): string {
        return this.paymentGroup.get(this.DATE).value.toString();
    }

    getInstallments(): number {
        return this.paymentGroup.get(this.INSTALLMENTS).value;
    }

    getInterest(): number {
        return this.interest;
    }

    getTotalAmount(): number {
        return new Interest(
            new Money(this.getSubtotalAmount()),
            this.getInterest(),
        ).getTotal().toNumber();
    }

    getSubtotalAmount(): number {
        return this.price;
    }

    isPaymentProcessing(isProcessing: boolean): boolean {
        return isProcessing;
    }

    handleCardNumber(): void {
        this.formatCardNumber();
        this.checkCardNumber();
    }

    formatCardNumber(): void {
        this.paymentGroup.get(this.CARD_NUMBER).setValue(
            this.paymentHelpersService.formatNumber(
                this.paymentGroup.get(this.CARD_NUMBER).value,
            ),
        );
    }

    checkCardNumber(): void {
        this.validCardNumber = this.isValidCardNumber() ||
            this.paymentGroup.get(this.CARD_NUMBER).pristine;
        this.checkCVV();
    }

    handleDate(): void {
        this.formatDate();
        this.checkDate();
    }

    formatDate(): void {
        this.paymentGroup.get(this.DATE).setValue(
            this.paymentHelpersService.formatDate(this.paymentGroup.get(this.DATE).value),
        );
    }

    checkDate(): void {
        const controlDate: AbstractControl = this.paymentGroup.get(this.DATE);
        this.validDate = controlDate.valid || controlDate.pristine;
    }

    checkCVV(): void {
        const controlCVV: AbstractControl = this.paymentGroup.get(this.CVV);
        this.validCVV = (controlCVV.valid &&
            !this.paymentGroup.hasError(this.INVALID_CVV)) ||
            controlCVV.pristine;
    }

    checkHolder(): void {
        const controlHolder: AbstractControl = this.paymentGroup.get(this.HOLDER);
        this.validHolder = controlHolder.valid || controlHolder.pristine;
    }

    checkInstallment(): void {
        const controlInstallment: AbstractControl = this.paymentGroup.get(this.INSTALLMENTS);
        this.validInstallment = controlInstallment.valid || controlInstallment.pristine;

        if (this.validInstallment) {
            this.handleInterest();
        }
    }

    handleInterest(): void {
        this.interest = this.getInterestFromInstallment(this.paymentGroup.get(this.INSTALLMENTS).value);
        this.installmentsWithInterest = this.getTotalAmountWithInterest(this.interest + 1);
        this.triggerEmitInstallments();
    }

    handleCPF(): void {
        this.formatCPF();
        this.checkCPF();
    }

    formatCPF(): void {
        this.paymentGroup.get(this.CPF).setValue(
            this.paymentHelpersService.formatNumber(
                this.paymentGroup.get(this.CPF).value,
            ),
        );
    }

    checkCPF(): void {
        const cpfControl: AbstractControl = this.paymentGroup.get(this.CPF);

        this.validCPF = cpfControl.valid || cpfControl.pristine;
    }

    checkAllErrors(): void {
        this.checkCPF();
        this.checkCVV();
        this.checkCardNumber();
        this.checkDate();
        this.checkHolder();
        this.checkInstallment();
    }

    saveSelectedInstallmentsOption(): void {
        if (this.getInstallments() === this.PLACEHOLDER_OPTION) {
            this.previousInstallmentsOption = this.ONE_INSTALLMENT;
        }

        if (this.getInstallments() >= this.ONE_INSTALLMENT) {
            this.previousInstallmentsOption = this.getInstallments();
        }
    }

    updateInstallments(value: number): void {
        this.paymentGroup.get(this.INSTALLMENTS).setValue(value);
        this.paymentGroup.get(this.INSTALLMENTS).updateValueAndValidity();
        this.checkInstallment();
    }

    public abstract makePayment(): void;

    getInstallmentsOptions(): string[] {
        const installments: string[] = [];
        const numberOfInstallments: number = this.getNumberOfInstallments();
        installments.push(this.translate.instant(
            'modules.main.pages.payment.installmentOption',
        ));

        for (let i = 1; i <= numberOfInstallments; i++) {
            const totalAmountWithInterest: Money = this.getTotalAmountWithInterest(i);
            const installmentAmount: Money = new Money(totalAmountWithInterest.toNumber() / i);

            installments.push(
                this.installmentSelectLabel(
                    i,
                    installmentAmount.toString(),
                    totalAmountWithInterest.toString(),
                ),
            );
        }

        return installments;
    }

    installmentSelectLabel(installment: number, value: string, totalAmountWithInterest: string): string {
        return this.translate.instant(
            'modules.main.pages.payment.installment',
            {
                installment,
                value: this.paymentHelpersService.formatCurrency(value),
                total: totalAmountWithInterest,
            },
        );
    }

    onlyFirstOptionDisabled(i: number): boolean {
        return i === this.PLACEHOLDER_OPTION;
    }

    getInterestFromInstallment(installment: number): number {
        return Interest.InstalmentToInterest[installment];
    }

    public triggerEmitInstallments(): void {
        this.emitInstallments.emit(this.installmentsWithInterest.toNumber());
    }
}
