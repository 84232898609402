import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IBoostPackagesState } from '@libs/store/boost-packages';

const selectFeature = createFeatureSelector<IBoostPackagesState>('boostPackages');

export const selectProductUuid = createSelector(
    selectFeature,
    (state: IBoostPackagesState): string | null => state.productUuid,
);
