import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SearchServiceCommon } from '@libs/modules/main/services/search/search.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { ISearch, SearchParser } from '@libs/shared/search/search';
import { IApplicationState } from '@libs/store/application-state';
import { RemoveSearchAction, SetCurrentSearchNameAction } from '@libs/store/ui/actions/search.action';

import { Config } from '@prince/config';
import { AuthHttpService } from '@prince/services/auth-http.service';

@Injectable()
export class SearchService extends SearchServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected authHttp: AuthHttpService,
        protected parser: SearchParser,
    ) {
        super(store, authHttp, parser);
    }

    public search(
        queryParameters: any,
        page: number = 1,
    ): Observable<IAuthResponse> {
        const endpoint: string = Config.serverIp + 'search?page=' + page;

        return this.authHttp.post(endpoint, queryParameters);
    }

    public getSavedSearches(
        _queryParameters: any,
        page: number = 1,
    ): Observable<IAuthResponse> {
        const endpoint: string = Config.serverIp + 'search/saved?page=' + page;

        return this.authHttp.get(endpoint);
    }

    public createOrUpdate(searchName: string, searchJSON: ISearch): Observable<IAuthResponse> {
        const endpoint: string = Config.serverIp + 'search/saved';

        return this.authHttp.post(endpoint, {
            criterion_name: searchName,
            criterion: this.parser.encode(searchJSON),
        }).pipe(tap((): void => {
            this.store.dispatch(new SetCurrentSearchNameAction(searchName));
        }));
    }

    public delete(criterionId: number): Observable<IAuthResponse> {
        const endpoint: string = Config.serverIp + 'search/saved/' + criterionId;

        return this.authHttp.delete(endpoint).pipe(tap((): void => {
            this.store.dispatch(new RemoveSearchAction(criterionId));
        }));
    }
}
