import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { ToastCommon } from '@libs/effects/toast/common/toast.common';
import {
    IToastStrategy,
} from '@libs/modules/main/toast/strategy/interfaces/toast-strategy.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { ToastType } from '@libs/shared/toast/interfaces/toast-parameters.interface';
import { ViewToastActions, ViewToastSelectors } from '@libs/store/view-toast';

export class ViewToastStrategy implements IToastStrategy {
    public readonly singleToastRoute: readonly unknown[] = ['main', 'profile'] as const;
    public readonly multipleToastsRoute: readonly unknown[] = ['main', 'favorites', 'viewed-me'] as const;

    public execute(store: Store, profile: IProfile): void {
        store.dispatch(ViewToastActions.upsertProfile({
            profile,
        }));
    }

    public clear(store: Store): void {
        store.dispatch(ViewToastActions.removeAllProfiles());
    }

    public navigate(router: Router, profiles: IProfile[]): void {
        ToastCommon.navigate(
            router,
            [...this.singleToastRoute, profiles[0].profile_id],
            { 'toast-type': ToastType.typeView },
        );
    }

    public navigateMultiple(router: Router): void {
        ToastCommon.navigate(
            router,
            [...this.multipleToastsRoute],
            { 'toast-type': `${ ToastType.typeView }-multiple` },
        );
    }

    public selectAllProfiles$(store: Store): Observable<IProfile[]> {
        return store.pipe(
            select(ViewToastSelectors.selectAll),
            share(),
        );
    }
}
