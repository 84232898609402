import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IOrderResponse } from '@libs/modules/main/services/payment/moip/moip';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

import { Config } from '@prince/config';
import { BoletoWebComponentCommon } from '@prince/modules/main/pages/payment-boleto/boleto.component.common';
import { AllCashService } from '@prince/modules/main/services/payment/allcash/allcash.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ConversationService } from '@prince/services/conversation.service';
import { DownloadManagerService } from '@prince/services/download-manager.service';
import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';

@Component({
    selector: 'mp-allcash-boleto',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: '../payment-boleto.html',
    styleUrls: ['../payment-boleto.scss'],
})
export class AllCashBoletoComponent extends BoletoWebComponentCommon {
    public downloadBoletoUrl: string = '';
    public isElitePayment: boolean = false;

    constructor(
        private readonly router: Router,
        protected paymentService: AllCashService,
        protected downloadManager: DownloadManagerService,
        protected profileService: ProfileService,
        protected translate: TranslateService,
        protected conversationService: ConversationService,
        protected modalService: ModalService,
        protected authenticationService: AuthenticationService,
        protected paymentHelperService: PaymentHelpersService,
    ) {
        super(
            paymentService,
            translate,
            modalService,
            authenticationService,
            paymentHelperService,
        );
    }

    makePayment(): void {
        this.downloadBoletoUrl = '';
        if (!this.validate()) {
            return;
        }

        this.loading = true;

        this.pay().subscribe((response: IAuthResponse): void => {
            this.loading = false;
            if (!response.data) {
                this.modalService.open(
                    this.translate.instant('common.unknown_error'),
                );
                return;
            }

            this.paymentService.informAPI({
                isBoleto: true,
                membershipId: this.type,
                extraParams: new HttpParams(),
            }).subscribe((): void => {
                this.downloadBoleto(response.data);
                this.downloadBoletoUrl = response.data;
            });
        }, (err): void => {
            this.loading = false;
            if (Config.development) {
                console.log(err);
            }
            this.modalService.open(
                this.translate.instant('common.unknown_error'),
            );
            return;
        });
    }

    pay(): Observable<IAuthResponse<IOrderResponse>> {
        return this.paymentService.generateBoleto(
            this.type,
            this.price,
            this.getCPF(),
            this.getInterest(),
        );
    }

    downloadBoleto(url: string): boolean {
        window.open(url, '_blank');
        return false;
    }

    goBack(): void {
        this.router.navigate(['main'], { replaceUrl: true });
    }
}
