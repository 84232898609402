export interface IProduct {
    id: string;
    name: string;
    price: number;
    available: boolean;
}

export enum ProductNames {
    ExpressApproval = 'Express Approval',
    BoostPackages = 'Boost Packages',
}
