import { TranslateService } from '@ngx-translate/core';

import { IExplanationSugar } from '@libs/shared/interfaces/explanation-sugar.interface';
import { IImageParams } from '@libs/shared/interfaces/image-params.interface';
import { ISugarEllipse } from '@libs/shared/interfaces/sugar-ellipse.interface';
import { ISugarPreference } from '@libs/shared/interfaces/sugar-preference.interface';
import { MembershipCommon, MembershipType } from '@libs/shared/membership/membership.common';
import { IPhoto } from '@libs/shared/profile/photo';
import { PhotoCommon } from '@libs/shared/profile/photo.common';
import {
    Gender,
    GenderMan,
    GenderWoman,
    IBaseProfile,
    IProfile,
} from '@libs/shared/profile/profile';
import { IAttrBehaveList } from '@libs/shared/user/interfaces/attr-behave-list';
import { IPhotoVerificationStatus } from '@libs/shared/user/interfaces/photo-verification-status.interface';
import { IStats } from '@libs/shared/user/stats';
import { arrayFind } from '@libs/utils/array-functions';

import * as emailValidator from 'email-validator';

export class UserCommon implements IBaseProfile {
    static readonly ALLOWED_CHARACTERS: RegExp = /^(?:[ !\[\]\(\)_-]?[a-z0-9])*[ !\[\]\(\)_-]?$/i;
    static readonly SPACES_AT_BEGINNING: RegExp = /^\s+/g;
    static readonly SPACES_AT_END: RegExp = /\s+$/g;

    static readonly TYPE_EXPRESS_APPROVAL_PAYMENT: number = 0;

    static readonly CONVERSATION_LIMIT_THRESHOLD: number = 10;

    static readonly EMAIL_VERIFIED_YES: string = 'yes';

    static readonly GENDER_WOMAN: GenderWoman = 8;
    static readonly GENDER_WOMAN_STR: string = 'shared.user.genders.female';
    static readonly GENDER_WOMAN_ALT_STR: string = 'shared.user.genders_alt.female';
    static readonly GENDER_MAN: GenderMan = 1;
    static readonly GENDER_MAN_STR: string = 'shared.user.genders.male';
    static readonly GENDER_MAN_ALT_STR: string = 'shared.user.genders_alt.male';

    static readonly MATCH_SEX_MAN_STR: string = 'shared.user.match_sex.man';
    static readonly MATCH_SEX_WOMAN_STR: string = 'shared.user.match_sex.woman';
    static readonly MATCH_SEX_MAN_AND_WOMAN_STR: string = 'shared.user.match_sex.man_and_woman';
    static readonly MATCH_SEX_BOTH: number = UserCommon.GENDER_WOMAN + UserCommon.GENDER_MAN;

    static readonly EXTENDED_RELATIONSHIP_SINGLE: number = 1;
    static readonly EXTENDED_RELATIONSHIP_SINGLE_STR: string = 'shared.user.relationships.single';
    static readonly EXTENDED_RELATIONSHIP_SEPARATED: number = 2;
    static readonly EXTENDED_RELATIONSHIP_SEPARATED_STR: string = 'shared.user.relationships.separated';
    static readonly EXTENDED_RELATIONSHIP_DIVORCED: number = 4;
    static readonly EXTENDED_RELATIONSHIP_DIVORCED_STR: string = 'shared.user.relationships.divorced';
    static readonly EXTENDED_RELATIONSHIP_WIDOWER: number = 8;
    static readonly EXTENDED_RELATIONSHIP_WIDOWER_STR: string = 'shared.user.relationships.widower';
    static readonly EXTENDED_RELATIONSHIP_MARRIED: number = 16;
    static readonly EXTENDED_RELATIONSHIP_MARRIED_STR: string = 'shared.user.relationships.married';

    static readonly EXTENDED_BODY_TYPE_THIN: number = 1;
    static readonly EXTENDED_BODY_TYPE_THIN_STR: string = 'shared.user.body_types.thin';
    static readonly EXTENDED_BODY_TYPE_AVERAGE: number = 2;
    static readonly EXTENDED_BODY_TYPE_AVERAGE_STR: string = 'shared.user.body_types.average';
    static readonly EXTENDED_BODY_TYPE_MUSCULAR: number = 4;
    static readonly EXTENDED_BODY_TYPE_MUSCULAR_STR: string = 'shared.user.body_types.muscular';
    static readonly EXTENDED_BODY_TYPE_IN_SHAPE: number = 8;
    static readonly EXTENDED_BODY_TYPE_IN_SHAPE_STR: string = 'shared.user.body_types.in_shape';
    static readonly EXTENDED_BODY_TYPE_OVERWEIGHT: number = 16;
    static readonly EXTENDED_BODY_TYPE_OVERWEIGHT_STR: string = 'shared.user.body_types.overweight';
    static readonly EXTENDED_BODY_TYPE_BIG_LOVING: number = 32;
    static readonly EXTENDED_BODY_TYPE_BIG_LOVING_STR: string = 'shared.user.body_types.big_loving';

    static readonly EXTENDED_ETHNICITY_BLACK: number = 1;
    static readonly EXTENDED_ETHNICITY_BLACK_STR: string = 'shared.user.ethnicities.black';
    static readonly EXTENDED_ETHNICITY_ASIAN_CHINA: number = 2;
    static readonly EXTENDED_ETHNICITY_ASIAN_CHINA_STR: string = 'shared.user.ethnicities.asian_china';
    static readonly EXTENDED_ETHNICITY_ASIAN_JAPAN: number = 4;
    static readonly EXTENDED_ETHNICITY_ASIAN_JAPAN_STR: string = 'shared.user.ethnicities.asian_japan';
    static readonly EXTENDED_ETHNICITY_WHITE: number = 8;
    static readonly EXTENDED_ETHNICITY_WHITE_STR: string = 'shared.user.ethnicities.white';
    static readonly EXTENDED_ETHNICITY_INDIAN: number = 16;
    static readonly EXTENDED_ETHNICITY_INDIAN_STR: string = 'shared.user.ethnicities.indian';
    static readonly EXTENDED_ETHNICITY_LATIN: number = 32;
    static readonly EXTENDED_ETHNICITY_LATIN_STR: string = 'shared.user.ethnicities.latin';
    static readonly EXTENDED_ETHNICITY_ASIAN_COREAN: number = 64;
    static readonly EXTENDED_ETHNICITY_ASIAN_COREAN_STR: string = 'shared.user.ethnicities.asian_corean';
    static readonly EXTENDED_ETHNICITY_ASIAN_OTHERS: number = 128;
    static readonly EXTENDED_ETHNICITY_ASIAN_OTHERS_STR: string = 'shared.user.ethnicities.asian_others';
    static readonly EXTENDED_ETHNICITY_MIDDLE_EAST: number = 512;
    static readonly EXTENDED_ETHNICITY_MIDDLE_EAST_STR: string = 'shared.user.ethnicities.middle_east';
    static readonly EXTENDED_ETHNICITY_BROWN: number = 1024;
    static readonly EXTENDED_ETHNICITY_BROWN_STR: string = 'shared.user.ethnicities.brown';
    static readonly EXTENDED_ETHNICITY_OTHERS: number = 2048;
    static readonly EXTENDED_ETHNICITY_OTHERS_STR: string = 'shared.user.ethnicities.others';

    static readonly EXTENDED_EYE_COLOR_BLACK: number = 1;
    static readonly EXTENDED_EYE_COLOR_BLACK_STR: string = 'shared.user.eye_colors.black';
    static readonly EXTENDED_EYE_COLOR_BLUE: number = 2;
    static readonly EXTENDED_EYE_COLOR_BLUE_STR: string = 'shared.user.eye_colors.blue';
    static readonly EXTENDED_EYE_COLOR_LIGHT_BROWN: number = 4;
    static readonly EXTENDED_EYE_COLOR_LIGHT_BROWN_STR: string = 'shared.user.eye_colors.light_brown';
    static readonly EXTENDED_EYE_COLOR_GREEN: number = 8;
    static readonly EXTENDED_EYE_COLOR_GREEN_STR: string = 'shared.user.eye_colors.green';
    static readonly EXTENDED_EYE_COLOR_GRAY: number = 16;
    static readonly EXTENDED_EYE_COLOR_GRAY_STR: string = 'shared.user.eye_colors.gray';
    static readonly EXTENDED_EYE_COLOR_OTHERS: number = 32;
    static readonly EXTENDED_EYE_COLOR_OTHERS_STR: string = 'shared.user.eye_colors.others';
    static readonly EXTENDED_EYE_COLOR_DARK_BROW: number = 64;
    static readonly EXTENDED_EYE_COLOR_DARK_BROW_STR: string = 'shared.user.eye_colors.dark_brow';

    static readonly EXTENDED_HAIR_COLOR_REDHEAD: number = 1;
    static readonly EXTENDED_HAIR_COLOR_REDHEAD_STR: string = 'shared.user.hair.redhead';
    static readonly EXTENDED_HAIR_COLOR_BALD: number = 2;
    static readonly EXTENDED_HAIR_COLOR_BALD_STR: string = 'shared.user.hair.bald';
    static readonly EXTENDED_HAIR_COLOR_BLACK: number = 4;
    static readonly EXTENDED_HAIR_COLOR_BLACK_STR: string = 'shared.user.hair.black';
    static readonly EXTENDED_HAIR_COLOR_BLOND: number = 8;
    static readonly EXTENDED_HAIR_COLOR_BLOND_STR: string = 'shared.user.hair.blond';
    static readonly EXTENDED_HAIR_COLOR_BROWN: number = 16;
    static readonly EXTENDED_HAIR_COLOR_BROWN_STR: string = 'shared.user.hair.brown';
    static readonly EXTENDED_HAIR_COLOR_LIGHT_BROWN: number = 32;
    static readonly EXTENDED_HAIR_COLOR_LIGHT_BROWN_STR: string = 'shared.user.hair.light_brown';
    static readonly EXTENDED_HAIR_COLOR_RED: number = 64;
    static readonly EXTENDED_HAIR_COLOR_RED_STR: string = 'shared.user.hair.red';
    static readonly EXTENDED_HAIR_COLOR_GRAY: number = 128;
    static readonly EXTENDED_HAIR_COLOR_GRAY_STR: string = 'shared.user.hair.gray';
    static readonly EXTENDED_HAIR_COLOR_OTHER: number = 256;
    static readonly EXTENDED_HAIR_COLOR_OTHER_STR: string = 'shared.user.hair.other';

    static readonly EXTENDED_HAVE_CHILDREN_NOT: number = 1;
    static readonly EXTENDED_HAVE_CHILDREN_NOT_STR: string = 'shared.user.children.none';
    static readonly EXTENDED_HAVE_CHILDREN_1: number = 2;
    static readonly EXTENDED_HAVE_CHILDREN_1_STR: string = 'shared.user.children.one';
    static readonly EXTENDED_HAVE_CHILDREN_2: number = 4;
    static readonly EXTENDED_HAVE_CHILDREN_2_STR: string = 'shared.user.children.two';
    static readonly EXTENDED_HAVE_CHILDREN_3: number = 8;
    static readonly EXTENDED_HAVE_CHILDREN_3_STR: string = 'shared.user.children.three';
    static readonly EXTENDED_HAVE_CHILDREN_4: number = 16;
    static readonly EXTENDED_HAVE_CHILDREN_4_STR: string = 'shared.user.children.four';
    static readonly EXTENDED_HAVE_CHILDREN_5_MORE: number = 32;
    static readonly EXTENDED_HAVE_CHILDREN_5_MORE_STR: string = 'shared.user.children.five_more';

    static readonly EXTENDED_EDUCATION_HIGH_SCHOOL: number = 1;
    static readonly EXTENDED_EDUCATION_HIGH_SCHOOL_STR: string = 'shared.user.educations.high_school';
    static readonly EXTENDED_EDUCATION_PROF_TEC: number = 2;
    static readonly EXTENDED_EDUCATION_PROF_TEC_STR: string = 'shared.user.educations.prof_tec';
    static readonly EXTENDED_EDUCATION_HIGHER_CUR: number = 4;
    static readonly EXTENDED_EDUCATION_HIGHER_CUR_STR: string = 'shared.user.educations.higher_cur';
    static readonly EXTENDED_EDUCATION_HIGHER_INC: number = 8;
    static readonly EXTENDED_EDUCATION_HIGHER_INC_STR: string = 'shared.user.educations.higher_inc';
    static readonly EXTENDED_EDUCATION_HIGHER_COM: number = 16;
    static readonly EXTENDED_EDUCATION_HIGHER_COM_STR: string = 'shared.user.educations.higher_com';
    static readonly EXTENDED_EDUCATION_POSTGRADUATE: number = 32;
    static readonly EXTENDED_EDUCATION_POSTGRADUATE_STR: string = 'shared.user.educations.postgraduate';
    static readonly EXTENDED_EDUCATION_PHD: number = 64;
    static readonly EXTENDED_EDUCATION_PHD_STR: string = 'shared.user.educations.phd';
    static readonly EXTENDED_EDUCATION_LIFE: number = 128;
    static readonly EXTENDED_EDUCATION_LIFE_STR: string = 'shared.user.educations.life';

    static readonly EXTENDED_INCOME_10: number = 1;
    static readonly EXTENDED_INCOME_10_STR: string = 'shared.user.incomes.tier_1';
    static readonly EXTENDED_INCOME_10_30: number = 2;
    static readonly EXTENDED_INCOME_10_30_STR: string = 'shared.user.incomes.tier_2';
    static readonly EXTENDED_INCOME_30_50: number = 4;
    static readonly EXTENDED_INCOME_30_50_STR: string = 'shared.user.incomes.tier_3';
    static readonly EXTENDED_INCOME_50_70: number = 8;
    static readonly EXTENDED_INCOME_50_70_STR: string = 'shared.user.incomes.tier_4';
    static readonly EXTENDED_INCOME_70_100: number = 16;
    static readonly EXTENDED_INCOME_70_100_STR: string = 'shared.user.incomes.tier_5';
    static readonly EXTENDED_INCOME_100_150: number = 32;
    static readonly EXTENDED_INCOME_100_150_STR: string = 'shared.user.incomes.tier_6';
    static readonly EXTENDED_INCOME_150_500: number = 64;
    static readonly EXTENDED_INCOME_150_500_STR: string = 'shared.user.incomes.tier_7';
    static readonly EXTENDED_INCOME_VERY_RICH: number = 128;
    static readonly EXTENDED_INCOME_VERY_RICH_STR: string = 'shared.user.incomes.tier_8';

    static readonly EXTENDED_SMOKE_NEVER: number = 1;
    static readonly EXTENDED_SMOKE_NEVER_STR: string = 'shared.user.smoke.never';
    static readonly EXTENDED_SMOKE_RARE: number = 2;
    static readonly EXTENDED_SMOKE_RARE_STR: string = 'shared.user.smoke.rare';
    static readonly EXTENDED_SMOKE_SOCIAL: number = 4;
    static readonly EXTENDED_SMOKE_SOCIAL_STR: string = 'shared.user.smoke.social';
    static readonly EXTENDED_SMOKE_OFTEN: number = 8;
    static readonly EXTENDED_SMOKE_OFTEN_STR: string = 'shared.user.smoke.often';
    static readonly EXTENDED_SMOKE_VERY_OFTEN: number = 16;
    static readonly EXTENDED_SMOKE_VERY_OFTEN_STR: string = 'shared.user.smoke.very_often';
    static readonly EXTENDED_SMOKE_TRY_STOP: number = 32;
    static readonly EXTENDED_SMOKE_TRY_STOP_STR: string = 'shared.user.smoke.try_stop';
    static readonly EXTENDED_SMOKE_STOP: number = 64;
    static readonly EXTENDED_SMOKE_STOP_STR: string = 'shared.user.smoke.stop';

    static readonly EXTENDED_DRINK_NEVER: number = 1;
    static readonly EXTENDED_DRINK_NEVER_STR: string = 'shared.user.drink.never';
    static readonly EXTENDED_DRINK_RARE: number = 2;
    static readonly EXTENDED_DRINK_RARE_STR: string = 'shared.user.drink.rare';
    static readonly EXTENDED_DRINK_SOCIAL: number = 4;
    static readonly EXTENDED_DRINK_SOCIAL_STR: string = 'shared.user.drink.social';
    static readonly EXTENDED_DRINK_OFTEN: number = 8;
    static readonly EXTENDED_DRINK_OFTEN_STR: string = 'shared.user.drink.often';
    static readonly EXTENDED_DRINK_VERY_OFTEN: number = 16;
    static readonly EXTENDED_DRINK_VERY_OFTEN_STR: string = 'shared.user.drink.very_often';
    static readonly EXTENDED_DRINK_TRY_STOP: number = 32;
    static readonly EXTENDED_DRINK_TRY_STOP_STR: string = 'shared.user.drink.try_stop';
    static readonly EXTENDED_DRINK_STOP: number = 64;
    static readonly EXTENDED_DRINK_STOP_STR: string = 'shared.user.drink.stop';

    static readonly EXTENDED_LIFESTYLE_BUDGET_NEGOTIABLE: number = 1;
    static readonly EXTENDED_LIFESTYLE_BUDGET_NEGOTIABLE_STR: string = 'shared.user.budgets.negotiable';
    static readonly EXTENDED_LIFESTYLE_BUDGET_MIN: number = 2;
    static readonly EXTENDED_LIFESTYLE_BUDGET_MIN_STR: string = 'shared.user.budgets.min';
    static readonly EXTENDED_LIFESTYLE_BUDGET_BASIC: number = 4;
    static readonly EXTENDED_LIFESTYLE_BUDGET_BASIC_STR: string = 'shared.user.budgets.basic';
    static readonly EXTENDED_LIFESTYLE_BUDGET_MODERATE: number = 8;
    static readonly EXTENDED_LIFESTYLE_BUDGET_MODERATE_STR: string = 'shared.user.budgets.moderate';
    static readonly EXTENDED_LIFESTYLE_BUDGET_MEANING: number = 16;
    static readonly EXTENDED_LIFESTYLE_BUDGET_MEANING_STR: string = 'shared.user.budgets.meaning';
    static readonly EXTENDED_LIFESTYLE_BUDGET_HIGHER: number = 32;
    static readonly EXTENDED_LIFESTYLE_BUDGET_HIGHER_STR: string = 'shared.user.budgets.higher';

    static readonly EXTENDED_SELECT_ONE_NO_OPTION: number = -1;
    static readonly EXTENDED_SELECT_ONE_FRIEND: number = 1;
    static readonly EXTENDED_SELECT_ONE_FRIEND_STR: string = 'shared.user.references.friend';
    static readonly EXTENDED_SELECT_ONE_ARTICLE: number = 2;
    static readonly EXTENDED_SELECT_ONE_ARTICLE_STR: string = 'shared.user.references.article';
    static readonly EXTENDED_SELECT_ONE_WHATSAPP: number = 4;
    static readonly EXTENDED_SELECT_ONE_WHATSAPP_STR: string = 'shared.user.references.whatsapp';
    static readonly EXTENDED_SELECT_ONE_BLOG: number = 8;
    static readonly EXTENDED_SELECT_ONE_BLOG_STR: string = 'shared.user.references.blog';
    static readonly EXTENDED_SELECT_ONE_PRESS: number = 16;
    static readonly EXTENDED_SELECT_ONE_PRESS_STR: string = 'shared.user.references.press';
    static readonly EXTENDED_SELECT_ONE_TV: number = 32;
    static readonly EXTENDED_SELECT_ONE_TV_STR: string = 'shared.user.references.tv';
    static readonly EXTENDED_SELECT_ONE_GOOGLE: number = 64;
    static readonly EXTENDED_SELECT_ONE_GOOGLE_STR: string = 'shared.user.references.google';
    static readonly EXTENDED_SELECT_ONE_FACEBOOK: number = 128;
    static readonly EXTENDED_SELECT_ONE_FACEBOOK_STR: string = 'shared.user.references.facebook';
    static readonly EXTENDED_SELECT_ONE_RADIO: number = 256;
    static readonly EXTENDED_SELECT_ONE_RADIO_STR: string = 'shared.user.references.radio';
    static readonly EXTENDED_SELECT_ONE_LINKEDIN: number = 512;
    static readonly EXTENDED_SELECT_ONE_LINKEDIN_STR: string = 'shared.user.references.linkedin';
    static readonly EXTENDED_SELECT_ONE_YOUTUBE: number = 1024;
    static readonly EXTENDED_SELECT_ONE_YOUTUBE_STR: string = 'shared.user.references.youtube';
    static readonly EXTENDED_SELECT_ONE_OTHERS: number = 2048;
    static readonly EXTENDED_SELECT_ONE_OTHERS_STR: string = 'shared.user.references.others';
    static readonly EXTENDED_SELECT_ONE_INSTAGRAM: number = 4096;
    static readonly EXTENDED_SELECT_ONE_INSTAGRAM_STR: string = 'shared.user.references.instagram';
    static readonly EXTENDED_SELECT_ONE_TWITTER: number = 8192;
    static readonly EXTENDED_SELECT_ONE_TWITTER_STR: string = 'shared.user.references.twitter';
    static readonly EXTENDED_SELECT_ONE_TIKTOK: number = 16384;
    static readonly EXTENDED_SELECT_ONE_TIKTOK_STR: string = 'shared.user.references.tiktok';
    static readonly EXTENDED_SELECT_ONE_PODCAST: number = 32768;
    static readonly EXTENDED_SELECT_ONE_PODCAST_STR: string = 'shared.user.references.podcast';

    static readonly EXTENDED_NET_WORTH_100: number = 1;
    static readonly EXTENDED_NET_WORTH_100_STR: string = 'shared.user.net_worth.tier_1';
    static readonly EXTENDED_NET_WORTH_100_250: number = 2;
    static readonly EXTENDED_NET_WORTH_100_250_STR: string = 'shared.user.net_worth.tier_2';
    static readonly EXTENDED_NET_WORTH_250_500: number = 4;
    static readonly EXTENDED_NET_WORTH_250_500_STR: string = 'shared.user.net_worth.tier_3';
    static readonly EXTENDED_NET_WORTH_500_750: number = 8;
    static readonly EXTENDED_NET_WORTH_500_750_STR: string = 'shared.user.net_worth.tier_4';
    static readonly EXTENDED_NET_WORTH_750_1000: number = 16;
    static readonly EXTENDED_NET_WORTH_750_1000_STR: string = 'shared.user.net_worth.tier_5';
    static readonly EXTENDED_NET_WORTH_1000_2000: number = 32;
    static readonly EXTENDED_NET_WORTH_1000_2000_STR: string = 'shared.user.net_worth.tier_6';
    static readonly EXTENDED_NET_WORTH_2000_5000: number = 64;
    static readonly EXTENDED_NET_WORTH_2000_5000_STR: string = 'shared.user.net_worth.tier_7';
    static readonly EXTENDED_NET_WORTH_5000_10000: number = 128;
    static readonly EXTENDED_NET_WORTH_5000_10000_STR: string = 'shared.user.net_worth.tier_8';
    static readonly EXTENDED_NET_WORTH_10000_50000: number = 256;
    static readonly EXTENDED_NET_WORTH_10000_50000_STR: string = 'shared.user.net_worth.tier_9';
    static readonly EXTENDED_NET_WORTH_50000_100000: number = 512;
    static readonly EXTENDED_NET_WORTH_50000_100000_STR: string = 'shared.user.net_worth.tier_10';
    static readonly EXTENDED_NET_WORTH_MORE_100000: number = 1024;
    static readonly EXTENDED_NET_WORTH_MORE_100000_STR: string = 'shared.user.net_worth.tier_11';

    static readonly EXTENDED_HAPPY_TRAVEL_WORLD_SELECT_OPTION: number = 0;
    static readonly EXTENDED_HAPPY_TRAVEL_WORLD_SELECT_OPTION_STR: string = 'modules.frictionless.select_option';
    static readonly EXTENDED_HAPPY_TRAVEL_WORLD: number = 1;
    static readonly EXTENDED_HAPPY_TRAVEL_WORLD_STR: string = 'shared.user.happy_to_travel.world';
    static readonly EXTENDED_HAPPY_TRAVEL_COUNTRY: number = 2;
    static readonly EXTENDED_HAPPY_TRAVEL_COUNTRY_STR: string = 'shared.user.happy_to_travel.country';
    static readonly EXTENDED_HAPPY_TRAVEL_STATE: number = 4;
    static readonly EXTENDED_HAPPY_TRAVEL_STATE_STR: string = 'shared.user.happy_to_travel.state';
    static readonly EXTENDED_HAPPY_TRAVEL_CITY: number = 8;
    static readonly EXTENDED_HAPPY_TRAVEL_CITY_STR: string = 'shared.user.happy_to_travel.city';

    static readonly OCCUPATION_BUSINESS_ADMINISTRATOR: number = 1;
    static readonly OCCUPATION_BUSINESS_ADMINISTRATOR_STR: string = 'shared.user.occupations.business_administrator';
    static readonly OCCUPATION_ATTORNEY: number = 2;
    static readonly OCCUPATION_ATTORNEY_STR: string = 'shared.user.occupations.attorney';
    static readonly OCCUPATION_ARCHITECT: number = 4;
    static readonly OCCUPATION_ARCHITECT_STR: string = 'shared.user.occupations.architect';
    static readonly OCCUPATION_ASSISTANT: number = 8;
    static readonly OCCUPATION_ASSISTANT_STR: string = 'shared.user.occupations.assistant';
    static readonly OCCUPATION_ATTENDANT: number = 16;
    static readonly OCCUPATION_ATTENDANT_STR: string = 'shared.user.occupations.attendant';
    static readonly OCCUPATION_AUTONOMOUS: number = 32;
    static readonly OCCUPATION_AUTONOMOUS_STR: string = 'shared.user.occupations.autonomous';
    static readonly OCCUPATION_HAIRDRESSER: number = 64;
    static readonly OCCUPATION_HAIRDRESSER_STR: string = 'shared.user.occupations.hairdresser';
    static readonly OCCUPATION_CONSULTANT: number = 128;
    static readonly OCCUPATION_CONSULTANT_STR: string = 'shared.user.occupations.consultant';
    static readonly OCCUPATION_DENTIST: number = 256;
    static readonly OCCUPATION_DENTIST_STR: string = 'shared.user.occupations.dentist';
    static readonly OCCUPATION_COMPANY_DIRECTOR: number = 512;
    static readonly OCCUPATION_COMPANY_DIRECTOR_STR: string = 'shared.user.occupations.company_director';
    static readonly OCCUPATION_ECONOMIST: number = 1024;
    static readonly OCCUPATION_ECONOMIST_STR: string = 'shared.user.occupations.economist';
    static readonly OCCUPATION_EDUCATOR: number = 2048;
    static readonly OCCUPATION_EDUCATOR_STR: string = 'shared.user.occupations.educator';
    static readonly OCCUPATION_BUSINESSMAN: number = 4096;
    static readonly OCCUPATION_BUSINESSMAN_STR: string = 'shared.user.occupations.businessman';
    static readonly OCCUPATION_NURSE: number = 8192;
    static readonly OCCUPATION_NURSE_STR: string = 'shared.user.occupations.nurse';
    static readonly OCCUPATION_ENGINEER: number = 16384;
    static readonly OCCUPATION_ENGINEER_STR: string = 'shared.user.occupations.engineer';
    static readonly OCCUPATION_ESTAGIARY: number = 32768;
    static readonly OCCUPATION_ESTAGIARY_STR: string = 'shared.user.occupations.estagiary';
    static readonly OCCUPATION_AESTHETICAL: number = 65536;
    static readonly OCCUPATION_AESTHETICAL_STR: string = 'shared.user.occupations.aesthetical';
    static readonly OCCUPATION_STUDENT: number = 131072;
    static readonly OCCUPATION_STUDENT_STR: string = 'shared.user.occupations.student';
    static readonly OCCUPATION_PUBLIC_EMPLOYEE: number = 262144;
    static readonly OCCUPATION_PUBLIC_EMPLOYEE_STR: string = 'shared.user.occupations.public_employee';
    static readonly OCCUPATION_IT: number = 524288;
    static readonly OCCUPATION_IT_STR: string = 'shared.user.occupations.it';
    static readonly OCCUPATION_JOURNALIST: number = 1048576;
    static readonly OCCUPATION_JOURNALIST_STR: string = 'shared.user.occupations.journalist';
    static readonly OCCUPATION_MEDICINE: number = 2097152;
    static readonly OCCUPATION_MEDICINE_STR: string = 'shared.user.occupations.medicine';
    static readonly OCCUPATION_MODEL: number = 4194304;
    static readonly OCCUPATION_MODEL_STR: string = 'shared.user.occupations.model';
    static readonly OCCUPATION_RECEPTIONIST: number = 8388608;
    static readonly OCCUPATION_RECEPTIONIST_STR: string = 'shared.user.occupations.receptionist';
    static readonly OCCUPATION_SECRETARY: number = 16777216;
    static readonly OCCUPATION_SECRETARY_STR: string = 'shared.user.occupations.secretary';
    static readonly OCCUPATION_SELLER: number = 33554432;
    static readonly OCCUPATION_SELLER_STR: string = 'shared.user.occupations.seller';
    static readonly OCCUPATION_OTHER: number = 67108864;
    static readonly OCCUPATION_OTHER_STR: string = 'shared.user.occupations.other';

    static readonly WHAT_SUGGAR_DADDY_MOMMY: number = 1;
    static readonly WHAT_SUGGAR_DADDY_MOMMY_STR: string = 'shared.user.account_types.sugar_parent';
    static readonly WHAT_SUGGAR_DADDY_STR: string = 'shared.user.account_types.sugar_daddy';
    static readonly WHAT_SUGGAR_MOMMY_STR: string = 'shared.user.account_types.sugar_mommy';
    static readonly WHAT_SUGGAR_BABY: number = 2;
    static readonly WHAT_SUGGAR_BABY_STR: string = 'shared.user.account_types.sugar_baby';

    static readonly WHAT_ENUM: number[] = [
        UserCommon.WHAT_SUGGAR_DADDY_MOMMY,
        UserCommon.WHAT_SUGGAR_BABY,
    ];

    static readonly WHAT_STR_ENUM: string[] = [
        UserCommon.WHAT_SUGGAR_DADDY_MOMMY_STR,
        UserCommon.WHAT_SUGGAR_BABY_STR,
    ];

    static readonly LOOKING_FOR_MAN: number = 1;
    static readonly LOOKING_FOR_WOMAN: number = 2;

    static readonly PICTURE_LIGHTWEIGHT: IImageParams = Object.freeze({ width: 260 });
    static readonly PICTURE_SMALL: IImageParams = Object.freeze({ width: 100 });
    static readonly PICTURE_EXTRA_LARGE: IImageParams = Object.freeze({ width: 480 });

    static readonly PICTURE_UNVERIFIED: number = 0;
    static readonly PICTURE_VERIFYING: number = 1;
    static readonly PICTURE_VERIFIED: number = 2;

    static readonly MAX_USER_PICS: number = 3;

    static readonly MIN_AGE: number = 18;
    static readonly MAX_AGE: number = 82;

    static readonly MESSAGE_COMMON: number = 1;
    static readonly MESSAGE_REQUEST: number = 2;
    static readonly MESSAGE_GRANT: number = 3;

    static readonly ORIGIN_APP_WEB: number = 0;
    static readonly ORIGIN_APP_ANDROID: number = 1;
    static readonly ORIGIN_APP_IOS: number = 2;

    static readonly REQUEST_PRIVATE_INEXISTENT: number = 0;
    static readonly REQUEST_PRIVATE_ACTIVE: number = 1;
    static readonly REQUEST_PRIVATE_PENDING: number = 2;

    static readonly ICON_ELITE: string = 'elite';
    static readonly ICON_PREMIUM: string = 'premium';
    static readonly ICON_VERIFIED: string = 'verified';

    static readonly FAVORITE: string = 'favorite';
    static readonly FAVORITED_ME: string = 'favorited_me';
    static readonly FAVORITED_TURNED_ON: number = 1;

    static readonly FAVORITES_ENUM: string[] = [
        UserCommon.FAVORITE,
        UserCommon.FAVORITED_ME,
    ];

    static readonly VIEWED: string = 'viewed';
    static readonly UNVIEWED: string = 'unviewed';
    static readonly VIEWED_ME: string = 'viewed_me';

    static readonly VIEWS_ENUM: string[] = [
        UserCommon.VIEWED,
        UserCommon.UNVIEWED,
        UserCommon.VIEWED_ME,
    ];

    static readonly STATUS_ACTIVE: string = 'active';
    static readonly STATUS_ON_HOLD: string = 'on_hold';
    static readonly STATUS_PENDING: string = 'pending';
    static readonly STATUS_SUSPENDED: string = 'suspended';
    static readonly STATUS_PROFILE_EDIT: string = 'profile_edit';
    static readonly STATUS_UNAVAILABLE: string = 'unavailable';

    static readonly HEIGHT_MINIMUM: number = 150;
    static readonly HEIGHT_MAXIMUM: number = 210;

    static readonly WEIGHT_MINIMUM: number = 1;
    static readonly WEIGHT_MAXIMUM: number = 120;

    static readonly USER_STATUS_ONLINE: number = 1;
    static readonly USER_STATUS_OFFLINE: number = 0;

    static readonly USER_CATEGORY_1: string = 'cat1';
    static readonly USER_CATEGORY_2: string = 'cat2';
    static readonly USER_CATEGORY_3: string = 'cat3';
    static readonly USER_CATEGORY_4: string = 'cat4';

    protected static readonly ELITE_PENDING: string = 'pending';
    protected static readonly ELITE_YES: string = 'y';

    protected static readonly DADDY_AGE_LIMIT_TO_EXPRESS: number = 24;

    protected static readonly EXPRESS_APPROVAL_PAYABLE: number = 0;
    protected static readonly EXPRESS_APPROVAL_PAID: number = 1;

    protected static readonly RULES_READ: number = 1;

    public static readonly SILHOUETTE_MALE_FILENAME: string = 'silhouette-male';
    public static readonly SILHOUETTE_FEMALE_FILENAME: string = 'silhouette-female';

    profile_id: number;
    username: string;
    sex: Gender;
    match_sex: number;
    birthdate: string;
    headline: string;
    general_description: string;
    country_id: string;
    state_id: string;
    city_id: number;
    membership_type_id: MembershipType;
    email_verified: string;
    status: string;
    elite: string;
    is_elite: number;
    express_approval_status: number;
    hide_profile: string;
    origin: number;
    uuid: string;
    private_album_count: number;
    new_count: number;
    verified_photo_count: number;
    age: number;
    cityName: string;
    countryName: string;
    numberOfPhotos: number;
    online_count: number;
    stateName: string;
    wideStateName: string;
    profile_extended: any;
    public_album: IPhoto[];
    private_album: IPhoto[];
    membership_expiration_timestamp: number;
    next_recurring_payment_timestamp: number;
    conversations_qty: number;
    email_obfuscated: string;
    have_pending_changes: boolean;
    photo: IPhoto;
    is_complete: boolean;
    profiletype: string;
    last_expired_membership_timestamp: number;

    email: string;
    password: string;
    country: string;
    state: string;
    city: string;
    bodytype: string;
    size: string;
    hair: string;
    relationship: string;
    weight: string;
    travelIntention: string;
    profileLanguage: string;
    lifeStyle: string;
    height1: number;
    interestDescription: string;
    happy_to_travel: string;
    head_line: string;
    accType: number;
    request_friend: boolean;
    body_type: number;
    hair_color: number;
    ethnicity: number;
    smoke: number;
    drink: number;
    children: number;
    education: number;
    occupation: number;
    income: number;
    netWorth: number;
    eyeColor: number;
    description: string;
    lifestyle_budget: string;
    presentationSentence: string;

    constructor(profileId: number) {
        this.profile_id = profileId;
    }

    static isMale(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            user.sex === UserCommon.GENDER_MAN;
    }

    static isFemale(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            user.sex === UserCommon.GENDER_WOMAN;
    }

    static isFemaleStraight(user: UserCommon | undefined): boolean {
        return UserCommon.isFemale(user) && user.match_sex === UserCommon.GENDER_MAN;
    }

    static isFemaleGay(user: UserCommon | undefined): boolean {
        return UserCommon.isFemale(user) && user.match_sex === UserCommon.GENDER_WOMAN;
    }

    static isMaleStraight(user: UserCommon | undefined): boolean {
        return UserCommon.isMale(user) && user.match_sex === UserCommon.GENDER_WOMAN;
    }

    static isMaleGay(user: UserCommon | undefined): boolean {
        return UserCommon.isMale(user) && user.match_sex === UserCommon.GENDER_MAN;
    }

    static isBisexual(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.match_sex === UserCommon.GENDER_MAN + UserCommon.GENDER_WOMAN;
    }

    static isValidPassword(password: string): boolean {
        return typeof password === 'string' && password.length >= 5;
    }

    static isValidUserName(username: string, ignoreLength: boolean = false): boolean {
        return UserCommon.ALLOWED_CHARACTERS.test(username) &&
            (ignoreLength || username.length >= 4);
    }

    static removeUndueSpacesAfterFocusOut(username: string): string {
        const hasUndueSpaces: boolean = UserCommon.SPACES_AT_END.test(username);

        if(!hasUndueSpaces) {
            return username;
        }

        return username.replace(UserCommon.SPACES_AT_END, '');
    }

    static removeUndueSpacesAtTheBegginging(username: string): string {
        const hasUndueSpaces: boolean = UserCommon.SPACES_AT_BEGINNING.test(username);

        if (!hasUndueSpaces) {
            return username;
        }

        return username.replace(UserCommon.SPACES_AT_BEGINNING, '');
    }

    static getAccTypeLabelById(id: number): string {
        return UserCommon.getAccTypeLabelList()[id];
    }

    static getGenderLabelById(id: number): string {
        return UserCommon.getGenderLabelList()[id];
    }

    static getAccTypeLabelList(): string[] {
        return UserCommon.WHAT_STR_ENUM;
    }

    static getGenderLabelList(): string[] {
        return [UserCommon.GENDER_MAN_STR, UserCommon.GENDER_WOMAN_STR];
    }

    static getGenderStatus(gender: number): string {
        if (gender === UserCommon.GENDER_MAN) {
            return UserCommon.GENDER_MAN_STR;
        }

        return UserCommon.GENDER_WOMAN_STR;
    }

    static getAltGenderStatus(gender: number): string {
        if (gender === UserCommon.GENDER_MAN) {
            return UserCommon.GENDER_MAN_ALT_STR;
        }

        return UserCommon.GENDER_WOMAN_ALT_STR;
    }

    static getLookingForOptions(): string[] {
        return UserCommon.getLookingForIds().map(UserCommon.getMatchSexStatus);
    }

    static getLookingForIds(): number[] {
        return [
            UserCommon.GENDER_MAN,
            UserCommon.GENDER_WOMAN,
            UserCommon.GENDER_MAN + UserCommon.GENDER_WOMAN,
        ];
    }

    static getAccTypeOptions(): string[] {
        return UserCommon.getAccTypeIds().map(UserCommon.getAccTypeStatus);
    }

    static getAccTypeIds(): number[] {
        return UserCommon.WHAT_ENUM;
    }

    static getGenderOptions(): string[] {
        return UserCommon.getGenderIds().map(UserCommon.getGenderStatus);
    }

    static getGenderIds(): number[] {
        return [
            UserCommon.GENDER_MAN,
            UserCommon.GENDER_WOMAN,
        ];
    }

    static getRelationshipOptions(): string[] {
        return [
            UserCommon.getRelationshipStatus(UserCommon.EXTENDED_RELATIONSHIP_SINGLE),
            UserCommon.getRelationshipStatus(UserCommon.EXTENDED_RELATIONSHIP_SEPARATED),
            UserCommon.getRelationshipStatus(UserCommon.EXTENDED_RELATIONSHIP_DIVORCED),
            UserCommon.getRelationshipStatus(UserCommon.EXTENDED_RELATIONSHIP_WIDOWER),
            UserCommon.getRelationshipStatus(UserCommon.EXTENDED_RELATIONSHIP_MARRIED),
        ];
    }

    static getBodyTypeOptions(): string[] {
        return [
            UserCommon.getBodyTypeStatus(UserCommon.EXTENDED_BODY_TYPE_THIN),
            UserCommon.getBodyTypeStatus(UserCommon.EXTENDED_BODY_TYPE_AVERAGE),
            UserCommon.getBodyTypeStatus(UserCommon.EXTENDED_BODY_TYPE_MUSCULAR),
            UserCommon.getBodyTypeStatus(UserCommon.EXTENDED_BODY_TYPE_IN_SHAPE),
            UserCommon.getBodyTypeStatus(UserCommon.EXTENDED_BODY_TYPE_OVERWEIGHT),
            UserCommon.getBodyTypeStatus(UserCommon.EXTENDED_BODY_TYPE_BIG_LOVING),
        ];
    }

    static getEthnicityStatusOptions(): string[] {
        return [
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_WHITE),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_BROWN),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_BLACK),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_LATIN),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_ASIAN_JAPAN),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_ASIAN_CHINA),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_ASIAN_COREAN),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_ASIAN_OTHERS),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_INDIAN),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_MIDDLE_EAST),
            UserCommon.getEthnicityStatus(UserCommon.EXTENDED_ETHNICITY_OTHERS),
        ];
    }

    static getHairTypeOptions(): string[] {
        return [
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_BLACK),
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_BROWN),
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_LIGHT_BROWN),
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_BLOND),
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_REDHEAD),
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_RED),
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_GRAY),
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_BALD),
            UserCommon.getHairColorStatus(UserCommon.EXTENDED_HAIR_COLOR_OTHER),
        ];
    }

    static getSmokeOptions(): string[] {
        return [
            UserCommon.getSmokeStatus(UserCommon.EXTENDED_SMOKE_NEVER),
            UserCommon.getSmokeStatus(UserCommon.EXTENDED_SMOKE_RARE),
            UserCommon.getSmokeStatus(UserCommon.EXTENDED_SMOKE_SOCIAL),
            UserCommon.getSmokeStatus(UserCommon.EXTENDED_SMOKE_OFTEN),
            UserCommon.getSmokeStatus(UserCommon.EXTENDED_SMOKE_VERY_OFTEN),
            UserCommon.getSmokeStatus(UserCommon.EXTENDED_SMOKE_TRY_STOP),
            UserCommon.getSmokeStatus(UserCommon.EXTENDED_SMOKE_STOP),
        ];
    }

    static getDrinkOptions(): string[] {
        return [
            UserCommon.getDrinkStatus(UserCommon.EXTENDED_DRINK_NEVER),
            UserCommon.getDrinkStatus(UserCommon.EXTENDED_DRINK_RARE),
            UserCommon.getDrinkStatus(UserCommon.EXTENDED_DRINK_SOCIAL),
            UserCommon.getDrinkStatus(UserCommon.EXTENDED_DRINK_OFTEN),
            UserCommon.getDrinkStatus(UserCommon.EXTENDED_DRINK_VERY_OFTEN),
            UserCommon.getDrinkStatus(UserCommon.EXTENDED_DRINK_TRY_STOP),
            UserCommon.getDrinkStatus(UserCommon.EXTENDED_DRINK_STOP),
        ];
    }

    static getChildrenOptions(): string[] {
        return [
            UserCommon.getChildrenStatus(UserCommon.EXTENDED_HAVE_CHILDREN_NOT),
            UserCommon.getChildrenStatus(UserCommon.EXTENDED_HAVE_CHILDREN_1),
            UserCommon.getChildrenStatus(UserCommon.EXTENDED_HAVE_CHILDREN_2),
            UserCommon.getChildrenStatus(UserCommon.EXTENDED_HAVE_CHILDREN_3),
            UserCommon.getChildrenStatus(UserCommon.EXTENDED_HAVE_CHILDREN_4),
            UserCommon.getChildrenStatus(UserCommon.EXTENDED_HAVE_CHILDREN_5_MORE),
        ];
    }

    static getEducationOptions(): string[] {
        return [
            UserCommon.getEducationStatus(UserCommon.EXTENDED_EDUCATION_HIGH_SCHOOL),
            UserCommon.getEducationStatus(UserCommon.EXTENDED_EDUCATION_PROF_TEC),
            UserCommon.getEducationStatus(UserCommon.EXTENDED_EDUCATION_HIGHER_CUR),
            UserCommon.getEducationStatus(UserCommon.EXTENDED_EDUCATION_HIGHER_INC),
            UserCommon.getEducationStatus(UserCommon.EXTENDED_EDUCATION_HIGHER_COM),
            UserCommon.getEducationStatus(UserCommon.EXTENDED_EDUCATION_POSTGRADUATE),
            UserCommon.getEducationStatus(UserCommon.EXTENDED_EDUCATION_PHD),
            UserCommon.getEducationStatus(UserCommon.EXTENDED_EDUCATION_LIFE),
        ];
    }

    static getIncomeOptions(): string[] {
        return [
            UserCommon.getIncomeStatus(UserCommon.EXTENDED_INCOME_10),
            UserCommon.getIncomeStatus(UserCommon.EXTENDED_INCOME_10_30),
            UserCommon.getIncomeStatus(UserCommon.EXTENDED_INCOME_30_50),
            UserCommon.getIncomeStatus(UserCommon.EXTENDED_INCOME_50_70),
            UserCommon.getIncomeStatus(UserCommon.EXTENDED_INCOME_70_100),
            UserCommon.getIncomeStatus(UserCommon.EXTENDED_INCOME_100_150),
            UserCommon.getIncomeStatus(UserCommon.EXTENDED_INCOME_150_500),
            UserCommon.getIncomeStatus(UserCommon.EXTENDED_INCOME_VERY_RICH),
        ];
    }

    static getNetWorthOptions(): string[] {
        return [
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_100),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_100_250),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_250_500),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_500_750),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_750_1000),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_1000_2000),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_2000_5000),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_5000_10000),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_10000_50000),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_50000_100000),
            UserCommon.getNetWorthStatus(UserCommon.EXTENDED_NET_WORTH_MORE_100000),
        ];
    }

    static getEyeColorOptions(): string[] {
        return [
            UserCommon.getEyeColorStatus(UserCommon.EXTENDED_EYE_COLOR_LIGHT_BROWN),
            UserCommon.getEyeColorStatus(UserCommon.EXTENDED_EYE_COLOR_DARK_BROW),
            UserCommon.getEyeColorStatus(UserCommon.EXTENDED_EYE_COLOR_BLACK),
            UserCommon.getEyeColorStatus(UserCommon.EXTENDED_EYE_COLOR_BLUE),
            UserCommon.getEyeColorStatus(UserCommon.EXTENDED_EYE_COLOR_GREEN),
            UserCommon.getEyeColorStatus(UserCommon.EXTENDED_EYE_COLOR_GRAY),
            UserCommon.getEyeColorStatus(UserCommon.EXTENDED_EYE_COLOR_OTHERS),
        ];
    }

    static getReferenceStatusList(): string[] {
        return [
            UserCommon.EXTENDED_SELECT_ONE_FRIEND_STR,
            UserCommon.EXTENDED_SELECT_ONE_ARTICLE_STR,
            UserCommon.EXTENDED_SELECT_ONE_WHATSAPP_STR,
            UserCommon.EXTENDED_SELECT_ONE_BLOG_STR,
            UserCommon.EXTENDED_SELECT_ONE_PRESS_STR,
            UserCommon.EXTENDED_SELECT_ONE_TV_STR,
            UserCommon.EXTENDED_SELECT_ONE_GOOGLE_STR,
            UserCommon.EXTENDED_SELECT_ONE_FACEBOOK_STR,
            UserCommon.EXTENDED_SELECT_ONE_RADIO_STR,
            UserCommon.EXTENDED_SELECT_ONE_LINKEDIN_STR,
            UserCommon.EXTENDED_SELECT_ONE_YOUTUBE_STR,
            UserCommon.EXTENDED_SELECT_ONE_PODCAST_STR,
            UserCommon.EXTENDED_SELECT_ONE_INSTAGRAM_STR,
            UserCommon.EXTENDED_SELECT_ONE_TWITTER_STR,
            UserCommon.EXTENDED_SELECT_ONE_TIKTOK_STR,
            UserCommon.EXTENDED_SELECT_ONE_OTHERS_STR,
        ];
    }

    static getOccupationStatusList(): string[] {
        return [
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_BUSINESS_ADMINISTRATOR),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_ATTORNEY),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_ARCHITECT),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_ASSISTANT),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_ATTENDANT),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_AUTONOMOUS),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_HAIRDRESSER),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_CONSULTANT),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_DENTIST),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_COMPANY_DIRECTOR),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_ECONOMIST),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_EDUCATOR),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_BUSINESSMAN),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_NURSE),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_ENGINEER),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_ESTAGIARY),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_AESTHETICAL),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_STUDENT),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_PUBLIC_EMPLOYEE),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_IT),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_JOURNALIST),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_MEDICINE),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_MODEL),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_RECEPTIONIST),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_SECRETARY),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_SELLER),
            UserCommon.getOccupationStatus(UserCommon.OCCUPATION_OTHER),
        ];
    }

    static getTravelOptions(): string[] {
        return [
            UserCommon.getTravelStatus(UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD),
            UserCommon.getTravelStatus(UserCommon.EXTENDED_HAPPY_TRAVEL_COUNTRY),
            UserCommon.getTravelStatus(UserCommon.EXTENDED_HAPPY_TRAVEL_STATE),
            UserCommon.getTravelStatus(UserCommon.EXTENDED_HAPPY_TRAVEL_CITY),
        ];
    }

    static getLifeStyleOptions(): string[] {
        return [
            UserCommon.getLifestyleBudgetStatus(UserCommon.EXTENDED_LIFESTYLE_BUDGET_NEGOTIABLE),
            UserCommon.getLifestyleBudgetStatus(UserCommon.EXTENDED_LIFESTYLE_BUDGET_MIN),
            UserCommon.getLifestyleBudgetStatus(UserCommon.EXTENDED_LIFESTYLE_BUDGET_BASIC),
            UserCommon.getLifestyleBudgetStatus(UserCommon.EXTENDED_LIFESTYLE_BUDGET_MODERATE),
            UserCommon.getLifestyleBudgetStatus(UserCommon.EXTENDED_LIFESTYLE_BUDGET_MEANING),
            UserCommon.getLifestyleBudgetStatus(UserCommon.EXTENDED_LIFESTYLE_BUDGET_HIGHER),
        ];
    }

    static favoritesOptions(): string[] {
        return UserCommon.FAVORITES_ENUM;
    }

    static favoritesKey(name: string): number {
        return UserCommon.FAVORITES_ENUM.indexOf(name);
    }

    static favoritesStatus(id: number): string {
        return UserCommon.FAVORITES_ENUM[id];
    }

    static viewOptions(): string[] {
        return UserCommon.VIEWS_ENUM;
    }

    static viewKey(name: string): number {
        return UserCommon.VIEWS_ENUM.indexOf(name);
    }

    static viewStatus(id: number): string {
        return UserCommon.VIEWS_ENUM[id];
    }

    static getPropertySuffix(propertyName: string): string {
        const propertyDict: object = {
            size: 'shared.user.measurements.height',
            weight: 'shared.user.measurements.weight',
        };

        return propertyDict[propertyName] || '';
    }

    static getDefaultHeightValue(): number {
        return 1.70;
    }

    static getDefaultWeightValue(): number {
        return 60;
    }

    static getProfilePicturePlaceholder(profile?: IBaseProfile): string {
        return UserCommon.getBasicImage(
            profile.sex,
            profile.membership_type_id,
        );
    }

    static getBasicImage(gender: number, membershipType: MembershipType): string {
        if (!MembershipCommon.isBaby(membershipType)) {
            if (gender === UserCommon.GENDER_MAN) {
                return UserCommon.resolveImageName(UserCommon.SILHOUETTE_MALE_FILENAME);
            }

            return UserCommon.resolveImageName(UserCommon.SILHOUETTE_FEMALE_FILENAME, '.png');
        }

        if (gender === UserCommon.GENDER_MAN) {
            return UserCommon.resolveImageName(UserCommon.SILHOUETTE_MALE_FILENAME);
        }

        return UserCommon.resolveImageName(UserCommon.SILHOUETTE_FEMALE_FILENAME, '.png');
    }

    static getTypeName(gender: number, type: number): string {
        if (type === UserCommon.WHAT_SUGGAR_DADDY_MOMMY) {
            if (gender === UserCommon.GENDER_WOMAN) {
                return UserCommon.WHAT_SUGGAR_MOMMY_STR;
            }

            return UserCommon.WHAT_SUGGAR_DADDY_STR;
        }

        return UserCommon.WHAT_SUGGAR_BABY_STR;
    }

    static isDaddyMommyByWhat(myType: number): boolean {
        return myType === UserCommon.WHAT_SUGGAR_DADDY_MOMMY;
    }

    static isDaddyMommyBelowAgeThreshold(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            MembershipCommon.isDaddyMommyType(user.membership_type_id) &&
            UserCommon.isAgeBelowExpressThreshold(user.age);
    }

    static isAgeBelowExpressThreshold(age: number): boolean {
        return age < UserCommon.DADDY_AGE_LIMIT_TO_EXPRESS;
    }

    static isBabyFree(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            MembershipCommon.isBabyFree(user.membership_type_id);
    }

    static isDaddyMommyFree(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            MembershipCommon.isDaddyMommyFreeType(user.membership_type_id);
    }

    static isBabyPremium(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            MembershipCommon.isBabyPremium(user.membership_type_id);
    }

    static isDaddyMommyPremium(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            MembershipCommon.isDaddyMommyPremiumType(user.membership_type_id);
    }

    static hasExpiredMembership(user: UserCommon | undefined, stats: IStats | undefined) {
        return user !== undefined &&
            stats !== undefined &&
            stats.paymentsMade > 0 &&
            MembershipCommon.isFree(user.membership_type_id);
    }

    static isPremium(user: IBaseProfile | undefined): boolean {
        return user !== undefined && (
            UserCommon.isDaddyMommyPremium(user) ||
            UserCommon.isBabyPremium(user)
        );
    }

    static isElite(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            MembershipCommon.isEliteType(user.membership_type_id);
    }

    static isPendingElite(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            user.elite === UserCommon.ELITE_PENDING;
    }

    static isDaddyMommy(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            MembershipCommon.isDaddyMommyType(user.membership_type_id);
    }

    static isBaby(user: IBaseProfile | undefined): boolean {
        return user !== undefined && (
            UserCommon.isBabyFree(user) || UserCommon.isBabyPremium(user)
        );
    }

    static hasPaidExpressApproval(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.express_approval_status === UserCommon.EXPRESS_APPROVAL_PAID;
    }

    static hasMembershipExpiration(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.membership_expiration_timestamp !== null;
    }

    static hasReachedMessageLimit(user: UserCommon | undefined): boolean {
        return UserCommon.isDaddyMommyFree(user) && UserCommon.hasSurpassedMessageThreshold(user);
    }

    static hasSurpassedMessageThreshold(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.conversations_qty >= UserCommon.CONVERSATION_LIMIT_THRESHOLD;
    }

    static hasPublicPhoto(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            user.public_album !== undefined &&
            user.public_album.length > 0;
    }

    static hasActivePublicPhoto(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.public_album !== undefined &&
            user.public_album
                .some((photo: IPhoto): boolean => PhotoCommon.isActive(photo));
    }

    static hasPendingPublicPhoto(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.public_album !== undefined &&
            user.public_album
                .some((photo: IPhoto): boolean => PhotoCommon.isPendingApproval(photo));
    }

    static hasActiveOrPendingPublicPhoto(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.public_album !== undefined &&
            user.public_album.some((photo): boolean =>
                PhotoCommon.isActive(photo) ||
                PhotoCommon.isPendingApproval(photo),
            );
    }

    static getPrivatePhotosCount(user: IProfile | undefined): number {
        if (user === undefined) {
            return 0;
        }

        return user.private_album_count;
    }

    static hasActivePrivatePhoto(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.private_album !== undefined &&
            user.private_album
                .some((photo: IPhoto): boolean => PhotoCommon.isActive(photo));
    }

    static isPhotoVerified(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            user.verified_photo_count > 0;
    }

    static isPrivatePhotoRequestPending(user: IProfile | undefined): boolean {
        return user !== undefined &&
            user.requester_friend_status === UserCommon.REQUEST_PRIVATE_PENDING;
    }

    static isPrivatePhotoRequestInexistent(user: IProfile | undefined): boolean {
        return user !== undefined &&
            user.requester_friend_status === UserCommon.REQUEST_PRIVATE_INEXISTENT;
    }

    static isPrivatePhotoRequestActive(user: IProfile | undefined): boolean {
        return user !== undefined &&
            user.requester_friend_status === UserCommon.REQUEST_PRIVATE_ACTIVE;
    }

    static isValidEmail(email: string): boolean {
        return emailValidator.validate(email);
    }

    static hasAcceptedTerms(user: UserCommon): boolean {
        return user.profile_extended.has_read_our_rules === UserCommon.RULES_READ;
    }

    static getAccTypeStatus(accType: number): string {
        if (accType === UserCommon.WHAT_SUGGAR_BABY) {
            return UserCommon.WHAT_SUGGAR_BABY_STR;
        }

        return UserCommon.WHAT_SUGGAR_DADDY_MOMMY_STR;
    }

    static getRelationshipStatus(relationship: number): string {
        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_SINGLE) {
            return UserCommon.EXTENDED_RELATIONSHIP_SINGLE_STR;
        }

        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_SEPARATED) {
            return UserCommon.EXTENDED_RELATIONSHIP_SEPARATED_STR;
        }

        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_DIVORCED) {
            return UserCommon.EXTENDED_RELATIONSHIP_DIVORCED_STR;
        }

        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_WIDOWER) {
            return UserCommon.EXTENDED_RELATIONSHIP_WIDOWER_STR;
        }

        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_MARRIED) {
            return UserCommon.EXTENDED_RELATIONSHIP_MARRIED_STR;
        }

        return '';
    }

    static getRelationshipKey(relationship: string): number {
        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_SINGLE_STR) {
            return UserCommon.EXTENDED_RELATIONSHIP_SINGLE;
        }

        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_SEPARATED_STR) {
            return UserCommon.EXTENDED_RELATIONSHIP_SEPARATED;
        }

        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_DIVORCED_STR) {
            return UserCommon.EXTENDED_RELATIONSHIP_DIVORCED;
        }

        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_WIDOWER_STR) {
            return UserCommon.EXTENDED_RELATIONSHIP_WIDOWER;
        }

        if (relationship === UserCommon.EXTENDED_RELATIONSHIP_MARRIED_STR) {
            return UserCommon.EXTENDED_RELATIONSHIP_MARRIED;
        }

        return 1;
    }

    static getBodyTypeStatus(bodyType: number): string {
        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_THIN) {
            return UserCommon.EXTENDED_BODY_TYPE_THIN_STR;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_AVERAGE) {
            return UserCommon.EXTENDED_BODY_TYPE_AVERAGE_STR;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_MUSCULAR) {
            return UserCommon.EXTENDED_BODY_TYPE_MUSCULAR_STR;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_IN_SHAPE) {
            return UserCommon.EXTENDED_BODY_TYPE_IN_SHAPE_STR;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_OVERWEIGHT) {
            return UserCommon.EXTENDED_BODY_TYPE_OVERWEIGHT_STR;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_BIG_LOVING) {
            return UserCommon.EXTENDED_BODY_TYPE_BIG_LOVING_STR;
        }

        return '';
    }

    static getBodyTypeKey(bodyType: string): number {
        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_THIN_STR) {
            return UserCommon.EXTENDED_BODY_TYPE_THIN;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_AVERAGE_STR) {
            return UserCommon.EXTENDED_BODY_TYPE_AVERAGE;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_MUSCULAR_STR) {
            return UserCommon.EXTENDED_BODY_TYPE_MUSCULAR;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_IN_SHAPE_STR) {
            return UserCommon.EXTENDED_BODY_TYPE_IN_SHAPE;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_OVERWEIGHT_STR) {
            return UserCommon.EXTENDED_BODY_TYPE_OVERWEIGHT;
        }

        if (bodyType === UserCommon.EXTENDED_BODY_TYPE_BIG_LOVING_STR) {
            return UserCommon.EXTENDED_BODY_TYPE_BIG_LOVING;
        }

        return 1;
    }

    static getEthnicityStatus(ethnicity: number): string {
        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_WHITE) {
            return UserCommon.EXTENDED_ETHNICITY_WHITE_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_ASIAN_JAPAN) {
            return UserCommon.EXTENDED_ETHNICITY_ASIAN_JAPAN_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_BROWN) {
            return UserCommon.EXTENDED_ETHNICITY_BROWN_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_BLACK) {
            return UserCommon.EXTENDED_ETHNICITY_BLACK_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_ASIAN_CHINA) {
            return UserCommon.EXTENDED_ETHNICITY_ASIAN_CHINA_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_ASIAN_COREAN) {
            return UserCommon.EXTENDED_ETHNICITY_ASIAN_COREAN_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_ASIAN_OTHERS) {
            return UserCommon.EXTENDED_ETHNICITY_ASIAN_OTHERS_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_INDIAN) {
            return UserCommon.EXTENDED_ETHNICITY_INDIAN_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_LATIN) {
            return UserCommon.EXTENDED_ETHNICITY_LATIN_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_MIDDLE_EAST) {
            return UserCommon.EXTENDED_ETHNICITY_MIDDLE_EAST_STR;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_OTHERS) {
            return UserCommon.EXTENDED_ETHNICITY_OTHERS_STR;
        }

        return '';
    }

    static getEthnicityKey(ethnicity: string): number {
        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_BLACK_STR) {
            return UserCommon.EXTENDED_ETHNICITY_BLACK;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_ASIAN_CHINA_STR) {
            return UserCommon.EXTENDED_ETHNICITY_ASIAN_CHINA;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_ASIAN_JAPAN_STR) {
            return UserCommon.EXTENDED_ETHNICITY_ASIAN_JAPAN;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_WHITE_STR) {
            return UserCommon.EXTENDED_ETHNICITY_WHITE;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_INDIAN_STR) {
            return UserCommon.EXTENDED_ETHNICITY_INDIAN;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_LATIN_STR) {
            return UserCommon.EXTENDED_ETHNICITY_LATIN;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_ASIAN_COREAN_STR) {
            return UserCommon.EXTENDED_ETHNICITY_ASIAN_COREAN;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_ASIAN_OTHERS_STR) {
            return UserCommon.EXTENDED_ETHNICITY_ASIAN_OTHERS;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_MIDDLE_EAST_STR) {
            return UserCommon.EXTENDED_ETHNICITY_MIDDLE_EAST;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_BROWN_STR) {
            return UserCommon.EXTENDED_ETHNICITY_BROWN;
        }

        if (ethnicity === UserCommon.EXTENDED_ETHNICITY_OTHERS_STR) {
            return UserCommon.EXTENDED_ETHNICITY_OTHERS;
        }

        return 1;
    }

    static getEyeColorStatus(eyeColor: number): string {
        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_BLACK) {
            return UserCommon.EXTENDED_EYE_COLOR_BLACK_STR;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_BLUE) {
            return UserCommon.EXTENDED_EYE_COLOR_BLUE_STR;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_LIGHT_BROWN) {
            return UserCommon.EXTENDED_EYE_COLOR_LIGHT_BROWN_STR;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_GREEN) {
            return UserCommon.EXTENDED_EYE_COLOR_GREEN_STR;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_GRAY) {
            return UserCommon.EXTENDED_EYE_COLOR_GRAY_STR;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_OTHERS) {
            return UserCommon.EXTENDED_EYE_COLOR_OTHERS_STR;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_DARK_BROW) {
            return UserCommon.EXTENDED_EYE_COLOR_DARK_BROW_STR;
        }

        return '';
    }

    static getEyeColorKey(eyeColor: string): number {
        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_BLACK_STR) {
            return UserCommon.EXTENDED_EYE_COLOR_BLACK;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_BLUE_STR) {
            return UserCommon.EXTENDED_EYE_COLOR_BLUE;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_LIGHT_BROWN_STR) {
            return UserCommon.EXTENDED_EYE_COLOR_LIGHT_BROWN;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_GREEN_STR) {
            return UserCommon.EXTENDED_EYE_COLOR_GREEN;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_GRAY_STR) {
            return UserCommon.EXTENDED_EYE_COLOR_GRAY;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_OTHERS_STR) {
            return UserCommon.EXTENDED_EYE_COLOR_OTHERS;
        }

        if (eyeColor === UserCommon.EXTENDED_EYE_COLOR_DARK_BROW_STR) {
            return UserCommon.EXTENDED_EYE_COLOR_DARK_BROW;
        }

        return 1;
    }

    static getHairColorStatus(hairColor: number): string {
        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_REDHEAD) {
            return UserCommon.EXTENDED_HAIR_COLOR_REDHEAD_STR;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_BALD) {
            return UserCommon.EXTENDED_HAIR_COLOR_BALD_STR;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_BLACK) {
            return UserCommon.EXTENDED_HAIR_COLOR_BLACK_STR;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_BLOND) {
            return UserCommon.EXTENDED_HAIR_COLOR_BLOND_STR;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_BROWN) {
            return UserCommon.EXTENDED_HAIR_COLOR_BROWN_STR;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_LIGHT_BROWN) {
            return UserCommon.EXTENDED_HAIR_COLOR_LIGHT_BROWN_STR;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_RED) {
            return UserCommon.EXTENDED_HAIR_COLOR_RED_STR;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_GRAY) {
            return UserCommon.EXTENDED_HAIR_COLOR_GRAY_STR;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_OTHER) {
            return UserCommon.EXTENDED_HAIR_COLOR_OTHER_STR;
        }

        return '';
    }

    static getHairColorKey(hairColor: string): number {
        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_REDHEAD_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_REDHEAD;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_BALD_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_BALD;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_BLACK_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_BLACK;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_BLOND_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_BLOND;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_BROWN_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_BROWN;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_LIGHT_BROWN_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_LIGHT_BROWN;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_RED_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_RED;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_GRAY_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_GRAY;
        }

        if (hairColor === UserCommon.EXTENDED_HAIR_COLOR_OTHER_STR) {
            return UserCommon.EXTENDED_HAIR_COLOR_OTHER;
        }

        return 1;
    }

    static getChildrenStatus(childrenCount: number): string {
        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_NOT) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_NOT_STR;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_1) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_1_STR;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_2) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_2_STR;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_3) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_3_STR;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_4) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_4_STR;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_5_MORE) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_5_MORE_STR;
        }

        return '';
    }

    static getChildrenKey(childrenCount: string): number {
        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_NOT_STR) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_NOT;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_1_STR) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_1;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_2_STR) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_2;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_3_STR) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_3;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_4_STR) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_4;
        }

        if (childrenCount === UserCommon.EXTENDED_HAVE_CHILDREN_5_MORE_STR) {
            return UserCommon.EXTENDED_HAVE_CHILDREN_5_MORE;
        }

        return 1;
    }

    static getEducationStatus(education: number): string {
        if (education === UserCommon.EXTENDED_EDUCATION_HIGH_SCHOOL) {
            return UserCommon.EXTENDED_EDUCATION_HIGH_SCHOOL_STR;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_PROF_TEC) {
            return UserCommon.EXTENDED_EDUCATION_PROF_TEC_STR;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_HIGHER_CUR) {
            return UserCommon.EXTENDED_EDUCATION_HIGHER_CUR_STR;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_HIGHER_INC) {
            return UserCommon.EXTENDED_EDUCATION_HIGHER_INC_STR;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_HIGHER_COM) {
            return UserCommon.EXTENDED_EDUCATION_HIGHER_COM_STR;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_POSTGRADUATE) {
            return UserCommon.EXTENDED_EDUCATION_POSTGRADUATE_STR;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_PHD) {
            return UserCommon.EXTENDED_EDUCATION_PHD_STR;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_LIFE) {
            return UserCommon.EXTENDED_EDUCATION_LIFE_STR;
        }

        return '';
    }

    static getEducationKey(education: string): number {
        if (education === UserCommon.EXTENDED_EDUCATION_HIGH_SCHOOL_STR) {
            return UserCommon.EXTENDED_EDUCATION_HIGH_SCHOOL;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_PROF_TEC_STR) {
            return UserCommon.EXTENDED_EDUCATION_PROF_TEC;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_HIGHER_CUR_STR) {
            return UserCommon.EXTENDED_EDUCATION_HIGHER_CUR;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_HIGHER_INC_STR) {
            return UserCommon.EXTENDED_EDUCATION_HIGHER_INC;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_HIGHER_COM_STR) {
            return UserCommon.EXTENDED_EDUCATION_HIGHER_COM;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_POSTGRADUATE_STR) {
            return UserCommon.EXTENDED_EDUCATION_POSTGRADUATE;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_PHD_STR) {
            return UserCommon.EXTENDED_EDUCATION_PHD;
        }

        if (education === UserCommon.EXTENDED_EDUCATION_LIFE_STR) {
            return UserCommon.EXTENDED_EDUCATION_LIFE;
        }

        return 1;
    }

    static getIncomeStatus(income: number): string {
        if (income === UserCommon.EXTENDED_INCOME_10) {
            return UserCommon.EXTENDED_INCOME_10_STR;
        }

        if (income === UserCommon.EXTENDED_INCOME_10_30) {
            return UserCommon.EXTENDED_INCOME_10_30_STR;
        }

        if (income === UserCommon.EXTENDED_INCOME_30_50) {
            return UserCommon.EXTENDED_INCOME_30_50_STR;
        }

        if (income === UserCommon.EXTENDED_INCOME_50_70) {
            return UserCommon.EXTENDED_INCOME_50_70_STR;
        }

        if (income === UserCommon.EXTENDED_INCOME_70_100) {
            return UserCommon.EXTENDED_INCOME_70_100_STR;
        }

        if (income === UserCommon.EXTENDED_INCOME_100_150) {
            return UserCommon.EXTENDED_INCOME_100_150_STR;
        }

        if (income === UserCommon.EXTENDED_INCOME_150_500) {
            return UserCommon.EXTENDED_INCOME_150_500_STR;
        }

        if (income === UserCommon.EXTENDED_INCOME_VERY_RICH) {
            return UserCommon.EXTENDED_INCOME_VERY_RICH_STR;
        }

        return '';
    }

    static getHeight1Status(height1: any): string {
        return (UserCommon.getHeight1(height1)).toFixed(2) + 'm';
    }

    static getIncomeKey(income: string): number {
        if (income === UserCommon.EXTENDED_INCOME_10_STR) {
            return UserCommon.EXTENDED_INCOME_10;
        }

        if (income === UserCommon.EXTENDED_INCOME_10_30_STR) {
            return UserCommon.EXTENDED_INCOME_10_30;
        }

        if (income === UserCommon.EXTENDED_INCOME_30_50_STR) {
            return UserCommon.EXTENDED_INCOME_30_50;
        }

        if (income === UserCommon.EXTENDED_INCOME_50_70_STR) {
            return UserCommon.EXTENDED_INCOME_50_70;
        }

        if (income === UserCommon.EXTENDED_INCOME_70_100_STR) {
            return UserCommon.EXTENDED_INCOME_70_100;
        }

        if (income === UserCommon.EXTENDED_INCOME_100_150_STR) {
            return UserCommon.EXTENDED_INCOME_100_150;
        }

        if (income === UserCommon.EXTENDED_INCOME_150_500_STR) {
            return UserCommon.EXTENDED_INCOME_150_500;
        }

        if (income === UserCommon.EXTENDED_INCOME_VERY_RICH_STR) {
            return UserCommon.EXTENDED_INCOME_VERY_RICH;
        }

        return 1;
    }

    static getHeight1(height1: number): number {
        return height1 / 100;
    }

    static setHeight(height: string): number {
        return Math.ceil(Number(height) * 100);
    }

    static getNumberRangeList(initial: number = 0, limit: number = 100): number[] {
        const list: number[] = [];
        for (let i = 0; initial <= limit; i++, initial++) {
            list[i] = (initial / 100);
        }

        return list;
    }

    static getSmokeStatus(smoke: number): string {
        if (smoke === UserCommon.EXTENDED_SMOKE_NEVER) {
            return UserCommon.EXTENDED_SMOKE_NEVER_STR;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_RARE) {
            return UserCommon.EXTENDED_SMOKE_RARE_STR;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_SOCIAL) {
            return UserCommon.EXTENDED_SMOKE_SOCIAL_STR;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_OFTEN) {
            return UserCommon.EXTENDED_SMOKE_OFTEN_STR;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_VERY_OFTEN) {
            return UserCommon.EXTENDED_SMOKE_VERY_OFTEN_STR;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_TRY_STOP) {
            return UserCommon.EXTENDED_SMOKE_TRY_STOP_STR;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_STOP) {
            return UserCommon.EXTENDED_SMOKE_STOP_STR;
        }

        return '';
    }

    static getSmokeKey(smoke: string): number {
        if (smoke === UserCommon.EXTENDED_SMOKE_NEVER_STR) {
            return UserCommon.EXTENDED_SMOKE_NEVER;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_RARE_STR) {
            return UserCommon.EXTENDED_SMOKE_RARE;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_SOCIAL_STR) {
            return UserCommon.EXTENDED_SMOKE_SOCIAL;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_OFTEN_STR) {
            return UserCommon.EXTENDED_SMOKE_OFTEN;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_VERY_OFTEN_STR) {
            return UserCommon.EXTENDED_SMOKE_VERY_OFTEN;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_TRY_STOP_STR) {
            return UserCommon.EXTENDED_SMOKE_TRY_STOP;
        }

        if (smoke === UserCommon.EXTENDED_SMOKE_STOP_STR) {
            return UserCommon.EXTENDED_SMOKE_STOP;
        }

        return 1;
    }

    static getDrinkStatus(drink: number): string {
        if (drink === UserCommon.EXTENDED_DRINK_NEVER) {
            return UserCommon.EXTENDED_DRINK_NEVER_STR;
        }

        if (drink === UserCommon.EXTENDED_DRINK_RARE) {
            return UserCommon.EXTENDED_DRINK_RARE_STR;
        }

        if (drink === UserCommon.EXTENDED_DRINK_SOCIAL) {
            return UserCommon.EXTENDED_DRINK_SOCIAL_STR;
        }

        if (drink === UserCommon.EXTENDED_DRINK_OFTEN) {
            return UserCommon.EXTENDED_DRINK_OFTEN_STR;
        }

        if (drink === UserCommon.EXTENDED_DRINK_VERY_OFTEN) {
            return UserCommon.EXTENDED_DRINK_VERY_OFTEN_STR;
        }

        if (drink === UserCommon.EXTENDED_DRINK_TRY_STOP) {
            return UserCommon.EXTENDED_DRINK_TRY_STOP_STR;
        }

        if (drink === UserCommon.EXTENDED_DRINK_STOP) {
            return UserCommon.EXTENDED_DRINK_STOP_STR;
        }

        return '';
    }

    static getDrinkKey(drink: string): number {
        if (drink === UserCommon.EXTENDED_DRINK_NEVER_STR) {
            return UserCommon.EXTENDED_DRINK_NEVER;
        }

        if (drink === UserCommon.EXTENDED_DRINK_RARE_STR) {
            return UserCommon.EXTENDED_DRINK_RARE;
        }

        if (drink === UserCommon.EXTENDED_DRINK_SOCIAL_STR) {
            return UserCommon.EXTENDED_DRINK_SOCIAL;
        }

        if (drink === UserCommon.EXTENDED_DRINK_OFTEN_STR) {
            return UserCommon.EXTENDED_DRINK_OFTEN;
        }

        if (drink === UserCommon.EXTENDED_DRINK_VERY_OFTEN_STR) {
            return UserCommon.EXTENDED_DRINK_VERY_OFTEN;
        }

        if (drink === UserCommon.EXTENDED_DRINK_TRY_STOP_STR) {
            return UserCommon.EXTENDED_DRINK_TRY_STOP;
        }

        if (drink === UserCommon.EXTENDED_DRINK_STOP_STR) {
            return UserCommon.EXTENDED_DRINK_STOP;
        }

        return 1;
    }

    static getLifestyleBudgetStatus(lifestyleBudget: number): string {
        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_NEGOTIABLE) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_NEGOTIABLE_STR;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_MIN) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_MIN_STR;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_BASIC) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_BASIC_STR;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_MODERATE) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_MODERATE_STR;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_MEANING) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_MEANING_STR;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_HIGHER) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_HIGHER_STR;
        }

        return '';
    }

    static getLifestyleBudgetKey(lifestyleBudget: string): number {
        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_NEGOTIABLE_STR) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_NEGOTIABLE;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_MIN_STR) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_MIN;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_BASIC_STR) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_BASIC;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_MODERATE_STR) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_MODERATE;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_MEANING_STR) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_MEANING;
        }

        if (lifestyleBudget === UserCommon.EXTENDED_LIFESTYLE_BUDGET_HIGHER_STR) {
            return UserCommon.EXTENDED_LIFESTYLE_BUDGET_HIGHER;
        }

        return 1;
    }

    static getReferenceStatus(reference: number): string {
        const referenceMap: Record<number, string> = {
            [UserCommon.EXTENDED_SELECT_ONE_FRIEND]: UserCommon.EXTENDED_SELECT_ONE_FRIEND_STR,
            [UserCommon.EXTENDED_SELECT_ONE_ARTICLE]: UserCommon.EXTENDED_SELECT_ONE_ARTICLE_STR,
            [UserCommon.EXTENDED_SELECT_ONE_WHATSAPP]: UserCommon.EXTENDED_SELECT_ONE_WHATSAPP_STR,
            [UserCommon.EXTENDED_SELECT_ONE_BLOG]: UserCommon.EXTENDED_SELECT_ONE_BLOG_STR,
            [UserCommon.EXTENDED_SELECT_ONE_PRESS]: UserCommon.EXTENDED_SELECT_ONE_PRESS_STR,
            [UserCommon.EXTENDED_SELECT_ONE_TV]: UserCommon.EXTENDED_SELECT_ONE_TV_STR,
            [UserCommon.EXTENDED_SELECT_ONE_GOOGLE]: UserCommon.EXTENDED_SELECT_ONE_GOOGLE_STR,
            [UserCommon.EXTENDED_SELECT_ONE_FACEBOOK]: UserCommon.EXTENDED_SELECT_ONE_FACEBOOK_STR,
            [UserCommon.EXTENDED_SELECT_ONE_RADIO]: UserCommon.EXTENDED_SELECT_ONE_RADIO_STR,
            [UserCommon.EXTENDED_SELECT_ONE_LINKEDIN]: UserCommon.EXTENDED_SELECT_ONE_LINKEDIN_STR,
            [UserCommon.EXTENDED_SELECT_ONE_YOUTUBE]: UserCommon.EXTENDED_SELECT_ONE_YOUTUBE_STR,
            [UserCommon.EXTENDED_SELECT_ONE_INSTAGRAM]: UserCommon.EXTENDED_SELECT_ONE_INSTAGRAM_STR,
            [UserCommon.EXTENDED_SELECT_ONE_TWITTER]: UserCommon.EXTENDED_SELECT_ONE_TWITTER_STR,
            [UserCommon.EXTENDED_SELECT_ONE_TIKTOK]: UserCommon.EXTENDED_SELECT_ONE_TIKTOK_STR,
            [UserCommon.EXTENDED_SELECT_ONE_PODCAST]: UserCommon.EXTENDED_SELECT_ONE_PODCAST_STR,
            [UserCommon.EXTENDED_SELECT_ONE_OTHERS]: UserCommon.EXTENDED_SELECT_ONE_OTHERS_STR,
        };

        if (referenceMap[reference] === undefined) {
            return '';
        }

        return referenceMap[reference];
    }

    static getReferenceKey(reference: string): number {
        const referenceMap: Record<string, number> = {
            [UserCommon.EXTENDED_SELECT_ONE_FRIEND_STR]: UserCommon.EXTENDED_SELECT_ONE_FRIEND,
            [UserCommon.EXTENDED_SELECT_ONE_ARTICLE_STR]: UserCommon.EXTENDED_SELECT_ONE_ARTICLE,
            [UserCommon.EXTENDED_SELECT_ONE_WHATSAPP_STR]: UserCommon.EXTENDED_SELECT_ONE_WHATSAPP,
            [UserCommon.EXTENDED_SELECT_ONE_BLOG_STR]: UserCommon.EXTENDED_SELECT_ONE_BLOG,
            [UserCommon.EXTENDED_SELECT_ONE_PRESS_STR]: UserCommon.EXTENDED_SELECT_ONE_PRESS,
            [UserCommon.EXTENDED_SELECT_ONE_TV_STR]: UserCommon.EXTENDED_SELECT_ONE_TV,
            [UserCommon.EXTENDED_SELECT_ONE_GOOGLE_STR]: UserCommon.EXTENDED_SELECT_ONE_GOOGLE,
            [UserCommon.EXTENDED_SELECT_ONE_FACEBOOK_STR]: UserCommon.EXTENDED_SELECT_ONE_FACEBOOK,
            [UserCommon.EXTENDED_SELECT_ONE_RADIO_STR]: UserCommon.EXTENDED_SELECT_ONE_RADIO,
            [UserCommon.EXTENDED_SELECT_ONE_LINKEDIN_STR]: UserCommon.EXTENDED_SELECT_ONE_LINKEDIN,
            [UserCommon.EXTENDED_SELECT_ONE_YOUTUBE_STR]: UserCommon.EXTENDED_SELECT_ONE_YOUTUBE,
            [UserCommon.EXTENDED_SELECT_ONE_INSTAGRAM_STR]: UserCommon.EXTENDED_SELECT_ONE_INSTAGRAM,
            [UserCommon.EXTENDED_SELECT_ONE_PODCAST_STR]: UserCommon.EXTENDED_SELECT_ONE_PODCAST,
            [UserCommon.EXTENDED_SELECT_ONE_TWITTER_STR]: UserCommon.EXTENDED_SELECT_ONE_TWITTER,
            [UserCommon.EXTENDED_SELECT_ONE_TIKTOK_STR]: UserCommon.EXTENDED_SELECT_ONE_TIKTOK,
            [UserCommon.EXTENDED_SELECT_ONE_OTHERS_STR]: UserCommon.EXTENDED_SELECT_ONE_OTHERS,
        };

        if (referenceMap[reference] === undefined) {
            return UserCommon.EXTENDED_SELECT_ONE_NO_OPTION;
        }

        return referenceMap[reference];
    }

    static getNetWorthStatus(netWorth: number): string {
        if (netWorth === UserCommon.EXTENDED_NET_WORTH_100) {
            return UserCommon.EXTENDED_NET_WORTH_100_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_100_250) {
            return UserCommon.EXTENDED_NET_WORTH_100_250_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_250_500) {
            return UserCommon.EXTENDED_NET_WORTH_250_500_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_500_750) {
            return UserCommon.EXTENDED_NET_WORTH_500_750_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_750_1000) {
            return UserCommon.EXTENDED_NET_WORTH_750_1000_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_1000_2000) {
            return UserCommon.EXTENDED_NET_WORTH_1000_2000_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_2000_5000) {
            return UserCommon.EXTENDED_NET_WORTH_2000_5000_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_5000_10000) {
            return UserCommon.EXTENDED_NET_WORTH_5000_10000_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_10000_50000) {
            return UserCommon.EXTENDED_NET_WORTH_10000_50000_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_50000_100000) {
            return UserCommon.EXTENDED_NET_WORTH_50000_100000_STR;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_MORE_100000) {
            return UserCommon.EXTENDED_NET_WORTH_MORE_100000_STR;
        }

        return '';
    }

    static getNetWorthKey(netWorth: string): number {
        if (netWorth === UserCommon.EXTENDED_NET_WORTH_100_STR) {
            return UserCommon.EXTENDED_NET_WORTH_100;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_100_250_STR) {
            return UserCommon.EXTENDED_NET_WORTH_100_250;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_250_500_STR) {
            return UserCommon.EXTENDED_NET_WORTH_250_500;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_500_750_STR) {
            return UserCommon.EXTENDED_NET_WORTH_500_750;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_750_1000_STR) {
            return UserCommon.EXTENDED_NET_WORTH_750_1000;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_1000_2000_STR) {
            return UserCommon.EXTENDED_NET_WORTH_1000_2000;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_2000_5000_STR) {
            return UserCommon.EXTENDED_NET_WORTH_2000_5000;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_5000_10000_STR) {
            return UserCommon.EXTENDED_NET_WORTH_5000_10000;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_10000_50000_STR) {
            return UserCommon.EXTENDED_NET_WORTH_10000_50000;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_50000_100000_STR) {
            return UserCommon.EXTENDED_NET_WORTH_50000_100000;
        }

        if (netWorth === UserCommon.EXTENDED_NET_WORTH_MORE_100000_STR) {
            return UserCommon.EXTENDED_NET_WORTH_MORE_100000;
        }

        return 1;
    }

    static getTravelStatus(travel: number): string {
        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD_SELECT_OPTION) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD_SELECT_OPTION_STR;
        }

        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD_STR;
        }

        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_COUNTRY) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_COUNTRY_STR;
        }

        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_STATE) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_STATE_STR;
        }

        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_CITY) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_CITY_STR;
        }

        return '';
    }

    static getTravelKey(travel: string): number {
        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD_SELECT_OPTION_STR) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD_SELECT_OPTION;
        }

        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD_STR) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_WORLD;
        }

        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_COUNTRY_STR) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_COUNTRY;
        }

        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_STATE_STR) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_STATE;
        }

        if (travel === UserCommon.EXTENDED_HAPPY_TRAVEL_CITY_STR) {
            return UserCommon.EXTENDED_HAPPY_TRAVEL_CITY;
        }

        return 1;
    }

    static getOccupationStatus(occupation: number): string {
        if (occupation === UserCommon.OCCUPATION_BUSINESS_ADMINISTRATOR) {
            return UserCommon.OCCUPATION_BUSINESS_ADMINISTRATOR_STR;
        }

        if (occupation === UserCommon.OCCUPATION_ATTORNEY) {
            return UserCommon.OCCUPATION_ATTORNEY_STR;
        }

        if (occupation === UserCommon.OCCUPATION_ARCHITECT) {
            return UserCommon.OCCUPATION_ARCHITECT_STR;
        }

        if (occupation === UserCommon.OCCUPATION_ASSISTANT) {
            return UserCommon.OCCUPATION_ASSISTANT_STR;
        }

        if (occupation === UserCommon.OCCUPATION_ATTENDANT) {
            return UserCommon.OCCUPATION_ATTENDANT_STR;
        }

        if (occupation === UserCommon.OCCUPATION_AUTONOMOUS) {
            return UserCommon.OCCUPATION_AUTONOMOUS_STR;
        }

        if (occupation === UserCommon.OCCUPATION_HAIRDRESSER) {
            return UserCommon.OCCUPATION_HAIRDRESSER_STR;
        }

        if (occupation === UserCommon.OCCUPATION_CONSULTANT) {
            return UserCommon.OCCUPATION_CONSULTANT_STR;
        }

        if (occupation === UserCommon.OCCUPATION_DENTIST) {
            return UserCommon.OCCUPATION_DENTIST_STR;
        }

        if (occupation === UserCommon.OCCUPATION_COMPANY_DIRECTOR) {
            return UserCommon.OCCUPATION_COMPANY_DIRECTOR_STR;
        }

        if (occupation === UserCommon.OCCUPATION_ECONOMIST) {
            return UserCommon.OCCUPATION_ECONOMIST_STR;
        }

        if (occupation === UserCommon.OCCUPATION_EDUCATOR) {
            return UserCommon.OCCUPATION_EDUCATOR_STR;
        }

        if (occupation === UserCommon.OCCUPATION_BUSINESSMAN) {
            return UserCommon.OCCUPATION_BUSINESSMAN_STR;
        }

        if (occupation === UserCommon.OCCUPATION_NURSE) {
            return UserCommon.OCCUPATION_NURSE_STR;
        }

        if (occupation === UserCommon.OCCUPATION_ENGINEER) {
            return UserCommon.OCCUPATION_ENGINEER_STR;
        }

        if (occupation === UserCommon.OCCUPATION_ESTAGIARY) {
            return UserCommon.OCCUPATION_ESTAGIARY_STR;
        }

        if (occupation === UserCommon.OCCUPATION_AESTHETICAL) {
            return UserCommon.OCCUPATION_AESTHETICAL_STR;
        }

        if (occupation === UserCommon.OCCUPATION_STUDENT) {
            return UserCommon.OCCUPATION_STUDENT_STR;
        }

        if (occupation === UserCommon.OCCUPATION_PUBLIC_EMPLOYEE) {
            return UserCommon.OCCUPATION_PUBLIC_EMPLOYEE_STR;
        }

        if (occupation === UserCommon.OCCUPATION_IT) {
            return UserCommon.OCCUPATION_IT_STR;
        }

        if (occupation === UserCommon.OCCUPATION_JOURNALIST) {
            return UserCommon.OCCUPATION_JOURNALIST_STR;
        }

        if (occupation === UserCommon.OCCUPATION_MEDICINE) {
            return UserCommon.OCCUPATION_MEDICINE_STR;
        }

        if (occupation === UserCommon.OCCUPATION_MODEL) {
            return UserCommon.OCCUPATION_MODEL_STR;
        }

        if (occupation === UserCommon.OCCUPATION_RECEPTIONIST) {
            return UserCommon.OCCUPATION_RECEPTIONIST_STR;
        }

        if (occupation === UserCommon.OCCUPATION_SECRETARY) {
            return UserCommon.OCCUPATION_SECRETARY_STR;
        }

        if (occupation === UserCommon.OCCUPATION_SELLER) {
            return UserCommon.OCCUPATION_SELLER_STR;
        }

        if (occupation === UserCommon.OCCUPATION_OTHER) {
            return UserCommon.OCCUPATION_OTHER_STR;
        }

        return '';
    }

    static getOccupationKey(occupation: string): number {
        if (occupation === UserCommon.OCCUPATION_ATTORNEY_STR) {
            return UserCommon.OCCUPATION_ATTORNEY;
        }

        if (occupation === UserCommon.OCCUPATION_ARCHITECT_STR) {
            return UserCommon.OCCUPATION_ARCHITECT;
        }

        if (occupation === UserCommon.OCCUPATION_ASSISTANT_STR) {
            return UserCommon.OCCUPATION_ASSISTANT;
        }

        if (occupation === UserCommon.OCCUPATION_ATTENDANT_STR) {
            return UserCommon.OCCUPATION_ATTENDANT;
        }

        if (occupation === UserCommon.OCCUPATION_AUTONOMOUS_STR) {
            return UserCommon.OCCUPATION_AUTONOMOUS;
        }

        if (occupation === UserCommon.OCCUPATION_HAIRDRESSER_STR) {
            return UserCommon.OCCUPATION_HAIRDRESSER;
        }

        if (occupation === UserCommon.OCCUPATION_CONSULTANT_STR) {
            return UserCommon.OCCUPATION_CONSULTANT;
        }

        if (occupation === UserCommon.OCCUPATION_DENTIST_STR) {
            return UserCommon.OCCUPATION_DENTIST;
        }

        if (occupation === UserCommon.OCCUPATION_COMPANY_DIRECTOR_STR) {
            return UserCommon.OCCUPATION_COMPANY_DIRECTOR;
        }

        if (occupation === UserCommon.OCCUPATION_ECONOMIST_STR) {
            return UserCommon.OCCUPATION_ECONOMIST;
        }

        if (occupation === UserCommon.OCCUPATION_EDUCATOR_STR) {
            return UserCommon.OCCUPATION_EDUCATOR;
        }

        if (occupation === UserCommon.OCCUPATION_BUSINESSMAN_STR) {
            return UserCommon.OCCUPATION_BUSINESSMAN;
        }

        if (occupation === UserCommon.OCCUPATION_NURSE_STR) {
            return UserCommon.OCCUPATION_NURSE;
        }

        if (occupation === UserCommon.OCCUPATION_ENGINEER_STR) {
            return UserCommon.OCCUPATION_ENGINEER;
        }

        if (occupation === UserCommon.OCCUPATION_ESTAGIARY_STR) {
            return UserCommon.OCCUPATION_ESTAGIARY;
        }

        if (occupation === UserCommon.OCCUPATION_AESTHETICAL_STR) {
            return UserCommon.OCCUPATION_AESTHETICAL;
        }

        if (occupation === UserCommon.OCCUPATION_STUDENT_STR) {
            return UserCommon.OCCUPATION_STUDENT;
        }

        if (occupation === UserCommon.OCCUPATION_PUBLIC_EMPLOYEE_STR) {
            return UserCommon.OCCUPATION_PUBLIC_EMPLOYEE;
        }

        if (occupation === UserCommon.OCCUPATION_IT_STR) {
            return UserCommon.OCCUPATION_IT;
        }

        if (occupation === UserCommon.OCCUPATION_JOURNALIST_STR) {
            return UserCommon.OCCUPATION_JOURNALIST;
        }

        if (occupation === UserCommon.OCCUPATION_MEDICINE_STR) {
            return UserCommon.OCCUPATION_MEDICINE;
        }

        if (occupation === UserCommon.OCCUPATION_MODEL_STR) {
            return UserCommon.OCCUPATION_MODEL;
        }

        if (occupation === UserCommon.OCCUPATION_RECEPTIONIST_STR) {
            return UserCommon.OCCUPATION_RECEPTIONIST;
        }

        if (occupation === UserCommon.OCCUPATION_SECRETARY_STR) {
            return UserCommon.OCCUPATION_SECRETARY;
        }

        if (occupation === UserCommon.OCCUPATION_SELLER_STR) {
            return UserCommon.OCCUPATION_SELLER;
        }

        if (occupation === UserCommon.OCCUPATION_OTHER_STR) {
            return UserCommon.OCCUPATION_OTHER;
        }

        return 1;
    }

    static getAccTypeKey(accType: string): number {
        if (accType === UserCommon.WHAT_SUGGAR_BABY_STR) {
            return UserCommon.WHAT_SUGGAR_BABY;
        }

        if (accType === UserCommon.WHAT_SUGGAR_DADDY_MOMMY_STR) {
            return UserCommon.WHAT_SUGGAR_DADDY_MOMMY;
        }

        return 1;
    }

    static getGenderKey(gender: string): number {
        if (gender === UserCommon.GENDER_MAN_STR) {
            return UserCommon.GENDER_MAN;
        }

        if (gender === UserCommon.GENDER_WOMAN_STR) {
            return UserCommon.GENDER_WOMAN;
        }

        return 1;
    }

    static getMatchSexStatus(matchSex: number): string {
        if (matchSex === UserCommon.GENDER_MAN) {
            return UserCommon.MATCH_SEX_MAN_STR;
        }

        if (matchSex === UserCommon.GENDER_WOMAN) {
            return UserCommon.MATCH_SEX_WOMAN_STR;
        }

        if (matchSex === UserCommon.GENDER_MAN + UserCommon.GENDER_WOMAN) {
            return UserCommon.MATCH_SEX_MAN_AND_WOMAN_STR;
        }

        return '';
    }

    static getMatchSexKey(matchSex: string): number {
        if (matchSex === UserCommon.MATCH_SEX_MAN_STR) {
            return UserCommon.GENDER_MAN;
        }

        if (matchSex === UserCommon.MATCH_SEX_WOMAN_STR) {
            return UserCommon.GENDER_WOMAN;
        }

        if (matchSex === UserCommon.MATCH_SEX_MAN_AND_WOMAN_STR) {
            return UserCommon.GENDER_MAN + UserCommon.GENDER_WOMAN;
        }

        return 1;
    }

    static getTheOppositeMembershipType(membershipType: MembershipType): MembershipType {
        if (MembershipCommon.isBaby(membershipType)) {
            return MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM;
        }

        return MembershipType.MEMBERSHIP_TYPE_BABY_FREE;
    }

    static getFullStateName(code: string): string {
        return {
            AC: 'Acre',
            AL: 'Alagoas',
            AP: 'Amapa',
            AM: 'Amazonas',
            BA: 'Bahia',
            CE: 'Ceara',
            DF: 'Distrito Federal',
            ES: 'Espirito Santo',
            MS: 'Mato Grosso do Sul',
            MA: 'Maranhao',
            MT: 'Mato Grosso',
            MG: 'Minas Gerais',
            PA: 'Para',
            PB: 'Paraiba',
            PR: 'Parana',
            PI: 'Piaui',
            RJ: 'Rio de Janeiro',
            RN: 'Rio Grande do Norte',
            RS: 'Rio Grande do Sul',
            RO: 'Rondonia',
            RR: 'Roraima',
            SC: 'Santa Catarina',
            SP: 'Sao Paulo',
            SE: 'Sergipe',
            GO: 'Goias',
            PE: 'Pernambuco',
            TO: 'Tocantins',
        }[code] || code;
    }

    static getStatus(attributeName: string, attributeValue: string): any {
        return ({
            body_type: UserCommon.getBodyTypeStatus,
            height1: UserCommon.getHeight1,
            drink: UserCommon.getDrinkStatus,
            relationship: UserCommon.getRelationshipStatus,
            ethnicity: UserCommon.getEthnicityStatus,
            eye_color: UserCommon.getEyeColorStatus,
            hair_color: UserCommon.getHairColorStatus,
            occupation: UserCommon.getOccupationStatus,
            have_children: UserCommon.getChildrenStatus,
            education: UserCommon.getEducationStatus,
            income: UserCommon.getIncomeStatus,
            smoke: UserCommon.getSmokeStatus,
            lifestyle_budget: UserCommon.getLifestyleBudgetStatus,
            arrangement_type: UserCommon.getBodyTypeKey,
            net_worth: UserCommon.getNetWorthStatus,
            select_one: UserCommon.getReferenceStatus,
            happy_to_travel: UserCommon.getTravelStatus,
        }[attributeName])(attributeValue);
    }

    static getAttrBehaveList(): IAttrBehaveList {
        return {
            match_sex: {
                status: UserCommon.getMatchSexStatus,
                key: UserCommon.getMatchSexKey,
            },
            body_type: {
                status: UserCommon.getBodyTypeStatus,
                key: UserCommon.getBodyTypeKey,
            },
            height1: {
                status: UserCommon.getHeight1Status,
                key: null,
            },
            drink: {
                status: UserCommon.getDrinkStatus,
                key: UserCommon.getDrinkKey,
            },
            relationship: {
                status: UserCommon.getRelationshipStatus,
                key: UserCommon.getRelationshipKey,
            },
            ethnicity: {
                status: UserCommon.getEthnicityStatus,
                key: UserCommon.getEthnicityKey,
            },
            eye_color: {
                status: UserCommon.getEyeColorStatus,
                key: UserCommon.getEyeColorKey,
            },
            hair_color: {
                status: UserCommon.getHairColorStatus,
                key: UserCommon.getHairColorKey,
            },
            occupation: {
                status: UserCommon.getOccupationStatus,
                key: UserCommon.getOccupationKey,
            },
            have_children: {
                status: UserCommon.getChildrenStatus,
                key: UserCommon.getChildrenKey,
            },
            education: {
                status: UserCommon.getEducationStatus,
                key: UserCommon.getEducationKey,
            },
            income: {
                status: UserCommon.getIncomeStatus,
                key: UserCommon.getIncomeKey,
            },
            smoke: {
                status: UserCommon.getSmokeStatus,
                key: UserCommon.getSmokeKey,
            },
            arrangement_type: {
                status: UserCommon.getBodyTypeKey,
                key: UserCommon.getBodyTypeKey,
            },
            net_worth: {
                status: UserCommon.getNetWorthStatus,
                key: UserCommon.getNetWorthKey,
            },
            happy_to_travel: {
                status: UserCommon.getTravelStatus,
                key: UserCommon.getTravelKey,
            },
        };
    }

    static getImageUnavailable(): string {
        return '/assets/img/undefined-user.gif';
    }

    static getEllipseUrl(gender: number, type: number): ISugarEllipse {
        const imageUrl = `/assets/img/ellipse/${UserCommon.getEllipseType(gender, type)}`;
        return {
            firstUrl: `${imageUrl}-1.png`,
            secondUrl: `${imageUrl}-2.png`,
            thirdUrl: `${imageUrl}-3.png`,
        };
    }

    static getEllipseType(gender: number, type: number): string {
        if (gender === UserCommon.GENDER_MAN && type === UserCommon.WHAT_SUGGAR_DADDY_MOMMY) {
            return 'Daddy';
        }

        if (gender === UserCommon.GENDER_WOMAN && type === UserCommon.WHAT_SUGGAR_DADDY_MOMMY) {
            return 'Mommy';
        }

        if (gender === UserCommon.GENDER_WOMAN && type === UserCommon.WHAT_SUGGAR_BABY) {
            return 'Fbaby';
        }

        return 'Mbaby';
    }

    static getProfileType(user: UserCommon): string {
        const profilePreference: ISugarPreference = UserCommon.getProfilePreference(user);

        if (MembershipCommon.isDaddyMommyType(user.membership_type_id)) {
            return profilePreference.daddy;
        }

        return profilePreference.baby;
    }

    static getUserJoinDays(joinDate: number): number {
        return Math.round((Date.now() - joinDate * 1000) / (1000 * 60 * 60 * 24));
    }

    static getProfilePreference(user: UserCommon): ISugarPreference {
        if (UserCommon.isFemaleGay(user)) {
            return {
                daddy: 'modules.main.pages.profile-unavailable.find_another_baby_female',
                baby: 'modules.main.pages.profile-unavailable.find_another_mommy',
            };
        }

        if (UserCommon.isFemaleStraight(user)) {
            return {
                daddy: 'modules.main.pages.profile-unavailable.find_another_baby_male',
                baby: 'modules.main.pages.profile-unavailable.find_another_daddy',
            };
        }

        if (UserCommon.isMaleGay(user)) {
            return {
                daddy: 'modules.main.pages.profile-unavailable.find_another_baby_male',
                baby: 'modules.main.pages.profile-unavailable.find_another_daddy',
            };
        }

        if (UserCommon.isFemale(user) && UserCommon.isBisexual(user)) {
            return {
                daddy: 'modules.main.pages.profile-unavailable.find_another_baby_male',
                baby: 'modules.main.pages.profile-unavailable.find_another_daddy',
            };
        }

        return {
            daddy: 'modules.main.pages.profile-unavailable.find_another_baby_female',
            baby: 'modules.main.pages.profile-unavailable.find_another_mommy',
        };
    }

    static getWhatIsSugarExp(gender: number, type: number): IExplanationSugar {
        if (gender === UserCommon.GENDER_MAN && type === UserCommon.WHAT_SUGGAR_DADDY_MOMMY) {
            return {
                firstParagraph: 'modules.frictionless.what_is_sugar_daddy_1',
                secondParagraph: 'modules.frictionless.what_is_sugar_daddy_2',
                thirdParagraph: 'modules.frictionless.what_is_sugar_daddy_3',
            };
        }

        if (gender === UserCommon.GENDER_WOMAN && type === UserCommon.WHAT_SUGGAR_DADDY_MOMMY) {
            return {
                firstParagraph: 'modules.frictionless.what_is_sugar_mommy_1',
                secondParagraph: 'modules.frictionless.what_is_sugar_mommy_2',
                thirdParagraph: 'modules.frictionless.what_is_sugar_mommy_3',
            };
        }

        if (gender === UserCommon.GENDER_WOMAN && type === UserCommon.WHAT_SUGGAR_BABY) {
            return {
                firstParagraph: 'modules.frictionless.what_is_sugar_baby_female_1',
                secondParagraph: 'modules.frictionless.what_is_sugar_baby_female_2',
                thirdParagraph: 'modules.frictionless.what_is_sugar_baby_female_3',
            };
        }

        return {
            firstParagraph: 'modules.frictionless.what_is_sugar_baby_male_1',
            secondParagraph: 'modules.frictionless.what_is_sugar_baby_male_2',
            thirdParagraph: 'modules.frictionless.what_is_sugar_baby_male_3',
        };
    }

    static photoToVerify(user: UserCommon | undefined): IPhoto | undefined {
        if (user === undefined) {
            return undefined;
        }

        return arrayFind([
            ...(user.public_album || []),
            ...(user.private_album || []),
        ], (photo): boolean => photo.verify_photo !== UserCommon.PICTURE_UNVERIFIED);
    }

    static hasPendingOrVerifiedPhotos(user: UserCommon | undefined): boolean {
        return UserCommon.isBaby(user) &&
            (UserCommon.isPhotoVerified(user) ||
                UserCommon.photoToVerify(user) !== undefined);
    }

    static isOnline(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            user.online_count >= UserCommon.USER_STATUS_ONLINE;
    }

    static isNew(user: IBaseProfile | undefined): boolean {
        return user !== undefined &&
            user.new_count >= 1;
    }

    static hasEmailVerified(user: UserCommon | undefined): boolean {
        return user !== undefined &&
            user.email_verified === UserCommon.EMAIL_VERIFIED_YES;
    }

    static daddyHasPendingEmailVerification(user: UserCommon | undefined): boolean {
        return UserCommon.isDaddyMommy(user) &&
            !UserCommon.hasEmailVerified(user);
    }

    static getMainIcon(user: IProfile | undefined): string {
        if (UserCommon.isElite(user)) {
            return UserCommon.ICON_ELITE;
        }

        if (UserCommon.isPremium(user)) {
            return UserCommon.ICON_PREMIUM;
        }

        if (UserCommon.isPhotoVerified(user)) {
            return UserCommon.ICON_VERIFIED;
        }

        return '';
    }

    static isValidDescription(description: string): boolean {
        if (description === null || description === undefined || description === '') {
            return false;
        }

        return description.trim().length >= 6;
    }

    static isValidHeadline(headline: string): boolean {
        if (headline === null || headline === undefined || headline === '') {
            return false;
        }

        return headline.trim().length >= 4;
    }

    static resolveImageName(imageName: string, suffix: string = '.jpg'): string {
        const prefix = '/assets/img/';

        return prefix + imageName + suffix;
    }

    static getName(profile: IProfile, translate: TranslateService): string {
        if (profile === undefined) {
            return '';
        }

        const formatted_username = translate.instant('modules.main.pages.profile.formatted_username', {
            username: profile.username,
        });

        if (profile.age) {
            return translate.instant('modules.main.pages.profile.username_and_age', {
                formatted_username,
                age: profile.age,
            });
        }

        return formatted_username;
    }

    static findProfileByProfileId(profiles: IProfile[], profileId: number): IProfile {
        return profiles.find((profile: IProfile): boolean =>
            profile.profile_id === profileId,
        );
    }

    static isPaymentTypeExpress(type: number): boolean {
        return type === UserCommon.TYPE_EXPRESS_APPROVAL_PAYMENT;
    }

    static isProfileEdit(profile: IBaseProfile): boolean {
        return profile.status === UserCommon.STATUS_PROFILE_EDIT;
    }

    static isEligibleToSubscribe(profile: UserCommon): boolean {
        return !this.isProfileEdit(profile) && !this.isUserCategory4(profile);
    }

    isValidPassword(): boolean {
        return UserCommon.isValidPassword(this.password);
    }

    isValidUserName(): boolean {
        return UserCommon.isValidUserName(this.email, true);
    }

    static isUserCategory1(user: UserCommon): boolean {
        return user.profiletype === this.USER_CATEGORY_1;
    }

    static isUserCategory3(user: UserCommon): boolean {
        return user.profiletype === this.USER_CATEGORY_3;
    }

    static isUserCategory4(user: UserCommon): boolean {
        return user.profiletype === this.USER_CATEGORY_4;
    }

    static getPhotoVerificationStatus(user: UserCommon): string {
        const allPhotos: IPhoto[] = [...user.public_album, ...user.private_album];

        if (!UserCommon.hasAnyVerifiedPhoto(allPhotos)) {
            return 'false';
        }

        const verifiedPhoto: IPhoto | undefined = this.getPhotoToCheck(allPhotos);

        if (verifiedPhoto === undefined) {
            return 'false';
        }

        return this.getVerificationPhotoCurrentStatus(verifiedPhoto);
    }

    static getPhotoToCheck(photos: IPhoto[]): IPhoto | undefined {
        return photos.find((photo: IPhoto): boolean => {
            return photo.verify_photo === 1 || photo.verify_photo === 2;
        });
    }

    static hasAnyVerifiedPhoto(photos: IPhoto[]): boolean {
        return photos.some((photo: IPhoto): boolean => {
            return photo.verify_photo === 1 || photo.verify_photo === 2;
        });
    }

    static getVerificationPhotoCurrentStatus(verificationPhoto: IPhoto): string {
        const photoVerificationStatusMap: IPhotoVerificationStatus = {
            ['active']: 'true',
            ['approval']: 'pending',
        };
        const photoStatus: string = verificationPhoto.status;

        if (
            !Object.keys(photoVerificationStatusMap).includes(photoStatus)
        ) {
            return '';
        }

        return photoVerificationStatusMap[photoStatus];
    }
}
