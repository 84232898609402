import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'navigation-button',
    templateUrl: 'navigation-button.html',
    styleUrls: ['navigation-button.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationButtonComponent {
    @Input() public isPageActive: boolean;
    @Input() public routeToNavigate: string[] = [''];
    @Input() public id: string = '';
    @Input() public text: string = '';

    protected readonly ACTIVE_PAGE_BUTTON_MODIFIER_CLASS: string = 'tab-bar-button__link--active';
    protected readonly ACTIVE_PAGE_BORDER_TOP_MODIFIER_CLASS: string = 'tab-bar-button__border-top--active';

    public getNavigationButtonModifierClass(): string {
        if (this.isPageActive) {
            return this.ACTIVE_PAGE_BUTTON_MODIFIER_CLASS;
        }

        return '';
    }

    public getBorderTopModifierClass(): string {
        if (this.isPageActive) {
            return this.ACTIVE_PAGE_BORDER_TOP_MODIFIER_CLASS;
        }

        return '';
    }
}
