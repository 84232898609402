import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ILoginState } from '@libs/store/login/interfaces/login-state.interface';

const selectFeature = createFeatureSelector<ILoginState>('login');

export const selectForgotPasswordSendingRequest = createSelector(
    selectFeature,
    (state: ILoginState): boolean => {
        return state.sendingRequest;
    },
);

export const selectForgotPasswordEmailSent = createSelector(
    selectFeature,
    (state: ILoginState): boolean => {
        return state.emailSent;
    },
);

export const selectForgotPasswordEmailNotFound = createSelector(
    selectFeature,
    (state: ILoginState): boolean => {
        return state.emailNotFound;
    },
);
