import { createAction, props } from '@ngrx/store';

import { MembershipType } from '@libs/shared/membership/membership.common';

export const SET_LATEST_PAID_MEMBERSHIP: string = '[Membership] - Set Latest Paid Membership Action';

export const LATEST_PAID_MEMBERSHIP_CHANGE_SUCCESSFULLY: string = '[Membership] - Latest Paid Membership Change Successfully Action';

export const setLatestPaidMembership = createAction(
    SET_LATEST_PAID_MEMBERSHIP,
    props<{ latestPaidMembership: MembershipType }>(),
);

export const latestPaidMembershipChange = createAction(
    LATEST_PAID_MEMBERSHIP_CHANGE_SUCCESSFULLY,
);
