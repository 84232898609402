import { HttpErrorResponse } from '@angular/common/http';
import { Directive, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { CouponServiceCommon } from '@libs/modules/main/services/coupon.service.common';
import { CouponCommon } from '@libs/shared/coupon/coupon.common';
import { IStats } from '@libs/shared/user/stats';
import { IApplicationState } from '@libs/store/application-state';

export interface IHttpResponseBody {
    error: string;
}

@Directive()
export abstract class CouponComponentCommon implements OnDestroy {
    public couponCode: string = '';
    protected userProfileId: number;
    protected subscriptions: Subscription[] = [];
    protected errorMessage: string = '';
    protected loading: boolean = false;

    constructor(
        protected store: Store<IApplicationState>,
        protected couponService: CouponServiceCommon,
    ) {
        this.subscriptions.push(this.store.select('stats').subscribe((stats: IStats): void => {
            if (!stats.profile_id) {
                return;
            }

            this.userProfileId = stats.profile_id;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    abstract afterRedeem(data: any): void;

    abstract afterRedeemError(errorMessage: string): void;

    abstract showValidationMessage(): void;

    public getErrorMessage(message: string): string {
        this.errorMessage = message;
        return this.errorMessage;
    }

    public redeemFail(): boolean {
        return this.getErrorMessage(this.errorMessage) !== '';
    }

    public isLoading(): boolean {
        return this.loading;
    }

    public redeem(): void {
        if (!this.couponCode) {
            this.showValidationMessage();
            return;
        }

        this.loading = true;

        this.couponService.redeem(this.couponCode).subscribe((response): void => {
            this.loading = false;
            this.afterRedeem(response.data);
        }, (response: HttpErrorResponse): void => {
            this.loading = false;
            this.couponCode = '';

            if (response.status === 422 && response.error) {
                const errTag = CouponCommon.getTranslateResponse(response.error.error);
                this.afterRedeemError(errTag);
            }
        });
    }
}
