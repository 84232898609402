import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';

import { Alert, AlertType } from '@prince/modules/main/modals/alert/alert.interfaces';
import { AlertService } from '@prince/services/alert.service';

@Component({
    selector: 'mp-alert',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './alert.html',
})
export class AlertComponent implements OnInit {
    alerts: Alert[] = [];

    constructor(@Inject(AlertService) public alertService: AlertService) {
        //
    }

    ngOnInit(): void {
        this.alertService.getAlert().subscribe((alert: Alert): void => {
            if (!alert) {
                this.alerts = [];
                return;
            }

            this.alerts.push(alert);
        });
    }

    removeAlert(alert: Alert): void {
        this.alerts = this.alerts.filter((x): boolean => x !== alert);
    }

    cssClass(alert: Alert): string {
        if (!alert) {
            return '';
        }

        switch (alert.type) {
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
            case AlertType.Success:
            default:
                return 'alert alert-success';
        }
    }
}
