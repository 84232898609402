import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

import { EffectInitializerCommon } from '@libs/effects/effect-initializer.common';

import { AuthenticationService } from '@prince/services/authentication.service';

@Injectable()
export class EffectInitializer extends EffectInitializerCommon {
    constructor(
        protected readonly actions$: Actions,
        protected readonly auth: AuthenticationService,
    ) {
        super(
            actions$,
            auth,
        );
    }
}
