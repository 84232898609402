<div class="generic-header chat-header white-bg">
    <div>
        <div class="info">
            <div>
                <mp-actionbar-back-button class="visible-xs visible-sm"></mp-actionbar-back-button>
            </div>
            <div>
                <mp-actionbar-message-receive
                    [receiver]="receiver"
                    [currentConversation]="currentConversation"
                    [isRecipientWriting]="isRecipientWriting"
                ></mp-actionbar-message-receive>
            </div>
        </div>
        <div class="header-options">
            <mp-actionbar-three-dots
                [options]="threeDotsMenu"
                *ngIf="threeDotsMenu"
            ></mp-actionbar-three-dots>
        </div>
    </div>
</div>
