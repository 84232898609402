import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

enum Actions {
    UPSERT_PROFILE = '[View Toast] - Upsert Profile in Toast Array',
    REMOVE_ALL_PROFILES = '[View Toast] - Remove all Profiles of the Toast Array',
}

export const upsertProfile = createAction(
    Actions.UPSERT_PROFILE,
    props<{ profile: IProfile }>(),
);

export const removeAllProfiles = createAction(Actions.REMOVE_ALL_PROFILES);
