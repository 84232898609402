<div
    class="subscribed-status"
    [class.extended]="!isTinyBadges()"
>
    <ng-container *ngIf="isFree(); else userBadge">
        <mp-subscribe-button
            [hideOnMediumDown]="isTinyBadges()"
            (click)="closeSlidableMenu()"
        ></mp-subscribe-button>
    </ng-container>
    <ng-template #userBadge>
        <mp-membership-badge (click)="openSlidableMenu()" *ngIf="isTinyBadges(); else badgesWithText"></mp-membership-badge>
        <ng-template #badgesWithText>
            <mp-membership-badge-extended></mp-membership-badge-extended>
        </ng-template>
    </ng-template>
</div>
