import { Directive } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { IApplicationState } from '@libs/store/application-state';
import { hasPaymentOptions } from '@libs/store/payment/selectors';
import { closeSlidableMenu } from '@libs/store/slidable-menu/action';

@Directive()
export abstract class PlansButtonBabyComponentCommon {
    constructor(
        protected store: Store<IApplicationState>,
    ) {
        //
    }

    abstract navigateToUpgradeAccount(): void;

    canUpgrade$(): Observable<boolean> {
        return this.store.pipe(
            select(hasPaymentOptions),
            first(),
        ); ;
    }

    closeSlidableMenu(): void {
        this.store.dispatch(closeSlidableMenu());
    }
}
