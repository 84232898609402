import { Action, createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as FavoriteToastActions from '@libs/store/favorite-toast/actions';
import {
    favoriteToastAdapter,
    favoriteToastInitialState,
    IFavoriteToastState,
} from '@libs/store/favorite-toast/state';

const favoriteToastReducer = createReducer(
    favoriteToastInitialState,
    on(
        FavoriteToastActions.upsertProfile,
        (
            state: IFavoriteToastState,
            { profile }: { profile: IProfile },
        ): IFavoriteToastState => {
            return favoriteToastAdapter.upsertOne(profile, state);
        },
    ),
    on(
        FavoriteToastActions.removeAllProfiles,
        (state: IFavoriteToastState): IFavoriteToastState => {
            return favoriteToastAdapter.removeAll(state);
        },
    ),
);

export function reducer(
    state: IFavoriteToastState,
    action: Action,
): IFavoriteToastState {
    return favoriteToastReducer(state, action);
}
