import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
} from '@ngrx/entity';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IPaymentInfo } from '@libs/store/payment-info/interface';

export interface IPaymentInfoState extends EntityState<IPaymentInfo> {
    paymentStatus?: PaymentStatus;
    isPaying: boolean;
    hasResolvedPlans: boolean;
    boletoUrl: string;
}

function selectPaymentInfoId(paymentInfo: IPaymentInfo): number {
    return paymentInfo.fin_sale_id;
}

export const paymentInfoAdapter: EntityAdapter<IPaymentInfo> = createEntityAdapter<IPaymentInfo>({
    selectId: selectPaymentInfoId,
});

export const paymentInfoInitialState: IPaymentInfoState = paymentInfoAdapter.getInitialState({
    isPaying: false,
    hasResolvedPlans: false,
    boletoUrl: '',
});
