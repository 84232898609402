import { Directive, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, ParamMap } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import {
    concatMap,
    map,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import {
    PaymentMethod, PaymentMethods,
} from '@libs/modules/main/pages/payment/payment-methods';
import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import {
    EXPRESS_APPROVAL_ID,
} from '@libs/modules/main/services/payment/payment.service.common';
import { TrialServiceCommon } from '@libs/modules/main/services/trial/trial.service.common';
import {
    MembershipCommon,
} from '@libs/shared/membership/membership.common';
import { InvalidProduct } from '@libs/shared/product/implementations/invalid/invalid';
import { IProduct } from '@libs/shared/product/product.interface';
import { IBaseProfile } from '@libs/shared/profile/profile';
import { Trial } from '@libs/shared/trial/trial';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoActions, PaymentInfoSelectors, PaymentSelectors } from '@libs/store/payment-info';
import {
    selectOptionsByMembershipAndPeriod,
    selectHasBoletoPaymentType,
    selectHasCreditCardPaymentType,
} from '@libs/store/payment/selectors';
import { UpgradeAccountScreenSelectors } from '@libs/store/upgrade-account';

@Directive()
export abstract class PaymentComponentCommon implements OnDestroy {
    public price$: Observable<number> = of(0);
    public product: IProduct = new InvalidProduct();
    public plan: string = '';
    public period: number = 0;
    public user: UserCommon | undefined;
    public userOriginResolved: boolean = false;
    public currentPaymentMethod: PaymentMethod = PaymentMethods.PAYMENT_NONE;
    public readonly PAYMENT_CARD: PaymentMethod = PaymentMethods.PAYMENT_CREDIT_CARD;
    public readonly PAYMENT_BOLETO: PaymentMethod = PaymentMethods.PAYMENT_BOLETO;
    protected readonly expressApprovalPrice: number = 249;
    protected readonly PREMIUM_PRICE_ONE_MONTH: number = 299;
    protected readonly PRODUCT_ROUTE_DATA_KEY: string = 'product';
    public hasLoadedPaymentOptions$: Observable<boolean> = of(false);
    public hasResolvedPlans$: Observable<boolean> = of(false);
    public isResolvingPlans$: Observable<boolean> = of(false);
    public installments: number = 0;

    protected subscriptions: Subscription[] = [];

    constructor(
        protected store: Store<IApplicationState>,
        protected trialService: TrialServiceCommon,
        protected translate: TranslateService,
        protected route: ActivatedRoute,
    ) {
        this.subscriptions.push(
            this.store.select('user').subscribe({
                next: (user: UserCommon | undefined): void => {
                    if (user === undefined) {
                        return;
                    }

                    this.user = user;
                    this.userOriginResolved = true;
                },
            }),
        );
        this.hasLoadedPaymentOptions$ = this.store.pipe(
            select(PaymentSelectors.hasLoadedPaymentOptions),
        );
        this.hasResolvedPlans$ = this.store.pipe(
            select(PaymentInfoSelectors.selectHasResolvedPlans),
        );
        this.isResolvingPlans$ = this.store.pipe(
            select(UpgradeAccountScreenSelectors.selectIsResolvingVisiblePlans),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    isExpressApproval(): boolean {
        return this.product.satisfied(EXPRESS_APPROVAL_ID);
    }

    isDaddyMommy(): boolean {
        return MembershipCommon.isDaddyMommyType(this.product.identifier);
    }

    loadPrice(): void {
        this.price$ = this.getPriceByMembershipAndPeriod$().pipe(
            map((price: number | undefined): number => {
                if (this.isExpressApproval()) {
                    return this.expressApprovalPrice;
                }

                if (this.isTrial()) {
                    return this.getTrialPrice();
                }

                if (price === undefined) {
                    return 0;
                }

                return price;
            }),
        );
    }

    receiveParams(): void {
        this.subscriptions.push(
            this.route.paramMap.subscribe({
                next: (parameters: ParamMap): void => {
                    this.period = +parameters.get('period');

                    const productId: string = parameters.get('productId');

                    this.store.dispatch(
                        PaymentInfoActions.handleAccessToCheckout({
                            planPath: this.period.toString(),
                            variantUuid: productId,
                        }),
                    );

                    this.receiveProductFromRoute();
                    this.handleProductPlanString();
                    this.handleExpressApprovalPaymentType();
                    this.handleTrialPaymentType();
                    this.loadPrice();
                    this.handleDefaultPaymentMethod();
                },
            }),
        );
    }

    receiveProductFromRoute(): void {
        this.subscriptions.push(
            this.route.data.subscribe({
                next: (routeData: Data): void => {
                    this.handleProductReceivedFromRoute(routeData);
                },
            }),
        );
    }

    handleDefaultPaymentMethod(): void {
        this.subscriptions.push(
            this.canShowCardPaymentMethod$(this.user).pipe(
                withLatestFrom(this.canShowBoletoPayment$()),
                tap({
                    next: ([
                        canShowCardPaymentMethod,
                        canShowBoletoPaymentMethod,
                    ]: [boolean, boolean]): void => {
                        this.handleSetPaymentMethodOnInitialization(
                            canShowCardPaymentMethod,
                            canShowBoletoPaymentMethod,
                        );
                    },
                }),
            ).subscribe(),
        );
    }

    handleSetPaymentMethodOnInitialization(
        canShowCardPaymentMethod: boolean,
        canShowBoletoPayment: boolean,
    ): void {
        if (!canShowCardPaymentMethod && !canShowBoletoPayment) {
            this.changePaymentType(PaymentMethods.PAYMENT_NONE);
            return;
        }

        if (canShowCardPaymentMethod) {
            this.changePaymentType(PaymentMethods.PAYMENT_CREDIT_CARD);
            return;
        }

        this.changePaymentType(PaymentMethods.PAYMENT_BOLETO);
    }

    handleProductReceivedFromRoute(routeData: Data): void {
        if (!(this.PRODUCT_ROUTE_DATA_KEY in routeData)) {
            return;
        }

        this.product = routeData.product;
    }

    handleProductPlanString(): void {
        let productType: string = 'express_approval';

        if (!this.isExpressApproval()) {
            productType = MembershipCommon.getMachineMembership(this.product.identifier) + '_member';
        }

        this.plan = this.translate.instant(`modules.main.pages.payment.product_types.${productType}`);
    }

    handleExpressApprovalPaymentType(): void {
        if (this.isUserElectiveToCardPayment(this.user)) {
            return;
        }

        this.changePaymentType(PaymentMethods.PAYMENT_BOLETO);
    }

    handleTrialPaymentType(): void {
        if (!this.isTrial()) {
            return;
        }

        this.changePaymentType(PaymentMethods.PAYMENT_CREDIT_CARD);
    }

    changePaymentType(paymentOption: string): void {
        if (PaymentMethods.getAvailablePaymentMethods()[paymentOption] === undefined) {
            return;
        }

        if (paymentOption === PaymentMethods.PAYMENT_BOLETO) {
            this.openChatbox();
        }

        this.currentPaymentMethod = PaymentMethods.getAvailablePaymentMethods()[paymentOption];
    }

    public abstract openChatbox(): void;

    getPriceByMembershipAndPeriod$(): Observable<number | undefined> {
        return this.store.pipe(
            select(selectOptionsByMembershipAndPeriod(
                this.product.identifier,
                this.period,
            )),
        ).pipe(
            map((paymentOption) => paymentOption?.price || 0),
        );
    }

    isTrial(): boolean {
        return this.trialService.hasSomeTrial() &&
            PaymentCommon.isTrialPayment(
                this.product.identifier,
                this.period,
            );
    }

    canShowCardPaymentMethod$(user: UserCommon) {
        return this.store.pipe(
            select(selectHasCreditCardPaymentType),
        ).pipe(
            concatMap((hasCreditCardPaymentType: boolean): Observable<boolean> => {
                return of(
                    hasCreditCardPaymentType &&
                    this.isUserElectiveToCardPayment(user),
                );
            }),
        );
    }

    canShowBoletoPayment$(): Observable<boolean> {
        return this.store.pipe(
            select(selectHasBoletoPaymentType),
        ).pipe(
            concatMap((hasBoletoPayment: boolean): Observable<boolean> => {
                return of(
                    hasBoletoPayment &&
                    !this.isTrial(),
                );
            }),
        );
    }

    isUserElectiveToCardPayment(user: UserCommon | undefined): boolean {
        return user !== undefined && !this.isBabyMaleExpressApproval(user);
    }

    isBabyMaleExpressApproval(user: IBaseProfile): boolean {
        return (
            this.isExpressApproval() &&
            UserCommon.isBaby(user) &&
            UserCommon.isMale(user)
        );
    }

    getTrialPrice(): number {
        if (Trial.getEnabledTrial(this.period) === null) {
            return 0;
        }

        return Trial.getEnabledTrial(this.period).price.toNumber();
    }

    getPlanDescription(): string {
        return this.translate.instant('modules.main.pages.payment.plan_description', {
            plan: this.plan,
            duration: this.getPlanDuration(),
        });
    }

    getPlanDuration(): string {
        if (this.isTrial()) {
            return this.translate.instant('modules.main.pages.payment.plan_duration.trial', {
                trialDays: this.trialDuration,
            });
        }

        if (this.isExpressApproval()) {
            return '';
        }

        return this.translate.instant('modules.main.pages.payment.duration', { duration: this.period });
    }

    protected get trialDuration(): number {
        if (Trial.getEnabledTrial(this.period) === null) {
            return 0;
        }

        return Trial.getEnabledTrial(this.period).duration;
    }

    public getPlanPrice(price: number): string {
        if (this.isTrial()) {
            return this.translate.instant('modules.main.pages.payment.price.trial', {
                price,
            });
        }

        return this.translate.instant('modules.main.pages.payment.price', {
            price: this.getPlanConditionsPrice(price),
            period: this.getPeriod(price),
        });
    }

    public getPeriod(price: number) : number {
        let period: number = this.period;

        if (this.installments === price) {
            period = 1;
        }

        if (this.isExpressApproval()) {
            period = EXPRESS_APPROVAL_ID;
        }

        return period;
    }

    public getEmitCardSelector(event: number) : void{
        this.installments = event;
    }

    public getPlanConditionsPrice(price: number): number {
        if (this.installments === 0) {
            this.installments = price;
        }

        if (this.isTrial()) {
            return this.getTrialPrice();
        }

        return this.installments;
    }

    public getPlanConditions(price: number): string {
        if (this.isExpressApproval()) {
            return '';
        }

        if (this.isTrial()) {
            return this.translate.instant(
                'modules.main.pages.payment.trial_renewal',
            );
        }

        return this.translate.instant(
            'modules.main.pages.payment.renewal',
            {
                plan: this.plan,
                period: this.getPlanDuration(),
                price: this.getPlanConditionsPrice(price),
            },
        );
    }
}
