import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lineBreaker',
})
export class LineBreaker implements PipeTransform {
    transform(value: string): string {
        if (value === undefined || value === null) {
            return '';
        }

        return value.replace(/(?:\\[r])+/g, '').replace(/(?:\\[n])+/g, '\n');
    }
}
