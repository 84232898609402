import { IUnsafeAction } from '@libs/store/application-state';
import { PREMIUM_SETTINGS_CHANGED_ACTION } from '@libs/store/ui/actions/premium-settings.action';
import { INITIAL_PREMIUM_SETTINGS, IPremiumSettings } from '@libs/store/ui/premium-settings';

export function premiumSettings(state: IPremiumSettings = { ...INITIAL_PREMIUM_SETTINGS }, action: IUnsafeAction): IPremiumSettings {
    switch (action.type) {
        case PREMIUM_SETTINGS_CHANGED_ACTION:
            return Object.assign({ }, state, action.payload);
        default:
            return state;
    }
}
