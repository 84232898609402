<div class="generic-modal">
    <div class="generic-modal__header">
        <h2 class="generic-modal__header__title">
            {{ getModalTitle() | translate }}
        </h2>
        <div
            class="generic-modal__header__close-button"
            (click)="closeModal()"
        >
            <i class="material-icons">
                &#xE5CD;
            </i>
        </div>
    </div>
    <div class="generic-modal__content">
        <div class="generic-modal__content__text">
            {{ getTextContent() | translate }}
        </div>
        <div class="generic-modal__content__button-container">
            <button
                #mainButton
                class="generic-modal__content__button"
                (click)="closeCallback()"
            >
                {{ getButtonText() | translate }}
            </button>
        </div>
    </div>
</div>
