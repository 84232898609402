import { Observable, of } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import {
    MembershipCommon,
    MembershipType,
} from '@libs/shared/membership/membership.common';
import { Trial } from '@libs/shared/trial/trial';
import { IPaymentInfo } from '@libs/store/payment-info';

export enum PaymentStatus {
    PAYMENT_NONE,
    PAYMENT_AWAITING,
    PAYMENT_OK,
    PAYMENT_PROCESSING,
    PAYMENT_ERROR,
}

export interface ILastPayment {
    fin_sale_id: number;
    amount: number;
    period: number;
    membership_type_id: MembershipType;
    order_stamp: number;
}

export abstract class PaymentCommon {
    static readonly PAYMENT_METHOD_CREDIT_CARD: string = 'CREDIT_CARD';
    static readonly PAYMENT_METHOD_BOLETO: string = 'BOLETO';

    static readonly PAYMENT_BILL_DESCRIPTION_MP = 'MEUPAT';
    static readonly PAYMENT_BILL_DESCRIPTION_PRINCE = 'MEUPRI';

    static readonly PROVIDER_ALLCASH: string = 'allcash';
    static readonly PROVIDER_PAGSEGURO: string = 'PagSeguro';

    static readonly ALLCASH_EXTERNAL_IDENTIFIER: string = 'c66fa130-394b-43d9-a928-f2fc7385af02' as const;
    static readonly PAGSEGURO_EXTERNAL_IDENTIFIER: string = '1cf08282-99fa-4348-86c6-c3f2d461fa6b' as const;

    static readonly CURRENCY_BRAZILIAN_REAL: string = 'BRL';
    static readonly UPGRADE: string = 'common.upgrade';
    static readonly SUBSCRIBE: string = 'common.subscribe';

    static readonly DEFAULT_CPF: string = '06503410743';

    static isTrialPayment(
        membershipType: MembershipType,
        paymentTime: number,
    ): boolean {
        return MembershipCommon.isDaddyMommyPremiumType(membershipType) &&
            Trial.getEnabledTrial(paymentTime) !== null;
    }

    static getSubscribeOrUpgrade(
        membershipType: MembershipType | undefined,
        variant$: Observable<number>,
    ): Observable<any> {
        return variant$.pipe(
            take(1),
            concatMap((variant: number): Observable<string> => {
                if (variant === 1 && MembershipCommon.isDaddyMommyPremiumType(membershipType)) {
                    return of(this.UPGRADE);
                }

                return of(this.SUBSCRIBE);
            }),
        );
    }

    public static findMostRecentPayment(payments: IPaymentInfo[]): IPaymentInfo {
        return payments.reduce(
            (
                previousPayment: IPaymentInfo,
                currentPayment: IPaymentInfo,
            ): IPaymentInfo => {
                if (
                    previousPayment !== undefined &&
                    currentPayment !== undefined &&
                    previousPayment.fin_sale_id > currentPayment.fin_sale_id
                ) {
                    return previousPayment;
                }

                return currentPayment;
            },
        );
    }

    public static hasCreditCardPaymentType(paymentTypes: string[]): boolean {
        return paymentTypes.includes(PaymentMethods.PAYMENT_CREDIT_CARD);
    }

    public static hasBoletoPaymentType(paymentTypes: string[]): boolean {
        return paymentTypes.includes(PaymentMethods.PAYMENT_BOLETO);
    }

    public static hasPaymentTypesAvailable(paymentTypes: string[]): boolean {
        return Object.keys(paymentTypes).length > 0;
    }

    public static isPaymentForAPlusPackage(paymentInfo: IPaymentInfo): boolean {
        const A_PLUS_PACKAGE_PRICE: number = 300;

        return paymentInfo.subtotal_amount !== undefined &&
            paymentInfo.subtotal_amount === A_PLUS_PACKAGE_PRICE;
    }

    public static getLatestPayment(
        paymentCollection: IPaymentInfo[],
    ): IPaymentInfo | null {
        if (paymentCollection.length === 0) {
            return null;
        }

        return paymentCollection.reduce(
            (previousInfo: IPaymentInfo, currentInfo: IPaymentInfo): IPaymentInfo => {
                if (previousInfo.fin_sale_id > currentInfo.fin_sale_id) {
                    return previousInfo;
                }

                return currentInfo;
            },
        );
    }

    public static shouldKeepPollingPaymentHistory(
        paymentStatus: PaymentStatus,
        latestPaymentOnStore: IPaymentInfo,
        latestPaymentOnRemote: IPaymentInfo,
    ): boolean {
        const isRemoteHistoryEmptyOrCorrupted = !latestPaymentOnRemote || !latestPaymentOnRemote.fin_sale_id;
        const isRemoteHistoryOutdated = latestPaymentOnStore &&
            latestPaymentOnStore.fin_sale_id &&
            latestPaymentOnRemote &&
            latestPaymentOnRemote.fin_sale_id &&
            latestPaymentOnStore.fin_sale_id === latestPaymentOnRemote.fin_sale_id &&
            paymentStatus === PaymentStatus.PAYMENT_PROCESSING;
        const isBoleto = latestPaymentOnRemote.payment_type === 'boleto';

        if (isRemoteHistoryEmptyOrCorrupted || isRemoteHistoryOutdated || isBoleto) {
            return true;
        }

        return false;
    }
}
