<div class="subscribe-button-container">
    <button
        id="subscribe-button__button"
        class="subscribe-button-container__button"
        type="button"
        [ngClass]="getButtonModifierClass()"
        [routerLink]="['/main', 'upgrade-account']"
        (click)="closeSlidableMenu()"
    >
        {{ getButtonText() | translate }}
    </button>
</div>
