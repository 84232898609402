import { Directive, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IConversation } from '@libs/store/conversations';

@Directive()
export abstract class ActionbarMessageReceiveComponentCommon {
    public readonly ICON_ELITE: string = UserCommon.ICON_ELITE;
    public readonly ICON_PREMIUM: string = UserCommon.ICON_PREMIUM;
    public readonly ICON_VERIFIED: string = UserCommon.ICON_VERIFIED;

    @Input() receiver: IProfile | undefined;
    @Input() currentConversation: IConversation;
    @Input() isRecipientWriting: boolean;

    constructor(
        protected auth: AuthenticationServiceCommon,
        protected translate: TranslateService,
        protected profileService: ProfileServiceCommon,
    ) {
        //
    }

    abstract getReceiverPicture(): string;

    isLiked(): boolean {
        return this.profileService.isProfileFavorite(this.receiver);
    }

    isReceiverOnline(): boolean {
        return UserCommon.isOnline(this.receiver);
    }

    getReceiverName(): string {
        return UserCommon.getName(this.receiver, this.translate);
    }

    getReceiverLocation(): string {
        return IProfile.getLocation(this.receiver, this.translate);
    }
}
