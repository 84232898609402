import { Injectable } from '@angular/core';

import { IBabyViewersBaselines } from '@libs/modules/boost/services/profile-viewers-baseline/interfaces/baby-viewers-baseline.interface';
import { IDaddyViewersBaselines } from '@libs/modules/boost/services/profile-viewers-baseline/interfaces/daddy-viewers-baseline.interface';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { UserCommon } from '@libs/shared/user/user.common';

@Injectable({
    providedIn: 'root',
})
export class ProfileViewersBaselineServiceCommon {
    protected readonly daddyBaselines: IDaddyViewersBaselines = {
        SP: {
            premiumAccount: 7.4,
            eliteAccount: 73,
        },
        RJ: {
            premiumAccount: 7.3,
            eliteAccount: 72.6,
        },
        MG: {
            premiumAccount: 7.1,
            eliteAccount: 72.9,
        },
        PR: {
            premiumAccount: 7.9,
            eliteAccount: 73.9,
        },
        RS: {
            premiumAccount: 7.3,
            eliteAccount: 73.1,
        },
        SC: {
            premiumAccount: 8.1,
            eliteAccount: 74.1,
        },
        DF: {
            premiumAccount: 7.1,
            eliteAccount: 72.2,
        },
        GO: {
            premiumAccount: 7.8,
            eliteAccount: 73.7,
        },
        BA: {
            premiumAccount: 6.9,
            eliteAccount: 72.9,
        },
        PE: {
            premiumAccount: 6.8,
            eliteAccount: 72.0,
        },
        general: {
            premiumAccount: 7,
            eliteAccount: 60,
        },
    };
    protected readonly babyBaselines: IBabyViewersBaselines = {
        SP: {
            freeAccount: 6.8,
            premiumAccount: 13.4,
        },
        RJ: {
            freeAccount: 6.4,
            premiumAccount: 12.4,
        },
        MG: {
            freeAccount: 6,
            premiumAccount: 12.8,
        },
        PR: {
            freeAccount: 6.4,
            premiumAccount: 11.9,
        },
        RS: {
            freeAccount: 5.8,
            premiumAccount: 11.7,
        },
        SC: {
            freeAccount: 5.4,
            premiumAccount: 11.2,
        },
        DF: {
            freeAccount: 6.1,
            premiumAccount: 13,
        },
        GO: {
            freeAccount: 5.8,
            premiumAccount: 11.2,
        },
        BA: {
            freeAccount: 6.2,
            premiumAccount: 12.1,
        },
        PE: {
            freeAccount: 5.9,
            premiumAccount: 11.6,
        },
        general: {
            freeAccount: 5,
            premiumAccount: 20,
        },
    };

    public getProfileViewersBaseline(user: UserCommon): number {
        if (!UserCommon.isBaby(user)) {
            return this.getDaddyViewersBaseline(user, this.daddyBaselines);
        }

        return this.getBabyViewersBaseline(user, this.babyBaselines);
    }

    public getDaddyViewersBaseline(user: UserCommon, baselines: IDaddyViewersBaselines): number {
        const userState: string = user.stateName ?? '';

        if (!this.isUserFromKeyState(userState, baselines) && !UserCommon.isElite(user)) {
            return baselines.general.premiumAccount;
        }

        if (!this.isUserFromKeyState(userState, baselines) && UserCommon.isElite(user)) {
            return baselines.general.eliteAccount;
        }

        if (this.isUserFromKeyState(userState, baselines) && !UserCommon.isElite(user)) {
            return baselines[userState].premiumAccount;
        }

        return baselines[userState].eliteAccount;
    }

    public getBabyViewersBaseline(user: UserCommon, baselines: IBabyViewersBaselines): number {
        const userState: string = user.stateName ?? '';

        if (!this.isUserFromKeyState(userState, baselines) && MembershipCommon.isFree(user.membership_type_id)) {
            return baselines.general.freeAccount;
        }

        if (!this.isUserFromKeyState(userState, baselines) && !MembershipCommon.isFree(user.membership_type_id)) {
            return baselines.general.premiumAccount;
        }

        if (this.isUserFromKeyState(userState, baselines) && MembershipCommon.isFree(user.membership_type_id)) {
            return baselines[userState].freeAccount;
        }

        return baselines[userState].premiumAccount;
    }

    protected isUserFromKeyState(
        state: string,
        baselines: IBabyViewersBaselines | IDaddyViewersBaselines,
    ): boolean {
        return state in baselines;
    }
}
