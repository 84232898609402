import { Action } from '@ngrx/store';

import { ISearch } from '@libs/shared/search/search';

export const SEARCH_ACTION: string = 'SEARCH_ACTION';
export const REMOVE_SEARCH_ACTION: string = 'REMOVE_SEARCH_ACTION';
export const SET_CURRENT_SEARCH_NAME_ACTION: string = 'SET_CURRENT_SEARCH_NAME_ACTION';
export const DISPLAYING_SEARCH_RESULT_ACTION: string = 'DISPLAYING_SEARCH_RESULT_ACTION';
export const SAVED_SEARCH_RECEIVED_ACTION: string = 'SAVED_SEARCH_RECEIVED_ACTION';

export interface ISavedSearchReceivedActionPayload {
    savedSearches: any[];
}

export class SearchAction implements Action {
    readonly type: string = SEARCH_ACTION;

    constructor(public payload: ISearch) {
        //
    }
}

export class RemoveSearchAction implements Action {
    readonly type: string = REMOVE_SEARCH_ACTION;

    constructor(public payload: number) {
        //
    }
}

export class SetCurrentSearchNameAction implements Action {
    readonly type: string = SET_CURRENT_SEARCH_NAME_ACTION;

    constructor(public payload: string) {
        //
    }
}

export class DisplayingSearchResultAction implements Action {
    readonly type: string = DISPLAYING_SEARCH_RESULT_ACTION;

    constructor(public payload: boolean) {
        //
    }
}

export class SavedSearchReceivedAction implements Action {
    readonly type: string = SAVED_SEARCH_RECEIVED_ACTION;

    constructor(public payload: ISavedSearchReceivedActionPayload) {
        //
    }
}
