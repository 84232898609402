import { createFeatureSelector, createSelector } from '@ngrx/store';

import { INamedSearch } from '@libs/shared/search/search';
import { ISearchState } from '@libs/store/search/interface/search-state.interface';
import { SearchModalTabs } from '@libs/store/search/types/search-modal-tabs.type';

const selectFeature = createFeatureSelector<ISearchState>('searchState');

export const selectScrollYPosition = createSelector(
    selectFeature,
    (state: ISearchState): number => state.scrollYPosition,
);

export const selectIsSavingSearch = createSelector(
    selectFeature,
    (state: ISearchState): boolean => {
        return state.isSavingSearch;
    },
);

export const selectActiveSearchModalTab = createSelector(
    selectFeature,
    (state: ISearchState): SearchModalTabs => {
        return state.searchModalActiveTab;
    },
);

export const selectHasUpdateFailed = createSelector(
    selectFeature,
    (state: ISearchState): boolean => {
        return state.hasUpdateFailed;
    },
);

export const selectHasErrorOcurred = createSelector(
    selectFeature,
    (state: ISearchState): boolean => {
        return state.hasErrorOccurred;
    },
);

export const selectIsDeletingSearch = createSelector(
    selectFeature,
    (state: ISearchState): boolean => {
        return state.isDeletingSearch;
    },
);

export const selectSavedSearches = createSelector(
    selectFeature,
    (state: ISearchState): INamedSearch[] => {
        return state.savedSearches;
    },
);
