import {
    PROFILES_ID_PRIVATE_PHOTO_GAVE_ACCESS_RECEIVED_ACTION,
    PROFILES_ID_PRIVATE_PHOTO_REMOVE_ACCESS_RECEIVED_ACTION,
} from '@libs/store/profiles/actions/private-photo-gave.action';

export function storeProfilesPrivatePhotoIGaveAccess(
    state: number[] = [],
    action: any,
): number[] {
    switch (action.type) {
        case PROFILES_ID_PRIVATE_PHOTO_GAVE_ACCESS_RECEIVED_ACTION:
            return onProfilesIdPrivatePhotoIGaveAccessReceived(state, action.payload.profileIds);
        case PROFILES_ID_PRIVATE_PHOTO_REMOVE_ACCESS_RECEIVED_ACTION:
            return onProfilesIdPrivatePhotoIRemoveAccessReceived(state, action.payload.profileIds);
        default:
            return state;
    }
}

function onProfilesIdPrivatePhotoIGaveAccessReceived(state: number[], profileIds: number[]): number[] {
    for (const profileId of profileIds) {
        state = onProfileIdPrivatePhotoIGaveAccessReceived(state, profileId);
    }
    return state;
}

function onProfileIdPrivatePhotoIGaveAccessReceived(state: number[], newProfileId: number): number[] {
    const index: number = state.indexOf(newProfileId);
    if (index !== -1) {
        return state;
    }

    return [...state, newProfileId];
}

function onProfilesIdPrivatePhotoIRemoveAccessReceived(state: number[], profileIds: number[]): number[] {
    for (const profileId of profileIds) {
        state = onProfileIdPrivatePhotoIRemoveAccessReceived(state, profileId);
    }

    return state;
}

function onProfileIdPrivatePhotoIRemoveAccessReceived(state: number[], newProfileId: number): number[] {
    const index: number = state.indexOf(newProfileId);
    if (index === -1) {
        return state;
    }

    return [
        ...state.slice(0, index),
        ...state.slice(index + 1),
    ];
}
