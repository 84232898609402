import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalService } from '@prince/services/modal.service';

@Component({
    selector: 'mp-help-tooltip',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './help-tooltip.html',
})
export class HelpTooltipComponent {
    @Input() title: string;
    @Input() content: string;

    constructor(
        protected modalService: ModalService,
    ) {
        //
    }

    showInfo(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.modalService.open(this.content);
    }
}
