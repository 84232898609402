import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    ActionbarCallToActionComponentCommon,
} from '@libs/modules/shared/actionbar/actionbar-call-to-action/actionbar-call-to-action.component.common';

@Component({
    selector: 'mp-actionbar-call-to-action',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './actionbar-call-to-action.html',
})
export class ActionbarCallToActionComponent extends ActionbarCallToActionComponentCommon {
    //
}
