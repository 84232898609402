import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
    UserButtonsBoostComponentCommon,
} from '@libs/modules/main/shared/user-buttons-boost/user-buttons-boost.component.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

@Component({
    selector: 'mp-user-buttons-boost',
    templateUrl: './user-buttons-boost.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class UserButtonsBoostComponent extends UserButtonsBoostComponentCommon {
    constructor(
        protected router: Router,
        protected store: Store<IApplicationState>,
    ) {
        super(store);
    }

    getBoostButtonIcon(): string {
        return UserCommon.resolveImageName('boost-icon', '.svg');
    }

    navigateToBoost(): void {
        this.closeSlidableMenu();
        this.router.navigate(['main', 'boost']);
    }
}
