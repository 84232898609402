import { Directive } from '@angular/core';

import {
    AuthenticationServiceCommon,
} from '@libs/services/authentication/authentication.service.common';

@Directive()
export abstract class UserAvatarComponentCommon {
    constructor(
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    abstract goToProfile(): void;

    abstract getUserPicture(): string;

    getUserName(): string {
        return this.auth.get().username;
    }
}
