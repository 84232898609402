import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { UserCommon } from '@libs/shared/user/user.common';

@Component({
    selector: 'mp-blocked-notifications-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './blocked-notifications-modal.html',
})
export class BlockedNotificationsModalComponent implements IModalComponent {
    public data: never;
    public readonly FIREFOX_BROWSER_NAME: string = 'Firefox';
    public readonly EDGE_BROWSER_NAME: string = 'Edge';
    public readonly IE_BROWSER_NAME: string = 'Trident';
    public readonly CHROME_BROWSER_NAME: string = 'Chrome';
    public readonly SAFARI_BROWSER_NAME: string = 'Safari';

    constructor(
        protected modalRef: ModalRef,
    ) {
        //
    }

    public getUserAgent(): string {
        return navigator.userAgent;
    }

    public getBrowserName(): string {
        const getAgent = this.getUserAgent();

        if (getAgent.indexOf(this.FIREFOX_BROWSER_NAME) !== -1) {
            return this.FIREFOX_BROWSER_NAME;
        }

        if (getAgent.indexOf(this.EDGE_BROWSER_NAME) !== -1 || getAgent.indexOf(this.IE_BROWSER_NAME) !== -1) {
            return this.EDGE_BROWSER_NAME;
        }

        if (getAgent.indexOf(this.CHROME_BROWSER_NAME) !== 1) {
            return this.CHROME_BROWSER_NAME;
        }

        if (getAgent.indexOf(this.SAFARI_BROWSER_NAME) !== -1) {
            return this.SAFARI_BROWSER_NAME;
        }

        return this.CHROME_BROWSER_NAME;
    }

    public getBrowserImage(): string {
        return UserCommon.resolveImageName(this.getBrowserName());
    }

    public getBrowserSupportLink(): string {
        const browser = this.getBrowserName();

        if (browser === this.FIREFOX_BROWSER_NAME) {
            return 'https://mzl.la/20jwSoU';
        }

        if (browser === this.EDGE_BROWSER_NAME) {
            return 'https://support.microsoft.com/help/4028678/windows-10-change-notification-settings';
        }

        if (browser === this.CHROME_BROWSER_NAME) {
            return 'https://support.google.com/chrome/answer/3220216';
        }

        if (browser === this.SAFARI_BROWSER_NAME) {
            return 'https://support.apple.com/guide/safari/sfri40734/mac';
        }

        return 'https://support.google.com/chrome/answer/3220216';
    }

    public closeModal(): void {
        this.modalRef.close();
    }
}
