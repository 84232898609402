import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';

import {
    DownloadManagerServiceCommon,
} from '@libs/modules/main/services/download-manager.service.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { ToastType } from '@libs/shared/toast/interfaces/toast-parameters.interface';
import { IApplicationState } from '@libs/store/application-state';
import { PushNotificationActions } from '@libs/store/push-notification';
import { ToastActions } from '@libs/store/toast';

@Injectable({
    providedIn: 'root',
})
export abstract class MeltServiceCommon implements OnDestroy {
    protected subscriptions: Subscription[] = [];

    constructor(
        protected store: Store<IApplicationState>,
        protected authHttp: AuthHttpServiceCommon,
        protected downloadManager: DownloadManagerServiceCommon,
    ) {
        //
    }

    abstract watchMelt(): void;

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
    }

    getBulkProfiles$(profileIdsToLoad: number[]): Observable<IAuthResponse | { }> {
        if (profileIdsToLoad.length === 0) {
            return of({ });
        }

        return this.downloadManager.resetAndUpdate('bulkProfiles', profileIdsToLoad);
    }

    addMeltPushNotification(meltId: number): void {
        this.store.dispatch(PushNotificationActions.createProfilePush({
            profileId: meltId,
            parameters: {
                title: 'modules.main.services.notification.melt.title',
                options: {
                    body: 'modules.main.services.notification.melt.body',
                },
                extraParameters: {
                    route: [
                        'main',
                        'profile',
                        meltId,
                    ],
                },
            },
        }));
    }

    handleMeltToast(userMeltId: number): void {
        this.store.dispatch(ToastActions.initializeNewToast({
            profileId: userMeltId,
            toastType: ToastType.typeMelt,
        }));
    }
}
