import { createAction, props } from '@ngrx/store';

import { IReferral } from '@libs/shared/referral/referral';

export const LOAD_REFERRALS_ACTION: string =
    '[Referral] - Load Referrals Action';

export const UPSERT_MANY_REFERRALS_ACTION: string =
    '[Referral] - Upsert Many Referrals Action';

export const loadReferrals = createAction(
    LOAD_REFERRALS_ACTION,
);

export const upsertManyReferrals = createAction(
    UPSERT_MANY_REFERRALS_ACTION,
    props<{ referrals: IReferral[] }>(),
);
