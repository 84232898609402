import { TrialRule } from '@libs/shared/trial/rules/base/trial.rule';
import { UserCommon } from '@libs/shared/user/user.common';

export class TrialRuleConversation extends TrialRule {
    user: UserCommon | undefined;

    protected readonly conversationQuantitiesToTrial: number[] = [2, 7];

    constructor(
        user: UserCommon,
    ) {
        super();
        this.user = user;
    }

    satisfied(): boolean {
        return this.user !== undefined &&
            this.conversationQuantitiesToTrial.indexOf(
                this.user.conversations_qty,
            ) !== -1;
    }
}
