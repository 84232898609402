import { Component, OnInit } from '@angular/core';
import { FormBuilder, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { PagSeguroCardComponentCommon } from '@libs/modules/main/pages/payment-card/pagseguro-card/pagseguro-card.component.common';
import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { FormHelpers } from '@libs/modules/registration/form-helpers/form-helpers';
import { CardNumberValidatons, CPFValidations, CVVValidations, DateValidations, HolderValidations, InstallmentValidations } from '@libs/services/payment/validators';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoActions } from '@libs/store/payment-info';

import { MoipService } from '@prince/modules/main/services/payment/moip/moip.service';
import { PaymentInfoService } from '@prince/modules/main/services/payment/payment-info.service';
import { ModalService } from '@prince/services/modal.service';
import { UserService } from '@prince/services/user.service';

@Component({
    selector: 'mp-moip-card',
    templateUrl: './moip-card.html',
    styleUrls: ['./moip-card.scss'],
})
export class MoipCardComponent extends PagSeguroCardComponentCommon implements OnInit {
    public readonly IS_ISSUED_IN_BRAZIL: string = 'isIssuedInBrazil';

    constructor(
        protected modalService: ModalService,
        protected paymentService: MoipService,
        protected translate: TranslateService,
        protected store: Store<IApplicationState>,
        protected formBuilder: FormBuilder,
        protected userService: UserService,
        protected paymentInfoService: PaymentInfoService,
        protected router: Router,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        super(
            translate,
            store,
            formBuilder,
            userService,
            paymentInfoService,
            router,
            paymentHelpersService,
        );
    }

    ngOnInit(): void {
        this.paymentGroup = this.formBuilder.group(
            {
                [this.HOLDER]: ['', HolderValidations.validations.validation],
                [this.CARD_NUMBER]: ['', CardNumberValidatons.validations.validation],
                [this.DATE]: ['', DateValidations.validations.validation],
                [this.CVV]: ['', CVVValidations.validations.validation],
                [this.CPF]: ['', CPFValidations.validations.validation],
                [this.IS_ISSUED_IN_BRAZIL]: [true],
                [this.INSTALLMENTS]: [
                    this.getFirstOption(),
                    InstallmentValidations.validations.validation,
                ],
            },
            {
                validators: CVVValidations.validateCvv,
            },
        );
    }

    getFirstOption(): number {
        return this.FIRST_INSTALLMENT_OPTION;
    }

    goBack(): void {
        if (!this.isExpressApproval()) {
            this.router.navigate(['main', 'home'], { replaceUrl: true });
            return;
        }

        this.router.navigate(['express-approval'], { replaceUrl: true });
    }

    public toggleIsIssuedInBrazil(): void {
        if (!this.isIssuedInBrazil()) {
            this.checkIsIssuedInBrazil();

            return;
        }

        this.uncheckIsIssuedInBrazil();
    }

    protected checkIsIssuedInBrazil(): void {
        this.paymentGroup.get(this.CPF).setValue(this.previousCPF);
        this.paymentGroup.get(this.CPF).setValidators(
            <ValidatorFn[]>(CPFValidations.validations.validation),
        );
        this.paymentGroup.get(this.CPF).updateValueAndValidity();
        this.checkCPF();
        this.updateInstallments(this.previousInstallmentsOption);
    }

    protected uncheckIsIssuedInBrazil(): void {
        this.previousCPF = this.getCPF();
        this.paymentGroup.get(this.CPF).setValue(PaymentCommon.DEFAULT_CPF);
        this.paymentGroup.get(this.CPF).setValidators(null);
        this.paymentGroup.get(this.CPF).updateValueAndValidity();
        this.checkCPF();
        this.saveSelectedInstallmentsOption();
        this.updateInstallments(this.ONE_INSTALLMENT);
    }

    public isIssuedInBrazil(): boolean {
        return FormHelpers.getFormControlValue(
            this.paymentGroup,
            this.IS_ISSUED_IN_BRAZIL,
        );
    }

    public canSubmit(): boolean {
        return this.paymentGroup.valid;
    }

    public handleDisabledInstallments(index: number): boolean {
        if (!this.isIssuedInBrazil()) {
            return index !== this.ONE_INSTALLMENT;
        }

        return this.onlyFirstOptionDisabled(index);
    }

    public getCardErrorMessage(): string {
        if (this.validCardNumber) {
            return '';
        }

        return this.getErrorMessage(this.CARD_NUMBER);
    }

    public getDateErrorMessage(): string {
        if (this.validDate) {
            return '';
        }

        return this.getErrorMessage(this.DATE);
    }

    public getCVVErrorMessage(): string {
        if (this.validCVV) {
            return '';
        }

        return this.getErrorMessage(this.CVV);
    }

    public getHolderNameErrorMessage(): string {
        if (this.validHolder) {
            return '';
        }

        return this.getErrorMessage(this.HOLDER);
    }

    public getCPFErrorMessage(): string {
        if (this.validCPF) {
            return '';
        }

        return this.getErrorMessage(this.CPF);
    }

    public makePayment(): void {
        this.store.dispatch(PaymentInfoActions.setIsPaying({
            isPaying: true,
        }));
    }
}
