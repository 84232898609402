<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <form
                class="row"
                id="modalForm"
                (submit)="disableAccountSubmit()"
                *ngIf="isJoinDaysHighEnough()"
            >
                <div class="col-xs-12">
                    <h4 class="bold">
                        {{ 'modules.main.pages.visibility.disable_title' | translate }}
                    </h4>
                    <p class="text-break-line">
                        {{ 'modules.main.pages.visibility.disable_blurb' | translate }}
                    </p>
                </div>
                <div class="form-group col-xs-12">
                    <select
                        class="form-control"
                        [(ngModel)]="disableReason"
                        [ngModelOptions]="{ standalone: true }"
                    >
                        <option
                            value="-1"
                            disabled
                            selected="selected"
                        >
                            {{ 'modules.main.pages.visibility.disable.validation.reason' | translate }}
                        </option>
                        <option
                            *ngFor="let reason of reasons$ | async"
                            [(ngValue)]="reason.value"
                        >
                            {{ ('modules.main.pages.visibility.disable_reasons.' + reason.label) | translate }}
                        </option>
                    </select>
                </div>
                <div
                    class="form-group col-xs-12"
                    *ngIf="hasSelectedDisableReason()"
                >
                    <textarea
                        class="form-control"
                        rows="4"
                        placeholder="{{ 'modules.main.pages.visibility.disable.validation.description' | translate }}"
                        [(ngModel)]="disableDescription"
                        [ngModelOptions]="{ standalone: true }"
                    ></textarea>
                </div>
                <div class="col-xs-12">
                    <p>
                        <small
                            class="text-break-line"
                            *ngIf="isDaddyMommy"
                        >
                            {{ 'modules.main.pages.visibility.disable_blurb.payment' | translate }}
                        </small>
                    </p>
                    <p>
                        <small class="text-break-line">
                            {{ 'modules.main.pages.visibility.disable_blurb.implications' | translate }}
                        </small>
                    </p>
                </div>
            </form>
            <div class="row disable-text">
                <div
                    class="col-xs-12"
                    *ngIf="!isJoinDaysHighEnough()"
                >
                    <h4 class="bold">
                        {{ 'modules.main.pages.visibility.disable_not_allowed_title' | translate }}
                    </h4>
                    <p class="text-break-line">
                        {{ 'modules.main.pages.visibility.disable_not_allowed_blurb' | translate }}
                    </p>
                    <p class="text-blurb">
                        <mp-advanced-translate key="modules.main.pages.visibility.disable_not_allowed_blurb2">
                            <a
                                [routerLink]="['/main', 'settings', 'customer-support']"
                                mp-translate-param="support"
                            >
                                {{ 'modules.main.pages.visibility.disabled_support' | translate }}
                            </a>
                        </mp-advanced-translate>
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-default"
                type="button"
                (click)="closeModal()"
            >
                {{ 'common.cancel' | translate }}
            </button>
            <button
                class="btn btn-danger"
                type="submit"
                form="modalForm"
                *ngIf="isJoinDaysHighEnough()"
            >
                {{ 'modules.main.pages.visibility.disable_account' | translate }}
            </button>
        </div>
    </div>
</div>
