import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { BoostMultiplierComponentCommon } from '@libs/modules/boost/components/boost-multiplier/boost-multiplier.component.common';
import { IApplicationState } from '@libs/store/application-state';

@Component({
    selector: 'mp-boost-multiplier',
    templateUrl: './boost-multiplier.html',
})
export class BoostMultiplierComponent extends BoostMultiplierComponentCommon {
    constructor(
        protected store: Store<IApplicationState>,
    ) {
        super(
            store,
        );
    }
}
