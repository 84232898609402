import { createAction } from '@ngrx/store';

export enum Actions {
    INITIALIZE_REDIRECT_FLOW = '[Redirect] - Initialize Redirect Flow',
    REDIRECT_BY_PROFILE_STATUS = '[Redirect] - Redirect By Profile Status',
    RESOLVE_QUERY_PARAMS = '[Redirect] - Resolve Query Params',
}

export const initializeRedirectFlow = createAction(
    Actions.INITIALIZE_REDIRECT_FLOW,
);

export const redirectByStatus = createAction(
    Actions.REDIRECT_BY_PROFILE_STATUS,
);

export const resolveQueryParams = createAction(
    Actions.RESOLVE_QUERY_PARAMS,
);
