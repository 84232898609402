import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IThreeDotsMenuItem } from '@libs/shared/interfaces/three-dots-menu-item.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { IConversation } from '@libs/store/conversations';

@Component({
    selector: 'mp-message-header',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './message-header.html',
})
export class MessageHeaderComponent {
    @Input() receiver: IProfile | undefined;
    @Input() currentConversation: IConversation;
    @Input() isRecipientWriting: boolean;
    @Input() threeDotsMenu: IThreeDotsMenuItem;

    constructor() {
        //
    }
}
