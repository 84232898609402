import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BoostLastViewersServiceCommon } from '@libs/modules/boost/services/boost-last-viewers/boost-last-viewers.service.common';
import { IProfile } from '@libs/shared/profile/profile';
import { IBoostLastViewersState } from '@libs/store/boost-last-viewers';
import { boostLastViewersAdapter } from '@libs/store/boost-last-viewers/state';

const selectFeature = createFeatureSelector<IBoostLastViewersState>('boostLastViewers');

export const selectAll = createSelector(
    selectFeature,
    boostLastViewersAdapter.getSelectors().selectAll,
);

export const selectProfilesForBubbles = createSelector(
    selectAll,
    (
        profiles: IProfile[],
        props: { boostLastViewersService: BoostLastViewersServiceCommon },
    ): IProfile[] => {
        return props.boostLastViewersService.getProfilesForBubbles(profiles);
    },
);
