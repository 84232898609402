<div class="tab-bar-button">
    <div
        class="tab-bar-button__border-top"
        [ngClass]="getBorderTopModifierClass()"
    ></div>
    <a
        class="tab-bar-button__link"
        [id]="id"
        [ngClass]="getNavigationButtonModifierClass()"
        [routerLink]="routeToNavigate"
    >
        <ng-content></ng-content>
        <p>
            {{ text | translate }}
        </p>
    </a>
</div>
