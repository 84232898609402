import { Params, Router } from '@angular/router';

import { ComponentNames } from '@libs/effects/toast/common/component-names';
import { IProfile } from '@libs/shared/profile/profile';
import { IToastComponent } from '@libs/shared/toast/interfaces/toast-component.interface';
import { ToastType } from '@libs/shared/toast/interfaces/toast-parameters.interface';

import { Memoize } from 'typescript-memoize';

export class ToastCommon {
    static defaultToastTypes: readonly ToastType[] = Object.freeze([
        ToastType.typeFavorite,
        ToastType.typeMessage,
        ToastType.typeView,
    ]);

    static customToastTypes: readonly ToastType[] = Object.freeze([
        ToastType.typeMelt,
        ToastType.typeBoost,
    ]);

    @Memoize()
    static findProfile(profileId: number, profiles: IProfile[]): IProfile | undefined {
        return profiles.find(
            (profile: IProfile): boolean => profile.profile_id === profileId,
        );
    }

    static isToastDefaultType(toastType: ToastType): boolean {
        return ToastCommon.defaultToastTypes.includes(toastType);
    }

    static isCustomToastType(toastType: ToastType): boolean {
        return ToastCommon.customToastTypes.includes(toastType);
    }

    static isDefaultToastComponentName(component: IToastComponent): boolean {
        return component.componentName === ComponentNames.DEFAULT_TOAST_COMPONENT;
    }

    static navigate(router: Router, route: unknown[], queryParameters: Params): void {
        router.navigate(route, {
            queryParams: queryParameters,
        });
    }
}
