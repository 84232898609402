import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { closeSlidableMenu } from '@libs/store/slidable-menu/action';

@Component({
    selector: 'slidable-menu-item',
    styleUrls: ['slidable-menu-item.scss'],
    templateUrl: 'slidable-menu-item.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlidableMenuItemComponent {
    @Input() description: string[] = [];
    @Input() id: string = '';

    constructor(
        protected store: Store,
    ) {
        //
    }

    public closeSlidableMenu(): void {
        this.store.dispatch(closeSlidableMenu());
    }
}
