import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { UiActions } from '@libs/store/new-ui';

import {
    ReactiveModalAnimations,
    STATES_STR,
} from '@prince/components/reactivate-modal/reactivate-modal.animation';
import { AuthenticationService } from '@prince/services/authentication.service';
import { UserService } from '@prince/services/user.service';

interface IModalData {
    token: string;
}

enum STATES {
    STATE_QUESTION,
    STATE_PROCESSING,
    STATE_FINISHED,
}

@Component({
    selector: 'mp-reactivate-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './reactivate-modal.html',
    animations: ReactiveModalAnimations,
    styleUrls: ['./reactivate-modal.scss'],
})
export class ReactivateModalComponent implements IModalComponent<IModalData> {
    state: number = STATES.STATE_QUESTION;
    animating: boolean = false;

    constructor(
        @Inject(MODAL_DATA) public data: IModalData,
        protected modalRef: ModalRef,
        protected router: Router,
        protected auth: AuthenticationService,
        protected userService: UserService,
        protected store: Store,
    ) {
        //
    }

    closeModal(): void {
        this.modalRef.close();
        this.setIsAttemptingLoginAsFalse();
    }

    getState(): string {
        if (this.state === STATES.STATE_QUESTION) {
            return STATES_STR.QUESTION;
        }

        if (this.state === STATES.STATE_PROCESSING) {
            return STATES_STR.PROCESSING;
        }

        return STATES_STR.FINISHED;
    }

    reactivate(): void {
        this.state = STATES.STATE_PROCESSING;

        this.userService
            .reactivate(this.data.token)
            .subscribe((): void => {
                this.auth.setToken(this.data.token);

                setTimeout((): void => {
                    this.state = STATES.STATE_FINISHED;
                }, 500);
            });
    }

    protected setIsAttemptingLoginAsFalse(): void {
        this.store.dispatch(
            UiActions.setIsAttemptingLogin({
                isAttemptingLogin: false,
            }),
        );
    }

    isQuestioningAndReady(): boolean {
        return this.state === STATES.STATE_QUESTION && !this.animating;
    }

    isProcessingAndReady(): boolean {
        return this.state === STATES.STATE_PROCESSING && !this.animating;
    }

    isFinishedAndReady(): boolean {
        return this.state === STATES.STATE_FINISHED && !this.animating;
    }

    animationStarted(): void {
        this.animating = true;
    }

    animationFinished(): void {
        this.animating = false;
    }
}
