import { Action } from '@ngrx/store';

import { UserCommon } from '@libs/shared/user/user.common';

export const PHOTO_UPLOADED_RECEIVED_ACTION: string = 'PHOTO_UPLOADED_RECEIVED_ACTION';

export interface IPhotoUploadedReceivedActionPayload {
    user: UserCommon;
}

export class PhotoUploadedReceivedAction implements Action {
    readonly type: string = PHOTO_UPLOADED_RECEIVED_ACTION;
}
