import { IRegion } from '@libs/services/location/region/region';
import {
    RegionsReceivedAction,
    REGIONS_RECEIVED_ACTION,
} from '@libs/store/location/actions/regions.action';

export function locationStoreRegions(state: IRegion[] = [], action: RegionsReceivedAction): IRegion[] {
    switch (action.type) {
        case REGIONS_RECEIVED_ACTION:
            return (action.payload.regions || []).slice();

        default:
            return state;
    }
}
