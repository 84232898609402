import { createAction, props } from '@ngrx/store';

import { IBoostPackageProduct } from '@libs/modules/boost/interface/boost-package-product';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';

enum Actions {
    SET_PROVIDER_PAYMENT_TYPES = '[Payment] - Set Provider Payment Types',
    FETCH_PAYMENT_OPTIONS = '[Payment / API] - Fetch Payment Options',
    PAYMENT_OPTIONS_LOADED = '[Payment Effect] - Payment Options Loaded',
    PAYMENT_ERROR = '[Payment Effect] - Payment Error',
    SET_SELECTED_PERIOD = '[Component] - Set selected period',
    SET_SELECTED_MEMBERSHIP_PLAN_BY_UUID = '[Component] - Set selected plan uuid',
    SET_SELECTED_PROVIDER = '[Component] - Set selected provider',
    UPDATE_PAYMENT_OPTIONS = '[Payment] - Update Payment Options',
    CHECK_CORE_DATA_AVAILABILITY = '[Payment / API] - Check Core Data Availability',
    CHECK_USER_ELIGIBILITY_TO_APLUS_CHECKOUT = '[Payment / API] - Check User Eligibility To A+ Checkout',
    FETCH_PLAN_BY_UUID = '[Payment / API] - Fetch Plan By UUID',
}

export const setProviderPaymentTypes = createAction(
    Actions.SET_PROVIDER_PAYMENT_TYPES,
    props<{ payment_types: string[] }>(),
);

export const fetchPaymentOptions = createAction(
    Actions.FETCH_PAYMENT_OPTIONS,
);

export const paymentOptionsLoaded = createAction(
    Actions.PAYMENT_OPTIONS_LOADED,
    props<{paymentOptions: IPaymentOption[]}>(),
);

export const paymentError = createAction(
    Actions.PAYMENT_ERROR,
    props<{ product: MembershipType | IBoostPackageProduct }>(),
);

export const setSelectedPeriod = createAction(
    Actions.SET_SELECTED_PERIOD,
    (period: number): { period: number } => ({ period }),
);

export const setSelectedMembershipPlanByUuid = createAction(
    Actions.SET_SELECTED_MEMBERSHIP_PLAN_BY_UUID,
    (uuid: string): { uuid: string } => ({ uuid }),
);

export const setSelectedProvider = createAction(
    Actions.SET_SELECTED_PROVIDER,
    (provider: string): { provider: string } => ({ provider }),
);

export const updatePaymentOptions = createAction(
    Actions.UPDATE_PAYMENT_OPTIONS,
    props<{ paymentOptions: IPaymentOption[] }>(),
);

export const checkCoreDataAvailability = createAction(
    Actions.CHECK_CORE_DATA_AVAILABILITY,
);

export const checkUserEligibilityToAPlusCheckout = createAction(
    Actions.CHECK_USER_ELIGIBILITY_TO_APLUS_CHECKOUT,
);

export const fetchPlanByUuid = createAction(
    Actions.FETCH_PLAN_BY_UUID,
    props<{ uuid: string }>(),
);
