<div
    class="profile-card"
    *ngIf="profile$ | async as profile"
>
    <div class="profile-card__profile-picture">
        <div class="profile-card__profile-picture__badges">
            <mp-badges [profile]="profile"></mp-badges>
        </div>
        <span
            *ngIf="isOnline(profile)"
            class="profile-card__profile-picture__online-signal"
        ></span>
        <div class="img-aspectratio">
            <div class="content">
                <mp-img-srcset
                    (click)="navigateToProfile()"
                    *ngIf="!!profileId"
                    [url]="getProfilePicture(profile)"
                    place="lists"
                ></mp-img-srcset>
            </div>
        </div>
    </div>
    <div class="profile-card__profile-info">
        <div
            class="profile-card__profile-info__profile-description"
            (click)="navigateToProfile()"
        >
            <div class="profile-card__profile-info__profile-description__pictures">
                <i class="material-icons">
                    &#xE412;
                </i>
                {{ getNumberOfPhotos(profile) }}
            </div>
            <div class="profile-card__profile-info__profile-description__identification">
                {{ profile.username }}, {{profile.age}}
            </div>
            <div class="profile-card__profile-info__profile-description__location">
                {{ getCityAndState(profile) }}
            </div>
        </div>
        <div
            class="profile-card__profile-info__favorite"
            (click)="toogleFavorite(profile)"
        >
            <i
                class="material-icons"
                aria-hidden="true"
                *ngIf="!isFavorite"
            >
                &#xE87E;
            </i>
            <i
                class="material-icons favorited"
                aria-hidden="true"
                *ngIf="isFavorite"
            >
                &#xE87D;
            </i>
        </div>
    </div>
</div>
