import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'view-style-button',
    templateUrl: './view-style-button.html',
    styleUrls: ['./view-style-button.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewStyleButtonComponent implements OnInit{
    @ViewChild('viewStyleButton', { static: true }) viewStyleButton: ElementRef;

    @Input() public iconReference: string = '';
    @Input() public isActive: boolean = false;

    constructor(
        protected renderer: Renderer2,
    ) {
        //
    }

    ngOnInit(): void {
        this.addMaterialIconsClassToButton();
    }

    public getActiveButtonModifierClass(): string {
        if (this.isActive) {
            return `${ this.iconReference }--active`;
        }

        return '';
    }

    protected addMaterialIconsClassToButton(): void {
        this.renderer.addClass(this.viewStyleButton.nativeElement, this.iconReference);
    }
}
