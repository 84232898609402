import { AbstractControl, FormGroup } from '@angular/forms';

export function getNestableControl(
    controlName: string,
    formGroup: FormGroup,
): AbstractControl {
    const dotIndex = controlName.indexOf('.');
    const hasNest = dotIndex !== -1;
    if (!hasNest) {
        return formGroup.controls[controlName];
    }

    const parentControlName = controlName.substring(0, dotIndex);
    const parentControl: FormGroup = <FormGroup> formGroup.controls[parentControlName];
    const child = controlName.substring(dotIndex + 1);

    return getNestableControl(
        child,
        parentControl,
    );
}
