import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';

export interface IBoostedProfilesState extends EntityState<IProfile> {
    //
}

function selectProfileId(profile: IProfile): number {
    return profile.profile_id;
}

export const boostedProfilesAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>(
    {
        selectId: selectProfileId,
    },
);

export const boostedProfilesInitialState: IBoostedProfilesState = boostedProfilesAdapter.getInitialState();
