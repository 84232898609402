<div
    class="mp-carousel"
    #container
>
    <div
        class="mp-carousel-inner"
        #inner
        (mousedown)="dragStart($event)"
        (touchstart)="dragStart($event)"
        (mousemove)="dragMove($event)"
        (touchmove)="dragMove($event)"
        (mouseup)="dragEnd()"
        (touchend)="dragEnd()"
        (touchcancel)="dragEnd()"
        [ngClass]="{dragging: dragging}"
    >
        <div
            class="mp-carousel-item"
            *ngFor="let item of items; let i = index"
            [ngClass]="{active: i === index}"
        >
            <ng-content *ngTemplateOutlet="template; context: {item: item}"></ng-content>
        </div>
        <div
            class="mp-carousel-item"
            *ngIf="items.length > 1"
        >
            <ng-content *ngTemplateOutlet="template; context: {item: items[0]}"></ng-content>
        </div>
    </div>
    <ol
        class="mp-carousel-indicators"
        *ngIf="items.length > 1"
    >
        <li
            *ngFor="let item of items; let i = index"
            [ngClass]="{active: i === index}"
        ></li>
    </ol>
</div>
