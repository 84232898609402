import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { MoipServiceCommon } from '@libs/modules/main/services/payment/moip/moip.service.common';
import { IConfigurationParameters } from '@libs/shared/interfaces/configuration-parameters.interface';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { AnalyticsService } from '@prince/services/analytics/analytics.service';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ProfileService } from '@prince/services/profile.service';
import { UserService } from '@prince/services/user.service';

import { JSEncrypt } from 'jsencrypt';

@Injectable()
export class MoipService extends MoipServiceCommon {
    protected readonly ORIGIN_WEB_TEXT: string = 'web';

    constructor(
        protected translate: TranslateService,
        protected auth: AuthenticationService,
        protected authHttp: AuthHttpService,
        protected store: Store<IApplicationState>,
        protected analytics: AnalyticsService,
        protected userService: UserService,
        protected profileService: ProfileService,
        protected router: Router,
    ) {
        super(
            translate,
            auth,
            authHttp,
            store,
            analytics,
            userService,
            profileService,
            router,
        );
    }

    getConfig(): IConfigurationParameters {
        return Config;
    }

    protected encryptData(
        cardNumber: string,
        cvcNumber: string,
        date: string,
    ): string {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(Config.moip.pubkey);

        const dates: string[] = date.split('/');

        const monthDate: string = dates[0];
        const yearDate: string = dates[1];
        return encrypt.encrypt(
            [
                `number=${cardNumber}`,
                `cvc=${cvcNumber}`,
                `expirationMonth=${monthDate}`,
                `expirationYear=${yearDate}`,
            ].join('&'),
        );
    }

    getOrigin(): string {
        return this.ORIGIN_WEB_TEXT;
    }
}
