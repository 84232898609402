import { createAction, props } from '@ngrx/store';

import { IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';
import { IOlarkUserFlow } from '@libs/store/olark/interfaces/olark-user-flow.interface';

enum Actions {
    INITIALIZE_OLARK = '[Olark] - Initialize Olark',
    APPEND_SCRIPT = '[Olark] - Append Script',
    HANDLE_INITIALIZE_USER_CORE_DATA = '[Olark] - Handle Initialize User Core Data',
    INITIALIZE_USER = '[Olark] - Initialize User',
    HANDLE_USER_FLOW = '[Olark] - Handle User Flow',
    SEND_EVENT_TO_QUEUE = '[Olark] - Send Event To Queue',
    CHECK_FOR_OPERATOR_ONLINE = '[Olark] - Check For Operator Online',
    EXECUTE_USER_FLOW = '[Olark] - Execute User Flow',
    SEND_SYSTEM_MESSAGE_TO_USER = '[Olark] - Send System Message To User',
    HANDLE_CHATBOX_AFTER_SYSTEM_MESSAGE = '[Olark] - Handle Chatbox After System Message',
    SET_ACTIVE_CONVERSATION = '[Olark] - Set Active Conversation',
    UPDATE_USER_INFO = '[Olark] - Update User Info',
    HANDLE_UPDATE_USER_INFO_CYCLE = '[Olark] - Handle Update User Info Cycle',
    CHANGE_CHATBOX_TEXTS_AFTER_OPENING = '[Olark] - Change Chatbox Texts After Opening',
    RESET_ACTIVE_CONVERSATION = '[Olark] - Reset Active Conversation',
}

export const initializeOlark = createAction(
    Actions.INITIALIZE_OLARK,
);

export const appendScript = createAction(
    Actions.APPEND_SCRIPT,
);

export const handleInitializeUserCoreData = createAction(
    Actions.HANDLE_INITIALIZE_USER_CORE_DATA,
);

export const initializeUser = createAction(
    Actions.INITIALIZE_USER,
    props<{
        user: UserCommon;
        stats: IStats;
    }>(),
);

export const handleUserFlow = createAction(
    Actions.HANDLE_USER_FLOW,
    props<IOlarkUserFlow>(),
);

export const sendEventToQueue = createAction(
    Actions.SEND_EVENT_TO_QUEUE,
    props<IOlarkUserFlow>(),
);

export const checkForOperatorOnline = createAction(
    Actions.CHECK_FOR_OPERATOR_ONLINE,
    props<IOlarkUserFlow>(),
);

export const executeUserFlow = createAction(
    Actions.EXECUTE_USER_FLOW,
    props<IOlarkUserFlow>(),
);

export const sendSystemMessageToUser = createAction(
    Actions.SEND_SYSTEM_MESSAGE_TO_USER,
    props<IOlarkUserFlow>(),
);

export const handleChatboxAfterSystemMessage = createAction(
    Actions.HANDLE_CHATBOX_AFTER_SYSTEM_MESSAGE,
);

export const setActiveConversation = createAction(
    Actions.SET_ACTIVE_CONVERSATION,
    props<IOlarkUserFlow & {
        timestamp: number;
    }>(),
);

export const updateUserInfo = createAction(
    Actions.UPDATE_USER_INFO,
    props<{
        user: UserCommon;
        stats: IStats;
    }>(),
);

export const handleUpdateUserInfoCycle = createAction(
    Actions.HANDLE_UPDATE_USER_INFO_CYCLE,
);

export const changeChatboxTextsAfterOpening = createAction(
    Actions.CHANGE_CHATBOX_TEXTS_AFTER_OPENING,
);

export const resetActiveConversation = createAction(
    Actions.RESET_ACTIVE_CONVERSATION,
);
