import { Directive, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IApplicationState } from '@libs/store/application-state';
import { BoostActions, BoostSelectors } from '@libs/store/boost';

@Directive()
export class BoostMultiplierComponentCommon implements OnInit {
    public label: string = 'modules.main.shared.boost_results.multiplier-label';

    constructor(
        protected store: Store<IApplicationState>,
    ) {
        //
    }

    ngOnInit(): void {
        this.store.dispatch(BoostActions.provideAllLastBoostViewers());
    }

    public getMultiplierFromStore$(): Observable<number> {
        return this.store.select(BoostSelectors.selectMultiplier);
    }
}
