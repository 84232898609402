<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h3 class="center">
                {{ 'modules.main.shared.modal_express_approval.title' | translate }}
            </h3>
        </div>
        <div class="modal-body">
            <p class="terms">
                {{ 'modules.main.shared.modal_express_approval.message' | translate }}
            </p>
            <div class="flex">
                <div>
                    <input
                        class="form-check-input"
                        id="agree"
                        type="radio"
                        name="express-agree"
                        [(ngModel)]="agreeTerms"
                        [value]="true"
                        (ngModelChange)="handleChanges($event)"
                    >
                    <label
                        class="radio-label"
                        for="agree"
                    >
                        {{ 'common.accept' | translate }}
                    </label>
                </div>
                <div>
                    <input
                        class="form-check-input"
                        id="disagree"
                        type="radio"
                        name="express-agree"
                        [(ngModel)]="agreeTerms"
                        [value]="false"
                        (ngModelChange)="handleChanges($event)"
                    >
                    <label
                        class="radio-label"
                        for="disagree"
                    >
                        {{ 'common.decline' | translate }}
                    </label>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-default"
                type="button"
                data-dismiss="modal"
                (click)="close()"
            >
                {{ 'common.close' | translate }}
            </button>
            <a
                class="btn btn-danger"
                [routerLink]="['/express-approval', 'payment', EXPRESS_APPROVAL_ID, 0]"
                type="button"
                [ngClass]="getDisabledClass()"
                (click)="close()"
            >
                {{ 'common.continue' | translate }}
            </a>
        </div>
    </div>
</div>
