import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';

import { IFormConditionalValidatorProperties } from '@libs/modules/registration/form-helpers/interfaces/form-conditional-validator-properties.interface';

export class FormHelpers {
    public static getFormControlValue<T = string>(
        formGroup: FormGroup,
        controlName: string,
    ): T {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            throw new Error(`Form group does not have a control with name ${ controlName }`);
        }

        return control.value;
    }

    public static setFormControlValue<T = string>(
        formGroup: FormGroup,
        controlName: string,
        value: T,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            throw new Error(`Form group does not have a control with name ${ controlName }`);
        }

        control.setValue(value);
    }

    public static isFormValid(formGroup: FormGroup) {
        return formGroup.valid;
    }

    public static handleConditionalValidatorOnControl({
        form,
        controlName,
        conditional,
        validator,
    }: IFormConditionalValidatorProperties) {
        const control: AbstractControl | null = form.get(controlName);

        if (control === null) {
            throw new Error(`Form group does not have a control with name ${ controlName }`);
        }

        if (!conditional) {
            control.setValidators([]);
            return;
        }

        control.setValidators([validator]);
    }

    public static isFormFieldFilled(
        formGroup: FormGroup,
        controlName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return false;
        }

        return control.value !== '' &&
            control.value !== 0 &&
            control.value !== undefined;
    }

    public static isControlValid(
        formGroup: FormGroup,
        controlName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return false;
        }

        return control.valid;
    }

    public static isControlInvalid(
        formGroup: FormGroup,
        controlName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return false;
        }

        return control.invalid;
    }

    public static isControlTouched(
        formGroup: FormGroup,
        controlName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return false;
        }

        return control.touched;
    }

    public static isControlDirty(
        formGroup: FormGroup,
        controlName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return false;
        }

        return control.dirty;
    }

    public static isControlPending(
        formGroup: FormGroup,
        controlName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return false;
        }

        return control.pending;
    }

    public static hasErrorWithSpecificName(
        formGroup: FormGroup,
        controlName: string,
        errorName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return false;
        }

        return control.hasError(errorName);
    }

    public static isAnyNestedControlDirty(
        formGroup: FormGroup,
        nestedFormGroupName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(nestedFormGroupName);

        if (control === null) {
            return false;
        }

        const nestedFormGroupControlNames: string [] = Object.keys(control.value);

        return nestedFormGroupControlNames.some((controlName: string): boolean => {
            const nestedFormControl: AbstractControl | null = control.get(controlName);

            if (nestedFormControl === null) {
                return false;
            }

            return nestedFormControl.dirty;
        });
    }

    public static areAllNestedControlsFilled(
        formGroup: FormGroup,
        childFormGroupName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(childFormGroupName);

        if (control === null) {
            return false;
        }

        const nestedFormGroupControlNames: string[] = Object.keys(control.value);

        return nestedFormGroupControlNames.every((controlName: string): boolean => {
            const nestedFormControl: AbstractControl | null = control.get(controlName);

            if (nestedFormControl === null) {
                return false;
            }

            return nestedFormControl.value !== '' &&
                nestedFormControl.value !== 0;
        });
    }

    public static getFormControlSpecificError(
        formGroup: FormGroup,
        controlName: string,
        errorName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null || control.errors === null) {
            return '';
        }

        return control.errors[errorName];
    }

    public static canShowValidationErrorOnRealTime(
        formGroup: FormGroup,
        controlName: string,
    ) {
        return (
            FormHelpers.isControlInvalid(formGroup, controlName) &&
            (
                FormHelpers.isControlDirty(formGroup, controlName) ||
                FormHelpers.isControlTouched(formGroup, controlName)
            )
        );
    }

    public static canShowValidationError(
        formGroup: FormGroup,
        controlName: string,
    ) {
        return (
            FormHelpers.isControlInvalid(formGroup, controlName) &&
            FormHelpers.isControlTouched(formGroup, controlName)
        );
    }

    public static canShowSuccessMessage(
        formGroup: FormGroup,
        controlName: string,
    ) {
        return (
            FormHelpers.isControlValid(formGroup, controlName) &&
            (
                FormHelpers.isControlDirty(formGroup, controlName) ||
                FormHelpers.isControlTouched(formGroup, controlName)
            )
        );
    }

    public static getControlValueChanges$<T>(
        formGroup: FormGroup,
        controlName: string,
    ): Observable<T> {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return of();
        }

        return control.valueChanges.pipe(share());
    }

    public static getControlStatusChanges$(
        formGroup: FormGroup,
        controlName: string,
    ) {
        const control: AbstractControl | null = formGroup.get(controlName);

        if (control === null) {
            return of();
        }

        return control.statusChanges.pipe(share());
    }

    public static triggerValidationOnSpecificFields(
        formGroup: FormGroup,
        formFields: string[],
    ) {
        for (const formField of formFields) {
            const control: AbstractControl | null = formGroup.get(formField);

            if (control === null || control.value === '') {
                return;
            }

            FormHelpers.triggerFieldValidation(control);
        }
    }

    public static triggerFieldValidation(control: AbstractControl) {
        control.markAsTouched();
        control.updateValueAndValidity();
    }

    public static hasSelectedOption(
        formGroup: FormGroup,
        formControlName: string,
    ) {
        return FormHelpers.getFormControlValue(
            formGroup,
            formControlName,
        ) !== '';
    }

    public static getSelectedOptionModifierClass(
        formGroup: FormGroup,
        formControlName: string,
    ) {
        if (!FormHelpers.hasSelectedOption(
            formGroup,
            formControlName,
        )) {
            return '';
        }

        return 'option-selected';
    }

    public static isControlTouchedAndEmpty(
        formGroup: FormGroup,
        controlName: string,
    ) {
        return !FormHelpers.isFormFieldFilled(
            formGroup,
            controlName,
        ) && FormHelpers.isControlTouched(
            formGroup,
            controlName,
        );
    }
}
