import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IPhotoVerifyState as IPhotoVerifyState } from '@libs/store/photo-verify/interfaces/photo-verify.interface';

const feature = createFeatureSelector<IPhotoVerifyState>('photoVerify');

export const selectIsUploadingImage = createSelector(
    feature,
    (state: IPhotoVerifyState): boolean => {
        return state.isUploadingImage;
    },
);

export const selectPhotoInReview = createSelector(
    feature,
    (state: IPhotoVerifyState): boolean => {
        return state.photoInReview;
    },
);

export const selectHasPhotoVerified = createSelector(
    feature,
    (state: IPhotoVerifyState): boolean => {
        return state.hasPhotoVerified;
    },
);
