import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
    PlansButtonDaddyMommyComponentCommon,
} from '@libs/modules/main/shared/plans-button-daddy-mommy/plans-button-daddy-mommy.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { AuthenticationService } from '@prince/services/authentication.service';

@Component({
    selector: 'mp-plans-daddy-mommy-button',
    templateUrl: './plans-button-daddy-mommy.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class PlansButtonDaddyMommyComponent extends PlansButtonDaddyMommyComponentCommon {
    constructor(
        protected router: Router,
        protected auth: AuthenticationService,
        protected store: Store<IApplicationState>,
    ) {
        super(store);
    }

    navigateToUpgradeAccount(): void {
        this.closeSlidableMenu();
        this.router.navigate(['main', 'upgrade-account']);
    }
}
