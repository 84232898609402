import { Directive } from '@angular/core';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { UserCommon } from '@libs/shared/user/user.common';

@Directive()
export class MembershipBadgeComponentCommon {
    constructor(
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    private get profile(): UserCommon {
        return this.auth.get();
    }

    public isPremium(): boolean {
        return UserCommon.isDaddyMommyPremium(this.profile) ||
            UserCommon.isBabyPremium(this.profile) ||
            UserCommon.isPendingElite(this.profile);
    }

    public isElite(): boolean {
        return UserCommon.isElite(this.profile);
    }
}
