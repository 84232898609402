import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export class EmailUnsubscribeErrorComponentCommon {
    @Input() errorHeaderTranslation: string | any;
    @Input() errorDescriptionTranslation: string | any;
    @Input() retryButtonTranslation: string | any;
    @Input() attemptsError: number = 0;

    @Output() readonly retryUnsubscribe: EventEmitter<void> = new EventEmitter<void>();

    protected readonly ATTEMPTS_TO_SHOW_SUPPORT_LINK: number = 1;

    retryToUnsubscribe(): void {
        this.retryUnsubscribe.emit();
    }

    canShowSupportLink(): boolean {
        return this.attemptsError > this.ATTEMPTS_TO_SHOW_SUPPORT_LINK;
    }
}
