import { Action, createReducer, on } from '@ngrx/store';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import * as PaymentInfoActions from '@libs/store/payment-info/actions';
import { IPaymentInfo } from '@libs/store/payment-info/interface';
import {
    IPaymentInfoState,
    paymentInfoAdapter,
    paymentInfoInitialState,
} from '@libs/store/payment-info/state';

const paymentInfoReducer = createReducer(
    paymentInfoInitialState,
    on(PaymentInfoActions.upsertPaymentInfo, (
        state, { paymentInfo }: { paymentInfo: IPaymentInfo[] },
    ): IPaymentInfoState => {
        return paymentInfoAdapter.upsertMany(paymentInfo, state);
    }),
    on(PaymentInfoActions.setPaymentStatus, (
        state, { paymentStatus }: { paymentStatus: PaymentStatus },
    ): IPaymentInfoState => {
        return { ...state, paymentStatus };
    }),
    on(PaymentInfoActions.setIsPaying, (
        state, { isPaying }: { isPaying: boolean },
    ): IPaymentInfoState => {
        return { ...state, isPaying };
    }),
    on(PaymentInfoActions.setHasResolvedPlans, (
        state: IPaymentInfoState,
        { hasResolvedPlans }: { hasResolvedPlans: boolean },
    ): IPaymentInfoState => {
        return {
            ...state,
            hasResolvedPlans,
        };
    }),
    on(PaymentInfoActions.setBoletoUrl, (
        state: IPaymentInfoState,
        { boletoUrl }: { boletoUrl: string },
    ): IPaymentInfoState => {
        return {
            ...state,
            boletoUrl,
        };
    }),
    on(PaymentInfoActions.resetBoletoUrl, (
        state: IPaymentInfoState,
    ): IPaymentInfoState => {
        return {
            ...state,
            boletoUrl: '',
        };
    }),
);

export function reducer(state: IPaymentInfoState, action: Action): IPaymentInfoState {
    return paymentInfoReducer(state, action);
}
