import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
    PlansButtonBabyComponentCommon,
} from '@libs/modules/main/shared/plans-button-baby/plans-button-baby.component.common';
import { IApplicationState } from '@libs/store/application-state';

@Component({
    selector: 'mp-plans-button-baby',
    templateUrl: './plans-button-baby.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class PlansButtonBabyComponent extends PlansButtonBabyComponentCommon {
    constructor(
        protected router: Router,
        protected store: Store<IApplicationState>,
    ) {
        super(store);
    }

    navigateToUpgradeAccount(): void {
        this.closeSlidableMenu();
        this.router.navigate(['main', 'upgrade-account']);
    }
}
