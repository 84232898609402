import { IImageParams } from '@libs/shared/interfaces/image-params.interface';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { IPhoto } from '@libs/shared/profile/photo';
import { IBaseProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@prince/config';

export class ImageHelper {
    static getImage(
        profileId: number,
        photo: IPhoto,
        { width }: IImageParams = { },
    ): string {
        width = width || 800;

        return `${ Config.imagesServer }${ profileId }/${ photo.photo_id }` +
            `/${ photo.index }/width=${ width },height=${ width }`;
    }

    static getProfilePicture(profile: IBaseProfile, user: UserCommon, params: IImageParams = { }): string {
        if (!profile || Object.keys(profile).length === 0 || (profile.status && profile.status !== 'active')) {
            return '/assets/img/undefined-user.gif';
        }

        const photo = profile.photo;

        if (
            (photo && Object.keys(photo).length === 0) ||
            !photo ||
            photo.photo_id === undefined ||
            photo.index === undefined
        ) {
            let membershipTypeToCompare: MembershipType = profile.membership_type_id;

            if (!membershipTypeToCompare) {
                membershipTypeToCompare = UserCommon.getTheOppositeMembershipType(user.membership_type_id);
            }

            return UserCommon.getBasicImage(profile.sex, membershipTypeToCompare);
        }

        return ImageHelper.getImage(profile.profile_id, photo, params);
    }
}
