<div class="close-menu-container">
    <button
        class="close-menu-btn"
        type="button"
        (click)="closeMenu()"
    >
        <i class="material-icons">
            &#xE5CD;
        </i>
    </button>
</div>
