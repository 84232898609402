import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { filter } from 'rxjs/operators';

import { GenericModalComponent } from '@libs/components/generic-modal/generic-modal.component';
import { MpCropperjsModule } from '@libs/integration/mp-cropperjs';
import { EmailSuggestionFacade } from '@libs/really-shared/email-suggestion/email-suggestion.facade';
import { EmailSuggestionService } from '@libs/services/email-suggestion/email-suggestion.service';
import { ExpiredTokenInterceptor } from '@libs/services/expired-token/expired-token.interceptor';
import { RelativeTimeService } from '@libs/services/relative-time/relative-time.service';
import { LanguageModel } from '@libs/shared/language/language-model';
import { SearchParser } from '@libs/shared/search/search';
import { IApplicationState, initialValue, reducers } from '@libs/store/application-state';
import { MPWebMissingTranslationHandler } from '@libs/utils/missing-translation-handler.web';

import { AppComponent } from '@prince/app.component';
import { AppRoutingWebModule, navigatableComponents } from '@prince/app.routing';
import {
    ChangePasswordModalComponent,
} from '@prince/components/change-password-modal/change-password-modal.component';
import {
    IncompleteModalComponent,
} from '@prince/components/frictionless/incomplete-modal/incomplete-modal.component';
import {
    PendingApprovalModalComponent,
} from '@prince/components/frictionless/pending-approval-modal/pending-approval-modal.component';
import {
    ReactivateModalComponent,
} from '@prince/components/reactivate-modal/reactivate-modal.component';
import { Config } from '@prince/config';
import { AnalyticsEffects } from '@prince/effects/analytics/analytics.effects';
import { EffectInitializer } from '@prince/effects/effect-initializer';
import { MembershipEffects } from '@prince/effects/membership/membership.effects';
import { ModalViewEffects } from '@prince/effects/modal-view/modal-view.effects';
import { PaymentInfoEffects } from '@prince/effects/payment-info/payment-info.effects';
import { ProductEffects } from '@prince/effects/product/product.effects';
import { AuthGuard } from '@prince/guards/auth/auth-guard.service';
import {
    EmailUnsubscribeErrorComponent,
} from '@prince/modules/initial/pages/email-unsubscribe-error/email-unsubscribe-error.component';
import {
    EmailUnsubscribeHeaderComponent,
} from '@prince/modules/initial/pages/email-unsubscribe-header/email-unsubscribe-header.component';
import {
    EmailUnsubscribeLoadingComponent,
} from '@prince/modules/initial/pages/email-unsubscribe-loading/email-unsubscribe-loading.component';
import {
    EmailUnsubscribeSuccessComponent,
} from '@prince/modules/initial/pages/email-unsubscribe-success/email-unsubscribe-success.component';
import { LoginEffects } from '@prince/modules/initial/pages/login/effects/login.effects';
import { NewPasswordEffects } from '@prince/modules/initial/pages/new-password/effects/new-password.effects';
import { AlertComponent } from '@prince/modules/main/modals/alert/alert.component';
import { CharmModalComponent } from '@prince/modules/main/modals/charm-modal/charm-moda.component';
import {
    DeactivateAccountModalComponent,
} from '@prince/modules/main/modals/deactivate-account-modal/deactivate-account-modal.component';
import {
    ExpressApprovalModalComponent,
} from '@prince/modules/main/modals/express-approval-modal/express-approval-modal.component';
import { ModalComponent } from '@prince/modules/main/modals/modal/modal.component';
import { ReportModalComponent } from '@prince/modules/main/modals/report-modal/report-modal.component';
import { PhotoVerifyEffects } from '@prince/modules/main/pages/photo-verify/effects/photo-verify.effects';
import { ActionbarService } from '@prince/modules/main/services/actionbar/actionbar.service';
import { BrowserFaviconsService, BROWSER_FAVICONS_CONFIG, FaviconsService } from '@prince/modules/main/services/favicons.service';
import { LanguageService } from '@prince/modules/main/services/language.service';
import { AllCashService } from '@prince/modules/main/services/payment/allcash/allcash.service';
import { MoipService } from '@prince/modules/main/services/payment/moip/moip.service';
import { ToastService } from '@prince/modules/main/services/toast/toast.service';
import { AskNotificationsModalComponent } from '@prince/modules/main/shared/ask-notifications-modal/ask-notifications-modal.component';
import {
    BlockedNotificationsModalComponent,
} from '@prince/modules/main/shared/blocked-notifications-modal/blocked-notifications-modal.component';
import {
    CloseMenuButtonComponent,
} from '@prince/modules/main/shared/close-menu-button/close-menu-button.component';
import { DailyMessageLimitModalComponent } from '@prince/modules/main/shared/daily-message-limit-modal/daily-message-limit-modal.component';
import { FreeMsgModalComponent } from '@prince/modules/main/shared/free-msg-modal/free-msg-modal.component';
import {
    MenuActionsComponent,
} from '@prince/modules/main/shared/menu-actions/menu-actions.component';
import { ModalViewComponent } from '@prince/modules/main/shared/modal-view/modal-view.component';
import { NewModalComponent } from '@prince/modules/main/shared/new-modal/new-modal.component';
import { PhotoVerifyModalComponent } from '@prince/modules/main/shared/photo-verify-modal/photo-verify-modal.component';
import { PictureCropModalComponent } from '@prince/modules/main/shared/picture-crop/picture-crop.component';
import { PlansButtonBabyComponent } from '@prince/modules/main/shared/plans-button-baby/plans-button-baby.component';
import { PlansButtonDaddyMommyComponent } from '@prince/modules/main/shared/plans-button-daddy-mommy/plans-button-daddy-mommy.component';
import { RulesModalComponent } from '@prince/modules/main/shared/rules-modal/rules-modal.component';
import {
    SlidableMenuComponent,
} from '@prince/modules/main/shared/slidable-menu/slidable-menu.component';
import { TenMessageModalComponent } from '@prince/modules/main/shared/ten-message-modal/ten-message-modal.component';
import { TrialModalComponent } from '@prince/modules/main/shared/trial-modal/trial-modal.component';
import {
    UserAvatarComponent,
} from '@prince/modules/main/shared/user-avatar/user-avatar.component';
import {
    UserButtonsBoostComponent,
} from '@prince/modules/main/shared/user-buttons-boost/user-buttons-boost.component';
import {
    UserButtonsPhotoVerifyComponent,
} from '@prince/modules/main/shared/user-buttons-photo-verify/user-buttons-photo-verify.component';
import {
    UserButtonsReferralComponent,
} from '@prince/modules/main/shared/user-buttons-referral/user-buttons-referral.component';
import { MeltToastComponent } from '@prince/modules/shared/melt-toast/melt-toast.component';
import { MPToastComponent } from '@prince/modules/shared/mp-toast/mp-toast.component';
import { SharedModule } from '@prince/modules/shared/shared.module';
import { AdvancedModalService } from '@prince/services/advanced-modal.service';
import { AlertService } from '@prince/services/alert.service';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { BlockService } from '@prince/services/block.service';
import { BlogPostsService } from '@prince/services/blog-posts.service';
import { ConversationService } from '@prince/services/conversation.service';
import { CouponService } from '@prince/services/coupon.service';
import { CustomerSupportService } from '@prince/services/customer-support.service';
import { DownloadManagerService } from '@prince/services/download-manager.service';
import { ListTTLService } from '@prince/services/list-ttl.service';
import { ListService } from '@prince/services/list.service';
import { MeltService } from '@prince/services/melt/melt.service';
import { MessageService } from '@prince/services/message.service';
import { ModalService } from '@prince/services/modal.service';
import { NagbarService } from '@prince/services/nagbar/nagbar.service';
import { ReportService } from '@prince/services/report.service';
import { SearchService } from '@prince/services/search.service';
import { TrialService } from '@prince/services/trial/trial.service';
import { UserService } from '@prince/services/user.service';
import { metaReducer } from '@prince/state';
import { OfflineInterceptor } from '@prince/utils/offline.interceptor';

import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?' + Config.version);
}

export function loadI18nJson(
    http: HttpClient,
    translate: TranslateService,
    languageService: LanguageService,
): () => Promise<void> {
    return async (): Promise<void> => new Promise((resolve: (value: void | PromiseLike<void>) => void ): void => {
        const language: string = languageService.currentLanguage().lang;

        const loader: TranslateHttpLoader = createTranslateLoader(http);
        loader.getTranslation(language).subscribe((response: Object): void => {
            translate.setDefaultLang(language);
            translate.setTranslation(language, response);
            resolve();
        }, (): void => {
            //
        });
    });
}

export function initialState(): IApplicationState {
    return Object.assign({ }, initialValue);
}

export const metaReducers = [metaReducer];

@NgModule({
    schemas: [NO_ERRORS_SCHEMA],
    imports: [
        TranslateModule.forRoot({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MPWebMissingTranslationHandler,
            },
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        ToastrModule.forRoot({
            timeOut: 15000,
            extendedTimeOut: 2500,
            maxOpened: 1,
            progressBar: true,
            progressAnimation: 'increasing',
            closeButton: true,
            preventDuplicates: true,
            toastComponent: MPToastComponent,
            toastClass: 'toast',
        }),
        StoreModule.forRoot(reducers, {
            initialState,
            metaReducers,
            runtimeChecks: {
                strictStateSerializability: false,
                strictActionSerializability: false,
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictActionWithinNgZone: false,
                strictActionTypeUniqueness: true,
            },
        }),
        EffectsModule.forRoot([
            EffectInitializer,
            AnalyticsEffects,
            PaymentInfoEffects,
            ProductEffects,
            MembershipEffects,
            ModalViewEffects,
            LoginEffects,
            PhotoVerifyEffects,
            NewPasswordEffects,
        ]),
        SharedModule,
        A11yModule,
        AppRoutingWebModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        OverlayModule,
        MpCropperjsModule,
        ServiceWorkerModule.register(
            'ngsw-worker.js',
            {
                enabled: true,
                registrationStrategy: 'registerImmediately',
            },
        ),
    ],
    declarations: [
        AlertComponent,
        AppComponent,
        DailyMessageLimitModalComponent,
        DeactivateAccountModalComponent,
        ExpressApprovalModalComponent,
        EmailUnsubscribeHeaderComponent,
        EmailUnsubscribeSuccessComponent,
        EmailUnsubscribeLoadingComponent,
        EmailUnsubscribeErrorComponent,
        FreeMsgModalComponent,
        ModalComponent,
        MPToastComponent,
        NewModalComponent,
        PhotoVerifyModalComponent,
        BlockedNotificationsModalComponent,
        AskNotificationsModalComponent,
        PictureCropModalComponent,
        ReportModalComponent,
        RulesModalComponent,
        TenMessageModalComponent,
        TrialModalComponent,
        CharmModalComponent,
        IncompleteModalComponent,
        ReactivateModalComponent,
        PendingApprovalModalComponent,
        SlidableMenuComponent,
        PlansButtonDaddyMommyComponent,
        PlansButtonBabyComponent,
        UserAvatarComponent,
        UserButtonsBoostComponent,
        UserButtonsReferralComponent,
        UserButtonsPhotoVerifyComponent,
        CloseMenuButtonComponent,
        ChangePasswordModalComponent,
        MenuActionsComponent,
        ModalViewComponent,
        MeltToastComponent,
        GenericModalComponent,
        ...navigatableComponents,
    ],
    providers: [
        ActionbarService,
        AdvancedModalService,
        AlertService,
        AuthenticationService,
        AuthGuard,
        AuthHttpService,
        BlockService,
        BlogPostsService,
        ConversationService,
        CookieService,
        CouponService,
        CustomerSupportService,
        DownloadManagerService,
        LanguageService,
        ListService,
        ListTTLService,
        MessageService,
        MeltService,
        ModalService,
        MoipService,
        NagbarService,
        AllCashService,
        RelativeTimeService,
        ReportService,
        SearchParser,
        SearchService,
        ToastService,
        TrialService,
        UserService,
        EmailSuggestionFacade,
        EmailSuggestionService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadI18nJson,
            multi: true,
            deps: [HttpClient, TranslateService, LanguageService],
        },
        {
            provide: FaviconsService,
            useClass: BrowserFaviconsService,
        },
        {
            provide: BROWSER_FAVICONS_CONFIG,
            useValue: {
                icons: {
                    normal: {
                        type: 'image/png',
                        href: '/assets/img/favicon-96x96.png',
                        isDefault: true,
                    },
                    notification: {
                        type: 'image/png',
                        href: '/assets/img/message-favicon.png',
                    },
                },
            },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ExpiredTokenInterceptor,
            multi: true,
        },
        {
            provide: MESSAGE_FORMAT_CONFIG,
            useValue: {
                locales: LanguageModel.getAllAvailableLanguages(),
            },
        },
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
    constructor(
        router: Router,
        viewportScroller: ViewportScroller,
    ) {
        router.events
            .pipe(filter<Scroll>((e): boolean => e instanceof Scroll))
            .subscribe((e): void => {
                setTimeout((): void => {
                    if (e.position) {
                        viewportScroller.scrollToPosition(e.position);
                        return;
                    }
                    if (e.anchor) {
                        viewportScroller.scrollToAnchor(e.anchor);
                        return;
                    }

                    if (!/message\/\d+$/.test(router.url)) {
                        viewportScroller.scrollToPosition([0, 0]);
                    }
                }, 50);
            });
    }
}
