import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';

import { DeactivateAccountServiceCommon } from '@libs/modules/main/services/deactivate-account/deactivate-account.service.common';
import { IApplicationState } from '@libs/store/application-state';

import { AuthenticationService } from '@prince/services/authentication.service';
import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';
import { TrialService } from '@prince/services/trial/trial.service';

@Injectable({
    providedIn: 'root',
})
export class DeactivateAccountService extends DeactivateAccountServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected trialService: TrialService,
        protected translate: TranslateService,
        protected profileService: ProfileService,
        protected authenticationService: AuthenticationService,
        protected modalService: ModalService,
        protected router: Router,
    ) {
        super(
            store,
            trialService,
            translate,
            profileService,
            authenticationService,
        );
    }

    showDeactivatedSuccessModal(): void {
        this.modalService.open('modules.main.pages.visibility.disable.success', this.logout);
    }

    showDeactivateErrorModal(): void {
        this.modalService.open('modules.main.pages.visibility.disable.error');
    }

    protected handleLastModalAnswer(reason: string): Observable<unknown> {
        return new Observable((subscriber: Subscriber<unknown>): void => {
            this.modalService.confirm(
                'modules.main.pages.visibility.disable.modal.confirm',
                (): void => {
                    this.deactivateAccountRequest(reason);
                    subscriber.complete();
                },
                'modules.main.pages.visibility.disable_account',
                'modules.main.pages.visibility.disable.modal.confirm.no',
                (): void => {
                    this.router.navigate(['main', 'home']);
                    subscriber.complete();
                },
            );
        });
    }
}
