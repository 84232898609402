<a
    class="personal-link flex"
    *ngIf="receiver"
    [routerLink]="['/main', 'profile', receiver.profile_id]"
>
    <div
        class="online-tip"
        *ngIf="isReceiverOnline()"
    >
        {{ 'modules.main.pages.conversation.online' | translate }}
    </div>
    <div class="icon">
        <img
            *ngIf="checkConversationIcon(ICON_VERIFIED)"
            [src]="getVerifiedBadge()"
            [alt]="'modules.main.pages.profile.member_verified' | translate"
        >
        <img
            *ngIf="checkConversationIcon(ICON_PREMIUM)"
            src="{{ premiumBadge.imagePath }}"
            [alt]="premiumBadge.imageAlt | translate"
        >
        <img
            *ngIf="checkConversationIcon(ICON_ELITE)"
            [src]="getKingBadge()"
            [alt]="'shared.user.memberships.king' | translate"
        >
    </div>
    <mp-img-srcset
        url="{{ getReceiverPicture() }}"
        imgClass="avatar"
        place="conversation-avatar"
    ></mp-img-srcset>
</a>
<div class="action-message-receive">
    <div class="notranslate action-message-receive__profile">
        <i
            class="material-icons"
            *ngIf="isLiked()"
        >
            &#xE87D;
        </i>
        <span class="action-message-receive__profile__username">
            {{ getReceiverName() }}
        </span>
    </div>
    <div
        class="notranslate action-message-receive__location"
        *ngIf="!isRecipientWriting"
    >
        {{ getReceiverLocation() }}
    </div>
    <div
        class="writing"
        *ngIf="isRecipientWriting"
    >
        {{ 'common.typing' | translate }}
    </div>
</div>
