<div
    [@ModalView]="animationState"
    (@ModalView.done)="animationFinished($event)"
    class="modal-view"
>
    <div
        class="modal-view__container"
        [ngClass]="containerModifierClasses"
        #modalViewContainer
    >
        <div class="modal-view__header" *ngIf="hasHeader">
            <img
                (click)="close()"
                src="/assets/img/ic_close_36px.svg"
                [alt]="'common.close' | translate"
            />
        </div>
        <div class="modal-view__content">
            <ng-template #modalViewContent></ng-template>
        </div>
        <div class="modal-view__footer" *ngIf="hasFooter"></div>
    </div>
</div>
