<div class="dial-box msg-limit-modal">
    <button
        class="close-button"
        (click)="closeModal()"
    >
        <i
            class="material-icons"
            aria-hidden="true"
        >
            &#xE5CD;
        </i>
    </button>
    <h2 class="title">
        {{ 'modules.main.shared.daily_message_limit.title' | translate }}
    </h2>
    <p>
        {{ 'modules.main.shared.daily_message_limit.description' | translate:{ isConversation: isConversation } }}
    </p>
    <div class="buttons-container">
        <a
            class="mp-main-button bt-shadow bt-md"
            [routerLink]="['/main', 'settings', 'customer-support']"
            (click)="closeModal()"
        >
            {{ 'modules.main.shared.daily_message_limit.support' | translate }}
        </a>
        <button
            class="text-link"
            (click)="closeModal()"
        >
            {{ 'modules.main.shared.daily_message_limit.wait' | translate }}
        </button>
    </div>
</div>
