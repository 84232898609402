import { Action, createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as MeltProfilesActions from '@libs/store/melt-profiles/actions';
import {
    IMeltProfilesState,
    meltProfilesAdapter,
    meltProfilesInitialState,
} from '@libs/store/melt-profiles/state';

const meltProfilesReducer = createReducer(
    meltProfilesInitialState,
    on(
        MeltProfilesActions.upsertMeltProfiles,
        (
            state: IMeltProfilesState,
            { profiles }: { profiles: IProfile[] },
        ): IMeltProfilesState => {
            return meltProfilesAdapter.upsertMany(profiles, state);
        },
    ),
    on(
        MeltProfilesActions.upsertMeltProfile,
        (
            state: IMeltProfilesState,
            { profile }: { profile: IProfile },
        ): IMeltProfilesState => {
            return meltProfilesAdapter.upsertOne(profile, state);
        },
    ),
    on(
        MeltProfilesActions.removeMeltProfile,
        (
            state: IMeltProfilesState,
            { profile }: { profile: IProfile },
        ): IMeltProfilesState => {
            return meltProfilesAdapter.removeOne(profile.profile_id, state);
        },
    ),
    on(
        MeltProfilesActions.removeAllMeltProfiles,
        (state: IMeltProfilesState): IMeltProfilesState => {
            return meltProfilesAdapter.removeAll(state);
        },
    ),
    on(
        MeltProfilesActions.MeltTTLUpdatedAction,
        (state: IMeltProfilesState, { lastUpdateTimestamp }: { lastUpdateTimestamp: number }): IMeltProfilesState => {
            return { ...state, lastUpdateTimestamp };
        },
    ),
);

export function reducer(state: IMeltProfilesState, action: Action): IMeltProfilesState {
    return meltProfilesReducer(state, action);
}
