import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Registration } from '@libs/registration-prince/domain/registration';
import { SomeGender } from '@libs/registration-prince/domain/types/genders.type';

@Component({
    selector: 'mp-radio-check-gradient',
    templateUrl: 'radio-check-gradient.html',
    styleUrls: ['radio-check-gradient.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioCheckGradientComponent {
    @Input() id: string = '';
    @Input() name: string = '';
    @Input() value: number | string = 0;
    @Input() gender: SomeGender = 0;
    @Input() isChecked: boolean = false;

    public getGradientModifierClass(): string {
        if(this.gender === Registration.GENDER_FEMALE) {
            return 'radio-check-gradient--princess';
        }

        return '';
    }

    public isRadioChecked(): boolean {
        return this.isChecked;
    }
}
