<div class="actionbar-checkout">
    <mp-actionbar-back-button [definedBackAction]="navigateBack"></mp-actionbar-back-button>
    <div class="actionbar-checkout__selected-plan">
        <img
            [src]="getPlanIconPath()"
            class="actionbar-checkout__selected-plan__icon"
        >
        <span
            #title
            class="actionbar-checkout__selected-plan__title"
        >
            {{ getPlanTitle() | translate }}
        </span>
    </div>
</div>
