import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'mp-actionbar-anchor-action',
    templateUrl: 'actionbar-anchor-action.html',
    styleUrls: ['./actionbar-anchor-action.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ActionbarAnchorActionComponent {
    @Input() readonly anchorText: string = '';
    @Output() readonly definedAction: EventEmitter<void> = new EventEmitter<void>();

    public handleDefinedAction(): void {
        this.definedAction.emit();
    }
}
