import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    EmailUnsubscribeLoadingComponentCommon,
} from '@libs/modules/initial/email-unsubscribe-loading/email-unsubscribe-loading.component.common';

import {
    EmailUnsubscribeAnimations,
} from '@prince/modules/initial/pages/email-unsubscribe/email-unsubscribe.animations';

@Component({
    selector: 'mp-email-unsubscribe-loading',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: 'email-unsubscribe-loading.html',
    animations: [
        EmailUnsubscribeAnimations.slideToggle,
    ],
})
export class EmailUnsubscribeLoadingComponent extends EmailUnsubscribeLoadingComponentCommon {
    //
}
