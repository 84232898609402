import { Injectable, OnDestroy } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import {
    PaymentCommon,
} from '@libs/modules/main/services/payment/payment.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import {
    CardNumberValidatons,
    CPFValidations,
    CVVValidations,
    DateValidations,
    HolderValidations,
    getNestableControl,
} from '@libs/services/payment/validators';

interface IValidationErrorsObject {
    [controlName: string]: (errors: ValidationErrors) => string;
}

@Injectable({
    providedIn: 'root',
})
export abstract class PaymentModalFormServiceCommon implements OnDestroy {
    public paymentGroupForm: FormGroup = this.formBuilder.group({ });
    public readonly BOOST_PACKAGE_CONTROL_NAME: string = 'PAYMENT_MODAL_CONTROL';
    public readonly HOLDER: string = 'holder';
    public readonly CARD_NUMBER: string = 'cardNumber';
    public readonly DATE: string = 'date';
    public readonly CPF: string = 'cpf';
    public readonly CVV: string = 'cvv';
    public readonly IS_ISSUED_ABROAD: string = 'isIssuedAbroad';
    public readonly INVALID_CVV: string = 'invalidCvv';
    protected subscriptions: Subscription[] = [];

    constructor(
        protected formBuilder: FormBuilder,
        protected authenticationService: AuthenticationServiceCommon,
    ) {
        this.setupForm();
        this.subscribeToOnLogout();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    public setupForm(): void {
        this.paymentGroupForm = this.formBuilder.group(
            {
                [this.HOLDER]: ['', HolderValidations.validations.validation],
                [this.CARD_NUMBER]: ['', CardNumberValidatons.validations.validation],
                [this.DATE]: ['', DateValidations.validations.validation],
                [this.CVV]: ['', CVVValidations.validations.validation],
                [this.CPF]: ['', CPFValidations.validations.validation],
                [this.IS_ISSUED_ABROAD]: [false],
            },
            {
                validators: CVVValidations.validateCvv,
            },
        );
    }

    subscribeToOnLogout(): void {
        this.subscriptions.push(this.authenticationService.onLogout$.subscribe({
            next: (): void => {
                this.clearAllData();
            },
        }));
    }

    getInitialValue(): FormGroup {
        return this.formBuilder.group({ });
    }

    getFormGroup(): FormGroup {
        return this.paymentGroupForm;
    }

    getHolder(): string {
        const control: AbstractControl | null = this.getFormGroup().get(this.HOLDER);

        if (control === null) {
            return '';
        }

        return control.value.toString();
    }

    getCardNumber(): string {
        const control: AbstractControl | null = this.getFormGroup().get(this.CARD_NUMBER);

        if (control === null) {
            return '';
        }

        return control.value.toString();
    }

    getCvv(): string {
        const control: AbstractControl | null = this.getFormGroup().get(this.CVV);

        if (control === null) {
            return '';
        }

        return control.value.toString();
    }

    getCPF(): string {
        const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

        if (control === null) {
            return '';
        }

        return control.value.toString();
    }

    getDate(): string {
        const control: AbstractControl | null = this.getFormGroup().get(this.DATE);

        if (control === null) {
            return '';
        }

        return control.value.toString();
    }

    getIsIssuedAbroad(): boolean {
        const control: AbstractControl | null = this.getFormGroup().get(this.IS_ISSUED_ABROAD);

        if (control === null) {
            return false;
        }

        return control.value;
    }

    getFormValidationErrosMessage(controlName: string): string {
        const fieldsTovalidate: IValidationErrorsObject = {
            [this.HOLDER]: HolderValidations.validations.messages,
            [this.CARD_NUMBER]: CardNumberValidatons.validations.messages,
            [this.DATE]: DateValidations.validations.messages,
            [this.CPF]: CPFValidations.validations.messages,
            [this.CVV]: CVVValidations.validations.messages,
        };

        if (fieldsTovalidate[controlName] === undefined) {
            return '';
        }

        const errors: ValidationErrors = getNestableControl(
            controlName,
            this.paymentGroupForm,
        ).errors;

        return fieldsTovalidate[controlName](errors);
    }

    setIsIssuedAbroad(isIssuedAbroad: boolean): void {
        const control: AbstractControl | null = this.getFormGroup().get(this.IS_ISSUED_ABROAD);

        if (control === null) {
            return;
        }

        control.setValue(isIssuedAbroad);
    }

    setCardNumber(cardNumber: string): void {
        const control: AbstractControl | null = this.getFormGroup().get(this.CARD_NUMBER);

        if (control === null) {
            return;
        }

        control.setValue(cardNumber);
    }

    setDate(date: string): void {
        const control: AbstractControl | null = this.getFormGroup().get(this.DATE);

        if (control === null) {
            return;
        }

        control.setValue(date);
    }

    setCVV(cvv: string): void {
        const control: AbstractControl | null = this.getFormGroup().get(this.CVV);

        if (control === null) {
            return;
        }

        control.setValue(cvv);
    }

    setHolder(holder: string): void {
        const control: AbstractControl | null = this.getFormGroup().get(this.HOLDER);

        if (control === null) {
            return;
        }

        control.setValue(holder);
    }

    setCPF(cpf: string): void {
        const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

        if (control === null) {
            return;
        }

        control.setValue(cpf);
        control.updateValueAndValidity();
    }

    handleDefaultCPF(isIssuedAbroad: boolean): void {
        if (!isIssuedAbroad) {
            this.setCPF('');
            return;
        }

        this.setCPF(PaymentCommon.DEFAULT_CPF);
    }

    isFormValid(): boolean {
        return this.paymentGroupForm.valid;
    }

    isCardNumberPristine(): boolean {
        const control: AbstractControl | null = this.getFormGroup().get(this.CARD_NUMBER);

        if (control === null) {
            return false;
        }

        return control.pristine;
    }

    isValidDate(): boolean {
        const control: AbstractControl | null = this.getFormGroup().get(this.DATE);

        if (control === null) {
            return false;
        }

        return control.valid || control.pristine;
    }

    isValidCVV(): boolean {
        const control: AbstractControl | null = this.getFormGroup().get(this.CVV);

        if (control === null) {
            return false;
        }

        return control.valid;
    }

    isPristineCVV(): boolean {
        const control: AbstractControl | null = this.getFormGroup().get(this.CVV);

        if (control === null) {
            return false;
        }

        return control.pristine;
    }

    isValidHolder(): boolean {
        const control: AbstractControl | null = this.getFormGroup().get(this.HOLDER);

        if (control === null) {
            return false;
        }

        return control.valid || control.pristine;
    }

    isCPFValid(): boolean {
        const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

        if (control === null) {
            return false;
        }

        return control.valid || control.pristine;
    }

    groupHasInvalidCVVError(): boolean {
        return this.getFormGroup().hasError(this.INVALID_CVV);
    }

    clearAllData(): void {
        this.getFormGroup().reset({
            [this.HOLDER]: '',
            [this.CARD_NUMBER]: '',
            [this.DATE]: '',
            [this.CVV]: '',
            [this.CPF]: '',
            [this.IS_ISSUED_ABROAD]: false,
        });
    }
}
