import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import {
    CustomerSupportServiceCommon,
} from '@libs/modules/main/services/customer-support/customer-support.service.common';
import { IDeviceInformation } from '@libs/modules/main/services/customer-support/interfaces/device-information.interface';
import { ISupportRequestData } from '@libs/modules/main/services/customer-support/interfaces/support-request-data.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { GlobalObjectService } from '@prince/services/global-object-service';

import { Memoize } from 'typescript-memoize';
import { UAParser } from 'ua-parser-js';

@Injectable()
export class CustomerSupportService extends CustomerSupportServiceCommon {
    public readonly ENDPOINT_CUSTOMER_SUPPORT: string = 'customer-support/';

    protected userAgentParser: UAParser = new UAParser();
    protected user: UserCommon;
    protected userDeviceInformation: readonly IDeviceInformation[] = [
        {
            title: 'Espaço utilizado no local storage:',
            value: this.getLocalStorageUsedSpace(),
        },
        {
            title: 'Versão da aplicação do usuário:',
            value: this.getUserAppVersion(),
        },
        {
            title: 'Navegador:',
            value: this.getBrowserName(),
        },
        {
            title: 'Versão do navegador:',
            value: this.getBrowserVersion(),
        },
        {
            title: 'Sistema operacional:',
            value: this.getOS(),
        },
        {
            title: 'User Agent do navegador:',
            value: this.getUserAgent(),
        },
    ] as const;

    protected subscriptions: Subscription[] = [];

    constructor(
        protected authHttp: AuthHttpService,
        protected store: Store<IApplicationState>,
        protected globalObjectService: GlobalObjectService,
    ) {
        super(
            authHttp,
            store,
            globalObjectService,
        );

        this.subscriptions.push(
            this.store.select('user').subscribe(
                (user: UserCommon): void => {
                    if (!user) {
                        return;
                    }

                    this.user = user;
                },
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    protected getLocalStorageUsedSpace(): string {
        let totalSpaceUsed: number = 0;

        for (const key in localStorage) {
            const keyValueSizeInMb: number = (localStorage[key].length * 2) / 1024 / 1024;

            if (!isNaN(keyValueSizeInMb) && localStorage.hasOwnProperty(key)) {
                totalSpaceUsed += keyValueSizeInMb;
            }
        }

        return `${ totalSpaceUsed.toFixed(2) }mb`;
    };

    @Memoize()
    protected getParsedUserAgent(): UAParser.IResult {
        return this.userAgentParser.getResult();
    }

    protected getBrowserName(): string {
        return this.getParsedUserAgent().browser.name;
    }

    protected getBrowserVersion(): string {
        return this.getParsedUserAgent().browser.version;
    }

    protected getOS(): string {
        return this.getParsedUserAgent().os.name;
    }

    protected getUserAgent(): string {
        return this.globalObjectService.window.navigator.userAgent;
    }

    protected getUserAppVersion(): string {
        return Config.version;
    }

    public sendSupportRequest(
        title: string,
        message: string,
        params: any,
        successCallback: () => void,
        errorCallback: (error: any) => void,
    ): void {
        const endpoint: string = Config.serverIp + this.ENDPOINT_CUSTOMER_SUPPORT;

        const data: ISupportRequestData = {
            ...params,
            subject: title,
            message,
            name: this.user.username,
            custom_private_fields: this.userDeviceInformation,
        };

        this.authHttp.post(endpoint, data).subscribe((): void => {
            successCallback();
        }, (error: any): void => {
            errorCallback(error);
        });
    }
}
