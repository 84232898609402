import { Action, createReducer, on } from '@ngrx/store';

import { IBoostPackage } from '@libs/modules/boost/interface/boost-package';
import * as BoostPackagesActions from '@libs/store/boost-packages/actions';
import { boostPackagesAdapter, boostPackagesInitialState, IBoostPackagesState } from '@libs/store/boost-packages/state';

const boostPackagesReducer = createReducer(
    boostPackagesInitialState,
    on(
        BoostPackagesActions.setBoostPackages,
        (
            state: IBoostPackagesState,
            { boostPackages, productUuid, refreshData }: {
                boostPackages: IBoostPackage[],
                productUuid: string,
                refreshData: number,
            },
        ): IBoostPackagesState => {
            return boostPackagesAdapter.upsertMany(
                boostPackages,
                {
                    ...state,
                    productUuid,
                    refreshData,
                },
            );
        },
    ),
    on(
        BoostPackagesActions.setIsPaymentProcessing,
        (
            state: IBoostPackagesState,
            { isPaymentProcessing }: { isPaymentProcessing: boolean },
        ): IBoostPackagesState => {
            return {
                ...state,
                isPaymentProcessing,
            };
        },
    ),
);

export function reducer(
    state: IBoostPackagesState,
    action: Action,
): IBoostPackagesState {
    return boostPackagesReducer(state, action);
}
