import { Action, createReducer, on } from '@ngrx/store';

import * as ProductActions from '@libs/store/product/actions';
import { IProduct } from '@libs/store/product/interface';
import { IProductState, productAdapter, productInitialState } from '@libs/store/product/state';

const productReducer = createReducer(
    productInitialState,
    on(ProductActions.upsertProduct, (
        state: IProductState, { product }: { product: IProduct },
    ): IProductState => {
        return productAdapter.upsertOne(product, state);
    }),
    on(ProductActions.upsertProducts, (
        state: IProductState, { products }: { products: IProduct[]},
    ): IProductState => {
        return productAdapter.upsertMany(products, state);
    }),
);

export function reducer(state: IProductState, action: Action): IProductState {
    return productReducer(state, action);
}
