<div class="dial-box trial-modal">
    <div
        class="countdown-time"
        *ngIf="canShowCountdown()"
    >
        <div class="countdown-title">
            {{ 'modules.main.shared.trial_modal.countdown_title' | translate }}
        </div>
        <div class="board-group">
            <div
                class="flipdown"
                id="flipdown"
            ></div>
            <div class="flipdown-text-block">
                <div class="flipdown-text">
                    {{ 'modules.main.shared.trial_modal.days_title' | translate }}
                </div>
                <div class="flipdown-text">
                    {{ 'modules.main.shared.trial_modal.hours_title' | translate }}
                </div>
                <div class="flipdown-text">
                    {{ 'modules.main.shared.trial_modal.minutes_title' | translate }}
                </div>
                <div class="flipdown-text">
                    {{ 'modules.main.shared.trial_modal.seconds_title' | translate }}
                </div>
            </div>
        </div>
    </div>
    <h2 class="title">
        {{ this.getModalTitle() | translate }}
        <span class="large">
            {{ 'modules.main.pages.my_profile.basic.member_premium' | translate }}
        </span>
        <span class="medium">{{ getTrialPlanDescription() }}</span>
    </h2>
    <p class="cancel-print">
        <small>{{ getTrialPlansFinePrint() }}</small>
    </p>
    <div class="buttons-container">
        <a
            class="mp-main-button bt-shadow bt-lg"
            cdkFocusInitial
            [routerLink]="['/main', 'payment', TYPE_PREMIUM, activeTrialRoutePath]"
            (click)="acceptOffer()"
        >
            {{ getAcceptButtonText() | translate }}
        </a>
        <button
            class="text-link"
            (click)="declineModal()"
        >
            {{ getDeclineText() | translate }}
        </button>
    </div>
    <p class="fine-print">
        <small>
            {{ 'modules.main.shared.trial_modal.trial_plans.cancel_print' | translate }}
        </small>
    </p>
    <p class="fine-print">
        <small>
            {{ 'modules.main.shared.trial_modal.fine_print2' | translate }}
        </small>
    </p>
</div>
