<div
    class="slidable-menu-bar inertial"
    [@slideToggle]="animationState"
    (@slideToggle.done)="onToggleFinished($event)"
>
    <div class="slidable-menu-bar__user-header">
        <mp-user-avatar></mp-user-avatar>
        <mp-close-menu-button></mp-close-menu-button>
    </div>
    <div class="slidable-menu-bar__subscribed-status">
        <ng-template #subscribedStatusContainer></ng-template>
    </div>
    <div class="slidable-menu-bar__user-buttons">
        <ng-template #userButtonsContainer></ng-template>
    </div>
    <div class="slidable-menu-bar__menu-separator"></div>
    <div class="slidable-menu-bar__actions">
        <slidable-menu-item
            [id]="'slidable-menu-item_edit-profile'"
            [description]="'modules.main.pages.edit_profile.title'"
            (click)="navigateTo('edit-profile')"
        ></slidable-menu-item>
        <slidable-menu-item
            [id]="'slidable-menu-item_settings'"
            [description]="'modules.main.pages.settings.title'"
            (click)="navigateTo('settings')"
        ></slidable-menu-item>
        <slidable-menu-item
            [id]="'slidable-menu-item_faq'"
            [description]="'modules.main.shared.menu-actions.customer-support'"
            (click)="navigateToFAQ()"
        ></slidable-menu-item>
        <slidable-menu-item
            [id]="'slidable-menu-item_logout'"
            [description]="'common.logout'"
            (click)="logout()"
        ></slidable-menu-item>
    </div>
</div>
