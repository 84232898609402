import { Router } from '@angular/router';

import { MembershipType } from '@libs/shared/membership/membership.common';
import { Money } from '@libs/shared/money/money';
import { IAvailableTrial, IEnabledTrial, ITrial } from '@libs/shared/trial/interface';

export type EnabledTrials = {
    [key: number] : IEnabledTrial
};

export class Trial {
    public static readonly TRIAL_TIME: number = 0;
    public static readonly TRIAL_3_DAYS: number = 72;
    public static readonly TRIAL_7_DAYS: number = 7;
    public static readonly PRICE_3_DAYS: Money = new Money(45.00);
    public static readonly PRICE_7_DAYS: Money = new Money(79.99);
    public static readonly MONTH_UNIT: string = 'months';
    public static readonly DAY_UNIT: string = 'days';

    protected static readonly ENABLED_TRIALS: EnabledTrials = {
        [Trial.TRIAL_TIME]: {
            duration: 7,
            price: Trial.PRICE_7_DAYS,
        },
        [Trial.TRIAL_3_DAYS]: {
            duration: 3,
            price: Trial.PRICE_3_DAYS,
        },
    };

    protected static readonly MILLISECONDS_PER_DAY: number = 1000 * 60 * 60 * 24;
    protected static readonly MILLISECONDS: number = 1000;

    protected trialData: IAvailableTrial;
    protected currentPrice: Money;
    protected nextPrice: Money;

    constructor(
        trialData: IAvailableTrial,
    ) {
        this.trialData = trialData;
        this.currentPrice = this.getCurrentMoneyFromData();
        this.nextPrice = this.getNextMoneyFromData();
    }

    static isAtTrialLocation(url: string): boolean {
        for (const key of Object.keys(Trial.ENABLED_TRIALS)) {
            if (url.indexOf(`${ MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM }/${ key }`) !== -1) {
                return true;
            }
        }

        return false;
    }

    public static getEnabledTrial(period: number): IEnabledTrial | null {
        return Trial.ENABLED_TRIALS[period] || null;
    }

    public static getLastTrialPeriod(paidPrice: number, allTrialsFromRedux: ITrial[]): number {
        const lastTrial: ITrial = allTrialsFromRedux.find(
            (trial: ITrial): boolean => {
                return trial.price === paidPrice;
            },
        );

        if (typeof lastTrial === 'undefined') {
            return 0;
        }

        return lastTrial.period;
    }

    protected getCurrentMoneyFromData(): Money {
        let price = 0;
        if (this.trialData !== undefined &&
            this.trialData.price !== undefined &&
            this.trialData.price !== null &&
            !isNaN(this.trialData.price)
        ) {
            price = this.trialData.price;
        }

        return new Money(price);
    }

    protected getNextMoneyFromData(): Money {
        let nextPrice = 0;
        if (this.trialData.next !== undefined) {
            nextPrice = this.trialData.next.price;
        }

        return new Money(nextPrice);
    }

    public isAtPlans(url: string): boolean {
        return /payment|upgrade-account/.test(url);
    }

    public isTrialEnabled(router: Router): boolean {
        return this.isElective() &&
            !this.isAtPlans(router.url);
    }

    public isElective(): boolean {
        return Object.keys(this.trialData).length > 0 &&
            this.getDiffDate() > 0;
    }

    public getDiffDate(): number {
        return this.trialData.until*1000 - Date.now();
    }

    public getEndDateTimestampInSeconds(): number {
        return this.trialData.until;
    }

    public getEndTrialDaysCount(): number {
        return Math.floor(this.getDiffDate() / (Trial.MILLISECONDS_PER_DAY));
    }

    public getTrialPlanDaysActive(): number {
        return this.trialData.period;
    }

    public isOriginalTrialPlan(): boolean {
        return this.getTrialPlanDaysActive() === Trial.TRIAL_7_DAYS;
    }

    public getTrialNextRecurringPeriod(): number {
        return this.trialData.next.period;
    }

    public getTrialNextRecurringPrice(): Money {
        return this.nextPrice;
    }

    public getTrialPrice(): Money {
        return this.currentPrice;
    }

    public isRecurringUnityDay(): boolean {
        return this.trialData.next.units === Trial.DAY_UNIT;
    }

    public isRecurringUnityMonth(): boolean {
        return this.trialData.next.units === Trial.MONTH_UNIT;
    }

    public isEmpty(): boolean {
        return Object.keys(this.trialData).length === 0;
    }
}
