import {
    animate,
    group,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export enum STATES_STR {
    QUESTION = 'question',
    PROCESSING = 'processing',
    FINISHED = 'finished',
}

export const ReactiveModalAnimations = [
    trigger('modal', [
        state(STATES_STR.PROCESSING, style({
            height: '160px',
        })),
        transition(
            STATES_STR.QUESTION + ' => ' + STATES_STR.PROCESSING +
            ', ' +
            STATES_STR.PROCESSING + ' => ' + STATES_STR.FINISHED,
            [
                group([
                    query('.animated-content', [
                        animate(300),
                    ]),
                    animate('200ms ease-out'),
                ]),
            ],
        ),
    ]),
];
