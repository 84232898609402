import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

enum Actions {
    UPSERT_PROFILE = '[Boost Toast] - Upsert Profile in toast array',
    REMOVE_ALL_PROFILES = '[Boost Toast] - Remove all profiles of the toast array',
    HANDLE_NAVIGATION_ON_TOAST = '[Boost Toast] - Handle Navigation on Toast',
    HANDLE_PROFILES_DELETION = '[Boost Toast] - Handle Profiles Deletion',
    NAVIGATE_TO_PROFILE = '[Boost Toast] - Navigate to Profile',
    NAVIGATE_TO_VIEWED_ME = '[Boost Toast] - Navigate to Viewed Me',
}

export const upsertProfile = createAction(
    Actions.UPSERT_PROFILE,
    props<{ profile: IProfile }>(),
);

export const handleNavigationOnToast = createAction(Actions.HANDLE_NAVIGATION_ON_TOAST);

export const handleProfilesDeletion = createAction(Actions.HANDLE_PROFILES_DELETION);

export const navigateToProfile = createAction(
    Actions.NAVIGATE_TO_PROFILE,
    props<{ profiles: IProfile[] }>(),
);

export const navigateToViewedMe = createAction(Actions.NAVIGATE_TO_VIEWED_ME);

export const removeAllProfiles = createAction(Actions.REMOVE_ALL_PROFILES);
