import { Injectable } from '@angular/core';

import { AnalyticsEvent } from '@libs/services/analytics/analytics.service.common';

export interface ICustomWindow extends Window {
    dataLayer?: {
        push(content: AnalyticsEvent): void;
    };
}

@Injectable({
    providedIn: 'root',
})
export class GlobalObjectServiceCommon {
    get window(): ICustomWindow {
        return window;
    }
}
