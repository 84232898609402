import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProductActions, ProductSelectors } from '@libs/store/product';
import { IProduct, ProductNames } from '@libs/store/product/interface';

export abstract class ProductServiceCommon {
    public readonly EXPRESS_APPROVAL_PRICE: number = 249;
    public readonly EXPRESS_APPROVAL_ID: number = -1;

    constructor(
        protected store: Store<IApplicationState>,
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    public getExpressApprovalProduct(): IProduct {
        return {
            name: ProductNames.ExpressApproval,
            id: this.EXPRESS_APPROVAL_ID.toString(),
            available: !UserCommon.hasPaidExpressApproval(this.auth.get()),
            price: this.EXPRESS_APPROVAL_PRICE,
        };
    }

    public setExpressApprovalProduct(): void {
        this.store.dispatch(ProductActions.upsertProducts({
            products: [this.getExpressApprovalProduct()],
        }));
    }

    public findProductByName(name: string, products: IProduct[]): IProduct | undefined {
        return products.find((product: IProduct): boolean => {
            return product.name === name;
        });
    }

    public isExpressApprovalPrice(price: number | string): boolean {
        return Number(price) === this.EXPRESS_APPROVAL_PRICE;
    }

    public expressApprovalPaid$(): Observable<boolean> {
        return this.isExpressApprovalAvailable$().pipe(
            concatMap((isExpressApprovalAvailable: boolean): Observable<boolean> => {
                return of(
                    !isExpressApprovalAvailable ||
                    UserCommon.hasPaidExpressApproval(this.auth.get()),
                );
            }),
        );
    }

    protected isExpressApprovalAvailable$(): Observable<boolean> {
        return this.store.pipe(
            select(ProductSelectors.selectProductByName, {
                name: ProductNames.ExpressApproval,
                productService: this,
            }),
        ).pipe(
            map((product: IProduct | undefined): boolean => {
                if (product === undefined) {
                    return false;
                }

                return product.available;
            }),
        );
    }
}
