<div class="new-password">
    <div class="new-password__logo">
        <img
            [src]="meuPrincipeLogo"
            alt="MeuPríncipe"
        >
    </div>
    <div class="new-password__content">
        <h2 class="new-password__content__title">
            {{ 'modules.initial.pages.new_password.screen_title' | translate }}
        </h2>
        <div class="new-password__content__form">
            <form [formGroup]="newPasswordForm">
                <div class="new-password__content__form__inputs">
                    <mp-generic-input
                        [form]="newPasswordForm"
                        [controlName]="'password'"
                        [label]="'modules.initial.pages.new_password.label_password' | translate"
                        [id]="'new-password__input'"
                        [placeholder]="'common.fill_out_field' | translate"
                        [type]="'password'"
                        [errorMessage]="getInputErrorMessage(PASSWORD_FORM_CONTROL_NAME)"
                    ></mp-generic-input>
                    <mp-generic-input
                        [form]="newPasswordForm"
                        [controlName]="'passwordConfirmation'"
                        [label]="'modules.initial.pages.new_password.label_password_confirmation' | translate"
                        [id]="'confirm-new-password__input'"
                        [placeholder]="'common.fill_out_field' | translate"
                        [type]="'password'"
                        [errorMessage]="getInputErrorMessage(PASSWORD_CONFIRMATION_CONTROL_NAME)"
                    ></mp-generic-input>
                </div>
                <button
                    class="new-password__content__form__submit-button"
                    [ngClass]="getButtonModifierClass(isSavingNewPassword$ | async)"
                    (click)="submitForm()"
                >
                    <ng-container *ngIf="(isSavingNewPassword$ | async); else buttonText">
                        <div class="new-password__content__form__submit-button__loader"></div>
                    </ng-container>
                    <ng-template #buttonText>
                        {{ 'modules.initial.pages.new_password.button_text' | translate }}
                    </ng-template>
                </button>
            </form>
        </div>
    </div>
    <div class="new-password__footer">
        <span class="new-password__footer__divider"></span>
        <p class="new-password__footer__warning">
            {{ 'modules.initial.pages.new_password.warning' | translate }}
        </p>
    </div>
</div>
