import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
    ActionbarBackButtonComponentCommon,
} from '@libs/modules/shared/actionbar/actionbar-back-button/actionbar-back-button.component.common';

interface INavigationState {
    navigationId: number;
}

@Component({
    selector: 'mp-actionbar-back-button',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './actionbar-back-button.html',
})
export class ActionbarBackButtonComponent extends ActionbarBackButtonComponentCommon implements OnDestroy {
    protected subscriptions: Subscription[] = [];
    protected cameFromNewTab: boolean = false;

    protected readonly NO_BROWSER_HISTORY: number = 1;

    constructor(
        protected location: Location,
        protected router: Router,
        protected route: ActivatedRoute,
    ) {
        super();

        this.subscriptions.push(this.route
            .queryParamMap
            .subscribe((params: ParamMap): void => {
                this.cameFromNewTab = (params.get('openedTab') === 'true');
            }),
        );

        this.addOpenedTabToQueryParam();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    public goBack(): void {
        if (this.definedBackAction === undefined) {
            this.handleBackNavigationHistory();
            return;
        }

        this.definedBackAction();
    }

    handleBackNavigationHistory(): void {
        if (this.cameFromNewTab) {
            this.router.navigate(['main', 'search']);
            return;
        }

        this.location.back();
    }

    addOpenedTabToQueryParam(): void {
        if (
            this.location.getState() &&
            (<INavigationState>this.location.getState()).navigationId === this.NO_BROWSER_HISTORY
        ) {
            this.router.navigate([], {
                queryParams: { openedTab: true },
                queryParamsHandling: 'merge',
                replaceUrl: true,
                preserveFragment: true,
            });
        }
    }
}
