import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IOlarkActiveConversation } from '@libs/store/olark/interfaces/olark-active-conversation.interface';
import { IOlarkState } from '@libs/store/olark/interfaces/olark-state.interface';

const olarkFeature = createFeatureSelector('olark');

export const selectActiveConversation = createSelector(
    olarkFeature,
    (state: IOlarkState): IOlarkActiveConversation => {
        return state.activeConversation;
    },
);
