import { createAction, props } from '@ngrx/store';

import { IProduct } from '@libs/store/product/interface';

export const UPSERT_PRODUCT_ACTION: string = '[Product] - Upsert Product Action';

export const UPSERT_PRODUCTS_ACTION: string = '[Product] - Upsert Products Action';

export const SET_PRODUCT_AVAILABILITY: string = '[Product] - Set Product Availability Action';

export const upsertProduct = createAction(
    UPSERT_PRODUCT_ACTION,
    props<{ product: IProduct }>(),
);

export const upsertProducts = createAction(
    UPSERT_PRODUCTS_ACTION,
    props<{ products: IProduct[] }>(),
);

export const setProductAvailability = createAction(
    SET_PRODUCT_AVAILABILITY,
    props<{ available: boolean, productName: string }>(),
);
