<div class="generic-input" [formGroup]="form">
    <label
        *ngIf="hasLabel()"
        [for]="id"
    >
        {{ label | translate }}
    </label>
    <input
        #inputElement
        type="text"
        [id]="id"
        [placeholder]="placeholder"
        [type]="type"
        [formControlName]="controlName"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="none"
        spellcheck="off"
        (change)="emitInputChangeEvent()"
        [attr.aria-describedby]="getInputInstructionId(id)"
    >
    <p
        [id]="getInputInstructionId(id)"
        class="generic-input__warning">
        {{ errorMessage | translate }}
    </p>
</div>
