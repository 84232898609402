import { IStats } from '@libs/shared/user/stats';
import { IUnsafeAction } from '@libs/store/application-state';
import {
    GTM_STATS_ACTION,
} from '@libs/store/authentication/actions/stats.action';

export function stats(state: IStats, action: IUnsafeAction): IStats {
    switch (action.type) {
        case GTM_STATS_ACTION:
            return Object.assign({ }, action.payload);
        default:
            return state;
    }
}
