<div
    class="plans-button-container"
    *ngIf="canUpgrade$() | async"
>
    <button
        class="mp-main-button bt-sm"
        (click)="navigateToUpgradeAccount()"
    >
        {{ 'common.subscribe' | translate }}
    </button>
</div>
