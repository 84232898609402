import { Action } from '@ngrx/store';

import { ICity } from '@libs/services/location/city/city';

export const CITIES_RECEIVED_ACTION: string = 'CITIES_RECEIVED_ACTION';

export interface ICitiesReceivedActionPayload {
    cities: ICity[];
}

export class CitiesReceivedAction implements Action {
    readonly type: string = CITIES_RECEIVED_ACTION;

    constructor(public payload: ICitiesReceivedActionPayload) {
        //
    }
}
