import {
    Directive,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ActionbarServiceCommon,
} from '@libs/modules/main/services/actionbar/actionbar.service.common';

@Directive()
export abstract class BaseActionbarComponentCommon implements OnDestroy, OnInit {
    @ViewChild('defaultActionBar', { static: true })
        defaultActionBar: TemplateRef<unknown> | undefined;

    protected subscription: Subscription;

    constructor(
        protected actionBarService: ActionbarServiceCommon,
        protected viewContainerRef: ViewContainerRef,
    ) {
        this.subscription = this.actionBarService.onUpdate.subscribe(this.updateActionBar);
    }

    public ngOnInit(): void {
        this.updateActionBar(null);
    }

    ngOnDestroy(): void {
        if (!this.subscription) {
            return;
        }

        this.subscription.unsubscribe();
    }

    protected updateActionBar =
        (templateReference: TemplateRef<unknown> | null): void => {
            this.viewContainerRef.clear();
            this.viewContainerRef.insert(
                (templateReference ?? this.defaultActionBar)
                    .createEmbeddedView(null),
            );
        };
}
