import { INewUiState } from '@libs/store/new-ui/interfaces/new-ui-state.interface';

export const newUiInitialState: INewUiState = {
    isAttemptingLogin: false,
    searchLocationOption: 0,
    listFirstUpdateTimestamp: 0,
    viewStyle: 'list',
    isSavingNewPassword: false,
    isDownloadingLists: false,
    hasRedirectedOnSession: false,
    isRefreshButtonVisible: false,
    isUpsellVisible: false,
    webSocketStatus: {
        isReconnected: false,
        isOffline: false,
        isOnline: true,
    },
};
