<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <p>{{ data?.message | translate }}</p>
        </div>
        <div
            class="modal-footer"
            *ngIf="isConfirm()"
        >
            <button
                class="btn btn-default"
                type="button"
                data-dismiss="modal"
                (click)="cancel()"
            >
                {{ data.closeText | translate }}
            </button>
            <button
                class="btn btn-danger"
                cdkFocusInitial
                type="button"
                (click)="ok()"
            >
                {{ data.okText | translate }}
            </button>
        </div>
        <div
            class="modal-footer"
            *ngIf="!isConfirm()"
        >
            <button
                class="btn btn-default"
                cdkFocusInitial
                type="button"
                data-dismiss="modal"
                (click)="close()"
            >
                {{ 'common.close' | translate }}
            </button>
        </div>
    </div>
</div>
