import { Observable } from 'rxjs';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

export abstract class CouponServiceCommon {
    readonly ENDPOINT_COUPON_REDEEM: string = 'coupons/redeem';

    constructor(
        protected authHttp: AuthHttpServiceCommon,
    ) {
        //
    }

    public abstract redeem(couponCode: string): Observable<IAuthResponse>;
}
