export enum UserTypeName {
    SugarDaddy = 'sugar daddy',
    SugarMommy = 'sugar mommy',
    SugarBabyMale = 'sugar baby male',
    SugarBabyFemale = 'sugar baby female',
}

export enum MembershipName {
    Free = 'Free',
    Premium = 'Premium',
    Elite = 'Elite',
    Expired = 'Expired',
    BabyPremium = 'Sugar Baby Premium',
    ExpiredBabyPremium = 'Expired Sugar Baby',
    BabyFree = 'Free Sugar Baby',
}
