<div class="submenu-call-to-action">
    <div
        class="submenu-call-to-action__navigation-list container"
        role="tablist"
    >
        <ng-container *ngIf="hasGridViewSelector">
            <view-style-selector></view-style-selector>
        </ng-container>
        <div
            class="submenu-call-to-action__item"
            *ngFor="let listItem of submenuItems; index as i; trackBy: trackByLabel"
            role="presentation"
        >
            <mp-bubble
                id="mp-bubble-submenu-{{listItem.label | translate: listItem.labelParams }}"
                aria-controls="home"
                role="tab"
                data-toggle="tab"
                text="{{ listItem.label | translate: listItem.labelParams }}"
                icon="&#xE863;"
                (primaryFunction)="openSearchModal()"
                [hasSecondaryFunction]="hasClearCallback(listItem)"
                (secondaryFunction)="handleClearCallback(listItem)"
                icon="&#xE14C;"
                [theme]="'prince-theme--active-white'"
                [isFullWidth]="false"
                [isActive]="false"
            ></mp-bubble>
        </div>
        <div
            class="submenu-call-to-action__item"
            id="mp-action-button"
        >
            <mp-bubble
                id="action-button-component"
                aria-controls="home"
                role="tab"
                data-toggle="tab"
                icon="&#xe152;"
                [isActive]="true"
                [theme]="'prince-theme--active-minsk'"
                [hasSecondaryFunction]="true"
                (secondaryFunction)="handleActionButton()"
            ></mp-bubble>
        </div>
    </div>
</div>
