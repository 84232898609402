export enum PaymentInfoType {
    TypeUndefined = 'undefined',
    TypeFree = 'free',
    TypeUserTriggered = 'user-triggered',
    TypeSystemTriggered = 'system-triggered',
    TypeUserTriggeredInApp = 'user-triggered-in-app',
    TypeRefund = 'refund',
    TypeCoupon = 'coupon',
    TypeBoleto = 'boleto',
}

export enum PaymentInfoStatus {
    StatusApproval = 'approval',
    StatusDeleted = 'deleted',
    StatusFailed = 'failed',
    StatusRefunded = 'refunded',
    StatusChargeBack = 'chargeback',
    StatusPending = 'pending',
}

export interface IPaymentInfo {
    fin_payment_provider_id?: number;
    fin_sale_id?: number;
    order_stamp?: number;
    payment_type?: PaymentInfoType;
    profile_id?: number;
    status?: PaymentInfoStatus;
    subtotal_amount?: number;
    product_uuid?: string | null;
    variant_uuid?: string | null;
}
