import { createReducer, on, Action } from '@ngrx/store';

import { INamedSearch } from '@libs/shared/search/search';
import * as SearchActions from '@libs/store/search/actions';
import { ISearchState } from '@libs/store/search/interface/search-state.interface';
import { searchStateInitialState } from '@libs/store/search/state';
import { SearchModalTabs } from '@libs/store/search/types/search-modal-tabs.type';

const searchStateReducer = createReducer(
    searchStateInitialState,
    on(
        SearchActions.saveScrollPosition,
        (
            state: ISearchState,
            { scrollYPosition }: { scrollYPosition: number },
        ): ISearchState => {
            return {
                ...state,
                scrollYPosition,
            };
        },
    ),
    on(SearchActions.setIsSavingSearch, (
        state: ISearchState,
        { isSavingSearch }: { isSavingSearch: boolean },
    ): ISearchState => {
        return {
            ...state,
            isSavingSearch,
        };
    }),
    on(SearchActions.setSearchModalActiveTab, (
        state: ISearchState,
        { searchModalActiveTab }: { searchModalActiveTab: SearchModalTabs },
    ): ISearchState => {
        return {
            ...state,
            searchModalActiveTab,
        };
    }),
    on(SearchActions.setHasUpdateFailed, (
        state: ISearchState,
        { hasUpdateFailed }: { hasUpdateFailed: boolean },
    ): ISearchState => {
        return {
            ...state,
            hasUpdateFailed,
        };
    }),
    on(SearchActions.setHasErrorOccurred, (
        state: ISearchState,
        { hasErrorOccurred }: { hasErrorOccurred: boolean },
    ): ISearchState => {
        return {
            ...state,
            hasErrorOccurred,
        };
    }),
    on(SearchActions.setIsDeletingSearch, (
        state: ISearchState,
        { isDeletingSearch }: { isDeletingSearch: boolean },
    ): ISearchState => {
        return {
            ...state,
            isDeletingSearch,
        };
    }),
    on(SearchActions.storeSavedSearchesList, (
        state: ISearchState,
        { savedSearches }: { savedSearches: INamedSearch[] },
    ): ISearchState => {
        return {
            ...state,
            savedSearches,
        };
    }),
    on(SearchActions.resetSavedSearchesFlow, (
        state: ISearchState,
    ): ISearchState => {
        return {
            ...state,
            hasUpdateFailed: searchStateInitialState.hasUpdateFailed,
            hasErrorOccurred: searchStateInitialState.hasErrorOccurred,
            isSavingSearch: searchStateInitialState.isSavingSearch,
            isDeletingSearch: searchStateInitialState.isDeletingSearch,
        };
    }),
);

export function reducer(
    state: ISearchState,
    action: Action,
): ISearchState {
    return searchStateReducer(state, action);
}
