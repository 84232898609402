import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
    CustomerSupportComponentCommon,
} from '@libs/modules/main/modules/settings/customer-support/customer-support.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { CustomerSupportService } from '@prince/services/customer-support.service';
import { ModalService } from '@prince/services/modal.service';

@Component({
    selector: 'mp-customer-support',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './customer-support.html',
})
export class CustomerSupportComponent extends CustomerSupportComponentCommon {
    public title: string = '';
    public issue: string = '';
    public loading: boolean = false;
    public messageSent: boolean = false;

    constructor(
        protected customerSupportService: CustomerSupportService,
        protected router: Router,
        protected modalService: ModalService,
        protected store: Store<IApplicationState>,
        protected changeDetectorReference: ChangeDetectorRef,
    ) {
        super(
            customerSupportService,
            store,
        );
    }

    goBack(): void {
        this.router.navigate(['main', 'settings']);
    }

    sendIssue(): void {
        const title: string = this.title;
        const message: string = this.issue;
        if (!this.verifyFields(title, message)) {
            this.modalService.open('common.all_fields_required');
            return;
        }

        this.loading = true;
        this.send(title, message, (): void => {
            this.loading = false;
            this.messageSent = true;
            this.markForChangeDetection();
        }, (): void => {
            this.loading = false;
            this.modalService.open('common.unknown_error');
            this.markForChangeDetection();
        });
    }

    protected markForChangeDetection(): void {
        this.changeDetectorReference.markForCheck();
    }
}
