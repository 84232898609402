import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'mp-carousel',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './carousel.html',
})
export class CarouselComponent implements AfterViewInit, OnChanges {
    public dragging: boolean = false;

    @ContentChild(TemplateRef) template: TemplateRef<unknown>;
    @ViewChild('container', { static: true }) container: ElementRef;
    @ViewChild('inner', { static: true }) inner: ElementRef;

    @Input() items: unknown[];
    @Input() set selectedIndex(index: number) {
        if (index === null || index === undefined) {
            return;
        }

        this.inner.nativeElement.style.transitionTimingFunction = '';
        this.setIndex(index);
    }
    @Output() readonly selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();

    get actualCount(): number {
        let ret = this.items.length;
        if (ret > 1) {
            ret += 1;
        }

        return ret;
    }

    protected readonly LONG_SWIPE_TIME: number = 300;
    protected scrolling: boolean = false;
    protected position: number = 0;
    protected containerWidth: number;
    protected initialX: number;
    protected initialY: number;
    protected initialTime: number;
    protected index: number = 0;

    ngAfterViewInit(): void {
        this.inner.nativeElement.addEventListener('transitionend', this.transitionEnd);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('items' in changes) {
            let length = changes.items.currentValue.length;
            if (length > 1) {
                length += 1;
            }

            this.inner.nativeElement.style.width = `${length * 100}%`;
        }
    }

    dragStart(e: MouseEvent | TouchEvent): void {
        if (this.items.length === 1) {
            return;
        }

        this.dragging = true;
        this.scrolling = true;
        this.initialTime = performance.now();
        this.containerWidth = parseFloat(getComputedStyle(this.container.nativeElement).width);

        if (e instanceof MouseEvent) {
            this.initialX = e.pageX;
            this.initialY = e.pageY;
        } else {
            this.initialX = e.targetTouches[0].pageX;
            this.initialY = e.targetTouches[0].pageY;
        }
    }

    dragMove(e: MouseEvent | TouchEvent): void {
        if (this.items.length === 1 || e instanceof MouseEvent && !this.dragging || !this.scrolling) {
            return;
        }

        let dx: number;
        if (e instanceof MouseEvent) {
            dx = e.pageX - this.initialX;
        } else {
            dx = e.targetTouches[0].pageX - this.initialX;
        }

        e.preventDefault();

        this.position = this.normalizePosition(this.index - dx / this.containerWidth);
        this.inner.nativeElement.style.transform = `translate3d(-${this.position * 100 / this.actualCount}%, 0, 0)`;
    }

    dragEnd(): void {
        if (this.items.length === 1) {
            return;
        }

        this.dragging = false;
        this.scrolling = false;
        this.inner.nativeElement.style.transitionTimingFunction = 'ease-out';

        const timeDelta = performance.now() - this.initialTime;

        if (timeDelta < this.LONG_SWIPE_TIME) {
            if (this.position < this.index || !this.index && this.position >= this.items.length - 1) {
                this.setIndex(Math.floor(this.position));
                return;
            }

            if (this.position > this.index) {
                this.setIndex(Math.ceil(this.position));
                return;
            }

            return;
        }

        this.setIndex(Math.round(this.position));
    }

    private normalizePosition(position: number): number {
        if (position >= this.items.length) {
            position -= this.items.length;
        }
        if (position < 0) {
            position += this.items.length;
        }

        return position;
    }

    private readonly transitionEnd = (): void => {
        this.dragging = true;
        this.setIndex(this.normalizePosition(this.position));
        setTimeout((): void => {
            this.dragging = false;
        }, 1);
    };

    private setIndex(index: number): void {
        index = Math.max(0, index);
        this.position = index;
        this.index = index % this.items.length;
        this.inner.nativeElement.style.transform = `translate3d(-${index * 100 / this.actualCount}%, 0, 0)`;
        this.selectedIndexChange.emit(index);
    }
}
