import { Action, createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as MeltToastActions from '@libs/store/melt-toast/actions';
import {
    meltToastInitialState,
    meltToastAdapter,
    IMeltToastState,
} from '@libs/store/melt-toast/state';

const meltToastReducer = createReducer(
    meltToastInitialState,
    on(
        MeltToastActions.upsertProfile,
        (
            state: IMeltToastState,
            { profile }: { profile: IProfile },
        ): IMeltToastState => {
            return meltToastAdapter.upsertOne(profile, state);
        },
    ),
    on(
        MeltToastActions.removeAllProfiles,
        (state: IMeltToastState): IMeltToastState => {
            return meltToastAdapter.removeAll(state);
        },
    ),
);

export function reducer(
    state: IMeltToastState,
    action: Action,
): IMeltToastState {
    return meltToastReducer(state, action);
}
