import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
    ProfilePreviewSmallComponentCommon,
} from '@libs/modules/main/shared/profile-preview-small/profile-preview-small.component.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { ImageHelper } from '@prince/utils/image-helper';

@Component({
    selector: 'mp-profile-preview-small',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './profile-preview-small.html',
})
export class ProfilePreviewSmallComponent extends ProfilePreviewSmallComponentCommon implements OnInit, OnDestroy {
    constructor(
        protected router: Router,
        protected store: Store<IApplicationState>,
    ) {
        super(router, store);
    }

    getProfilePicture(): string {
        return ImageHelper.getProfilePicture(this.profile, this.user, UserCommon.PICTURE_EXTRA_LARGE);
    }
}
