import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IConversationWriting } from '@libs/store/conversations-writing/interface';
import {
    ConversationWritingAdapter,
    IConversationWritingState,
} from '@libs/store/conversations-writing/state';

export const selectState = createFeatureSelector<IConversationWritingState>('storeConversationWritingEntities');

export const selectAll = createSelector(
    selectState,
    ConversationWritingAdapter.getSelectors().selectAll,
);

export const selectEntities = createSelector(
    selectState,
    ConversationWritingAdapter.getSelectors().selectEntities,
);

export const selectHasWriterByPeerId = createSelector(
    selectAll,
    (writers: IConversationWriting[], props: { peerId: number }): boolean => {
        return writers.findIndex((writer: IConversationWriting): boolean =>
            writer.conversation_peer_id === props.peerId,
        ) !== -1;
    },
);
