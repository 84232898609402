export interface IPremiumVisibility {
    age?: boolean;
    location?: boolean;
    read_stamp?: boolean;
}

export interface IPremiumSettings {
    visibility: IPremiumVisibility;
}

export const INITIAL_PREMIUM_SETTINGS = {
    visibility: {
        age: true,
        location: true,
        read_stamp: true,
    },
};
