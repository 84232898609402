import { Action, createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as BoostedProfilesActions from '@libs/store/boosted-profiles/actions';
import {
    boostedProfilesAdapter,
    boostedProfilesInitialState,
    IBoostedProfilesState,
} from '@libs/store/boosted-profiles/state';

const boostedProfilesReducer = createReducer(
    boostedProfilesInitialState,
    on(
        BoostedProfilesActions.setBoostedProfiles,
        (
            state: IBoostedProfilesState,
            { boostedProfiles }: { boostedProfiles: IProfile[] },
        ): IBoostedProfilesState => {
            return boostedProfilesAdapter.upsertMany(boostedProfiles, state);
        },
    ),
    on(
        BoostedProfilesActions.removeBoostedProfile,
        (
            state: IBoostedProfilesState,
            { profileId }: { profileId: number },
        ): IBoostedProfilesState => {
            return boostedProfilesAdapter.removeOne(profileId, state);
        },
    ),
    on(
        BoostedProfilesActions.removeAllBoostedProfiles,
        (
            state: IBoostedProfilesState,
        ): IBoostedProfilesState => {
            return boostedProfilesAdapter.removeAll({ ...state });
        },
    ),
);

export function reducer(
    state: IBoostedProfilesState,
    action: Action,
): IBoostedProfilesState {
    return boostedProfilesReducer(state, action);
}
