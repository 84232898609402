import { Directive, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import {
    CustomerSupportServiceCommon,
} from '@libs/modules/main/services/customer-support/customer-support.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { SupportParamsAction } from '@libs/store/ui/actions/support-params.action';
import { isNotNull } from '@libs/utils/generic-checkers/generic-checkers';

@Directive()
export abstract class CustomerSupportComponentCommon implements OnDestroy {
    protected supportParams: any;
    protected subscriptions: Subscription[] = [];

    constructor(
        protected customerSupportService: CustomerSupportServiceCommon,
        protected store: Store<IApplicationState>,
    ) {
        this.subscriptions.push(this.store.select('uiState').pipe(take(1)).subscribe((uiState): void => {
            this.supportParams = uiState.supportParams;
            this.store.dispatch(new SupportParamsAction({ }));
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    abstract sendIssue(
        title: string,
        message: string,
        successCallback: () => void,
        errorCallback: (error: any) => void,
    ): void;

    send(
        title: string,
        message: string,
        successCallback: () => void,
        errorCallback: (error: any) => void,
    ): void {
        this.customerSupportService.sendSupportRequest(
            title,
            message,
            this.supportParams,
            successCallback,
            errorCallback,
        );
    }

    verifyFields(title: string, message: string): boolean {
        return isNotNull(title) && isNotNull(message);
    }
}
