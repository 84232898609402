import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Config } from '@prince/config';
import { IViaCepHttpResponse } from '@prince/services/address/interfaces/viacep-http-response.interface';

export interface IAddress {
    country: string;
    state: string;
    city: string;
    postcode: string;
    street1: string;
}

@Injectable({
    providedIn: 'root',
})
export class AddressService {
    protected readonly COUNTRY: string = 'Brasil';

    protected url: string = '';
    protected integrationResource: string = '/ws/{cep}/json/';

    constructor(protected http: HttpClient) {
        //
    }

    protected mountUrl(cep: string): string {
        return Config.cepIntegrationEndPoint + this.integrationResource.replace(/\{cep\}/, cep);
    }

    public consultCEP(cep: string): Observable<IAddress> {
        this.url = this.mountUrl(cep);

        return this.http.get(this.url).pipe(
            filter((data: IViaCepHttpResponse): boolean => !data.erro),
            map(
                (data: IViaCepHttpResponse): IAddress => ({
                    country: this.COUNTRY,
                    state: data.uf,
                    city: data.localidade,
                    postcode: data.cep,
                    street1: data.logradouro,
                }),
            ),
        );
    }
}
