import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[mp-nagbar-host]',
})
export class NagbarDirective {
    constructor(
        public viewContainerRef: ViewContainerRef,
    ) {
        //
    }
}
