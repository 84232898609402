<div class="credit-card-form">
    <form
        [formGroup]="paymentGroup"
        (ngSubmit)="makePayment()"
    >
        <div class="credit-card-form__field">
            <mp-generic-input
                [id]="'checkout_credit-card-form_credit-card-input'"
                [placeholder]="'____ ____ ____ ____'"
                [label]="'Número do cartão'"
                [type]="'text'"
                [controlName]="CARD_NUMBER"
                [form]="paymentGroup"
                (inputChange)="handleCardNumber()"
                [errorMessage]="getCardErrorMessage()"
            ></mp-generic-input>
        </div>
        <div class="credit-card-form__field credit-card-form__field--small">
            <mp-generic-input
                [id]="'checkout_credit-card-form_credit-card-input'"
                [placeholder]="'modules.main.pages.payment.cc_expiry.placeholder' | translate"
                [label]="'modules.main.pages.payment.cc_expiry.label' | translate"
                [type]="'text'"
                [controlName]="DATE"
                [form]="paymentGroup"
                (inputChange)="handleDate()"
                [errorMessage]="getDateErrorMessage()"
            ></mp-generic-input>
        </div>
        <div class="credit-card-form__field credit-card-form__field--small">
            <mp-generic-input
                [id]="'checkout_credit-card-form_cvv-input'"
                [placeholder]="'modules.main.pages.payment.cc_cvv.placeholder' | translate"
                [label]="'modules.main.pages.payment.cc_cvv' | translate"
                [type]="'text'"
                [controlName]="CVV"
                [form]="paymentGroup"
                (inputChange)="checkCVV()"
                [errorMessage]="getCVVErrorMessage()"
            >
            </mp-generic-input>
        </div>
        <div class="credit-card-form__field">
            <mp-generic-input
                [id]="'checkout_credit-card-form_credit-card-holder'"
                [placeholder]="'modules.main.pages.payment.cc_holder.placeholder' | translate"
                [label]="'modules.main.pages.payment.cc_holder' | translate"
                [type]="'text'"
                [controlName]="HOLDER"
                [form]="paymentGroup"
                (inputChange)="checkHolder()"
                [errorMessage]="getHolderNameErrorMessage()"
            ></mp-generic-input>
        </div>
        <div class="credit-card-form__field credit-card-form__field--checkbox">
            <div class="custom-radio-check mini">
                <input
                    id="checkout_credit-card-form_credit-card-country"
                    type="checkbox"
                    (click)="toggleIsIssuedInBrazil()"
                    [formControlName]="IS_ISSUED_IN_BRAZIL"
                >
                <label for="checkout_credit-card-form_credit-card-country">
                    {{ 'modules.main.pages.payment.national-cc' | translate }}
                </label>
            </div>
        </div>
        <div
            *ngIf="isIssuedInBrazil()"
            class="credit-card-form__field"
        >
            <mp-generic-input
                [id]="'checkout_credit-card-form_holder-cpf'"
                [placeholder]="'___.___.___-__'"
                [label]="'modules.main.pages.payment.cpf' | translate"
                [type]="'text'"
                [controlName]="CPF"
                [form]="paymentGroup"
                (inputChange)="handleCPF()"
                [errorMessage]="getCPFErrorMessage()"
            ></mp-generic-input>
        </div>
        <div class="credit-card-form__field">
            <label for="installments">
                {{ 'modules.main.pages.payment.installmentLabel' | translate }}
            </label>
            <select
                [formControlName]="INSTALLMENTS"
                (change)="checkInstallment()"
                required
            >
                <option
                    *ngFor="let value of getInstallmentsOptions(); index as index"
                    value="{{ index }}"
                    [disabled]="handleDisabledInstallments(index)"
                >
                    {{ value }}
                </option>
            </select>
            <small *ngIf="!validInstallment">{{ getErrorMessage('installments') }}</small>
        </div>
        <button
            class="credit-card-form__submit-button"
            type="submit"
            *ngIf="!isPaymentProcessing(isProcessingPayment$() | async)"
            [disabled]="!canSubmit()"
        >
            {{ 'modules.main.pages.payment.pay' | translate }}
        </button>
        <mp-loading *ngIf="isProcessingPayment$() | async"></mp-loading>
    </form>
</div>
