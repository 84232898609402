import { HttpErrorResponse } from '@angular/common/http';
import { Directive } from '@angular/core';

import { UserServiceCommon } from '@libs/shared/user/user.service.common';

@Directive()
export abstract class ChangePasswordComponentCommon {
    constructor(
        protected userService: UserServiceCommon,
    ) {
        //
    }

    abstract redirectSuccess(): void;

    abstract redirectHashExpired(): void;

    abstract redirectError(): void;

    verifyHash(hash: string): void {
        this.userService.checkForgottenPasswordHash(hash, (): void => {
            this.redirectSuccess();
        }, (response: HttpErrorResponse): void => {
            if (response.error.error === 'expired.token') {
                this.redirectHashExpired();
                return;
            }
            this.redirectError();
        });
    }
}
