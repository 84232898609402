import {
    Action,
    createReducer,
    on,
} from '@ngrx/store';

import { INewRegistrationPayload } from '@libs/registration-prince/domain/interfaces/new-registration-payload.interface';
import { IRegistrationAboutYou } from '@libs/registration-prince/domain/interfaces/registration-about-you.interface';
import { IRegistrationInitialStep } from '@libs/registration-prince/domain/interfaces/registration-initial-step.interface';
import { IRegistrationLoginData } from '@libs/registration-prince/domain/interfaces/registration-login-data.interface';
import { IRegistrationPersonalData } from '@libs/registration-prince/domain/interfaces/registration-personal-data.interface';
import {
    saveRegistrationInitialStep,
    savePersonalData,
    saveAboutYou,
    setIsSubmittingForm,
    setIfTriedToAccessModalRoute,
    setIsCroppingImage,
    clearPassword,
    resetToInitialState,
    changeSubmittingFormText,
    setHasSubmittedForm,
    saveLoginData,
} from '@libs/store/registration-prince/actions';
import { registrationPrinceInitialState } from '@libs/store/registration-prince/state';

const registrationReducer = createReducer(
    registrationPrinceInitialState,
    on(saveRegistrationInitialStep, (
        state: INewRegistrationPayload,
        { initialStep }: { initialStep: IRegistrationInitialStep },
    ): INewRegistrationPayload => {
        return {
            ...state,
            initialStep,
        };
    }),
    on(saveLoginData, (
        state: INewRegistrationPayload,
        { loginData }: { loginData: IRegistrationLoginData },
    ): INewRegistrationPayload => {
        return {
            ...state,
            loginData,
        };
    }),
    on(savePersonalData, (
        state: INewRegistrationPayload,
        { personalData }: { personalData: IRegistrationPersonalData },
    ): INewRegistrationPayload => {
        return {
            ...state,
            personalData,
        };
    }),
    on(setIsCroppingImage, (
        state: INewRegistrationPayload,
        { isCroppingImage }: { isCroppingImage: boolean },
    ): INewRegistrationPayload => {
        return {
            ...state,
            isCroppingImage,
        };
    }),
    on(saveAboutYou, (
        state: INewRegistrationPayload,
        { aboutYou }: { aboutYou: IRegistrationAboutYou },
    ): INewRegistrationPayload => {
        return {
            ...state,
            aboutYou,
        };
    }),
    on(setIsSubmittingForm, (
        state: INewRegistrationPayload,
        { isSubmittingForm }: { isSubmittingForm: boolean },
    ): INewRegistrationPayload => {
        return {
            ...state,
            isSubmittingForm,
        };
    }),
    on(setIfTriedToAccessModalRoute, (
        state: INewRegistrationPayload,
        { hasAccessedModalRoute }: { hasAccessedModalRoute: boolean },
    ): INewRegistrationPayload => {
        return {
            ...state,
            hasAccessedModalRoute,
        };
    }),
    on(clearPassword, (
        state: INewRegistrationPayload,
    ): INewRegistrationPayload => {
        return {
            ...state,
            loginData: {
                ...state.loginData,
                password: '',
            },
        };
    }),
    on(changeSubmittingFormText, (
        state: INewRegistrationPayload,
        { translationKey }: { translationKey: string },
    ): INewRegistrationPayload => {
        return {
            ...state,
            submittingFormText: translationKey,
        };
    }),
    on(setHasSubmittedForm, (
        state: INewRegistrationPayload,
        { hasSubmittedForm }: { hasSubmittedForm: boolean },
    ): INewRegistrationPayload => {
        return {
            ...state,
            hasSubmittedForm,
        };
    }),
    on(resetToInitialState, (): INewRegistrationPayload => {
        return {
            ...registrationPrinceInitialState,
        };
    }),
);

export function reducer(
    state: INewRegistrationPayload,
    action: Action,
): INewRegistrationPayload {
    return registrationReducer(state, action);
}
