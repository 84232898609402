import { HttpErrorResponse } from '@angular/common/http';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import {
    AuthenticationServiceCommon,
} from '@libs/services/authentication/authentication.service.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';

@Directive()
export abstract class EmailVerifyComponentCommon implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    constructor(
        protected userService: UserServiceCommon,
        protected route: ActivatedRoute,
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    ngOnInit(): void {
        this.subscriptions.push(this.route
            .paramMap.subscribe((params: ParamMap): void => {
                this.verifyHash(params.get('hash'));
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.
            forEach((subscription): void => subscription.unsubscribe());
    }

    abstract redirectHashExpired(): void;

    abstract redirectError(): void;

    redirectSuccess(): void {
        this.auth.redirectUserToMain();
    }

    verifyHash(hash: string): void {
        this.userService.checkEmailKey(hash, (): void => {
            this.redirectSuccess();
        }, (errorResponse: HttpErrorResponse): void => {
            if (errorResponse.error.error === 'expired.token') {
                this.redirectHashExpired();
                return;
            }
            this.redirectError();
        });
    }
}
