import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';

import { GenericModalComponent } from '@libs/components/generic-modal/generic-modal.component';
import { FormHelpers } from '@libs/modules/registration/form-helpers/form-helpers';
import { TokenReceivedAction } from '@libs/store/authentication/actions/token.action';
import { PasswordActions } from '@libs/store/password';
import { ClearAllAction } from '@libs/store/ui/actions/clear-all.action';
import { selectTmpToken } from '@libs/store/ui/selectors';

import { INewPasswordHttpErrorResponse } from '@prince/modules/initial/pages/new-password/effects/interfaces/new-password-http-error-response.interface';
import { NewPasswordFormService } from '@prince/modules/initial/pages/new-password/services/new-password-form/new-password-form.service';
import { INewPasswordResponse } from '@prince/modules/initial/pages/new-password/services/new-password/interfaces/new-password-response.interface';
import { NewPasswordService } from '@prince/modules/initial/pages/new-password/services/new-password/new-password.service';
import { AdvancedModalService } from '@prince/services/advanced-modal.service';
import { AuthenticationService } from '@prince/services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class NewPasswordEffects {
    handleUpdatePassword$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PasswordActions.updatePassword),
            withLatestFrom(
                this.store.pipe(
                    select(selectTmpToken),
                ),
            ),
            tap({
                next: (): void => {
                    this.setIsSavingNewPassword(true);
                },
            }),
            concatMap(([_, tmpToken]: [Action, string]): Observable<Action> => {
                return this.newPasswordService.newPassword(
                    this.getFormControlValue('password'),
                    this.getFormControlValue('passwordConfirmation'),
                    tmpToken,
                ).pipe(
                    map((response: HttpResponse<INewPasswordResponse>): Action => {
                        return PasswordActions.handleUpdatePasswordSuccess({
                            token: response.body.token,
                        });
                    }),
                    catchError((response: HttpErrorResponse): Observable<Action> => {
                        return of(PasswordActions.handleUpdatePasswordFailure({
                            status: response.status,
                        }));
                    }),
                );
            }),
        ),
        { dispatch: true, useEffectsErrorHandler: false },
    );

    handleUpdatePasswordSuccess$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PasswordActions.handleUpdatePasswordSuccess),
            map(({ token }: { token: string }): Action => {
                this.dispatchClearAllAction();
                this.openSuccessPrompt();
                this.setIsSavingNewPassword(false);

                return new TokenReceivedAction({
                    token,
                });
            }),
        ),
        { dispatch: true, useEffectsErrorHandler: true },
    );

    handleUpdatePasswordFailure$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PasswordActions.handleUpdatePasswordFailure),
            tap({
                next: (response: INewPasswordHttpErrorResponse): void => {
                    this.setIsSavingNewPassword(false);

                    if (response.status === 422) {
                        this.openSamePasswordFailurePrompt();

                        return;
                    }

                    this.openGenericFailurePrompt();
                },
            }),
        ),
        { dispatch: false, useEffectsErrorHandler: true },
    );

    constructor(
        protected actions$: Actions,
        protected store: Store,
        protected authenticationService: AuthenticationService,
        protected newPasswordService: NewPasswordService,
        protected newPasswordFormManagerService: NewPasswordFormService,
        protected advancedModalService: AdvancedModalService,
    ) {
        //
    }

    protected get newPasswordForm(): FormGroup {
        return this.newPasswordFormManagerService.getNewPasswordForm();
    }

    protected getFormControlValue(
        formControlName: string,
    ): string {
        return FormHelpers.getFormControlValue(
            this.newPasswordForm,
            formControlName,
        );
    }

    protected dispatchClearAllAction(): void {
        this.store.dispatch(
            new ClearAllAction(),
        );
    }

    protected openSuccessPrompt(): void {
        this.advancedModalService.open(
            GenericModalComponent,
            {
                data: {
                    title: 'modules.initial.pages.new_password.success_title',
                    message: 'modules.main.pages.change_password.password_updated',
                    closeCallback: (): void => {
                        this.authenticationService.redirectUserToMain();
                        this.advancedModalService.closeAll();
                    },
                    buttonTheme: 'fleur-de-lis',
                },
            },
        );
    }

    protected openSamePasswordFailurePrompt(): void {
        this.advancedModalService.open(
            GenericModalComponent,
            {
                data: {
                    title: 'modules.initial.pages.new_password.invalid_password_title',
                    message: 'modules.main.pages.new_password.current_equals_new_password',
                    buttonTheme: 'rad-red',
                },
            },
        );
    }

    protected openGenericFailurePrompt(): void {
        this.advancedModalService.open(
            GenericModalComponent,
            {
                data: {
                    title: 'common.error.internal_error',
                    message: 'common.error.please_try_again',
                    buttonTheme: 'rad-red',
                },
            },
        );
    }

    protected setIsSavingNewPassword(
        isSavingNewPassword: boolean,
    ): void {
        this.store.dispatch(
            PasswordActions.setIsSavingNewPassword({
                isSavingNewPassword,
            }),
        );
    }
}
