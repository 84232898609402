<div class="unsubscribe-content">
    <div
        class="unsubscribe-content__logo"
        *ngIf="mpLogo"
    >
        <img [src]="mpLogo">
    </div>
    <div
        class="unsubscribe-content--email"
        *ngIf="canShowEmail()"
    >
        <p class="email-obfuscated">{{ emailResponse.obfuscated_email }}</p>
    </div>
</div>
