import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'mp-advanced-modal-ui',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './advanced-modal-ui.component.html',
    styleUrls: ['./advanced-modal-ui.scss'],
})
export class AdvancedModalUiComponent {
    constructor() {
        //
    }
}
