import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IConversation } from '@libs/store/conversations';

export interface IConversationState extends EntityState<IConversation> {
    selectedId: number | null;
    isLoading: boolean;
    unreadConversations: number;
}

function selectPeerId(conversation: IConversation): number {
    return conversation.peer_id;
}

function sortByConversationByLastMessageId(
    conversation1: IConversation,
    conversation2: IConversation,
): number {
    return conversation2.last_message_id - conversation1.last_message_id;
}

export const ConversationAdapter: EntityAdapter<IConversation> = createEntityAdapter<IConversation>({
    selectId: selectPeerId,
    sortComparer: sortByConversationByLastMessageId,
});

export const ConversationInitialState: IConversationState = ConversationAdapter.getInitialState({
    selectedId: null,
    isLoading: true,
    unreadConversations: 0,
});
