import { MpCropperjsComponent } from '@libs/integration/mp-cropperjs/lib/components/mp-cropperjs/mp-cropperjs.component';
import { ICropperResult } from '@libs/integration/mp-cropperjs/lib/interfaces/cropper-result.interface';
import { IImageCropperResult } from '@libs/integration/mp-cropperjs/lib/interfaces/image-cropper-result.interface';
import { MpCropperjsModule } from '@libs/integration/mp-cropperjs/lib/mp-cropperjs.module';
import { CropperjsService } from '@libs/integration/mp-cropperjs/lib/services/cropperjs/cropperjs.service';

export {
    CropperjsService,
    MpCropperjsComponent,
    MpCropperjsModule,
    ICropperResult,
    IImageCropperResult,
};
