import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { ICallbackData } from '@libs/shared/interfaces/callback-data.interface';
import { PhotoCommon } from '@libs/shared/profile/photo.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { AdvancedModalService } from '@prince/services/advanced-modal.service';
import { ImageService } from '@prince/services/image.service';
import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';

@Component({
    selector: 'mp-new-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './new-modal.html',
})
export class NewModalComponent implements OnDestroy, IModalComponent {
    public data: never;
    public modalOpen: boolean;
    protected photoType: string = 'public';
    protected isUploading: boolean = false;
    protected subscriptions: Subscription[] = [];
    protected user: UserCommon | undefined;

    constructor(
        protected modalRef: ModalRef,
        protected store: Store<IApplicationState>,
        protected advancedModalService: AdvancedModalService,
        protected modalService: ModalService,
        protected router: Router,
        protected imageService: ImageService,
        protected profileService: ProfileService,
    ) {
        this.subscriptions.push(this.store.select('user').subscribe((user): void => {
            this.user = user;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    closeModal(): void {
        this.modalRef.close();
    }

    uploadPhoto(event: Event): void {
        const input = <HTMLInputElement>event.target;

        if (input.files === undefined || input.files.length === 0) {
            return;
        }

        const file = input.files[0];

        this.imageService.cropPhoto({
            file,
            type: this.photoType,
            callback: this.getImageUploadedCallback,
        });
    }

    getImageUploadedCallback = (event: ICallbackData): void => {
        event.request.subscribe((eventResponse: HttpEvent<any>): void => {
            if (eventResponse.type === HttpEventType.Response) {
                this.modalService.open(
                    'modules.main.pages.photo_verify.new_modal_photo_uploaded',
                );
                this.profileService.updateSelf();
            }
        }, (): void => {
            this.modalService.open('modules.main.services.auth_http.image_error');
        });
    };

    hasPendingPhoto(): boolean {
        return UserCommon.hasPendingPublicPhoto(this.user);
    }

    hasApprovedPhoto(): boolean {
        return UserCommon.hasActivePublicPhoto(this.user);
    }

    noPendingOrApprovedPhoto(): boolean {
        return !this.hasPendingPhoto() && !this.hasApprovedPhoto();
    }

    translateOpts(): object {
        const options = { gender: undefined };

        if (this.user) {
            options.gender = this.user.match_sex;
        }

        return options;
    }

    getAcceptedImageTypes(): string {
        return PhotoCommon.TYPE_PHOTOS_ACCEPTED_IN_UPLOAD;
    }
}
