<div class="view-style-selector">
    <view-style-button
        id="view-style-button-list-view"
        [iconReference]="'list'"
        (click)="setViewStyle('list')"
        [isActive]="isListStyleActive()"
    >
        &#xE047;
    </view-style-button>
    <view-style-button
        id="view-style-button-grid-view"
        [iconReference]="'grid'"
        (click)="setViewStyle('grid')"
        [isActive]="!isListStyleActive()"
    >
        &#xE9B0;
    </view-style-button>
    <div class="view-style-selector__divider"></div>
</div>
