import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { ImageHelper } from '@prince/utils/image-helper';

@Component({
    selector: 'mp-ten-message-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './ten-message-modal.html',
})
export class TenMessageModalComponent implements OnDestroy, IModalComponent {
    public data: never;
    protected subscriptions: Subscription[] = [];
    protected user: UserCommon | undefined;

    constructor(
        protected modalRef: ModalRef,
        protected store: Store<IApplicationState>,
    ) {
        this.subscriptions.push(this.store.select('user').subscribe((user): void => {
            this.user = user;
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    get avatar(): string {
        return ImageHelper.getProfilePicture(this.user, null, UserCommon.PICTURE_EXTRA_LARGE);
    }

    closeModal(): void {
        this.modalRef.close();
    }
}
