<div
    *ngIf="billDescription"
    class="warning-under-payment"
>
    <div class="warning-under-payment__text">
        <p>{{ 'modules.main.pages.payment.blurb9l1' | translate }}</p>
        <p>
            <mp-advanced-translate key="modules.main.pages.payment.blurb9l2">
                <strong mp-translate-param="billId"> {{ billDescription }} </strong>
            </mp-advanced-translate>
        </p>
    </div>
    <div class="warning-under-payment__icons">
        <ul>
            <li *ngFor="let iconPath of getSecurityIconPaths(); trackBy: trackByPath">
                <img [src]="iconPath">
            </li>
        </ul>
    </div>
</div>
