import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';

export interface IMessageToastState extends EntityState<IProfile> {
    //
}

function selectProfileId(profile: IProfile): number | undefined {
    return profile.profile_id;
}

export const messageToastAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>(
    {
        selectId: selectProfileId,
        sortComparer: false,
    },
);

export const messageToastInitialState: IMessageToastState = messageToastAdapter.getInitialState(
    { },
);
