import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { GlobalObjectServiceCommon } from '@libs/services/global-object/global-object.service.common';
import { IApplicationState } from '@libs/store/application-state';

@Directive()
export abstract class CustomerSupportServiceCommon {
    constructor(
        protected authHttp: AuthHttpServiceCommon,
        protected store: Store<IApplicationState>,
        protected globalObjectService: GlobalObjectServiceCommon,
    ) {
        //
    }

    public abstract sendSupportRequest(
        title: string,
        message: string,
        params: any,
        successCallback: () => void,
        errorCallback: (error: any) => void,
    ): void;
}
