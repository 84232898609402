export function padStart(str: string, targetLength: number, padString: string = ' '): string {
    if (str.length >= targetLength) {
        return str;
    }

    targetLength = targetLength - str.length;
    if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length);
    }
    return padString.slice(0, targetLength) + str;
}
