<div class="referral-nagbar">
    <div class="ad-referral-content">
        <h3>
            <mp-advanced-translate key="modules.main.shared.referral-friendship-day-nagbar.header">
                <span mp-translate-param="friendshipDay">{{ 'friendship_day' | translate }}</span>
            </mp-advanced-translate>
        </h3>
        <div class="aux-text">
            <mp-advanced-translate key="modules.main.shared.referral-friendship-day-nagbar.header2">
                <span mp-translate-param="days">{{ THIRTY_DAYS_OF_PREMIUM }}</span>
            </mp-advanced-translate>
        </div>
        <div class="referral-description">
            <mp-advanced-translate key="modules.main.shared.referral-friendship-day-nagbar.description">
                <span
                    class="text-normal"
                    mp-translate-param="country"
                >
                    {{ 'country_brazil' | translate }}
                </span>
            </mp-advanced-translate>
        </div>
        <a
            class="mp-main-button bt-md white-bg-red-text full-width"
            [routerLink]="['/main', 'settings', 'referral']"
        >
            {{ 'modules.main.shared.referral-friendship-day-nagbar.button' | translate }}
        </a>
    </div>
    <div class="ad-referral-background">
        <div class="ad-referral-gradient"></div>
        <picture>
            <img [src]="getFriendshipDayBackground()">
        </picture>
    </div>
</div>
