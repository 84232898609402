<div class="unsubscribe-content">
    <div
        class="unsubscribe-content__main--animate"
        @slideToggle
    >
        <h1
            class="header header-no-email"
            *ngIf="errorHeaderTranslation"
        >
            {{ errorHeaderTranslation | translate }}
        </h1>
        <p
            class="description"
            *ngIf="errorDescriptionTranslation"
        >
            {{ errorDescriptionTranslation | translate }}
        </p>
        <button
            class="mp-main-button bt-md outline-fleur-de-lis"
            (click)="retryToUnsubscribe()"
        >
            {{ retryButtonTranslation | translate }}
        </button>
        <div
            class="unsubscribe-content__mp-links"
            *ngIf="canShowSupportLink()"
        >
            <div class="support-title">
                {{ 'modules.initial.pages.email-unsubscribe.action-error-support-link-title' | translate }}
            </div>
            <a [href]="mpUrlContact">
                {{ 'modules.initial.pages.email-unsubscribe.action-error-support-link-text' | translate }}
            </a>
        </div>
    </div>
</div>
