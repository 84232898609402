<div class="actionbar-profile-details">
    <a class="actionbar-profile-details__avatar-link">
        <div
            class="actionbar-profile-details__avatar-link__online-tip"
            *ngIf="isOnline()"
        ></div>
        <img
            class="actionbar-profile-details__avatar-link__avatar"
            alt
            [src]="getProfilePicture()"
        >
    </a>
    <div class="actionbar-profile-details__info">
        <div class="actionbar-profile-details__info__profile">
            <i
                *ngIf="isFavorite()"
                class="material-icons"
            >&#xE87D;</i>
            <div class="actionbar-profile-details__info__profile__username">
                {{ getOtherProfileUsername() }}
            </div>
        </div>
        <div class="actionbar-profile-details__info__location">
            {{ getProfileLocation() }}
        </div>
    </div>
</div>
