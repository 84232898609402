<form [formGroup]="form">
    <div class="user-gender-selector">
        <label
            class="custom-radio-check mini user-gender-selector__container"
            [for]="prince"
            (click)="handleGenderSelected(prince)"
            [ngClass]="getSelectedGenderModifierClass(prince)"
        >
            <div class="user-gender-selector__container__icon">
                <img [src]="getUserGenderIcon(prince)" alt="gender icon">
            </div>
            <div class="user-gender-selector__container__description">
                <p> {{ getGenderLabelTranslation(prince) }} </p>
                <strong> {{ getGenderText(prince) | translate }} </strong>
                <div class="user-gender-selector__container__description__radio-button">
                    <mp-radio-check-gradient
                        [name]="controlName"
                        [id]="prince"
                        [value]="prince"
                        [gender]="prince"
                        [isChecked]="isMale()"
                    ></mp-radio-check-gradient>
                </div>
            </div>
        </label>
        <label
            class="custom-radio-check mini user-gender-selector__container"
            [for]="princess"
            (click)="handleGenderSelected(princess)"
            [ngClass]="getSelectedGenderModifierClass(princess)"
        >
            <div class="user-gender-selector__container__icon">
                <img [src]="getUserGenderIcon(princess)" alt="gender icon">
            </div>
            <div class="user-gender-selector__container__description">
                <p> {{ getGenderLabelTranslation(princess) }} </p>
                <strong> {{ getGenderText(princess) | translate }} </strong>
                <div class="user-gender-selector__container__description__radio-button">
                    <mp-radio-check-gradient
                        [name]="controlName"
                        [id]="princess"
                        [value]="princess"
                        [gender]="princess"
                        [isChecked]="isFemale()"
                    ></mp-radio-check-gradient>
                </div>
            </div>
        </label>
    </div>
</form>
