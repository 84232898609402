import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { CouponComponentCommon } from '@libs/modules/main/shared/coupon/coupon.component.common';
import { FormHelpers } from '@libs/modules/registration/form-helpers/form-helpers';
import { CouponCommon } from '@libs/shared/coupon/coupon.common';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { IApplicationState } from '@libs/store/application-state';
import { MaterialIconsLibrary } from '@libs/utils/material-icons/material-icons';

import { CouponFormService } from '@prince/modules/main/shared/coupon/service/coupon-form.service';
import { CouponService } from '@prince/services/coupon.service';
import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';

@Component({
    selector: 'mp-coupon',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['coupon.scss'],
    templateUrl: './coupon.html',
})
export class CouponComponent extends CouponComponentCommon {
    public show: boolean = false;
    public paymentSucess: boolean = false;
    public membershipType: string;
    public period: string;

    constructor(
        protected store: Store<IApplicationState>,
        protected couponService: CouponService,
        protected router: Router,
        protected modalService: ModalService,
        protected profileService: ProfileService,
        protected translate: TranslateService,
        protected couponFormService: CouponFormService,
    ) {
        super(
            store,
            couponService,
        );
        this.couponFormService.couponFormSetup();
    }

    public redeem(): void {
        if (!this.getFormControlNameValue()) {
            this.showValidationMessage();
            return;
        }

        this.loading = true;

        this.couponService.redeem(this.couponCode).subscribe((response): void => {
            this.loading = false;
            this.afterRedeem(response.data);
        }, (response: HttpErrorResponse): void => {
            this.loading = false;
            FormHelpers.setFormControlValue(
                this.couponForm,
                'coupon',
                '',
            );

            if (response.status === 422 && response.error) {
                const errTag = CouponCommon.getTranslateResponse(response.error.error);
                this.afterRedeemError(errTag);
            }
        });
    }

    public get couponForm(): FormGroup {
        return this.couponFormService.getCouponFormInstance();
    }

    public getFormControlNameValue(): AbstractControl {
        return FormHelpers.getFormControlValue(
            this.couponForm,
            'coupon',
        );
    }

    public afterRedeem(data: any): void {
        const membership = MembershipCommon.getMachineMembership(data.membership_type_id);
        this.membershipType = this.translate.instant(`shared.user.memberships.${membership}`);
        this.period = this.translate.instant(`common.periods.${data.unit}`, { period: data.period });
        this.paymentSucess = true;
        this.profileService.updateSelf();
    }

    public afterRedeemError(errorMessage: string): void {
        this.getErrorMessage(this.translate.instant(errorMessage));
    }

    public showValidationMessage(): void {
        this.getErrorMessage(this.translate.instant('shared.coupon.empty_code_error'));
    }

    public redeemClick(): void {
        this.show = true;
    }

    public getInputPlaceholder(): string {
        return this.translate.instant(
            'modules.main.pages.plans.upgrade.coupon.placholder',
        );
    }

    public getTagMaterialIcons(): string {
        return MaterialIconsLibrary.SELL;
    }
}
