import { MessageCommon } from '@libs/shared/message/message.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IConversation, IConversationState } from '@libs/store/conversations';
import { IMessage } from '@libs/store/messages/interface';
import { arrayFind } from '@libs/utils/array-functions';

export class ConversationCommon {
    static readonly FREE_CONVERSATIONS_COUNT_IS_THE_SAME_ERROR: string = 'same.count';

    static readonly MAX_NUMBER_OF_FREE_CONVERSATIONS: number = 10;
    static readonly ATTEMPTS_TO_GET_FREE_COUNVERSATION_COUNT: number = 5;

    static isViewingMessages(locationPath: string): boolean {
        return /^\/main\/conversation\/message\/\d+.+?$/.test(locationPath);
    }

    static isViewingProfileConversation(locationPath: string, profileId: number): boolean {
        let currentProfileId = -1;
        if (ConversationCommon.isViewingMessages(locationPath)) {
            currentProfileId = parseInt(locationPath.split('/').pop(), 10);
        }

        return currentProfileId === profileId;
    }

    static isConversation(conversation: IConversation | undefined): boolean {
        return conversation !== undefined && conversation.conversation_id !== undefined;
    }

    static lastMessageRecipientMatchesUser(conversation: IConversation, userId: number): boolean {
        if (conversation.last_message === undefined) {
            return false;
        }

        return conversation.last_message.recipient_id === userId;
    }

    static getTheirProfileId(userId: number, conversation: IConversation): number {
        if (userId === conversation.initiator_id) {
            return conversation.interlocutor_id;
        }

        return conversation.initiator_id;
    }

    static getOtherId(conversation: IConversation, userId: number): number {
        let otherId = conversation.interlocutor_id;
        if (otherId === userId) {
            otherId = conversation.initiator_id;
        }

        return otherId;
    }

    static conversationIcon(
        conversation: IConversation,
    ): string {
        const profile: IProfile | undefined = conversation.peer;
        if (conversation === undefined) {
            return '';
        }

        return UserCommon.getMainIcon(profile);
    }

    static iReadConversation(conversation: IConversation, userId: number): boolean {
        return ConversationCommon.iReadMessage(
            conversation,
            conversation.last_message_id,
            userId,
        );
    }

    static iReadMessage(conversation: IConversation, messageId: number, userId: number): boolean {
        if (!(userId !== undefined && conversation && messageId > 0)) {
            return false;
        }

        return ConversationCommon.isMessageReadByProfile(
            conversation,
            userId,
            messageId,
        );
    }

    static otherReadConversation(conversation: IConversation, userId: number): boolean {
        return this.otherReadMessage(
            conversation,
            conversation.last_message.message_id,
            userId,
        );
    }

    static otherReadMessage(
        conversation: IConversation,
        messageId: number,
        userId: number,
    ): boolean {
        if (conversation === undefined ||
            messageId === 0 ||
            conversation.peer.status === UserCommon.STATUS_UNAVAILABLE
        ) {
            return false;
        }

        return ConversationCommon.isMessageReadByProfile(
            conversation,
            this.getOtherId(conversation, userId),
            messageId,
        );
    }

    static canMarkAsRead(conversation: IConversation | undefined, user: UserCommon): boolean {
        return !UserCommon.isDaddyMommyFree(user) &&
            ConversationCommon.isConversation(conversation) &&
            ConversationCommon.lastMessageRecipientMatchesUser(
                conversation,
                user.profile_id,
            ) && !ConversationCommon.iReadConversation(conversation,user.profile_id);
    }

    static getConversationByReceiverId(
        receiverId: number,
        conversations: IConversation[],
    ): IConversation {
        return arrayFind(conversations, (conversation: IConversation): boolean => (
            conversation.last_message && MessageCommon.isReceiverSenderOrRecipient(
                conversation.last_message.sender_id,
                conversation.last_message.recipient_id,
                receiverId,
            )
        ));
    }

    static getPeerId(conversation: IConversation, user: UserCommon): number {
        if (ConversationCommon.isUserInterlocutor(conversation, user)) {
            return conversation.initiator_id;
        }

        return conversation.interlocutor_id;
    }

    static isUserInterlocutor(conversation: IConversation, user: UserCommon): boolean {
        return conversation.interlocutor_id === user.profile_id;
    }

    static isAlreadyRead(conversation: IConversation, user: UserCommon): boolean {
        if (ConversationCommon.isUserInterlocutor(conversation, user)) {
            return conversation.last_message_read_id_interlocutor === conversation.last_message_id;
        }

        return conversation.last_message_read_id_initiator === conversation.last_message_id;
    }

    static getConversationPeer(conversation: IConversation, profiles: IProfile[]): IProfile {
        return conversation.peer = arrayFind(
            profiles,
            (profile: IProfile): boolean => profile.profile_id === conversation.peer_id,
        );
    }

    static getConversationsStoredLastMessages(conversations: IConversation[]): IMessage[] {
        return conversations.filter((conversation: IConversation): boolean =>
            conversation.last_message !== undefined,
        ).map((conversation: IConversation): IMessage =>
            conversation.last_message,
        );
    }

    static getUpdatedLastMessageId(conversation: IConversation, lastMessageId: number): number {
        if (conversation.last_message_id > lastMessageId) {
            return conversation.last_message_id;
        }

        return lastMessageId;
    }

    static mapConversations(state: IConversationState): IConversation[] {
        return Object.keys(state.entities)
            .map((key: string): IConversation => state.entities[key]);
    }

    static buildConversationFromMessage(message: IMessage): IConversation {
        return {
            conversation_id: message.conversation_id,
            last_message_id: message.message_id,
            last_message: message,
            peer_id: message.sender_id,
        };
    }

    protected static isMessageReadByProfile(
        conversation: IConversation,
        userId: number,
        messageId: number,
    ): boolean {
        if (!ConversationCommon.isInConversation(conversation, userId)) {
            return false;
        }

        return ConversationCommon.getLastReadMessageId(conversation, userId) >= messageId;
    }

    protected static getLastReadMessageId(conversation: IConversation, userId: number): number {
        if (conversation.initiator_id === userId) {
            return conversation.last_message_read_id_initiator;
        }

        return conversation.last_message_read_id_interlocutor;
    }

    protected static isInConversation(conversation: IConversation, userId: number): boolean {
        return conversation.initiator_id === userId ||
            conversation.interlocutor_id === userId;
    }
}
