import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfile } from '@libs/shared/profile/profile';

@Pipe({
    name: 'boostToastTitle',
    pure: true,
})
export class BoostToastTitlePipe implements PipeTransform {
    constructor(
        protected translateService: TranslateService,
    ) {
        //
    }

    transform(profiles: IProfile[] | undefined): string {
        if (profiles === undefined || profiles.length === 0) {
            return '';
        }

        const lastProfile: IProfile = profiles[profiles.length - 1];

        if (profiles.length === 1) {
            return this.handleUniqueProfileTitle(lastProfile);
        }

        return (
            lastProfile.username +
            this.translateService.instant(
                'modules.main.shared.mp_toast.and_more',
                {
                    numProfiles: profiles.length - 1,
                },
            )
        );
    }

    handleUniqueProfileTitle(lastProfile: IProfile): string {
        if (lastProfile.age === undefined) {
            return lastProfile.username ?? '';
        }

        return (
            lastProfile.username +
            this.translateService.instant(
                'modules.main.shared.mp_toast.username_age',
                {
                    username: lastProfile.username,
                    age: lastProfile.age,
                },
            )
        );
    }
}
