import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IMessage } from '@libs/store/messages/interface';

export interface IMessageState extends EntityState<IMessage> {
    sentFreeMessage: boolean;
}

function selectMessageId(message: IMessage): number {
    return message.message_id;
}

function sortByMessageId(message1: IMessage, message2: IMessage): number {
    return sortByGreaterThan(message1, message2);
}

function sortByGreaterThan(message1: IMessage, message2: IMessage): number {
    return message1.message_id - message2.message_id;
}

export const MessageAdapter: EntityAdapter<IMessage> = createEntityAdapter<IMessage>({
    selectId: selectMessageId,
    sortComparer: sortByMessageId,
});

export const MessageInitialState: IMessageState = MessageAdapter.getInitialState({
    sentFreeMessage: false,
});
