import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { UserProfileSelectors } from '@libs/store/authentication';
import { closeSlidableMenu } from '@libs/store/slidable-menu/action';
@Component({
    selector: 'subscription-button',
    templateUrl: 'subscription-button.html',
    styleUrls: ['subscription-button.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionButtonComponent implements OnDestroy {
    protected subscriptions: Subscription[] = [];
    protected profileGender: number = 0;

    protected readonly PRINCE_BUTTON_DESCRIPTION: string = 'common.subscribe-prince';
    protected readonly CINDERELLA_BUTTON_DESCRIPTION: string = 'common.subscribe-cinderella';
    protected readonly PRINCE_BUTTON_MODIFIER_CLASS: string = 'subscribe-button-container__button--prince';
    protected readonly CINDERELLA_BUTTON_MODIFIER_CLASS: string = 'subscribe-button-container__button--cinderella';
    protected readonly GENDER_MAN: number = 1;

    constructor(
        protected store: Store,
    ) {
        this.subscriptions.push(
            this.store.select(UserProfileSelectors.profileGender).subscribe((gender: number): void => {
                this.profileGender = gender;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => {
            subscription.unsubscribe();
        });
    }

    public getButtonModifierClass(): string {
        if (this.profileGender === this.GENDER_MAN) {
            return this.PRINCE_BUTTON_MODIFIER_CLASS;
        }

        return this.CINDERELLA_BUTTON_MODIFIER_CLASS;
    }

    public getButtonText(): string {
        if (this.profileGender === this.GENDER_MAN) {
            return this.PRINCE_BUTTON_DESCRIPTION;
        }

        return this.CINDERELLA_BUTTON_DESCRIPTION;
    }

    public closeSlidableMenu(): void {
        this.store.dispatch(closeSlidableMenu());
    }
}
