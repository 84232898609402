import { Action } from '@ngrx/store';

import { IPremiumSettings } from '@libs/store/ui/premium-settings';

export const PREMIUM_SETTINGS_CHANGED_ACTION: string = 'PREMIUM_SETTINGS_CHANGED_ACTION';

export class PremiumSettingsChangedAction implements Action {
    readonly type: string = PREMIUM_SETTINGS_CHANGED_ACTION;

    constructor(public settings: IPremiumSettings) {
        //
    }
}
