import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    EmailUnsubscribeSuccessComponentCommon,
} from '@libs/modules/initial/email-unsubscribe-success/email-unsubscribe-success.component.common';

import {
    EmailUnsubscribeAnimations,
} from '@prince/modules/initial/pages/email-unsubscribe/email-unsubscribe.animations';

@Component({
    selector: 'mp-email-unsubscribe-success',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './email-unsubscribe-success.html',
    animations: [
        EmailUnsubscribeAnimations.slideToggle,
    ],
})
export class EmailUnsubscribeSuccessComponent extends EmailUnsubscribeSuccessComponentCommon {
    //
}
