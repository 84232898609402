import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MPWebMissingTranslationHandler extends MissingTranslationHandler {
    handle({ key, translateService, interpolateParams }: MissingTranslationHandlerParams): string {
        if (key === 'yes' || key === 'no') {
            return '';
        }

        const regexp = /\.(?:regular)$/;
        if (regexp.test(key)) {
            return key.replace(regexp, '');
        }

        key += '.regular';

        return translateService.instant(key, interpolateParams);
    }
}
