<div class="membership-badge">
    <div
        class="user-badge membership-badge__elite-badge"
        *ngIf="isElite()"
    >
        <img src="/assets/img/elite-crown-only-white.svg">
    </div>
    <div
        class="user-badge membership-badge__premium-badge"
        *ngIf="isPremium()"
    >
        <img src="/assets/img/sbp-diamond-icon.svg">
    </div>
</div>
