export interface IPaymentMethods {
    [method: string]: string;
}

export type PaymentMethod = typeof PaymentMethods.PAYMENT_NONE |
    typeof PaymentMethods.PAYMENT_CREDIT_CARD |
    typeof PaymentMethods.PAYMENT_BOLETO;

export class PaymentMethods {
    public static readonly PAYMENT_NONE: string = 'none';
    public static readonly PAYMENT_CREDIT_CARD: string = 'credit_card';
    public static readonly PAYMENT_BOLETO: string = 'boleto';

    static getAvailablePaymentMethods(): IPaymentMethods {
        return {
            [PaymentMethods.PAYMENT_NONE]: PaymentMethods.PAYMENT_NONE,
            [PaymentMethods.PAYMENT_CREDIT_CARD]: PaymentMethods.PAYMENT_CREDIT_CARD,
            [PaymentMethods.PAYMENT_BOLETO]: PaymentMethods.PAYMENT_BOLETO,
        };
    }
}
