import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import {
    ProviderSelectorComponentCommon,
} from '@libs/modules/main/pages/payment/provider-selector.component.common';
import { IApplicationState } from '@libs/store/application-state';

@Component({
    selector: 'mp-card-selector',
    templateUrl: './card-selector.html',
})
export class CardSelectorComponent extends ProviderSelectorComponentCommon {
    constructor(
        protected store: Store<IApplicationState>,
    ) {
        super(store);
    }
}
