import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IGetPaymentHistoryResponse } from '@libs/modules/main/services/payment/interfaces/get-payment-history-response.interface';
import {
    PaymentInfoServiceCommon,
} from '@libs/modules/main/services/payment/payment-info.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { AuthHttpService } from '@prince/services/auth-http.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentInfoService extends PaymentInfoServiceCommon {
    constructor(
        protected authHttpService: AuthHttpService,
        protected store: Store<IApplicationState>,
    ) {
        super(authHttpService, store);
    }

    public getPaymentHistory(): Observable<IAuthResponse<IGetPaymentHistoryResponse>> {
        const endpoint: string = `${ Config.serverIp }payment/history`;

        return this.authHttp.get(endpoint).pipe(
            map((response): IAuthResponse<IGetPaymentHistoryResponse> => {
                return {
                    ...response,
                    data: {
                        payments: response.data,
                    },
                };
            }),
        );
    }

    public loadPaymentProvider() {
        const endpoint: string = `${ Config.serverIp }payment/provider`;

        return this.authHttp.get(endpoint);
    }
}
