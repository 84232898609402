import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IProfile } from '@libs/shared/profile/profile';
import { IApplicationState } from '@libs/store/application-state';
import { ProfilesSelectors } from '@libs/store/profiles-v2';
import { isNotNull } from '@libs/utils/generic-checkers/generic-checkers';

import { ModalService } from '@prince/services/modal.service';
import { ReportService } from '@prince/services/report.service';

interface IModalData {
    profileId?: number;
}

@Component({
    selector: 'mp-report-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './report-modal.html',
})
export class ReportModalComponent implements OnDestroy, IModalComponent<IModalData> {
    public isOk: boolean = true;
    public profile: IProfile | undefined;
    public profileId: number;
    public reason: string;
    protected subscriptions: Subscription[] = [];

    constructor(
        protected route: ActivatedRoute,
        protected store: Store<IApplicationState>,
        protected modalService: ModalService,
        protected reportService: ReportService,
        protected modalRef: ModalRef,
        @Inject(MODAL_DATA) public data: IModalData,
    ) {
        this.profileId = data.profileId;
        this.subscriptions.push(
            this.store.pipe(
                select(ProfilesSelectors.selectProfileById(this.profileId)),
                take(1),
            ).subscribe({
                next: (profile) => {
                    this.profile = profile;
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    close(): void {
        this.modalRef.close();
    }

    submit(): void {
        if (!this.verifyField(this.reason)) {
            this.isOk = false;
            return;
        }

        this.isOk = true;
        this.send(this.reason);
        this.close();
    }

    send(reason: string): void {
        this.reportService.report(this.profileId, reason, this.successCallback, this.errorCallback);
    }

    verifyField(reason: string): boolean {
        return isNotNull(reason);
    }

    successCallback = (): void => {
        this.modalService.open('modules.main.pages.report.success');
    };

    errorCallback = (): void => {
        this.modalService.open('common.unknown_error');
    };
}
