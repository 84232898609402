import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { FavoritesSelectors } from '@libs/store/favorites';
import { MeltProfilesActions } from '@libs/store/melt-profiles';
import { ProfilesSelectors } from '@libs/store/profiles-v2';

import { AuthenticationService } from '@prince/services/authentication.service';
import { ProfileService } from '@prince/services/profile.service';
import { ImageHelper } from '@prince/utils/image-helper';

@Component({
    selector: 'mp-profile-card',
    styleUrls: ['profile-card.scss'],
    templateUrl: 'profile-card.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileCardComponent implements OnInit, OnDestroy {
    @Input() profileId: number = 0;

    public profile$: Observable<IProfile> = of();
    public isFavorite: boolean = false;

    protected subscription: Subscription[] = [];

    constructor(
        protected store: Store,
        protected router: Router,
        protected translate: TranslateService,
        protected authenticationService: AuthenticationService,
        protected profileService: ProfileService,
    ) {
        //
    }

    ngOnInit(): void {
        this.profile$ = this.store.pipe(
            select(ProfilesSelectors.selectProfileById(this.profileId)),
        );
        this.subscription.push(
            this.store.pipe(
                select(FavoritesSelectors.selectIsFavorite(this.profileId)),
                take(1),
            ).subscribe({
                next: (isFavorite: boolean): void => {
                    this.isFavorite = isFavorite;
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.forEach(
            (subscription: Subscription): void => subscription.unsubscribe(),
        );
    }

    public getCityAndState(profile: IProfile | undefined): string {
        if (profile === undefined) {
            return this.translate.instant('common.not_set');
        }

        return this.translate.instant('modules.main.pages.profile.user_location', {
            city: profile.cityName,
            province: profile.stateName,
        });
    }

    public getProfilePicture(profile: IProfile): string {
        return ImageHelper.getProfilePicture(
            profile,
            this.authenticationService.get(),
            UserCommon.PICTURE_EXTRA_LARGE,
        );
    }

    public getNumberOfPhotos(profile: IProfile): string {
        if (profile === undefined) {
            return '';
        }

        return this.translate.instant('modules.main.pages.profile.user_number_of_photos', {
            number_photos: profile.numberOfPhotos,
        });
    }

    public toogleFavorite(profile: IProfile): void {
        if (!this.isFavorite) {
            this.addFavorite(profile);

            return;
        }

        this.removeFavorite(profile);
    }

    protected addFavorite(profile: IProfile): void {
        this.isFavorite = true;
        this.profileService.like(profile);
    }

    protected removeFavorite(profile: IProfile): void {
        this.isFavorite = false;
        this.profileService.unLike(profile);
        this.removeMelt(profile);
    }

    protected removeMelt(profile: IProfile): void {
        this.store.dispatch(
            MeltProfilesActions.removeMeltProfile({ profile }),
        );
    }

    public navigateToProfile(): void {
        this.router.navigate(
            [
                'main',
                'profile',
                this.profileId,
            ],
        );
    }

    public isOnline(profile: IProfile): boolean {
        return UserCommon.isOnline(profile);
    }
}
