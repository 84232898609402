import { Component, Input } from '@angular/core';

@Component({
    selector: 'lib-payment-security-warnings',
    templateUrl: './payment-security-warnings.html',
    styleUrls: ['./payment-security-warnings.scss'],
})
export class PaymentSecurityWarningsComponent {
    @Input() public billDescription = '';

    constructor() {
        //
    }

    public getSecurityIconPaths() {
        return [
            '/assets/img/security-ssl.gif',
            '/assets/img/trust-e.png',
            '/assets/img/security-metrics.gif',
            '/assets/img/ssl-secure-connection.gif',
        ];
    }

    public trackByPath(_: number, path: string) {
        return path;
    }
}
