import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const upsertMeltProfiles = createAction(
    '[Melt] - Upsert Melt Profiles',
    props<{ profiles: IProfile[] }>(),
);

export const upsertMeltProfile = createAction(
    '[Melt] - Upsert Melt Profile',
    props<{ profile: IProfile }>(),
);

export const removeMeltProfile = createAction(
    '[Melt] - Remove Melt Profile',
    props<{ profile: IProfile }>(),
);

export const removeAllMeltProfiles = createAction(
    '[Melt] - Remove all Melt Profiles',
);

export const MeltTTLUpdatedAction = createAction(
    '[Melt] - Updated Melt LastTimestamp Action',
    props<{ lastUpdateTimestamp: number }>(),
);
