import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { BadgesComponentCommon } from '@libs/components/badges/badges.component.common';
import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { BoostLastViewersService } from '@prince/modules/shared/boost-shared/services/boost-last-viewers/boost-last-viewers.service';

@Component({
    selector: 'mp-badges-extended',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './badges-extended.html',
})
export class BadgesExtendedComponent extends BadgesComponentCommon {
    public getAttributes: IProfileAttributes[] = [
        {
            isVerified: (): boolean => this.isMelt(),
            className: 'changed-attribute',
            imagePath: `${ Config.imagesFolderBaseUrl }badges/charm-badge.svg`,
            imageAlt: 'common.list.melt',
            extendedClassName: 'melt-badge',
            extendedImagePath: `${ Config.imagesFolderBaseUrl }badges/charm-badge-full.svg`,
            isMelt: true,
        },
        {
            isVerified: (): boolean => this.isElite(),
            imagePath: `${ Config.imagesFolderBaseUrl }badges/king-badge.svg`,
            imageAlt: 'shared.user.memberships.king',
            extendedClassName: 'elite-badge gold',
            extendedImagePath: `${ Config.imagesFolderBaseUrl }badges/king-badge-full.svg`,
        },
        {
            isVerified: (): boolean =>
                this.isPremium() &&
                this.getGender() === UserCommon.GENDER_MAN,
            imagePath: `${ Config.imagesFolderBaseUrl }badges/prince.svg`,
            imageAlt: 'shared.user.memberships.prince',
            extendedClassName: 'prince-badge',
            extendedImagePath: `${ Config.imagesFolderBaseUrl }badges/prince-badge-full.svg`,
        },
        {
            isVerified: (): boolean =>
                this.isPremium() &&
                this.getGender() === UserCommon.GENDER_WOMAN,
            imagePath: `${ Config.imagesFolderBaseUrl }badges/highlight-badge.svg`,
            imageAlt: 'shared.user.memberships.highlight',
            extendedClassName: 'highlight-badge',
            extendedImagePath: `${ Config.imagesFolderBaseUrl }badges/highlight-badge-full.svg`,
        },
        {
            isVerified: (): boolean => this.isVerified(),
            imagePath: `${ Config.imagesFolderBaseUrl }badges/verified-badge.svg`,
            imageAlt: 'modules.main.pages.profile.member_verified',
            extendedClassName: 'verified-badge',
            extendedImagePath: `${ Config.imagesFolderBaseUrl }badges/verified-badge-full.svg`,
        },
        {
            isVerified: (): boolean => this.isNew(),
            imagePath: `${ Config.imagesFolderBaseUrl }badges/new-badge.svg`,
            imageAlt: 'modules.main.pages.profile.member_new',
            interpolateParams: { gender: this.getGender() },
            extendedClassName: 'new-badge',
            extendedImagePath: `${ Config.imagesFolderBaseUrl }badges/new-badge-full.svg`,
        },
    ];

    constructor(
        protected changeDetector: ChangeDetectorRef,
        protected store: Store<IApplicationState>,
        public translate: TranslateService,
        protected boostLastViewers: BoostLastViewersService,
    ) {
        super(changeDetector, store, translate, boostLastViewers);
    }
}
