import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IAvailableTrial, ITrial } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';
import { IPaymentInfo } from '@libs/store/payment-info';
import { selectLastMembershipPayment } from '@libs/store/payment-info/selectors';
import { ITrialState } from '@libs/store/trial/interfaces/trial-state.interface';
import { TrialAdapter } from '@libs/store/trial/state';
import { TrialVersion } from '@libs/store/trial/types/trial-version.type';

const selectFeature =
    createFeatureSelector< ITrialState>('storeTrials');

export const selectAll = createSelector(
    selectFeature,
    TrialAdapter.getSelectors().selectAll,
);

export const selectIds = createSelector(
    selectFeature,
    TrialAdapter.getSelectors().selectIds,
);

export const selectGlobalTrial = createSelector(
    selectFeature,
    (state: ITrialState): IAvailableTrial => {
        return state.globalTrial;
    },
);

export const selectGameTrial = createSelector(
    selectFeature,
    (state: ITrialState): IAvailableTrial => {
        return state.entities[state.gameTrialIndex];
    },
);

export const selectLinkTrial = createSelector(
    selectFeature,
    (state: ITrialState): IAvailableTrial => {
        return state.entities[state.linkTrialIndex];
    },
);

export const selectTrialPeriodByAmountPaid = createSelector(
    selectAll,
    selectLastMembershipPayment,
    (
        allTrials: ITrial[],
        lastPayment: IPaymentInfo,
    ): number => {
        if (
            !lastPayment || !lastPayment.subtotal_amount
        ) {
            return 0;
        }

        return Trial.getLastTrialPeriod(lastPayment.subtotal_amount, allTrials);
    },
);

export const selectTrialVersion = createSelector(
    selectFeature,
    (state: ITrialState): TrialVersion => {
        return state.trialVersion;
    },
);
