import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { ISearch, SearchParser } from '@libs/shared/search/search';
import { IApplicationState } from '@libs/store/application-state';
import {
    SavedSearchReceivedAction,
} from '@libs/store/ui/actions/search.action';

@Injectable({
    providedIn: 'root',
})
export abstract class SearchServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected authHttp: AuthHttpServiceCommon,
        protected parser: SearchParser,
    ) {
        //
    }

    public abstract search(
        queryParameters: any,
        page: number,
    ): Observable<IAuthResponse>;

    public abstract getSavedSearches(
        _queryParameters: any,
        page?: number,
    ): Observable<IAuthResponse>;

    public abstract createOrUpdate(
        searchName: string,
        searchJSON: ISearch
    ): Observable<IAuthResponse>;

    public abstract delete(
        criterionId: number,
    ): Observable<IAuthResponse>;

    public holdSavedSearch(savedSearches: any[]): void {
        const decodedSearches = [];

        savedSearches.forEach((savedSearch): void => {
            decodedSearches.push({
                id: savedSearch.criterion_id,
                name: savedSearch.criterion_name,
                search: this.parser.decode(savedSearch.criterion),
            });
        });

        this.store.dispatch(new SavedSearchReceivedAction({
            savedSearches: decodedSearches,
        }));
    }
}
