<div *ngIf="!navHidden">
    <header class="visible-md visible-lg">
        <div class="container">
            <mp-user-menu></mp-user-menu>
        </div>
    </header>
</div>
<main
    class="error-404"
    [ngClass]="{full: navHidden}"
>
    <div class="error-404-inner">
        <p class="logo">
            <img
                src="/assets/img/meu-principe-logo-horizontal.svg"
                alt="MeuPríncipe"
            >
        </p>
        <p class="title">
            {{ 'modules.page_not_found.title' | translate }}
        </p>
        <p class="blurb">
            {{ 'modules.page_not_found.blurb' | translate }}
        </p>
        <p>
            <a
                class="back-to-start"
                [routerLink]="['/']"
            >
                {{ 'modules.page_not_found.back_to_start' | translate }}
            </a>
        </p>
    </div>
</main>
<mp-responsive-menu *ngIf="!navHidden"></mp-responsive-menu>
