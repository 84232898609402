<div class="badges-container">
    <ng-template
        ngFor="ngFor"
        let-attribute
        [ngForOf]="getAttributes"
    >
        <div
            class="profile-badge"
            [class]="attribute.extendedClassName"
            *ngIf="attribute.isVerified()"
        >
            <img [src]="attribute.extendedImagePath" [alt]="getTranslate(attribute)">
            <p *ngIf="!attribute.isMelt">
                {{ getTranslate(attribute) }}
            </p>
        </div>
    </ng-template>
</div>
