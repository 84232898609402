import { Action } from '@ngrx/store';

import { IStats } from '@libs/shared/user/stats';

export const GTM_STATS_ACTION: string = 'GTM_STATS_ACTION';

export class GtmStatsAction implements Action {
    readonly type: string = GTM_STATS_ACTION;

    constructor(public payload: IStats) {
        //
    }
}
