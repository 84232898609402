import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import {
    IPaymentOptionBrandData,
} from '@libs/modules/main/pages/payment-method-option/payment-option-brand-data';
import {
    PaymentMethod,
    PaymentMethods,
} from '@libs/modules/main/pages/payment/payment-methods';
import {
    PaymentComponentCommon,
} from '@libs/modules/main/pages/payment/payment.component.common';
import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentSelectors } from '@libs/store/payment-info';
import { TrialActions } from '@libs/store/trial';

import { Config } from '@prince/config';
import { IPlanCheckoutTitle } from '@prince/modules/shared/actionbar/actionbar-checkout/interfaces/plan-checkout-title.interface';
import { ModalService } from '@prince/services/modal.service';
import { TrialService } from '@prince/services/trial/trial.service';

@Component({
    selector: 'mp-payment',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './payment.html',
    styleUrls: ['./payment.scss'],
})
export class PaymentComponent extends PaymentComponentCommon implements OnInit, OnDestroy {
    protected paymentOptions$: Observable<IPaymentOption[]>;
    public provider$: Observable<string> = of('');
    public readonly billDescription = PaymentCommon.PAYMENT_BILL_DESCRIPTION_PRINCE;

    constructor(
        protected route: ActivatedRoute,
        protected readonly router: Router,
        protected store: Store<IApplicationState>,
        protected translate: TranslateService,
        protected trialService: TrialService,
        protected modalService: ModalService,

    ) {
        super(store, trialService, translate, route);
    }

    ngOnInit(): void {
        this.receiveParams();
        this.provider$ = this.store.pipe(
            select(PaymentSelectors.selectSelectedProvider),
        );
    }

    @HostListener('window:beforeunload', ['$event']) onClose(): void {
        this.handleRemoveGamefyTrial();
    }

    @HostListener('window:pagehide', ['$event']) onCloseIos(): void {
        this.handleRemoveGamefyTrial();
    }

    ngOnDestroy(): void {
        this.handleRemoveGamefyTrial();
    }

    public isCardPayment(): boolean {
        return this.currentPaymentMethod === PaymentMethods.PAYMENT_CREDIT_CARD;
    }

    protected handleRemoveGamefyTrial(): void {
        if (!this.isTrial()) {
            return;
        }

        this.store.dispatch(TrialActions.setGameTrial({
            period: null,
        }));
    }

    public getInstallmentsInterestWarning(): string {
        return 'modules.main.pages.payment.checkout.interest_warning';
    }

    public canBePaidWithInstallments(): boolean {
        return this.period >= 3;
    }

    public getSpotPrice(price: number): string {
        if (
            this.isTrial() ||
            this.isExpressApproval()
        ) {
            return this.translate.instant('modules.main.pages.payment.price.without_installments', {
                price,
            });
        }

        return this.translate.instant('modules.main.pages.payment.price', {
            price,
        });
    }

    public getActiveMethodButtonModifierClass(
        method: PaymentMethod,
    ): string {
        if (method !== this.currentPaymentMethod) {
            return '';
        }

        return 'payment-checkout__payment-method-selector__button--active';
    }

    public getPaymentMethods(): IPaymentOptionBrandData[] {
        const paymentMethods: string[] = [
            ...Config.paymentBrands,
            PaymentMethods.PAYMENT_BOLETO,
        ];

        return paymentMethods.map(
            (method: string): IPaymentOptionBrandData => {
                return {
                    name: method.toLowerCase(),
                    format: '.gif',
                };
            },
        );
    }

    public getMethodIcon(brand: IPaymentOptionBrandData): string {
        return `${ Config.imagesFolderBaseUrl }${ brand.name }${ brand.format }`;
    }

    public getProviderText(provider: string): string {
        return this.translate.instant('modules.main.pages.payment.checkout.additional_information', {
            provider,
        });
    }

    public getSecurityIcons(): string[] {
        return [
            'security-ssl.gif',
            'trust-e.png',
            'security-metrics.gif',
            'ssl-secure-connection.gif',
        ].map(
            (icon: string): string => `${ Config.imagesFolderBaseUrl }${ icon }`,
        );
    }

    public getPlanCheckoutTitle(): IPlanCheckoutTitle {
        const planTitles: {
            [membershipId: number]: IPlanCheckoutTitle
        } = {
            [MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM]: {
                title: 'shared.user.memberships.highlight',
                titleColorVariable: 'radRed',
                iconPath: 'highlight-badge.svg',
            },
            [MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM]: {
                title: 'shared.user.memberships.prince',
                titleColorVariable: 'galaxyBlue',
                iconPath: 'prince-badge.svg',
            },
            [MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE]: {
                title: 'shared.user.memberships.king',
                titleColorVariable: 'ui8',
                iconPath: 'king-badge.svg',
            },
        };

        if (!planTitles[this.product.identifier]) {
            return {
                title: '',
                titleColorVariable: '',
                iconPath: '',
            };
        }

        return planTitles[this.product.identifier];
    }

    public getSelectedPlan(): string {
        if (this.isExpressApproval()) {
            return this.translate.instant('modules.main.pages.payment.product_types.express_approval');
        }

        return this.getPlanDuration();
    }

    public openChatbox(): void {
        //
    }
}
