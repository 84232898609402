import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IMeltToastState } from '@libs/store/melt-toast';
import { meltToastAdapter } from '@libs/store/melt-toast/state';

const selectFeature = createFeatureSelector<IMeltToastState>('meltToast');

export const selectAll = createSelector(
    selectFeature,
    meltToastAdapter.getSelectors().selectAll,
);
