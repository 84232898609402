<div class="container">
    <div class="row">
        <div class="col-sm-8 col-sm-offset-2 col-lg-6 col-lg-offset-3 no-padding">
            <div class="incomplete-modal-box full-img-bg">
                <div class="incomplete-modal-main-header absolute">
                    <button
                        class="modal-close right-btn"
                        type="button"
                        (click)="closeModal()"
                    >
                        <img src="/assets/img/close-icon-white.svg">
                    </button>
                </div>
                <div>
                    <p class="title">
                        {{ 'modules.frictionless.modal.incomplete.title' | translate }}
                    </p>
                    <p class="description">
                        <span>
                            {{ 'modules.frictionless.modal.incomplete.desc1' | translate }}
                        </span>
                        <br>
                        <span>
                            {{ 'modules.frictionless.modal.incomplete.desc2' | translate }}
                        </span>
                    </p>
                    <div class="actions">
                        <a
                            class="mp-main-button bt-md outline-white"
                            [routerLink]="['/main', 'edit-profile']"
                        >
                            {{ 'modules.frictionless.modal.edit' | translate }}
                        </a>
                        <a
                            class="mp-main-button bt-md"
                            [routerLink]="['/main', 'upgrade-account']"
                        >
                            {{ 'modules.frictionless.modal.buy' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
