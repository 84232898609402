import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, Subject } from 'rxjs';
import {
    map,
    switchMap,
} from 'rxjs/operators';

import { IDecrementResponse, ILatestBoost } from '@libs/modules/boost/interface/boost-bff-endpoints';
import { StateActivatable, StateActivated, BoostState } from '@libs/modules/boost/interface/boost-states';
import { BoostProgressServiceCommon } from '@libs/modules/boost/services/boost-progress/boost-progress.service.common';
import {
    DownloadManagerServiceCommon,
} from '@libs/modules/main/services/download-manager.service.common';
import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { BoostActions } from '@libs/store/boost';
import { BoostLastViewersActions } from '@libs/store/boost-last-viewers';
import { ProfilesSelectors } from '@libs/store/profiles-v2';
import { PushNotificationActions } from '@libs/store/push-notification';

@Injectable({
    providedIn: 'root',
})
export abstract class BoostServiceCommon implements OnDestroy {
    protected readonly COUNT_PROFILES: number = 6;
    protected subscriptions: Subscription[] = [];

    public activateBoostSubject: Subject<boolean> = new Subject<boolean>();
    public boostStateSubject$: Subject<BoostState> = new Subject<BoostState>();

    constructor(
        protected store: Store<IApplicationState>,
        protected downloadManagerService: DownloadManagerServiceCommon,
        protected profileService: ProfileServiceCommon,
        protected boostProgressService: BoostProgressServiceCommon,
        protected authHttp: AuthHttpServiceCommon,
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
        this.boostStateSubject$.complete();
    }

    public getBoostProfiles$(numberOfProfiles: number = 6): Observable<IProfile[]> {
        return this.store.select('storeFeaturedProfiles').pipe(
            switchMap((profilesIds: number[]): Observable<IProfile[]> => {
                return this.handleProfiles$(profilesIds, numberOfProfiles);
            }),
        );
    }

    public downloadBoostProfileList(): void {
        const firstPage: number = 1;

        if (this.downloadManagerService.isDownloading('featured')) {
            return;
        }

        this.subscriptions.push(this.downloadManagerService
            .updatePage('featured', undefined, firstPage)
            .subscribe(),
        );
    }

    public initializeBoost(): void {
        this.boostProgressService.startCountdownSequence();
        this.startBoostEndResultsCountdown();
        this.setCanShowBoostModalResults();
        this.store.dispatch(BoostLastViewersActions.updateLastViewersDuringBoost());
    }

    startBoostEndResultsCountdown(): void {
        this.store.dispatch(BoostActions.initializeBoostEndResultsCountdown());
    }

    setCanShowBoostModalResults(): void {
        this.store.dispatch(BoostActions.setCanShowBoostModalResults({
            canShowBoostModalResults: true,
        }));
    }

    changeBoostState(boostState: BoostState): void {
        this.boostStateSubject$.next(boostState);
    }

    protected get user(): UserCommon {
        return this.auth.get();
    }

    protected handleProfiles$(
        profilesId: number[],
        numberOfProfiles: number,
    ): Observable<IProfile[]> {
        return this.store.pipe(
            select(ProfilesSelectors.selectAllProfiles),
            map((profiles: IProfile[]): IProfile[] => {
                return this.getEligibleProfiles(
                    profiles,
                    profilesId,
                    numberOfProfiles,
                );
            }),
        );
    }

    protected getEligibleProfiles(
        profiles: IProfile[],
        profilesId: number[],
        numberOfProfiles: number,
    ): IProfile[] {
        const featuredProfiles: IProfile[] = this.pickFeaturedProfiles(
            profiles,
            profilesId,
        );

        return this.handleEligibleProfiles(
            this.filterBlockedProfiles(featuredProfiles),
        ).slice(0, numberOfProfiles);
    }

    protected pickFeaturedProfiles(profiles: IProfile[], featuredProfiles: number[]): IProfile[] {
        return profiles.filter((profile: IProfile): boolean => {
            if (typeof profile.profile_id === 'undefined') {
                return false;
            }

            return featuredProfiles.includes(profile.profile_id);
        });
    }

    protected handleEligibleProfiles(profiles: IProfile[]): IProfile[] {
        if (!UserCommon.isBaby(this.user)) {
            return profiles;
        }

        return this.filterProfilesWithNoPicture(profiles);
    }

    protected filterBlockedProfiles(profiles: IProfile[]): IProfile[] {
        return profiles.filter((profile: IProfile): boolean => {
            return !this.profileService.isProfileBlocked(profile);
        });
    }

    protected filterProfilesWithNoPicture(profiles: IProfile[]): IProfile[] {
        return profiles.filter((profile: IProfile): boolean => {
            if (typeof profile.photo === 'undefined') {
                return false;
            }

            return profile.photo &&
                typeof profile.photo === 'object' &&
                profile.photo.photo_id !== undefined;
        });
    }

    public abstract getLatestBoostActivation$(): Observable<ILatestBoost>;

    handleBoostState(hasBoostEnded: boolean): void {
        if (!hasBoostEnded) {
            this.changeBoostState(StateActivated);

            return;
        }

        this.changeBoostState(StateActivatable);
    }

    public abstract decrementBoostCredit(): Observable<IDecrementResponse>;

    public hasNotMinimumCredits(boostCredits: number): boolean {
        const minimumOfCredits: number = 100;

        return boostCredits < minimumOfCredits;
    }

    addBoostViewPushNotification(profileId: number): void {
        this.store.dispatch(PushNotificationActions.createProfilePush({
            profileId,
            parameters: {
                title: 'modules.main.services.notification.boost.title',
                options: {
                    body: 'modules.main.services.notification.boost.body',
                },
                extraParameters: {
                    route: [
                        'main',
                        'profile',
                        profileId,
                    ],
                },
            },
        }));
    }
}
