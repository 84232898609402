<div class="dial-box verify-modal">
    <div class="waiting-image">
        <button
            class="close-button"
            (click)="closeModal()"
        >
            <i
                class="material-icons"
                aria-hidden="true"
            >
                close
            </i>
        </button>
        <h1 class="header-text">
            {{ 'modules.main.pages.photo_verify.title' | translate }}
        </h1>
        <p class="verify-description">
            {{ 'modules.main.pages.photo_verify.title_description' | translate }}
        </p>
        <div class="sidebar-img">
            <img
                class="responsive-modal-img hidden-sm hidden-md hidden-lg"
                [src]="getModalImage()"
            >
        </div>
        <h2>
            {{ 'modules.main.pages.photo_verify.step1' | translate }}
        </h2>
        <p>
            {{ 'modules.main.pages.photo_verify.step1_description' | translate }}
        </p>
        <h2>
            {{ 'modules.main.pages.photo_verify.step2' | translate }}
        </h2>
        <form class="buttons-container">
            <label class="file-input button mp-main-button bt-lg btn btn-lg">
                {{ 'modules.main.pages.photo_verify.step2_btn' | translate }}
                <input
                    type="file"
                    [accept]="getAcceptedImageTypes()"
                    (change)="cropPhoto($event)"
                >
            </label>
        </form>
        <h2>
            {{ 'modules.main.pages.photo_verify.step3' | translate }}
        </h2>
        <p>
            {{ 'modules.main.pages.photo_verify.step3_description' | translate }}
        </p>
        <div class="profile-badge badge-verified">
            <img src="/assets/img/verified-green.svg">
            <div>
                {{ 'modules.main.pages.profile.member_verified' | translate }}
            </div>
        </div>
        <div class="warning-text">
            <strong class="warning-title">
                {{ 'modules.main.pages.photo_verify.warning' | translate }}
            </strong>
            {{ 'modules.main.pages.photo_verify.warning_text' | translate }}
        </div>
    </div>
    <div class="sidebar-img hidden-xs">
        <img [src]="getModalImage()">
    </div>
</div>
<div
    class="modal"
    *ngIf="isUploading()"
>
    <mp-loading></mp-loading>
</div>
