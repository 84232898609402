import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { closeSlidableMenu } from '@libs/store/slidable-menu/action';

import { Config } from '@prince/config';
@Component({
    styleUrls: ['button-photo-verify.scss'],
    templateUrl: 'button-photo-verify.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonPhotoVerifyComponent {
    constructor(
        protected router: Router,
        protected store: Store,
    ) {
        //
    }

    public getPhotoVerifyButtonIcon(): string {
        return `${ Config.imagesFolderBaseUrl }badges/verified-badge-full.svg`;
    }

    public closeMenuAndNavigateToPhotoVerify(): void {
        this.store.dispatch(closeSlidableMenu());
        this.router.navigate(['main', 'photo-verify']);
    }
}
