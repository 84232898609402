import { Action } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const PROFILES_NEARBY_RECEIVED_ACTION: string = 'PROFILES_NEARBY_RECEIVED_ACTION';
export const PROFILES_NEARBY_REFRESHED_ACTION: string = 'PROFILES_NEARBY_REFRESHED_ACTION';
export const PROFILES_NEARBY_REMOVED_ACTION: string = 'PROFILES_NEARBY_REMOVED_ACTION';
export const NEARBY_TTL_UPDATED_ACTION: string = 'NEARBY_TTL_UPDATED_ACTION';

export interface IProfilesNearbyReceivedActionPayload {
    profiles: IProfile[];
}

export class ProfilesNearbyReceivedAction implements Action {
    readonly type: string = PROFILES_NEARBY_RECEIVED_ACTION;

    constructor(public payload: IProfilesNearbyReceivedActionPayload) {
        //
    }
}

export class ProfilesNearbyRefreshedAction implements Action {
    readonly type: string = PROFILES_NEARBY_REFRESHED_ACTION;
}

export interface INearByTTLActionPayload {
    ttl: number;
}

export class NearByTTLUpdatedAction implements Action {
    readonly type: string = NEARBY_TTL_UPDATED_ACTION;

    constructor(public payload: INearByTTLActionPayload) {
        //
    }
}

export class ProfileNearbyRemovedAction implements Action {
    readonly type: string = PROFILES_NEARBY_REMOVED_ACTION;

    constructor(public payload: IProfilesNearbyReceivedActionPayload) {
        //
    }
}
