import { IUpgradeAccountVersions } from '@libs/store/upgrade-account/interfaces/upgrade-account-versions.interface';

export const UPGRADE_ACCOUNT_VERSIONS: IUpgradeAccountVersions = {
    premiumWithPrize: '7f29f654-0abc-4cb7-a956-37a6a9894111',
    standardPlans: 'b9c8420a-b15f-4ee9-a71b-d6fcf1a1f2d2',
};

export type UpgradeAccountVersion =
    typeof UPGRADE_ACCOUNT_VERSIONS.premiumWithPrize |
    typeof UPGRADE_ACCOUNT_VERSIONS.standardPlans;
