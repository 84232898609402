import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ViewStyle } from '@libs/components/submenu-v2/interfaces/list-view-style.type';
import { UiActions, UiSelectors } from '@libs/store/new-ui';

@Component({
    selector: 'view-style-selector',
    styleUrls: ['./view-style-selector.scss'],
    templateUrl: './view-style-selector.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewStyleSelectorComponent implements OnInit, OnDestroy {
    public activeStyle: ViewStyle = 'list';

    protected subscriptions: Subscription[] = [];

    constructor(
        protected store: Store,
    ) {
        //
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.pipe(
                select(UiSelectors.selectPreferredViewStyle),
            ).subscribe({
                next: (viewStyle: ViewStyle): void => {
                    this.activeStyle = viewStyle;
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(
            (subscription: Subscription): void => {
                subscription.unsubscribe();
            },
        );
        this.subscriptions = [];
    }

    public setViewStyle(viewStyle: ViewStyle): void {
        this.store.dispatch(UiActions.setPreferredViewStyle({
            viewStyle,
        }));
    }

    public isListStyleActive(): boolean {
        return this.activeStyle === 'list';
    }
}
