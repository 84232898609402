import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { EMPTY, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

interface IScrollPosition {
    percent: number;
}

const DEFAULT_SCROLL_POSITION: IScrollPosition = {
    percent: 0,
};

@Directive({
    selector: '[mp-infinite-scroll]',
})
export class InfiniteScrollDirective implements AfterViewInit, OnDestroy {
    @Input() scrollCallback:
        ((positions?: [IScrollPosition, IScrollPosition]) => void) | undefined;
    @Input() immediateCallback: boolean = true;
    @Input() scrollPercent: number = 70;
    @Input() trackElement: boolean = false;

    protected scrollEvent$: Observable<Event> = EMPTY;
    protected userScrolledDown$:
        Observable<[IScrollPosition, IScrollPosition]> = EMPTY;
    protected requestOnScroll$:
        Observable<[IScrollPosition, IScrollPosition]> = EMPTY;

    protected subscriptions: Subscription[] = [];

    constructor(protected elm: ElementRef) {
        //
    }

    ngAfterViewInit(): void {
        this.registerNgScroll();
        this.streamScrollEvents();
        this.requestCallbackOnScroll();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    registerNgScroll(): void {
        if (this.trackElement) {
            this.scrollEvent$ = fromEvent(this.elm.nativeElement, 'scroll');

            return;
        }

        this.scrollEvent$ = fromEvent(window, 'scroll');
    }

    protected streamScrollEvents(): void {
        this.userScrolledDown$ = this.scrollEvent$.pipe(
            map((): IScrollPosition => this.getScrollPercentage()),
            pairwise(),
            filter((positions): boolean => this.isUserScrollingDown(positions) &&
                this.isScrollExpectedPercent(positions[1])),
        );
    }

    protected getScrollPercentage(): IScrollPosition {
        if (this.trackElement) {
            return {
                percent: this.elm.nativeElement.scrollTop / (
                    this.elm.nativeElement.scrollHeight -
                    this.elm.nativeElement.clientHeight
                ),
            };
        }

        return {
            percent: (
                document.documentElement.scrollTop ||
                document.body.scrollTop
            ) / (
                (
                    document.documentElement.scrollHeight ||
                    document.body.scrollHeight
                ) - document.documentElement.clientHeight
            ),
        };
    }

    protected requestCallbackOnScroll(): void {
        this.requestOnScroll$ = this.userScrolledDown$;
        const defaultPos: [IScrollPosition, IScrollPosition] = [
            { ...DEFAULT_SCROLL_POSITION },
            { ...DEFAULT_SCROLL_POSITION },
        ];

        if (this.immediateCallback) {
            this.requestOnScroll$ = this.requestOnScroll$.pipe(startWith(defaultPos));
        }

        this.subscriptions.push(this.requestOnScroll$
            .subscribe(this.scrollCallback, (err): void => console.log(err)),
        );
    }

    protected isUserScrollingDown = ([before, after]: [IScrollPosition, IScrollPosition]): boolean => {
        return before.percent < after.percent;
    };

    protected isScrollExpectedPercent = (position: IScrollPosition): boolean => {
        return position.percent > (this.scrollPercent / 100);
    };
}
