import '@prince/polyfills';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@prince/app.module';
import { Config } from '@prince/config';

if (!Config.development) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
    ngZoneEventCoalescing: true,
});
