import {
    ChangeDetectionStrategy,
    Component,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { ComponentNames } from '@libs/effects/toast/common/component-names';

import { MpToastAnimations } from '@prince/modules/shared/mp-toast/mp-toast.animations';

import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: '.mp-toast',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './mp-toast.html',
    animations: MpToastAnimations,
})
export class MPToastComponent extends Toast {
    static componentName: string = ComponentNames.DEFAULT_TOAST_COMPONENT;

    @ViewChild('mpToastContent', { read: ViewContainerRef, static: true })
    mpToastContent: ViewContainerRef | undefined;

    public hasCustomContent: boolean = false;
    public icon: string = '';
    public images: string[] = [];

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
    ) {
        super(toastrService, toastPackage);

        [this.icon, ...this.images] = toastPackage.toastType.split('|');
    }

    public get cleanTitle(): string {
        if (this.title === undefined) {
            return '';
        }

        return this.title.replace(/\|/, '');
    }

    public get username(): string {
        if (this.title === undefined) {
            return '';
        }

        return this.title.split('|')[0];
    }

    public get restOfTitle(): string {
        if (this.title === undefined) {
            return '';
        }

        return this.title.split('|')[1];
    }

    public trackByFn(index: number): number {
        return index;
    }

    public showIcon(icon: string): boolean {
        return this.icon === icon;
    }

    public getCustomToastProgressBarModifierClass(): string {
        if (!this.hasCustomContent) {
            return '';
        }

        return 'custom-toast';
    }
}
