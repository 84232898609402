import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import { IToastComponent } from '@libs/shared/toast/interfaces/toast-component.interface';
import { ToastType } from '@libs/shared/toast/interfaces/toast-parameters.interface';

enum Actions {
    CREATE_TOAST = '[Toast] - Create',
    INITIALIZE_NEW_TOAST = '[Toast] - Initialize New Toast',
    INITIALIZE_NEW_VIEW_TOAST = '[Toast] - Initialize New View Toast',
    DOWNLOAD_PROFILE_FOR_TOAST = '[Toast] - Download Profile For Toast',
    SHOW_TOAST_PROFILE = '[Toast] - Show Toast Profile',
}

export const initializeNewToast = createAction(
    Actions.INITIALIZE_NEW_TOAST,
    props<{ profileId: number, toastType: ToastType }>(),
);

export const initializeNewViewToast = createAction(
    Actions.INITIALIZE_NEW_VIEW_TOAST,
    props<{ profileId: number }>(),
);

export const downloadProfileForToast = createAction(
    Actions.DOWNLOAD_PROFILE_FOR_TOAST,
    props<{ profileId: number, toastType: ToastType }>(),
);

export const createToast = createAction(
    Actions.CREATE_TOAST,
    props<{
        profile: IProfile,
        toastType: ToastType,
        component: IToastComponent,
    }>(),
);

export const showToastProfile = createAction(
    Actions.SHOW_TOAST_PROFILE,
    props<{ toastType: ToastType, profile: IProfile }>(),
);
