import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PaymentInfoServiceCommon } from '@libs/modules/main/services/payment/payment-info.service.common';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IPaymentInfo, PaymentInfoStatus, PaymentInfoType } from '@libs/store/payment-info/interface';
import { IPaymentInfoState, paymentInfoAdapter } from '@libs/store/payment-info/state';

export const selectPaymentInfoState = createFeatureSelector<IPaymentInfoState>('paymentInfoEntity');

export const selectAll = createSelector(
    selectPaymentInfoState,
    paymentInfoAdapter.getSelectors().selectAll,
);

export const selectPaymentStatus = createSelector(
    selectPaymentInfoState,
    (state: IPaymentInfoState): PaymentStatus => state.paymentStatus,
);

export const selectIsPaying = createSelector(
    selectPaymentInfoState,
    (state: IPaymentInfoState): boolean => state.isPaying,
);

export const selectIsPaymentSuccess = createSelector(
    selectPaymentInfoState,
    (state: IPaymentInfoState, props: { paymentInfoService: PaymentInfoServiceCommon }): boolean => {
        return props.paymentInfoService.isPaymentOk(state.paymentStatus);
    },
);

export const selectIsPaymentProcessing = createSelector(
    selectPaymentInfoState,
    (state: IPaymentInfoState): boolean => {
        return state.paymentStatus === PaymentStatus.PAYMENT_PROCESSING;
    },
);

export const selectLastMembershipPayment = createSelector(
    selectAll,
    (
        lastPayments: IPaymentInfo[],
    ): IPaymentInfo => {
        const filteredMembershipPayments: IPaymentInfo[] = lastPayments.filter(
            (payment: IPaymentInfo): boolean => {
                return (
                    payment.payment_type === PaymentInfoType.TypeUserTriggered ||
                    payment.payment_type === PaymentInfoType.TypeSystemTriggered ||
                    payment.payment_type === PaymentInfoType.TypeBoleto ||
                    payment.payment_type === PaymentInfoType.TypeCoupon
                ) && payment.product_uuid === null &&
                payment.status !== PaymentInfoStatus.StatusRefunded;
            });

        if (filteredMembershipPayments.length === 0) {
            return { };
        }

        return PaymentCommon.findMostRecentPayment(filteredMembershipPayments);
    },
);

export const selectHasResolvedPlans = createSelector(
    selectPaymentInfoState,
    (state: IPaymentInfoState): boolean => {
        return state.hasResolvedPlans;
    },
);

export const selectBoletoUrl = createSelector(
    selectPaymentInfoState,
    (state: IPaymentInfoState): string => {
        return state.boletoUrl;
    },
);
