import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IBirthdateFields } from '@libs/registration-prince/domain/interfaces/birthdate-fields.interface';
import { INewRegistrationPayload } from '@libs/registration-prince/domain/interfaces/new-registration-payload.interface';
import { IRegistrationAboutYou } from '@libs/registration-prince/domain/interfaces/registration-about-you.interface';
import { IRegistrationInitialStep } from '@libs/registration-prince/domain/interfaces/registration-initial-step.interface';
import { IRegistrationLoginData } from '@libs/registration-prince/domain/interfaces/registration-login-data.interface';
import { IRegistrationPersonalData } from '@libs/registration-prince/domain/interfaces/registration-personal-data.interface';

const selectFeature = createFeatureSelector<INewRegistrationPayload>('registrationPrince');

export const selectInitialStep = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationInitialStep => {
        return state.initialStep;
    },
);

export const selectLoginData = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationLoginData => {
        return state.loginData;
    },
);

export const selectPersonalData = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationPersonalData => {
        return state.personalData;
    },
);

export const selectIsCroppingImage = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): boolean | undefined => {
        return state.isCroppingImage;
    },
);

export const selectAboutYou = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationAboutYou => {
        return state.aboutYou;
    },
);

export const selectIfTriedDirectAccessToModalRoute = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): boolean => {
        return state.hasAccessedModalRoute;
    },
);

export const selectIsSubmittingForm = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): boolean => {
        return state.isSubmittingForm;
    },
);

export const selectBirthdate = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IBirthdateFields => {
        return state.personalData.birthdate;
    },
);

export const selectEmail = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): string => {
        return state.loginData.email;
    },
);

export const selectSubmittingFormText = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): string => {
        return state.submittingFormText;
    },
);

export const selectAll = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): INewRegistrationPayload => {
        return state;
    },
);

export const selectHasSubmittedForm = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): boolean => {
        return state.hasSubmittedForm;
    },
);

export const userGender = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): number => {
        return state.initialStep.userGender;
    },
);
