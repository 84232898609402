import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
} from '@ngrx/entity';

import { IReason } from '@libs/store/deactivate-reason/interface';

export interface IDeactivateReasonsState extends EntityState<IReason> {
    currentReasonTextSelected: string | null;
}

function selectReasonId(reason: IReason): number {
    return reason.value;
}

export const reasonAdapter: EntityAdapter<IReason> = createEntityAdapter<IReason>({
    selectId: selectReasonId,
});

export const deactivateReasonInitialState: IDeactivateReasonsState =
    reasonAdapter.getInitialState({
        currentReasonTextSelected: null,
    });
