import { createAction, props } from '@ngrx/store';

import { ViewStyle } from '@libs/components/submenu-v2/interfaces/list-view-style.type';
import { IWebSocketStatus } from '@libs/interfaces/socket/socket.service.interface';

enum Actions {
    SET_IS_ATTEMPTING_LOGIN = '[Login] - Set Is Attempting Login',
    SET_PREFERRED_VIEW_STYLE = '[UI] - Set Preferred View Style',
    SET_SEARCH_LOCATION_OPTION = '[Search] - Set Search Location Option',
    SET_IS_DOWNLOADING_LISTS = '[Lists] - Set Is Downloading Lists',
    SET_HAS_REDIRECTED_ON_SESSION = '[ABTests] - Set Has Redirected on Session',
    UPDATE_LIST_FIRST_UPDATE_TIMESTAMP = '[Lists] - Update List First Update Timestamp',
    SET_LIST_FIRST_UPDATE_TIMESTAMP = '[Lists] - Set List First Update Timestamp',
    REFRESH_PRIMARY_LISTS = '[Lists] - Refresh Primary Lists',
    SET_IS_REFRESH_BUTTON_VISIBLE = '[UI] - Set Is Refresh Button Visible',
    SET_IS_UPSELL_VISIBLE = '[UI] - Set Is Upsell Visible',
    SET_WEBSOCKET_STATUS = '[UI] - Set Websocket Status',
}

export const setIsAttemptingLogin = createAction(
    Actions.SET_IS_ATTEMPTING_LOGIN,
    props<{ isAttemptingLogin: boolean }>(),
);

export const setPreferredViewStyle = createAction(
    Actions.SET_PREFERRED_VIEW_STYLE,
    props<{ viewStyle: ViewStyle }>(),
);

export const setSearchLocationOption = createAction(
    Actions.SET_SEARCH_LOCATION_OPTION,
    props<{ searchLocationOption: number }>(),
);

export const setIsDownloadingLists = createAction(
    Actions.SET_IS_DOWNLOADING_LISTS,
    props<{ isDownloadingLists: boolean }>(),
);

export const setHasRedirectedOnSession = createAction(
    Actions.SET_HAS_REDIRECTED_ON_SESSION,
);

export const updateListFirstUpdateTimestamp = createAction(
    Actions.UPDATE_LIST_FIRST_UPDATE_TIMESTAMP,
);

export const setListFirstUpdateTimestamp = createAction(
    Actions.SET_LIST_FIRST_UPDATE_TIMESTAMP,
    props<{ listFirstUpdateTimestamp: number, isPageRefresh?: boolean }>(),
);

export const setIsRefreshButtonVisible = createAction(
    Actions.SET_IS_REFRESH_BUTTON_VISIBLE,
    props<{ isRefreshButtonVisible: boolean }>(),
);

export const refreshPrimaryLists = createAction(
    Actions.REFRESH_PRIMARY_LISTS,
);

export const setIsUpsellVisible = createAction(
    Actions.SET_IS_UPSELL_VISIBLE,
    props<{ isOpen: boolean }>(),
);

export const setWebSocketStatus = createAction(
    Actions.SET_WEBSOCKET_STATUS,
    props<{ webSocketStatus: IWebSocketStatus }>(),
);
