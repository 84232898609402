import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { FreeMsgModalComponentCommon } from '@libs/modules/shared/modals/free-msg-modal/free-message-modal.component.common';
import { IFreeMsgModalData } from '@libs/modules/shared/modals/free-msg-modal/interfaces/free-message-modal-data.interface';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { MembershipService } from '@prince/services/membership.service';
import { TrialRuleService } from '@prince/services/trial.rule.service';
import { TrialService } from '@prince/services/trial/trial.service';

@Component({
    selector: 'mp-free-msg-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './free-msg-modal.html',
})
export class FreeMsgModalComponent extends FreeMsgModalComponentCommon implements OnDestroy, OnInit {
    constructor(
        protected store: Store<IApplicationState>,
        protected membershipService: MembershipService,
        protected router: Router,
        protected modalRef: ModalRef,
        protected trialService: TrialService,
        protected trialRuleService: TrialRuleService,
        @Inject(MODAL_DATA) protected modalData: IFreeMsgModalData,
    ) {
        super(store, modalData);
    }

    closeModal(): void {
        this.modalRef.close();
        this.prepareTrial();
    }

    goToUpgradeAccount(): void {
        this.closeModal();
        if (!this.membershipService.isAlreadyPaid()) {
            this.router.navigate(['main', 'premium']);
            return;
        }
        this.router.navigate(['main', 'upgrade-account']);
    }

    canShowGameTrial(): boolean {
        return this.isTrialGameSatisfied() && Config.gameTrial.enableMessageGame;
    }

    isTrialGameSatisfied(): boolean {
        return (
            !this.alreadySentFreeMessage &&
            this.trialRuleService
                .trialRuleFactory(this.trialRuleService.CONVERSATION_TYPE)
                .satisfied()
        );
    }

    protected prepareTrial(): void {
        if (!this.canShowGameTrial()) {
            return;
        }

        this.trialService.setGameTrialAccordingToGlobal();
        this.trialService.startGamefiedTrial({
            firstTimer: this.trialService.MILLISECONDS_IN_A_MINUTE,
            satisfied: this.trialService.isGameTrialFreeMessageSatisfied,
            retryStrategy: {
                retryWhen: this.trialService.getGameTrialFreeMessageRule,
                millisecondsRetry: this.trialService.ONE_SECOND_IN_MILLISECONDS,
            },
            trial: this.trialService.getGameTrial(),
        });
    }
}
