<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 col-md-offset-3"></div>
    </div>
</div>
<div class="content-maintenance">
    <div class="content-maintenence-inner">
        <p class="logo">
            <img
                src="/assets/img/meu-patrocinio-logo.svg"
                alt="MeuPríncipe"
            >
        </p>
        <p class="title">
            {{ 'modules.initial.pages.maintenance.maintenance_message' | translate }}
        </p>
        <p class="blurb">
            {{ 'modules.initial.pages.maintenance.maintenance_message_soon' | translate }}
        </p>
        <p>
            <a
                class="update-page"
                [routerLink]="['/main']"
            >
                {{ 'common.refresh' | translate }}
            </a>
        </p>
    </div>
</div>
