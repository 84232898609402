<div class="cropper-wrapper">
    <div
        class="loading-block"
        *ngIf="isLoading"
    >
        <div class="spinner"></div>
    </div>
    <div
        class="alert alert-warning"
        *ngIf="imageLoadFailed"
    >
        {{ loadImageErrorText }}
    </div>
    <div class="cropper">
        <img
            #image
            alt="image"
            [src]="imageUrl"
            (load)="imageLoaded($event)"
            (error)="imageLoadError($event)"
        >
    </div>
</div>
