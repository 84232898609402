import { INewRegistrationPayload } from '@libs/modules/registration/domain/interfaces/new-registration-payload.interface';
import { Registration } from '@libs/modules/registration/domain/registration';

export const registrationInitialState: INewRegistrationPayload = {
    initialStep: {
        sugarRole: 0,
        userGender: 0,
        userLookingFor: 0,
    },
    personalInfo: {
        username: '',
        email: '',
        password: '',
        birthdate: {
            day: '',
            month: '',
            year: '',
        },
        cityId: '',
        stateId: '',
        countryId: '',
        referral: '',
    },
    appearance: {
        bodySize: '',
        ethnicity: '',
        hairColor: '',
        eyesColor: '',
        height: '',
    },
    lifeInfo: {
        cigaretteSmoking: '',
        drinking: '',
        civilStatus: '',
        children: '',
        scholarity: '',
        monthlyIncome: '',
        personalPatrimony: '',
        profession: '',
    },
    lastDetails: {
        headline: '',
        aboutMe: '',
        searchingFor: '',
        enjoysTraveling: '',
    },
    isCroppingImage: false,
    isSubmittingForm: false,
    hasSubmittedForm: false,
    hasAccessedModalRoute: false,
    submittingFormText: Registration.SUBMITTING_FORM_TEXT_STAGE_1,
};
