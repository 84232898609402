import { IBaseProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';

export class FrictionlessCommon {
    static readonly STEP_INITIAL: number = 0;
    static readonly STEP_TERMS: number = 1;
    static readonly STEP_BASIC: number = 2;
    static readonly STEP_PICTURE: number = 3;
    static readonly STEP_BODY: number = 4;
    static readonly STEP_SOCIAL: number = 5;
    static readonly STEP_FINAL: number = 6;
    static readonly STEP_CONFIRMATION: number = 7;

    static readonly checkFields: string[] = ['headline', 'general_description', 'photo'];
    static readonly checkExtendedFields: string[] = [
        'height1',
        'body_type',
        'ethnicity',
        'hair_color',
        'eye_color',
        'smoke',
        'drink',
        'relationship',
        'have_children',
        'education',
        'income',
        'net_worth',
        'description',
    ];

    static isComplete(user: IBaseProfile): boolean {
        if (!user) {
            return false;
        }

        return !UserCommon.isDaddyMommyFree(user) ||
            user.is_complete;
    }

    static canFinishRegistrationLater(myType: number, age: number): boolean {
        return UserCommon.isDaddyMommyByWhat(myType) &&
            !UserCommon.isAgeBelowExpressThreshold(age);
    }

    static hasPendingData(user: UserCommon): boolean {
        return UserCommon.isDaddyMommy(user) &&
            Math.floor(FrictionlessCommon.getCompletenessPercentage(
                FrictionlessCommon.getNumberOfFilledFields(user),
            )) !== 1;
    }

    static isIncompleteWithWaitingApprovalData(user: UserCommon): boolean {
        return !FrictionlessCommon.isComplete(user) &&
            !FrictionlessCommon.hasPendingData(user);
    }

    static getCompletenessPercentage(numEmptyFields: number): number {
        return 1 - numEmptyFields / (
            FrictionlessCommon.checkFields.length +
            FrictionlessCommon.checkExtendedFields.length
        );
    }

    static getNumberOfFilledFields(user: UserCommon): number {
        let numEmptyFields = 0;
        for (const field of FrictionlessCommon.checkFields) {
            if (!user[field] ||
                typeof user[field] === 'string' &&
                !user[field].trim()
            ) {
                numEmptyFields++;
            }
        }

        for (const field of FrictionlessCommon.checkExtendedFields) {
            if (!user.profile_extended[field] ||
                typeof user.profile_extended[field] === 'string' &&
                !user.profile_extended[field].trim()
            ) {
                numEmptyFields++;
            }
        }

        return numEmptyFields;
    }

    static isBabyAtConfirmationStep(whatType: number, step: number): boolean {
        return !UserCommon.isDaddyMommyByWhat(whatType) &&
            step === FrictionlessCommon.STEP_CONFIRMATION;
    }

    static isAtAnyModalStep(step: number): boolean {
        return step > FrictionlessCommon.STEP_INITIAL &&
            step < FrictionlessCommon.STEP_FINAL;
    }

    static isBabyWithoutPhotoAheadOfPhotoStep(
        whatType: number,
        step: number,
        pictureData: string,
    ): boolean {
        return !UserCommon.isDaddyMommyByWhat(whatType) &&
            step > FrictionlessCommon.STEP_PICTURE &&
            pictureData === undefined;
    }

    static isDaddyMommyWithoutPhoto(
        whatType: number,
        hasPictures: boolean,
        skippedPhotoStep: boolean,
    ): boolean {
        return UserCommon.isDaddyMommyByWhat(whatType) &&
            !hasPictures &&
            !skippedPhotoStep;
    }
}
