import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProfilesSelectors } from '@libs/store/profiles-v2';

@Directive()
export abstract class ActionbarProfileDetailsComponentCommon implements OnInit, OnDestroy {
    @Input() profileId: number;
    protected profile: IProfile | undefined;

    protected subscriptions: Subscription[] = [];

    constructor(
        protected store: Store<IApplicationState>,
        protected translate: TranslateService,
    ) {
        //
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.pipe(
                select(ProfilesSelectors.selectProfileById(this.profileId)),
            ).subscribe({
                next: (profile) => {
                    this.profile = profile;
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }

    getProfileLocation(): any {
        if (this.profile === undefined) {
            return '';
        }

        if (!this.profile.cityName || !this.profile.stateName) {
            return this.translate.instant('common.not_set');
        }

        return this.translate.instant('modules.main.pages.profile.user_location', {
            city: this.profile.cityName,
            province: this.profile.stateName,
        });
    }

    getOtherProfileUsername(): string {
        return UserCommon.getName(this.profile, this.translate);
    }

    isOnline(): boolean {
        return UserCommon.isOnline(this.profile);
    }
}
