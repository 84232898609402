import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { ViewStyle } from '@libs/components/submenu-v2/interfaces/list-view-style.type';
import { SearchActions } from '@libs/store/search';

import { IBasicSubmenuData } from '@prince/modules/main/shared/submenu-call-to-action/basic-submenu-data.interface';

@Component({
    selector: 'mp-submenu-call-to-action',
    templateUrl: './submenu-call-to-action.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['submenu-call-to-action.scss'],
})
export class SubmenuCallToAction {
    @Input() public hasGridViewSelector: boolean = true;
    @Input() public submenuItems: IBasicSubmenuData[] = [];
    @Output() public removeFilter: EventEmitter<IBasicSubmenuData> =
        new EventEmitter<IBasicSubmenuData>();
    @Output() public actionButton: EventEmitter<void> = new EventEmitter<void>();

    public listViewStyle: ViewStyle = 'grid';

    constructor(
        protected store: Store,
    ) {
        //
    }

    public openSearchModal(): void {
        this.store.dispatch(
            SearchActions.openModal(),
        );
    }

    public setListViewStyle(style: ViewStyle): void {
        this.listViewStyle = style;
    }

    public getGridViewButtonModifierClass(style: ViewStyle): string {
        if (style !== this.listViewStyle) {
            return '';
        }

        return 'selected';
    }

    public trackByLabel = (_index: number, submenuItem: IBasicSubmenuData): string => {
        return submenuItem.label;
    };

    public handleClearCallback(submenuItem: IBasicSubmenuData): void {
        submenuItem.clearCallback();
        this.removeFilter.emit();
    }

    public hasClearCallback(submenuItem: IBasicSubmenuData): boolean {
        return submenuItem.clearCallback !== undefined;
    }

    public handleActionButton(): void {
        this.actionButton.emit();
    }
}
