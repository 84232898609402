import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    PaymentModalFormComponentCommon,
} from '@libs/modules/main/shared/payment-modal-form/payment-modal-form.component.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';

import { PaymentModalFormService } from '@prince/services/payment-modal-form/payment-modal-form.service';

@Component({
    selector: 'mp-payment-modal-form',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './payment-modal-form.html',
})
export class PaymentModalFormComponent extends PaymentModalFormComponentCommon {
    constructor(
        protected paymentModalFormService: PaymentModalFormService,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        super(
            paymentModalFormService,
            paymentHelpersService,
        );
    }
}
