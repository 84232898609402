import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'mp-fancy-checkbox',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './fancy-checkbox.html',
})
export class FancyCheckboxComponent {
    public id: string = '';

    @Input() disabled: boolean = false;
    @Output() readonly valueChange: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    protected _value: boolean = false;

    get value(): boolean {
        return this._value;
    }
    @Input() set value(value: boolean) {
        this._value = value;
    }

    constructor() {
        this.id = 'fcb_' + Date.now();
    }

    public setValue(value: boolean, event: Event): void {
        this._value = value;
        this.valueChange.emit(value);
        event.stopPropagation();
    }
}
