import { ICity } from '@libs/services/location/city/city';
import {
    CitiesReceivedAction,
    CITIES_RECEIVED_ACTION,
} from '@libs/store/location/actions/cities.action';

export function locationStoreCities(state: ICity[] = [], action: CitiesReceivedAction): ICity[] {
    switch (action.type) {
        case CITIES_RECEIVED_ACTION:
            return (action.payload.cities || []).slice();

        default:
            return state;
    }
}
