import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    ActionbarBackTitleComponentCommon,
} from '@libs/modules/shared/actionbar/actionbar-back-title/actionbar-back-title.component.common';

@Component({
    selector: 'mp-actionbar-back-title',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './actionbar-back-title.html',
})
export class ActionbarBackTitleComponent extends ActionbarBackTitleComponentCommon {
    //
}
