import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import {
    UserAvatarComponentCommon,
} from '@libs/components/user-avatar/user-avatar.component.common';
import { UserCommon } from '@libs/shared/user/user.common';

import { AuthenticationService } from '@prince/services/authentication.service';
import { SlidableMenuService } from '@prince/services/slidable-menu/slidable-menu.service';
import { ImageHelper } from '@prince/utils/image-helper';

@Component({
    selector: 'mp-user-avatar',
    templateUrl: './user-avatar.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class UserAvatarComponent extends UserAvatarComponentCommon {
    constructor(
        protected router: Router,
        protected slidableMenuService: SlidableMenuService,
        protected auth: AuthenticationService,
    ) {
        super(auth);
    }

    getUserPicture(): string {
        return ImageHelper.getProfilePicture(
            this.auth.get(),
            null,
            UserCommon.PICTURE_EXTRA_LARGE,
        );
    }

    goToProfile(): void {
        this.slidableMenuService.closeSlidableMenu();
        this.router.navigate(['main', 'my-profile']);
    }
}
