import { IProfileSwipeState } from '@libs/store/profile-swipe/interfaces/profile-swipe-state.interface';

export const profileSwipeInitialState: IProfileSwipeState = {
    isSwipingProfiles: false,
    latestSwipeListName: '',
    hasProfileSwipeTest: false,
    hasDoneProfileSwipe: false,
    isSwipingOnNearby: false,
    listScrollPosition: 0,
};
