import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IMessagesPayload } from '@libs/shared/message/message-payload';
import { IConversation } from '@libs/store/conversations/interface';
import { IMessage } from '@libs/store/messages/interface';

enum Actions {
    LOAD_MESSAGES = '[Messages] - Messages Loaded',
    UPSERT_MESSAGES = '[Messages] - Upsert Messages',
    MESSAGE_INPUTED = '[Messages] - Message Inputed',
    DELETE_BY_CONVERSATION = '[Messages] - Delete By Conversation',
    CLEAN_MESSAGES = '[Messages] - Clean Messages',
    SENT_FREE_MESSAGE = '[Messages] - Sent Free Message',
    RESET_AND_UPDATE_MESSAGES_LIST = '[Messages] - Reset And Update Messages List Action',
    UPDATE_MESSAGES_LIST_PAGE = '[Messages] - Update Messages List Page Action',
    NEXT_PAGE_MESSAGES_LIST = '[Messages] - Next Page Messages List Action',
    MESSAGES_LIST_UPDATED = '[Messages] - Messages List Updated',
    ERROR_UPDATING_MESSAGES_LIST = '[Messages] - Error Updating Messages List',
    MESSAGE_ERROR = '[Messages] - Error trying to send new Message',
    UPDATE_USER_AFTER_FREE_MSG = '[Messages] - Update User After Free Msg',
}

export const loadMessages = createAction(
    Actions.LOAD_MESSAGES,
    props<{ messages: IMessage[] }>(),
);

export const upsertMessages = createAction(
    Actions.UPSERT_MESSAGES,
    props<{ messages: IMessage[] }>(),
);

export const messageInputed = createAction(
    Actions.MESSAGE_INPUTED,
    props<IMessagesPayload>(),
);

export const deleteByConversation = createAction(
    Actions.DELETE_BY_CONVERSATION,
    props<{ conversation: IConversation }>(),
);

export const cleanMessages = createAction(
    Actions.CLEAN_MESSAGES,
);

export const sentFreeMessage = createAction(
    Actions.SENT_FREE_MESSAGE,
    props<{ sentFreeMessage: boolean }>(),
);

export const resetAndUpdateMessagesList = createAction(
    Actions.RESET_AND_UPDATE_MESSAGES_LIST,
);

export const updateMessagesListPage = createAction(
    Actions.UPDATE_MESSAGES_LIST_PAGE,
    props<{ page: number }>(),
);

export const nextPageMessagesList = createAction(
    Actions.NEXT_PAGE_MESSAGES_LIST,
);

export const messagesListUpdated = createAction(
    Actions.MESSAGES_LIST_UPDATED,
);

export const errorUpdatingMessagesList = createAction(
    Actions.ERROR_UPDATING_MESSAGES_LIST,
    props<{ error: HttpErrorResponse }>(),
);

export const messageError = createAction(
    Actions.MESSAGE_ERROR,
    props<{
        conversation: IConversation,
        errorResponse: HttpErrorResponse,
    }>(),
);

export const updateUserAfterFreeMsg = createAction(
    Actions.UPDATE_USER_AFTER_FREE_MSG,
    props<{ freeMsgCount: number }>(),
);
