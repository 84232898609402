import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IParametersForRequest } from '@libs/modules/main/services/auth-request-facade/interfaces/parameters-for-request.interface';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

@Injectable({
    providedIn: 'root',
})
export abstract class AuthRequestFacadeCommon {
    public readonly REQUEST_RETRY_ATTEMPTS: number = 2;
    public readonly REQUEST_RETRY_DELAY_IN_MILLISECONDS: number = 1000;

    constructor(
        protected authHttp: AuthHttpServiceCommon,
    ) {
        //
    }

    abstract get(parameters: IParametersForRequest): Observable<IAuthResponse>;
}
