import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IModalViewProps } from '@libs/components/modal-view/interface/modal-view-props';

const selectFeature =
    createFeatureSelector<IModalViewProps>('modalViewProps');

export const selectIsOpened = createSelector(
    selectFeature,
    (state: IModalViewProps): boolean => state.isModalOpened,
);
