<div class="login-forgot-password">
    <login-modal-container [modalTitle]="getModalTitle()">
        <div
            class="login-forgot-password__email-sent"
            *ngIf="emailSent$ | async; else emailNotSent"
        >
            <p>
                {{ getEmailSentText() | translate }}
            </p>
            <button-with-loading
                class="login-forgot-password__email-sent__ok"
                [description]="getOkButtonText()"
                [theme]="'rad-red'"
                (click)="close()"
            ></button-with-loading>
        </div>
        <ng-template #emailNotSent>
            <mp-generic-input
                [form]="forgotPasswordForm"
                [controlName]="email"
                [label]="getEmailLabel()"
                [id]="'login-forgot-password'"
                [type]="email"
                [placeholder]="getEmailPlaceholder() | translate"
                [errorMessage]="getInputErrorMessage(emailNotFound$ | async)"
                (inputChange)="dispatchForgotPasswordEmailNotFound()"
            ></mp-generic-input>
            <div class="login-forgot-password__footer">
                <a
                    class="login-forgot-password__footer__cancel"
                    (click)="close()"
                >
                    {{ getCancelSendEmailButtonText() | translate }}
                </a>
                <button-with-loading
                    [id]="'login-forgot-password_send-email'"
                    [description]="getSendEmailButtonText()"
                    [theme]="'fleur-de-lis'"
                    [isLoading]="isAttemptingLogin$ | async"
                    (click)="dispatchForgotPassword()"
                ></button-with-loading>
            </div>
        </ng-template>
    </login-modal-container>
</div>
