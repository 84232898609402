import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConversationCommon } from '@libs/shared/conversation/conversation.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IConversation } from '@libs/store/conversations/interface';
import { ConversationAdapter, IConversationState } from '@libs/store/conversations/state';

export const selectState = createFeatureSelector<IConversationState>('storeConversationEntities');

export const getSelectedId = (state: IConversationState): number | undefined => state.selectedId;

export const selectAll = createSelector(
    selectState,
    ConversationAdapter.getSelectors().selectAll,
);

const selectEntities = createSelector(
    selectState,
    ConversationAdapter.getSelectors().selectEntities,
);

export const selectCurrentId = createSelector(
    selectState,
    getSelectedId,
);

export const selectCurrent = createSelector(
    selectEntities,
    selectCurrentId,
    (
        conversations: Dictionary<IConversation>,
        peerId: number,
    ): IConversation | undefined => conversations[peerId],
);

export const selectHasPeer = createSelector(
    selectAll,
    (conversations: IConversation[], props: { peerId: number }): boolean => {
        return conversations.some((conversation: IConversation): boolean =>
            conversation.peer !== undefined &&
            conversation.peer.profile_id === props.peerId &&
            conversation.conversation_id !== undefined);
    },
);

export const selectByConversationId = createSelector(
    selectAll,
    (conversations: IConversation[], props: { conversationId: number }): IConversation => {
        return conversations.find((conversation: IConversation): boolean => {
            return conversation.conversation_id === props.conversationId;
        });
    },
);

export const selectIsLoading = createSelector(
    selectState,
    (state: IConversationState): boolean => state.isLoading,
);

export const selectConversationsTotal = createSelector(
    selectState,
    ConversationAdapter.getSelectors().selectTotal,
);

export const selectUnreadConversations = createSelector(
    selectState,
    (state: IConversationState): number => state.unreadConversations,
);

export const hasUnreadConversations = createSelector(
    selectState,
    (state: IConversationState): boolean => state.unreadConversations > 0,
);

export const selectPeerIds = createSelector(
    selectState,
    (state: IConversationState): number[] =>
        ConversationCommon.mapConversations(state)
            .map((conversation: IConversation): number => conversation.peer_id),
);

export const selectFavorites = createSelector(
    selectAll,
    (state: IConversation[]): IConversation[] =>
        state.filter((conversation: IConversation): boolean =>
            conversation.peer.favorited === UserCommon.FAVORITED_TURNED_ON),
);
