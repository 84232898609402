import { Action, createReducer, on } from '@ngrx/store';

import { setEmailVerified, setIsRequestingNewConfirmationEmail, setIsSendingRequest, setRequestTimestamp, setUserApproved } from '@libs/store/waiting-list/actions';
import { IWaitingListState } from '@libs/store/waiting-list/interfaces/waiting-list-state';
import { waitingListInitialState } from '@libs/store/waiting-list/state';

const waitingListReducer = createReducer(
    waitingListInitialState,
    on(setIsRequestingNewConfirmationEmail, (
        state: IWaitingListState,
        { isRequesting }: { isRequesting: boolean },
    ): IWaitingListState => {
        return {
            ...state,
            isRequestingNewConfirmationEmail: isRequesting,
        };
    }),
    on(setRequestTimestamp, (
        state: IWaitingListState,
        { timestamp }: { timestamp: number },
    ): IWaitingListState => {
        return {
            ...state,
            confirmationEmailRequestTimestamp: timestamp,
        };
    }),
    on(setUserApproved, (
        state: IWaitingListState,
    ): IWaitingListState => {
        return {
            ...state,
            isUserApproved: true,
        };
    }),
    on(setEmailVerified, (
        state: IWaitingListState,
    ): IWaitingListState => {
        return {
            ...state,
            isEmailVerified: true,
        };
    }),
    on(setIsSendingRequest, (
        state: IWaitingListState,
        { isSendingRequest }: { isSendingRequest: boolean },
    ): IWaitingListState => {
        return {
            ...state,
            isSendingRequest,
        };
    }),
);

export function reducer(
    state: IWaitingListState,
    action: Action,
): IWaitingListState {
    return waitingListReducer(state, action);
}
