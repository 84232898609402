import { createAction, props } from '@ngrx/store';

import { IRegistrationAppearance } from '@libs/modules/registration/domain/interfaces/registration-appearance.interface';
import { IRegistrationInitialStep } from '@libs/modules/registration/domain/interfaces/registration-initial-step.interface';
import { IRegistrationLastDetails } from '@libs/modules/registration/domain/interfaces/registration-last-details.interface';
import { IRegistrationLifeInfo } from '@libs/modules/registration/domain/interfaces/registration-life-info.interface';
import { IRegistrationPersonalInfo } from '@libs/modules/registration/domain/interfaces/registration-personal-info.interface';

enum Actions {
    SET_IF_TRIED_TO_ACCESS_MODAL_ROUTE = '[Registration] - Set if user tried to access modal internal route',
    OPEN_MODAL_AND_NAVIGATE = '[Registration] - Open Modal and Navigate',
    CLOSE_MODAL = '[Registration] - Close Modal',
    CLEAR_PASSWORD = '[Registration] - Clear Password',
    SAVE_INITIAL_STEP = '[Registration] - Save Initial Step',
    SAVE_PERSONAL_INFO = '[Registration] - Save Personal Info',
    SAVE_APPEARANCE = '[Registration] - Save Appearance',
    SAVE_LIFE_INFO = '[Registration] - Save Life Info',
    SAVE_LAST_DETAILS = '[Registration] - Save User Bio',
    SET_IS_CROPPING_IMAGE = '[Registration] - Set Is Cropping Image',
    SET_IS_SUBMITTING_FORM = '[Registration] - Set Is Submitting Form',
    START_SUBMISSION = '[Registration] - Start Submission',
    SUBMIT_REGISTRATION = '[Registration] - Submit Registration',
    SUBMISSION_FAILED = '[Registration] - Submission Failed',
    CHECK_IF_REGISTRATION_SUCCEEDED = '[Registration] - Check if registration succeeded',
    NOTIFY_ANALYTICS_ON_SUCCESS = '[Registration] - Notify Analytics On Success',
    NOTIFY_ANALYTICS_ON_FAILURE = '[Registration] - Notify Analytics On Failure',
    AUTHENTICATE_ON_SUCCESS = '[Registration] - Authenticate on Success',
    BABY_REGISTRATION_SUCCESS = '[Registration] - Baby Registration Success',
    OPEN_MODAL_ON_BABY_SUCCESS = '[Registration] - Open Modal On Baby Registration Success',
    DADDY_MOMMY_REGISTRATION_SUCCESS = '[Registration] - Daddy or Mommy Registration Success',
    CHECK_REGISTREE_SUGAR_ROLE = '[Registration] - Check Registree Sugar Role',
    RESET_TO_INITIAL_STATE = '[Registration] - Reset to Initial State',
    NAVIGATE_TO_CONFIRM_EMAIL = '[Registration] - Navigate to Confirm Email',
    CHANGE_SUBMITTING_FORM_TEXT = '[Registration] - Change Submitting Form Text',
    SET_HAS_SUBMITTED_FORM = '[Registration] - Set Has Submitted Form',
}

export const setIfTriedToAccessModalRoute = createAction(
    Actions.SET_IF_TRIED_TO_ACCESS_MODAL_ROUTE,
    props<{ hasAccessedModalRoute: boolean }>(),
);

export const openModalAndNavigate = createAction(
    Actions.OPEN_MODAL_AND_NAVIGATE,
    props<{ route: string[] }>(),
);

export const closeModalAndNavigate = createAction(
    Actions.CLOSE_MODAL,
    props<{ route: string[] }>(),
);

export const clearPassword = createAction(
    Actions.CLEAR_PASSWORD,
);

export const saveRegistrationInitialStep = createAction(
    Actions.SAVE_INITIAL_STEP,
    props<{ initialStep: IRegistrationInitialStep }>(),
);

export const savePersonalnfo = createAction(
    Actions.SAVE_PERSONAL_INFO,
    props<{ personalInfo: IRegistrationPersonalInfo }>(),
);

export const setIsCroppingImage = createAction(
    Actions.SET_IS_CROPPING_IMAGE,
    props<{ isCroppingImage: boolean }>(),
);

export const saveAppearance = createAction(
    Actions.SAVE_APPEARANCE,
    props<{ appearance: IRegistrationAppearance }>(),
);

export const saveLifeInfo = createAction(
    Actions.SAVE_LIFE_INFO,
    props<{ lifeInfo: IRegistrationLifeInfo }>(),
);

export const saveLastDetails = createAction(
    Actions.SAVE_LAST_DETAILS,
    props<{ lastDetails: IRegistrationLastDetails }>(),
);

export const setIsSubmittingForm = createAction(
    Actions.SET_IS_SUBMITTING_FORM,
    props<{ isSubmittingForm: boolean }>(),
);

export const startSubmission = createAction(
    Actions.START_SUBMISSION,
);

export const submitRegistration = createAction(
    Actions.SUBMIT_REGISTRATION,
);

export const submissionFailed = createAction(
    Actions.SUBMISSION_FAILED,
);

export const checkIfRegistrationSucceeded = createAction(
    Actions.CHECK_IF_REGISTRATION_SUCCEEDED,
);

export const notifyAnalyticsOnSuccess = createAction(
    Actions.NOTIFY_ANALYTICS_ON_SUCCESS,
);

export const notifyAnalyticsOnFailure = createAction(
    Actions.NOTIFY_ANALYTICS_ON_FAILURE,
);

export const authenticateOnSuccess = createAction(
    Actions.AUTHENTICATE_ON_SUCCESS,
);

export const babyRegistrationSuccess = createAction(
    Actions.BABY_REGISTRATION_SUCCESS,
);

export const openModalOnBabyRegistrationSuccess = createAction(
    Actions.OPEN_MODAL_ON_BABY_SUCCESS,
);

export const daddyMommyRegistrationSuccess = createAction(
    Actions.DADDY_MOMMY_REGISTRATION_SUCCESS,
);

export const checkRegistreeSugarRole = createAction(
    Actions.CHECK_REGISTREE_SUGAR_ROLE,
);

export const resetToInitialState = createAction(
    Actions.RESET_TO_INITIAL_STATE,
);

export const navigateToConfirmEmail = createAction(
    Actions.NAVIGATE_TO_CONFIRM_EMAIL,
);

export const changeSubmittingFormText = createAction(
    Actions.CHANGE_SUBMITTING_FORM_TEXT,
    props<{ translationKey: string }>(),
);

export const setHasSubmittedForm = createAction(
    Actions.SET_HAS_SUBMITTED_FORM,
    props<{ hasSubmittedForm: boolean }>(),
);
