import { Component } from '@angular/core';

import { SubscribeButtonComponentCommon } from '@libs/components/subscribe-button/subscribe-button.component.common';

import { AuthenticationService } from '@prince/services/authentication.service';

@Component({
    selector: 'mp-subscribe-button',
    templateUrl: 'subscribe-button.html',
})
export class SubscribeButtonComponent extends SubscribeButtonComponentCommon {
    constructor(
        protected authenticationService: AuthenticationService,
    ) {
        super(
            authenticationService,
        );
    }
}
