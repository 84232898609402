import { Action } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const PROFILES_FAV_ME_RECEIVED_ACTION: string = 'PROFILES_FAV_ME_RECEIVED_ACTION';
export const PROFILES_FAV_ME_REFRESHED_ACTION: string = 'PROFILES_FAV_ME_REFRESHED_ACTION';
export const PREPEND_FAV_ME_PROFILE_ACTION: string = 'PROFILES_FAV_ME_PREPEND_ACTION';
export const PROFILES_FAV_ME_REMOVED_ACTION: string = 'PROFILES_FAV_ME_REMOVED_ACTION';
export const FAV_ME_TTL_UPDATED_ACTION: string = 'FAV_ME_TTL_UPDATED_ACTION';

export interface IProfilesFavMeReceivedActionPayload {
    profiles: IProfile[];
}

export class ProfilesFavMeReceivedAction implements Action {
    readonly type: string = PROFILES_FAV_ME_RECEIVED_ACTION;

    constructor(public payload: IProfilesFavMeReceivedActionPayload) {
        //
    }
}

export class ProfilesFavMeRefreshedAction implements Action {
    readonly type: string = PROFILES_FAV_ME_REFRESHED_ACTION;
}

export class PrependFavMeProfileAction implements Action {
    readonly type: string = PREPEND_FAV_ME_PROFILE_ACTION;

    constructor(public payload: IProfilesFavMeReceivedActionPayload) {
        //
    }
}

export interface IFavMeTTLActionPayload {
    ttl: number;
}

export class FavMeTTLUpdatedAction implements Action {
    readonly type: string = FAV_ME_TTL_UPDATED_ACTION;

    constructor(public payload: IFavMeTTLActionPayload) {
        //
    }
}

export class ProfilesFavMeRemovedAction implements Action {
    readonly type: string = PROFILES_FAV_ME_REMOVED_ACTION;

    constructor(public payload: IProfilesFavMeReceivedActionPayload) {
        //
    }
}
