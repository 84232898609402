import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { concatMap, filter, switchMap, take, tap } from 'rxjs/operators';

import {
    AllCashCardComponentCommon,
} from '@libs/modules/main/pages/payment-card/allcash-card/allcash-card.component.common';
import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { Interest } from '@libs/shared/money/interest/interest';
import { Money } from '@libs/shared/money/money';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoActions } from '@libs/store/payment-info';
import { Tick } from '@libs/utils/timeout-typings';

import { Config } from '@prince/config';
import { LanguageService } from '@prince/modules/main/services/language.service';
import { AllCashService } from '@prince/modules/main/services/payment/allcash/allcash.service';
import { PaymentInfoService } from '@prince/modules/main/services/payment/payment-info.service';
import { AllCashIframeService } from '@prince/services/allcash-iframe.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ModalService } from '@prince/services/modal.service';
import { TrialService } from '@prince/services/trial/trial.service';
import { UserService } from '@prince/services/user.service';

@Component({
    selector: 'mp-allcash-card',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './allcash-card.html',
    providers: [AllCashIframeService],
})
export class AllCashCardComponent extends AllCashCardComponentCommon implements AfterViewInit {
    public paymentRouteTimeout: Tick;
    public nationalCard: boolean = true;
    public isInternational: boolean = false;
    public checkoutId: string = '';

    readonly INSTALLMENTS_COUNT: number = 0;

    protected profileId: number;

    protected installment: number = 1;
    protected interest: number = 0;

    constructor(
        protected route: ActivatedRoute,
        protected paymentService: AllCashService,
        protected paymentInfoService: PaymentInfoService,
        protected translate: TranslateService,
        protected auth: AuthenticationService,
        protected userService: UserService,
        protected modalService: ModalService,
        protected store: Store<IApplicationState>,
        protected trialService: TrialService,
        protected languageService: LanguageService,
        protected iframeService: AllCashIframeService,
        protected router: Router,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        super(
            paymentInfoService,
            translate,
            userService,
            store,
            router,
            paymentHelpersService,
        );
    }

    getHolder(): string {
        return '';
    }

    getCardNumber(): string {
        return '';
    }

    getCvv(): string {
        return '';
    }

    getDate(): string {
        return '';
    }

    getCPF(): string {
        return '';
    }

    getInstallments(): number {
        return this.INSTALLMENTS_COUNT;
    }

    ngAfterViewInit(): void {
        this.store.select('user')
            .pipe(
                switchMap((user: UserCommon ): Observable<IAuthResponse> => {
                    this.profileId = user.profile_id;

                    return this.pay();
                }),
                take(1),
            ).subscribe((): void => {
                this.addAllCashHtml();
            });

        this.route.fragment.pipe(
            filter((fragment: string): boolean => {
                return fragment !== undefined && fragment !== null;
            }),
            tap((fragment: string): void => {
                this.installment = parseInt(
                    fragment.match(/\:(\d+?)/)[1],
                    10,
                );

                this.interest = Interest.InstalmentToInterest[this.installment];
            }),
            concatMap((): Observable<IAuthResponse> => {
                return this.paymentService.checkout(
                    this.price,
                    this.interest,
                    this.type,
                ).pipe(
                    tap((response: IAuthResponse): void => {
                        this.checkoutId = response.data;

                        this.addAllCashHtml();
                    }),
                );
            }),
        ).subscribe();
    }

    getCheckoutURL(): string {
        return Config.payment.server + 'paymentWidgets.js?checkoutId=' + this.checkoutId;
    }

    getFormURL(): string {
        let pageToRedirect = 'main/home/';

        if (this.isExpressApproval()) {
            pageToRedirect = 'express-approval';
        }

        return Config.v2URL + pageToRedirect + '?payment=' + AllCashService.SUCCESSFUL_PARAM_VALUE;
    }

    addAllCashHtml(): void {
        const iframe = <HTMLIFrameElement>document.getElementById('injectFrame');
        iframe['allowPaymentRequest'] = true;
        let addressOptions: any = [];

        if (this.isExpressApproval() && Config.billingAddressInExpressApproval) {
            addressOptions = this.iframeService.iframeAddressOptions;
        }

        iframe.addEventListener('load', (): void => {
            (<any>iframe.contentWindow).wpwlOptions = {
                locale: this.languageService.currentLanguage().baseLang,
                style: 'plain',
                brandDetection: true,
                paymentTarget: '_top',
                shopperResultTarget: '_top',
                showTaxNumberField: !this.isInternational,
                iframeStyles: {
                    'wpwl-button-pay': {
                        'background-color': '#BE2F20',
                    },
                },
                ...addressOptions,
                onReady: (): void => {
                    document.querySelectorAll('iframe').forEach((iframeElement: HTMLIFrameElement): void => {
                        this.addInstallmentsSelectBox(iframeElement);

                        const inputElementList: NodeListOf<HTMLInputElement> = this.iframeService.getIframeInputElementList();

                        const formElement: HTMLFormElement = this.iframeService.getIframeFormElement();

                        this.iframeService.iframeFormEventListenerCallback(formElement, this.submitPayment);

                        this.iframeService.iframeInputWalk(
                            inputElementList,
                            (input: HTMLInputElement, className: string): void => {
                                this.iframeService.initService(
                                    input,
                                    className,
                                    inputElementList,
                                );

                                this.iframeService.validateFields(
                                    this.iframeService.iframeInputFieldsValidations.billing,
                                    className,
                                );

                                this.iframeService.keepFieldsValuesBetweenIframeInstances();

                                if (this.isExpressApproval() && Config.billingAddressInExpressApproval) {
                                    this.iframeService.fillAddressFieldsViaPostCode(
                                        className,
                                    );
                                    this.iframeService.validateFields(
                                        this.iframeService.iframeInputFieldsValidations.address,
                                        className,
                                    );
                                }
                            },
                        );
                    });
                },
            };
        });

        this.writeScriptsIntoIframe(iframe);
    }

    addInstallmentsSelectBox(iframeElement: HTMLIFrameElement): void {
        const options: string[] = [];
        const numberOfInstallments: number = this.getNumberOfInstallments();

        for (let installment: number = 1; installment <= numberOfInstallments; installment++) {
            const totalAmountWithInterest: Money = this.getTotalAmountWithInterest(installment);
            const installmentAmount: Money = new Money(totalAmountWithInterest.toNumber() / installment);

            const translatedText = this.translate.instant(
                'modules.main.pages.payment.installment',
                {
                    installment,
                    value: installmentAmount.toString(),
                    total: totalAmountWithInterest.toString(),
                },
            );

            let selected = '';
            if (this.installment === installment) {
                selected = 'selected';
            }

            options.push(
                `<option ${selected} value="${installment}">${translatedText}</option>`,
            );
        }

        const content: string = [
            '<div style="display:inline-block" class="wpwl-label wpwl-label-cardHolder">',
            this.translate.instant('modules.main.pages.payment.installmentLabel'),
            '</div>',
            '<div class="wpwl-wrapper">',
            '<select class="wpwl-control" name="recurring.numberOfInstallments" id="installmentSelection"',
            '   onchange="reloadWithNewCheckoutId(this.value);">',
            ...options,
            '</select>',
            '</div>',
        ].join('');

        const numberOfInstallmentsHtml: HTMLDivElement = iframeElement.contentWindow.document.createElement('div');
        numberOfInstallmentsHtml.className = 'wpwl-group wpwl-group-taxNumber wpwl-clearfix';
        numberOfInstallmentsHtml.insertAdjacentHTML('afterbegin', content);

        const elementToInsertBefore: NodeListOf<Element> = iframeElement
            .contentWindow
            .document
            .body
            .querySelectorAll('.wpwl-group-brand');

        elementToInsertBefore[0].parentNode.insertBefore(
            numberOfInstallmentsHtml,
            elementToInsertBefore[0],
        );
    }

    writeScriptsIntoIframe(iframe: HTMLIFrameElement): void {
        const form: HTMLFormElement = document.createElement('form');
        form.action = this.getFormURL();
        form.className = 'paymentWidgets';
        form.setAttribute('data-brands', Config.paymentBrands.join(' '));

        const script: HTMLScriptElement = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.getCheckoutURL();
        script.async = false;

        const installmentsReloadScript: HTMLScriptElement = document.createElement('script');
        installmentsReloadScript.async = false;
        installmentsReloadScript.type = 'text/javascript';
        installmentsReloadScript.textContent = [
            'function reloadWithNewCheckoutId(installment) {',
            '   window.parent.location.hash = "#installment:" + installment;',
            '};',
        ].join('');

        const style: HTMLStyleElement = document.createElement('style');
        style.type = 'text/css';
        style.textContent = '.wpwl-container { background-color: #f1f1f1 } .wpwl-button-pay, .wpwl-button-pay:hover { background-color: #BE2F20; border-color: #BE2F20 }';

        iframe.contentDocument.open();
        iframe.contentDocument.write(
            style.outerHTML +
            form.outerHTML +
            script.outerHTML +
            installmentsReloadScript.outerHTML,
        );
        iframe.contentDocument.close();
    }

    pay(): Observable<IAuthResponse> {
        return this.paymentService.checkout(this.price, this.interest, this.type)
            .pipe(tap((response: IAuthResponse): void => {
                this.checkoutId = response.data;
            }));
    }

    changeInternational(): void {
        this.isInternational = !this.isInternational;
        this.addAllCashHtml();
    }

    submitPayment = (): void => {
        this.store.dispatch(PaymentInfoActions.setIsPaying({
            isPaying: true,
        }));

        this.store.dispatch(PaymentInfoActions.setPaymentStatus({
            paymentStatus: PaymentStatus.PAYMENT_PROCESSING,
        }));
    };

    goBack(): void {
        this.router.navigate(['main'], { replaceUrl: true });
    }
}
