import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { ReportServiceCommon } from '@libs/modules/main/services/report/report.service.common';

import { Config } from '@prince/config';
import { AuthHttpService } from '@prince/services/auth-http.service';

@Injectable()
export class ReportService extends ReportServiceCommon {
    constructor(
        protected authHttp: AuthHttpService,
    ) {
        super(authHttp);
    }

    public report(
        profileId: number,
        reason: string,
        successCallback: () => void,
        errorCallback: (error: any) => void,
    ): Subscription {
        const endpoint: string = Config.serverIp + 'profile/' + profileId + '/report';

        return this.authHttp.post(endpoint, {
            reason,
        }).subscribe((): void => {
            successCallback();
        }, (error: any): void => {
            errorCallback(error);
        });
    }
}
