import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IUpgradeAccountPaymentOptions } from '@libs/store/upgrade-account/interfaces/upgrade-account-payment-options.interface';
import { IUpgradeAccountSelectedPlans } from '@libs/store/upgrade-account/interfaces/upgrade-account-selected-plans.interface';
import { IUpgradeAccountState } from '@libs/store/upgrade-account/interfaces/upgrade-account-state.interface';
import { UpgradeAccountVersion } from '@libs/store/upgrade-account/types/upgrade-account-version.type';

const upgradeAccountFeature = createFeatureSelector<IUpgradeAccountState>(
    'upgradeAccountScreen',
);

export const selectAll = createSelector(
    upgradeAccountFeature,
    (state: IUpgradeAccountState): IUpgradeAccountState => {
        return state;
    },
);

export const selectActiveVersion = createSelector(
    upgradeAccountFeature,
    (state: IUpgradeAccountState): UpgradeAccountVersion | null => {
        return state.activeVersion;
    },
);

export const selectIsResolvingVisiblePlans = createSelector(
    upgradeAccountFeature,
    (state: IUpgradeAccountState): boolean => {
        return state.isResolvingVisiblePlans;
    },
);

export const selectPaymentOptions = createSelector(
    upgradeAccountFeature,
    (state: IUpgradeAccountState): IUpgradeAccountPaymentOptions => {
        return state.paymentOptions;
    },
);

export const selectSelectedPlans = createSelector(
    upgradeAccountFeature,
    (state: IUpgradeAccountState): IUpgradeAccountSelectedPlans => {
        return state.selectedPlans;
    },
);

export const selectDataTTL = createSelector(
    upgradeAccountFeature,
    (state: IUpgradeAccountState): number => {
        return state.dataTTL;
    },
);
