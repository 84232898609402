import { Action, createReducer, on } from '@ngrx/store';

import * as DeactivateReasonActions from '@libs/store/deactivate-reason/actions';
import { IReason } from '@libs/store/deactivate-reason/interface';
import {
    deactivateReasonInitialState,
    IDeactivateReasonsState,
    reasonAdapter,
} from '@libs/store/deactivate-reason/state';

const deactivateReasonReducer = createReducer(
    deactivateReasonInitialState,
    on(DeactivateReasonActions.upsertDeactivateReasons, (
        state, { reasons }: { reasons: IReason[] },
    ): IDeactivateReasonsState => {
        return reasonAdapter.upsertMany(reasons, state);
    }),
    on(DeactivateReasonActions.setCurrentDeactivateReason, (
        state, { currentReasonTextSelected }: { currentReasonTextSelected: string },
    ): IDeactivateReasonsState => {
        return { ...state, currentReasonTextSelected };
    }),
);

export function reducer(state: IDeactivateReasonsState, action: Action): IDeactivateReasonsState {
    return deactivateReasonReducer(state, action);
}
