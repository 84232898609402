import { Directive, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { IFreeMsgModalData } from '@libs/modules/shared/modals/free-msg-modal/interfaces/free-message-modal-data.interface';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { ConversationCommon } from '@libs/shared/conversation/conversation.common';
import { IApplicationState } from '@libs/store/application-state';
import { MessageActions, MessageSelectors } from '@libs/store/messages';

@Directive()
export abstract class FreeMsgModalComponentCommon implements OnDestroy, OnInit {
    public isLoading: boolean = true;
    public alreadySentFreeMessage: boolean = false;
    public freeMsgCount: number = 0;

    protected subscriptions: Subscription[] = [];

    constructor(
        protected store: Store<IApplicationState>,
        @Inject(MODAL_DATA) protected modalData: IFreeMsgModalData,
    ) {
        this.subscriptions.push(this.store
            .pipe(
                select(MessageSelectors.alreadySentFreeMessage),
            ).subscribe((alreadySentFreeMessage: boolean): void => {
                this.alreadySentFreeMessage = alreadySentFreeMessage;

                if (alreadySentFreeMessage) {
                    this.isLoading = false;
                }
            }),
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.handleFreeMsgCount().subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];

        this.store.dispatch(MessageActions.sentFreeMessage({ sentFreeMessage: false }));
    }

    protected handleFreeMsgCount() {
        const loadingTimeForVisualComfort = 500;
        const getFreeMsgCount = (count) => {
            if (count < ConversationCommon.MAX_NUMBER_OF_FREE_CONVERSATIONS) {
                return count + 1;
            }

            return ConversationCommon.MAX_NUMBER_OF_FREE_CONVERSATIONS;
        };

        return of(this.modalData.messagesCount).pipe(
            delay(loadingTimeForVisualComfort),
            tap({
                next: (messagesCount) => {
                    this.freeMsgCount = getFreeMsgCount(messagesCount);
                    this.isLoading = false;
                    this.store.dispatch(
                        MessageActions.updateUserAfterFreeMsg({
                            freeMsgCount: this.freeMsgCount,
                        }),
                    );
                },
            }),
        );
    }

    abstract closeModal(): void;

    abstract goToUpgradeAccount(): void;
}
