import { createAction, props } from '@ngrx/store';

import { IBoostPackage } from '@libs/modules/boost/interface/boost-package';

export enum Actions {
    LOAD_BOOST_PACKAGES = '[Boost Packages] - Load Boost Packages',
    SET_BOOST_PACKAGES = '[Boost Packages] - Set Boost Packages',
    SET_IS_PAYMENT_PROCESSING = '[Boost Packages] - Set is payment processing',
}

export const loadBoostPackages = createAction(
    Actions.LOAD_BOOST_PACKAGES,
);

export const setBoostPackages = createAction(
    Actions.SET_BOOST_PACKAGES,
    props<{
        boostPackages: IBoostPackage[];
        productUuid: string;
        refreshData: number;
    }>(),
);

export const setIsPaymentProcessing = createAction(
    Actions.SET_IS_PAYMENT_PROCESSING,
    props<{
        isPaymentProcessing: boolean,
    }>(),
);
