import { Injectable } from '@angular/core';

import { ProfileViewersBaselineServiceCommon } from '@libs/modules/boost/services/profile-viewers-baseline/profile-viewers-baseline.service.common';

@Injectable({
    providedIn: 'root',
})
export class ProfileViewersBaselineService extends ProfileViewersBaselineServiceCommon {
    //
}
