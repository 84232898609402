import { Injectable } from '@angular/core';

export interface ISearch {
    location_radius_first?: number;
    location_country_id?: string;
    location_state_id?: string;
    location_city_id?: number;
    keyword_search?: string;
    ethnicity?: number[];
    hair_color?: number[];
    income?: number[];
    net_worth?: number[];
    eye_color?: number[];
    smoke?: number[];
    drink?: number[];
    body_type?: number[];
    relationship?: number[];
    have_children?: number[];
    education?: number[];
    happy_to_travel?: number[];
    travel_intention?: number[];
    occupation?: number[];
    sex?: number[];
    age_between?: number[];
    sortkey?: string;
    basic_filter?: {
        has_photos?: boolean,
        featured?: boolean,
        verified_photos?: boolean,
        unviewed?: boolean,
        viewed?: boolean,
        viewed_me?: boolean,
        favorite?: boolean,
        favorited_me?: boolean,
    };
    height?: number[];
}

export const DEFAULT_SEARCH = {
    location_radius_first: 0,
    location_country_id: '',
    location_state_id: '',
    keyword_search: '',
    ethnicity: [],
    hair_color: [],
    income: [],
    netWorth: [],
    eye_color: [],
    smoke: [],
    drink: [],
    body_type: [],
    relationship: [],
    have_children: [],
    education: [],
    happy_to_travel: [],
    travel_intention: [],
    occupation: [],
    sex: [],
    age_between: [],
    sortkey: '',
    basic_filter: {
        has_photos: false,
        featured: false,
        verified_photos: false,
        unviewed: false,
        viewed: false,
        viewed_me: false,
        favorite: false,
        favorited_me: false,
    },
    height: [],
};

@Injectable()
export class SearchParser {
    public decode(search: string): ISearch {
        const searchJSON = JSON.parse(search);

        if (searchJSON.location) {
            searchJSON.location_country_id = searchJSON.location.country_id;
            searchJSON.location_state_id = searchJSON.location.state_id;
            searchJSON.location_city_id = searchJSON.location.city_id;
            searchJSON.location_radius_first = searchJSON.location.radius_first;

            delete searchJSON.location;
        }

        if (searchJSON.birthdate) {
            searchJSON.age_between = searchJSON.birthdate;
            delete searchJSON.birthdate;
        }

        delete searchJSON.new_search;
        delete searchJSON.search_type;

        return searchJSON;
    }

    public encode(searchJSON: any): string {
        searchJSON.location = {
            country_id: searchJSON.location_country_id,
            state_id: searchJSON.location_state_id,
            city_id: searchJSON.location_city_id,
            radius_first: searchJSON.location_radius_first,
        };

        delete searchJSON.location_country_id;
        delete searchJSON.location_state_id;
        delete searchJSON.location_city_id;
        delete searchJSON.location_radius_first;

        return JSON.stringify(searchJSON);
    }
}

export interface INamedSearch {
    id?: number;
    name: string;
    search: ISearch;
}

export const SORT_ACTIVITY = 'recently';
export const SORT_NEWEST = 'newest';
export const SORT_NEAREST = 'nearest';
