import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
    MembershipServiceCommon,
} from '@libs/modules/main/services/membership/membership.service.common';
import { IApplicationState } from '@libs/store/application-state';

import { AnalyticsService } from '@prince/services/analytics/analytics.service';

@Injectable({
    providedIn: 'root',
})
export class MembershipService extends MembershipServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected analyticsService: AnalyticsService,
    ) {
        super(store, analyticsService);
    }
}
