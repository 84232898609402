import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum LoadingThemes {
    princeDefault = '#7357FF',
    mpBoost = '#4400BB',
};

export enum LoadingThemesNames {
    princeDefault = 'princeDefault',
    mpBoost = 'mpBoost',
}

@Component({
    selector: 'mp-loading',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './loading.html',
})
export class LoadingComponent {
    @Input() theme: string = LoadingThemes.princeDefault;

    getBorderColor(): string {
        if (this.theme === LoadingThemesNames.mpBoost) {
            return LoadingThemes.mpBoost;
        }

        return LoadingThemes.princeDefault;
    }
}
