import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';

import { IApplicationState } from '@libs/store/application-state';
import { IProfileSwipeState } from '@libs/store/profile-swipe/interfaces/profile-swipe-state.interface';

const profileSwipeStateFeature = createFeatureSelector<IProfileSwipeState>('profileSwipe');

export const selectNearby = (state: IApplicationState): number[] => state.storeNearbyProfiles;
export const selectFeatured = (state: IApplicationState): number[] => state.storeFeaturedProfiles;
export const selectNew = (state: IApplicationState): number[] => state.storeNewUserProfiles;
export const selectViewedMe = (state: IApplicationState): number[] => state.storeViewedMeProfiles;
export const selectMyFav = (state: IApplicationState): number[] => state.storeMyFavProfiles;
export const selectFavMe = (state: IApplicationState): number[] => state.storeFavMeProfiles;
export const selectSearch = (state: IApplicationState): number[] => state.storeSearchProfiles;

const listSelectorMapper = {
    '/nearby': selectNearby,
    '/featured': selectFeatured,
    '/new': selectNew,
    '/viewed-me': selectViewedMe,
    '/fav-my': selectMyFav,
    '/fav-me': selectFavMe,
    '/search': selectSearch,
    'default': selectNearby,
};

export const selectProfileSwipeList = (
    listName: string,
): MemoizedSelector<IApplicationState, number[], DefaultProjectorFn<number[]>> =>
    createSelector(
        (state: IApplicationState): IApplicationState => state,
        (state): number[] =>
            listSelectorMapper[listName]
                ? listSelectorMapper[listName](state)
                : listSelectorMapper['default'](state),
    );

export const selectIsSwipingProfiles = createSelector(
    profileSwipeStateFeature,
    (state: IProfileSwipeState): boolean => state.isSwipingProfiles,
);

export const selectLatestSwipeListName = createSelector(
    profileSwipeStateFeature,
    (state: IProfileSwipeState): string => state.latestSwipeListName,
);

export const selectIsSwipingOnNearby = createSelector(
    profileSwipeStateFeature,
    (state: IProfileSwipeState): boolean => state.isSwipingOnNearby,
);

export const selectHasDoneProfileSwipe = createSelector(
    profileSwipeStateFeature,
    (state: IProfileSwipeState): boolean => state.hasDoneProfileSwipe,
);

export const selectHasProfileSwipeTest = createSelector(
    profileSwipeStateFeature,
    (state: IProfileSwipeState): boolean => state.hasProfileSwipeTest,
);

export const selectListScrollPosition = createSelector(
    profileSwipeStateFeature,
    (state: IProfileSwipeState): number => state.listScrollPosition,
);
