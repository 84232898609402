import { Injectable, OnDestroy, Type } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { BoostSelectors } from '@libs/store/boost';
import { PaymentInfoSelectors } from '@libs/store/payment-info';

import { BoostEligibilityHelpers } from '@prince/modules/boost/helpers/boost-eligibility-helpers';
import {
    FeedbackNagbarComponent,
} from '@prince/modules/main/shared/feedback-nagbar/feedback-nagbar.component';
import {
    MobileNagbarComponent,
} from '@prince/modules/main/shared/mobile-nagbar/mobile-nagbar.component';
import { NagBarList } from '@prince/modules/main/shared/nagbar/nagbar-list';
import {
    PaymentNagbarComponent,
} from '@prince/modules/main/shared/payment-nagbar/payment-nagbar.component';
import {
    PhotoVerifyNagbarComponent,
} from '@prince/modules/main/shared/photo-verify-nagbar/photo-verify-nagbar.component';
import {
    ReferralFriendshipDayNagbarComponent,
} from '@prince/modules/main/shared/referral-friendship-day-nagbar/referral-friendship-day-nagbar';
import { INagbarItem } from '@prince/modules/nagbar/nagbar-list-item';
import { NagbarCommon } from '@prince/modules/nagbar/nagbar.common';
import { BoostNagbarComponent } from '@prince/modules/shared/boost-shared/components/boost-nagbar/boost-nagbar.component';
import { BoostRunningNagbarComponent } from '@prince/modules/shared/boost-shared/components/boost-running-nagbar/boost-running-nagbar.component';
import { BoostProgressService } from '@prince/modules/shared/boost-shared/services/boost-progress/boost-progress.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ReferralService } from '@prince/services/referral.service';

@Injectable()
export class NagbarService implements OnDestroy {
    public nagbars: NagBarList<INagbarItem> = new NagBarList<INagbarItem>();

    protected subscriptions: Subscription[] = [];

    protected paymentStatus: PaymentStatus = PaymentStatus.PAYMENT_NONE;

    public isPhotoVerifyNagbarVisible: boolean = false;
    public feedBackNagbarVisible: boolean = false;
    public safeTipNagbarVisible: boolean = true;
    public userJoinDaysTimestamp: number = 0;
    public hasBoostEnded: boolean = false;

    constructor(
        protected auth: AuthenticationService,
        protected store: Store<IApplicationState>,
        protected referralService: ReferralService,
        protected boostProgressService: BoostProgressService,
    ) {
        this.addNagbars();

        this.subscriptions.push(this.store.pipe(
            select(PaymentInfoSelectors.selectPaymentStatus),
        ).subscribe((paymentStatus: PaymentStatus): void => {
            this.paymentStatus = paymentStatus;
        }));

        this.subscriptions.push(this.store.select('uiState').subscribe((uiState): void => {
            this.feedBackNagbarVisible = uiState.feedbackHidden;
            this.isPhotoVerifyNagbarVisible = !uiState.photoVerifyHidden;
            this.safeTipNagbarVisible = !uiState.mpSafeTipNagbarHidden;
        }));

        this.subscriptions.push(this.store.select('stats').subscribe((stats): void => {
            this.userJoinDaysTimestamp = stats.joinDateTimestamp;
        }));

        this.subscriptions.push(
            this.store.select(BoostSelectors.selectHasBoostEnded, {
                boostProgressService: this.boostProgressService,
            }).subscribe((hasBoostEnded: boolean): void => {
                this.hasBoostEnded = hasBoostEnded;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public addNagbars(): void {
        this.nagbars.add({
            component: PaymentNagbarComponent,
            condition: (): boolean => NagbarCommon.isPaymentNagbarValid(
                this.auth.get(),
                this.paymentStatus,
            ),
        });

        this.nagbars.add({
            component: MobileNagbarComponent,
            condition: (): boolean => NagbarCommon.isMobileNagbarValid(this.auth.get()),
        });

        this.nagbars.add({
            component: BoostRunningNagbarComponent,
            condition: (): boolean => !UserCommon.isDaddyMommyFree(this.auth.get()) &&
                !this.hasBoostEnded,
        });

        this.nagbars.add({
            component: BoostNagbarComponent,
            condition: (): boolean =>
                BoostEligibilityHelpers.isUserEligibleToBoost(this.auth.get()) &&
                BoostEligibilityHelpers.isBoostEnabled(),
        });

        this.nagbars.add({
            component: ReferralFriendshipDayNagbarComponent,
            condition: (): boolean => NagbarCommon.isReferralFriendshipNagbarValid(
                this.auth.get(),
                (): boolean => this.referralService.canShowFriendshipDayReferralAd(),
                this.paymentStatus,
            ),
        });

        this.nagbars.add({
            component: PhotoVerifyNagbarComponent,
            condition: (): boolean => NagbarCommon.isPhotoVerifyNagbarValid(
                this.isPhotoVerifyNagbarVisible,
                this.auth.get(),
                (): boolean => this.referralService.canShowFriendshipDayReferralAd(),
            ),
        });

        this.nagbars.add({
            component: FeedbackNagbarComponent,
            condition: (): boolean => NagbarCommon.isFeedbackNagbarValid(
                this.auth.get(),
                this.userJoinDaysTimestamp,
                this.feedBackNagbarVisible,
            ),
        });
    }

    getAValidNagbarComponent(): Type<any> {
        for (let i = 0; i < this.nagbars.size(); i++) {
            if (this.nagbars.get(i).condition()) {
                return this.nagbars.get(i).component;
            }
        }

        return null;
    }
}
