<div class="public-profile-buttons">
    <button
        #toogleFavoriteButton
        class="public-profile-buttons__favorite"
        [ngClass]="modifierClassFavoriteButton()"
        (click)="toogleFavorite(profile)"
    >
        <i
            class="material-icons"
            aria-hidden="true"
            *ngIf="!isFavorite"
        >
            &#xE87E;
        </i>
        <i
            class="material-icons"
            aria-hidden="true"
            *ngIf="isFavorite"
        >
            &#xE87D;
        </i>
        <div *ngIf="isFavorite">
            {{ "modules.main.pages.profile.favorite_button" | translate }}
        </div>
    </button>
    <button
        class="public-profile-buttons__send-message"
        (click)="navigateToMessages()"
    >
        <i
            class="material-icons-outlined"
            aria-hidden="true"
        >
            &#xE0B7;
        </i>
        <div>
            {{ 'modules.main.pages.profile.send_message' | translate }}
        </div>
    </button>
</div>
