import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ComponentNames } from '@libs/effects/toast/common/component-names';
import { IToastOptions } from '@libs/modules/shared/toast-common/toast-options.interface';
import { TOAST_OPTIONS } from '@libs/modules/shared/toast-common/toast.tokens';
import { MeltToastActions } from '@libs/store/melt-toast';

import { AuthenticationService } from '@prince/services/authentication.service';

import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'mp-melt-toast',
    templateUrl: './melt-toast.html',
    styleUrls: ['./melt-toast.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeltToastComponent {
    public static componentName: string = ComponentNames.MELT_TOAST_COMPONENT;

    protected subscriptions: Subscription[] = [];

    constructor(
        @Inject(TOAST_OPTIONS) public toastOptions: IToastOptions,
        protected authenticationService: AuthenticationService,
        protected translateService: TranslateService,
        protected store: Store,
        protected toastrService: ToastrService,
    ) {
        this.handleOnTap();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    protected handleOnTap(): void {
        if (this.toastOptions.toast === undefined) {
            return;
        }

        this.subscriptions.push(
            this.toastOptions.toast.onTap.pipe(
                take(1),
            ).subscribe({
                next: (): void => {
                    this.onTap();
                    this.handleProfilesDeletion();
                },
            }),
        );
    }

    protected handleProfilesDeletion(): void {
        this.store.dispatch(MeltToastActions.handleProfilesDeletion());
    }

    protected onTap(): void {
        this.store.dispatch(MeltToastActions.handleTapOnMeltToast());
    }

    public closeToast(): void {
        this.handleProfilesDeletion();

        if (this.toastOptions.toast === undefined) {
            return;
        }

        this.toastrService.remove(this.toastOptions.toast.toastId);
    }
}
