<div class="upload-image-box">
    <label
        class="upload-image-box__label"
        *ngFor="let i of indices"
    >
        <div class="upload-image-box__container">
            <div class="upload-image-box__input content">
                <div *ngIf="!pictureData[i]">
                    <i
                        class="material-icons-outlined"
                        aria-hidden="true"
                    >
                        &#XE412;
                    </i>
                    <div class="upload-image-box__input__label">
                        {{ 'modules.frictionless.select_picture' | translate }}
                    </div>
                </div>
                <img
                    class="upload-image-box__input__image"
                    *ngIf="pictureData[i]"
                    [src]="pictureData[i]"
                >
                <input
                    #fileInput
                    type="file"
                    class="upload-image-box__input__file-input"
                    [accept]="getAcceptedImageTypes()"
                    (change)="processPicture($event, i)"
                    required
                >
            </div>
        </div>
    </label>
</div>
<div
    class="loading-overlay"
    *ngIf="isUploading"
>
    <mp-loading></mp-loading>
</div>
