<a
    class="clearfix"
    [routerLink]="['/main', 'profile', this.profileId]"
>
    <mp-img-srcset
        url="{{ getProfilePicture() }}"
        imgClass="avatar"
        place="settings-photo"
    ></mp-img-srcset>
    <div class="info">
        <p class="username notranslate">{{ getProfileUsername() }}</p>
    </div>
</a>
