import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IUiState } from '@libs/store/ui/ui-state';

const selectFeature = createFeatureSelector<IUiState>('uiState');

export const selectTmpToken = createSelector(
    selectFeature,
    (state: IUiState): string => {
        return state.tmpToken;
    },
);
