import { Directive, Input } from '@angular/core';

import { SubscribeButtonTheme } from '@libs/components/subscribe-button/subscribe-button-theme.type';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { UserCommon } from '@libs/shared/user/user.common';

@Directive()
export class SubscribeButtonComponentCommon {
    @Input() theme: SubscribeButtonTheme = 'medium';
    @Input() hideOnMediumDown: boolean = false;

    public readonly BUTTON_BASE_CLASSES: string = 'subscribe-button subscribe-button--';

    constructor(
        protected authenticationService: AuthenticationServiceCommon,
    ) {
        //
    }

    protected get user(): UserCommon {
        return this.authenticationService.get();
    }

    protected isBabyFree(): boolean {
        return UserCommon.isBabyFree(this.user);
    }

    protected isDaddyMommyFree(): boolean {
        return UserCommon.isDaddyMommyFree(this.user);
    }

    public isEligibleToSubscribe(): boolean {
        return UserCommon.isEligibleToSubscribe(this.user);
    }

    public getSubscribeButtonText(): string {
        if (this.isBabyFree()) {
            return 'common.subscribe-baby-premium';
        }

        if (this.isDaddyMommyFree()) {
            return 'common.subscribe';
        }

        return 'common.upgrade';
    }

    public getButtonClasses(): string {
        return `${ this.BUTTON_BASE_CLASSES }${ this.theme }`;
    }
}
