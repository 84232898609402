import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';

export abstract class SlidableMenuServiceCommon {
    constructor(
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    abstract openSlidableMenu(): void;

    abstract closeSlidableMenu(): void;
}
