import { Directive, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import {
    EXPRESS_APPROVAL_ID,
} from '@libs/modules/main/services/payment/payment.service.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import {
    MembershipCommon,
    MembershipType,
} from '@libs/shared/membership/membership.common';

@Directive()
export abstract class PaymentBoletoComponentCommon {
    public downloadBoletoUrl: string = '';
    public isElitePayment: boolean = false;
    public loading: boolean = false;
    public boletoUrl: string = '';

    protected readonly BOLETO_INTEREST: number = 0;

    @Input() type: MembershipType;
    @Input() time: number;
    @Input() price: number;

    @ViewChild('cpf', { static: true }) cpf: ElementRef;

    constructor(
        protected translate: TranslateService,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        this.isElitePayment = MembershipCommon.isEliteType(this.type);
    }

    abstract whenCPFNotExist(): void;

    abstract whenCPFInvalidInput(): void;

    abstract whenCPFInvalidNumber(): void;

    abstract whenIsTrialPayment(): void;

    abstract getCPF(): string;

    abstract getInterest(): number;

    containWhiteSpace(data: string): boolean {
        return data.indexOf(' ') !== -1;
    }

    isExpressApproval(): boolean {
        return this.type === EXPRESS_APPROVAL_ID;
    }

    getPlanDescription(): any {
        let productType: string = 'express_approval';

        if (!this.isExpressApproval()) {
            productType = MembershipCommon.getMachineMembership(this.type) + '_member';
        }

        const plan = this.translate.instant(`modules.main.pages.payment.product_types.${productType}`);
        if (!this.time) {
            return plan;
        }

        return this.translate.instant('modules.main.pages.payment.plan_description', {
            plan,
            duration: this.getPlanDuration(),
        });
    }

    getPlanDuration(): string | any {
        return this.translate.instant('modules.main.pages.payment.plan_duration.months', { period: this.time });
    }

    canShowDuration(): boolean {
        return this.time > 0;
    }

    getPlanPeriod(): number {
        return this.time;
    }

    getPlanName(): string {
        let productType: string = 'express_approval';
        if (!this.isExpressApproval()) {
            productType = MembershipCommon.getMachineMembership(this.type) + '_member';
        }

        return this.translate.instant(`modules.main.pages.payment.product_types.${productType}`);
    }

    getPriceValue(): number {
        return this.price;
    }

    getPriceDescription(): string {
        return 'R$ ' + this.getPriceValue().toFixed(2);
    }

    validate(): boolean {
        const cpf: string = this.getCPF();
        if (!this.paymentHelpersService.exist(cpf)) {
            this.whenCPFNotExist();
            return false;
        }

        if (!this.paymentHelpersService.isValidCPFInput(cpf)) {
            this.whenCPFInvalidInput();
            return false;
        }

        if (!this.paymentHelpersService.isValidCPFField(cpf)) {
            this.whenCPFInvalidNumber();
            return false;
        }

        if (PaymentCommon.isTrialPayment(this.type, this.time)) {
            this.whenIsTrialPayment();
            return false;
        }

        return true;
    }
}
