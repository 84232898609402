import { Component } from '@angular/core';

import { MembershipBadgeComponentCommon } from '@libs/components/membership-badge/membership-badge.component.common';

import { AuthenticationService } from '@prince/services/authentication.service';

@Component({
    selector: 'mp-membership-badge',
    templateUrl: 'membership-badge.html',
})
export class MembershipBadgeComponent extends MembershipBadgeComponentCommon {
    constructor(
        protected auth: AuthenticationService,
    ) {
        super(auth);
    }
}
