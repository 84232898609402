import { ICountry } from '@libs/services/location/country/country';
import {
    CountriesReceivedAction,
    COUNTRIES_RECEIVED_ACTION,
} from '@libs/store/location/actions/countries.action';

export function locationStoreCountries(state: ICountry[] = [], action: CountriesReceivedAction): ICountry[] {
    switch (action.type) {
        case COUNTRIES_RECEIVED_ACTION:
            return (action.payload.countries || []).slice();

        default:
            return state;
    }
}
