<div class="boost-multiplier">
    <div>
        <div class="boost-multiplier__ratio">
            <i
                class="material-icons-round boost-icon"
                aria-hidden="true"
            >
                &#xe3e7;
            </i>{{ getMultiplierFromStore$() | async | divideByOneHundred }}x
        </div>
        <div class="boost-multiplier__label">{{ label | translate }}</div>
    </div>
</div>
