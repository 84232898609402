import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserCommon } from '@libs/shared/user/user.common';
import { PhotoVerifyActions } from '@libs/store/photo-verify';

@Injectable()
export class PhotoVerifyEffects {
    handleHasPhotoVerified$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PhotoVerifyActions.checkIfUserHasVerificationPhoto),
            map(({ user }: { user: UserCommon }): Action => {
                const hasPhotoToVerify: boolean =
                    UserCommon.photoToVerify(user) !== undefined;

                if (!hasPhotoToVerify) {
                    return PhotoVerifyActions.setHasVerifiedPhoto({
                        hasPhotoVerified: false,
                    });
                }

                this.setIsUploadingImageAsFalse();

                return PhotoVerifyActions.setHasVerifiedPhoto({
                    hasPhotoVerified: true,
                });
            }),
        ),
        { dispatch: true, useEffectsErrorHandler: true },
    );

    constructor(
        protected actions$: Actions,
        protected store: Store,
    ) {
        //
    }

    protected setIsUploadingImageAsFalse(): void {
        this.store.dispatch(PhotoVerifyActions.setIsUploadingImage({
            isUploadingImage: false,
        }));
    }
}
