import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

import { Medias } from '@libs/utils/medias';

@Injectable({
    providedIn: 'root',
})
export class UserMenuService {
    public showItems: boolean = false;

    constructor(
        protected breakpointObserver: BreakpointObserver,
    ) {
        //
    }

    checkRoute(path: string): void {
        this.showItems = path.indexOf('/main/home') !== -1 ||
            path.indexOf('/main/search') !== -1 ||
            path.indexOf('/main/favorite') !== -1 ||
            path.indexOf('/main/conversation') !== -1;
    }

    responsiveMode(): boolean {
        return this.breakpointObserver.isMatched(Medias.MAX_WIDTH_991);
    }

    canShowUserMenuItens(): boolean {
        return this.showItems;
    }
}
