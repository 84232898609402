import { IPhoto } from '@libs/shared/profile/photo';

export class PhotoCommon implements IPhoto {
    static readonly STATUS_ACTIVE: string = 'active';
    static readonly STATUS_PENDING_APPROVAL: string = 'approval';
    static readonly STATUS_SUSPENDED: string = 'suspended';

    static readonly PICTURE_UNVERIFIED: number = 0;
    static readonly PICTURE_VERIFYING: number = 1;
    static readonly PICTURE_VERIFIED: number = 2;

    static readonly HAS_PHOTOS: string = 'has_photos';
    static readonly VERIFIED_PHOTOS: string = 'verified_photos';
    static readonly FEATURED: string = 'featured';

    static readonly PHOTO_TYPE_JPEG: string = 'image/jpeg';

    static readonly TYPE_PHOTOS_ACCEPTED_IN_UPLOAD: string =
        'image/gif,image/jpeg,image/jpg,image/png';

    static readonly PHOTOS_BABY_ENUM: string[] = [
        PhotoCommon.HAS_PHOTOS,
        PhotoCommon.FEATURED,
    ];
    static readonly PHOTOS_DADDY_ENUM: string[] = [
        PhotoCommon.VERIFIED_PHOTOS,
        PhotoCommon.FEATURED,
    ];

    static readonly MIN_FILE_SIZE: number = 5120;
    static readonly MAX_FILE_SIZE: number = 10485760;
    static readonly NATIVE_IMAGE_UPLOAD_DIMENSIONS: number = 300;

    static isActive(photo: IPhoto): boolean {
        return photo.status === undefined || photo.status === PhotoCommon.STATUS_ACTIVE;
    }

    static isPendingApproval(photo: IPhoto): boolean {
        return photo.status === PhotoCommon.STATUS_PENDING_APPROVAL;
    }

    static isSuspended(photo: IPhoto): boolean {
        return photo.status === PhotoCommon.STATUS_SUSPENDED;
    }

    static isVerified(photo: IPhoto): boolean {
        return photo.verify_photo === PhotoCommon.PICTURE_VERIFIED;
    }

    static photoOptionsByMembership(isBaby: boolean): string[] {
        return isBaby ?
            PhotoCommon.PHOTOS_BABY_ENUM :
            PhotoCommon.PHOTOS_DADDY_ENUM;
    }

    static photoKeyByName(name: string, isBaby: boolean): number {
        return PhotoCommon.photoOptionsByMembership(isBaby).indexOf(name);
    }

    static photoStatusById(id: number, isBaby: boolean): string {
        return PhotoCommon.photoOptionsByMembership(isBaby)[id];
    }
}
