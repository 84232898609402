import {
    FeaturedTTLUpdatedAction,
    FEATURED_TTL_UPDATED_ACTION,
} from '@libs/store/profiles/actions/featured.action';

export function storeFeaturedTTL(state: number = Date.now(), action: FeaturedTTLUpdatedAction): number {
    switch (action.type) {
        case FEATURED_TTL_UPDATED_ACTION:
            return updateTTL(state, action.payload.ttl);
        default:
            return state;
    }
}

function updateTTL(_state: number, newTTL: number): number {
    return newTTL;
}
