import {
    createEntityAdapter,
    EntityAdapter,
} from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';
import { IBoostState } from '@libs/store/boost/interfaces/boost-state.interface';

function selectProfileId(profile: IProfile): number {
    return profile.profile_id;
}

export const boostAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>(
    {
        selectId: selectProfileId,
    },
);

export const boostInitialState: IBoostState = boostAdapter.getInitialState(
    {
        canShowBoostList: false,
        canShowBoostModalResults: false,
        isActivatingBoost: false,
        isActivationButtonDisabled: false,
        boostEndTimestamp: 0,
        balance: 0,
        multiplierValue: 0,
        boostRunningInfo: {
            startTimestamp: 0,
            endTimestamp: 0,
        },
        isProcessingBoostPrize: false,
    },
);
