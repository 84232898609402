<div class="login-modal-container">
    <header class="login-modal-container__header">
        <h2 class="login-modal-container__header__title">
            {{ getModalTitle() | translate }}
        </h2>
        <div
            class="login-modal-container__header__close-modal"
            (click)="close()"
        >
            <i class="material-icons">
                &#xE5CD;
            </i>
        </div>
    </header>
    <div class="login-modal-container__content">
        <ng-content></ng-content>
    </div>
</div>
