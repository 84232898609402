import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import {
    ActionbarMessageReceiveComponentCommon,
} from '@libs/modules/shared/actionbar/actionbar-message-receiver/actionbar-message-receiver.component.common';
import { ConversationCommon } from '@libs/shared/conversation/conversation.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IConversation } from '@libs/store/conversations';

import { Config } from '@prince/config';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ProfileService } from '@prince/services/profile.service';
import { ImageHelper } from '@prince/utils/image-helper';

@Component({
    selector: 'mp-actionbar-message-receive',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './actionbar-message-receive.html',
})
export class ActionbarMessageReceiveComponent extends ActionbarMessageReceiveComponentCommon implements OnInit {
    protected peerGender: number = 0;

    constructor(
        protected auth: AuthenticationService,
        protected translate: TranslateService,
        protected profileService: ProfileService,
    ) {
        super(
            auth,
            translate,
            profileService,
        );
    }

    ngOnInit(): void {
        this.peerGender = this.receiver.sex;
    }

    checkConversationIcon(icon: string): boolean {
        return this.currentConversation !== undefined &&
            this.conversationIcon(this.currentConversation) === icon;
    }

    getReceiverPicture(): string {
        return ImageHelper.getProfilePicture(
            this.receiver,
            this.auth.get(),
            UserCommon.PICTURE_LIGHTWEIGHT,
        );
    }

    conversationIcon(conversation: IConversation): string {
        return ConversationCommon.conversationIcon(conversation);
    }

    getBadgeIcon(name: string): string {
        return `${ Config.imagesFolderBaseUrl }badges/${ name }.svg`;
    }

    public getKingBadge(): string {
        return `${ Config.imagesFolderBaseUrl }badges/king-badge.svg`;
    }

    public getVerifiedBadge(): string {
        return `${ Config.imagesFolderBaseUrl }badges/verified-badge.svg`;
    }

    public get premiumBadge(): IProfileAttributes {
        return {
            [UserCommon.GENDER_MAN]: {
                imagePath: `${ Config.imagesFolderBaseUrl }badges/prince-badge.svg`,
                imageAlt: 'shared.user.memberships.prince',
            },
            [UserCommon.GENDER_WOMAN]: {
                imagePath: `${ Config.imagesFolderBaseUrl }badges/highlight-badge.svg`,
                imageAlt: 'shared.user.memberships.highlight',
            },
        }[this.peerGender];
    }
}
