import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    EmailUnsubscribeComponentCommon,
} from '@libs/modules/initial/email-unsubscribe/email-unsubscribe.component.common';

import { Config } from '@prince/config';
import {
    EmailUnsubscribeAnimations,
} from '@prince/modules/initial/pages/email-unsubscribe/email-unsubscribe.animations';
import { UserService } from '@prince/services/user.service';

@Component({
    selector: 'mp-email-unsubscribe',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './email-unsubscribe.html',
    animations: [
        EmailUnsubscribeAnimations.animateChildren,
    ],
})
export class EmailUnsubscribeComponent extends EmailUnsubscribeComponentCommon {
    public mpUrlContact: string = Config.mpUrlContact;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected userService: UserService,
    ) {
        super(route, router, userService);
    }

    redirectInvalidKey(): void {
        this.router.navigate(['initial', 'hash-unavailable']);
    }

    redirectToNotifications = (): void => {
        this.router.navigate(['main', 'settings', 'notifications']);
    };
}
