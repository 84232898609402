import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

enum Actions {
    GET_RECOMMENDED_PROFILES = '[RecommendedList] - Get Recommended Profiles',
    STORE_RECOMMENDED_PROFILE_LIST_BY_ID = '[RecommendedList] - Store Recommended Profile List By Id',
    REFRESH_RECOMMENDED_PROFILE_LIST = '[RecommendedList] - Refresh Recommended Profile List',
}

export const getRecommendedProfiles = createAction(
    Actions.GET_RECOMMENDED_PROFILES,
);

export const storeRecommendedProfileIdList = createAction(
    Actions.STORE_RECOMMENDED_PROFILE_LIST_BY_ID,
    props<{ profiles: IProfile[] }>(),
);

export const refreshRecommendedProfileList = createAction(
    Actions.REFRESH_RECOMMENDED_PROFILE_LIST,
);
