import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProductServiceCommon } from '@libs/modules/main/services/product/product.service.common';
import { IProduct } from '@libs/store/product/interface';
import { IProductState, productAdapter } from '@libs/store/product/state';

const selectFeature =
    createFeatureSelector< IProductState>('product');

export const selectAll = createSelector(
    selectFeature,
    productAdapter.getSelectors().selectAll,
);

export const selectProductByName = createSelector(
    selectAll,
    (products: IProduct[], props: { name: string, productService: ProductServiceCommon }): IProduct | undefined => {
        return props.productService.findProductByName(props.name, products);
    },
);
