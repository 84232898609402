<button
    class="user-button"
    type="button"
    aria-haspopup="true"
    aria-expanded="true"
    (click)="navigateToPhotoVerify()"
>
    <img
        class="user-button--icon"
        src="{{ getPhotoVerifyButtonIcon() }}"
    >
    <div class="user-button--button-content">
        <div class="title">
            {{ 'modules.main.shared.home_menu.photo_verify' | translate }}
        </div>
        <div class="description">
            {{ 'modules.main.shared.menu-user-buttons.photo-verify-description' | translate }}
        </div>
    </div>
</button>
