import {
    ChangeDetectionStrategy,
    Component,
    ViewContainerRef,
} from '@angular/core';

import { BaseActionbarComponentCommon } from '@libs/modules/shared/actionbar/base-actionbar/base-actionbar.component.common';

import { ActionbarService } from '@prince/modules/main/services/actionbar/actionbar.service';

@Component({
    selector: 'mp-base-actionbar',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './base-actionbar.html',
})
export class BaseActionbarComponent extends BaseActionbarComponentCommon {
    constructor(
        protected actionBarService: ActionbarService,
        protected viewContainerRef: ViewContainerRef,
    ) {
        super(actionBarService, viewContainerRef);
    }
}
