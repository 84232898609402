<div class="login-not-allowed">
    <login-modal-container [modalTitle]="getTitle()">
        <div class="login-not-allowed__description">
            <p>
                {{ getErrorDescription() | translate }}
            </p>
        </div>
        <div class="login-not-allowed__footer">
            <button-with-loading
                class="login-forgot-password__email-sent__ok"
                [description]="getButtonText()"
                [theme]="'rad-red'"
                (click)="closeModal()"
            ></button-with-loading>
        </div>
    </login-modal-container>
</div>
