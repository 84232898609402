<button
    #buttonWithLoading
    type="submit"
    class="button-with-loading"
    [ngClass]="getBackgroundColorModifierClass()"
    [disabled]="isDisabled"
>
    <div
        class="button-with-loading__description"
        *ngIf="!isLoading"
    >
        {{ description | translate }}
    </div>
    <div
        *ngIf="isLoading"
        class="button-with-loading__loading"
    >
        <ng-container
            *ngIf="hasDescriptionWhileLoading()"
        >
            {{ descriptionWhileLoading | translate }}
        </ng-container>
        <load-icon-animated
            [theme]="theme"
        ></load-icon-animated>
    </div>
</button>

