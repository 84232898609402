import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { LoginActions } from '@libs/store/login';
import { UiActions } from '@libs/store/new-ui';

@Component({
    selector: 'login-modal-container',
    templateUrl: 'login-modal-container.html',
    styleUrls: ['login-modal-container.scss'],
})
export class LoginModalContainerComponent {
    @Input() modalTitle: string = '';

    constructor(
        protected store: Store,
        protected modalRef: ModalRef,
    ) {
        //
    }

    protected dispatchIsAttemptingLogin(isAttemptingLogin: boolean): void {
        this.store.dispatch(UiActions.setIsAttemptingLogin({
            isAttemptingLogin,
        }));
        this.dispatchIsAttemptingLogin(false);
    }

    public dispatchForgotPasswordResetState(): void {
        this.store.dispatch(LoginActions.resetState());
    }

    public getModalTitle(): string {
        return this.modalTitle;
    }

    public close(): void {
        this.modalRef.close();
        this.dispatchForgotPasswordResetState();
    }
}
