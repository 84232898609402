import { Directive } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';

@Directive()
export abstract class EffectInitializerCommon {
    initializer$ = createEffect((): Observable<Action> =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            tap((): void => {
                this.auth.mpOnInit();
            }),
        ), { dispatch: false });

    constructor(
        protected readonly actions$: Actions,
        protected readonly auth: AuthenticationServiceCommon,
    ) {
        //
    }
}
