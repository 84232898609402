import { Directive } from '@angular/core';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';

@Directive()
export abstract class ActionbarUserImageComponentCommon {
    constructor(
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    abstract getProfilePicture(): string;
}
