import { IProfile } from '@libs/shared/profile/profile';
import {
    ProfilesNewReceivedAction,
    PROFILES_NEW_RECEIVED_ACTION,
    PROFILES_NEW_REFRESHED_ACTION,
    PROFILES_NEW_REMOVED_ACTION,
} from '@libs/store/profiles/actions/new.action';

export function storeNewUserProfiles(state: number[] = [], action: ProfilesNewReceivedAction): number[] {
    switch (action.type) {
        case PROFILES_NEW_RECEIVED_ACTION:
            return onProfilesNewReceived(state, action.payload.profiles);
        case PROFILES_NEW_REMOVED_ACTION:
            return onProfilesNewRemoved(state, action.payload.profiles);
        case PROFILES_NEW_REFRESHED_ACTION:
            return [];
        default:
            return state;
    }
}

function onProfilesNewReceived(state: number[], profiles: IProfile[]): number[] {
    for (const profile of profiles) {
        state = onProfileNewReceived(state, profile);
    }

    return state;
}

function onProfileNewReceived(state: number[], newProfile: IProfile): number[] {
    const index: number = state.indexOf(newProfile.profile_id);
    if (index !== -1) {
        return state;
    }

    return [...state, newProfile.profile_id];
}

function onProfilesNewRemoved(state: number[], newProfiles: IProfile[]): number[] {
    for (const profile of newProfiles) {
        state = onProfileNewRemoved(state, profile);
    }

    return state;
}

function onProfileNewRemoved(state: number[], newProfile: IProfile): number[] {
    const index: number = state.indexOf(newProfile.profile_id);
    if (index === -1) {
        return state;
    }

    return [
        ...state.slice(0, index),
        ...state.slice(index + 1),
    ];
}
