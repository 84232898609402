<div class="container">
    <div class="row">
        <div class="col-sm-8 col-sm-offset-2 col-lg-6 col-lg-offset-3 no-padding">
            <div class="reactivate-modal-box">
                <div class="animated-content active">
                    <p class="title">{{ 'common.info' | translate }}</p>
                    <p class="description">
                        {{ 'modules.modal.change-password.description' | translate }}
                    </p>
                    <div class="actions">
                        <a
                            class="mp-main-button bt-md outline-fleur-de-lis"
                            (click)="closeModal()"
                        >
                            {{ 'common.ok' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
