import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ReferralServiceCommon } from '@libs/modules/main/services/referral.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IReferralStatus } from '@libs/shared/referral/referral-status';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { AuthenticationService } from '@prince/services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class ReferralService extends ReferralServiceCommon implements OnDestroy {
    protected numberOfUserPayments: number;
    protected numberOfMessagesReceived: number = 0;
    protected subscriptions: Subscription[] = [];

    constructor(
        protected authHttp: AuthHttpService,
        protected store: Store<IApplicationState>,
        protected auth: AuthenticationService,
    ) {
        super(authHttp, store);

        this.subscriptions.push(this.store
            .select('stats')
            .subscribe((stats): void => {
                this.numberOfUserPayments = stats.paymentsMade;
                this.numberOfMessagesReceived = stats.numberOfMessagesReceived;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public retrieveReferAll(profileId: number): Observable<IAuthResponse> {
        const endpoint: string =
            `${ Config.serverIp }referral/compensation/list/${ profileId }`;

        return this.authHttp.get(endpoint);
    }

    public retrieveReferralStatus(profileId: number): Observable<IAuthResponse> {
        const endpoint: string =
            `${ Config.serverIp }referral/compensation/status/${ profileId }`;

        return this.authHttp.get(endpoint);
    }

    public getCode(referralStatus: IReferralStatus): string {
        if (referralStatus.code === undefined) {
            return `${ Config.v2URL }cadastro/CODEERROR`;
        }

        return `${ Config.v2URL }cadastro/${ referralStatus.code }`;
    }

    public isElectiveSugarDaddy(): boolean {
        return (UserCommon.isDaddyMommyFree(this.auth.get()) ||
            UserCommon.isDaddyMommyPremium(this.auth.get())) &&
            this.numberOfUserPayments > 0;
    }

    public isElectiveSugarBaby(): boolean {
        return UserCommon.isBaby(this.auth.get()) &&
            this.numberOfMessagesReceived > 0;
    }

    public isFriendshipAdDayAvailable(): boolean {
        return Config.referralFriendshipDayEnabled;
    }

    public canShowFriendshipDayReferralAd(): boolean {
        return this.isFriendshipAdDayAvailable() &&
            (
                this.isElectiveSugarDaddy() ||
                this.isElectiveSugarBaby()
            );
    }
}
