<div class="login-container">
    <form [formGroup]="loginForm">
        <div class="login-container__meu-principe-icon">
            <img
                [src]="meuPrincipeLogo"
                alt="MeuPríncipe"
            >
        </div>
        <div class="login-container__inputs">
            <div class="login-container__inputs__email">
                <mp-generic-input
                    [form]="loginForm"
                    [controlName]="email"
                    [label]="getEmailLabelText()"
                    [id]="'login-input_email'"
                    [placeholder]="getEmailPlaceholderText() | translate"
                    [type]="'email'"
                    [errorMessage]="getInputErrorMessage(email)"
                ></mp-generic-input>
            </div>
            <div class="login-container__inputs__password">
                <mp-generic-input
                    [form]="loginForm"
                    [controlName]="password"
                    [label]="getPasswordLabelText()"
                    [id]="'login-input_password'"
                    [placeholder]="getPasswordPlaceholderText() | translate"
                    [type]="'password'"
                    [errorMessage]="getInputErrorMessage(password)"
                ></mp-generic-input>
            </div>
        </div>
        <div class="login-container__login-method">
            <a
                class="login-container__login-method__forgot-password"
                (click)="forgotPassword()"
            >
                {{ getForgotPasswordText() | translate }}
            </a>
            <button-with-loading
                [description]="getEnterAccountText()"
                (click)="submit()"
                [isLoading]="isAttemptingLogin$ | async"
                [id]="'login__enter-account'"
                [theme]="'fleur-de-lis'"
                [isDisabled]="isAttemptingLogin$ | async"
            ></button-with-loading>
        </div>
        <hr class="login-container__divider">
        <p class="login-container_presentation">
            {{ getPresentationText() | translate }}
        </p>
        <a
            class="login-container__register"
            (click)="navigateToRegistration()"
        >
            {{ getLoginRegisterText() | translate }}
        </a>
    </form>
</div>
