import { Injectable } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { concatMap, share, tap } from 'rxjs/operators';

import { ISocketService } from '@libs/shared/interfaces/socket.interface';

import { Config } from '@prince/config';
import { AuthenticationService } from '@prince/services/authentication.service';

import { Socket, io } from 'socket.io-client';

@Injectable({
    providedIn: 'root',
})
export class SocketService implements ISocketService {
    protected socketIO: Socket = io(Config.serverIp, this.getConfiguration());

    constructor(protected authenticationService: AuthenticationService) {
        //
    }

    connect(): void {
        if (this.socketIO.connected) {
            return;
        }

        this.socketIO = this.addConnectionSettings();
        this.socketIO.connect();
    }

    disconnect(): void {
        this.socketIO.disconnect();
    }

    listen(eventName: string): Observable<string> {
        return of(this.socketIO).pipe(
            concatMap((socket: Socket): Observable<string> => {
                return fromEvent(
                    socket,
                    `${ eventName }`,
                );
            }),
            share(),
            tap({
                next: (socketMessage: string): void => {
                    this.debugEvents(eventName, socketMessage);
                },
            }),
        );
    }

    listenUserEvent(eventName: string): Observable<string> {
        return this.listen(
            `${ this.authenticationService.get().profile_id }${ eventName }`,
        );
    }

    addConnectionSettings(): Socket {
        return io(Config.serverIp, this.getConfiguration());
    }

    protected debugEvents(description: string, socketMessage: string): void {
        if (!Config.showLogs.notifications) {
            return;
        }

        const date: Date = new Date();
        const timeString =
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds() +
            '.' +
            date.getMilliseconds();

        const totalLength: string = ` ${ (socketMessage.length / 1024).toFixed(2) } Kb.`;

        console.log(`[${ timeString }] NOTIFICATIONS --> ${ description }. ${ totalLength }`);
    }

    protected getConfiguration(): object {
        return {
            secure: true,
            reconnection: true,
            reconnectionDelayMax: 5000,
            transports: ['websocket'],
            path: '/notifications/v2',
            autoConnect: false,
            query: {
                profileId: this.authenticationService.get().profile_id,
                token: this.authenticationService.getToken(),
            },
        };
    }
}
