import { createFeatureSelector, createSelector } from '@ngrx/store';

import { boostedProfilesAdapter, IBoostedProfilesState } from '@libs/store/boosted-profiles/state';

const selectFeature =
    createFeatureSelector<IBoostedProfilesState>('boostedProfiles');

export const selectAll = createSelector(
    selectFeature,
    boostedProfilesAdapter.getSelectors().selectAll,
);

export const selectIds = createSelector(
    selectFeature,
    boostedProfilesAdapter.getSelectors().selectIds,
);
