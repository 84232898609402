<div class="notification-page outside-body">
    <div class="outside-banner visible-md visible-lg"></div>
    <div class="outside-page">
        <div class="container header">
            <div class="row">
                <div class="col-xs-12 col-md-6 col-lg-5">
                    <div class="fluid-container">
                        <div class="row">
                            <div class="col-xs-12 logo">
                                <img src="/assets/img/meu-patrocinio-logo-v02.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row notification-xs-sm-center">
                <div class="col-xs-12 col-md-6 col-lg-5">
                    <h1>{{ title }}</h1>
                    <p class="bold">
                        {{ text }}
                        <a
                            class="purple-link"
                            *ngIf="email"
                        >
                            {{ ' ' + email }}
                        </a>
                        .
                    </p>
                    <a
                        class="main-button outside-main-link btn btn-lg"
                        (click)="buttonClicked()"
                    >
                        {{ buttonText }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
