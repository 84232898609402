<button
    #bubble
    class="bubble"
    [class.fullWidth]="isFullWidth"
    [ngClass]="getBubbleModifierClass()"
>
    <div
        class="bubble__text"
        *ngIf="hasText()"
        (click)="handlePrimaryFunction()"
    >
        {{ text }}
    </div>
    <i
        class="material-icons bubble__icon"
        aria-hidden="true"
        *ngIf="shouldShowRefreshButton()"
        (click)="handleSecondaryFunction()" >
        {{ icon }}
    </i>
</button>
