import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'mp-bubble',
    templateUrl: './bubble.component.html',
    styleUrls: ['./bubble.component.scss'],
})
export class BubbleComponent implements OnInit {
    @Input() public icon: string = '';
    @Input() public id: string = '';
    @Input() public text: string = '';
    @Input() public theme: string = '';
    @Input() public isFullWidth: boolean = false;
    @Input() public isActive: boolean = false;
    @Input() public hasSecondaryFunction: boolean = false;
    @Output() public primaryFunction: EventEmitter<void> = new EventEmitter<void>();
    @Output() public secondaryFunction: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('bubble', { static: true }) bubble: ElementRef = new ElementRef({ });

    constructor(
        protected renderer: Renderer2,
    ) {
        //
    }

    ngOnInit(): void {
        this.addThemeClassToBubble();
    }

    public addThemeClassToBubble(): void {
        if (this.theme === '') {
            return;
        }

        this.renderer.addClass(
            this.bubble.nativeElement,
            this.theme,
        );
    }

    public hasText(): boolean {
        return this.text.length > 0;
    }

    public getBubbleModifierClass(): string {
        if (!this.isActive) {
            return '';
        }

        return `${ this.theme }--active`;
    }

    public handlePrimaryFunction(): void {
        this.primaryFunction.emit();
    }

    public handleSecondaryFunction(): void {
        this.secondaryFunction.emit();
    }

    public shouldShowRefreshButton(): boolean {
        return this.hasSecondaryFunction && this.isActive;
    }
}
