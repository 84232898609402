import { Action, createReducer, on } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import * as BoostToastActions from '@libs/store/boost-toast/actions';
import {
    boostToastInitialState,
    boostToastAdapter,
    IBoostToastState,
} from '@libs/store/boost-toast/state';

const boostReducer = createReducer(
    boostToastInitialState,
    on(
        BoostToastActions.upsertProfile,
        (
            state: IBoostToastState,
            { profile }: { profile: IProfile },
        ): IBoostToastState => {
            return boostToastAdapter.upsertOne(profile, state);
        },
    ),
    on(
        BoostToastActions.removeAllProfiles,
        (state: IBoostToastState): IBoostToastState => {
            return boostToastAdapter.removeAll(state);
        },
    ),
);

export function reducer(
    state: IBoostToastState,
    action: Action,
): IBoostToastState {
    return boostReducer(state, action);
}
