import { Action } from '@ngrx/store';

export const PROFILES_ID_PRIVATE_PHOTO_HAVE_ACCESS_RECEIVED_ACTION: string = 'PROFILES_ID_PRIVATE_PHOTO_HAVE_ACCESS_RECEIVED_ACTION';
export const PROFILES_ID_PRIVATE_PHOTO_I_HAVE_ACCESS_REMOVED_RECEIVED_ACTION: string =
    'PROFILES_ID_PRIVATE_PHOTO_I_HAVE_ACCESS_REMOVED_RECEIVED_ACTION';

export interface IProfileIdsPrivatePhotoHaveAccessReceivedActionPayload {
    profileIds: number[];
}

export class ProfileIdsPrivatePhotoIHaveAccessReceivedAction implements Action {
    readonly type: string = PROFILES_ID_PRIVATE_PHOTO_HAVE_ACCESS_RECEIVED_ACTION;

    constructor(public payload: IProfileIdsPrivatePhotoHaveAccessReceivedActionPayload) {
        //
    }
}

export class ProfilesPrivatePhotoIHaveAccessRemoveReceivedAction implements Action {
    readonly type: string = PROFILES_ID_PRIVATE_PHOTO_I_HAVE_ACCESS_REMOVED_RECEIVED_ACTION;

    constructor(public payload: IProfileIdsPrivatePhotoHaveAccessReceivedActionPayload) {
        //
    }
}
