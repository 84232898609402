import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'mp-outside-payment',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './outside-payment.html',
})
export class OutsidePaymentComponent implements OnDestroy {
    public cardPayment: boolean = true;
    public isCustomerSupport: boolean = false;
    protected subscriptions: Subscription[] = [];

    constructor(
        protected router: Router,
    ) {
        this.subscriptions.push(this.router.events.subscribe((val): void => {
            if (val instanceof NavigationEnd) {
                this.isCustomerSupport = /\/customer-support$/.test(val.urlAfterRedirects);
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }
}
