import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import {
    PaymentInfoEffectsCommon,
} from '@libs/effects/payment-info/payment-info.effects.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { PaymentInfoService } from '@prince/modules/main/services/payment/payment-info.service';
import { ProductService } from '@prince/modules/main/services/product/product.service';
import { AnalyticsService } from '@prince/services/analytics/analytics.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { BoostProductService } from '@prince/services/boost-product/boost-product.service';
import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';

@Injectable()
export class PaymentInfoEffects extends PaymentInfoEffectsCommon {
    constructor(
        protected actions$: Actions,
        protected paymentInfoService: PaymentInfoService,
        protected store: Store<IApplicationState>,
        protected productService: ProductService,
        protected modalService: ModalService,
        protected translateService: TranslateService,
        protected profileService: ProfileService,
        protected authenticationService: AuthenticationService,
        protected router: Router,
        protected analyticsService: AnalyticsService,
        protected boostProductService: BoostProductService,
    ) {
        super(
            actions$,
            paymentInfoService,
            store,
            productService,
            profileService,
            authenticationService,
            router,
            analyticsService,
            boostProductService,
        );
    }

    handlePaymentInProgress(): void {
        //
    }

    handleIsCurrentlyPaying(): void {
        //
    }

    hideLoader(): void {
        //
    }

    openCustomerSupportLink(): boolean {
        window.open(Config.supportLink, '_blank');
        return true;
    }

    protected triggerOlarkSupport(): void {
        //
    }

    protected disableGlobalAlert(): void {
        //
    }

    protected dispatchSendToken(): void {
        //
    }

    protected dispatchClearTokenFromMemory(): void {
        //
    }
}
