import {
    Directive,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProfilesSelectors } from '@libs/store/profiles-v2';

@Directive()
export class ProfilePreviewSmallComponentCommon implements OnInit, OnDestroy, OnChanges {
    @Input() profileId: number;
    @Input() selected: boolean = false;

    @Input() isSelectionMode: () => boolean;
    protected profile: IProfile | undefined;
    protected subscriptions: Subscription[] = [];
    protected user: UserCommon | undefined;

    constructor(
        protected router: Router,
        protected store: Store<IApplicationState>,
    ) {
        //
    }

    ngOnInit(): void {
        this.updateProfile();

        this.subscriptions.push(this.store.select('user').subscribe((user: UserCommon): void => {
            this.user = user;
        }));

        if (!this.isSelectionMode) {
            this.isSelectionMode = ((): boolean => false);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    ngOnChanges(): void {
        this.updateProfile();
    }

    updateProfile(): void {
        this.subscriptions.push(
            this.store.pipe(
                select(ProfilesSelectors.selectProfileById(this.profileId)),
            ).subscribe({
                next: (profile) => {
                    this.profile = profile;
                },
            }),
        );
    }

    getProfileUsername(): string {
        if (!this.profile) {
            return 'undefined';
        }

        return this.profile.username;
    }

    openProfile(): void {
        if (!this.profile) {
            return;
        }

        this.router.navigate(['main', 'profile', this.profileId]);
    }
}
