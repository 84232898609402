import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { LoginActions, LoginSelectors } from '@libs/store/login';
import { UiActions, UiSelectors } from '@libs/store/new-ui';

import { ForgotPasswordControlName } from '@prince/services/login-form-manager/login-form-control-names';
import { LoginFormManagerService } from '@prince/services/login-form-manager/login-form-manager.service';

@Component({
    templateUrl: 'login-forgot-password.html',
    styleUrls: ['login-forgot-password.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginForgotPasswordComponent implements OnInit {
    public email: string = ForgotPasswordControlName.EMAIL;
    public emailSent$: Observable<boolean> = of(false);
    public emailNotFound$: Observable<boolean> = of(false);
    public sendingRequest$: Observable<boolean> = of(false);
    public isAttemptingLogin$: Observable<boolean> = of(false);

    constructor(
        protected loginFormManagerService: LoginFormManagerService,
        protected modalRef: ModalRef,
        protected store: Store,
    ) {
        this.loginFormManagerService.setupForgotPasswordForm();
    }

    ngOnInit(): void {
        this.emailSent$ = this.store.select(
            LoginSelectors.selectForgotPasswordEmailSent,
        );
        this.emailNotFound$ = this.store.select(
            LoginSelectors.selectForgotPasswordEmailNotFound,
        );
        this.sendingRequest$ = this.store.select(
            LoginSelectors.selectForgotPasswordSendingRequest,
        );
        this.isAttemptingLogin$ = this.store.select(
            UiSelectors.selectIsAttemptingLogin,
        );
    }

    public get forgotPasswordForm(): FormGroup {
        return this.loginFormManagerService.getForgotPasswordForm();
    }

    public getEmailFormControlValue(): string {
        return this.forgotPasswordForm.get(this.email).value;
    }

    public dispatchForgotPassword(): void {
        this.store.dispatch(LoginActions.forgotPassword({
            email: this.getEmailFormControlValue(),
        }));
        this.dispatchIsAttemptingLogin(true);
    }

    protected dispatchIsAttemptingLogin(isAttemptingLogin: boolean): void {
        this.store.dispatch(UiActions.setIsAttemptingLogin({
            isAttemptingLogin,
        }));
    }

    public dispatchForgotPasswordResetState(): void {
        this.store.dispatch(LoginActions.resetState());
        this.dispatchIsAttemptingLogin(false);
    }

    public dispatchForgotPasswordEmailNotFound(): void {
        this.store.dispatch(LoginActions.forgotPasswordEmailNotFound({
            emailNotFound: false,
        }));
    }

    public close(): void {
        this.modalRef.close();
        this.dispatchForgotPasswordResetState();
    }

    protected isEmailInputTouchedAndEmpty(): boolean {
        return this.forgotPasswordForm.get(this.email).value === '' &&
            this.forgotPasswordForm.get(this.email).touched;
    }

    public getInputErrorMessage(emailNotFound: boolean): string {
        if (
            this.isEmailInputTouchedAndEmpty() &&
            !emailNotFound
        ) {
            return 'login.input.warning';
        }

        if (emailNotFound) {
            return 'login.forgot_password.warning.email_not_found';
        }

        return '';
    }

    public getModalTitle(): string {
        return 'login.forgot_password.header.title';
    }

    public getEmailSentText(): string {
        return 'login.forgot_password.email_sent.description';
    }

    public getOkButtonText(): string {
        return 'common.ok';
    }

    public getEmailPlaceholder(): string {
        return 'login.input.placeholder';
    }

    public getEmailLabel(): string {
        return 'login.forgot_password.email.label';
    }

    public getSendEmailButtonText(): string {
        return 'login.forgot_password.button.send';
    }

    public getCancelSendEmailButtonText(): string {
        return 'login.forgot_password.button.revoke';
    }
}
