import { INamedSearch, ISearch } from '@libs/shared/search/search';

export interface IUiState {
    userId?: number;
    previousProfileId?: number;
    profileAlbumAccessToDelete?: number[];
    profileSearch?: ISearch;
    savedSearches: INamedSearch[];
    currentSearchName?: string;
    displayingSearchResult?: boolean;
    imageTapped?: number;
    refreshingStatus?: boolean;
    tmpToken?: string;
    alreadySentFreeMsg?: boolean;
    maintenance?: boolean;
    feedbackHidden: boolean;
    photoVerifyHidden?: boolean;
    mpSafeTipNagbarHidden?: boolean;
    supportParams?: any;
    listViewMode: boolean;
}

export const INITIAL_UI_STATE: IUiState = {
    userId: undefined,
    previousProfileId: undefined,
    profileAlbumAccessToDelete: [],
    profileSearch: undefined,
    savedSearches: [],
    currentSearchName: '',
    displayingSearchResult: false,
    imageTapped: undefined,
    refreshingStatus: false,
    tmpToken: '',
    alreadySentFreeMsg: false,
    maintenance: false,
    feedbackHidden: false,
    photoVerifyHidden: false,
    mpSafeTipNagbarHidden: false,
    supportParams: { },
    listViewMode: false,
};
