<div class="verify-photo-container">
    <button
        id="verify-photo__button"
        class="verify-photo-container__button"
        aria-haspopup="true"
        aria-expanded="true"
        (click)="closeMenuAndNavigateToPhotoVerify()"
    >
        <img
            class="verify-photo-container__button__icon"
            [src]="getPhotoVerifyButtonIcon()"
        >
        <div class="verify-photo-container__button__content">
            <p class="verify-photo-container__button__content__title">
                {{ 'modules.main.shared.home_menu.photo_verify' | translate }}
            </p>
            <p class="verify-photo-container__button__content__description">
                {{ 'modules.main.shared.menu-user-buttons.photo-verify-description' | translate }}
            </p>
        </div>
    </button>
</div>
