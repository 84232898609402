import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
    ActionbarProfileDetailsComponentCommon,
} from '@libs/modules/shared/actionbar/actionbar-profile-details/actionbar-profile-details.component.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { AuthenticationService } from '@prince/services/authentication.service';
import { ProfileService } from '@prince/services/profile.service';
import { ImageHelper } from '@prince/utils/image-helper';

@Component({
    selector: 'mp-actionbar-profile-details',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './actionbar-profile-details.html',
    styleUrls: ['actionbar-profile-details.scss'],
})
export class ActionbarProfileDetailsComponent extends ActionbarProfileDetailsComponentCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected translate: TranslateService,
        protected authenticationService: AuthenticationService,
        protected profileService: ProfileService,
    ) {
        super(
            store,
            translate,
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public isFavorite(): boolean {
        return this.profileService.isProfileFavorite(this.profile);
    }

    getProfilePicture(): string {
        return ImageHelper.getProfilePicture(
            this.profile,
            this.authenticationService.get(),
            UserCommon.PICTURE_EXTRA_LARGE,
        );
    }
}
