import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';

import { UserMenuItemsComponenCommon } from '@libs/modules/main/shared/user-menu-items/user-menu-items.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { AuthenticationService } from '@prince/services/authentication.service';
import { ConversationService } from '@prince/services/conversation.service';

@Component({
    selector: 'mp-user-menu-items',
    templateUrl: 'user-menu-items.html',
    styleUrls: ['user-menu-items.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuItemsComponent extends UserMenuItemsComponenCommon implements OnDestroy {
    protected readonly ACTIVE_PAGE_ICON_MODIFIER_CLASS: string = 'material-icons--active';

    constructor(
        protected store: Store<IApplicationState>,
        protected auth: AuthenticationService,
        protected conversationService: ConversationService,
        protected router: Router,
        protected location: Location,
        protected changeDetectorRef: ChangeDetectorRef,
    ) {
        super(router, store, auth, conversationService);

        this.onRouteChanged(this.location.path());
        this.triggerChangeDetectionOnRouterEvents();
    }

    protected triggerChangeDetectionOnRouterEvents(): void {
        this.subscriptions.push(
            this.router.events.subscribe(
                (event: RouterEvent): void => {
                    if (!(event instanceof NavigationEnd)) {
                        return;
                    }

                    this.changeDetectorRef.markForCheck();
                },
            ),
        );
    }

    public getIconModifierClass(screenId: number): string {
        if (
            this.isAtAnyHomeRoute() ||
            !this.isPageActive(screenId)
        ) {
            return '';
        }

        return this.ACTIVE_PAGE_ICON_MODIFIER_CLASS;
    }

    protected isPageActive(pageId: number): boolean {
        return this.pageId === pageId;
    }

    protected isAtAnyHomeRoute(): boolean {
        return this.router.url.indexOf('main/home') !== -1;
    }
}
