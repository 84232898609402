import { Action } from '@ngrx/store';

import { IImageRegistrationPayload } from '@libs/shared/profile/image-registration';

export const IMAGE_RECEIVED_ACTION: string = 'IMAGE_RECEIVED_ACTION';
export const IMAGE_CLEAR_ALL_ACTION: string = 'IMAGE_CLEAR_ALL_ACTION';

export interface IImageRegistrationInputedActionPayload {
    data: IImageRegistrationPayload;
}

export class ImageReceivedAction implements Action {
    readonly type: string = IMAGE_RECEIVED_ACTION;

    constructor(public payload: IImageRegistrationInputedActionPayload) {
        //
    }
}

export class ImageClearAllAction implements Action {
    readonly type: string = IMAGE_CLEAR_ALL_ACTION;
}
