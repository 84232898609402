import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import {
    ActionbarLogoMPComponentCommon,
} from '@libs/modules/shared/actionbar/actionbar-logo-mp/actionbar-logo-mp.component.common';

import { AuthenticationService } from '@prince/services/authentication.service';

@Component({
    selector: 'mp-actionbar-logo-mp',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './actionbar-logo-mp.html',
})
export class ActionbarLogoMPComponent extends ActionbarLogoMPComponentCommon {
    constructor(
        protected router: Router,
        protected auth: AuthenticationService,
    ) {
        super();
    }

    hasLoggedIn(): boolean {
        return this.auth.isAuthenticated();
    }
}
