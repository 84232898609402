import {
    ComponentFactoryResolver,
    Injector,
    Type,
    ViewContainerRef,
} from '@angular/core';

export abstract class InjectComponentServiceCommon {
    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
    ) {
        //
    }

    inject(
        component: Type<unknown>,
        viewContainerRef: ViewContainerRef,
        injector: Injector | undefined,
    ): void {
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(component);

        this.clearViewContainerRef(viewContainerRef);

        viewContainerRef.createComponent(componentFactory, undefined, injector);
    }

    injectMultiple(
        components: Type<unknown>[],
        viewContainerRef: ViewContainerRef,
    ): void {
        this.clearViewContainerRef(viewContainerRef);

        for (const component of components) {
            const componentFactory = this.componentFactoryResolver
                .resolveComponentFactory(component);

            viewContainerRef.createComponent(componentFactory);
        }
    }

    protected clearViewContainerRef(viewContainerRef: ViewContainerRef): void {
        viewContainerRef.clear();
    }
}
