import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { concatMap, map, withLatestFrom } from 'rxjs/operators';

import { ProductServiceCommon } from '@libs/modules/main/services/product/product.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProductActions, ProductSelectors } from '@libs/store/product';
import { IProduct } from '@libs/store/product/interface';

interface IProductAvailabilityProps {
    productName: string;
    available: boolean;
}

export abstract class ProductEffectsCommon {
    setProductAvailability$: Observable<Action> = createEffect((): Observable<Action> => this.actions$
        .pipe(
            ofType(ProductActions.setProductAvailability),
            map(({ available, productName }: IProductAvailabilityProps): IProductAvailabilityProps => ({
                available,
                productName,
            })),
            concatMap(({ available, productName }: IProductAvailabilityProps): Observable<[boolean, IProduct]> => {
                return of(available).pipe(
                    withLatestFrom(this.store.pipe(
                        select(ProductSelectors.selectProductByName, {
                            name: productName,
                            productService: this.productService,
                        }),
                    ),
                    ));
            }),
            concatMap(([available, product]: [boolean, IProduct]): Observable<Action> => {
                if (product === undefined) {
                    return EMPTY;
                }

                return of(ProductActions.upsertProduct({
                    product: {
                        ...product,
                        available,
                    },
                }));
            }),
        ), { dispatch: true, useEffectsErrorHandler: true });

    constructor(
        protected actions$: Actions,
        protected store: Store<IApplicationState>,
        protected productService: ProductServiceCommon,
    ) {
        //
    }
}
