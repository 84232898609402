<main>
    <div
        class="payment-boleto-checkout"
        *ngIf="loading"
    >
        <mp-loading></mp-loading>
    </div>
    <div>
        <form (submit)="makePayment()">
            <div class="payment-boleto-checkout__form">
                <label for="cpf">
                    {{ 'modules.main.pages.payment.cpf' | translate }}
                </label>
                <input
                    class="form-control"
                    #cpf
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    id="cpf"
                    placeholder="___.___.___-__"
                >
            </div>
            <div
                *ngIf="!!downloadBoletoUrl"
                class="payment-boleto-checkout__download-message"
            >
                <p class="small">
                    <strong>
                        <mp-advanced-translate key="modules.main.pages.payment-boleto.download_boleto">
                            <a
                                [href]="downloadBoletoUrl"
                                mp-translate-param="link"
                                target="_blank"
                                download="download"
                            >
                                {{ 'modules.main.pages.payment-boleto.download_boleto.link' | translate }}
                            </a>
                        </mp-advanced-translate>
                    </strong>
                </p>
            </div>
            <div>
                <button
                    class="payment-boleto-checkout__button"
                    type="submit"
                >
                    {{ 'modules.main.pages.payment.generate_boleto' | translate }}
                </button>
            </div>
        </form>
    </div>
</main>
