import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
} from '@ngrx/entity';

import { IReferral } from '@libs/shared/referral/referral';

export interface IReferralState extends EntityState<IReferral> {
    //
}

function selectReferralId(referral: IReferral): number | null {
    return referral.friend_id;
}

export const referralAdapter: EntityAdapter<IReferral> = createEntityAdapter<IReferral>({
    selectId: selectReferralId,
});

export const referralInitialState: IReferralState =
    referralAdapter.getInitialState({ });
