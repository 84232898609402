<ng-container *ngIf="submenuItems"></ng-container>
<div class="submenu-list">
    <div
        class="submenu-list__navigation-list container"
        role="tablist"
    >
        <ng-container *ngIf="hasGridViewSelector">
            <view-style-selector></view-style-selector>
        </ng-container>
        <div
            class="submenu-list__item "
            *ngFor="let listItem of submenuItems"
            role="presentation"
            [ngClass]="getGenericButtonModifierClass(listItem.index)"
        >
            <mp-bubble
                id="bubbles-submenu-{{listItem.displayName | translate}}"
                (click)="nagivateToIndex(listItem.index)"
                aria-controls="home"
                role="tab"
                data-toggle="tab"
                text= "{{ listItem.displayName | translate }}"
                icon="&#xE863;"
                [hasSecondaryFunction]="hasSecondaryFunction"
                (secondaryFunction)="refreshList(listItem.index)"
                [theme]="'prince-theme'"
                [isFullWidth]="false"
                [isActive]="isButtonActive(listItem.index)"
            ></mp-bubble>
        </div>
    </div>
</div>
