export function arrayFill<T = any>(array: T[], value: T, start?: number, end?: number): T[] {
    const length = array.length;

    const relativeStart = start ?? 0;

    let index = Math.min(relativeStart, length);
    if (relativeStart < 0) {
        index = Math.max(length + relativeStart, 0);
    }

    const relativeEnd = end ?? length;

    let final = Math.min(relativeEnd, length);
    if (relativeEnd < 0) {
        final = Math.max(length + relativeEnd, 0);
    }

    for (; index < final; index++) {
        array[index] = value;
    }

    return array;
}

export function arrayFind<T = any>(
    array: T[],
    predicate: (value: T, index?: number, array?: T[]) => boolean,
    thisArg?: any,
): T | undefined {
    const index = arrayFindIndex(array, predicate, thisArg);

    if (index === -1) {
        return undefined;
    }

    return array[index];
}

export function arrayFindIndex<T = any>(
    array: T[],
    predicate: (value: T, index?: number, array?: T[]) => boolean,
    thisArg?: any,
): number {
    for (let i = 0; i < array.length; i++) {
        if (predicate.call(thisArg, array[i], i, array)) {
            return i;
        }
    }

    return -1;
}

export function arrayShuffle<T = any>(array: T[]): T[] {
    return array.sort((): number => Math.random() - 0.5);
}

export function range(start: number, end?: number, step?: number): number[] {
    if (end === undefined) {
        end = start;
        start = 0;
    }

    if (step === undefined) {
        step = 1;
        if (start > end) {
            step = -1;
        }
    }

    let index = -1;
    let length = Math.max(Math.ceil((end - start) / (step || 1)), 0);
    const result = arrayBuild<number>(length);

    while (length--) {
        result[++index] = start;
        start += step;
    }

    return result;
}

export function generateRange(range: number[]): number[] {
    if (range.length < 2) {
        throw new Error('Array must have two positions');
    }

    const [
        lesserValue = 0,
        greaterValue = 1,
    ]: number[] = range;

    if (greaterValue <= lesserValue) {
        throw new Error('Second value must be greater than first');
    }

    const rangeWidth: number = greaterValue - lesserValue + 1;

    return Array(rangeWidth).fill(1)
        .map((_, index: number): number => index + lesserValue);
}

export function arrayBuild<T = any>(length: number): T[] {
    const ret = [];
    for (let i = 0; i < length; i++) {
        ret[i] = undefined;
    }

    return ret;
}

export function orderBy<T = any>(array: T[], predicate: (value: T) => number | string): T[] {
    return [...array].sort((a, b): number => {
        const predicateA = predicate(a);
        const predicateB = predicate(b);

        if (typeof predicateA === 'number' && typeof predicateB === 'number') {
            return predicateA - predicateB;
        }

        if (typeof predicateA === 'string' && typeof predicateB === 'string') {
            if (predicateA < predicateB) {
                return -1;
            }

            return 1;
        }

        return 1;
    });
}

export function arrayChunk<T>(array: T[], size: number = 1): T[][] {
    const ret: T[][] = [];
    let chunk: T[] = [];

    for (const item of array) {
        chunk.push(item);

        if (chunk.length >= size) {
            ret.push(chunk);
            chunk = [];
        }
    }

    ret.push(chunk);
    return ret;
}

export function arrayDiff<T>(
    arrayA: T[],
    arrayB: T[],
    comparator: (a: T, b: T) => boolean = (a, b): boolean => a === b,
): T[] {
    return [...arrayA].filter((itemA): boolean => arrayB.every((itemB): boolean => !comparator(itemA, itemB)));
}

export function arrayUnique<T>(
    array: T[],
    comparator: (a: T, b: T) => boolean = (a, b): boolean => a === b,
): T[] {
    const ret: T[] = [];

    for (const item of array) {
        if (ret.every((insertedItem): boolean => !comparator(item, insertedItem))) {
            ret.push(item);
        }
    }

    return ret;
}

export function forEach<T>(array: ArrayLike<T>, callback: (value: T, index?: number) => void): void {
    Array.prototype.forEach.call(array, callback);
}
