import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@prince/guards/auth/auth-guard.service';
import { InitialChangePasswordComponent } from '@prince/modules/initial/pages/change-password/change-password.component';
import {
    EmailUnsubscribeComponent,
} from '@prince/modules/initial/pages/email-unsubscribe/email-unsubscribe.component';
import { EmailVerifyComponent } from '@prince/modules/initial/pages/email-verify/email-verify.component';
import { HashExpiredComponent } from '@prince/modules/initial/pages/hash-expired/hash-expired.component';
import { HashUnavailableComponent } from '@prince/modules/initial/pages/hash-unavailable/hash-unavailable.component';
import { LoginComponent } from '@prince/modules/initial/pages/login/login.component';
import { NewPasswordComponent } from '@prince/modules/initial/pages/new-password/component/new-password.component';
import { MaintenanceComponent } from '@prince/modules/main/pages/maintenance/maintenance.component';
import { OutsidePaymentComponent } from '@prince/modules/main/pages/outside-payment/outside-payment.component';
import { NotFoundComponent } from '@prince/modules/not-found.component';
import { RegistrationModule } from '@prince/modules/registration/registration.module';
import { WaitingListModule } from '@prince/modules/waiting-list/waiting-list.module';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'initial/login',
        pathMatch: 'full',
    },
    {
        path: 'initial/login',
        component: LoginComponent,
    },
    {
        path: 'initial/email-verify/:hash',
        component: EmailVerifyComponent,
    },
    {
        path: 'initial/email-unsubscribe/:key',
        component: EmailUnsubscribeComponent,
    },
    {
        path: 'initial/change-password/:hash',
        component: InitialChangePasswordComponent,
    },
    {
        path: 'initial/hash-unavailable',
        component: HashUnavailableComponent,
    },
    {
        path: 'initial/hash-expired',
        component: HashExpiredComponent,
    },
    {
        path: 'initial/new-password',
        component: NewPasswordComponent,
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
    },
    {
        path: 'main',
        canActivate: [AuthGuard],
        loadChildren: async (): Promise<any> =>
            import('@prince/modules/main/main.module')
                .then((module): typeof module.MainModule => module.MainModule),
    },
    {
        path: 'express-approval',
        loadChildren: async (): Promise<typeof WaitingListModule> =>
            import('@prince/modules/waiting-list/waiting-list.module')
                .then((module): typeof module.WaitingListModule => module.WaitingListModule),
    },
    {
        path: 'cadastro',
        loadChildren: async (): Promise<typeof RegistrationModule> =>
            import('@prince/modules/registration/registration.module')
                .then((module): typeof module.RegistrationModule => module.RegistrationModule),
    },
    // THE ROUTE BELOW MUST ALWAYS GO LAST
    {
        path: '**',
        component: NotFoundComponent,
    },
];

export const navigatableComponents = [
    EmailVerifyComponent,
    EmailUnsubscribeComponent,
    HashExpiredComponent,
    HashUnavailableComponent,
    InitialChangePasswordComponent,
    LoginComponent,
    MaintenanceComponent,
    NewPasswordComponent,
    NotFoundComponent,
    OutsidePaymentComponent,
];

@NgModule({
    imports: [
        RouterModule.forRoot([
            ...routes,
            {
                path: '**',
                component: NotFoundComponent,
            },
        ], {
            scrollPositionRestoration: 'enabled',
            preloadingStrategy: PreloadAllModules,
        }),
        HttpClientModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingWebModule {
    //
}
