import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IBoletoOrderResponse, IOrderResponse } from '@libs/modules/main/services/payment/moip/moip';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

import { Config } from '@prince/config';
import { BoletoWebComponentCommon } from '@prince/modules/main/pages/payment-boleto/boleto.component.common';
import { MoipService } from '@prince/modules/main/services/payment/moip/moip.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ConversationService } from '@prince/services/conversation.service';
import { DownloadManagerService } from '@prince/services/download-manager.service';
import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';

@Component({
    selector: 'mp-moip-boleto',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: '../payment-boleto.html',
    styleUrls: ['../payment-boleto.scss'],
})
export class MoipBoletoComponent extends BoletoWebComponentCommon {
    public downloadBoletoUrl: string = '';
    public isElitePayment: boolean = false;

    constructor(
        protected router: Router,
        protected paymentService: MoipService,
        protected downloadManager: DownloadManagerService,
        protected profileService: ProfileService,
        protected translate: TranslateService,
        protected conversationService: ConversationService,
        protected modalService: ModalService,
        protected authenticationService: AuthenticationService,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        super(
            paymentService,
            translate,
            modalService,
            authenticationService,
            paymentHelpersService,
        );
    }

    makePayment(): void {
        this.downloadBoletoUrl = '';
        if (!this.validate()) {
            return;
        }

        this.loading = true;

        this.pay().subscribe((orderResponse: IAuthResponse<IBoletoOrderResponse>): void => {
            this.loading = false;
            if (orderResponse.status !== 201) {
                this.modalService.open(
                    this.translate.instant('common.unknown_error'),
                );
                return;
            }

            this.downloadBoletoUrl = orderResponse.data._links.payBoleto.printHref;
            this.downloadBoleto(orderResponse.data._links.payBoleto.printHref);
        }, (error: HttpErrorResponse): void => {
            this.loading = false;
            if (Config.development) {
                console.log(error);
            }
            this.modalService.open(
                this.translate.instant('common.unknown_error'),
            );
            return;
        });
    }

    pay(): Observable<IAuthResponse<IOrderResponse>> {
        return this.paymentService.generateBoleto(
            this.type,
            this.price,
            this.getCPF(),
        );
    }

    downloadBoleto(url: string): boolean {
        window.open(url, '_blank');
        return false;
    }

    goBack(): void {
        this.router.navigate(['main'], { replaceUrl: true });
    }
}
