import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ProductEffectsCommon } from '@libs/effects/product/product.effects.common';
import { IApplicationState } from '@libs/store/application-state';

import { ProductService } from '@prince/modules/main/services/product/product.service';

@Injectable()
export class ProductEffects extends ProductEffectsCommon {
    constructor(
        protected actions$: Actions,
        protected store: Store<IApplicationState>,
        protected productService: ProductService,
    ) {
        super(
            actions$,
            store,
            productService,
        );
    }
}
