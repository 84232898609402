import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IProfile } from '@libs/shared/profile/profile';
import { FavoritesSelectors } from '@libs/store/favorites';
import { MeltProfilesActions } from '@libs/store/melt-profiles';

import { ProfileService } from '@prince/services/profile.service';

@Component({
    selector: 'public-profile-buttons',
    templateUrl: 'public-profile-buttons.html',
    styleUrls: ['public-profile-buttons.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class PublicProfileButtonsComponent implements OnInit {
    @ViewChild('toogleButton', { static: true }) tooogleButton: ElementRef;
    @Input() public profile: IProfile;
    @Input() protected profileId: number = 0;

    public isFavorite: boolean = false;
    protected subscriptions: Subscription[] = [];

    constructor(
        protected profileService: ProfileService,
        protected store: Store,
        protected router: Router,
        protected renderer2: Renderer2,
    ) {
        //
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.pipe(
                select(FavoritesSelectors.selectIsFavorite(this.profileId)),
            ).subscribe({
                next: (isFavorite: boolean): void => {
                    this.isFavorite = isFavorite;
                },
            }),
        );
    }

    public toogleFavorite(profile: IProfile): void {
        if (!this.isFavorite) {
            this.addFavorite(profile);

            return;
        }

        this.removeFavorite(profile);
    }

    protected addFavorite(profile: IProfile): void {
        this.isFavorite = true;
        this.profileService.like(profile);
    }

    protected removeFavorite(profile: IProfile): void {
        this.isFavorite = false;
        this.profileService.unLike(profile);
        this.removeMelt(profile);
    }

    protected removeMelt(profile: IProfile): void {
        this.store.dispatch(
            MeltProfilesActions.removeMeltProfile({ profile }),
        );
    }

    public modifierClassFavoriteButton(): string {
        if (this.isFavorite) {
            return 'public-profile-buttons__favorite--favorited';
        }

        return '';
    }

    public navigateToMessages(): void {
        this.router.navigate(
            [
                '/main',
                'conversation',
                'message',
                this.profileId,
            ],
        );
    }
}
