import {
    PhotoUploadedReceivedAction,
    PHOTO_UPLOADED_RECEIVED_ACTION,
} from '@libs/store/authentication/actions/photo-uploaded.action';

export function photoUploaded(state: number = 0, action: PhotoUploadedReceivedAction): number {
    switch (action.type) {
        case PHOTO_UPLOADED_RECEIVED_ACTION:
            return state + 1;
        default:
            return state;
    }
}
