import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
    EmailVerifyComponentCommon,
} from '@libs/modules/initial/email-verify/email-verify.component.common';

import { AuthenticationService } from '@prince/services/authentication.service';
import { UserService } from '@prince/services/user.service';

@Component({
    selector: 'mp-email-verify',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './email-verify.html',
})
export class EmailVerifyComponent extends EmailVerifyComponentCommon {
    protected subscriptions: Subscription[] = [];

    constructor(
        public router: Router,
        protected route: ActivatedRoute,
        protected userService: UserService,
        protected auth: AuthenticationService,
    ) {
        super(userService, route, auth);
    }

    redirectError(): void {
        this.router.navigate(['initial', 'hash-unavailable']);
    }

    redirectHashExpired(): void {
        this.router.navigate(['initial', 'hash-expired']);
    }
}
