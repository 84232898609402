import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EXPRESS_APPROVAL_ID } from '@libs/modules/main/services/payment/payment.service.common';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';

@Component({
    selector: 'mp-express-approval-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './express-approval-modal.html',
})
export class ExpressApprovalModalComponent implements IModalComponent {
    public data: never;
    public agreeTerms: boolean = false;
    public EXPRESS_APPROVAL_ID: number = EXPRESS_APPROVAL_ID;

    constructor(
        protected modalRef: ModalRef,
    ) {
        //
    }

    public handleChanges(change: boolean): void {
        this.agreeTerms = change;
    }

    public getDisabledClass(): string {
        if (!this.agreeTerms) {
            return 'disabled';
        }
        return '';
    }

    public close(): void {
        this.modalRef.close();
    }
}
