<div class="container-fluid">
    <div class="row">
        <mp-email-unsubscribe-header
            [mpLogo]="mpWebLogo"
            [emailResponse]="emailResponse"
        ></mp-email-unsubscribe-header>
        <div class="content-unsubscribe">
            <mp-email-unsubscribe-loading
                @animateChildren
                *ngIf="isLoading"
                [loadingMessage]="loadingMessage"
            ></mp-email-unsubscribe-loading>
            <mp-email-unsubscribe-success
                @animateChildren
                *ngIf="hasSuccessfullyUnsubscribed()"
                [successHeaderTranslation]="successHeaderTranslation"
                [successDescriptionTranslation]="successDescriptionTranslation"
                [actionItems]="actionItems"
            ></mp-email-unsubscribe-success>
            <mp-email-unsubscribe-error
                @animateChildren
                *ngIf="hasError()"
                [attemptsError]="attemptsError"
                [errorHeaderTranslation]="errorHeaderTranslation"
                [retryButtonTranslation]="retryButtonTranslation"
                [errorDescriptionTranslation]="errorDescriptionTranslation"
                (retryUnsubscribe)="unsubscribeByKey()"
            ></mp-email-unsubscribe-error>
        </div>
    </div>
</div>
