import {
    animate,
    AnimationTriggerMetadata,
    style,
    transition,
    trigger,
    state,
} from '@angular/animations';

export const MpToastAnimations: AnimationTriggerMetadata[] = [
    trigger('flyInOut', [
        state(
            'inactive',
            style({
                display: 'none',
                opacity: 0,
            }),
        ),
        state('active', style({ })),
        state('removed', style({ opacity: 0 })),
        transition(
            'inactive => active',
            animate('{{ easeTime }}ms {{ easing }}'),
        ),
        transition(
            'active => removed',
            animate('{{ easeTime }}ms {{ easing }}'),
        ),
    ]),
];
