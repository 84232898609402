import { Action, createReducer, on } from '@ngrx/store';

import { manyProfilesReceived, oneProfileReceived, removeProfile, resetProfileFavorited } from '@libs/store/profiles-v2/actions';
import { IProfilesState, profilesEntityAdapter, profilesEntitiesInitialState } from '@libs/store/profiles-v2/state';

const ProfilesReducer = createReducer(
    profilesEntitiesInitialState,
    on(
        manyProfilesReceived,
        (state, { profiles }) => {
            return profilesEntityAdapter.upsertMany(
                profiles,
                state,
            );
        },
    ),
    on(
        oneProfileReceived,
        (state, { profile }) => {
            return profilesEntityAdapter.upsertOne(
                profile,
                state,
            );
        },
    ),
    on(
        resetProfileFavorited,
        (state, { profileId }) => {
            return profilesEntityAdapter.updateOne(
                { id: profileId, changes: { favorited: 0 } },
                state,
            );
        },
    ),
    on(
        removeProfile,
        (state, { profileId }) => {
            return profilesEntityAdapter.removeOne(
                profileId,
                state,
            );
        },
    ),
);

export function reducer(
    state: IProfilesState,
    action: Action,
): IProfilesState {
    return ProfilesReducer(state, action);
}
