import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';

import { Alert, AlertType } from '@prince/modules/main/modals/alert/alert.interfaces';

@Injectable()
export class AlertService implements OnDestroy {
    protected subjectAlert: Subject<Alert> = new Subject<Alert>();
    protected keepAfterRouteChange: boolean = false;
    protected subscriptions: Subscription[] = [];

    constructor(protected router: Router) {
        this.subscriptions.push(this.router.events.subscribe((event): void => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    this.keepAfterRouteChange = false;
                    return;
                }

                this.clear();
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    getAlert(): Observable<Alert> {
        return this.subjectAlert.asObservable();
    }

    success(message: string, canClose: boolean = true, keepAfterRouteChange: boolean = false): void {
        this.alert(AlertType.Success, message, canClose, keepAfterRouteChange);
    }

    error(message: string, canClose: boolean = true, keepAfterRouteChange: boolean = false): void {
        this.alert(AlertType.Error, message, canClose, keepAfterRouteChange);
    }

    info(message: string, canClose: boolean = true, keepAfterRouteChange: boolean = false): void {
        this.alert(AlertType.Info, message, canClose, keepAfterRouteChange);
    }

    warn(message: string, canClose: boolean = true, keepAfterRouteChange: boolean = false): void {
        this.alert(AlertType.Warning, message, canClose, keepAfterRouteChange);
    }

    alert(
        type: AlertType,
        message: string,
        canClose: boolean = true,
        keepAfterRouteChange: boolean = false,
    ): void {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subjectAlert.next({ type, message, canClose });
    }

    clear(): void {
        this.subjectAlert.next();
    }
}
