<div class="unsubscribe-content">
    <div
        class="unsubscribe-content__main--animate"
        @slideToggle
    >
        <h1
            class="header"
            *ngIf="successHeaderTranslation"
        >
            {{ successHeaderTranslation | translate }}
        </h1>
        <p
            class="description"
            *ngIf="successDescriptionTranslation"
        >
            {{ successDescriptionTranslation | translate }}
        </p>
        <div class="unsubscribe-content__actions">
            <p class="unsubscribe-content--actions-title">
                {{ 'modules.initial.pages.email-unsubscribe.action-title' | translate }}
            </p>
            <ul class="unsubscribe-content--action-buttons">
                <li
                    class="action-item"
                    *ngFor="let actionItem of actionItems"
                >
                    <a
                        href="javascript:void(0)"
                        (click)="actionItem.definedNaviation()"
                    >
                        {{ actionItem.actionTranslation | translate }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="unsubscribe-content__mp-links">
            <a
                href="javascript:void(0)"
                [routerLink]="['/main', 'home']"
            >
                {{ 'modules.initial.pages.email-unsubscribe.mp-home-link' | translate }}
            </a>
        </div>
    </div>
</div>
