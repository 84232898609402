import { Injectable } from '@angular/core';

import emailMisspelled, { top100 } from 'email-misspelled';

@Injectable()
export class EmailSuggestionFacade {
    public getEmailSuggestionMachine() {
        return emailMisspelled({
            maxMisspelled: 4,
            lengthDiffMax: 5,
            domains: top100,
        });
    }
}
