import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { MembershipEffectsCommon } from '@libs/effects/membership/membership.effects.common';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { IApplicationState } from '@libs/store/application-state';

@Injectable()
export class MembershipEffects extends MembershipEffectsCommon {
    constructor(
        protected actions$: Actions,
        protected store: Store<IApplicationState>,
    ) {
        super(actions$, store);
    }

    handlePaidMembershipChange(_: MembershipType): void {
        //
    }
}
