import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';

import { LanguageServiceCommon } from '@libs/modules/main/services/language/language.service.common';
import { ILanguage } from '@libs/shared/language/interfaces/language.interface';
import { LanguageModel } from '@libs/shared/language/language-model';

import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class LanguageService extends LanguageServiceCommon {
    public static readonly COOKIE_NAME: string = 'lang';
    protected static readonly CHECK_TIMER_MS: number = 5000;

    protected isObservableRunning: boolean = false;

    constructor(
        protected cookieService: CookieService,
        protected translateService: TranslateService,
    ) {
        super();
        this.startLanguageCheckListener();
    }

    public startLanguageCheckListener(): void {
        if (this.isObservableRunning) {
            return;
        }

        this.isObservableRunning = true;
        timer(LanguageService.CHECK_TIMER_MS).subscribe(
            (): void => this.languageCheckListener(),
        );
    }

    protected languageCheckListener(): void {
        const currentLang: ILanguage = this.currentLanguage();
        if (currentLang.lang === this.translateService.defaultLang) {
            this.isObservableRunning = false;
            this.startLanguageCheckListener();
            return;
        }

        this.changeLanguage(currentLang);
        location.reload();
    }

    public currentLanguage(): ILanguage {
        const lang: string = this.cookieService.get(
            LanguageService.COOKIE_NAME,
        );

        const language: ILanguage | undefined = LanguageModel.ALL_LANGUAGES.find(
            (value: ILanguage): boolean => value.lang === lang,
        );

        if (language === undefined) {
            return LanguageModel.DEFAULT_LANGUAGE;
        }

        return language;
    }

    public changeLanguage(value: ILanguage): void {
        this.cookieService.delete(
            LanguageService.COOKIE_NAME,
            '/',
        );

        const expiryDate: Date = new Date();
        expiryDate.setDate(expiryDate.getDate() + 999);
        this.cookieService.set(
            LanguageService.COOKIE_NAME,
            value.lang,
            {
                expires: expiryDate,
                path: '/',
            },
        );
    }
}
