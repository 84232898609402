<div class="coupon">
    <form [formGroup]="couponForm">
        <p
            class="coupon__description"
            for="discount-coupon">
            {{ 'modules.main.pages.plans.upgrade.coupon.title' | translate }}
        </p>
        <label class="coupon__field">
            <div class="coupon__field__left-content">
                <span
                    class="material-icons-outlined"
                    [innerHTML]="getTagMaterialIcons()"
                ></span>
                <input
                    type="text"
                    [formControlName]="'coupon'"
                    [id]="'discount-coupon'"
                    [placeholder]="getInputPlaceholder()"
                >
            </div>
            <button-with-loading
                (click)="redeem()"
                [isLoading]="isLoading()"
                [theme]="'fleur-de-lis'"
                [description]="'modules.main.pages.plans.upgrade.coupon.text_button'"
            ></button-with-loading>
        </label>
        <p
            class="coupon__fail-message"
            *ngIf="redeemFail()"
        >
            {{ getErrorMessage(this.errorMessage) }}
        </p>
    </form>
</div>
