import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';

export interface IBoostToastState extends EntityState<IProfile> {
    //
}

function selectProfileId(profile: IProfile): number | undefined {
    return profile.profile_id;
}

export const boostToastAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>(
    {
        selectId: selectProfileId,
        sortComparer: false,
    },
);

export const boostToastInitialState: IBoostToastState = boostToastAdapter.getInitialState(
    { },
);
