import { IReason } from '@libs/store/deactivate-reason';
import { arrayShuffle } from '@libs/utils/array-functions';

export abstract class DeactivateAccountCommon {
    protected static readonly reasons: IReason[] = [
        { value: 2, label: 'did_not_like' },
        { value: 3, label: 'did_not_find' },
        { value: 4, label: 'temporary' },
        { value: 5, label: 'in_relationship' },
        { value: 6, label: 'found' },
        { value: 7, label: 'no_messages' },
        { value: 8, label: 'too_costly' },
        { value: 9, label: 'tech_problem' },
    ];

    protected static readonly otherReason: IReason = { value: 0, label: 'others' };

    public static getShuffleReasons(reasons: IReason[]): IReason[] {
        return arrayShuffle(reasons)
            .concat([DeactivateAccountCommon.otherReason]);
    }

    public static getReasons(): IReason[] {
        return this.reasons;
    }
}
