import { IOlarkState } from '@libs/store/olark/interfaces/olark-state.interface';

export const olarkInitialState: IOlarkState = {
    activeConversation: {
        userFlow: null,
        message: null,
        timestamp: null,
        chatboxConfig: null,
    },
};
