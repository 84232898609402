import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { BadgesComponentCommon } from '@libs/components/badges/badges.component.common';
import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { BoostLastViewersService } from '@prince/modules/shared/boost-shared/services/boost-last-viewers/boost-last-viewers.service';

@Component({
    selector: 'mp-badges',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './badges.html',
})
export class BadgesComponent extends BadgesComponentCommon {
    public getAttributes: IProfileAttributes[] = [
        {
            isVerified: (): boolean => this.isMelt(),
            className: 'changed-attribute',
            imagePath: `${ Config.imagesFolderBaseUrl }badges/charm-badge.svg`,
            imageAlt: 'common.list.melt',
        },
        {
            isVerified: (): boolean => this.isElite(),
            imagePath: `${ Config.imagesFolderBaseUrl }badges/king-badge.svg`,
            imageAlt: 'shared.user.memberships.king',
        },
        {
            isVerified: (): boolean => this.isHighlight(),
            imagePath: `${ Config.imagesFolderBaseUrl }badges/highlight-badge.svg`,
            imageAlt: 'shared.user.memberships.highlight',
        },
        {
            isVerified: (): boolean => this.isPrince(),
            imagePath: `${ Config.imagesFolderBaseUrl }badges/prince-badge.svg`,
            imageAlt: 'shared.user.memberships.prince',
        },
        {
            isVerified: (): boolean => this.isVerified(),
            imagePath: `${ Config.imagesFolderBaseUrl }badges/verified-badge.svg`,
            imageAlt: 'modules.main.pages.profile.member_verified',
        },
        {
            isVerified: (): boolean => this.isNew(),
            imagePath: `${ Config.imagesFolderBaseUrl }badges/new-badge.svg`,
            imageAlt: 'modules.main.pages.profile.member_new',
            interpolateParams: { gender: this.getGender() },
        },
    ];

    constructor(
        protected changeDetector: ChangeDetectorRef,
        protected store: Store<IApplicationState>,
        public translate: TranslateService,
        protected boostLastViewers: BoostLastViewersService,
    ) {
        super(
            changeDetector,
            store,
            translate,
            boostLastViewers,
        );
    }

    protected isHighlight(): boolean {
        return this.isPremium() &&
            this.getGender() === UserCommon.GENDER_WOMAN;
    }

    protected isPrince(): boolean {
        return this.isPremium() &&
            this.getGender() === UserCommon.GENDER_MAN;
    }
}
