<div class="boost-activated-card">
    <div class="boost-activated-card__header">
        <div class="boost-activated-card__header__title-block">
            <h3>{{ title | translate }}</h3>
        </div>
        <img
            [ngClass]="getIconClasses()"
            class="boost-icon"
            src="/assets/img/boost-icon.svg"
            [alt]="'common.boost_icon' | translate"
        >
    </div>
    <div class="boost-activated-card__body">
        <div class="boost-activated-card__body__boost-description">
            <mp-boost-multiplier></mp-boost-multiplier>
            <mp-boost-bubbles></mp-boost-bubbles>
        </div>
        <mp-boost-progress></mp-boost-progress>
    </div>
</div>
