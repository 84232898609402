import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { NewPasswordFormControlNames } from '@prince/modules/initial/pages/new-password/services/new-password-form/enums/new-password-form-control-names';

@Injectable({
    providedIn: 'root',
})
export class NewPasswordFormService {
    protected newPasswordForm: FormGroup = this.formBuilder.group({ });

    constructor(
        protected formBuilder: FormBuilder,
    ) {
        //
    }

    public setupNewPasswordForm(): void {
        this.newPasswordForm = this.formBuilder.group({
            [NewPasswordFormControlNames.PASSWORD]: ['', Validators.required],
            [NewPasswordFormControlNames.PASSWORD_CONFIRMATION]: ['', Validators.required],
        }, {
            validators: this.checkIfPasswordsMatch,
        });
    }

    public getNewPasswordForm(): FormGroup {
        return this.newPasswordForm;
    }

    protected checkIfPasswordsMatch: ValidatorFn =
        (formGroup: FormGroup): ValidationErrors | null => {
            const password: string = formGroup.get(
                NewPasswordFormControlNames.PASSWORD,
            ).value;
            const confirmPassword: string = formGroup.get(
                NewPasswordFormControlNames.PASSWORD_CONFIRMATION,
            ).value;

            if (confirmPassword === password) {
                return null;
            }

            return {
                unmatchingPasswords: true,
            };
        };
}
