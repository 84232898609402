import { Action } from '@ngrx/store';

export const ALREADY_SENT_FREE_MSG_ACTION: string = 'ALREADY_SENT_FREE_MSG_ACTION';

export class AlreadySentFreeMsgAction implements Action {
    readonly type: string = ALREADY_SENT_FREE_MSG_ACTION;

    constructor(public payload: boolean) {
        //
    }
}
