import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastCommon } from '@libs/effects/toast/common/toast.common';
import {
    ProfileServiceCommon,
} from '@libs/modules/main/services/profile/profile.service.common';
import {
    ToastContextCommon,
} from '@libs/modules/main/toast/strategy/toast-context.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import {
    AuthenticationServiceCommon,
} from '@libs/services/authentication/authentication.service.common';
import { IProfile } from '@libs/shared/profile/profile';
import { IToastComponent } from '@libs/shared/toast/interfaces/toast-component.interface';
import {
    IToastParameters,
    ToastType,
} from '@libs/shared/toast/interfaces/toast-parameters.interface';
import { ToastActions } from '@libs/store/toast';

@Directive()
export abstract class ToastServiceCommon {
    public toastType: ToastType = ToastType.typeMessage;
    protected subscriptions: Subscription[] = [];

    constructor(
        protected profileService: ProfileServiceCommon,
        protected translateService: TranslateService,
        protected router: Router,
        protected authenticationService: AuthenticationServiceCommon,
        protected store: Store,
        protected toastContext: ToastContextCommon,
    ) {
        //
    }

    abstract hasCustomToastRunning(): boolean;

    abstract canToast(): boolean;

    abstract handleCustomToastInjection(component: IToastComponent): void;

    abstract showToast(toastType: ToastType, profiles: IProfile[]): void;

    protected abstract resetToast(): void;

    protected abstract getImages(profiles: IProfile[]): string[];

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    public initialize(toastParameters: IToastParameters): void {
        if (!this.canToast()) {
            return;
        }

        this.persistProfilesOnToastCustom(
            toastParameters.profile,
            toastParameters.toastType,
        );

        this.setupToast({
            profile: toastParameters.profile,
            toastType: toastParameters.toastType,
            component: toastParameters.component,
        });
    }

    public setupToast({
        profile,
        toastType,
        component,
    }: IToastParameters): void {
        if (this.hasCustomToastRunning()) {
            return;
        }

        this.resetToast();

        if (!ToastCommon.isDefaultToastComponentName(component)) {
            this.handleCustomToastInjection(component);
            return;
        }

        this.createToast(toastType, profile);
    }

    public createToast(toastType: ToastType, profile: IProfile): void {
        this.updateToastType(toastType);

        this.dispatchShowToast(profile, toastType);
    }

    public persistProfile(profile: IProfile): void {
        if (!ToastCommon.isToastDefaultType(this.toastType)) {
            return;
        }

        this.toastContext.changeStrategy(this.toastType);
        this.toastContext.execute(this.store, profile);
    }

    public resetProfilesByToastType(): void {
        if (!ToastCommon.isToastDefaultType(this.toastType)) {
            return;
        }

        this.toastContext.changeStrategy(this.toastType);
        this.toastContext.clear(this.store);
    }

    public dispatchShowToast(profile: IProfile, toastType: ToastType): void {
        this.store.dispatch(ToastActions.showToastProfile({
            profile,
            toastType,
        }));
    }

    public isProfileAlreadyOnToast(newToastProfile: IProfile, profiles: IProfile[]): boolean {
        return profiles.some((profile: IProfile): boolean =>
            profile.profile_id === newToastProfile.profile_id,
        );
    }

    public updateToastType(toastType: ToastType): void {
        this.toastType = toastType;
    }

    public navigate(profiles: IProfile[]): void {
        if (!ToastCommon.isToastDefaultType(this.toastType)) {
            return;
        }

        this.toastContext.changeStrategy(this.toastType);

        if (profiles.length === 1) {
            this.toastContext.navigate(this.router, profiles);
            return;
        }

        this.toastContext.navigateMultiple(this.router);
    }

    public persistProfilesOnToastCustom(
        profile: IProfile,
        toastType: ToastType,
    ): void {
        if (!ToastCommon.isCustomToastType(toastType)) {
            return;
        }

        this.toastContext.changeToCustomToast(toastType);
        this.toastContext.execute(this.store, profile);
    }

    public getProfileForToast$(profileIds: number[]): Observable<IAuthResponse<IProfile[]>> {
        return this.profileService.downloadBulkProfiles(profileIds).pipe(
            tap({
                next: (response: IAuthResponse<IProfile[]>): void => {
                    this.profileService.updateBulkProfiles(response.data);
                },
            }),
        );
    }

    public getTitle(profiles: IProfile[]): string {
        if (profiles.length === 0) {
            return '';
        }

        const lastProfile: IProfile = profiles[profiles.length - 1];

        if (profiles.length === 1) {
            return this.handleLastProfileTitle(lastProfile);
        }

        return this.getProfilesTitle(lastProfile, profiles);
    }

    protected getProfilesTitle(lastProfile: IProfile, profiles: IProfile[]): string {
        const translation: string = this.translateService.instant('modules.main.shared.mp_toast.and_more', {
            numProfiles: profiles.length - 1,
        });

        return `${ lastProfile.username } | ${ translation }`;
    }

    protected handleLastProfileTitle(lastProfile: IProfile): string {
        if (lastProfile === undefined ||
            lastProfile.username === undefined
        ) {
            return '';
        }

        if (lastProfile.age !== undefined) {
            const translation: string = this.translateService.instant('modules.main.shared.mp_toast.username_age', {
                age: lastProfile.age,
            });

            return `${ lastProfile.username } | ${ translation }`;
        }

        return lastProfile.username;
    }

    protected getMessage(profiles: IProfile[]): string {
        return this.translateService.instant(`modules.main.shared.mp_toast.${ this.toastType }`, {
            numProfiles: profiles.length,
        });
    }
}
