<button
    class="user-button referral"
    type="button"
    aria-haspopup="true"
    aria-expanded="true"
    (click)="navigateToReferral()"
>
    <img
        class="user-button--icon"
        src="{{ getReferralButtonIcon() }}"
    >
    <div class="user-button--button-content">
        <div class="title">
            {{ 'modules.main.shared.menu-user-buttons.referral-title' | translate }}
        </div>
        <div class="description">
            <mp-advanced-translate key="modules.main.shared.referral-friendship-day-nagbar.header2">
                <span mp-translate-param="days">{{ THIRTY_DAYS_OF_PREMIUM }}</span>
            </mp-advanced-translate>
        </div>
    </div>
</button>
