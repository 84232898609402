import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { GenericModalComponent } from '@libs/components/generic-modal/generic-modal.component';
import { FormHelpers } from '@libs/modules/registration/form-helpers/form-helpers';
import { UiSelectors } from '@libs/store/new-ui';
import { PasswordActions } from '@libs/store/password';

import { Config } from '@prince/config';
import { NewPasswordFormControlNames } from '@prince/modules/initial/pages/new-password/services/new-password-form/enums/new-password-form-control-names';
import { NewPasswordFormService } from '@prince/modules/initial/pages/new-password/services/new-password-form/new-password-form.service';
import { AdvancedModalService } from '@prince/services/advanced-modal.service';

@Component({
    selector: 'mp-new-password',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './new-password.html',
    styleUrls: ['./new-password.scss'],
})
export class NewPasswordComponent {
    public isSavingNewPassword$: Observable<boolean> = of(false);

    public readonly PASSWORD_FORM_CONTROL_NAME: string = NewPasswordFormControlNames.PASSWORD;
    public readonly PASSWORD_CONFIRMATION_CONTROL_NAME: string = NewPasswordFormControlNames.PASSWORD_CONFIRMATION;

    constructor(
        protected store: Store,
        protected advancedModalService: AdvancedModalService,
        protected newPasswordFormManagerService: NewPasswordFormService,
    ) {
        this.newPasswordFormManagerService.setupNewPasswordForm();
        this.isSavingNewPassword$ = this.store.select(
            UiSelectors.selectIsSavingNewPassword,
        );
    }

    public get newPasswordForm(): FormGroup {
        return this.newPasswordFormManagerService.getNewPasswordForm();
    }

    public get meuPrincipeLogo(): string {
        return `${ Config.imagesFolderBaseUrl }meu-principe-logo.svg`;
    }

    public getButtonModifierClass(
        isSavingNewPassword: boolean,
    ): string {
        if (isSavingNewPassword) {
            return '';
        }

        return 'new-password__content__form__submit-button--full-width';
    }

    public getInputErrorMessage(control: string): string {
        if (this.isInputTouchedAndEmpty(control)) {
            return 'login.input.warning';
        }

        return '';
    }

    protected isInputTouchedAndEmpty(control: string): boolean {
        return FormHelpers.isControlTouchedAndEmpty(
            this.newPasswordForm,
            control,
        );
    }

    public get password(): string {
        return FormHelpers.getFormControlValue(
            this.newPasswordForm,
            NewPasswordFormControlNames.PASSWORD,
        );
    }

    public get passwordConfirmation(): string {
        return FormHelpers.getFormControlValue(
            this.newPasswordForm,
            NewPasswordFormControlNames.PASSWORD_CONFIRMATION,
        );
    }

    public submitForm(): void {
        if (!this.doesPasswordsMatch()) {
            this.advancedModalService.open(
                GenericModalComponent,
                {
                    data: {
                        title: 'modules.initial.pages.new_password.invalid_password_title',
                        message: 'modules.initial.pages.new_password.passwords_must_match_error',
                        buttonTheme: 'rad-red',
                    },
                },
            );

            return;
        }

        if (!this.hasMinimumLength()) {
            this.advancedModalService.open(
                GenericModalComponent,
                {
                    data: {
                        title: 'modules.initial.pages.new_password.invalid_password_title',
                        message: 'modules.main.pages.new_password.password_minlength',
                        buttonTheme: 'rad-red',
                    },
                },
            );

            return;
        }

        this.store.dispatch(
            PasswordActions.updatePassword(),
        );
    }

    protected doesPasswordsMatch(): boolean {
        return this.password === this.passwordConfirmation;
    }

    protected hasMinimumLength(): boolean {
        return this.password.length >= 4;
    }
}
