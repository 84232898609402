<div class="boost-progress">
    <div class="boost-progress__description">
        {{ 'modules.main.shared.boost_progress_content.description' | translate }}
        <ng-container *ngIf="boostTimeRemaining$ | async as boostTimeRemaining">
            <span>{{ getFormattedTimeRemaining(boostTimeRemaining) }}</span>
        </ng-container>
    </div>
    <div class="boost-progress__bar">
        <div class="boost-progress__bar__progress" [style.width]="progressInPercentage$ | async">
            <i class="material-icons">&#xe3e7;</i>
        </div>
    </div>
</div>
