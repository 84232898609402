import { Directive } from '@angular/core';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { UserCommon } from '@libs/shared/user/user.common';

type AgreeModelName = 'agreeCondition1' | 'agreeCondition2' | 'agreeCondition3';

@Directive()
export abstract class RulesModalComponentCommon {
    readonly MODEL_1: AgreeModelName = 'agreeCondition1';
    readonly MODEL_2: AgreeModelName = 'agreeCondition2';
    readonly MODEL_3: AgreeModelName = 'agreeCondition3';

    public agreeCondition1: boolean = false;
    public agreeCondition2: boolean = false;
    public agreeCondition3: boolean = false;

    constructor(
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    public getUserGender(): number {
        return this.auth.get().sex;
    }

    public getDescription(): string {
        let translationKey: string = 'modules.main.shared.rules_modal.subtext';
        if (this.isBaby()) {
            translationKey += '_baby';
        }

        return translationKey;
    }

    public isBaby(): boolean {
        return UserCommon.isBaby(this.auth.get());
    }

    public toggle(modelName: AgreeModelName): void {
        this[modelName] = !this[modelName];
    }

    public canShowRequiredSign(model: boolean): boolean {
        return !model;
    }

    public canAgree(): boolean {
        return this.agreeCondition1 && this.agreeCondition2 && (this.isBaby() || this.agreeCondition3);
    }

    public abstract agree(): void;

    public abstract decline(): void;
}
