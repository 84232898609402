import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    AllCashServiceCommon,
} from '@libs/modules/main/services/payment/allcash/allcash.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IConfigurationParameters } from '@libs/shared/interfaces/configuration-parameters.interface';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { AnalyticsService } from '@prince/services/analytics/analytics.service';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ProfileService } from '@prince/services/profile.service';
import { UserService } from '@prince/services/user.service';

@Injectable()
export class AllCashService extends AllCashServiceCommon {
    constructor(
        protected auth: AuthenticationService,
        protected authHttp: AuthHttpService,
        protected store: Store<IApplicationState>,
        protected http: HttpClient,
        protected analytics: AnalyticsService,
        protected userService: UserService,
        protected profileService: ProfileService,
        protected router: Router,
    ) {
        super(
            auth,
            authHttp,
            store,
            analytics,
            userService,
            profileService,
            router,
        );
    }

    getConfig(): IConfigurationParameters {
        return Config;
    }

    public checkout(
        price: number,
        interest: number,
        membershipId: MembershipType,
    ): Observable<IAuthResponse> {
        const endpoint: string = `${Config.serverIp}payment/checkout?membership_id=${membershipId}&price=${price}&interest=${interest}`;

        return this.authHttp.get(endpoint)
            .pipe(catchError(this.throwErrors));
    }

    public removePaymentQueryParams(): void {
        this.router.navigate([], {
            replaceUrl: true,
            queryParams: {
                payment: undefined,
            },
            queryParamsHandling: 'merge',
        });
    }
}
