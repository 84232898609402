import { Action, createReducer, on } from '@ngrx/store';

import { IAvailableTrial } from '@libs/shared/trial/interface';
import * as TrialActions from '@libs/store/trial/actions';
import { ITrialState } from '@libs/store/trial/interfaces/trial-state.interface';
import { TrialAdapter, TrialInitialState } from '@libs/store/trial/state';
import { TrialVersion } from '@libs/store/trial/types/trial-version.type';

const trialReducer = createReducer(
    TrialInitialState,
    on(
        TrialActions.setGlobalTrial, (
            state: ITrialState,
            { trial }: { trial: IAvailableTrial },
        ): ITrialState => {
            return {
                ...state,
                globalTrial: trial,
            };
        },
    ),
    on(
        TrialActions.updateTrials, (
            state: ITrialState,
            { trials }: { trials: IAvailableTrial[] },
        ): ITrialState => {
            return TrialAdapter.upsertMany(
                trials,
                state,
            );
        },
    ),
    on(
        TrialActions.setGameTrial, (
            state: ITrialState,
            { period }: { period: number },
        ): ITrialState => {
            return {
                ...state,
                gameTrialIndex: period,
            };
        },
    ),
    on(
        TrialActions.setLinkTrial, (
            state: ITrialState,
            { period }: { period: number },
        ): ITrialState => {
            return {
                ...state,
                linkTrialIndex: period,
            };
        },
    ),
    on(
        TrialActions.setTrialABVersion, (
            state: ITrialState,
            { trialVersion }: { trialVersion: TrialVersion },
        ): ITrialState => {
            return {
                ...state,
                trialVersion: trialVersion,
            };
        },
    ),
);

export function reducer(state: ITrialState, action: Action): ITrialState {
    return trialReducer(state, action);
}
