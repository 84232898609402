import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ProductServiceCommon } from '@libs/modules/main/services/product/product.service.common';
import { IApplicationState } from '@libs/store/application-state';

import { AuthenticationService } from '@prince/services/authentication.service';
import { ModalService } from '@prince/services/modal.service';

@Injectable({
    providedIn: 'root',
})
export class ProductService extends ProductServiceCommon {
    constructor(
        protected auth: AuthenticationService,
        protected store: Store<IApplicationState>,
        protected modalService: ModalService,
        protected translate: TranslateService,
    ) {
        super(store, auth);
    }
}
