import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import {
    EMPTY,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    concatMap,
    delay,
    filter,
    map,
    pluck,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import { IPaymentCheckInfoData } from '@libs/effects/payment-info/interfaces/payment-check-info-data.interface';
import { BoostProductServiceCommon } from '@libs/modules/boost/services/boost-product/boost-product.service.common';
import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { IGetPaymentHistoryResponse } from '@libs/modules/main/services/payment/interfaces/get-payment-history-response.interface';
import {
    PaymentInfoServiceCommon,
} from '@libs/modules/main/services/payment/payment-info.service.common';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { ProductServiceCommon } from '@libs/modules/main/services/product/product.service.common';
import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { AnalyticsServiceCommon } from '@libs/services/analytics/analytics.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { onPaymentSuccess } from '@libs/store/analytics/actions';
import { IApplicationState } from '@libs/store/application-state';
import { BoostActions, BoostSelectors } from '@libs/store/boost';
import { BoostPackagesActions } from '@libs/store/boost-packages';
import { BoostProductActions, BoostProductSelectors } from '@libs/store/boost-product';
import { ConversationActions } from '@libs/store/conversations';
import { MembershipActions } from '@libs/store/membership';
import { MessageActions } from '@libs/store/messages';
import { PaymentActions } from '@libs/store/payment';
import {
    IPaymentInfo,
    PaymentInfoActions,
    PaymentInfoSelectors,
} from '@libs/store/payment-info';
import { IPaymentProvider } from '@libs/store/payment/interfaces/payment-provider';
import { ProductActions } from '@libs/store/product';
import { ProductNames } from '@libs/store/product/interface';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';

export abstract class PaymentInfoEffectsCommon {
    fetchPaymentHistory$: Observable<Action> = createEffect((): Observable<Action> => this.actions$
        .pipe(
            ofType(PaymentInfoActions.fetchPaymentHistory),
            withLatestFrom(
                this.store.pipe(
                    select(PaymentInfoSelectors.selectIsPaying),
                ),
            ),
            filter(([_, isPaying]): boolean => !isPaying),
            concatMap((): Observable<Action> => {
                return this.paymentInfo.getPaymentHistory().pipe(
                    map((response: IAuthResponse): Action => {
                        return PaymentInfoActions.upsertPaymentInfo({
                            paymentInfo: response.data.payments,
                        });
                    }),
                    catchError((): Observable<Action> => EMPTY),
                );
            }),
        ), { dispatch: true, useEffectsErrorHandler: false });

    loadPaymentInfo$: Observable<Action> = createEffect((): Observable<Action> => this.actions$
        .pipe(
            ofType(PaymentInfoActions.loadPaymentInfo),
            concatMap((): Observable<Action> => {
                return this.paymentInfo.getPaymentHistory().pipe(
                    withLatestFrom(this.store.pipe(
                        select(PaymentInfoSelectors.selectAll),
                    )),
                    map(([
                        response,
                        paymentInfo,
                    ]: [IAuthResponse<IGetPaymentHistoryResponse>, IPaymentInfo[]]): Action => {
                        return PaymentInfoActions.checkPaymentUpdatedStatus({
                            currentPaymentInfo: paymentInfo,
                            updatedPaymentInfo: response.data.payments,
                        });
                    }),
                    catchError((): Observable<Action> => EMPTY),
                );
            }),
        ), { dispatch: true, useEffectsErrorHandler: false });

    checkPayment$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PaymentInfoActions.checkPaymentUpdatedStatus),
            concatMap((action: IPaymentCheckInfoData): Observable<[Action, PaymentStatus]> => {
                return of(action).pipe(
                    withLatestFrom(this.store.pipe(
                        select(PaymentInfoSelectors.selectPaymentStatus),
                    )),
                );
            }),
            tap(([paymentInfo, _]: [IPaymentCheckInfoData, PaymentStatus]): void => {
                this.store.dispatch(PaymentInfoActions.upsertPaymentInfo({
                    paymentInfo: paymentInfo.updatedPaymentInfo,
                }));
            }),
            concatMap(([paymentInfo, paymentStatus]: [IPaymentCheckInfoData, PaymentStatus]): Observable<Action | never> => {
                const latestStoredPaymentInfo: IPaymentInfo = this.paymentInfo.getLatestPaymentInfo(
                    paymentInfo.currentPaymentInfo,
                );
                const updatedPaymentInfo: IPaymentInfo = this.paymentInfo.getLatestPaymentInfo(
                    paymentInfo.updatedPaymentInfo,
                );

                if (this.paymentInfo.shouldKeepPolling(
                    paymentStatus,
                    latestStoredPaymentInfo,
                    updatedPaymentInfo,
                )) {
                    return of(PaymentInfoActions.loadPaymentInfo()).pipe(
                        delay(
                            this.paymentInfo.MILLISECONDS_DELAY_TO_CHECK_PAYMENT_HISTORY,
                        ),
                    );
                }

                if (this.paymentInfo.canHandleUpdatedPaymentResponse(
                    paymentStatus,
                    latestStoredPaymentInfo,
                    updatedPaymentInfo,
                )) {
                    return of(
                        PaymentInfoActions.handleUpdatedResponse({
                            updatedPaymentInfo,
                        }),
                    );
                }

                return EMPTY;
            }),
        ), { dispatch: true, useEffectsErrorHandler: true },
    );

    handleUpdatedResponse$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PaymentInfoActions.handleUpdatedResponse),
            withLatestFrom(
                this.store.pipe(select(BoostProductSelectors.selectProductUuid)),
            ),
            concatMap(([{ updatedPaymentInfo }, boostProductUuid]): Observable<Action> => {
                return this.handleUpdatedPaymentResponse$(updatedPaymentInfo, boostProductUuid);
            }),
        ), { dispatch: true, useEffectsErrorHandler: false },
    );

    handleIsPaying$: Observable<boolean> = createEffect((): Observable<boolean> => this.actions$
        .pipe(
            ofType(PaymentInfoActions.setIsPaying),
            pluck<Action, boolean>('isPaying'),
            tap((isPaying: boolean): void => {
                this.handleIsCurrentlyPaying(isPaying);
            }),
        ), { dispatch: false, useEffectsErrorHandler: true });

    onSuccessfullyPaymentDone$: Observable<Action> = createEffect((): Observable<Action> => this.actions$
        .pipe(
            ofType(PaymentInfoActions.setPaymentStatus),
            pluck<Action, PaymentStatus>('paymentStatus'),
            filter((paymentStatus: PaymentStatus): boolean =>
                this.paymentInfo.isPaymentOk(paymentStatus),
            ),
            map((): Action => {
                this.hideLoader();

                this.store.dispatch(ConversationActions.cleanConversations());
                this.store.dispatch(MessageActions.cleanMessages());

                return MembershipActions.latestPaidMembershipChange();
            }),
        ), { dispatch: true, useEffectsErrorHandler: true });

    handlePrizeProcessingMessage$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PaymentInfoActions.setPaymentStatus),
            map(({ paymentStatus }): PaymentStatus => paymentStatus),
            filter(
                (paymentStatus: PaymentStatus): boolean =>
                    this.paymentInfo.isPaymentOk(paymentStatus),
            ),
            withLatestFrom(
                this.store.pipe(
                    select(PaymentInfoSelectors.selectAll),
                ),
                this.store.pipe(
                    select(BoostProductSelectors.selectProductUuid),
                ),
            ),
            concatMap(([
                _,
                paymentInfoArray,
                boostProductUuid,
            ]: [PaymentStatus, IPaymentInfo[], string]): Observable<Action> => {
                const latestPayment: IPaymentInfo = PaymentCommon.getLatestPayment(
                    paymentInfoArray,
                );

                if (this.boostProductService.isBoostPackagePayment(
                    latestPayment,
                    boostProductUuid,
                )) {
                    return EMPTY;
                }

                return of(
                    PaymentInfoActions.updateUserAndLoadBoostPackages({
                        latestPayment,
                    }),
                );
            }),
        ),
        { dispatch: true, useEffectsErrorHandler: true },
    );

    updateUserAndLoadBoostPackages$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PaymentInfoActions.updateUserAndLoadBoostPackages),
            concatMap((action): Observable<Action> => {
                return this.profileService.getSelf$().pipe(
                    exponentialBackoff(),
                    concatMap((user: UserCommon): Observable<Action> => {
                        if (MembershipCommon.isFree(user.membership_type_id)) {
                            return of(
                                action,
                            ).pipe(
                                delay(2000),
                            );
                        }

                        if (PaymentCommon.isPaymentForAPlusPackage(action.latestPayment)) {
                            this.store.dispatch(
                                BoostActions.setIsProcessingBoostPrize({
                                    isProcessing: true,
                                }),
                            );
                            this.store.dispatch(
                                BoostActions.checkPendingCredits(),
                            );
                        }

                        return of(
                            BoostPackagesActions.loadBoostPackages(),
                        );
                    }),
                );
            }),
        ),
        { dispatch: true, useEffectsErrorHandler: false },
    );

    resetIsProcessingBoostPrizeOnLogin$: Observable<Action> = createEffect(
        (): Observable<Action> => this.authenticationService.onLogin$.pipe(
            withLatestFrom(
                this.store.pipe(
                    select(BoostSelectors.selectHasBalance),
                ),
            ),
            filter(([_, hasBalance]: [never, boolean]): boolean => hasBalance),
            map((): Action => {
                return BoostActions.setIsProcessingBoostPrize({
                    isProcessing: false,
                });
            }),
        ),
        { dispatch: true, useEffectsErrorHandler: true },
    );

    resetIsPayingAfterDelay$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PaymentInfoActions.resetIsPayingAfterDelay),
            delay(2500),
            map((): Action => {
                return PaymentInfoActions.setIsPaying({
                    isPaying: false,
                });
            }),
        ),
        { dispatch: true, useEffectsErrorHandler: true },
    );

    navigateAfterPagSeguroPayment$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(PaymentInfoActions.navigateAfterPayment),
            concatMap(
                (): Observable<Action> => {
                    this.navigateAfterPayment();

                    return EMPTY;
                },
            ),
        ),
        { dispatch: true, useEffectsErrorHandler: true },
    );

    loadPaymentProvider$ = createEffect(
        () => this.actions$.pipe(
            ofType(PaymentInfoActions.loadPaymentProvider),
            concatMap(() => this.paymentInfo.loadPaymentProvider().pipe(
                exponentialBackoff(),
                tap({
                    next: (response: IAuthResponse<IPaymentProvider>) => {
                        if (
                            response.data !== undefined &&
                            response.data.provider !== '' &&
                            response.data.payment_types !== undefined
                        ) {
                            this.paymentInfo.setProvider(
                                response.data.provider ?? PaymentCommon.PROVIDER_PAGSEGURO,
                            );
                            this.paymentInfo.setPaymentTypesAvailable(
                                response.data.payment_types ?? [
                                    PaymentMethods.PAYMENT_CREDIT_CARD,
                                    PaymentMethods.PAYMENT_BOLETO,
                                ],
                            );

                            return;
                        }

                        this.paymentInfo.setDefaultPaymentConfiguration();
                    },
                }),
                catchError(() => {
                    this.paymentInfo.setDefaultPaymentConfiguration();

                    return EMPTY;
                }),
            )),

        ),
        { dispatch: false },
    );

    constructor(
        protected actions$: Actions,
        protected paymentInfo: PaymentInfoServiceCommon,
        protected store: Store<IApplicationState>,
        protected productService: ProductServiceCommon,
        protected profileService: ProfileServiceCommon,
        protected authenticationService: AuthenticationServiceCommon,
        protected router: Router,
        protected analyticsService: AnalyticsServiceCommon,
        protected boostProductService: BoostProductServiceCommon,
    ) {
        //
    }

    protected get user(): UserCommon {
        return this.authenticationService.get();
    }

    protected abstract handleIsCurrentlyPaying(isPaying: boolean): void;

    protected abstract hideLoader(): void;

    protected abstract triggerOlarkSupport(): void;

    protected handleUpdatedPaymentResponse$(
        updatedPaymentInfo: IPaymentInfo,
        boostProductUuid: string,
    ): Observable<Action | never> {
        this.store.dispatch(PaymentInfoActions.setIsPaying({
            isPaying: false,
        }));

        if (this.paymentInfo.isPaymentInfoStatusFailed(updatedPaymentInfo.status)) {
            return this.handleFailedPayment(updatedPaymentInfo);
        }

        if (this.paymentInfo.isPaymentInfoStatusApproval(
            updatedPaymentInfo.status,
        )) {
            return this.handleApprovedPayment(
                updatedPaymentInfo,
                boostProductUuid,
            );
        }

        return EMPTY;
    }

    protected handleFailedPayment(
        updatedPaymentInfo: IPaymentInfo,
    ): Observable<Action> {
        this.store.dispatch(
            BoostProductActions.checkIfIsBoostPayment({ updatedPaymentInfo }),
        );
        this.store.dispatch(PaymentInfoActions.setPaymentStatus({
            paymentStatus: PaymentStatus.PAYMENT_ERROR,
        }));
        this.triggerOlarkSupport();
        this.dispatchClearTokenFromMemory();

        return of(PaymentInfoActions.handlePaymentErrorStatus({
            price: updatedPaymentInfo.subtotal_amount,
        }));
    }

    protected handleApprovedPayment(
        updatedPaymentInfo: IPaymentInfo,
        boostProductUuid: string,
    ): Observable<Action> {
        this.store.dispatch(
            BoostProductActions.checkIfIsBoostPayment({ updatedPaymentInfo }),
        );
        this.store.dispatch(onPaymentSuccess({
            paymentInfo: updatedPaymentInfo,
        }));
        this.dispatchSendToken();

        if (!this.boostProductService.isBoostPackagePayment(updatedPaymentInfo, boostProductUuid)) {
            this.store.dispatch(
                PaymentActions.fetchPaymentOptions(),
            );
            this.profileService.updateSelf();
            this.store.dispatch(
                PaymentInfoActions.navigateAfterPayment(),
            );
        }

        if (this.productService.isExpressApprovalPrice(updatedPaymentInfo.subtotal_amount)) {
            return of(ProductActions.setProductAvailability({
                available: false,
                productName: ProductNames.ExpressApproval,
            }));
        }

        return of(PaymentInfoActions.setPaymentStatus({
            paymentStatus: PaymentStatus.PAYMENT_OK,
        }));
    }

    protected navigateAfterPayment(): void {
        if (!this.isExpressApproval()) {
            this.router.navigate(['main', 'home'], { replaceUrl: true });

            return;
        }

        if (this.router.url.includes('/register')) {
            this.router.navigate(
                ['register','waiting-list'],
                { replaceUrl: true },
            );

            return;
        }

        this.store.dispatch(
            PaymentInfoActions.setPaymentStatus({
                paymentStatus: PaymentStatus.PAYMENT_NONE,
            }),
        );
        this.router.navigate(['express-approval'], { replaceUrl: true });
    }

    protected isExpressApproval(): boolean {
        return this.router.url.includes('express-approval');
    }

    protected startCheckingBoostInfoAfterMembershipPayment(): void {
        this.store.dispatch(
            BoostActions.checkPendingCredits(),
        );
        this.store.dispatch(
            BoostActions.startPackagesLoadedCheckCycle(),
        );
    }

    protected abstract dispatchSendToken(): void;

    protected abstract dispatchClearTokenFromMemory(): void;
}
