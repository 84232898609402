import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { FormHelpers } from '@libs/modules/registration/form-helpers/form-helpers';
import { Registration } from '@libs/registration-prince/domain/registration';
import { SomeGender } from '@libs/registration-prince/domain/types/genders.type';
import { SomeSexualPreference } from '@libs/registration-prince/domain/types/sexual-preferences.type';

import { Config } from '@prince/config';
import { RegistrationUtils } from '@prince/modules/registration/registration-utils/registration-utils';

export interface IGenderIconStyle {
    monochrome: string;
    gradient: string;
}

@Component({
    selector: 'mp-user-gender-select',
    templateUrl: './user-gender-selector.html',
    styleUrls: ['./user-gender-selector.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGenderSelectorComponent {
    @ViewChild('princeButton', { static: true }) princeButton: ElementRef;
    @ViewChild('princessButton', { static: true }) princessButton: ElementRef<HTMLDivElement>;

    @Input() id: string = '';
    @Input() controlName: string = '';
    @Input() form: FormGroup = new FormGroup({ });
    @Output() genderSelected: EventEmitter<SomeGender> = new EventEmitter();

    public readonly princess: SomeGender = Registration.GENDER_FEMALE;
    public readonly prince: SomeGender = Registration.GENDER_MALE;
    public userGender$: Observable<number> = of(0);

    constructor(
        protected translate: TranslateService,
        protected store: Store,
    ) {
        //
    }

    public getUserGender(): SomeGender {
        return FormHelpers.getFormControlValue<SomeGender>(
            this.form,
            this.controlName,
        );
    }

    public isFemale(): boolean {
        return this.getUserGender() === Registration.GENDER_FEMALE;
    }

    public isMale(): boolean {
        return this.getUserGender() === Registration.GENDER_MALE;
    }

    public getSelectedGenderModifierClass(gender: SomeGender): string {
        if (gender !== this.getUserGender()) {
            return '';
        }

        return {
            [Registration.GENDER_MALE]: 'user-gender-selector__container--prince',
            [Registration.GENDER_FEMALE]: 'user-gender-selector__container--princess',
        }[this.getUserGender()];
    }

    public getUserGenderIcon(gender: SomeGender): string {
        if (
            this.getUserGender() === 0 ||
            gender !== this.getUserGender()
        ) {
            return this.getIconPath(gender).monochrome;
        }

        return this.getIconPath(gender).gradient;
    }

    protected getIconPath(gender: SomeGender): IGenderIconStyle {
        return {
            [Registration.GENDER_MALE]: {
                monochrome: `${ Config.imagesFolderBaseUrl }prince-crown-monochrome.svg`,
                gradient: `${ Config.imagesFolderBaseUrl }prince-crown.svg`,
            },
            [Registration.GENDER_FEMALE]: {
                monochrome: `${ Config.imagesFolderBaseUrl }princess-crown-monochrome.svg`,
                gradient: `${ Config.imagesFolderBaseUrl }princess-crown.svg`,
            },
        }[gender];
    }

    public handleGenderSelected(gender: SomeGender): void {
        this.genderSelected.emit(gender);
        this.setGenderFormControlValue(gender);
    }

    public setGenderFormControlValue(gender: SomeGender): void {
        FormHelpers.setFormControlValue<SomeGender>(
            this.form,
            this.controlName,
            gender,
        );
    }

    public getGenderOptions(): SomeGender[] {
        return RegistrationUtils.getGenderOptions();
    }

    public trackBySexualPreference(
        _: number,
        sexualPreference: SomeSexualPreference,
    ): SomeSexualPreference {
        return sexualPreference;
    }

    public getGenderText(gender: SomeGender): string {
        return RegistrationUtils.getGenderTranslationKey(gender);
    }

    public getGenderLabelTranslation(gender: SomeGender): string {
        return this.translate.instant(
            'modules.registration.onboarding.gender_label',
            {
                gender,
            },
        );
    }
}
