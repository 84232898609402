import {
    FavMeTTLUpdatedAction,
    FAV_ME_TTL_UPDATED_ACTION,
} from '@libs/store/profiles/actions/fav-me.action';

export function storeFavMeTTL(state: number = Date.now(), action: FavMeTTLUpdatedAction): number {
    switch (action.type) {
        case FAV_ME_TTL_UPDATED_ACTION:
            return updateTTL(state, action.payload.ttl);
        default:
            return state;
    }
}

function updateTTL(_: number, newTTL: number): number {
    return newTTL;
}
