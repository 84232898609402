import { Action } from '@ngrx/store';

export const TOKEN_RECEIVED_ACTION: string = 'TOKEN_RECEIVED_ACTION';

export interface ITokenReceivedActionPayload {
    token: string;
}

export class TokenReceivedAction implements Action {
    readonly type: string = TOKEN_RECEIVED_ACTION;

    constructor(public payload: ITokenReceivedActionPayload) {
        //
    }
}
