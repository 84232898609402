import { Directive, Input } from '@angular/core';

@Directive()
export abstract class ActionbarCallToActionComponentCommon {
    @Input() action: (() => void) | undefined;
    @Input() label: string | undefined;
    @Input() outline: boolean = false;
    @Input() borderLess: boolean = false;
    @Input() borderLessWithNormalWeight: boolean = false;
    @Input() disabled: boolean = false;
    @Input() noMargin: boolean = false;

    get isDisabled(): boolean {
        return this.disabled;
    }

    showCssClasses(): string[] {
        const classes: string[] = [];

        if (this.outline) {
            classes.push('outline-white');
        }

        if (this.borderLess) {
            classes.push('border-less');
        }

        if (this.noMargin) {
            classes.push('no-margin');
        }

        if (this.borderLessWithNormalWeight) {
            classes.push('white-borderless-normal');
        }

        return classes;
    }
}
