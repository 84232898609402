import { Directive, Input } from '@angular/core';

import {
    IThreeDotsExtraStyles,
    IThreeDotsMenuItem,
} from '@libs/shared/interfaces/three-dots-menu-item.interface';

@Directive()
export abstract class ActionbarThreeDotsComponentCommon {
    public menuHidden: boolean = true;

    protected readonly ALERT_CSS_CLASS: string = 'dropdown-menu--alert';
    protected readonly STRONG_TEXT_CSS_CLASS: string = 'dropdown-menu--strong';

    @Input() options: IThreeDotsMenuItem[] = [];

    hasIcon(option: IThreeDotsMenuItem): boolean {
        return option.icon !== undefined;
    }

    getExtraTextStyleClasses(option: IThreeDotsMenuItem): IThreeDotsExtraStyles {
        return {
            [this.ALERT_CSS_CLASS]: option.hasTextAlertClass ?? false,
            [this.STRONG_TEXT_CSS_CLASS]: option.hasStrongText ?? false,
        };
    }

    getExtraIconStyleClasses(option: IThreeDotsExtraStyles): IThreeDotsExtraStyles {
        return {
            [this.ALERT_CSS_CLASS]: option.hasIconAlertClass,
        };
    }
}
