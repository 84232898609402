import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MaintenanceComponentCommon } from '@libs/modules/main/pages/maintenance/maintenance.component.common';

@Component({
    selector: 'mp-maintenance',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './maintenance.html',
})
export class MaintenanceComponent implements MaintenanceComponentCommon {
    //
}
