import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IBirthdateFields } from '@libs/modules/registration/domain/interfaces/birthdate-fields.interface';
import { INewRegistrationPayload } from '@libs/modules/registration/domain/interfaces/new-registration-payload.interface';
import { IRegistrationAppearance } from '@libs/modules/registration/domain/interfaces/registration-appearance.interface';
import { IRegistrationInitialStep } from '@libs/modules/registration/domain/interfaces/registration-initial-step.interface';
import { IRegistrationLastDetails } from '@libs/modules/registration/domain/interfaces/registration-last-details.interface';
import { IRegistrationLifeInfo } from '@libs/modules/registration/domain/interfaces/registration-life-info.interface';
import { IRegistrationPersonalInfo } from '@libs/modules/registration/domain/interfaces/registration-personal-info.interface';
import { SomeSugarRole } from '@libs/modules/registration/domain/types/sugar-roles.type';

const selectFeature = createFeatureSelector<INewRegistrationPayload>('registrationNew');

export const selectInitialStep = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationInitialStep => {
        return state.initialStep;
    },
);

export const selectSugarRole = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): SomeSugarRole => {
        return state.initialStep.sugarRole;
    },
);

export const selectPersonalInfo = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationPersonalInfo => {
        return state.personalInfo;
    },
);

export const selectIsCroppingImage = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): boolean | undefined => {
        return state.isCroppingImage;
    },
);

export const selectAppearance = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationAppearance => {
        return state.appearance;
    },
);

export const selectLifeInfo = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationLifeInfo => {
        return state.lifeInfo;
    },
);

export const selectLastDetails = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IRegistrationLastDetails => {
        return state.lastDetails;
    },
);

export const selectIfTriedDirectAccessToModalRoute = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): boolean => {
        return state.hasAccessedModalRoute;
    },
);

export const selectIsSubmittingForm = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): boolean => {
        return state.isSubmittingForm;
    },
);

export const selectBirthdate = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): IBirthdateFields => {
        return state.personalInfo.birthdate;
    },
);

export const selectEmail = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): string => {
        return state.personalInfo.email;
    },
);

export const selectSubmittingFormText = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): string => {
        return state.submittingFormText;
    },
);

export const selectAll = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): INewRegistrationPayload => {
        return state;
    },
);

export const selectHasSubmittedForm = createSelector(
    selectFeature,
    (state: INewRegistrationPayload): boolean => {
        return state.hasSubmittedForm;
    },
);
