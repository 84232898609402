import { Directive } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { BoostLastViewersServiceCommon } from '@libs/modules/boost/services/boost-last-viewers/boost-last-viewers.service.common';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IProfile } from '@libs/shared/profile/profile';
import { IApplicationState } from '@libs/store/application-state';
import { BoostLastViewersSelectors } from '@libs/store/boost-last-viewers';

@Directive()
export abstract class BoostBubblesComponentCommon {
    public profiles$: Observable<IProfile[]> = of([]);

    constructor(
        protected authenticationService: AuthenticationServiceCommon,
        protected boostLastViewersService: BoostLastViewersServiceCommon,
        protected store: Store<IApplicationState>,
    ) {
        this.getProfilesForBubbles();
    }

    abstract getProfilePicture(profile: IProfile): string;

    getProfilesForBubbles(): void {
        this.profiles$ = this.store.pipe(
            select(BoostLastViewersSelectors.selectProfilesForBubbles, {
                boostLastViewersService: this.boostLastViewersService,
            }),
        );
    }

    trackByProfileId(_: number, profile: IProfile): number {
        return profile.profile_id;
    }
}
