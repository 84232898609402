import { Action, createReducer, on } from '@ngrx/store';

import { IModalViewProps } from '@libs/components/modal-view/interface/modal-view-props';
import * as ModalViewActions from '@libs/store/modal-view/actions';
import { modalViewPropsInitialState } from '@libs/store/modal-view/state';

const ModalViewReducer = createReducer(modalViewPropsInitialState,
    on(
        ModalViewActions.setModalOpened, (
            state: IModalViewProps,
            { isModalOpened }: { isModalOpened: boolean },
        ): IModalViewProps => {
            return { ...state, isModalOpened };
        },
    ),
);

export function reducer(state: IModalViewProps, action: Action): IModalViewProps {
    return ModalViewReducer(state, action);
}
