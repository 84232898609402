import {
    NearByTTLUpdatedAction,
    NEARBY_TTL_UPDATED_ACTION,
} from '@libs/store/profiles/actions/nearby.action';

export function storeNearByTTL(state: number = Date.now(), action: NearByTTLUpdatedAction): number {
    switch (action.type) {
        case NEARBY_TTL_UPDATED_ACTION:
            return updateTTL(state, action.payload.ttl);
        default:
            return state;
    }
}

function updateTTL(_state: number, newTTL: number): number {
    return newTTL;
}
