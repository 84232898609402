import {
    NOTIFICATION_VERIFY_PHOTO_CLEAN_ACTION,
} from '@libs/store/notifications/actions/notification-verify-photo-clean.action';
import {
    NotificationCreatePhotoTimerAction,
    NOTIFICATION_CREATE_PHOTO_TIMER_ACTION,
} from '@libs/store/notifications/actions/notification-verify-photo-timer.action';
import {
    NOTIFICATION_VERIFY_PHOTO_ACTION,
} from '@libs/store/notifications/actions/notification-verify-photo.action';

export interface IVerifyPhotoTTLStatus {
    lastTimeSent: number;
    sending: boolean;
}

export const INITIAL_VERIFY_PHOTO_STATUS = {
    lastTimeSent: 0,
    sending: false,
};

export function storeVerifyPhotoTTL(
    state: IVerifyPhotoTTLStatus = INITIAL_VERIFY_PHOTO_STATUS,
    action: NotificationCreatePhotoTimerAction,
): IVerifyPhotoTTLStatus {
    switch (action.type) {
        case NOTIFICATION_CREATE_PHOTO_TIMER_ACTION:
            state.lastTimeSent = Date.now();
            state.sending = true;
            return state;
        case NOTIFICATION_VERIFY_PHOTO_ACTION:
            state.lastTimeSent = Date.now();
            state.sending = false;
            return state;
        case NOTIFICATION_VERIFY_PHOTO_CLEAN_ACTION:
            state.lastTimeSent = 0;
            state.sending = false;
            return state;
        default:
            return state;
    }
}
