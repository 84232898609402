<div class="advanced-modal-ui">
    <div class="advanced-modal-ui__header">
        <ng-content select="[header]"></ng-content>
    </div>
    <div class="advanced-modal-ui__content">
        <ng-content select="[description]"></ng-content>
    </div>
    <div class="advanced-modal-ui__footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
