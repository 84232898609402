import {
    Action,
    createReducer,
    on,
} from '@ngrx/store';

import { INewRegistrationPayload } from '@libs/modules/registration/domain/interfaces/new-registration-payload.interface';
import { IRegistrationAppearance } from '@libs/modules/registration/domain/interfaces/registration-appearance.interface';
import { IRegistrationInitialStep } from '@libs/modules/registration/domain/interfaces/registration-initial-step.interface';
import { IRegistrationLastDetails } from '@libs/modules/registration/domain/interfaces/registration-last-details.interface';
import { IRegistrationLifeInfo } from '@libs/modules/registration/domain/interfaces/registration-life-info.interface';
import { IRegistrationPersonalInfo } from '@libs/modules/registration/domain/interfaces/registration-personal-info.interface';
import {
    saveRegistrationInitialStep,
    savePersonalnfo,
    saveLastDetails,
    saveAppearance,
    saveLifeInfo,
    setIsSubmittingForm,
    setIfTriedToAccessModalRoute,
    setIsCroppingImage,
    clearPassword,
    resetToInitialState,
    changeSubmittingFormText,
    setHasSubmittedForm,
} from '@libs/store/registration/actions';
import { registrationInitialState } from '@libs/store/registration/state';

const registrationReducer = createReducer(
    registrationInitialState,
    on(saveRegistrationInitialStep, (
        state: INewRegistrationPayload,
        { initialStep }: { initialStep: IRegistrationInitialStep },
    ): INewRegistrationPayload => {
        return {
            ...state,
            initialStep,
        };
    }),
    on(savePersonalnfo, (
        state: INewRegistrationPayload,
        { personalInfo }: { personalInfo: IRegistrationPersonalInfo },
    ): INewRegistrationPayload => {
        return {
            ...state,
            personalInfo,
        };
    }),
    on(setIsCroppingImage, (
        state: INewRegistrationPayload,
        { isCroppingImage }: { isCroppingImage: boolean },
    ): INewRegistrationPayload => {
        return {
            ...state,
            isCroppingImage,
        };
    }),
    on(saveAppearance, (
        state: INewRegistrationPayload,
        { appearance }: { appearance: IRegistrationAppearance },
    ): INewRegistrationPayload => {
        return {
            ...state,
            appearance,
        };
    }),
    on(saveLastDetails, (
        state: INewRegistrationPayload,
        { lastDetails }: { lastDetails: IRegistrationLastDetails },
    ): INewRegistrationPayload => {
        return {
            ...state,
            lastDetails,
        };
    }),
    on(saveLifeInfo, (
        state: INewRegistrationPayload,
        { lifeInfo }: { lifeInfo: IRegistrationLifeInfo },
    ): INewRegistrationPayload => {
        return {
            ...state,
            lifeInfo,
        };
    }),
    on(setIsSubmittingForm, (
        state: INewRegistrationPayload,
        { isSubmittingForm }: { isSubmittingForm: boolean },
    ): INewRegistrationPayload => {
        return {
            ...state,
            isSubmittingForm,
        };
    }),
    on(setIfTriedToAccessModalRoute, (
        state: INewRegistrationPayload,
        { hasAccessedModalRoute }: { hasAccessedModalRoute: boolean },
    ): INewRegistrationPayload => {
        return {
            ...state,
            hasAccessedModalRoute,
        };
    }),
    on(clearPassword, (
        state: INewRegistrationPayload,
    ): INewRegistrationPayload => {
        return {
            ...state,
            personalInfo: {
                ...state.personalInfo,
                password: '',
            },
        };
    }),
    on(changeSubmittingFormText, (
        state: INewRegistrationPayload,
        { translationKey }: { translationKey: string },
    ): INewRegistrationPayload => {
        return {
            ...state,
            submittingFormText: translationKey,
        };
    }),
    on(setHasSubmittedForm, (
        state: INewRegistrationPayload,
        { hasSubmittedForm }: { hasSubmittedForm: boolean },
    ): INewRegistrationPayload => {
        return {
            ...state,
            hasSubmittedForm,
        };
    }),
    on(resetToInitialState, (): INewRegistrationPayload => {
        return {
            ...registrationInitialState,
        };
    }),
);

export function reducer(
    state: INewRegistrationPayload,
    action: Action,
): INewRegistrationPayload {
    return registrationReducer(state, action);
}
