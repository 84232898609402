import { AnimationEvent } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
    SlidableMenuCommon,
} from '@libs/modules/main/shared/slidable-menu/slidable-menu.common';
import {
    SlidableMenuComponentCommon,
} from '@libs/modules/main/shared/slidable-menu/slidable-menu.component.common';
import { KeyboardCommon } from '@libs/shared/keyboard/keyboard.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@prince/config';
import {
    SlidableMenuAnimations,
} from '@prince/modules/main/shared/slidable-menu/slidable-menu.animations';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ModalService } from '@prince/services/modal.service';
import { SlidableMenuService } from '@prince/services/slidable-menu/slidable-menu.service';

@Component({
    selector: 'mp-slidable-menu',
    templateUrl: './slidable-menu.html',
    animations: [
        SlidableMenuAnimations.slideToggle,
    ],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SlidableMenuComponent extends SlidableMenuComponentCommon implements OnInit, OnDestroy {
    protected readonly URL_FAQ: string = Config.supportLink;
    protected readonly CONFIRM_LOGOUT: string = 'common.confirm_logout';

    @ViewChild('subscribedStatusContainer', { read: ViewContainerRef, static: true }) subscribedStatus: ViewContainerRef;
    @ViewChild('userButtonsContainer', { read: ViewContainerRef, static: true }) userButtons: ViewContainerRef;

    public animationState: string;
    public toggleAnimationStateChanged: EventEmitter<AnimationEvent> =
        new EventEmitter<AnimationEvent>();

    constructor(
        protected slidableMenuService: SlidableMenuService,
        protected store: Store,
        protected modalService: ModalService,
        protected auth: AuthenticationService,
        protected router: Router,
        @Inject(DOCUMENT) protected document: Document,
    ) {
        super(slidableMenuService);
    }

    ngOnInit(): void {
        this.slidableMenuService.injectSubscribedStatus(this.subscribedStatus);
        this.slidableMenuService.injectUserButtons(this.userButtons);
    }

    ngOnDestroy(): void {
        this.toggleAnimationStateChanged.complete();
    }

    @HostListener('document: keydown', ['$event']) handleKeydown(event: KeyboardEvent): void {
        event.preventDefault();
        if (event.key === KeyboardCommon.ESCAPE_KEY_NAME) {
            this.slidableMenuService.closeSlidableMenu();
        }
    }

    onToggleFinished(event: AnimationEvent): void {
        this.toggleAnimationStateChanged.emit(event);
    }

    startClosingAnimation(): void {
        this.animationState = SlidableMenuCommon.ANIMATION_STATE_CLOSED;
    }

    public logout(): void {
        this.modalService.confirm(
            this.CONFIRM_LOGOUT,
            (): void => {
                this.auth.logout();
            },
        );
    }

    public navigateTo(route: string): void {
        this.router.navigate([
            'main',
            route,
        ]);
    }

    public navigateToFAQ(): void {
        const gender: IProfile = this.auth.get();

        if (UserCommon.isMale(gender)) {
            this.document.location.href = `${ Config.supportLink }faq-prince`;

            return;
        }

        this.document.location.href = `${ Config.supportLink }faq-princess`;
    }
}
