export {
    IDeactivateReasonsState,
    deactivateReasonInitialState,
    reasonAdapter,
} from '@libs/store/deactivate-reason/state';

import * as DeactivateReasonActions from '@libs/store/deactivate-reason/actions';
import * as DeactivateReasonReducer from '@libs/store/deactivate-reason/reducer';
import * as DeactivateReasonSelectors from '@libs/store/deactivate-reason/selectors';

export {
    DeactivateReasonActions,
    DeactivateReasonReducer,
    DeactivateReasonSelectors,
};

export {
    IReason,
} from '@libs/store/deactivate-reason/interface';
