import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    BoostActivatedCardComponentCommon,
} from '@libs/modules/boost/components/boost-activated-card/boost-activated-card.component.common';

@Component({
    selector: 'mp-boost-activated-card',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './boost-activated-card.html',
})
export class BoostActivatedCardComponent extends BoostActivatedCardComponentCommon {

}
