import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';

import { ReallySharedModule } from '@libs/really-shared/really-shared.module';
import { MPWebMissingTranslationHandler } from '@libs/utils/missing-translation-handler.web';

import { BoostToastSubtitlePipe } from '@prince/modules/boost/components/boost-toast/pipes/boost-toast-subtitle.pipe';
import { LoginForgotPasswordComponent } from '@prince/modules/initial/pages/login/components/login-forgot-password/login-forgot-password.component';
import { LoginModalContainerComponent } from '@prince/modules/initial/pages/login/components/login-modal-container/login-modal-container.component';
import { LoginNotAllowedComponent } from '@prince/modules/initial/pages/login/components/login-not-allowed/login-not-allowed.component';
import { NotificationComponent } from '@prince/modules/initial/pages/notification/notification.component';
import { CustomerSupportComponent } from '@prince/modules/main/modules/settings/customer-support/customer-support.component';
import { AllCashBoletoComponent } from '@prince/modules/main/pages/payment-boleto/allcash-boleto/allcash-boleto.component';
import { BoletoSelectorComponent } from '@prince/modules/main/pages/payment-boleto/boleto-selector.component';
import { MoipBoletoComponent } from '@prince/modules/main/pages/payment-boleto/moip-boleto/moip-boleto.component';
import { AllCashCardComponent } from '@prince/modules/main/pages/payment-card/allcash-card/allcash-card.component';
import { CardSelectorComponent } from '@prince/modules/main/pages/payment-card/card-selector.component';
import { MoipCardComponent } from '@prince/modules/main/pages/payment-card/moip/moip-card.component';
import { PaymentMethodOptionComponent } from '@prince/modules/main/pages/payment-method-option/payment-method-option.component';
import { PaymentComponent } from '@prince/modules/main/pages/payment/payment.component';
import { BoostToastTitlePipe } from '@prince/modules/main/services/toast/pipes/boost/boost-toast-title.pipe';
import { MeltToastTitlePipe } from '@prince/modules/main/services/toast/pipes/melt/melt-toast-title.pipe';
import { BadgesExtendedComponent } from '@prince/modules/main/shared/badges-extended/badges-extended.component';
import { BadgesComponent } from '@prince/modules/main/shared/badges/badges.component';
import { BubbleComponent } from '@prince/modules/main/shared/bubble/bubble.component';
import { ButtonPhotoVerifyComponent } from '@prince/modules/main/shared/button-photo-verify/button-photo-verify.component';
import { CarouselComponent } from '@prince/modules/main/shared/carousel/carousel.component';
import { CouponComponent } from '@prince/modules/main/shared/coupon/coupon.component';
import { DateInputDirective } from '@prince/modules/main/shared/date-input/date-input.directive';
import { FancyCheckboxComponent } from '@prince/modules/main/shared/fancy-checkbox/fancy-checkbox.component';
import { FeedbackNagbarComponent } from '@prince/modules/main/shared/feedback-nagbar/feedback-nagbar.component';
import { HelpTooltipComponent } from '@prince/modules/main/shared/help-tooltip/help-tooltip.component';
import { ImgSrcsetComponent } from '@prince/modules/main/shared/img-srcset/img-srcset.component';
import { InfiniteScrollDirective } from '@prince/modules/main/shared/inifinite-scroll/infinite-scroll.directive';
import { LoadingComponent } from '@prince/modules/main/shared/loading/loading.component';
import { NagbarDirective } from '@prince/modules/main/shared/nagbar/nagbar.directive';
import { PaymentLoadingComponent } from '@prince/modules/main/shared/payment-loading/payment-loading.component';
import { PaymentModalFormComponent } from '@prince/modules/main/shared/payment-modal-form/payment-modal-form.component';
import { PaymentModalHeaderComponent } from '@prince/modules/main/shared/payment-modal-header/payment-modal-header.component';
import { PercentagePipe } from '@prince/modules/main/shared/percentage/percentage.pipe';
import { ProfileCardComponent } from '@prince/modules/main/shared/profile-card/profile-card.component';
import { ProfilePreviewSmallComponent } from '@prince/modules/main/shared/profile-preview-small/profile-preview-small.component';
import { SlidableMenuItemComponent } from '@prince/modules/main/shared/slidable-menu-item/slidable-menu-item.component';
import { SubmenuCallToAction } from '@prince/modules/main/shared/submenu-call-to-action/submenu-call-to-action.component';
import { SubmenuV2Component } from '@prince/modules/main/shared/submenu-v2/submenu-v2.component';
import { SubscriptionButtonComponent } from '@prince/modules/main/shared/subscription-button/subscription-button.component';
import { UploadImageBoxComponent } from '@prince/modules/main/shared/upload-image-box/upload-image-box.component';
import { ViewStyleButtonComponent } from '@prince/modules/main/shared/view-style-button/view-style-button.component';
import { ViewStyleSelectorComponent } from '@prince/modules/main/shared/view-style-selector/view-style-selector.component';
import { RadioCheckGradientComponent } from '@prince/modules/registration/registration-onboarding-a/radio-check-gradient/radio-check-gradient.component';
import { UserGenderSelectorComponent } from '@prince/modules/registration/registration-onboarding-a/user-gender-selector/user-gender-selector.component';
import { AccessPrivatePhotosButton } from '@prince/modules/shared/access-private-photo-button/access-private-photos-button.component';
import { ActionbarAnchorActionComponent } from '@prince/modules/shared/actionbar/actionbar-anchor-action/actionbar-anchor-action.component';
import { ActionbarBackButtonComponent } from '@prince/modules/shared/actionbar/actionbar-back-button/actionbar-back-button.component';
import { ActionbarBackTitleComponent } from '@prince/modules/shared/actionbar/actionbar-back-title/actionbar-back-title.component';
import { ActionbarCallToActionComponent } from '@prince/modules/shared/actionbar/actionbar-call-to-action/actionbar-call-to-action.component';
import { ActionbarCheckoutComponent } from '@prince/modules/shared/actionbar/actionbar-checkout/actionbar-checkout.component';
import { ActionbarLogoMPComponent } from '@prince/modules/shared/actionbar/actionbar-logo-mp/actionbar-logo-mp.component';
import { ActionbarMessageReceiveComponent } from '@prince/modules/shared/actionbar/actionbar-message-receive/actionbar-message-receive.component';
import { ActionbarPageTitleComponent } from '@prince/modules/shared/actionbar/actionbar-page-title/actionbar-page-title.component';
import { ActionbarProfileDetailsComponent } from '@prince/modules/shared/actionbar/actionbar-profile-details/actionbar-profile-details.component';
import { ActionbarThreeDotsComponent } from '@prince/modules/shared/actionbar/actionbar-three-dots/actionbar-three-dots.component';
import { ActionbarUserImageComponent } from '@prince/modules/shared/actionbar/actionbar-user-image/actionbar-user-image.component';
import { ActionbarComponent } from '@prince/modules/shared/actionbar/actionbar.component';
import { BaseActionbarComponent } from '@prince/modules/shared/actionbar/base-actionbar/base-actionbar.component';
import { NavigationButtonComponent } from '@prince/modules/shared/actionbar/navigation-button/navigation-button.component';
import { UserMenuItemsComponent } from '@prince/modules/shared/actionbar/user-menu-items/user-menu-items.component';
import { UserMenuComponent } from '@prince/modules/shared/actionbar/user-menu/user-menu.component';
import { ButtonWithLoadingComponent } from '@prince/modules/shared/button-with-loading/button-with-loading.component';
import { GenericInputComponent } from '@prince/modules/shared/generic-input/generic-input.component';
import { LoadIconAnimatedComponent } from '@prince/modules/shared/load-icon-animated/load-icon-animated.component';
import { MembershipBadgeExtendedComponent } from '@prince/modules/shared/membership-badge-extended/membership-badge-extended.component';
import { MembershipBadgeComponent } from '@prince/modules/shared/membership-badge/membership-badge.component';
import { MessageHeaderComponent } from '@prince/modules/shared/message-header/message-header.component';
import { PaymentMethodsComponent } from '@prince/modules/shared/payment-methods/payment-methods.component';
import { PublicProfileButtonsComponent } from '@prince/modules/shared/public-profile-buttons/public-profile-buttons.component';
import { SubscribeButtonComponent } from '@prince/modules/shared/subscribe-button/subscribe-button.component';
import { SubscribedStatusComponent } from '@prince/modules/shared/subscribed-status/subscribed-status.component';

import { VirtualScrollerModule } from 'ngx-virtual-scroller';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild([]),
        TranslateModule.forChild({
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MPWebMissingTranslationHandler,
            },
        }),
        LayoutModule,
        VirtualScrollerModule,
        ReallySharedModule,
    ],
    exports: [
        ActionbarComponent,
        ActionbarBackButtonComponent,
        ActionbarAnchorActionComponent,
        ActionbarBackTitleComponent,
        ActionbarCallToActionComponent,
        ActionbarLogoMPComponent,
        ActionbarUserImageComponent,
        ActionbarThreeDotsComponent,
        ActionbarProfileDetailsComponent,
        ActionbarMessageReceiveComponent,
        ActionbarPageTitleComponent,
        ActionbarCheckoutComponent,
        BaseActionbarComponent,
        CarouselComponent,
        CouponComponent,
        UserMenuComponent,
        SubmenuV2Component,
        SubmenuCallToAction,
        NavigationButtonComponent,
        FancyCheckboxComponent,
        UploadImageBoxComponent,
        HelpTooltipComponent,
        LoadingComponent,
        BoletoSelectorComponent,
        CardSelectorComponent,
        MoipBoletoComponent,
        MoipCardComponent,
        NotificationComponent,
        NagbarDirective,
        AllCashBoletoComponent,
        AllCashCardComponent,
        PaymentModalHeaderComponent,
        PaymentModalFormComponent,
        PaymentLoadingComponent,
        DateInputDirective,
        PaymentComponent,
        PaymentMethodOptionComponent,
        VirtualScrollerModule,
        UserMenuItemsComponent,
        MessageHeaderComponent,
        FeedbackNagbarComponent,
        ProfilePreviewSmallComponent,
        ReactiveFormsModule,
        InfiniteScrollDirective,
        CustomerSupportComponent,
        ImgSrcsetComponent,
        MeltToastTitlePipe,
        BoostToastTitlePipe,
        PercentagePipe,
        BoostToastSubtitlePipe,
        BadgesComponent,
        BadgesExtendedComponent,
        MembershipBadgeComponent,
        MembershipBadgeExtendedComponent,
        SubscribedStatusComponent,
        SubscribeButtonComponent,
        ProfileCardComponent,
        SubscriptionButtonComponent,
        ButtonPhotoVerifyComponent,
        SlidableMenuItemComponent,
        ViewStyleButtonComponent,
        BubbleComponent,
        PublicProfileButtonsComponent,
        AccessPrivatePhotosButton,
        GenericInputComponent,
        ButtonWithLoadingComponent,
        LoadIconAnimatedComponent,
        LoginForgotPasswordComponent,
        LoginModalContainerComponent,
        LoginNotAllowedComponent,
        UserGenderSelectorComponent,
        RadioCheckGradientComponent,
        PaymentMethodsComponent,
        ReallySharedModule,
    ],
    declarations: [
        ActionbarComponent,
        ActionbarBackButtonComponent,
        ActionbarAnchorActionComponent,
        ActionbarBackTitleComponent,
        ActionbarCallToActionComponent,
        ActionbarLogoMPComponent,
        ActionbarUserImageComponent,
        ActionbarThreeDotsComponent,
        ActionbarProfileDetailsComponent,
        NavigationButtonComponent,
        ActionbarMessageReceiveComponent,
        ActionbarPageTitleComponent,
        ActionbarCheckoutComponent,
        BaseActionbarComponent,
        CarouselComponent,
        CouponComponent,
        UserMenuComponent,
        SubmenuV2Component,
        SubmenuCallToAction,
        UserMenuItemsComponent,
        FancyCheckboxComponent,
        UploadImageBoxComponent,
        HelpTooltipComponent,
        LoadingComponent,
        BoletoSelectorComponent,
        CardSelectorComponent,
        MoipBoletoComponent,
        MoipCardComponent,
        NotificationComponent,
        NagbarDirective,
        AllCashBoletoComponent,
        PaymentModalHeaderComponent,
        PaymentModalFormComponent,
        PaymentLoadingComponent,
        DateInputDirective,
        PaymentComponent,
        PaymentMethodOptionComponent,
        AllCashCardComponent,
        MessageHeaderComponent,
        FeedbackNagbarComponent,
        ProfilePreviewSmallComponent,
        InfiniteScrollDirective,
        CustomerSupportComponent,
        ImgSrcsetComponent,
        MeltToastTitlePipe,
        BoostToastTitlePipe,
        PercentagePipe,
        BoostToastSubtitlePipe,
        BadgesComponent,
        BadgesExtendedComponent,
        MembershipBadgeComponent,
        MembershipBadgeExtendedComponent,
        SubscribedStatusComponent,
        SubscribeButtonComponent,
        ProfileCardComponent,
        ViewStyleSelectorComponent,
        ProfileCardComponent,
        SubscriptionButtonComponent,
        ButtonPhotoVerifyComponent,
        SlidableMenuItemComponent,
        ViewStyleButtonComponent,
        BubbleComponent,
        PublicProfileButtonsComponent,
        AccessPrivatePhotosButton,
        GenericInputComponent,
        ButtonWithLoadingComponent,
        LoadIconAnimatedComponent,
        LoginForgotPasswordComponent,
        LoginModalContainerComponent,
        LoginNotAllowedComponent,
        UserGenderSelectorComponent,
        RadioCheckGradientComponent,
        PaymentMethodsComponent,
    ],
})
export class SharedModule {
    //
}
