import { Directive, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { AnalyticsServiceCommon } from '@libs/services/analytics/analytics.service.common';
import { MembershipCommon, MembershipType } from '@libs/shared/membership/membership.common';
import { IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { MembershipSelectors } from '@libs/store/membership';

@Directive()
export class MembershipServiceCommon implements OnDestroy {
    protected alreadyPaid: boolean = false;
    protected subscriptions: Subscription[] = [];
    protected latestPaidMembership$: Observable<MembershipType>;

    constructor(
        protected store: Store<IApplicationState>,
        protected analyticsService: AnalyticsServiceCommon,
    ) {
        this.subscriptions.push(this.store
            .select('stats')
            .subscribe((stats: IStats): void => {
                if (!stats.profile_id) {
                    return;
                }

                this.alreadyPaid = this.analyticsService.hasPayments(stats);
            }),
        );

        this.latestPaidMembership$ = this.store.pipe(
            select(MembershipSelectors.selectLatestPaidMembership),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    public isAlreadyPaid(): boolean {
        return this.alreadyPaid;
    }

    public userHasPaidEliteAndIsPending$(user: UserCommon): Observable<boolean> {
        return this.userHasPaidEliteMembership$().pipe(
            concatMap((hasPaidEliteMembership: boolean): Observable<boolean> => {
                return of(
                    hasPaidEliteMembership ||
                    UserCommon.isPendingElite(user),
                );
            }),
        );
    }

    protected userHasPaidEliteMembership$(): Observable<boolean> {
        return this.latestPaidMembership$.pipe(
            concatMap((latestPaidMembership: MembershipType): Observable<boolean> => {
                return of(MembershipCommon.isEliteType(latestPaidMembership));
            }),
        );
    }
}
