import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    IPendingMessageState,
    PendingMessageAdapter,
} from '@libs/store/pending-messages/state';

export const selectState = createFeatureSelector<IPendingMessageState>(
    'storePendingMessageEntities',
);

export const selectAll = createSelector(
    selectState,
    PendingMessageAdapter.getSelectors().selectAll,
);
