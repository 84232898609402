<div
    class="verify-photo"
    *ngIf="canShowNagBar()"
>
    <h2>
        {{ 'modules.main.pages.photo_verify.verify_nagbar_header' | translate }}
    </h2>
    <p>
        {{ 'modules.main.pages.photo_verify.verify_nagbar_text' | translate }}
    </p>
    <div class="profile-badge badge-verified">
        <img src="/assets/img/verified-green.svg">
        <div>
            {{ 'modules.main.pages.profile.member_verified' | translate }}
        </div>
    </div>
    <div class="buttons-container">
        <button
            class="mp-main-button bt-md"
            [routerLink]="['/main', 'photo-verify']"
        >
            {{ 'modules.main.pages.photo_verify.verify_nagbar_button' | translate }}
        </button>
        <button
            class="hide-btn"
            (click)="hide()"
        >
            {{ 'common.not_now' | translate }}
        </button>
    </div>
</div>
<div
    class="verify-photo"
    *ngIf="hasPhotosToVerify()"
>
    <h2>
        {{ 'modules.main.pages.photo_verify.verify_nagbar_waiting_header' | translate }}
    </h2>
    <p>
        {{ 'modules.main.pages.photo_verify.verify_nagbar_waiting_text' | translate }}
    </p>
    <div class="text-center">
        <div class="profile-badge badge-verified">
            <img src="/assets/img/verified-green.svg">
            <div>
                {{ 'modules.main.pages.profile.member_verified' | translate }}
            </div>
        </div>
    </div>
</div>
