import { Action } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

export const PROFILES_NEW_RECEIVED_ACTION: string = 'PROFILES_NEW_RECEIVED_ACTION';
export const PROFILES_NEW_REFRESHED_ACTION: string = 'PROFILES_NEW_REFRESHED_ACTION';
export const PROFILES_NEW_REMOVED_ACTION: string = 'PROFILES_NEW_REMOVED_ACTION';
export const NEW_TTL_UPDATED_ACTION: string = 'NEW_TTL_UPDATED_ACTION';

export interface IProfilesNewReceivedActionPayload {
    profiles: IProfile[];
}

export class ProfilesNewReceivedAction implements Action {
    readonly type: string = PROFILES_NEW_RECEIVED_ACTION;

    constructor(public payload: IProfilesNewReceivedActionPayload) {
        //
    }
}

export class ProfilesNewRefreshedAction implements Action {
    readonly type: string = PROFILES_NEW_REFRESHED_ACTION;
}

export interface INewTTLActionPayload {
    ttl: number;
}

export class NewTTLUpdatedAction implements Action {
    readonly type: string = NEW_TTL_UPDATED_ACTION;

    constructor(public payload: INewTTLActionPayload) {
        //
    }
}
export class ProfileNewRemovedAction implements Action {
    readonly type: string = PROFILES_NEW_REMOVED_ACTION;

    constructor(public payload: IProfilesNewReceivedActionPayload) {
        //
    }
}
