import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserCommon } from '@libs/shared/user/user.common';

const selectFeature = createFeatureSelector<UserCommon>('user');

export const profileGender = createSelector(
    selectFeature,
    (state: UserCommon): number => state.sex,
);

export const selectUser = createSelector(
    selectFeature,
    (state: UserCommon) => state,
);
