import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import {
    PaymentCardComponentCommon,
} from '@libs/modules/main/pages/payment-card/payment-card.component.common';
import {
    PaymentInfoServiceCommon,
} from '@libs/modules/main/services/payment/payment-info.service.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';

@Directive()
export abstract class AllCashCardComponentCommon extends PaymentCardComponentCommon {
    constructor(
        protected paymentInfoService: PaymentInfoServiceCommon,
        protected translate: TranslateService,
        protected userService: UserServiceCommon,
        protected store: Store<IApplicationState>,
        protected router: Router,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        super(
            translate,
            userService,
            store,
            paymentInfoService,
            router,
            paymentHelpersService,
        );
    }
}
