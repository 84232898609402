<div class="payment-margin">
    <mp-fancy-checkbox
        class="international-card-selector"
        [value]="isInternational"
        (valueChange)="changeInternational()"
    >
        {{ 'modules.main.pages.payment.cc_international' | translate }}
    </mp-fancy-checkbox>
    <div class="payment-wrapper">
        <iframe
            class="payment-iframe embed-responsive-item"
            id="injectFrame"
            name="injectFrame"
            border="0"
            height="500"
            width="100%"
        ></iframe>
        <div class="iframe-loader-wrapper">
            <div class="iframe-loader"></div>
        </div>
    </div>
</div>
