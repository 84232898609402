import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';

import { IApplicationState } from '@libs/store/application-state';
import { closeSlidableMenu } from '@libs/store/slidable-menu/action';

@Directive()
export class UserButtonsReferralComponentCommon {
    public readonly THIRTY_DAYS_OF_PREMIUM: number = 30;

    constructor(
        protected store: Store<IApplicationState>,
    ) {
        //
    }

    closeSlidableMenu(): void {
        this.store.dispatch(closeSlidableMenu());
    }
}
