import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { LoginInfo } from '@libs/shared/profile/login-info';
import { IUnsubscribeEmailResponse } from '@libs/shared/unsubscribe-email-response/unsubscribe-email-response.interface';
import { IStats } from '@libs/shared/user/stats';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { TokenReceivedAction } from '@libs/store/authentication/actions/token.action';
import { SetTmpTokenAction } from '@libs/store/ui/actions/auth-token.action';
import { ClearAllAction } from '@libs/store/ui/actions/clear-all.action';

import { Config } from '@prince/config';
import { AuthRequestFacade } from '@prince/modules/main/services/auth-request-facade/auth-request-facade';
import { AnalyticsService } from '@prince/services/analytics/analytics.service';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { origin } from '@prince/utils/origin';

@Injectable()
export class UserService extends UserServiceCommon {
    constructor(
        protected http: HttpClient,
        protected authRequestFacade: AuthRequestFacade,
        protected store: Store<IApplicationState>,
        protected translate: TranslateService,
        protected analytics: AnalyticsService,
        protected authHttp: AuthHttpService,
    ) {
        super(
            http,
            authRequestFacade,
            store,
            translate,
            analytics,
        );
    }

    login(user: LoginInfo): Observable<HttpResponse<any>> {
        const endpoint: string = Config.serverIp + 'login-new';
        const headers: HttpHeaders = this.defaultHeaders
            .append('Content-Type', this.contentType)
            .append('X-MP-Version', Config.version);

        const client_version: string = this.getClientInfo();

        const data: any = {
            password: user.password,
            client_version,
        };

        // TODO: Cannot read property 'search' of undefined
        if (user.username.search('@') !== -1) {
            data.email = user.username;
        } else {
            data.username = user.username;
        }

        return this.http.post(endpoint, data, { headers, observe: 'response' })
            .pipe(
                tap((): void => this.analytics.onLogin()),
                catchError((error: Response): Observable<never> => {
                    this.analytics.onLoginError();

                    return this.handleErrors(error);
                }),
            );
    }

    public forgotPassword(email: string): Observable<HttpResponse<any>> {
        const endpoint: string = Config.serverIp + 'forgot-password';

        return this.http.post(endpoint, { email }, {
            headers: this.defaultHeaders,
            observe: 'response',
        }).pipe(
            catchError(this.handleErrors),
            catchError(this.invalidEmailError),
        );
    }

    isEmailUnique(email: string): Observable<HttpResponse<any>> {
        const endpoint: string = Config.serverIp + 'email-new';
        const headers: HttpHeaders = this.defaultHeaders
            .append('Content-Type', this.contentType);

        const data = {
            email,
        };

        return this.http.post(endpoint, data, { headers, observe: 'response' });
    }

    isUsernameUnique(username: string): Observable<HttpResponse<any>> {
        const endpoint: string = Config.serverIp + 'username';
        const headers: HttpHeaders = this.defaultHeaders
            .append('Content-Type', this.contentType);

        const data = {
            username,
        };
        return this.http.post(endpoint, data, { headers, observe: 'response' });
    }

    sendVerificationEmailAgain(email: string, token: string): Observable<HttpResponse<any>> {
        const endpoint: string = Config.serverIp + 'change-email';

        const headers: HttpHeaders = this.defaultHeaders
            .append('Content-Type', this.contentType)
            .append('Authorization', this.bearerByToken(token));

        const data = {
            email,
        };
        return this.http
            .post(endpoint, data, { headers, observe: 'response' })
            .pipe(map((value: HttpResponse<any>): any => {
                if (!value || !value.body) {
                    throw value;
                }
                return value.body;
            }));
    }

    reactivate(token: string): Observable<HttpResponse<any>> {
        const endpoint: string = Config.serverIp + 'reactivate';
        const headers: HttpHeaders = this.defaultHeaders
            .append('Content-Type', this.contentType)
            .append('Authorization', this.bearerByToken(token));

        return this.http.post(endpoint, { }, { headers, observe: 'response' })
            .pipe(
                tap((): void => this.analytics.onReactivate()),
                catchError((error: Response): Observable<never> => {
                    this.analytics.onReactivateError();

                    return this.handleErrors(error);
                }),
            );
    }

    public checkEmailKey(
        hash: string,
        sucessCallback: () => void,
        errorCallback: (response: HttpErrorResponse) => void,
    ): Subscription {
        const endpoint: string = Config.serverIp + 'check-email-key';

        return this.http.post(endpoint, { hash }, { headers: this.defaultHeaders })
            .subscribe((response: any): void => {
                this.store.dispatch(new TokenReceivedAction({
                    token: response.token,
                }));

                sucessCallback();
            }, (error: HttpErrorResponse): void => {
                errorCallback(error);
            });
    }

    public unsubscribeEmailByCampaign(
        key: string,
    ): Observable<HttpResponse<IUnsubscribeEmailResponse>> {
        const endpoint: string = Config.serverIp + 'unsubscribe';

        const params = new HttpParams().set('key', key);

        return this.http.post(
            endpoint,
            params,
            { headers: this.defaultHeaders, observe: 'response' },
        );
    }

    public checkForgottenPasswordHash(
        hash: string,
        successCallback: (status: any, token: string) => void,
        errorCallback: (err: any) => void,
    ): Subscription {
        const endpoint: string = Config.serverIp + 'check-forgotten-password-hash';

        return this.http.post(endpoint, { hash }, { headers: this.defaultHeaders })
            .subscribe((response: any): void => {
                this.store.dispatch(new ClearAllAction());
                this.store.dispatch(new SetTmpTokenAction({ tmpToken: response.token }));

                successCallback(response.message, response.token);
            }, (err): void => {
                errorCallback(err);
            },
            );
    }

    protected getStats(callback: (response: IStats) => void): void {
        const endpoint: string = `${Config.serverIp}me/analytics`;

        this.authRequestFacade.get({
            endpoint,
        }).subscribe((response): void => {
            if (response) {
                callback(response.data);
            }
        });
    }

    getClientInfo(): string {
        return 'web';
    }

    public registerNewUser(userdata: any): Observable<string> {
        const endpoint = Config.serverIp + 'profile';

        return this.http
            .post(endpoint, userdata, { headers: this.defaultHeaders, observe: 'response' })
            .pipe(map((value: HttpResponse<any>): string => {
                if (!value || !value.body) {
                    throw value;
                }

                return value.body.token;
            }));
    }

    confirmationEmail(): Observable<any> {
        const endpoint: string = Config.serverIp + 'confirmation-email';

        return this.authHttp.post(endpoint, null);
    }

    public requestNewConfirmationEmail(
        email: string,
    ): Observable<IAuthResponse<any>> {
        const endpoint: string = Config.serverIp + 'confirmation-email';

        const body: {
            email: string,
        } = {
            email,
        };

        return this.authHttp.post(endpoint, body);
    }

    getOrigin(): string {
        return origin().toString();
    }
}
