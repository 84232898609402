import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription, throwError } from 'rxjs';

import { LoginInfo } from '@libs/shared/profile/login-info';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { UiActions, UiSelectors } from '@libs/store/new-ui';

import { ReactivateModalComponent } from '@prince/components/reactivate-modal/reactivate-modal.component';
import { Config } from '@prince/config';
import { LoginForgotPasswordComponent } from '@prince/modules/initial/pages/login/components/login-forgot-password/login-forgot-password.component';
import { LoginNotAllowedComponent } from '@prince/modules/initial/pages/login/components/login-not-allowed/login-not-allowed.component';
import { LoginStatus } from '@prince/modules/initial/pages/login/enums/login-status';
import { ILoginResponse } from '@prince/modules/initial/pages/login/interfaces/login-response.interface';
import { AdvancedModalService } from '@prince/services/advanced-modal.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { LoginFormControlNames } from '@prince/services/login-form-manager/login-form-control-names';
import { LoginFormManagerService } from '@prince/services/login-form-manager/login-form-manager.service';
import { UserService } from '@prince/services/user.service';

@Component({
    selector: 'login',
    templateUrl: 'login.html',
    styleUrls: ['login.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
    public email: string = LoginFormControlNames.EMAIL;
    public password: string = LoginFormControlNames.PASSWORD;
    public isAdvancedModalOpened: boolean = false;
    public loginInfo: LoginInfo = new LoginInfo();

    public isAttemptingLogin$: Observable<boolean> = of(false);

    protected subscriptions: Subscription[] = [];

    constructor(
        protected router: Router,
        protected store: Store<IApplicationState>,
        protected advancedModalService: AdvancedModalService,
        protected authenticationService: AuthenticationService,
        protected loginFormManagerService: LoginFormManagerService,
        protected userService: UserService,
        protected translateService: TranslateService,
        protected renderer2: Renderer2,
        @Inject(DOCUMENT) protected document: Document,
    ) {
        this.loginFormManagerService.setupLoginForm();
        this.resetIsAttemptingLoginState();
    }

    ngOnInit(): void {
        this.renderer2.addClass(this.document.body, 'no-padding');
        this.isAttemptingLogin$ = this.store.select(
            UiSelectors.selectIsAttemptingLogin,
        );
    }

    ngOnDestroy(): void {
        this.renderer2.removeClass(this.document.body, 'no-padding');
        this.subscriptions.forEach(
            (subscription: Subscription): void => {
                subscription.unsubscribe();
            },
        );
        this.subscriptions = [];
    }

    protected resetIsAttemptingLoginState(): void {
        this.store.dispatch(
            UiActions.setIsAttemptingLogin({
                isAttemptingLogin: false,
            }),
        );
    }

    public get loginForm(): FormGroup {
        return this.loginFormManagerService.getLoginForm();
    }

    public get meuPrincipeLogo(): string {
        return `${ Config.imagesFolderBaseUrl }meu-principe-logo.svg`;
    }

    public navigateToRegistration(): void {
        this.router.navigate(
            ['/cadastro'],
        );
    }

    protected setAdvancedModalOpened(isOpened: boolean): void {
        this.isAdvancedModalOpened = isOpened;
    }

    public getInputErrorMessage(control: string): string {
        if (this.isEmailInputTouchedAndEmpty(control)) {
            return 'login.input.warning';
        }

        return '';
    }

    public submit(): void {
        if (!this.validateLogin()) {
            return;
        }

        this.dispatchIsAttemptingLogin(true);

        this.subscriptions.push(
            this.login().subscribe({
                next: (response: HttpResponse<ILoginResponse>): void => {
                    if (response.body.message === LoginStatus.DEACTIVATED) {
                        this.advancedModalService.open(
                            ReactivateModalComponent, {
                                userDismissable: false,
                                data: {
                                    token: response.body.token,
                                },
                            });

                        return;
                    }

                    this.authenticationService.setToken(response.body.token);
                },
                error: (err: HttpErrorResponse): Observable<never> => {
                    this.checkLoginErrors(err);
                    this.dispatchIsAttemptingLogin(false);

                    return throwError(err);
                },
            }),
        );
    }

    protected dispatchIsAttemptingLogin(isAttemptingLogin: boolean): void {
        this.store.dispatch(UiActions.setIsAttemptingLogin({
            isAttemptingLogin,
        }));
    }

    protected isEmailInputTouchedAndEmpty(control: string): boolean {
        return this.loginForm.get(control).value === '' &&
            this.loginForm.get(control).touched;
    }

    public forgotPassword(): void {
        this.setAdvancedModalOpened(true);
        this.advancedModalService.open(LoginForgotPasswordComponent, {
            data: {
                closeCallback: (): void => {
                    this.setAdvancedModalOpened(true);
                },
            },
        });
    }

    protected invalidCrendentials(): void {
        this.setAdvancedModalOpened(true);
        this.advancedModalService.open(
            LoginNotAllowedComponent, {
                data: {
                    closeCallback: (): void => {
                        this.setAdvancedModalOpened(true);
                    },
                    title: 'login.not_allowed.header.title',
                    error: 'login.not_allowed.invalid_credentials.description',
                    buttonText: 'common.ok',
                },
            });
    }

    protected blockedLogins(): void {
        this.setAdvancedModalOpened(true);
        this.advancedModalService.open(
            LoginNotAllowedComponent, {
                data: {
                    closeCallback: (): void => {
                        this.setAdvancedModalOpened(true);
                    },
                    title: 'login.not_allowed.header.title',
                    error: 'login.not_allowed.blocked.description',
                    buttonText: 'login.not_allowed.blocked.button.text',
                },
            });
    }

    protected connectionLost(): void {
        this.setAdvancedModalOpened(true);
        this.advancedModalService.open(
            LoginNotAllowedComponent, {
                data: {
                    closeCallback: (): void => {
                        this.setAdvancedModalOpened(true);
                    },
                    title: 'login.not_allowed.connection_lost.header.title',
                    error: 'login.not_allowed.connection_lost.description',
                    buttonText: 'login.not_allowed.connection_lost.button.text',
                },
            });
    }

    protected loginNotAllowed(errorMessage: string): void {
        this.setAdvancedModalOpened(true);
        this.advancedModalService.open(
            LoginNotAllowedComponent, {
                data: {
                    closeCallback: (): void => {
                        this.setAdvancedModalOpened(true);
                    },
                    title: 'login.not_allowed.header.title',
                    error: errorMessage,
                    buttonText: 'common.ok',
                },
            });
    }

    protected getLoginFormValues(): void {
        this.loginInfo.username = this.loginForm.get(this.email).value;
        this.loginInfo.password = this.loginForm.get(this.password).value;
    }

    protected validateLogin(): boolean {
        this.getLoginFormValues();

        if (!this.loginInfo.username) {
            this.invalidCrendentials();

            return false;
        }

        if (
            this.loginInfo.username.includes('@') &&
            !UserCommon.isValidEmail(this.loginInfo.username)
        ) {
            this.invalidCrendentials();

            return false;
        }

        if (
            !this.loginInfo.username.includes('@') &&
            !UserCommon.isValidUserName(this.loginInfo.username)
        ) {
            this.invalidCrendentials();

            return false;
        }

        if (!UserCommon.isValidPassword(this.loginInfo.password)) {
            this.invalidCrendentials();

            return false;
        }

        if (!this.isOnline()) {
            this.connectionLost();

            return false;
        }

        return true;
    }

    public isOnline(): boolean {
        return navigator.onLine;
    }

    protected login(): Observable<HttpResponse<unknown>> {
        return this.userService.login(this.loginInfo);
    }

    protected isEmptyStatusTextError(response: HttpErrorResponse): boolean {
        return response.statusText === '' &&
            (response.status === 200 || !response.status);
    }

    protected checkLoginErrors(response: HttpErrorResponse): void {
        if (this.isEmptyStatusTextError(response)) {
            this.loginNotAllowed('modules.initial.pages.login.offline');

            return;
        }

        if (response.status === 403) {
            this.loginNotAllowed('modules.initial.pages.login.version_error_web');

            return;
        }

        if (response.status === 429) {
            this.blockedLogins();

            return;
        }

        if (response.status === 503) {
            this.router.navigate(['maintenance'], { replaceUrl: true });

            return;
        }

        if (response.status !== 401) {
            this.loginNotAllowed('modules.initial.pages.login.could_not_create_token');

            return;
        }

        const error = response.error.error;

        if (error === LoginStatus.MUST_CHANGE_PASSWORD) {
            this.loginNotAllowed('modules.modal.change-password.description');

            return;
        }

        if (error === LoginStatus.COULD_NOT_CREATE_TOKEN) {
            this.loginNotAllowed('modules.initial.pages.login.could_not_create_token');

            return;
        }

        if (error === LoginStatus.SUSPENDED) {
            this.loginNotAllowed('modules.initial.pages.login.status_suspended');

            return;
        }

        if (error === LoginStatus.PERMANENTLY_DELETED) {
            this.loginNotAllowed('status.temporary_deleted');

            return;
        }

        this.invalidCrendentials();
    }

    public getEmailLabelText(): string {
        return 'login.input.label.email';
    }

    public getEmailPlaceholderText(): string {
        return 'login.input.placeholder';
    }

    public getPasswordLabelText(): string {
        return 'login.input.label.password';
    }

    public getPasswordPlaceholderText(): string {
        return 'login.input.placeholder';
    }

    public getForgotPasswordText(): string {
        return 'login.forgot-password';
    }

    public getEnterAccountText(): string {
        return 'login.enter-account';
    }

    public getPresentationText(): string {
        return 'login.presentation';
    }

    public getLoginRegisterText(): string {
        return 'login.register';
    }
}
