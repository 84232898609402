import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { ConversationServiceCommon } from '@libs/modules/main/services/conversation/conversation.service.common';
import {
    IListMapper,
    IListState,
    IListStateMap,
    IRequestUpdatedListData,
    IUpdateListParams,
} from '@libs/modules/main/services/download-manager/download-manager';
import { ListServiceCommon } from '@libs/modules/main/services/list/list.service.common';
import { MessageServiceCommon } from '@libs/modules/main/services/message/message.service.common';
import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { SearchServiceCommon } from '@libs/modules/main/services/search/search.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { Message } from '@libs/shared/message/message';
import { IApplicationState } from '@libs/store/application-state';
import { ConversationActions, IConversation } from '@libs/store/conversations';
import { DownloadManagerUpdatedAction } from '@libs/store/download-manager/actions/download-manager.action';
import { UiActions } from '@libs/store/new-ui';

export interface ILists {
    featured: IListState;
    nearby: IListState;
    melt: IListState;
    'new': IListState;
    blocked: IListState;
    favMe: IListState;
    favMy: IListState;
    viewedMe: IListState;
    recommended: IListState;
    'my-private-photos-access': IListState;
    conversations: IListState;
    'conversations-favorites': IListState;
    messages: IListStateMap;
    profiles: IListStateMap;
    bulkSwipeProfiles: IListState;
    bulkProfiles: IListState;
    bulkMessages: IListState;
    messagesRecent: IListStateMap;
    search: IListState;
    savedSearch: IListState;
}

export type ListName = keyof ILists;

export const EMPTY_PAGE: IListState = {
    downloading: false,
    currentPage: 0,
    lastPage: -1,
    totalItems: 0,
};

export const INITIAL_DM_STATE: ILists = {
    featured: { ...EMPTY_PAGE },
    nearby: { ...EMPTY_PAGE },
    melt: { ...EMPTY_PAGE },
    new: { ...EMPTY_PAGE },
    blocked: { ...EMPTY_PAGE },
    favMe: { ...EMPTY_PAGE },
    favMy: { ...EMPTY_PAGE },
    viewedMe: { ...EMPTY_PAGE },
    recommended: { ...EMPTY_PAGE },
    'my-private-photos-access': { ...EMPTY_PAGE },
    conversations: { ...EMPTY_PAGE },
    'conversations-favorites': { ...EMPTY_PAGE },
    messages: { },
    profiles: { },
    bulkSwipeProfiles: { ...EMPTY_PAGE },
    bulkProfiles: { ...EMPTY_PAGE },
    bulkMessages: { ...EMPTY_PAGE },
    messagesRecent: { },
    search: { ...EMPTY_PAGE },
    savedSearch: { ...EMPTY_PAGE },
};

export abstract class DownloadManagerServiceCommon {
    protected pageCount: ILists;

    constructor(
        protected listService: ListServiceCommon,
        protected profileService: ProfileServiceCommon,
        protected searchService: SearchServiceCommon,
        protected conversationService: ConversationServiceCommon,
        protected messageService: MessageServiceCommon,
        protected store: Store<IApplicationState>,
    ) {
        this.store.select('downloadManager').pipe(take(1)).subscribe((pageCount): void => {
            this.pageCount = Object.assign({ }, pageCount || INITIAL_DM_STATE);

            for (const key of Object.keys(INITIAL_DM_STATE)) {
                if (this.pageCount[key] === undefined) {
                    this.pageCount[key] = INITIAL_DM_STATE[key];
                }

                this.pageCount[key].downloading = false;
            }
        });
    }

    resetAndUpdate(listName: ListName, subParam?: any): Observable<IAuthResponse> {
        this.resetPageCount(listName, subParam);
        this.serializeLists();
        return this.updateNextPage(listName, subParam);
    }

    resetPageCount(listName: ListName, subParam?: any): void {
        if (!this.listIsMap(listName)) {
            this.pageCount[listName] = { ...EMPTY_PAGE };
            return;
        }

        if (subParam === undefined) {
            this.pageCount[listName] = { };
            this.serializeLists();
            return;
        }

        this.pageCount[listName][subParam] = { ...EMPTY_PAGE };
        this.serializeLists();
    }

    public abstract updatePage(
        listName: ListName,
        subParam: any,
        page: number
    ): Observable<IAuthResponse>;

    public abstract updateNextPage(
        listName: ListName,
        subParam?: any
    ): Observable<IAuthResponse>;

    getListMapper(subParam: any, page?: number): IListMapper {
        return {
            featured: (): Observable<IAuthResponse> => this.updateHomeFeatured(),
            nearby: (): Observable<IAuthResponse> => this.updateHomeNearby(),
            melt: (): Observable<IAuthResponse> => this.updateFavMelt(),
            new: (): Observable<IAuthResponse> => this.updateHomeNew(),
            blocked: (): Observable<IAuthResponse> => this.updateBlockedUsers(),
            favMe: (): Observable<IAuthResponse> => this.updateFavMeProfiles(),
            favMy: (): Observable<IAuthResponse> => this.updateMyFavProfiles(),
            viewedMe: (): Observable<IAuthResponse> => this.updateViewedMeProfiles(),
            recommended: (): Observable<IAuthResponse> => this.updateRecommendedList(),
            'my-private-photos-access': (): Observable<IAuthResponse> => this.updateMyPrivatePhotosAccess(),
            conversations: (): Observable<IAuthResponse> => this.updateConversations(),
            'conversations-favorites': (): Observable<IAuthResponse> => this.updateConversationsFavorites(),
            messages: (): Observable<IAuthResponse> => this.updateMessages(subParam, page),
            profiles: (): Observable<IAuthResponse> => this.updateProfile(subParam),
            bulkProfiles: (): Observable<IAuthResponse> => this.updateBulkProfiles(subParam),
            bulkSwipeProfiles: (): Observable<IAuthResponse> => this.updateBulkSwipeProfiles(subParam),
            bulkMessages: (): Observable<IAuthResponse> => this.updateBulkMessages(subParam),
            messagesRecent: (): Observable<IAuthResponse> => this.updateMessagesRecent(subParam),
            search: (): Observable<IAuthResponse> => this.updateSearch(subParam),
            savedSearch: (): Observable<IAuthResponse> => this.updateSavedSearch(subParam),
        };
    }

    isAtLastPage(listName: ListName, subParam?: number): boolean {
        const list = this.getList(listName, subParam);
        return list.lastPage !== -1 && list.currentPage >= list.lastPage;
    }

    hasNeverDownloaded(listName: ListName, subParam?: number): boolean {
        return this.getList(listName, subParam).currentPage === 0;
    }

    isDownloading(listName: ListName, subParam?: number): boolean {
        return this.getList(listName, subParam).downloading;
    }

    totalItems(listName: ListName, subParam?: number): number {
        return this.getList(listName, subParam).totalItems;
    }

    clear(): void {
        for (const key of Object.keys(this.pageCount)) {
            this.resetPageCount(<ListName>key);
        }

        this.serializeLists();
    }

    protected updateHomeFeatured(): Observable<IAuthResponse> {
        return this.updateList(
            'featured',
            (currentPage): Observable<IAuthResponse> => this.listService
                .downloadHomeFeatured(currentPage),
            (response): void => {
                this.listService.updateHomeFeatured(response.data);
            },
        );
    }

    protected updateHomeNearby(): Observable<IAuthResponse> {
        return this.updateList(
            'nearby',
            (currentPage): Observable<IAuthResponse> => this.listService
                .downloadHomeNearby(currentPage),
            (response): void => {
                this.listService.updateHomeNearby(response.data);
            },
        );
    }

    protected updateFavMelt(): Observable<IAuthResponse> {
        return this.updateList(
            'melt',
            (currentPage): Observable<IAuthResponse> => this.listService
                .downloadFavMelt(currentPage),
            (response: IAuthResponse): void => {
                this.listService.updateFavMelt(response.data);
            },
        );
    }

    protected updateHomeNew(): Observable<IAuthResponse> {
        return this.updateList(
            'new',
            (currentPage): Observable<IAuthResponse> => this.listService
                .downloadHomeNewUsers(currentPage),
            (response): void => {
                this.listService.updateHomeNew(response.data);
            },
        );
    }

    protected updateBlockedUsers(): Observable<IAuthResponse> {
        return this.updateList(
            'blocked',
            (currentPage): Observable<IAuthResponse> => this.listService
                .downloadBlockedUsers(currentPage),
            (response): void => {
                this.listService.updateBlockedUsers(response.data);
            },
        );
    }

    protected updateFavMeProfiles(): Observable<IAuthResponse> {
        return this.updateList(
            'favMe',
            (currentPage): Observable<IAuthResponse> => this.listService
                .downloadFavMeProfiles(currentPage),
            (response): void => {
                this.listService.updateFavMeProfiles(response.data);
            },
        );
    }

    protected updateMyFavProfiles(): Observable<IAuthResponse> {
        return this.updateList(
            'favMy',
            (currentPage): Observable<IAuthResponse> => this.listService
                .downloadMyFavProfiles(currentPage),
            (response): void => {
                this.listService.updateMyFavProfiles(response.data);
            },
        );
    }

    protected updateViewedMeProfiles(): Observable<IAuthResponse> {
        return this.updateList(
            'viewedMe',
            (currentPage): Observable<IAuthResponse> => this.listService
                .downloadViewedMeProfiles(currentPage),
            (response): void => {
                this.listService.updateViewedMeProfiles(response.data);
            },
        );
    }

    protected updateRecommendedList() {
        return this.updateList(
            'recommended',
            (currentPage) => this.listService.downloadRecommendedProfiles(currentPage),
            (response) => this.listService.updateRecommendedProfiles(response.data),
        );
    }

    protected updateMyPrivatePhotosAccess(): Observable<IAuthResponse> {
        return this.updateList(
            'my-private-photos-access',
            (currentPage): Observable<IAuthResponse> => this.profileService
                .downloadMyAlbumAccess(currentPage),
            (response): void => {
                this.listService.updateMyPrivatePhotosAccess(response.data);
            },
        );
    }

    protected updateConversations(): Observable<IAuthResponse> {
        return this.updateList(
            'conversations',
            (currentPage: number): Observable<IAuthResponse> =>
                this.conversationService.downloadConversations(currentPage),
            (response: IAuthResponse): void => {
                const conversations: IConversation[] = response.data;
                if (!conversations.every((conversation): boolean => conversation !== undefined)) {
                    throw new Error(JSON.stringify({
                        message: 'Invalid response from the API. Null conversations were sent.',
                        content: response,
                    }));
                }

                this.store.dispatch(
                    ConversationActions.loadConversations(
                        { conversations: (<IConversation[]>response.data) },
                    ),
                );
            },
        );
    }

    protected updateConversationsFavorites(): Observable<IAuthResponse> {
        return this.updateList(
            'conversations-favorites',
            (currentPage: number): Observable<IAuthResponse> =>
                this.conversationService.downloadFavoritedConversations(currentPage),
            (response: IAuthResponse): void => {
                const conversations: IConversation[] = response.data;
                if (!conversations.every((conversation): boolean => conversation !== undefined)) {
                    throw new Error(JSON.stringify({
                        message: 'Invalid response from the API. Null conversations were sent.',
                        content: response,
                    }));
                }

                this.store.dispatch(
                    ConversationActions.loadConversations(
                        { conversations: (<IConversation[]>response.data) },
                    ),
                );
            },
        );
    }

    protected updateMessages(conversationId: number, page?: number): Observable<IAuthResponse> {
        return this.updateList(
            'messages',
            (currentPage): Observable<IAuthResponse> => this.messageService.downloadMessages(
                conversationId,
                currentPage,
            ),
            (response): void => {
                this.messageService.updateMessages((<any>Object).values(response.data));
            },
            conversationId,
            page,
        );
    }

    protected updateMessagesRecent(lastMessageId: number): Observable<IAuthResponse> {
        return this.updateList(
            'messagesRecent',
            (currentPage): Observable<IAuthResponse> => this.messageService.downloadMessagesRecent(
                lastMessageId,
                currentPage,
            ),
            (response): void => {
                const messages = response.data.map((message): Message => {
                    return new Message(
                        message.message_id,
                        message.created_at,
                        message.sender_id,
                        message.recipient_id,
                        message.text,
                    );
                });

                this.messageService.updateMessagesRecent(messages);
                if (!this.isAtLastPage('messagesRecent', lastMessageId)) {
                    this.updateMessagesRecent(lastMessageId);
                }
            },
            lastMessageId,
        );
    }

    protected updateProfile(profileId: number): Observable<IAuthResponse> {
        return this.updateList(
            'profiles',
            (): Observable<IAuthResponse> => this.profileService.downloadProfile(profileId),
            (response): void => {
                this.profileService.updateProfile(response.data);
            },
            profileId,
        );
    }

    protected updateBulkSwipeProfiles(profileIds: number[]): Observable<IAuthResponse> {
        return this.updateList(
            'bulkSwipeProfiles',
            (): Observable<IAuthResponse> => this.profileService.downloadBulkSwipeProfiles(profileIds),
            (response): void => {
                this.profileService.updateBulkProfiles(response.data);
            },
        );
    }

    protected updateBulkProfiles(profileIds: number[]): Observable<IAuthResponse> {
        return this.updateList(
            'bulkProfiles',
            (): Observable<IAuthResponse> => this.profileService.downloadBulkProfiles(profileIds),
            (response): void => {
                this.profileService.updateBulkProfiles(response.data);
            },
        );
    }

    protected updateBulkMessages(messageIds: number[]): Observable<IAuthResponse> {
        return this.updateList(
            'bulkMessages',
            (): Observable<IAuthResponse> => this.messageService.downloadBulkMessages(messageIds),
            (response): void => {
                this.messageService.updateMessages(response.data);
            },
        );
    }

    protected updateSearch(searchParameters: any): Observable<IAuthResponse> {
        return this.updateList(
            'search',
            (currentPage): Observable<IAuthResponse> => this.searchService.search(
                searchParameters,
                currentPage,
            ),
            (response): void => {
                this.listService.updateSearch(response.data);
            },
        );
    }

    protected updateSavedSearch(_searchParameters: any): Observable<IAuthResponse> {
        return this.updateList(
            'savedSearch',
            (currentPage): Observable<IAuthResponse> =>
                this.searchService.getSavedSearches(currentPage),
            (response): void => {
                this.searchService.holdSavedSearch(response.data);
            },
        );
    }

    protected updateList(
        listName: ListName,
        observable: (currentPage: number) => Observable<IAuthResponse>,
        success: (response: IAuthResponse) => void,
        subParam?: number,
        page?: number,
    ): Observable<IAuthResponse> {
        const list: IListState = this.getList(listName, subParam);
        let currentPage: number;

        if (page === undefined || list.currentPage === 0) {
            currentPage = list.currentPage + 1;
        }

        this.log(true, listName, subParam);
        this.dispatchSetIsDownloadingLists(true);
        list.downloading = true;

        return this.handleUpdatedList({
            listName,
            observable,
            success,
            list,
            currentPage,
            subParam,
            page,
        });
    }

    protected handleUpdatedList({
        listName,
        observable,
        success,
        list,
        currentPage,
        subParam,
        page,
    }: IUpdateListParams): Observable<IAuthResponse> {
        let pageToUpdate: number = page;

        if (page === undefined) {
            pageToUpdate = currentPage;
        }

        return observable(pageToUpdate).pipe(
            catchError((err): Observable<never> => {
                list.downloading = false;

                return throwError(err);
            }),
            tap((response): void => {
                if (!list.downloading) {
                    return;
                }

                this.dispatchSetIsDownloadingLists(false);
                list.downloading = false;

                if (response) {
                    this.updateListData(
                        list,
                        {
                            currentPage,
                            lastPage: response.last_page,
                            totalItems: response.total,
                        },
                        page,
                    );
                    this.log(false, listName, subParam);
                    success(response);
                }

                this.serializeLists();
            }),
        );
    }

    protected dispatchSetIsDownloadingLists(
        isDownloadingLists: boolean,
    ): void {
        this.store.dispatch(
            UiActions.setIsDownloadingLists({
                isDownloadingLists,
            }),
        );
    }

    protected updateListData(list: IListState, listUpdatedData: IRequestUpdatedListData, page?: number): void {
        if (page === undefined || list.currentPage === 0) {
            list.currentPage = listUpdatedData.currentPage;
        }

        list.lastPage = listUpdatedData.lastPage;
        list.totalItems = listUpdatedData.totalItems;
    }

    protected getList(listName: ListName, subParam?: number): IListState {
        if (this.listIsMap(listName)) {
            this.initializeListMapIfNeeded(listName, subParam);
            return this.pageCount[listName][subParam];
        }

        return this.pageCount[listName];
    }

    protected initializeListMapIfNeeded(listName: ListName, subParam: number): void {
        if (this.pageCount[listName][subParam]) {
            return;
        }

        this.pageCount[listName][subParam] = { ...EMPTY_PAGE };
    }

    protected abstract log(
        started: boolean,
        listName: ListName,
        subParam?: number
    ): void;

    protected listIsMap(listName: ListName): listName is 'messages' | 'messagesRecent' | 'profiles' {
        return listName === 'messages' ||
            listName === 'messagesRecent' ||
            listName === 'profiles';
    }

    protected serializeLists(): void {
        this.store.dispatch(new DownloadManagerUpdatedAction(this.pageCount));
    }
}
