import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IMessageToastState, messageToastAdapter } from '@libs/store/message-toast/state';

const selectFeature = createFeatureSelector<IMessageToastState>('messageToast');

export const selectAll = createSelector(
    selectFeature,
    messageToastAdapter.getSelectors().selectAll,
);
