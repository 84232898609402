<div
    class="modal-dialog"
    *ngIf="cropperImage"
>
    <div class="modal-content">
        <div class="modal-body">
            <lib-mp-cropperjs
                #angularCropper
                [cropperOptions]="cropperOptions"
                [imageUrl]="cropperImage"
            ></lib-mp-cropperjs>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-sm btn-link"
                [title]="'common.close' | translate"
                [disabled]="sendingPhoto"
                (click)="closeModal()"
            >
                <i
                    class="material-icons"
                    aria-hidden="true"
                >
                    &#xE5CD;
                </i>
            </button>
            <button
                class="btn btn-sm btn-link"
                [title]="'common.image_cropper.rotate_left' | translate"
                [disabled]="sendingPhoto"
                (click)="rotateCrop(-90)"
            >
                <i
                    class="material-icons"
                    aria-hidden="true"
                >
                    &#xE419;
                </i>
            </button>
            <button
                class="btn btn-sm btn-link"
                [title]="'common.image_cropper.rotate_right' | translate"
                [disabled]="sendingPhoto"
                (click)="rotateCrop(90)"
            >
                <i
                    class="material-icons"
                    aria-hidden="true"
                >
                    &#xE41A;
                </i>
            </button>
            <button
                class="btn btn-sm btn-link"
                [title]="'common.submit' | translate"
                [disabled]="sendingPhoto"
                (click)="prepareUploadPhoto()"
            >
                <i
                    class="material-icons"
                    aria-hidden="true"
                >
                    &#xE2C6;
                </i>
            </button>
        </div>
    </div>
</div>
