import { Injectable } from '@angular/core';

import {
    ToastContextCommon,
} from '@libs/modules/main/toast/strategy/toast-context.common';

@Injectable({
    providedIn: 'root',
})
export class ToastContext extends ToastContextCommon {
    //
}
