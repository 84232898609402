import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import { IReferralStatus } from '@libs/shared/referral/referral-status';
import {
    IReferralStatusState, referralStatusAdapter,
} from '@libs/store/referral-status/state';

export const selectReferralStatusState =
    createFeatureSelector<IReferralStatusState>('storeReferralStatusEntity');

export const selectAll = createSelector(
    selectReferralStatusState,
    referralStatusAdapter.getSelectors().selectAll,
);

export const selectFirst = createSelector(
    selectAll,
    (state): IReferralStatus => {
        if (state[0] !== undefined) {
            return state[0];
        }

        return null;
    },
);
