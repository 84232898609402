import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IDecrementResponse, ILatestBoost } from '@libs/modules/boost/interface/boost-bff-endpoints';
import { BoostServiceCommon } from '@libs/modules/boost/services/boost/boost.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';

import { boostBFF } from '@prince/modules/boost/helpers/boost-endpoints';
import { BoostProgressService } from '@prince/modules/shared/boost-shared/services/boost-progress/boost-progress.service';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { DownloadManagerService } from '@prince/services/download-manager.service';
import { ProfileService } from '@prince/services/profile.service';

@Injectable({
    providedIn: 'root',
})
export class BoostService extends BoostServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected downloadManagerService: DownloadManagerService,
        protected profileService: ProfileService,
        protected boostProgressService: BoostProgressService,
        protected authHttp: AuthHttpService,
        protected auth: AuthenticationService,
    ) {
        super(
            store,
            downloadManagerService,
            profileService,
            boostProgressService,
            authHttp,
            auth,
        );
    }

    public getLatestBoostActivation$(): Observable<ILatestBoost> {
        return this.authHttp
            .get(boostBFF.latestBoost)
            .pipe(
                map((authResponse: IAuthResponse<ILatestBoost>): ILatestBoost => authResponse.data),
            );
    }

    public decrementBoostCredit(): Observable<IDecrementResponse> {
        return this.authHttp.post(boostBFF.boostUser, { })
            .pipe(
                map((authResponse: IAuthResponse<IDecrementResponse>): IDecrementResponse => authResponse.data),
            );
    }
}
