import { Directive, Input } from '@angular/core';

@Directive()
export class BoostActivatedCardComponentCommon {
    @Input() title: string = '';
    protected readonly ICON_SHOW_UP_MODIFIER: string = 'boost-icon--show-up';

    getIconClasses(): string {
        return this.ICON_SHOW_UP_MODIFIER;
    }
}
