import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { IProfile } from '@libs/shared/profile/profile';

export interface IProfilesState extends EntityState<IProfile> {
    //
}

export const profilesEntityAdapter = createEntityAdapter<IProfile>({
    selectId: (profile: IProfile) => profile.profile_id,
    sortComparer: (profile1: IProfile, profile2: IProfile) => {
        return profile1.profile_id > profile2.profile_id ? 1 : -1;
    },
});

export const profilesEntitiesInitialState: IProfilesState = profilesEntityAdapter.getInitialState({
    //
});
