<div class="access-private-photos">
    <button
        class="access-private-photos__request-access"
        (click)="requestPrivatePhotoAccess()"
        *ngIf="isPrivatePhotoRequestInexistent()"
    >
        {{ 'modules.main.pages.profile.request_access' | translate }}
    </button>
    <div
        class="access-private-photos__pending-access"
        *ngIf="canShowPrivatePhotoRequestPending()"
    >
        {{ 'modules.main.pages.profile.photos_page.already_requested' | translate }}
    </div>
</div>
