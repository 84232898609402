import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IProfileAttributes } from '@libs/components/profile-preview/interfaces/profile-attributes.interface';
import { BoostLastViewersServiceCommon } from '@libs/modules/boost/services/boost-last-viewers/boost-last-viewers.service.common';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { BoostLastViewersSelectors } from '@libs/store/boost-last-viewers';
import { MeltProfilesSelectors } from '@libs/store/melt-profiles';

@Directive()
export class BadgesComponentCommon implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];
    protected meltProfileIds: number[] = [];
    protected boostLastViewersProfiles: IProfile[] = [];

    @Input() profile: IProfile | undefined;

    constructor(
        protected changeDetector: ChangeDetectorRef,
        protected store: Store<IApplicationState>,
        public translate: TranslateService,
        protected boostLastViewers: BoostLastViewersServiceCommon,
    ) {
        //
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.store
                .pipe(
                    select(MeltProfilesSelectors.selectMeltProfiles),
                    filter((): boolean => this.profile !== undefined),
                ).subscribe((profileIds: number[]): void => {
                    this.meltProfileIds = profileIds;
                    this.changeDetector.markForCheck();
                }),

            this.store
                .pipe(
                    select(BoostLastViewersSelectors.selectAll),
                ).subscribe((boostLastViewers: IProfile[]): void => {
                    this.boostLastViewersProfiles = boostLastViewers;
                    this.changeDetector.markForCheck();
                }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public isElite(): boolean {
        return UserCommon.isElite(this.profile);
    }

    public isVerified(): boolean {
        return UserCommon.isPhotoVerified(this.profile);
    }

    public isPremium(): boolean {
        return UserCommon.isPremium(this.profile);
    }

    public isNew(): boolean {
        return UserCommon.isNew(this.profile);
    }

    public isMelt(): boolean {
        return this.profile !== undefined &&
            this.meltProfileIds.indexOf(this.profile.profile_id) !== -1;
    }

    protected getGender(): number {
        return this.profile !== undefined && this.profile.sex;
    }

    public sawMeInLastBoost(): boolean {
        return this.boostLastViewers.sawMeInLastBoost(this.boostLastViewersProfiles, this.profile);
    }

    protected getTranslate(attribute: IProfileAttributes): string {
        return this.translate.instant(
            attribute.imageAlt,
            attribute.interpolateParams,
        );
    }

    public hadPreviousMembershipPurchase(): boolean {
        return (
            this.profile !== undefined &&
            this.profile.had_previous_membership_purchase === 'yes'
        );
    }

    public hadPreviousMembershipPurchaseAndIsCurrentlyFree(): boolean {
        return this.profile !== undefined &&
        (
            MembershipCommon.isFree(this.profile.membership_type_id) &&
            this.hadPreviousMembershipPurchase()
        );
    }

    public canShowDiamondBadge(): boolean {
        return (
            this.isPremium() ||
            this.hadPreviousMembershipPurchaseAndIsCurrentlyFree()
        );
    }
}
