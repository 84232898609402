import { Action } from '@ngrx/store';

export const SHOW_HIDE_FEEDBACK_NAGBAR_ACTION: string = 'SHOW_HIDE_FEEDBACK_NAGBAR_ACTION';

export class ShowHideFeedbackNagbarAction implements Action {
    readonly type: string = SHOW_HIDE_FEEDBACK_NAGBAR_ACTION;

    constructor(public payload: boolean) {
        //
    }
}
