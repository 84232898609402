import { Action } from '@ngrx/store';

export const PROFILE_ALBUM_ACCESS_DELETE_REMOVE: string = 'PROFILE_ALBUM_ACCESS_DELETE_REMOVE';

export interface IProfileIdsAlbumAccessDeleteRemoveActionPayload {
    profileAlbumAccessToDelete: number[];
}

export class AlbumAccessDeleteRemoveAction implements Action {
    readonly type: string = PROFILE_ALBUM_ACCESS_DELETE_REMOVE;

    constructor(public payload: IProfileIdsAlbumAccessDeleteRemoveActionPayload) {
        //
    }
}
