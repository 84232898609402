import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { LoginActions } from '@libs/store/login';
import { UiActions } from '@libs/store/new-ui';

import { UserService } from '@prince/services/user.service';

@Injectable()
export class LoginEffects {
    handleLoginForgotPassword$: Observable<Action> = createEffect(
        (): Observable<Action> => this.actions$.pipe(
            ofType(LoginActions.forgotPassword),
            concatMap(({ email }: { email: string }): Observable<Action> => {
                this.startSendingRequest();

                return this.userService.forgotPassword(email).pipe(
                    map((): Action => {
                        return LoginActions.forgotPasswordEmailSent();
                    }),
                    catchError((): Observable<Action> => {
                        this.setIsAttemptingLoginAsFalse();

                        return of(
                            LoginActions.forgotPasswordEmailNotFound({
                                emailNotFound: true,
                            }),
                        );
                    }),
                );
            }),
        ),
        { dispatch: true, useEffectsErrorHandler: false },
    );

    constructor(
        protected actions$: Actions,
        protected store: Store,
        protected userService: UserService,
    ) {
        //
    }

    protected startSendingRequest(): void {
        this.store.dispatch(
            LoginActions.forgotPasswordSendingRequest({
                sendingRequest: true,
            }),
        );
    }

    protected setIsAttemptingLoginAsFalse(): void {
        this.store.dispatch(
            UiActions.setIsAttemptingLogin({
                isAttemptingLogin: false,
            }),
        );
    }
}
