import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ChangePasswordComponentCommon } from '@libs/modules/initial/change-password/change-password.component.common';

import { UserService } from '@prince/services/user.service';

@Component({
    selector: 'mp-initial-change-password',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './change-password.html',
})
export class InitialChangePasswordComponent extends ChangePasswordComponentCommon implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    constructor(
        public router: Router,
        protected route: ActivatedRoute,
        protected userService: UserService,
    ) {
        super(userService);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.route.params
            .subscribe((params): void => this.verifyHash(params.hash)),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    redirectSuccess(): void {
        this.router.navigate(['initial', 'new-password']);
    }

    redirectError(): void {
        this.router.navigate(['initial', 'hash-unavailable']);
    }

    redirectHashExpired(): void {
        this.router.navigate(['initial', 'hash-expired']);
    }
}
