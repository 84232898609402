import { Directive, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { IApplicationState } from '@libs/store/application-state';
import { selectSelectedProvider } from '@libs/store/payment/selectors';

@Directive()
export abstract class ProviderSelectorComponentCommon {
    @Input() price: number;
    @Input() time: number;
    @Input() type: number;

    constructor(
        protected store: Store<IApplicationState>,
    ) {
        //
    }

    public isPagSeguro$(): Observable<boolean> {
        return this.getProvider$().pipe(
            map(
                (provider: string): boolean => {
                    return provider === PaymentCommon.PROVIDER_PAGSEGURO;
                },
            ),
        );
    }

    public isAllCash$(): Observable<boolean> {
        return this.getProvider$().pipe(
            map(
                (provider: string): boolean => {
                    return provider === PaymentCommon.PROVIDER_ALLCASH;
                },
            ),
        );
    }

    protected getProvider$(): Observable<string> {
        return this.store.pipe(
            select(selectSelectedProvider),
        );
    }
}
