<mp-allcash-boleto
    [time]="time"
    [price]="price"
    [type]="type"
    *ngIf="isAllCash$() | async"
></mp-allcash-boleto>
<mp-moip-boleto
    [time]="time"
    [price]="price"
    [type]="type"
    *ngIf="isPagSeguro$() | async"
></mp-moip-boleto>
