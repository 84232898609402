import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';

import {
    AnalyticsEvent,
    AnalyticsServiceCommon,
} from '@libs/services/analytics/analytics.service.common';
import { LanguageModel } from '@libs/shared/language/language-model';
import {
    MembershipCommon,
    MembershipType,
} from '@libs/shared/membership/membership.common';
import { IStats } from '@libs/shared/user/stats';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { IPaymentInfo } from '@libs/store/payment-info';

import { Config } from '@prince/config';
import { BoostProductService } from '@prince/services/boost-product/boost-product.service';
import { GlobalObjectService } from '@prince/services/global-object-service';

import { CookieService } from 'ngx-cookie-service';
@Injectable({
    providedIn: 'root',
})
export class AnalyticsService extends AnalyticsServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected router: Router,
        protected cookieService: CookieService,
        protected boostProductService: BoostProductService,
        public globalObjectReference: GlobalObjectService,
    ) {
        super(
            store,
            router,
            cookieService,
            globalObjectReference,
        );

        this.onLoaded();

        this.subscriptions.push(combineLatest(
            this.store.select('user'),
            this.store.select('stats'),
        ).pipe(
            distinctUntilChanged((
                [user1, stats1]: [UserCommon, IStats],
                [user2, stats2]: [UserCommon, IStats],
            ): boolean =>
                JSON.stringify(user1) === JSON.stringify(user2) &&
                JSON.stringify(stats1) === JSON.stringify(stats2),
            ),
            filter(([user, stats]: [UserCommon, IStats]): boolean =>
                stats.profile_id !== undefined &&
                user !== undefined &&
                user.profile_extended !== undefined &&
                stats.numberOfMessagesReceived !== undefined,
            ),
            tap(([user, stats]: [UserCommon, IStats]): void => {
                this.updateUser(user, stats);
            }),
        ).subscribe());

        this.subscriptions.push(
            this.router.events.subscribe((event: RouterEvent): void => {
                if (event instanceof NavigationEnd) {
                    this.routeLoaded(event);
                }
            }),
        );
    }

    public push(content: AnalyticsEvent): void {
        if (Config.GOOGLE_TAG_MANAGER_ID === '' || this.window === undefined) {
            return;
        }

        this.window.dataLayer.push(content);
    }

    public onLoaded(): void {
        const EVENT_NAME: string = 'app_open';

        this.push({
            event: EVENT_NAME,
            app_version: Config.version,
        });
    }

    public updateUser(user: UserCommon, stats: IStats): void {
        const EVENT_NAME: string = 'userUpdated';

        this.push({
            event: EVENT_NAME,
            app_version: Config.version,
            ...this.getUserData(user, stats),
        });
    }

    public get userLanguage(): string {
        let language: string = LanguageModel.LANGUAGE_PT_BR.lang;

        try {
            language = this.cookieService.get('lang');

            if (language === '') {
                language = LanguageModel.LANGUAGE_PT_BR.lang;
            }
        } catch {
            language = LanguageModel.LANGUAGE_PT_BR.lang;
        }

        return language;
    }

    public onPayment(
        paymentInfo: IPaymentInfo,
        membershipId: MembershipType,
        boostProductUuid: string | null,
    ): void {
        if (!this.boostProductService.isBoostPackagePayment(paymentInfo, boostProductUuid)) {
            this.onMembershipPayment(paymentInfo.subtotal_amount, membershipId);
            return;
        }

        this.onBoostPayment(paymentInfo.subtotal_amount);
    }

    onMembershipPayment(
        price: number,
        membershipId: MembershipType,
    ): void {
        const EVENT_NAME: string = MembershipCommon.getMachineMembership(membershipId) + '_member';

        this.push({
            transaction_total: price,
            event: EVENT_NAME,
        });
    }

    onBoostPayment(price: number): void {
        const EVENT_NAME: string = 'boost_purchase';

        this.push({
            event: EVENT_NAME,
            boost_transaction_purchase: price,
        });
    }
}
