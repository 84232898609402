<div [@CharmModal] class="charm-modal">
    <div class="charm-modal__box">
        <div class="charm-modal__branding">
            <img [src]="getCharmImagePath()" alt="charm">
        </div>
        <div class="charm-modal__container">
            <div class="charm-modal__container__liked-user">
                <img
                    [src]="getUserPicture(modalData.favoritedUser)"
                    class="charm-modal__container__liked-user__image"
                />
                <p
                    class="charm-modal__container__liked-user__text"
                    [innerHTML]="'modules.main.charm.modal.liked_user_text'
                        | translate: { user: modalData.favoritedUser.username, sex: modalData.favoritedUser.sex }"
                ></p>
            </div>
            <div class="charm-modal__container__footer">
                <button
                    (click)="startConversation(modalData.favoritedUser)"
                    class="charm-modal__container__footer__send-message"
                >
                    {{ 'modules.main.charm.modal.send_message' | translate }}
                </button>
                <span
                    (click)="closeModal()"
                    class="charm-modal__container__footer__not-now"
                >
                    {{ 'modules.main.charm.modal.not_now' | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
