import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'button-with-loading',
    templateUrl: 'button-with-loading.html',
    styleUrls: ['button-with-loading.scss'],
})
export class ButtonWithLoadingComponent implements OnInit {
    @ViewChild('buttonWithLoading', { static: true }) buttonWithLoading: ElementRef;

    @Input() id: string = '';
    @Input() form: FormGroup = new FormGroup({ });
    @Input() controlName: string = '';
    @Input() description: string = '';
    @Input() isLoading: boolean = false;
    @Input() theme: string = '';
    @Input() isDisabled: boolean = false;
    @Input() descriptionWhileLoading: string = '';

    constructor(
        protected renderer2: Renderer2,
    ) {

    }

    ngOnInit(): void {
        this.renderer2.addClass(
            this.buttonWithLoading.nativeElement,
            this.theme,
        );
    }

    public hasDescriptionWhileLoading(): boolean {
        return this.descriptionWhileLoading !== '';
    }

    public getBackgroundColorModifierClass(): string {
        if (this.isDisabled) {
            return 'button-with-loading--deactivated';
        }

        return '';
    }
}
