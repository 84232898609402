<button
    class="help-tooltip-btn hidden-md hidden-lg"
    tabindex="0"
    (click)="showInfo($event)"
>
    <span
        class="material-icons"
        aria-hidden="true"
    >
        &#xE887;
    </span>
    <span class="sr-only">{{ 'common.help' | translate }}</span>
</button>
<span class="popover-container hidden-xs hidden-sm">
    <button class="help-tooltip-btn">
        <span
            class="material-icons"
            aria-hidden="true"
        >
            &#xE887;
        </span>
        <span class="sr-only">{{ 'common.help' | translate }}</span>
    </button>
    <div
        class="popover top in"
        role="tooltip"
    >
        <h3 class="popover-title">{{ title }}</h3>
        <div
            class="popover-content"
            [innerText]="content"
        ></div>
    </div>
</span>
