<div
    class="boost-running-nagbar"
    (click)="navigateToBoost()"
    *ngIf="!hasBoostEnded(hasBoostEnded$ | async)"
>
    <mp-boost-activated-card
        [title]="'modules.main.shared.boost_activated.title'"
    >
    </mp-boost-activated-card>
</div>
