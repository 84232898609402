import { Action, ActionReducer } from '@ngrx/store';

import { IApplicationState, initialValue } from '@libs/store/application-state';
import { CLEAR_ALL_ACTION } from '@libs/store/ui/actions/clear-all.action';

import { Config } from '@prince/config';

const STORE_IMAGE_REGISTRATION_REDUCER: string = 'storeImageOnRegistration';
const STORE_PENDING_MESSAGES_REDUCER: string = 'storePendingMessageEntities';

const NONPERSISTENT_REDUCERS: readonly string[] = [
    STORE_IMAGE_REGISTRATION_REDUCER,
    STORE_PENDING_MESSAGES_REDUCER,
] as const;

const loginTokenName: string = 'mp-login-token' as const;

let saveBlocked = false;

export function metaReducer(reducer: ActionReducer<IApplicationState>): any {
    return (state: IApplicationState, action: Action): any => {
        const started = new Date();

        if (action.type === '@ngrx/store/init') {
            state = load();
        }

        if (action.type === CLEAR_ALL_ACTION) {
            state = initialValue;
            clear();
        }

        const result = reducer(state, action);

        save(result);
        log(action, started);

        return result;
    };
}

function load(): IApplicationState | null {
    const tokenData: string = localStorage.getItem(loginTokenName);
    const data: string = localStorage.getItem(Config.reduxName);

    let state: IApplicationState = initialValue;

    if (data !== null) {
        state = JSON.parse(data);
    }

    if (tokenData !== null) {
        state.token = JSON.parse(tokenData);
    }

    return state;
}

function save(data: IApplicationState): void {
    if (saveBlocked) {
        return;
    }

    try {
        if (data !== null) {
            localStorage.setItem(loginTokenName, JSON.stringify(data.token));
        }

        localStorage.setItem(Config.reduxName, JSON.stringify(data, replacer));
    } catch (_) {
        onSaveFailed();
    }
}

function onSaveFailed(): void {
    saveBlocked = true;

    clear();
    save(initialValue);

    setTimeout((): void => {
        window.location.reload();
    }, 100);
}

function clear(): void {
    localStorage.clear();
}

function replacer(key: string, value: unknown): unknown {
    if (NONPERSISTENT_REDUCERS.findIndex(
        (reducer: string): boolean => key === reducer) !== -1
    ) {
        return undefined;
    }

    return value;
}

function log(action: Action, started: Date): void {
    if (!Config.showLogs.redux) {
        return;
    }

    const ended = new Date();
    const duration = ended.getTime() - started.getTime();
    const timeString = started.getHours() + ':'
        + started.getMinutes() + ':'
        + started.getSeconds() + '.'
        + started.getMilliseconds();

    const totalLength = (JSON.stringify(action).length / 1024).toFixed(2);
    console.log('[' + timeString + '] REDUX --> ' + action.type + '. ' + totalLength + ' Kb after ' + duration + 'ms.');
}
