import { Directive } from '@angular/core';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { UserCommon } from '@libs/shared/user/user.common';

@Directive()
export class SubscribedStatusComponentCommon {
    constructor(
        protected auth: AuthenticationServiceCommon,
    ) {
        //
    }

    protected get user(): UserCommon {
        return this.auth.get();
    }

    public isFree(): boolean {
        return MembershipCommon.isFree(this.user.membership_type_id);
    }
}
