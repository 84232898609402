import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { BoostBubblesComponentCommon } from '@libs/modules/boost/components/boost-bubbles/boost-bubbles.component.common';
import { IBaseProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import {
    BoostLastViewersService,
} from '@prince/modules/shared/boost-shared/services/boost-last-viewers/boost-last-viewers.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ImageHelper } from '@prince/utils/image-helper';

@Component({
    selector: 'mp-boost-bubbles',
    templateUrl: './boost-bubbles.html',
})
export class BoostBubblesComponent extends BoostBubblesComponentCommon {
    constructor(
        protected authenticationService: AuthenticationService,
        protected boostLastViewersService: BoostLastViewersService,
        protected store: Store<IApplicationState>,
    ) {
        super(
            authenticationService,
            boostLastViewersService,
            store,
        );
    }

    getProfilePicture(profile: IBaseProfile): string {
        return ImageHelper.getProfilePicture(
            profile,
            this.authenticationService.get(),
            UserCommon.PICTURE_SMALL,
        );
    }
}
