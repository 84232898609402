import { createAction, props } from '@ngrx/store';

import { IReason } from '@libs/store/deactivate-reason/interface';

export const UPSERT_DEACTIVATE_REASONS: string = '[Deactivate] - Upsert Deactivate Reasons';

export const SET_CURRENT_DEACTIVATE_REASON: string = '[Deactivate] - Set Current Deactivate Reason';

export const upsertDeactivateReasons = createAction(
    UPSERT_DEACTIVATE_REASONS,
    props<{ reasons: IReason[] }>(),
);

export const setCurrentDeactivateReason = createAction(
    SET_CURRENT_DEACTIVATE_REASON,
    props<{ currentReasonTextSelected: string | null }>(),
);
