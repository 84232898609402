import { Injectable } from '@angular/core';

import { ICropperBuildParameters } from '@libs/integration/mp-cropperjs/lib/interfaces/cropper-build-parameters.interface';
import { ICropperResult } from '@libs/integration/mp-cropperjs/lib/interfaces/cropper-result.interface';
import { CropperBuilder } from '@libs/integration/mp-cropperjs/lib/utils/cropper.builder';

@Injectable({
    providedIn: 'root',
})
export class CropperjsService {
    public cropperBuilder: CropperBuilder = { } as CropperBuilder;

    public getNewCropperResult({
        imageElement,
        cropperOptions,
        callbackListener,
        imageResolutionSettings,
        cropper,
        cropbox,
    }: ICropperBuildParameters): ICropperResult {
        this.cropperBuilder = new CropperBuilder(
            imageElement,
            cropperOptions,
            imageResolutionSettings,
            cropper,
            cropbox,
        );

        this.cropperBuilder.resetCropper();
        this.cropperBuilder.addCrossOrigin();
        this.cropperBuilder.listenToReadyEvent(callbackListener);
        this.cropperBuilder.setupCropperOptions();
        this.cropperBuilder.createCropperInstance();

        const cropperResult: ICropperResult = this.cropperBuilder.getResult();

        return cropperResult;
    }

    public removeEventListener(): void {
        if (
            !this.cropperBuilder ||
            Object.keys(this.cropperBuilder).length === 0
        ) {
            return;
        }

        this.cropperBuilder.removerListenerToReadyEvent();
    }
}
