import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
    BoostRunningNagbarComponentCommon,
} from '@libs/modules/boost/components/boost-running-nagbar/boost-running-nagbar.component.common';
import { IApplicationState } from '@libs/store/application-state';

import { BoostProgressService } from '@prince/modules/shared/boost-shared/services/boost-progress/boost-progress.service';
import { BoostService } from '@prince/modules/shared/boost-shared/services/boost/boost.service';

@Component({
    selector: 'mp-boost-running-nagbar',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './boost-running-nagbar.html',
})
export class BoostRunningNagbarComponent extends BoostRunningNagbarComponentCommon {
    constructor(
        protected boostService: BoostService,
        protected boostProgressService: BoostProgressService,
        protected store: Store<IApplicationState>,
        protected router: Router,
    ) {
        super(
            boostService,
            boostProgressService,
            store,
            router,
        );
    }
}
