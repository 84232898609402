import { createAction, props } from '@ngrx/store';

import { IRegistrationAboutYou } from '@libs/registration-prince/domain/interfaces/registration-about-you.interface';
import { IRegistrationInitialStep } from '@libs/registration-prince/domain/interfaces/registration-initial-step.interface';
import { IRegistrationLoginData } from '@libs/registration-prince/domain/interfaces/registration-login-data.interface';
import { IRegistrationPersonalData } from '@libs/registration-prince/domain/interfaces/registration-personal-data.interface';

enum Actions {
    SET_IF_TRIED_TO_ACCESS_MODAL_ROUTE = '[Registration / Prince] - Set if user tried to access modal internal route',
    OPEN_MODAL_AND_NAVIGATE = '[Registration / Prince] - Open Modal and Navigate',
    CLOSE_MODAL = '[Registration / Prince] - Close Modal',
    CLEAR_PASSWORD = '[Registration / Prince] - Clear Password',
    SAVE_INITIAL_STEP = '[Registration / Prince] - Save Initial Step',
    SAVE_LOGIN_DATA = '[Registration / Prince] - Save Login Data',
    SAVE_PERSONAL_DATA = '[Registration / Prince] - Save Personal Data',
    SAVE_ABOUT_YOU = '[Registration / Prince] - Save About You',
    SET_IS_CROPPING_IMAGE = '[Registration / Prince] - Set Is Cropping Image',
    SET_IS_SUBMITTING_FORM = '[Registration / Prince] - Set Is Submitting Form',
    START_SUBMISSION = '[Registration / Prince] - Start Submission',
    SUBMIT_REGISTRATION = '[Registration / Prince] - Submit Registration',
    SUBMISSION_FAILED = '[Registration / Prince] - Submission Failed',
    CHECK_IF_REGISTRATION_SUCCEEDED = '[Registration / Prince] - Check if registration succeeded',
    NOTIFY_ANALYTICS_ON_SUCCESS = '[Registration / Prince] - Notify Analytics On Success',
    NOTIFY_ANALYTICS_ON_FAILURE = '[Registration / Prince] - Notify Analytics On Failure',
    AUTHENTICATE_ON_SUCCESS = '[Registration / Prince] - Authenticate on Success',
    REGISTRATION_SUCCESS = '[Registration / Prince] - Registration Success',
    RESET_TO_INITIAL_STATE = '[Registration / Prince] - Reset to Initial State',
    NAVIGATE_TO_CONFIRM_EMAIL = '[Registration / Prince] - Navigate to Confirm Email',
    CHANGE_SUBMITTING_FORM_TEXT = '[Registration / Prince] - Change Submitting Form Text',
    SET_HAS_SUBMITTED_FORM = '[Registration / Prince] - Set Has Submitted Form',
}

export const setIfTriedToAccessModalRoute = createAction(
    Actions.SET_IF_TRIED_TO_ACCESS_MODAL_ROUTE,
    props<{ hasAccessedModalRoute: boolean }>(),
);

export const openModalAndNavigate = createAction(
    Actions.OPEN_MODAL_AND_NAVIGATE,
    props<{ route: string[] }>(),
);

export const closeModalAndNavigate = createAction(
    Actions.CLOSE_MODAL,
    props<{ route: string[] }>(),
);

export const clearPassword = createAction(
    Actions.CLEAR_PASSWORD,
);

export const saveRegistrationInitialStep = createAction(
    Actions.SAVE_INITIAL_STEP,
    props<{ initialStep: IRegistrationInitialStep }>(),
);

export const saveLoginData = createAction(
    Actions.SAVE_LOGIN_DATA,
    props<{ loginData: IRegistrationLoginData }>(),
);

export const savePersonalData = createAction(
    Actions.SAVE_PERSONAL_DATA,
    props<{ personalData: IRegistrationPersonalData }>(),
);

export const setIsCroppingImage = createAction(
    Actions.SET_IS_CROPPING_IMAGE,
    props<{ isCroppingImage: boolean }>(),
);

export const saveAboutYou = createAction(
    Actions.SAVE_ABOUT_YOU,
    props<{ aboutYou: IRegistrationAboutYou }>(),
);

export const setIsSubmittingForm = createAction(
    Actions.SET_IS_SUBMITTING_FORM,
    props<{ isSubmittingForm: boolean }>(),
);

export const startSubmission = createAction(
    Actions.START_SUBMISSION,
);

export const submitRegistration = createAction(
    Actions.SUBMIT_REGISTRATION,
);

export const submissionFailed = createAction(
    Actions.SUBMISSION_FAILED,
);

export const checkIfRegistrationSucceeded = createAction(
    Actions.CHECK_IF_REGISTRATION_SUCCEEDED,
);

export const notifyAnalyticsOnSuccess = createAction(
    Actions.NOTIFY_ANALYTICS_ON_SUCCESS,
);

export const notifyAnalyticsOnFailure = createAction(
    Actions.NOTIFY_ANALYTICS_ON_FAILURE,
);

export const authenticateOnSuccess = createAction(
    Actions.AUTHENTICATE_ON_SUCCESS,
);

export const registrationSuccess = createAction(
    Actions.REGISTRATION_SUCCESS,
);

export const resetToInitialState = createAction(
    Actions.RESET_TO_INITIAL_STATE,
);

export const navigateToConfirmEmail = createAction(
    Actions.NAVIGATE_TO_CONFIRM_EMAIL,
);

export const changeSubmittingFormText = createAction(
    Actions.CHANGE_SUBMITTING_FORM_TEXT,
    props<{ translationKey: string }>(),
);

export const setHasSubmittedForm = createAction(
    Actions.SET_HAS_SUBMITTED_FORM,
    props<{ hasSubmittedForm: boolean }>(),
);
