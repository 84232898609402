import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
    IUnsubscribeActionItem,
} from '@libs/shared/interfaces/unsubscribe-action-item';
import {
    IUnsubscribeEmailResponse,
} from '@libs/shared/unsubscribe-email-response/unsubscribe-email-response.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';

@Directive()
export abstract class EmailUnsubscribeComponentCommon implements OnInit, OnDestroy {
    readonly loadingMessage: string = 'modules.initial.pages.email-unsubscribe.loading';
    readonly successHeaderTranslation: string =
        'modules.initial.pages.email-unsubscribe.title-success';
    readonly successDescriptionTranslation: string =
        'modules.initial.pages.email-unsubscribe.description-success';
    readonly errorHeaderTranslation: string =
        'modules.initial.pages.email-unsubscribe.action-error-title';
    readonly errorDescriptionTranslation: string =
        'modules.initial.pages.email-unsubscribe.action-error-description';
    readonly retryButtonTranslation: string =
        'modules.initial.pages.email-unsubscribe.action-error-btn';
    readonly INVALID_KEY_HTTP_RESPONSE: string = 'invalid.key';

    public mpWebLogo: string;
    public emailResponse: IUnsubscribeEmailResponse = { };
    public attemptsError: number = 0;
    public actionItems: IUnsubscribeActionItem[] = [];
    public isLoading: boolean = true;
    protected subscriptions: Subscription[] = [];
    protected key: string = '';
    protected hasRequestError: boolean = false;

    abstract redirectToNotifications: () => void;

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected userService: UserServiceCommon,
    ) {
        this.fillActionItems();
        this.resolveWebMpLogo();
    }

    abstract redirectInvalidKey(): void;

    ngOnInit(): void {
        this.subscriptions.push(this.route
            .paramMap.subscribe((params: ParamMap): void => {
                this.key = params.get('key');
                this.unsubscribeByKey();
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    unsubscribeByKey(): void {
        this.hasRequestError = false;
        this.isLoading = true;
        this.userService.unsubscribeEmailByCampaign(this.key)
            .subscribe((response: HttpResponse<IUnsubscribeEmailResponse>): void => {
                this.isLoading = false;
                this.emailResponse.obfuscated_email = response.body.obfuscated_email;
            },
            (errorResponse: HttpErrorResponse): void => {
                this.isLoading = false;
                this.attemptsError++;

                if (errorResponse &&
                    errorResponse.error &&
                    errorResponse.error.error === this.INVALID_KEY_HTTP_RESPONSE
                ) {
                    this.redirectInvalidKey();
                    return;
                }

                this.hasRequestError = true;
            },
            );
    }

    hasSuccessfullyUnsubscribed(): boolean {
        return !this.isLoading && !this.hasRequestError;
    }

    hasError(): boolean {
        return !this.isLoading && this.hasRequestError;
    }

    fillActionItems(): void {
        this.actionItems = [];

        this.actionItems.push({
            actionTranslation: 'modules.initial.pages.email-unsubscribe.btn-settings',
            definedNaviation: (): void => this.redirectToNotifications(),
        });
    }

    resolveWebMpLogo(): void {
        this.mpWebLogo = UserCommon.resolveImageName('meu-patrocinio-logo-v02', '.svg');
    }
}
