<div
    *ngFor="let alert of alerts"
    [class]="cssClass(alert)"
    [ngClass]="{'alert-dismissable': alert.canClose}"
>
    <p>{{ alert.message }}</p>
    <a
        class="alert-close"
        *ngIf="alert.canClose"
        (click)="removeAlert(alert)"
    >
        &times;
    </a>
</div>
