import { Directive } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PaymentBoletoComponentCommon } from '@libs/modules/main/pages/payment-boleto/payment-boleto.component.common';
import { PaymentServiceCommon } from '@libs/modules/main/services/payment/payment.service.common';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { UserCommon } from '@libs/shared/user/user.common';

import { AuthenticationService } from '@prince/services/authentication.service';
import { ModalService } from '@prince/services/modal.service';

@Directive()
export abstract class BoletoWebComponentCommon extends PaymentBoletoComponentCommon {
    constructor(
        protected paymentService: PaymentServiceCommon,
        protected translate: TranslateService,
        protected modalService: ModalService,
        protected authenticationService: AuthenticationService,
        protected paymentHelpersService: PaymentHelpersService,
    ) {
        super(
            translate,
            paymentHelpersService,
        );
    }

    protected get user(): UserCommon {
        return this.authenticationService.get();
    }

    whenCPFNotExist(): void {
        this.modalService.open(
            this.translate.instant('modules.main.pages.payment.cpf.error.missing'),
        );
    }

    whenCPFInvalidInput(): void {
        this.modalService.open(
            this.translate.instant('modules.main.pages.payment.cpf.error.invalid_input'),
        );
    }

    whenCPFInvalidNumber(): void {
        this.modalService.open(
            this.translate.instant('modules.main.pages.payment.cpf.error.invalid'),
        );
    }

    whenIsTrialPayment(): void {
        this.modalService.open(
            this.translate.instant('common.load_error'),
        );
    }

    getCPF(): string {
        return this.cpf.nativeElement.value.toString();
    }

    getInterest(): number {
        return this.BOLETO_INTEREST;
    }

    public isDaddyMommy(): boolean {
        return UserCommon.isDaddyMommy(this.user);
    }
}
