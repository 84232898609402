import { createFeatureSelector, createSelector } from '@ngrx/store';

import { viewToastAdapter, IViewToastState } from '@libs/store/view-toast/state';

const selectFeature = createFeatureSelector<IViewToastState>('viewToast');

export const selectAll = createSelector(
    selectFeature,
    viewToastAdapter.getSelectors().selectAll,
);
