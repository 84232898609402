import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, delay, retryWhen, takeWhile } from 'rxjs/operators';

import { AuthRequestFacadeCommon } from '@libs/modules/main/services/auth-request-facade/auth-request-facade.common';
import { IParametersForRequest } from '@libs/modules/main/services/auth-request-facade/interfaces/parameters-for-request.interface';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

import { AuthHttpService } from '@prince/services/auth-http.service';

@Injectable({
    providedIn: 'root',
})
export class AuthRequestFacade extends AuthRequestFacadeCommon {
    constructor(
        protected authHttp: AuthHttpService,
    ) {
        super(
            authHttp,
        );
    }

    public get({
        endpoint,
        data = { },
        delayInMilliseconds = this.REQUEST_RETRY_DELAY_IN_MILLISECONDS,
        attempts = this.REQUEST_RETRY_ATTEMPTS,
    }: IParametersForRequest): Observable<IAuthResponse> {
        return this.authHttp.get(endpoint, data).pipe(
            retryWhen((error: Observable<HttpErrorResponse>): Observable<unknown> => {
                return this.handleErrorRetries$(
                    error,
                    delayInMilliseconds,
                    attempts,
                );
            }),
            catchError((): Observable<never> => EMPTY),
        );
    }

    handleErrorRetries$(
        error: Observable<HttpErrorResponse>,
        delayInMilliseconds: number,
        attempts: number,
    ): Observable<HttpErrorResponse> {
        return error.pipe(
            delay(delayInMilliseconds),
            takeWhile((_, attempt: number): boolean => attempt < attempts),
        );
    }
}
