import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MessageServiceCommon } from '@libs/modules/main/services/message/message.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { Message } from '@libs/shared/message/message';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ConversationActions, IConversation } from '@libs/store/conversations';
import { IMessage } from '@libs/store/messages';

import { Config } from '@prince/config';
import { AnalyticsService } from '@prince/services/analytics/analytics.service';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ConversationService } from '@prince/services/conversation.service';

@Injectable()
export class MessageService extends MessageServiceCommon {
    constructor(
        protected auth: AuthenticationService,
        protected store: Store<IApplicationState>,
        protected authHttp: AuthHttpService,
        protected conversationService: ConversationService,
        protected analytics: AnalyticsService,
    ) {
        super(auth, store, authHttp, conversationService, analytics);
    }

    public downloadMessages(peerId: number, page: number = 1): Observable<IAuthResponse> {
        const endpoint = Config.serverIp + 'chat/conversation/peer/' + peerId + '/messages?page=' + page;

        return this.authHttp.get(endpoint);
    }

    public downloadBulkMessages(messageIds: number[]): Observable<IAuthResponse> {
        const query: string = messageIds.map(
            (messageId: number): string => 'ids[]=' + messageId,
        ).join('&');
        const endpoint = Config.serverIp + 'chat/messages/bulk?' + query;

        return this.authHttp.get(endpoint);
    }

    public downloadMessagesRecent(lastMessageId: number, page: number = 1): Observable<IAuthResponse> {
        if (lastMessageId === 0 || lastMessageId === undefined) {
            return of();
        }

        const endpoint = Config.serverIp + 'conversations/messages/' + lastMessageId + '/recent?page=' + page;

        return this.authHttp.get(endpoint);
    }

    public sendMessage(
        profileId: number,
        messageText: string,
        uuid: string,
        conversation?: IConversation,
    ): Observable<IMessage> {
        const endpoint: string = Config.serverIp + 'conversations/' + profileId + '/messages';
        const data = {
            text: messageText,
            uuid,
        };
        const user: UserCommon = this.auth.get();

        let initiatorId: number = user.profile_id;
        let interlocutorId: number = profileId;

        if (conversation !== undefined) {
            initiatorId = conversation.initiator_id;
            interlocutorId = conversation.interlocutor_id;
        }

        const message = new Message(
            null,
            null,
            user.profile_id,
            profileId,
            messageText,
            uuid,
        );
        const newConversation: IConversation = {
            last_message_id: Infinity,
            last_message: message,
            initiator_id: initiatorId,
            interlocutor_id: interlocutorId,
            peer_id: profileId,
        };

        return this.authHttp.post(endpoint, data).pipe(
            map((response: IAuthResponse): IMessage => {
                message.created_at = response.data.created_at;
                message.message_id = response.data.message_id;
                message.uuid = response.data.uuid;
                return message;
            }),
            tap((newMessage: IMessage): void => {
                newConversation.last_message_id = newMessage.message_id;
                this.store.dispatch(ConversationActions.loadConversations({
                    conversations: [newConversation],
                }));
            }),
            tap((): void => {
                this.store.dispatch(ConversationActions.checkExistsConversationByPeerId({
                    peerId: profileId,
                }));
            }),
        );
    }
}
