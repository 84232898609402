import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BoostProgressServiceCommon } from '@libs/modules/boost/services/boost-progress/boost-progress.service.common';
import { IApplicationState } from '@libs/store/application-state';

@Injectable({
    providedIn: 'root',
})
export class BoostProgressService extends BoostProgressServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
    ) {
        super(store);
    }
}
