import {
    animate,
    animateChild,
    AnimationTriggerMetadata,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const EmailUnsubscribeAnimations: {
    readonly slideToggle: AnimationTriggerMetadata;
    readonly animateChildren: AnimationTriggerMetadata;
} = {
    slideToggle: trigger('slideToggle', [
        transition(':enter', [
            style({
                transform: 'translateX(-100%)',
                opacity: 0,
            }),
            animate('0.6s ease-out'),
        ]),
        transition(':leave', [
            animate(
                '0.4s ease-in',
                style({
                    transform: 'translateX(-100%)',
                    opacity: 0,
                }),
            ),
        ]),
    ]),
    animateChildren: trigger('animateChildren', [
        transition(':enter', [
            query('@*', [animateChild()], { optional: true }),
        ]),
        transition(':leave', [
            query('@*', [animateChild()], { optional: true }),
        ]),
    ]),
};
