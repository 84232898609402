import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromEvent, iif, Observable, throwError } from 'rxjs';
import { concatMap, mapTo, retryWhen } from 'rxjs/operators';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
    protected onlineChanges$: Observable<boolean> = fromEvent(window, 'online')
        .pipe(mapTo(true));

    get isOnline(): boolean {
        return navigator.onLine;
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retryWhen((errors): Observable<never|boolean> => errors.pipe(
                concatMap((err): Observable<never|boolean> => iif(
                    (): boolean => this.isOnline,
                    throwError(err),
                    this.onlineChanges$,
                )),
            )),
        );
    }
}
