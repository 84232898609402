import { Action, createReducer, on } from '@ngrx/store';

import { resetActiveConversation, setActiveConversation } from '@libs/store/olark/actions';
import { IOlarkState } from '@libs/store/olark/interfaces/olark-state.interface';
import { olarkInitialState } from '@libs/store/olark/state';

const olarkReducer = createReducer(
    olarkInitialState,
    on(
        setActiveConversation,
        (
            state: IOlarkState,
            { userFlow, message, timestamp, chatboxConfig },
        ): IOlarkState => {
            return {
                ...state,
                activeConversation: {
                    userFlow,
                    message,
                    timestamp,
                    chatboxConfig,
                },
            };
        },
    ),
    on(
        resetActiveConversation,
        (state: IOlarkState) => {
            return {
                ...state,
                activeConversation: {
                    userFlow: null,
                    message: null,
                    timestamp: null,
                    chatboxConfig: null,
                },
            };
        },
    ),
);

export function reducer(
    state: IOlarkState,
    action: Action,
): IOlarkState {
    return olarkReducer(state, action);
}
