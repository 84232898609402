import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';

import { IModalData } from '@prince/modules/initial/pages/login/interfaces/modal-data.interface';

@Component({
    templateUrl: 'login-not-allowed.html',
    styleUrls: ['login-not-allowed.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginNotAllowedComponent {
    constructor(
        protected modalRef: ModalRef,
        @Inject(MODAL_DATA) public data: IModalData,
    ) {
        //
    }

    public getErrorDescription(): string {
        return this.data.error;
    }

    public getTitle(): string {
        return this.data.title;
    }

    public getButtonText(): string {
        return this.data.buttonText;
    }

    public closeModal(): void {
        this.modalRef.close();
    }
}
