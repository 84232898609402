import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';

interface ICalculationResult {
    text?: string;
    unit: string;
}

interface INumberMap {
    [unit: string]: number;
}

@Injectable()
export class RelativeTimeService {
    protected readonly timeouts: INumberMap = {
        d: Infinity,
        h: 120000,
        m: 30000,
        s: 10000,
    };

    protected readonly divisors: INumberMap = {
        d: 86400000,
        h: 3600000,
        m: 60000,
        s: 15000,
    };

    protected readonly dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(this.translate.currentLang, {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });

    constructor(
        protected translate: TranslateService,
    ) {
        //
    }

    public fromNow(unixStamp: number): Observable<string> {
        const inner = (subscriber: Subscriber<string>): void => {
            const result = this.calculate(unixStamp * 1000);
            subscriber.next(result.text);

            const timeout = this.timeouts[result.unit];
            if (timeout === Infinity) {
                subscriber.complete();
                return;
            }

            setTimeout((): void => {
                inner(subscriber);
            }, timeout);
        };

        return new Observable(inner);
    }

    protected calculate(date: number): ICalculationResult {
        const now = Date.now();
        const baseDiff = now - date;

        for (const unit of Object.keys(this.timeouts)) {
            const diff = Math.floor(baseDiff / this.divisors[unit]);

            if (unit === 'd' && diff) {
                return {
                    text: this.dateFormat.format(new Date(date)),
                    unit,
                };
            }

            if (diff) {
                let tense = 'past';
                if (diff < 0) {
                    tense = 'future';
                }

                const duration = this.translate.instant(`common.time_ago.${unit}`, { diff });
                return {
                    text: this.translate.instant(`common.time_ago.${tense}`, { duration }),
                    unit,
                };
            }
        }

        return {
            text: this.translate.instant('common.time_ago.now'),
            unit: 's',
        };
    }
}
