import {
    HttpErrorResponse,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { Directive, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
    Observable,
    of,
    range,
    Subscription,
    throwError,
    TimeoutError,
    zip,
} from 'rxjs';
import {
    mergeMap,
    retryWhen,
} from 'rxjs/operators';

import { CustomEncoder } from '@libs/modules/encoder/custom.enconder';
import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { AnalyticsServiceCommon } from '@libs/services/analytics/analytics.service.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IConfigurationParameters } from '@libs/shared/interfaces/configuration-parameters.interface';
import {
    MembershipCommon,
    MembershipType,
} from '@libs/shared/membership/membership.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoActions } from '@libs/store/payment-info';
import { setProviderPaymentTypes, setSelectedProvider } from '@libs/store/payment/actions';
import { IPaymentProvider } from '@libs/store/payment/interfaces/payment-provider';
import { hasPaymentOptions } from '@libs/store/payment/selectors';
import { Tick } from '@libs/utils/timeout-typings';

import * as CPF from 'gerador-validador-cpf';
import { MoipValidator } from 'moip-sdk-js';

export const EXPRESS_APPROVAL_ID = -1;

export interface IInformApiParams {
    isBoleto: boolean;
    membershipId: MembershipType;
    extraParams: HttpParams;
}

@Directive()
export abstract class PaymentServiceCommon implements OnDestroy {
    protected waitToInformAPI: Tick;
    protected readonly EXPRESS_APROVAL_ID: MembershipType = -1;

    protected subscriptions: Subscription[] = [];
    protected readonly dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
    });

    constructor(
        protected auth: AuthenticationServiceCommon,
        protected authHttp: AuthHttpServiceCommon,
        protected store: Store<IApplicationState>,
        protected analytics: AnalyticsServiceCommon,
        protected userService: UserServiceCommon,
        protected profileService: ProfileServiceCommon,
        protected router: Router,
    ) {
        //
    }

    abstract getConfig(): IConfigurationParameters;

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    getDefaultEmail(profileId: number): string {
        return this.getConfig().mailPrefix + profileId + '@' + this.getConfig().mailDomain;
    }

    public canUpgrade$(): Observable<boolean> {
        return this.store.pipe(
            select(hasPaymentOptions),
        );
    }

    public exist(atribute: any): boolean {
        return atribute !== null &&
            atribute !== undefined &&
            atribute !== '';
    }

    public loadPaymentProvider(): void {
        const endpoint: string = this.getConfig().serverIp + 'payment/provider';

        this.authHttp.get(endpoint)
            .subscribe((response: IAuthResponse<IPaymentProvider>): void => {
                if (response.status === 200 &&
                    response.data !== undefined &&
                    response.data.provider !== ''
                ) {
                    this.setProvider(
                        response.data.provider ?? PaymentCommon.PROVIDER_PAGSEGURO,
                    );
                    this.setPaymentTypesAvailable(
                        response.data.payment_types ?? [
                            PaymentMethods.PAYMENT_CREDIT_CARD,
                            PaymentMethods.PAYMENT_BOLETO,
                        ],
                    );
                    return;
                }

                this.setDefaultPaymentConfiguration();
            }, (): void => {
                this.setDefaultPaymentConfiguration();
            });
    }

    public containWhiteSpace(data: string): boolean {
        return /\s/.test(data);
    }

    public validInput(field: string): boolean {
        return this.exist(field) &&
            !this.containWhiteSpace(field);
    }

    public isValidCardInput(cardNumber: string): boolean {
        return this.validInput(cardNumber);
    }

    public isValidCardNumber(cardNumber: string): boolean {
        return MoipValidator.isValidNumber(cardNumber);
    }

    public isValidDateInput(date: string): boolean {
        if (!/^\d{2}\/\d{2}$/.test(date)) {
            return false;
        }

        const dates = date.split('/');
        return MoipValidator.isExpiryDateValid(dates[0], dates[1]);
    }

    public isValidCVV(
        cvv: string,
        cardNumber: string,
    ): boolean {
        return MoipValidator.isSecurityCodeValid(cardNumber, cvv);
    }

    public isValidHolder(holder: string): boolean {
        return this.exist(holder);
    }

    public isValidCPFInput(cpf: string): boolean {
        return /^\d{11}$/.test(cpf);
    }

    public isValidCPFField(cpf: string): boolean {
        return CPF.validate(cpf);
    }

    public getCardBrand(cardNumber: string): string {
        const brand = MoipValidator.cardType(cardNumber);

        if (brand === null) {
            return '';
        }

        return brand['brand'];
    }

    public setDefaultPaymentConfiguration(): void {
        this.setProvider(PaymentCommon.PROVIDER_PAGSEGURO);

        this.setPaymentTypesAvailable([
            PaymentMethods.PAYMENT_CREDIT_CARD,
            PaymentMethods.PAYMENT_BOLETO,
        ]);
    }

    public setProvider(provider: string): void {
        this.store.dispatch(
            setSelectedProvider(provider),
        );
    }

    public setPaymentTypesAvailable(paymentTypes: string[]): void {
        this.store.dispatch(
            setProviderPaymentTypes({
                payment_types: paymentTypes,
            }),
        );
    }

    public abstract generateBoleto(
        membershipId: MembershipType,
        price: number,
        cpf: string,
        interest: number,
    ): Observable<IAuthResponse>;

    public informAPI({
        isBoleto,
        membershipId,
        extraParams,
    }: IInformApiParams): Observable<any> {
        const endpoint: string = this.getConfig().serverIp;

        let suffix: string = 'payment';

        if (membershipId === this.EXPRESS_APROVAL_ID) {
            suffix = 'payment/express-approval';
        }

        if (isBoleto) {
            suffix = 'payment/pending';
        }

        return this.authHttp.post(
            endpoint + suffix,
            extraParams,
        ).pipe(
            retryWhen((errors): Observable<number> => zip(range(1, 3), errors).pipe(
                mergeMap(([attempt, error]): Observable<number> => {
                    if (!this.expectedError(error) || attempt >= 3) {
                        this.store.dispatch(PaymentInfoActions.setPaymentStatus({
                            paymentStatus: PaymentStatus.PAYMENT_ERROR,
                        }));

                        throw error;
                    }

                    return of();
                }),
            )),
        );
    }

    public throwErrors = (err: HttpErrorResponse): Observable<never> => {
        this.store.dispatch(PaymentInfoActions.setPaymentStatus({
            paymentStatus: PaymentStatus.PAYMENT_ERROR,
        }));

        return throwError(err);
    };

    public formatNumber(value: string): string {
        return value.replace(/\D+/g, '');
    }

    public formatCurrency(value: string): string {
        return value.replace(/\./, ',');
    }

    public formatDate(date: string): string {
        date = date.trim();
        if (/^\d{2}\/\d{2}$/.test(date)) {
            return date;
        }

        if (/^\d{4,}$/.test(date) ||
            /^\d{2}\/\d{2,}$/.test(date) ||
            /^\d{2}\/\d{2,}$/.test(date)
        ) {
            return date.substring(0, 2) +
                '/' +
                date.slice(-2);
        }

        if (/^\d{1}\/\d{2,}$/.test(date) ||
            /^\d{3}$/.test(date) ||
            /^\d{1}\s*\d{2,}/.test(date) ||
            /^\d{1}\s*\/\s*\d{2,}/.test(date)
        ) {
            return '0' +
                date.substring(0, 1) +
                '/' +
                date.slice(-2);
        }

        return '';
    }

    public handlePaymentMadeAtProvider(): void {
        this.store.dispatch(PaymentInfoActions.setPaymentStatus({
            paymentStatus: PaymentStatus.PAYMENT_PROCESSING,
        }));

        this.store.dispatch(PaymentInfoActions.loadPaymentInfo());
    }

    public tryInformAPI({
        isBoleto,
        membershipId,
        extraParams,
    }: IInformApiParams): void {
        this.informAPI({
            isBoleto,
            membershipId,
            extraParams,
        }).subscribe(
            (dataSub: HttpResponse<any>): void => {
                if (dataSub.status !== 201) {
                    return;
                }

                this.profileService.updateSelf();
            },
            (): void => {
                this.store.dispatch(PaymentInfoActions.setPaymentStatus({
                    paymentStatus: PaymentStatus.PAYMENT_ERROR,
                }));

                this.analytics.onPaymentError();
            },
        );
    }

    protected expectedError(error: any): boolean {
        return (error instanceof TimeoutError) || (
            (
                error !== undefined &&
                error.error !== undefined &&
                error.error.error !== undefined
            ) &&
            (
                error.error.error === 'order.not.paid.yet'
            )
        );
    }

    protected formatDueDate(): string {
        const date = new Date();
        date.setDate(date.getDate() + 2);
        return this.dateFormat.format(date).replace(/\D/g, '');
    }

    protected getMachineProductType(
        membershipId: MembershipType,
    ): string {
        if (membershipId === this.EXPRESS_APROVAL_ID) {
            return 'express_approval';
        }

        return MembershipCommon.getMachineMembership(membershipId) + '_member';
    }

    protected formatParams(data: any): HttpParams {
        let params = new HttpParams({ encoder: new CustomEncoder() });
        for (const key of Object.keys(data)) {
            params = params.set(key, data[key]);
        }
        return params;
    }
}
