import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { IBoostPackageProduct } from '@libs/modules/boost/interface/boost-package-product';
import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { MembershipType } from '@libs/shared/membership/membership.common';
import {
    IPaymentOption,
    PaymentOption,
} from '@libs/shared/payment-option/payment-option';
import {
    paymentOptionsLoaded,
    paymentError,
    setSelectedPeriod,
    setSelectedProvider,
    setProviderPaymentTypes,
    setSelectedMembershipPlanByUuid,
    updatePaymentOptions,
} from '@libs/store/payment/actions';

export interface IPaymentOptionState extends EntityState<IPaymentOption> {
    selectedPeriod: number;
    selectedMembershipsPlans?: Dictionary<string>;
    selectedProvider?: string;
    hasLoaded: boolean;
    currentProductPayment?: MembershipType | IBoostPackageProduct;
    payment_types: string[];
}

export const paymentOptionsAdapter: EntityAdapter<IPaymentOption> = createEntityAdapter<IPaymentOption>({
    selectId: (options: IPaymentOption): string => options.uuid,
});

export const initialPaymentOptions: IPaymentOptionState = paymentOptionsAdapter.getInitialState({
    selectedPeriod: 1,
    selectedProvider: PaymentCommon.PROVIDER_ALLCASH,
    hasLoaded: false,
    currentProductPayment: null,
    payment_types: [],
});

export const paymentOptionsReducer = createReducer(
    initialPaymentOptions,
    on(paymentOptionsLoaded, (state: IPaymentOptionState, { paymentOptions }): IPaymentOptionState => {
        for (const paymentOption of paymentOptions) {
            paymentOption.discount = PaymentOption.calculateDiscount(
                paymentOptions,
                paymentOption.price,
                paymentOption.period,
                paymentOption.membership_type_id,
            );

            paymentOption.totalByMonth = Math.round(
                paymentOption.price / paymentOption.period,
            );
        }

        return paymentOptionsAdapter.setAll(
            paymentOptions, {
                ...state,
                hasLoaded: true,
                selectedMembershipsPlans: getPreferredPlans(paymentOptions),
            },
        );
    }),
    on(paymentError, (state: IPaymentOptionState, { product }): IPaymentOptionState => {
        return { ...state, currentProductPayment: product };
    }),
    on(setSelectedPeriod, (state: IPaymentOptionState, { period }): IPaymentOptionState => {
        return { ...state, selectedPeriod: period };
    }),
    on(setSelectedMembershipPlanByUuid, (state: IPaymentOptionState, { uuid }): IPaymentOptionState => {
        return {
            ...state,
            selectedMembershipsPlans: {
                ...state.selectedMembershipsPlans,
                [state.entities[uuid].membership_type_id]: uuid,
            },
        };
    }),
    on(setSelectedProvider, (state: IPaymentOptionState, { provider }): IPaymentOptionState => {
        return { ...state, selectedProvider: provider };
    }),
    on(
        setProviderPaymentTypes,
        (
            state: IPaymentOptionState,
            { payment_types }: { payment_types: string[] },
        ): IPaymentOptionState => {
            return { ...state, payment_types };
        },
    ),
    on(
        updatePaymentOptions,
        (
            state: IPaymentOptionState,
            { paymentOptions }: { paymentOptions: IPaymentOption[] },
        ): IPaymentOptionState => {
            return paymentOptionsAdapter.setAll(
                paymentOptions, {
                    ...state,
                },
            );
        },
    ),
);

function getPreferredPlans(paymentOptions: IPaymentOption[]): Dictionary<string> {
    return Object.assign(
        { },
        ...paymentOptions.filter((option: IPaymentOption): boolean =>
            option.preferred_plan,
        ).map((paymentOption: IPaymentOption): Dictionary<string> => ({
            [paymentOption.membership_type_id]: paymentOption.uuid,
        })),
    );
}

export function reducer(state: IPaymentOptionState, action: Action): IPaymentOptionState {
    return paymentOptionsReducer(state, action);
}
