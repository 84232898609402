import { HttpErrorResponse } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

export function exponentialBackoff(
    {
        maxRetries = 3,
        customBaseTime = 1500,
    } = {},
): (source: Observable<unknown>) => Observable<unknown> {
    return (source: Observable<unknown>): Observable<unknown> =>
        source.pipe(
            retryWhen((errors: Observable<unknown>) : Observable<unknown> => errors.pipe(
                mergeMap((error: unknown, attempt: number) : Observable<unknown> => {
                    const notRetryableStatusCodes: number[] = [400, 401, 422, 500, 503];
                    const isHttpError: boolean = error instanceof HttpErrorResponse;
                    const isRetryableStatusCode: boolean = !notRetryableStatusCodes.includes(
                        (error as HttpErrorResponse).status,
                    );
                    const retryAttempt: number = attempt + 1;

                    if (
                        retryAttempt > maxRetries ||
                        !isHttpError ||
                        !isRetryableStatusCode
                    ) {
                        return throwError(error);
                    }

                    const delayTime: number = retryAttempt * retryAttempt * customBaseTime;

                    if (isDevMode()) {
                        console.log(`Attempt ${retryAttempt}: retrying in ${delayTime}ms`);
                    }

                    return timer(delayTime);
                }),
            )),
        );
}
