<button
    class="header-button"
    (click)="goBack()"
>
    <i
        class="material-icons"
        aria-hidden="true"
    >
        &#xE5C4;
    </i>
</button>
