import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    EmailUnsubscribeHeaderComponentCommon,
} from '@libs/modules/initial/email-unsubscribe-header/email-unsubscribe-header.component.common';

@Component({
    selector: 'mp-email-unsubscribe-header',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './email-unsubscribe-header.html',
})
export class EmailUnsubscribeHeaderComponent extends EmailUnsubscribeHeaderComponentCommon {
    //
}
