import { Action, createReducer, on } from '@ngrx/store';

import { MembershipType } from '@libs/shared/membership/membership.common';
import * as MembershipActions from '@libs/store/membership/actions';
import { IMembershipState, membershipInitialState } from '@libs/store/membership/state';

const membershipReducer = createReducer(
    membershipInitialState,
    on(MembershipActions.setLatestPaidMembership, (
        state: IMembershipState, { latestPaidMembership }: { latestPaidMembership: MembershipType},
    ): IMembershipState => {
        return { ...state, latestPaidMembership };
    }),
);

export function reducer(state: IMembershipState, action: Action): IMembershipState {
    return membershipReducer(state, action);
}
