import { MembershipType } from '@libs/shared/membership/membership.common';
import { IProduct } from '@libs/shared/product/product.interface';

export class InvalidProduct implements IProduct {
    identifier: number = MembershipType.MEMBERSHIP_TYPE_NONE;

    satisfied(identifier: number): boolean {
        return identifier === this.identifier;
    }
}
