import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationExtras, Router } from '@angular/router';

import { ViewStyle } from '@libs/components/submenu-v2/interfaces/list-view-style.type';
import {
    SubmenuV2ComponentCommon,
} from '@libs/components/submenu-v2/submenu-v2.component.common';

@Component({
    selector: 'mp-submenu-v2',
    templateUrl: './submenu-v2.html',
})
export class SubmenuV2Component extends SubmenuV2ComponentCommon implements OnInit {
    @Input() hasSecondaryFunction: boolean = true;
    @Input() hasGridViewSelector: boolean = true;

    protected listViewStyle: ViewStyle = 'list';

    constructor(
        protected router: Router,
        protected location: Location,
    ) {
        super(location);

        this.updateOnNavigation();
    }

    navigateTo(route: any[], extras?: NavigationExtras): void {
        this.router.navigate(route, extras);
    }

    updateOnNavigation(): void {
        this.subscriptions.push(this.router.events
            .subscribe((event: Event): void => {
                if (event instanceof NavigationEnd) {
                    this.updateSubmenuItems();
                }
            }),
        );
    }

    public setListViewStyle(style: ViewStyle): void {
        this.listViewStyle = style;
    }

    public getGridViewButtonModifierClass(style: ViewStyle): string {
        if (style !== this.listViewStyle) {
            return '';
        }

        return 'selected';
    }

    public getGenericButtonModifierClass(itemIndex: number): string {
        if (this.index === itemIndex) {
            return 'active';
        }

        return '';
    }

    public isButtonActive(index: number): boolean {
        return index === this.index;
    }
}
