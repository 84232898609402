import { createAction, props } from '@ngrx/store';

import { INamedSearch } from '@libs/shared/search/search';
import { SearchModalTabs } from '@libs/store/search/types/search-modal-tabs.type';

enum Actions {
    OPEN_MODAL_VIEW = '[Search] - Open Search Modal View',
    SAVE_SCROLL_POSITION = '[Search] - Save Scroll Position',
    SAVE_SEARCH = '[Search] - Save Search',
    SET_IS_SAVING_SEARCH = '[Search] - Saving is Saving Search',
    UPDATE_SAVED_SEARCHES_LIST = '[Search] - Update Saved Searches List',
    LOAD_SAVED_SEARCHES_LIST = '[Search] - Load Saved Searches List',
    SET_HAS_UPDATE_FAILED = '[Search] - Set Has Upload Failed',
    STORE_SAVED_SEARCHES_LIST = '[Search] - Store Saved Searches List',
    SET_SEARCH_MODAL_ACTIVE_TAB = '[Search] - Set Search Modal Active Tab',
    SET_HAS_ERROR_OCCURRED = '[Search] - Set Has Error Occurred',
    DELETE_SAVED_SEARCH = '[Search] - Delete Saved Search',
    SET_IS_DELETING_SEARCH = '[Search] - Set is Deleting Search',
    APPLY_SEARCH_FILTER = '[Search] - Apply Search Filter',
    RESET_SAVED_SEARCHES_FLOW = '[Search] - Reset Saved Search Flow',
}

export const saveScrollPosition = createAction(
    Actions.SAVE_SCROLL_POSITION,
    props<{ scrollYPosition: number }>(),
);

export const openModal = createAction(
    Actions.OPEN_MODAL_VIEW,
);

export const saveSearch = createAction(
    Actions.SAVE_SEARCH,
    props<{ searchName: string }>(),
);

export const setIsSavingSearch = createAction(
    Actions.SET_IS_SAVING_SEARCH,
    props<{ isSavingSearch: boolean }>(),
);

export const updateSavedSearchesList = createAction(
    Actions.UPDATE_SAVED_SEARCHES_LIST,
);

export const loadSavedSearchesList = createAction(
    Actions.LOAD_SAVED_SEARCHES_LIST,
);

export const setHasUpdateFailed = createAction(
    Actions.SET_HAS_UPDATE_FAILED,
    props<{ hasUpdateFailed: boolean }>(),
);

export const storeSavedSearchesList = createAction(
    Actions.STORE_SAVED_SEARCHES_LIST,
    props<{ savedSearches: INamedSearch[] }>(),
);

export const setSearchModalActiveTab = createAction(
    Actions.SET_SEARCH_MODAL_ACTIVE_TAB,
    props<{ searchModalActiveTab: SearchModalTabs }>(),
);

export const setHasErrorOccurred = createAction(
    Actions.SET_HAS_ERROR_OCCURRED,
    props<{ hasErrorOccurred: boolean }>(),
);

export const deleteSavedSearch = createAction(
    Actions.DELETE_SAVED_SEARCH,
    props<{ searchId: number }>(),
);

export const setIsDeletingSearch = createAction(
    Actions.SET_IS_DELETING_SEARCH,
    props<{ isDeletingSearch: boolean }>(),
);

export const applySearchFilter = createAction(
    Actions.APPLY_SEARCH_FILTER,
    props<{ savedSearch: INamedSearch }>(),
);

export const resetSavedSearchesFlow = createAction(
    Actions.RESET_SAVED_SEARCHES_FLOW,
);
