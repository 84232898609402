import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ListServiceCommon } from '@libs/modules/main/services/list/list.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { AuthRequestFacade } from '@prince/modules/main/services/auth-request-facade/auth-request-facade';
import { AuthHttpService } from '@prince/services/auth-http.service';

@Injectable()
export class ListService extends ListServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected authHttp: AuthHttpService,
        protected authRequestFacade: AuthRequestFacade,
    ) {
        super(
            store,
            authHttp,
            authRequestFacade,
        );
    }

    public downloadHomeFeatured(page: number = 1): Observable<IAuthResponse> {
        const endpoint: string = `${ Config.serverIp }search/featured?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }

    public downloadHomeNearby(page: number = 1): Observable<IAuthResponse> {
        const endpoint: string = `${ Config.serverIp }search/nearby?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }

    public downloadHomeNewUsers(page: number = 1): Observable<IAuthResponse> {
        const endpoint: string = `${ Config.serverIp }search/newUsers?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }

    public downloadBlockedUsers(page: number = 1): Observable<IAuthResponse> {
        const endpoint: string = `${ Config.serverIp }search/blocked-list?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }

    public downloadFavMeProfiles(page: number = 1): Observable<IAuthResponse> {
        const endpoint: string = `${ Config.serverIp }search/bookmarked?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }

    public downloadMyFavProfiles(page: number = 1): Observable<IAuthResponse> {
        const endpoint: string = `${ Config.serverIp }search/bookmark?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }

    public downloadFavMelt(page: number = 1): Observable<IAuthResponse> {
        const endpoint: string = `${ Config.serverIp }search/melt?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }

    public downloadViewedMeProfiles(page: number = 1): Observable<IAuthResponse> {
        const endpoint: string = `${ Config.serverIp }search/viewed?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }

    public downloadRecommendedProfiles(page: number) {
        const endpoint: string = `${ Config.serverIp }search/featured?page=${ page }`;

        return this.authRequestFacade.get({
            endpoint,
        });
    }
}
