import { TranslateService } from '@ngx-translate/core';

import { DownloadManagerServiceCommon } from '@libs/modules/main/services/download-manager.service.common';
import { IParsedMessage } from '@libs/shared/interfaces/parsed-message.interface';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IMessage } from '@libs/store/messages';
import { IPendingMessage } from '@libs/store/pending-messages';

import jsSHA from 'jssha';

export abstract class MessageCommon {
    static readonly ORIGIN_USER: number = 1;
    static readonly ORIGIN_PRIVATE_PHOTO_REQUEST: number = 2;
    static readonly ORIGIN_PRIVATE_PHOTO_GRANT: number = 3;
    static readonly ORIGIN_INFO_REQUEST: number = 4;

    public static readonly DEFAULT_APP_TITLE: string = 'MeuPatrocínio';
    static readonly SENT_RESPONSE_STATUS_DELETED: string = 'target.deleted';
    static readonly SENT_RESPONSE_NOT_ACTIVE: string = 'target.not.active';
    static readonly SENT_RESPONSE_ON_HOLD: string = 'target.on_hold.status';
    static readonly SENT_RESPONSE_BLOCKED: string = 'target.blocked';
    static readonly SENT_RESPONSE_SEX_MISMATCH: string = 'target.sex.mismatch';
    static readonly SENT_RESPONSE_ALREADY_SENT_FREE_MESSAGE: string = 'conversation.exists';
    static readonly SENT_RESPONSE_DAILY_CONVERSATION_LIMIT_REACHED: string = 'daily.conversation.limit.reached';

    static readonly INTERNAL_ERROR_TRANSLATION: string = 'modules.main.services.auth_http.internal_error';
    static readonly RECEIPIENT_DELETED_ERROR_TRANSLATION: string = 'modules.main.services.auth_http.recipient_deleted';

    static readonly CONVERSATION_FORBIDDEN_CHARACTERS: RegExp = /<[^>]*>/g;

    static NO_MESSAGE_ID_FOUND: number = 0;

    static getMessageHash(text: string): string {
        const shaObj = new jsSHA('SHA-256', 'TEXT');
        shaObj.update(text);
        return shaObj.getHash('HEX');
    }

    static interpretPhotosMessage(message: IMessage, userId: number): string {
        if (message === undefined) {
            return '';
        }

        if (message.origin === UserCommon.MESSAGE_REQUEST) {
            return this.interpretRequestMessage(message, userId);
        }

        if (message.origin === UserCommon.MESSAGE_GRANT) {
            return this.interpretGrantMessage(message, userId);
        }

        return message.text.replace(/<[^>]*>/g, '');
    }

    static isMessageByProfileIdOrReceiverId(
        profileId: number,
        receiverId: number,
        message: IMessage,
    ): boolean {
        return (message.sender_id === profileId && message.recipient_id === receiverId) ||
        (message.recipient_id === profileId && message.sender_id === receiverId);
    }

    protected static interpretRequestMessage(message: IMessage, userId: number): string {
        if (message.sender_id === userId) {
            return 'modules.main.pages.message.sender_request_private_photo_access';
        }

        return 'modules.main.pages.message.receiver_request_private_photo_access';
    }

    protected static interpretGrantMessage(message: IMessage, userId: number): string {
        if (message.sender_id === userId) {
            return 'modules.main.pages.message.sender_grant_private_photo_access';
        }

        return 'modules.main.pages.message.receiver_grant_private_photo_access';
    }

    static getLastReceivedMessageId(messages: IMessage[], profileId: number): number {
        for (let index: number = messages.length - 1; index >= 0; index--) {
            const sender_id: number = messages[index].sender_id;
            const message_id: number = messages[index].message_id;
            if (sender_id !== profileId &&
                message_id !== undefined
            ) {
                return message_id;
            }
        }

        return MessageCommon.NO_MESSAGE_ID_FOUND;
    }

    static findMessageIndexByMessageId(messages: IMessage[], messageId: number): number {
        return messages.findIndex((message: IMessage): boolean =>
            message.message_id === messageId,
        );
    }

    static findMessageIndexByMessagesIds(message: IMessage, messagesIds: number[]): number {
        return messagesIds.findIndex((messageId: number): boolean =>
            message.message_id === messageId,
        );
    }

    static findMessageByReceiverId(messages: IMessage[], receiverId: number): IMessage {
        return messages.find((message: IMessage): boolean =>
            MessageCommon.isReceiverSenderOrRecipient(
                message.sender_id,
                message.recipient_id,
                receiverId,
            ),
        );
    }

    static buildMessageObject(user: UserCommon, parsedMessage: IParsedMessage): IMessage {
        return {
            conversation_id: parsedMessage.conversationId,
            recipient_id: user.profile_id,
            message_id: parsedMessage.messageId,
            sender_id: parsedMessage.senderId,
            text: parsedMessage.text,
            created_at: parsedMessage.timeStamp,
            origin: parsedMessage.origin,
        };
    }

    static concatMessagesAndPendingMessages(messages: IMessage[], pendingMessages: IPendingMessage[]): IMessage[] {
        return messages.concat(pendingMessages);
    }

    static isReceiverSenderOrRecipient(
        senderId: number,
        recipientId: number,
        receiverId: number,
    ): boolean {
        return senderId === receiverId ||
            recipientId === receiverId;
    }

    static canDownloadMessagesRecent(
        lastMessageId: number,
        downloadManagerService: DownloadManagerServiceCommon,
    ): boolean {
        return lastMessageId !== MessageCommon.NO_MESSAGE_ID_FOUND &&
            !downloadManagerService.isDownloading('messagesRecent', lastMessageId);
    }

    static getMessagePageTitle(
        receiver: IProfile | undefined,
        translateService: TranslateService,
    ): string {
        if (!receiver) {
            return MessageCommon.DEFAULT_APP_TITLE;
        }

        let title: string = translateService.instant('common.page_title.conversation', {
            username: receiver.username,
        });

        if (receiver.age) {
            title = translateService.instant('common.page_title.conversation.title-with-age', {
                username: receiver.username,
                age: receiver.age,
            });
        }

        return title;
    }
}
