<div class="uau-toast">
    <img src="/assets/img/charm/charm-logo-toast.svg" />
    <button
        class="uau-toast__close"
        (click)="closeToast()"
        [attr.aria-label]="'common.close' | translate"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
