import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHeaders,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ImageServiceCommon } from '@libs/modules/main/services/image/image-service.common';
import { IModalPhotoData } from '@libs/shared/interfaces/modal-photo-data.interface';
import { IApplicationState } from '@libs/store/application-state';
import { PhotoVerifyActions } from '@libs/store/photo-verify';
import { RegistrationActions } from '@libs/store/registration';

import { Config } from '@prince/config';
import { AdvancedModalService } from '@prince/services/advanced-modal.service';
import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';
import { origin } from '@prince/utils/origin';

@Injectable({
    providedIn: 'root',
})
export class ImageService extends ImageServiceCommon {
    constructor(
        protected advancedModalService: AdvancedModalService,
        protected modalService: ModalService,
        protected profileService: ProfileService,
        protected http: HttpClient,
        protected store: Store<IApplicationState>,
    ) {
        super(profileService, store);
    }

    cropPhoto(data: IModalPhotoData): void {
        if (!this.validateImageFile(data)) {
            return;
        }

        this.store.dispatch(RegistrationActions.setIsCroppingImage({
            isCroppingImage: true,
        }));

        this.openCropModal(data);
    }

    getOrigin(): string {
        return origin().toString();
    }

    protected validateImageFile(data: IModalPhotoData): boolean {
        const imageFile = data.file;

        if (imageFile === undefined) {
            return false;
        }

        if (!/^image\/(?:gif|jpe?g|png)$/.test(imageFile.type)) {
            this.modalService.open('modules.main.shared.picture_crop.invalid_file');
            return false;
        }

        if (!this.isImageFileSizeValid(imageFile.size)) {
            this.modalService.open('modules.frictionless.picture_size');
            return false;
        }

        return true;
    }

    protected prepareDataToUpload(): void {
        const formData = new FormData();

        if (this.uploadData.modalData && this.uploadData.modalData.extraParams) {
            for (const key of Object.keys(this.uploadData.modalData.extraParams)) {
                formData.append(key, this.uploadData.modalData.extraParams[key]);
            }
        }

        formData.append('image', this.uploadData.image);
        formData.append('perfil', '0');
        formData.append('publishing_status', this.uploadData.modalData.type);

        this.uploadData.formData = formData;

        this.processImageRequest();
    }

    protected processImageRequest(): void {
        this.setIsUploadingImage(true);

        if (this.uploadData.modalData === undefined ||
            !this.uploadData.modalData.callback
        ) {
            return;
        }

        const request = this.mountDataRequest();
        this.uploadData.modalData.callback({
            image: this.uploadData.image,
            request: this.http.request(request).pipe(
                tap((event: HttpEvent<any>): void => {
                    if (event.type !== HttpEventType.Sent) {
                        return;
                    }
                    this.uploadData.closeCallback();
                    this.token = '';

                }),
                catchError((error: HttpErrorResponse): Observable<never> => {
                    this.uploadData.closeCallback();
                    this.modalService.open('modules.main.shared.picture_crop.upload_error');
                    this.token = '';
                    this.uploadData = { };
                    this.setIsUploadingImage(false);

                    return throwError(error);
                }),
            ),
        });
    }

    protected setIsUploadingImage(isUploadingImage: boolean): void {
        this.store.dispatch(PhotoVerifyActions.setIsUploadingImage({
            isUploadingImage,
        }));
    }

    protected mountDataRequest(): HttpRequest<FormData> {
        const headers = new HttpHeaders({
            Authorization: this.getAuthenticationKey(),
            mobile: this.getOrigin(),
            'X-MP-Request-Origin': this.getOrigin(),
        });
        return new HttpRequest(
            this.METHOD_HTTP_POST,
            Config.serverIp +
            'profile/photo',
            this.uploadData.formData, { headers, reportProgress: true },
        );
    }
}
