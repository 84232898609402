import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IPendingMessage } from '@libs/store/pending-messages/interface';

export interface IPendingMessageState extends EntityState<IPendingMessage> { }

function selectPendingMessageId(message: IPendingMessage): string {
    return message.uuid;
}

function sortByCreatedAt(
    message1: IPendingMessage,
    message2: IPendingMessage,
): number {
    return message1.created_at - message2.created_at;
}

export const PendingMessageAdapter: EntityAdapter<IPendingMessage> =
    createEntityAdapter<IPendingMessage>({
        selectId: selectPendingMessageId,
        sortComparer: sortByCreatedAt,
    },
    );

export const PendingMessageInitialState: IPendingMessageState =
    PendingMessageAdapter.getInitialState({ });
