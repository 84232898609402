import { IThreeDotsMenuItem } from '@libs/shared/interfaces/three-dots-menu-item.interface';
import { MessageCommon } from '@libs/shared/message/message.common';
import { IMessage } from '@libs/store/messages';

export enum MessageButtons {
    ADD_FAVORITE,
    REMOVE_FAVORITE,
    PRIVATE_PHOTOS_GRANT,
    PRIVATE_PHOTOS_REVOKE,
    BLOCK,
    UNBLOCK,
    REPORT,
    DELETE_CONVERSATION,
}

export interface IMessageThreeDotsItems {
    [item: string]: IThreeDotsMenuItem;
}

export class Message extends MessageCommon implements IMessage {
    created_at: number;
    message_id: number;
    recipient_id: number;
    sender_id: number;
    text: string;
    pending: boolean;
    origin?: number;
    original_text_hash?: string;
    uuid?: string;

    constructor(
        messageId: number,
        timeStamp: number,
        senderId: number,
        recipientId: number,
        text: string,
        uuid?: string,
    ) {
        super();

        this.message_id = this.getDefaultMessageId(messageId);
        this.created_at = timeStamp || Math.floor(Date.now() / 1000);
        this.sender_id = senderId;
        this.recipient_id = recipientId;
        this.text = this.fixNewLine(text);
        this.origin = Message.ORIGIN_USER;
        this.uuid = uuid;
    }

    // TO DO: Put pipe in native
    fixNewLine(text: string): string {
        return text.replace(/<br\s*\/?>(?:\r\n|\n\r|\r|\n)?/g, '\n');
    }

    protected getDefaultMessageId(messageId: number): number {
        if (messageId === undefined || messageId === null) {
            return Infinity;
        }

        return messageId;
    }
}
