import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

import { Config } from '@prince/config';
import { IPlanCheckoutTitle } from '@prince/modules/shared/actionbar/actionbar-checkout/interfaces/plan-checkout-title.interface';

@Component({
    selector: 'prince-actionbar-checkout',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './actionbar-checkout.html',
    styleUrls: ['./actionbar-checkout.scss'],
})
export class ActionbarCheckoutComponent implements AfterViewInit {
    @Input() selectedPlan: IPlanCheckoutTitle = {
        title: '',
        titleColorVariable: '',
        iconPath: '',
    };

    @ViewChild('title', { static: true }) title!: ElementRef<HTMLSpanElement>;

    constructor(
        protected location: Location,
        protected renderer: Renderer2,
    ) {
        //
    }

    ngAfterViewInit(): void {
        this.setTitleColor();
    }

    protected setTitleColor(): void {
        if (!this.selectedPlan) {
            return;
        }

        this.renderer.setStyle(
            this.title.nativeElement,
            'color',
            `var(--${this.selectedPlan.titleColorVariable})`,
        );
    }

    public getPlanIconPath(): string {
        if (
            !this.selectedPlan ||
            this.selectedPlan.iconPath === ''
        ) {
            return '';
        }

        return `${ Config.imagesFolderBaseUrl }badges/${ this.selectedPlan.iconPath }`;
    }

    public getPlanTitle(): string {
        if (!this.selectedPlan) {
            return '';
        }

        return this.selectedPlan.title;
    }

    public navigateBack: () => void = (): void => {
        this.location.back();
    };
}
