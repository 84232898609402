import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { ProfilesActions } from '@libs/store/profiles-v2';

import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';

@Component({
    selector: 'access-private-photos-button',
    templateUrl: 'access-private-photos-button.html',
    styleUrls: ['access-private-photos-button.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessPrivatePhotosButton {
    @Input() profile: IProfile;

    constructor(
        protected modalService: ModalService,
        protected profileService: ProfileService,
        protected store: Store,
    ) {

    }

    public requestPrivatePhotoAccess(): void {
        if (UserCommon.isPrivatePhotoRequestPending(this.profile)) {
            this.modalService.open('modules.main.pages.profile.request_access.already_requested');

            return;
        }

        this.modalService.confirm(
            'modules.main.pages.profile.request_access.body',
            (): void => {
                this.profileService.requestAlbumAccess(
                    this.profile.profile_id, (): void => {
                        this.profile.requester_friend_status = UserCommon.REQUEST_PRIVATE_PENDING;
                        this.store.dispatch(ProfilesActions.oneProfileReceived({ profile: this.profile }));
                    }, (): void => {
                        this.modalService.open('modules.main.pages.profile.request_access.already_granted');
                    },
                );
            },
        );
    }

    public canShowPrivatePhotoRequestPending(): boolean {
        return UserCommon.isPrivatePhotoRequestPending(this.profile);
    }

    public isPrivatePhotoRequestInexistent(): boolean {
        return UserCommon.isPrivatePhotoRequestInexistent(this.profile);
    }
}
