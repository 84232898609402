import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IBaseProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { Config } from '@prince/config';
import { CharmModalAnimation } from '@prince/modules/main/modals/charm-modal/animations/charm-modal.animation';
import { ICharmModalData } from '@prince/modules/main/modals/charm-modal/interfaces/charm-modal-data.interface';
import { ImageHelper } from '@prince/utils/image-helper';

@Component({
    selector: 'mp-charm-modal',
    templateUrl: './charm-modal.html',
    styleUrls: ['./charm-modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: CharmModalAnimation,
})
export class CharmModalComponent {
    constructor(
        protected router: Router,
        protected store: Store<IApplicationState>,
        protected modalRef: ModalRef,
        @Inject(MODAL_DATA) public modalData: ICharmModalData,
    ) {
        //
    }

    public getCharmImagePath(): string {
        return `${ Config.imagesFolderBaseUrl }charm/charm-logo-modalview.svg`;
    }

    public getUserPicture(profile: IBaseProfile | undefined): string {
        const defaultImage: string = `/assets/img/${ UserCommon.SILHOUETTE_MALE_FILENAME }.jpg`;

        if (profile === undefined) {
            return defaultImage;
        }

        if (!profile.photo.hasOwnProperty('photo_id')) {
            return UserCommon.getBasicImage(
                profile.sex,
                profile.membership_type_id,
            );
        }

        return ImageHelper.getImage(
            profile.profile_id,
            profile.photo,
            UserCommon.PICTURE_EXTRA_LARGE,
        );
    }

    public closeModal(): void {
        this.modalRef.close();
    }

    public startConversation(user: IBaseProfile): void {
        this.closeModal();
        this.router.navigate([
            '/main',
            'conversation',
            'message',
            user.profile_id,
        ]);
    }
}
