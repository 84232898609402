import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BoostLastViewersServiceCommon } from '@libs/modules/boost/services/boost-last-viewers/boost-last-viewers.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IBoostLatestViewersData } from '@libs/shared/boost/interfaces/latest-viewers-data.interface';
import { IProfile } from '@libs/shared/profile/profile';

import { Config } from '@prince/config';
import { ProfileViewersBaselineService } from '@prince/modules/shared/boost-shared/services/profile-viewers-baseline/profile-viewers-baseline.service';
import { AuthHttpService } from '@prince/services/auth-http.service';
import { AuthenticationService } from '@prince/services/authentication.service';
import { ProfileService } from '@prince/services/profile.service';

@Injectable({
    providedIn: 'root',
})
export class BoostLastViewersService extends BoostLastViewersServiceCommon {
    constructor(
        protected authHttp: AuthHttpService,
        protected auth: AuthenticationService,
        protected profileService: ProfileService,
        protected profileViewersBaselineService: ProfileViewersBaselineService,
    ) {
        super(
            authHttp,
            auth,
            profileService,
            profileViewersBaselineService,
        );
    }

    public getLastViewersProfilesFromBff$(
        quantityOfProfiles: number | undefined = undefined,
    ): Observable<IProfile[]> {
        const boostLastViewersEndpoint: string = `${ Config.boostBFFUrl }/last-boost-views`;

        return this.authHttp
            .get(boostLastViewersEndpoint, {
                take: quantityOfProfiles,
            }).pipe(
                map((authResponse: IAuthResponse<IBoostLatestViewersData>): IProfile[] => {
                    return this.getViewsProfile(authResponse.data.views);
                }),
            );
    };
}
