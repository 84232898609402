import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'generateRange',
    pure: true,
})
export class GenerateRangePipe implements PipeTransform {
    public readonly TUPLE_WRONG_ORDER_ERROR: string = 'Second number of array must be greater than first';

    protected readonly placeholderValue: number = 1;
    protected readonly padStartDesiredStringLength: number = 2;
    protected readonly padStartCharacter: string = '0';

    transform(
        value: number[],
        shouldPadStart: boolean = false,
        shouldReverse: boolean = false,
    ): number[] | string[] {
        const [
            lesserValue,
            greaterValue,
        ]: number[] = value;

        if (greaterValue <= lesserValue) {
            throw new Error(this.TUPLE_WRONG_ORDER_ERROR);
        }

        const rangeWidth: number = greaterValue - lesserValue + 1;
        const rangeArray: number[] = Array(rangeWidth).fill(this.placeholderValue)
            .map((_, index: number): number => index + lesserValue);

        if (shouldPadStart) {
            return this.reverseIfNeeded(rangeArray, shouldReverse)
                .map((element: number): string => element.toString()
                    .padStart(this.padStartDesiredStringLength, this.padStartCharacter));
        }

        return this.reverseIfNeeded(rangeArray, shouldReverse);
    }

    protected reverseIfNeeded(range: number[], shouldReverse: boolean): number[] {
        if (!shouldReverse) {
            return range;
        }

        return range.reverse();
    }
}
