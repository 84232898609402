import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { UserCommon } from '@libs/shared/user/user.common';
import { PushNotificationActions } from '@libs/store/push-notification';

@Component({
    selector: 'mp-ask-notifications-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './ask-notifications-modal.html',
})
export class AskNotificationsModalComponent implements IModalComponent {
    public data: never;

    constructor(
        protected modalRef: ModalRef,
        protected store: Store,
    ) {
        //
    }

    public requestBrowserPermission(): void {
        this.store.dispatch(PushNotificationActions.requestPermission());
        this.closeModal();
    }

    public getUserAgent(): string {
        return navigator.userAgent;
    }

    public getOperationalSystemName(): string {
        const userOs = this.getUserAgent();

        if (userOs.indexOf('Windows') !== -1) {
            return 'windows-notification';
        }

        return 'mac-notification';
    }

    public getOperationalSystemGif(): string {
        return UserCommon.resolveImageName(this.getOperationalSystemName(), '.gif');
    }

    public closeModal(): void {
        this.modalRef.close();
    }
}
