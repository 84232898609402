import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IReferralState, referralAdapter } from '@libs/store/referral/state';

export const selectReferralState = createFeatureSelector<IReferralState>('storeReferralsEntity');

export const selectAll = createSelector(
    selectReferralState,
    referralAdapter.getSelectors().selectAll,
);
