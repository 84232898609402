import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'load-icon-animated',
    templateUrl: 'load-icon-animated.html',
    styleUrls: ['load-icon-animated.scss'],
})
export class LoadIconAnimatedComponent implements OnInit {
    @ViewChild('iconElement', { static: true }) loadIcon: ElementRef;

    @Input() theme: string = '';

    constructor(
        protected renderer2: Renderer2,
    ) {
        //
    }

    ngOnInit(): void {
        this.renderer2.addClass(
            this.loadIcon.nativeElement,
            this.theme,
        );
    }
}
