<div
    class="tab-bar"
    *ngIf="isConnected()"
>
    <navigation-button
        [id]="'tab-bar-search-button'"
        [routeToNavigate]="['/main/search']"
        [text]="'modules.main.pages.search_filter.title'"
        [isPageActive]="isPageActive(PAGE_ID_SEARCH)"
    >
        <i
            class="material-icons"
            [ngClass]="getIconModifierClass(PAGE_ID_SEARCH)"
        >&#xE8B6;</i>
    </navigation-button>
    <navigation-button
        [id]="'tab-bar-conversation-button'"
        [routeToNavigate]="['conversation']"
        [text]="'modules.main.pages.conversation.title'"
        [isPageActive]="isPageActive(PAGE_ID_CONVERSATION)"
    >
        <i
            class="material-icons"
            [ngClass]="getIconModifierClass(PAGE_ID_CONVERSATION)"
        >&#xE8AF;</i>
    </navigation-button>
    <navigation-button
        [id]="'tab-bar-favorites-button'"
        [routeToNavigate]="['favorites']"
        [text]="'modules.main.shared.home_menu.interests'"
        [isPageActive]="isPageActive(PAGE_ID_INTERESTS)"
    >
        <i
            class="material-icons"
            [ngClass]="getIconModifierClass(PAGE_ID_INTERESTS)"
        >&#xE87D;</i>
    </navigation-button>
</div>
