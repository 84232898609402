import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { SubscribedStatusComponentCommon } from '@libs/components/subscribed-status/subscribed-status.component.common';
import { openSlidableMenu, closeSlidableMenu } from '@libs/store/slidable-menu/action';

import { AuthenticationService } from '@prince/services/authentication.service';

@Component({
    selector: 'mp-subscribed-status',
    templateUrl: 'subscribed-status.html',
})
export class SubscribedStatusComponent extends SubscribedStatusComponentCommon {
    @Input() tinyBadges: boolean = false;

    constructor(
        protected auth: AuthenticationService,
        protected store: Store,
    ) {
        super(auth);
    }

    public isTinyBadges(): boolean {
        return this.tinyBadges;
    }

    public openSlidableMenu(): void {
        this.store.dispatch(openSlidableMenu());
    }

    public closeSlidableMenu(): void {
        this.store.dispatch(closeSlidableMenu());
    }
}
