import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { MpCropperjsComponent } from '@libs/integration/mp-cropperjs';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { IModalPhotoData } from '@libs/shared/interfaces/modal-photo-data.interface';
import { IApplicationState } from '@libs/store/application-state';
import { RegistrationActions } from '@libs/store/registration';

import { ImageService } from '@prince/services/image.service';
import { ModalService } from '@prince/services/modal.service';
import { ProfileService } from '@prince/services/profile.service';

interface IMinCropBox {
    width: number;
    height: number;
}

@Component({
    selector: 'mp-picture-crop-modal',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './picture-crop.html',
})
export class PictureCropModalComponent
implements IModalComponent<IModalPhotoData> {
    @ViewChild('angularCropper') public angularCropper: MpCropperjsComponent;

    public sendingPhoto: boolean;
    public cropperImage: string;
    public isRegistration: boolean = false;
    public minCropBox: IMinCropBox = {
        width: 250,
        height: 250,
    };

    constructor(
        protected store: Store<IApplicationState>,
        protected profileService: ProfileService,
        protected http: HttpClient,
        protected modalService: ModalService,
        protected modalRef: ModalRef,
        protected imageService: ImageService,
        @Inject(MODAL_DATA) public data: IModalPhotoData,
    ) {
        this.processPhoto(data.file);
        this.isRegistration = data.isRegistration || false;
    }

    get cropperOptions(): Cropper.Options {
        const boundary: number = Math.min(
            window.innerHeight - 220,
            Math.min(window.innerWidth - 52, 500),
        );
        const viewport: number = boundary * 0.7;

        return {
            modal: true,
            guides: true,
            checkOrientation: true,
            aspectRatio: 1,
            initialAspectRatio: 1,
            viewMode: 2,
            zoomable: true,
            dragMode: 'move',
            minContainerWidth: viewport,
            minContainerHeight: viewport,
            minCropBoxWidth: this.minCropBox.width,
            minCropBoxHeight: this.minCropBox.height,
            zoom: (event: CustomEvent): void => this.zoomCropper(event),
        };
    }

    zoomCropper(event: CustomEvent): void {
        const imageData: Cropper.ImageData = this.angularCropper.cropper.getImageData();

        if (event.detail.ratio > event.detail.oldRatio) {
            return;
        }

        let newCropArea: number = imageData.width;

        if (imageData.width > imageData.height) {
            newCropArea = imageData.height;
        }

        newCropArea = newCropArea / 2;

        this.setCropBoxData({
            width: newCropArea,
            height: newCropArea,
        });
    }

    setCropBoxData(data: Cropper.SetCropBoxDataOptions): void {
        this.angularCropper.cropper.setCropBoxData(data);
    }

    clearCrop(): void {
        this.angularCropper.cropper.clear().crop();
    }

    processPhoto(file: Blob): void {
        const reader: FileReader = new FileReader();
        reader.onloadend = (): void => {
            this.cropperImage = <string>reader.result;
        };
        reader.readAsDataURL(file);
    }

    prepareUploadPhoto(): void {
        this.sendingPhoto = true;

        this.angularCropper.cropper
            .getCroppedCanvas({
                width: 160,
                height: 90,
                minWidth: 800,
                minHeight: 800,
                maxWidth: 4096,
                maxHeight: 4096,
                fillColor: '#FFF',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
            })
            .toBlob((image: Blob): void => {
                if (this.isRegistration) {
                    this.getCroppedForRegistration(image);
                    return;
                }

                this.uploadPhoto(image);
            });
    }

    getCroppedForRegistration(image: Blob): void {
        if (this.data && this.data.callback) {
            this.data.callback({
                image,
            });
        }

        this.closeModal();
    }

    uploadPhoto(image: Blob): void {
        this.imageService.uploadImage({
            image,
            modalData: this.data,
            closeCallback: this.closeModal,
        });
    }

    closeModal = (): void => {
        this.store.dispatch(RegistrationActions.setIsCroppingImage({
            isCroppingImage: false,
        }));

        this.modalRef.close();
    };

    rotateCrop(degrees: number): void {
        this.restoreCropAndZoom();
        this.angularCropper.cropper.rotate(degrees);
    }

    restoreCropAndZoom(): void {
        const containerData: Cropper.ContainerData = this.angularCropper.cropper.getContainerData();

        this.angularCropper.cropper.zoomTo(0, {
            x: containerData.width / 2,
            y: containerData.height / 2,
        });

        this.clearCrop();
    }
}
