import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IBoostToastState } from '@libs/store/boost-toast';
import { boostToastAdapter } from '@libs/store/boost-toast/state';

const selectFeature = createFeatureSelector<IBoostToastState>('boostToast');

export const selectAll = createSelector(
    selectFeature,
    boostToastAdapter.getSelectors().selectAll,
);
