import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';

import { IGenericModalData } from '@libs/components/generic-modal/interfaces/generic-modal-data.interface';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';

@Component({
    selector: 'lib-generic-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './generic-modal.html',
    styleUrls: ['./generic-modal.scss'],
})
export class GenericModalComponent implements OnInit {
    @ViewChild('mainButton', { static: true })
    mainButton: ElementRef<HTMLButtonElement>;

    constructor(
        protected renderer: Renderer2,
        protected modalRef: ModalRef,
        @Inject(MODAL_DATA) public data: IGenericModalData,
    ) {
        //
    }

    ngOnInit(): void {
        this.addButtonTheme();
    }

    protected addButtonTheme(): void {
        if (!this.data.buttonTheme) {
            this.renderer.addClass(
                this.mainButton.nativeElement,
                'generic-modal__content__button--default-colors',
            );

            return;
        }

        this.renderer.addClass(
            this.mainButton.nativeElement,
            this.data.buttonTheme,
        );
    }

    public closeModal(): void {
        this.modalRef.close();
    }

    public getModalTitle(): string {
        if (!this.data.title) {
            return '';
        }

        return this.data.title;
    }

    public getTextContent(): string {
        if (!this.data.message) {
            return '';
        }

        return this.data.message;
    }

    public getButtonText(): string {
        if (!this.data.buttonText) {
            return 'common.ok';
        }

        return this.data.buttonText;
    }

    public closeCallback(): void {
        if (!this.data.closeCallback) {
            this.closeModal();

            return;
        }

        this.data.closeCallback();
    }
}
