import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ShowHideVerifyPhotoNagbarAction } from '@libs/store/ui/actions/photo-verify-nagbar.action';

import { NagbarCommon } from '@prince/modules/nagbar/nagbar.common';
import { AuthenticationService } from '@prince/services/authentication.service';

@Component({
    selector: 'mp-photo-verify-nagbar',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './photo-verify-nagbar.html',
})
export class PhotoVerifyNagbarComponent implements OnInit, OnDestroy {
    static componentName: string = NagbarCommon.PHOTO_VERIFY_NAGBAR_COMPONENT_NAME;
    public isVisible: boolean;
    public unverifiedPhoto: boolean;
    protected subscriptions: Subscription[] = [];

    constructor(
        protected store: Store<IApplicationState>,
        protected auth: AuthenticationService,
    ) {
        //
    }

    ngOnInit(): void {
        this.subscriptions.push(this.store
            .select('uiState')
            .subscribe((state): void => {
                this.isVisible = !state.photoVerifyHidden;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public isPhotoVerified(): boolean {
        return UserCommon.isPhotoVerified(this.auth.get());
    }

    public hasPhotosToVerify(): boolean {
        return this.auth.get() &&
            UserCommon.isBaby(this.auth.get()) &&
            !this.isPhotoVerified() &&
            UserCommon.photoToVerify(this.auth.get()) !== undefined &&
            this.isVisible;
    }

    public canShowNagBar(): boolean {
        return this.auth.get() &&
            UserCommon.isBaby(this.auth.get()) &&
            !this.isPhotoVerified() &&
            UserCommon.photoToVerify(this.auth.get()) === undefined &&
            this.isVisible;
    }

    public hide(): void {
        this.store.dispatch(new ShowHideVerifyPhotoNagbarAction(true));
    }
}
