import { createFeatureSelector, createSelector } from '@ngrx/store';

import { favoriteToastAdapter, IFavoriteToastState } from '@libs/store/favorite-toast/state';

const selectFeature = createFeatureSelector<IFavoriteToastState>('favoriteToast');

export const selectAll = createSelector(
    selectFeature,
    favoriteToastAdapter.getSelectors().selectAll,
);
