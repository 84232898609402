import {
    Directive,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {
    IPaymentOptionBrandData,
} from '@libs/modules/main/pages/payment-method-option/payment-option-brand-data';

@Directive()
export class PaymentMethodOptionComponentCommon {
    @Input() activeClassConditional: boolean = false;
    @Input() disabled: boolean = false;
    @Input() placeholderText: string = '';
    @Input() additionalText: string = '';
    @Input() brands: IPaymentOptionBrandData[] = [];

    @Output() readonly selected: EventEmitter<void> = new EventEmitter<void>();

    selectOption(): void {
        this.selected.emit();
    }

    canShowBrands(): boolean {
        return this.brands.length > 0;
    }

    canShowAdditionalText(): boolean {
        return this.additionalText.length > 0;
    }
}
