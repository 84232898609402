import { createAction, props } from '@ngrx/store';

import { IPendingMessage } from '@libs/store/pending-messages/interface';

export const upsertPengingMessages = createAction(
    '[Pending Message] Upsert Pending Messages',
    props<{ pendingMessages: IPendingMessage[] }>(),
);

export const deletePendingMessage = createAction(
    '[Pending Message] Delete Pending Messages',
    props<{ uuid: string }>(),
);
