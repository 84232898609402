<div class="unsubscribe-content">
    <div
        class="unsubscribe-content__main--animate"
        @slideToggle
    >
        <h1
            class="header-no-email"
            *ngIf="loadingMessage"
        >
            {{ loadingMessage | translate }}
        </h1>
    </div>
</div>
