import { Injectable, OnDestroy } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { AdvancedModalService } from '@prince/services/advanced-modal.service';
import { AuthenticationService } from '@prince/services/authentication.service';

@Injectable()
export class AuthGuard implements OnDestroy, CanActivate {
    protected subscriptions: Subscription[] = [];
    protected url: string;
    protected isVerified: boolean;
    protected isSugarBaby: boolean;
    protected isPaying: boolean;
    protected expressApprovalPaid: boolean;
    protected user: UserCommon | undefined;
    protected hasUserMadeAnyPayments: boolean;
    protected numberOfMessagesReceived: number;

    constructor(
        protected auth: AuthenticationService,
        protected router: Router,
        protected store: Store<IApplicationState>,
        protected modal: AdvancedModalService,
    ) {
        this.subscriptions.push(this.store
            .select('user')
            .subscribe((user: UserCommon): void => {
                if (!user || !Object.keys(user).length || user.profile_id === 0) {
                    return;
                }

                this.user = user;

                this.isSugarBaby = UserCommon.isBaby(user);
                this.isPaying = !MembershipCommon.isFree(user.membership_type_id);

                this.expressApprovalPaid = UserCommon.hasPaidExpressApproval(user);

                this.isVerified = UserCommon.isPhotoVerified(user);
            }),
        );

        this.subscriptions.push(this.store
            .select('stats')
            .subscribe((stats): void => {
                this.hasUserMadeAnyPayments = stats.paymentsMade > 0;
                this.numberOfMessagesReceived = stats.numberOfMessagesReceived;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
        this.subscriptions = [];
    }

    canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        this.url = state.url;

        if (!this.checkLogin()) {
            this.auth.onFailedAttemptToAccessURL(this.url);

            return this.router.createUrlTree(
                ['initial','login'],
                this.getUrlTreeParameters(activatedRoute),
            );
        }

        if (this.checkLoggedOutPages()) {
            return this.router.createUrlTree(
                ['main','home'],
                this.getUrlTreeParameters(activatedRoute),
            );
        }

        if (
            this.checkPhotoVerifyLocation() &&
            !this.checkVerified()
        ) {
            return this.router.createUrlTree(
                ['main','home'],
                this.getUrlTreeParameters(activatedRoute),
            );
        }

        if (
            this.checkExpressApprovalPaymentLocation() &&
            this.expressApprovalPaid
        ) {
            return this.router.createUrlTree(
                ['express-approval'],
                this.getUrlTreeParameters(activatedRoute),
            );
        }

        if (
            this.checkPlansLocation() &&
            !this.isPaying
        ) {
            return this.router.createUrlTree(
                ['main','home'],
                this.getUrlTreeParameters(activatedRoute),
            );
        }

        return true;
    }

    protected getUrlTreeParameters(activatedRoute: ActivatedRouteSnapshot) {
        return {
            queryParams: activatedRoute.root.queryParams,
            fragment: activatedRoute.root.fragment,
        };
    }

    protected logoutIfNotAtInitialOrSignupLocation(): boolean {
        if (this.checkInitialLocation() ||
            this.checkSignupLocation()
        ) {
            return true;
        }

        this.auth.logout();

        return false;
    }

    protected navigateToExpressApprovalIfNotAtItsLocation(): boolean {
        if (this.checkExpressApprovalLocation()) {
            return true;
        }

        this.router.navigate(['express-approval'], { replaceUrl: true });
        return false;
    }

    protected checkLogin(): boolean {
        if (!this.auth.isAuthenticated()) {
            return this.logoutIfNotAtInitialOrSignupLocation();
        }

        if (this.auth.isAtWaitingList()) {
            return this.navigateToExpressApprovalIfNotAtItsLocation();
        }

        if (this.checkExpressApprovalLocation()) {
            this.router.navigate(['main'], { replaceUrl: true });
        }

        return true;
    }

    protected checkLoggedOutPages(): boolean {
        return this.auth.isAuthenticated() &&
            (this.checkInitialLocation() || this.checkSignupLocation());
    }

    protected checkVerified(): boolean {
        return this.isSugarBaby && !this.isVerified;
    }

    protected checkExpressApprovalLocation(): boolean {
        return this.url.indexOf('express-approval') !== -1;
    }

    protected checkInitialLocation(): boolean {
        return this.url.indexOf('initial') !== -1;
    }

    protected checkSignupLocation(): boolean {
        return this.url.indexOf('cadastro') !== -1;
    }

    protected checkExpressApprovalPaymentLocation(): boolean {
        return this.url.indexOf('express-approval/payment') !== -1;
    }

    protected checkPhotoVerifyLocation(): boolean {
        return this.url.indexOf('photo-verify') !== -1;
    }

    protected checkPlansLocation(): boolean {
        return this.url.indexOf('settings/plans') !== -1;
    }
}
