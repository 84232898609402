<div class="membership-badge-extended">
    <div
        class="user-badge-extended membership-badge-extended__elite-badge"
        *ngIf="isElite()"
    >
        <img src="/assets/img/elite-crown-only-white.svg">
        <p>{{ 'modules.main.pages.profile.member_elite' | translate }}</p>
    </div>
    <div
        class="user-badge-extended membership-badge-extended__premium-badge"
        *ngIf="isPremium()"
    >
        <img src="/assets/img/sbp-diamond-icon.svg">
        <p>{{ 'shared.user.memberships.premium' | translate }}</p>
    </div>
</div>
