<li
    role="presentation"
    [ngClass]="{ active: activeClassConditional }"
>
    <button
        (click)="selectOption()"
        role="tab"
        data-toggle="tab"
        [disabled]="disabled"
    >
        <div>
            <div class="radio">
                <div></div>
            </div>
            <div>
                {{ placeholderText | translate }}
                <span *ngIf="canShowAdditionalText()">{{ additionalText | translate }}</span>
            </div>
        </div>
        <ul
            class="cards-flag"
            *ngIf="canShowBrands()"
        >
            <li *ngFor="let brand of brands; trackBy: trackByIndex">
                <img [src]="getBrandUrl(brand)">
            </li>
        </ul>
    </button>
</li>
