import { INewRegistrationPayload } from '@libs/registration-prince/domain/interfaces/new-registration-payload.interface';
import { Registration } from '@libs/registration-prince/domain/registration';

export const registrationPrinceInitialState: INewRegistrationPayload = {
    initialStep: {
        userGender: 0,
        sexualPreference: 0,
    },
    loginData: {
        username: '',
        email: '',
        password: '',
    },
    personalData: {
        birthdate: {
            day: '',
            month: '',
            year: '',
        },
        civilStatus: '',
        cityId: '',
        stateId: '',
        countryId: '',
    },
    aboutYou: {
        aboutMe: '',
        searchingFor: '',
        referral: '',
    },
    isCroppingImage: false,
    isSubmittingForm: false,
    hasSubmittedForm: false,
    hasAccessedModalRoute: false,
    submittingFormText: Registration.SUBMITTING_FORM_TEXT_STAGE_1,
};
