import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';

import { GlobalObjectService } from '@prince/services/global-object-service';
import { ProfileService } from '@prince/services/profile.service';

import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthenticationService extends AuthenticationServiceCommon {
    constructor(
        protected store: Store<IApplicationState>,
        protected cookies: CookieService,
        protected router: Router,
        protected profileService: ProfileService,
        protected route: ActivatedRoute,
        protected globalObjectService: GlobalObjectService,
        protected cookieService: CookieService,
    ) {
        super(store, router, profileService);
    }

    public mpOnInit(): void {
        this.checkToken();

        super.mpOnInit();
    }

    navigateToHome(): void {
        this.navigate(['main', 'home']);
    }

    public onFailedAttemptToAccessURL(url: string): void {
        this.cookies.set(
            this.COOKIE_REDIRECT_URL_NAME,
            url,
            {
                expires: 7,
                path: '/',
            },
        );
    }

    protected attemptRedirectToFailedURL(): boolean {
        const url: string = this.cookies.get(this.COOKIE_REDIRECT_URL_NAME);
        const openedTab: string = 'openedTab=true';

        if (!url || url.indexOf('/') !== 0) {
            return false;
        }

        this.cookies.delete(
            this.COOKIE_REDIRECT_URL_NAME,
            '/',
        );

        const queryToken: object = {
            1: '?',
            0: '&',
        };

        const queryStringReference: number = +(url.indexOf('?') === -1);
        this.router.navigateByUrl(
            url + queryToken[queryStringReference] + openedTab,
            this.getNavigationExtras(),
        );

        return true;
    }

    protected hasRoute(route: string): boolean {
        return this.globalObjectService
            .window.location.pathname.indexOf(route) !== -1;
    }

    protected canCallDefaultRoute(): boolean {
        return this.globalObjectService
            .window.location.pathname === '/' ||
            this.hasRoute('/express-approval') ||
            this.hasRoute('/edit-profile') ||
            this.hasRoute('/cadastro') ||
            this.hasInitialRoutes();
    }

    protected getNavigationExtras(): NavigationExtras {
        return {
            replaceUrl: true,
            queryParamsHandling: 'merge',
            preserveFragment: true,
        };
    }

    protected hasInitialRoutes(): boolean {
        return this.hasRoute('initial/login');
    }

    protected checkToken(): void {
        const tokenMatches: string[] = this.globalObjectService
            .window.location.href.match(/token=([^&]*)/g);

        if (tokenMatches !== null &&
            tokenMatches !== undefined &&
            tokenMatches.length >= 1
        ) {
            const token: string = tokenMatches[0].replace(/token=/, '');
            this.logout();
            this.setToken(token);
            this.router.navigate(
                ['.'],
                { relativeTo: this.route },
            );
        }
    }

    public isAtWaitingList(): boolean {
        return this.user && (
            this.user.status === UserCommon.STATUS_ON_HOLD ||
            this.user.status === UserCommon.STATUS_PENDING ||
            this.user.email_verified !== 'yes'
        );
    }

    protected redirectByStatus(): void {
        if(this.isOnTheEmailVerificationRoute()) {
            return;
        }

        if (this.isAtWaitingList()) {
            this.handleExpressApproval();
            return;
        }

        if (this.isProfileEdit()) {
            this.navigate(['main', 'edit-profile']);

            return;
        }

        if (!this.isActive()) {
            this.logout();
            return;
        }

        if (this.attemptRedirectToFailedURL()) {
            return;
        }

        this.onNoForcedRouteFound();
    }

    protected isOnTheEmailVerificationRoute(): boolean {
        return this.hasRoute('email-verify');
    }

    public onStatusChange(): void {
        this.onLogin$.next();

        this.redirectByStatus();
    }

}
