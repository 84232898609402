export class Money {
    protected real: number;
    protected cents: number;

    constructor(
        rawValue: number,
    ) {
        this.real = Math.floor(rawValue);
        this.cents = Math.round((rawValue - this.real) * 100);
    }

    toNumber(): number {
        return this.real + (this.cents / 100);
    }

    toString(): string {
        return this.real + ',' + this.decimalString();
    }

    protected decimalString(): string {
        return this.cents
            .toString()
            .padStart(2, '0');
    }
}
