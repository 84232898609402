import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IProduct } from '@libs/store/product/interface';

export interface IProductState extends EntityState<IProduct> {
    //
}

export const productAdapter: EntityAdapter<IProduct> = createEntityAdapter<IProduct>({ });

export const productInitialState: IProductState = productAdapter.getInitialState({
    //
});
