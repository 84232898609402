import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'mp-hash-unavailable',
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: './hash-unavailable.html',
})
export class HashUnavailableComponent {
    email: string = '';

    constructor(
        public router: Router,
    ) {
        //
    }

    goBack(): void {
        this.router.navigate(['main', 'home']);
    }
}
