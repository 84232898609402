import { Directive, Input } from '@angular/core';

import {
    IUnsubscribeActionItem,
} from '@libs/shared/interfaces/unsubscribe-action-item';

@Directive()
export class EmailUnsubscribeSuccessComponentCommon {
    @Input() successHeaderTranslation: string | any;
    @Input() successDescriptionTranslation: string | any;
    @Input() actionItems: IUnsubscribeActionItem[] | undefined;
}
