import { Subscription } from 'rxjs';

import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';

export abstract class ReportServiceCommon {
    constructor(protected authHttp: AuthHttpServiceCommon) {
        //
    }

    public abstract report(
        profileId: number,
        reason: string,
        successCallback: () => void,
        errorCallback: (error: any) => void,
    ): Subscription;
}
