import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import {
    PaymentModalFormServiceCommon,
} from '@libs/services/payment-modal-form/payment-modal-form.service.common';

import { AuthenticationService } from '@prince/services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentModalFormService extends PaymentModalFormServiceCommon {
    constructor(
        protected formBuilder: FormBuilder,
        protected authenticationService: AuthenticationService,
    ) {
        super(formBuilder, authenticationService);
    }
}
