import { createAction, props } from '@ngrx/store';

enum Actions {
    UPDATE_PASSWORD = '[Password] - Update Password',
    HANDLE_UPDATE_PASSWORD_SUCCESS = '[Password] - Handle Update Password Success',
    HANDLE_UPDATE_PASSWORD_FAILURE = '[Password] - Handle Update Password Failure',
    SET_IS_SAVING_NEW_PASSWORD = '[Password] - Set is Saving New Password',
}

export const updatePassword = createAction(
    Actions.UPDATE_PASSWORD,
);

export const handleUpdatePasswordSuccess = createAction(
    Actions.HANDLE_UPDATE_PASSWORD_SUCCESS,
    props<{ token: string }>(),
);

export const handleUpdatePasswordFailure = createAction(
    Actions.HANDLE_UPDATE_PASSWORD_FAILURE,
    props<{ status: number }>(),
);

export const setIsSavingNewPassword = createAction(
    Actions.SET_IS_SAVING_NEW_PASSWORD,
    props<{ isSavingNewPassword: boolean }>(),
);
