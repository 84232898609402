import { CollapseComponent } from '@libs/components/collapse/collapse.component';
import { IBirthdateFields } from '@libs/registration-prince/domain/interfaces/birthdate-fields.interface';
import { Registration } from '@libs/registration-prince/domain/registration';
import { SomeGender } from '@libs/registration-prince/domain/types/genders.type';
import { SomeSexualPreference } from '@libs/registration-prince/domain/types/sexual-preferences.type';

export class RegistrationUtils {
    public static shouldOpenCollapse(
        recoveredAnswer: string,
    ): boolean {
        if (recoveredAnswer !== '') {
            return false;
        }

        return true;
    }

    public static activateDropdown(component: CollapseComponent | undefined): void {
        if (component === undefined || component.value !== '') {
            return;
        }

        component.collapseOpened = true;
    }

    public static getIndexRangeForFileInputs(): number[] {
        return [0, 1];
    }

    public static getRegistreeAge(birthdateObject: IBirthdateFields): number {
        const birthdate: Date = new Date(
            `${ birthdateObject.year }-${ birthdateObject.month }-${ birthdateObject.day }`,
        );
        const ageDifferenceInMilliseconds: number = Date.now() - birthdate.getTime();

        return new Date(ageDifferenceInMilliseconds).getUTCFullYear() - 1970;
    }

    public static getBirthYearRangeTuple(): number[] {
        const laterYear: number = new Date().getFullYear() - Registration.MINIMUM_AGE;
        const earlierYear: number = laterYear - Registration.DIFFERENCE_TO_100_YEARS;

        return [earlierYear, laterYear];
    }

    public static removeUndueSpacesFromUsername(username: string): string {
        const hasUndueSpaces: boolean = Registration.USERNAME_UNDUE_SPACES_PATTERN.test(username);

        if (!hasUndueSpaces) {
            return username;
        }

        return username.replace(Registration.USERNAME_UNDUE_SPACES_PATTERN, '');
    }

    public static padDateWithZero(dayOrMonth: number): string {
        return String(dayOrMonth).padStart(2, '0');
    }

    public static getCurrentStep(routerUrl: string): string {
        const outletName: string = Registration.MODAL_OUTLET_NAME;
        const outletNameIndicatorLength: number = outletName.length + 1;
        const startTrimIndex: number = routerUrl.lastIndexOf(outletName) + outletNameIndicatorLength;
        const unwantedCharacters: RegExp = /\)|\s/g;
        const currentStep: string = routerUrl.substring(startTrimIndex).replace(unwantedCharacters, '');

        return currentStep;
    }

    public static getGenderOptions(): SomeGender[] {
        return [
            Registration.GENDER_MALE,
            Registration.GENDER_FEMALE,
        ];
    }

    public static getGenderTranslationKey(gender: SomeGender): string {
        return {
            [Registration.GENDER_FEMALE]: Registration.GENDER_FEMALE_TEXT,
            [Registration.GENDER_MALE]: Registration.GENDER_MALE_TEXT,
        }[gender];
    }

    public static isMale(gender: SomeGender): boolean {
        return gender === Registration.GENDER_MALE;
    }

    public static getUserLookingForOptions(): SomeSexualPreference[] {
        return [
            Registration.GENDER_MALE,
            Registration.GENDER_FEMALE,
            Registration.BISEXUAL,
        ];
    }

    public static getSexualPreferenceTranslationKey(lookingFor: SomeSexualPreference): string {
        return {
            [Registration.GENDER_FEMALE]: Registration.USER_LOOKING_FOR_WOMEN_TEXT,
            [Registration.GENDER_MALE]: Registration.USER_LOOKING_FOR_MEN_TEXT,
            [Registration.BISEXUAL]: Registration.USER_LOOKING_FOR_MEN_AND_WOMEN_TEXT,
        }[lookingFor];
    }
}
