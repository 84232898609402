import {
    HttpErrorResponse,
    HttpParams,
} from '@angular/common/http';
import { Directive } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { CustomEncoder } from '@libs/modules/encoder/custom.enconder';
import {
    ICreateOrderParams,
    IDataToPrepareForPayment,
    IMoipHolderInfo,
    IMoipPayData,
    IMoipPurchaseInfo,
    IOrder,
    IOrderParams,
    IOrderResponse,
    IPayment,
    IPaymentMethod,
} from '@libs/modules/main/services/payment/moip/moip';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import {
    IInformApiParams,
    PaymentServiceCommon,
} from '@libs/modules/main/services/payment/payment.service.common';
import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import {
    AnalyticsServiceCommon,
} from '@libs/services/analytics/analytics.service.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import {
    MembershipCommon,
    MembershipType,
} from '@libs/shared/membership/membership.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserServiceCommon } from '@libs/shared/user/user.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { PaymentInfoActions } from '@libs/store/payment-info';
import { paymentError } from '@libs/store/payment/actions';

@Directive()
export abstract class MoipServiceCommon extends PaymentServiceCommon {
    readonly MONEY_FACTOR: number = 100;
    readonly DEFAULT_QUANTITY: number = 1;
    readonly BOLETO_INSTALLMENT_COUNT: number = 1;
    readonly BOLETO_INTEREST: number = 0;

    readonly ORDER_NOT_CREATED_ERROR: string = 'Order Not Created';
    readonly MOIP_BOLETO_DOES_NOT_INFORM_API: string = 'Moip boleto does not inform API';
    protected readonly MOIP_PAYMENT_DEFAULT_ERROR: string = 'order.not.paid';

    protected readonly ORIGIN_WEB_TEXT: string = 'web';
    protected readonly ORIGIN_ANDROID_TEXT: string = 'android';

    readonly ACCEPTABLE_MOIP_PAYMENT_RESPONSES: string[] = [
        'AUTHORIZED',
        'IN_ANALYSIS',
    ];

    constructor(
        protected translate: TranslateService,
        protected auth: AuthenticationServiceCommon,
        protected authHttp: AuthHttpServiceCommon,
        protected store: Store<IApplicationState>,
        protected analytics: AnalyticsServiceCommon,
        protected userService: UserServiceCommon,
        protected profileService: ProfileServiceCommon,
        protected router: Router,
    ) {
        super(
            auth,
            authHttp,
            store,
            analytics,
            userService,
            profileService,
            router,
        );
    }

    protected abstract encryptData(
        cardNumber: string,
        cvcNumber: string,
        date: string,
    ): string;

    abstract getOrigin(): string;

    public moipPrice(value: number): number {
        return Math.round(value * this.MONEY_FACTOR);
    }

    public generateBoleto(
        membershipId: MembershipType,
        price: number,
        cpf: string,
    ): Observable<IAuthResponse<IOrderResponse>> {
        return this.createOrder({
            purchaseInfo: {
                subtotalAmount: price,
                totalAmount: price,
            },
            holderInfo: {
                cpf,
                membershipId,
                name: '',
            },
            isBoleto: true,
        }).pipe(
            switchMap((orderResponse: IAuthResponse<IOrderResponse>): Observable<IAuthResponse<IOrderResponse>> => {
                if (orderResponse.status !== 201) {
                    throw new Error(this.ORDER_NOT_CREATED_ERROR);
                }

                const orderId: string = orderResponse.data.id;

                return this.payOrder({
                    isBoleto: true,
                    orderId,
                    cardInfo: {
                        date: '',
                        cardNumber: '',
                        cvcNumber: '',
                        installments: this.BOLETO_INSTALLMENT_COUNT,
                        interest: this.BOLETO_INTEREST,
                    },
                    holderInfo: {
                        cpf,
                        name: '',
                        membershipId: MembershipType.MEMBERSHIP_TYPE_NONE,
                    },
                });
            }),
        );
    }

    public informAPI({
        isBoleto,
        membershipId,
        extraParams,
    }: IInformApiParams): Observable<any> {
        if (isBoleto) {
            return throwError(this.MOIP_BOLETO_DOES_NOT_INFORM_API);
        }

        return super.informAPI({
            isBoleto,
            membershipId,
            extraParams,
        });
    }

    public createOrder({
        holderInfo,
        purchaseInfo,
        isBoleto = false,
    }: ICreateOrderParams): Observable<IAuthResponse<IOrderResponse>> {
        if (purchaseInfo.totalAmount < purchaseInfo.subtotalAmount) {
            return throwError(this.ORDER_NOT_CREATED_ERROR);
        }

        if (!isBoleto) {
            this.store.dispatch(PaymentInfoActions.setPaymentStatus({
                paymentStatus: PaymentStatus.PAYMENT_PROCESSING,
            }));
        }

        const user: UserCommon = this.auth.get();

        const description: string = this.getPaymentDescription(holderInfo, purchaseInfo);

        const detail: { description: string, origin: string } = {
            description,
            origin: this.getOrigin(),
        };

        const params: IOrder = {
            ownId: Math.random().toString(36),
            amount: {
                currency: PaymentCommon.CURRENCY_BRAZILIAN_REAL,
                subtotals: {
                    addition: this.moipPrice(purchaseInfo.totalAmount - purchaseInfo.subtotalAmount),
                },
            },
            items: [{
                product: this.getProduct(holderInfo, purchaseInfo),
                quantity: this.DEFAULT_QUANTITY,
                detail: JSON.stringify(detail),
                price: this.moipPrice(purchaseInfo.subtotalAmount),
            }],
            customer: {
                ownId: user.profile_id,
                email: this.getDefaultEmail(user.profile_id),
                fullname: user.username,
                birthdate: user.birthdate,
                shippingAddress: {
                    city: this.getConfig().payment.boletoCity,
                    complement: this.getConfig().payment.boletoComplement,
                    country: 'BRA',
                    district: this.getConfig().payment.boletoDistrict,
                    state: this.getConfig().payment.boletoState,
                    street: this.getConfig().payment.boletoStreetOnly,
                    streetNumber: this.getConfig().payment.boletoStreetNumber,
                    zipCode: this.formatNumber(this.getConfig().payment.boletoCEP),
                },
                taxDocument: {
                    type: 'CPF',
                    number: holderInfo.cpf,
                },
            },
        };

        const endpoint: string = `${ this.getConfig().serverIp }payment/moip/orders`;

        return this.authHttp.post(endpoint, params).pipe(
            catchError((error: HttpErrorResponse): Observable<never> => {
                this.store.dispatch(paymentError({ product: purchaseInfo.product ?? holderInfo.membershipId }));
                return this.throwErrors(error);
            }),
        );
    }

    public pay({
        holderInfo,
        cardInfo,
        purchaseInfo,
    }: IMoipPayData): Observable<unknown> {
        let orderId: string = '';
        return this.createOrder({
            holderInfo,
            cardInfo,
            purchaseInfo,
            isBoleto: false,
        }).pipe(
            switchMap((orderResponse: IAuthResponse<IOrderResponse>): Observable<IAuthResponse<IOrderResponse>> => {
                if (orderResponse.status !== 201) {
                    return throwError(this.ORDER_NOT_CREATED_ERROR);
                }

                orderId = orderResponse.data.id;

                return this.payOrder({
                    holderInfo,
                    cardInfo,
                    orderId,
                    isBoleto: false,
                    purchaseInfo,
                });
            }),
            switchMap((dataPay: IAuthResponse<IOrderResponse>): Observable<unknown> => {
                return this.afterPaymentByCard(
                    holderInfo.membershipId,
                    new HttpParams({ encoder: new CustomEncoder() })
                        .set('order_id', orderId)
                        .set(
                            'hash',
                            this.encryptData(
                                cardInfo.cardNumber,
                                cardInfo.cvcNumber,
                                cardInfo.date,
                            ),
                        )
                        .set('fullname', holderInfo.name)
                        .set('cpf', holderInfo.cpf)
                        .set('price', purchaseInfo.subtotalAmount.toString())
                        .set('interest', cardInfo.interest.toString()),
                    dataPay,
                );
            }),
        );
    }

    getProduct(
        holderInfo: IMoipHolderInfo,
        purchaseInfo: IMoipPurchaseInfo,
    ): number | string {
        if (holderInfo.membershipId !== undefined) {
            return holderInfo.membershipId;
        }

        if (purchaseInfo.product !== undefined) {
            return JSON.stringify(purchaseInfo.product);
        }

        return '';
    }

    getPaymentDescription(
        holderInfo: IMoipHolderInfo,
        purchaseInfo: IMoipPurchaseInfo,
    ): string {
        if (holderInfo.membershipId !== undefined) {
            return (
                'Membership ' +
                MembershipCommon.getMachineMembership(holderInfo.membershipId)
            );
        }

        if (purchaseInfo.detailName !== undefined) {
            return purchaseInfo.detailName;
        }

        return '';
    }

    protected afterPaymentByCard(
        membershipId: MembershipType,
        extraParams: HttpParams,
        orderResponse: IAuthResponse<IOrderResponse>,
    ): Observable<unknown> {
        const moipPaymentStatus: string = orderResponse.data.status;

        if (this.checkMoipResponse(moipPaymentStatus) === undefined) {
            this.store.dispatch(PaymentInfoActions.setPaymentStatus({
                paymentStatus: PaymentStatus.PAYMENT_ERROR,
            }));

            throwError(this.MOIP_PAYMENT_DEFAULT_ERROR);
        }

        this.tryInformAPI({
            isBoleto: false,
            membershipId,
            extraParams,
        });

        return of({ });
    }

    protected preparePaymentData({
        cpf,
        date,
        name,
        cardNumber,
        cvcNumber,
    }: IDataToPrepareForPayment): IPaymentMethod {
        let hashValue: string = '';

        if (cardNumber !== '' ||
            cvcNumber !== '' ||
            date !== ''
        ) {
            hashValue = this.encryptData(
                cardNumber,
                cvcNumber,
                date,
            );
        }

        return {
            method: PaymentCommon.PAYMENT_METHOD_CREDIT_CARD,
            creditCard: {
                hash: hashValue,
                store: false,
                holder: {
                    fullname: name,
                    taxDocument: {
                        type: 'CPF',
                        number: cpf,
                    },
                },
            },
        };
    }

    protected getBoletoData(): IPaymentMethod {
        const data: Date = new Date();
        data.setDate(data.getDate() + 3);

        return {
            method: PaymentCommon.PAYMENT_METHOD_BOLETO,
            boleto: {
                expirationDate: data.toISOString().substring(0, 10),
                instructionLines: {
                    first: this.translate.instant('modules.main.pages.payment.boleto.blurb1'),
                    second: this.translate.instant('modules.main.pages.payment.boleto.blurb2'),
                    third: '',
                },
                logoUri: 'https://beta.meupatrocinio.com/meu-patrocinio-logo-v02.c0a97031240fffabc7c35fc8eab68612.svg',
            },
        };
    }

    protected payOrder({
        isBoleto = false,
        orderId = '',
        cardInfo,
        holderInfo,
        purchaseInfo,
    }: IOrderParams): Observable<IAuthResponse<IOrderResponse>> {
        const endpoint: string = `${ this.getConfig().serverIp }payment/moip/orders/${ orderId }/payments`;

        let paymentData: IPaymentMethod = this.preparePaymentData({
            cpf: holderInfo.cpf,
            name: holderInfo.name,
            date: cardInfo.date,
            cardNumber: cardInfo.cardNumber,
            cvcNumber: cardInfo.cvcNumber,
        });

        if (isBoleto) {
            paymentData = this.getBoletoData();
        }

        const params: IPayment = {
            installmentCount: cardInfo.installments,
            statementDescriptor: 'ecmoip*MEUPAT',
            fundingInstrument: paymentData,
        };

        return this.authHttp.post(endpoint, params).pipe(
            catchError((error: HttpErrorResponse): Observable<never> => {
                this.store.dispatch(paymentError({ product: purchaseInfo.product ?? holderInfo.membershipId }));

                return this.throwErrors(error);
            }),
        );
    }

    protected checkMoipResponse(responseStatus: string): string | undefined {
        return this.ACCEPTABLE_MOIP_PAYMENT_RESPONSES.find((response: string): boolean => {
            return response === responseStatus;
        });
    }
}
