export {
    productAdapter,
    productInitialState,
    IProductState,
} from '@libs/store/product/state';

import * as ProductActions from '@libs/store/product/actions';
import * as ProductReducer from '@libs/store/product/reducer';
import * as ProductSelectors from '@libs/store/product/selectors';

export {
    ProductActions,
    ProductReducer,
    ProductSelectors,
};
