<div class="payment-methods">
    <h3 class="payment-methods__title">
        {{ 'modules.main.pages.upgrade_account.payments.types' | translate }}
    </h3>
    <div class="payment-methods__icons">
        <img
            *ngFor="let method of getPaymentMethods()"
            [src]="getMethodIcon(method)"
            [alt]="method.name"
        >
    </div>
</div>
