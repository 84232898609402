<picture>
    <source
        *ngFor="let srcset of srcsets; let srcsetIndex = index"
        [srcset]="getSrcsetString(srcsetIndex)"
        [media]="getMedia(srcsetIndex)"
    >
    <img
        [src]="url"
        [class]="imgClass"
    >
</picture>
