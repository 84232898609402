import { Directive } from '@angular/core';

import {
    SlidableMenuServiceCommon,
} from '@libs/modules/main/services/slidable-menu/slidable-menu.service.common';

@Directive()
export class SlidableMenuComponentCommon {
    constructor(
        protected slidableMenuService: SlidableMenuServiceCommon,
    ) {
        //
    }
}
