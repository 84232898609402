import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ModalRef } from '@libs/services/modal/modal-ref';
import { IModalComponent } from '@libs/services/modal/modal.interfaces';

@Component({
    selector: 'mp-change-password-modal',
    templateUrl: './change-password-modal.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ChangePasswordModalComponent implements IModalComponent {
    public data: never;

    constructor(
        protected modalRef: ModalRef,
    ) {
        //
    }

    closeModal(): void {
        this.modalRef.close();
    }
}
