import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class CouponFormService {
    protected couponForm: FormGroup = new FormGroup({ });

    constructor(
        protected formBuilder: FormBuilder,
    ) {
        //
    }

    public couponFormSetup(): void {
        this.couponForm = this.formBuilder.group({
            ['coupon']: ['', Validators.required],
        });
    }

    public getCouponFormInstance(): FormGroup {
        return this.couponForm;
    }
}
