import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mp-generic-input',
    templateUrl: 'generic-input.html',
    styleUrls: ['generic-input.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericInputComponent implements AfterViewInit {
    @ViewChild('inputElement', { static: true }) input!: ElementRef<HTMLInputElement>;

    @Input() public id: string = '';
    @Input() public placeholder: string = '';
    @Input() public label: string = '';
    @Input() public type: string = '';
    @Input() public controlName: string ='';
    @Input() public form: FormGroup = new FormGroup({ });
    @Input() public errorMessage: string = '';
    @Input() public shouldAutoFocus: boolean = false;

    @Output() public inputChange: EventEmitter<void> = new EventEmitter();

    constructor(
        protected translateService: TranslateService,
    ) {
        //
    }

    ngAfterViewInit(): void {
        this.autoFocusInputIfShould();
    }

    protected autoFocusInputIfShould(): void {
        if (!this.shouldAutoFocus) {
            return;
        }

        this.input.nativeElement.focus();
    }

    public getInputInstructionId(id: string): string {
        return `${ id }_instruction`;
    }

    public emitInputChangeEvent(): void {
        this.inputChange.emit();
    }

    public hasLabel(): boolean {
        return this.label !== '';
    }
}
