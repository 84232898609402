import {
    HttpClient,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { AuthRequestFacadeCommon } from '@libs/modules/main/services/auth-request-facade/auth-request-facade.common';
import { AnalyticsServiceCommon } from '@libs/services/analytics/analytics.service.common';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import {
    IEditableProfileFields,
    IProfile,
} from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { ProfilesActions } from '@libs/store/profiles-v2';
import { IPremiumSettings } from '@libs/store/ui/premium-settings';

@Injectable({
    providedIn: 'root',
})
export abstract class ProfileServiceCommon {
    protected subscriptions: Subscription[] = [];
    protected blockedProfileIds: number[] = [];
    protected myFavoritesProfileIds: number[] = [];

    constructor(
        protected store: Store<IApplicationState>,
        protected authHttp: AuthHttpServiceCommon,
        protected authRequestFacade: AuthRequestFacadeCommon,
        protected http: HttpClient,
        protected analytics: AnalyticsServiceCommon,
    ) {
        this.subscriptions.push(this.store.select('storeProfileBlockedUsers')
            .subscribe((storeProfileBlockedUsers: number[]): void => {
                this.blockedProfileIds = storeProfileBlockedUsers;
            }),
        );

        this.subscriptions.push(this.store.select('storeMyFavProfiles')
            .subscribe((myFavoritesProfileIds: number[]): void => {
                this.myFavoritesProfileIds = myFavoritesProfileIds;
            }),
        );
    }

    abstract getOrigin(): string;

    public updateProfile(profile: IProfile): void {
        if (!profile) {
            throw new Error('Invalid response from the API. Null profile were sent.');
        }

        this.store.dispatch(ProfilesActions.oneProfileReceived({ profile }));
    }

    public updateBulkProfiles(profiles: IProfile[]): void {
        if (profiles.some((profile: IProfile): boolean => !profile)) {
            throw new Error('Invalid response from the API. Null profiles were sent.');
        }

        this.store.dispatch(ProfilesActions.manyProfilesReceived({ profiles }));
    }

    public abstract getSelf$(): Observable<IAuthResponse>;

    public updateSelf(): void {
        this.subscriptions.push(this.getSelf$().subscribe());
    }

    public abstract deactivateSelf(reason: string): Observable<IAuthResponse>;

    public abstract downloadProfile(profileId: number): Observable<IAuthResponse>;

    public abstract downloadBulkProfiles(profileIds: number[]): Observable<IAuthResponse>;

    public abstract downloadBulkSwipeProfiles(profileIds: number[]): Observable<IAuthResponse>;

    public abstract downloadMyAlbumAccess(page: number): Observable<IAuthResponse>;

    public abstract downloadOtherAlbumAccess(page: number): Observable<IAuthResponse>;

    public abstract updateOtherAlbumAccess(page?: number): void;

    public abstract grantPrivateAlbumAccess(
        profileId: number,
        callbackSuccessFunction?: () => void,
        callbackErrorFunction?: () => void,
    ): void;

    public abstract revokePrivateAlbumAccessBulk(
        profileIds: number[],
        callbackSuccessFunction?: () => void,
        callbackErrorFunction?: () => void,
    ): void;

    public abstract requestAlbumAccess(
        profileId: number,
        callbackSuccess?: () => void,
        callbackError?: () => void,
    ): Subscription;

    public abstract deletePhoto(
        photo_id: number
    ): Observable<IAuthResponse>;

    public abstract updatePassword(
        password: string,
        passwordConf: string,
        passwordNew: string,
        callbackSuccess?: () => void,
        callbackError?: (response: HttpErrorResponse) => void,
    ): void;

    public abstract updatePasswordAuth(
        passwordNew: string,
        passwordConfirmation: string,
        tmpToken: string,
        callbackSuccess?: (token: string, status: string) => void,
        callbackError?: (response: any) => void,
    ): Subscription;

    public abstract changeProfilePhoto(
        newProfilePhotoId: number,
        errorCallback: (err: any) => void
    ): Subscription;

    public abstract updateUser(
        data: IEditableProfileFields,
    ): Observable<IAuthResponse>;

    public uploadPhoto(uri: any,
        type: any,
        uploadCallback: (uri: any, photoType: string, token: string) => void,
    ): void {
        this.authHttp.uploadPhoto(uri, type, uploadCallback);
    }

    public abstract updateEmailAuth(
        email: string,
        email_confirmation: string,
        password: string,
    ): Observable<IAuthResponse>;

    public abstract like(profile: IProfile | undefined): void;

    public abstract unLike(profile: IProfile): Subscription;

    public abstract getEmailNotificationSettings(): Observable<IAuthResponse>;

    public abstract setEmailNotificationSettings(data: any): Observable<IAuthResponse>;

    public abstract setVisibility(value: boolean): Observable<IAuthResponse>;

    public abstract getPremiumSettings(): Observable<IAuthResponse>;

    public abstract setPremiumSettings(settings: IPremiumSettings): Observable<IAuthResponse>;

    public abstract askMoreInformation(profile: IProfile): Observable<IAuthResponse>;

    public abstract acceptTerms(): Observable<IAuthResponse>;

    isProfileBlocked(profile: IProfile | undefined): boolean {
        if (profile === undefined) {
            return false;
        }

        return this.blockedProfileIds.indexOf(profile.profile_id) !== -1;
    }

    isProfileBlockedById(profileId: number): boolean {
        return this.blockedProfileIds.indexOf(profileId) !== -1;
    }

    isProfileAvailable(profile: IProfile | undefined): boolean {
        return profile !== undefined &&
            profile.status !== UserCommon.STATUS_UNAVAILABLE &&
            !this.isProfileBlocked(profile);
    }

    isProfileFavorite(profile: IProfile | undefined): boolean {
        if (profile === undefined) {
            return false;
        }

        return this.myFavoritesProfileIds.indexOf(profile.profile_id) !== -1;
    }

    getMyFavoritesProfileIds(): number[] {
        return this.myFavoritesProfileIds;
    }
}
