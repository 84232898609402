import { createAction, props } from '@ngrx/store';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IPaymentInfo } from '@libs/store/payment-info/interface';
import { IAccessToCheckoutPayload } from '@libs/store/payment-info/interfaces/access-to-checkout-payload.interface';

enum Actions {
    FETCH_PAYMENT_HISTORY_ACTION = '[PaymentInfo] - Fetch Payment History Action',
    LOAD_PAYMENT_INFO_ACTION = '[PaymentInfo] - Load PaymentInfo Action',
    UPSERT_PAYMENT_INFO_ACTION = '[PaymentInfo] - Upsert Many PaymentInfo Action',
    SET_PAYMENT_STATUS_ACTION = '[PaymentInfo] - Set PaymentStatus Action',
    CHECK_PAYMENT_UPDATED_STATUS = '[PaymentInfo] - Check Updated PaymentInfo Action',
    HANDLE_UPDATED_RESPONSE = '[PaymentInfo] - Handle Updated Response Action',
    PAYMENT_HANDLE_ERROR = '[PaymentInfo] - Handle Payment Error Action',
    PAYMENT_SET_IS_PAYING = '[PaymentInfo] - Set Is Paying Action',
    PAYMENT_HANDLE_REDIRECT = '[PaymentInfo] - Handle Redirect Action',
    PAYMENT_SET_WAS_REDIRECTED = '[PaymentInfo] - Set Was Redirected Action',
    SET_ALLCASH_PAYMENT_STATUS = '[PaymentInfo] - Set AllCash Payment Status Action',
    ALLCASH_PAYMENT_FAIL_REDIRECT_TO_CHECKOUT = '[PaymentInfo] - AllCash Payment Fail Redirect to Checkout',
    ALLCASH_PAYMENT_SUCCESS_REDIRECT_TO_HOME = '[PaymentInfo] - AllCash Payment Success Redirect to Home',
    HANDLE_PAYMENT_STATUS = '[PaymentInfo] - Initialize Payment Processing',
    PROCESSING_PAYMENT_EXPRESS_APPROVAL = '[PaymentInfo] - Processing Payment Express Approval',
    HANDLE_ACCESS_TO_CHECKOUT = '[PaymentInfo] - Handle Access to Checkout',
    HANDLE_ACCESS_TO_TRIAL_CHECKOUT = '[PaymentInfo] - Handle Access to Trial checkout',
    NAVIGATE_AFTER_RESOLVING_PLANS = '[PaymentInfo] - Navigate After Resolving Plans',
    SET_HAS_RESOLVED_PLANS = '[PaymentInfo] - Set Has Resolved Plans',
    RESET_IS_PAYING_AFTER_DELAY = '[PaymentInfo] - Reset Is Paying After Delay',
    NAVIGATE_AFTER_PAYMENT = '[PaymentInfo] - Navigate After Pagseguro Payment',
    UPDATE_USER_AND_LOAD_BOOST_PACKAGES = '[PaymentInfo] - Update User and Load Boost Packages',
    SET_BOLETO_URL = '[PaymentInfo] - Set Boleto URL',
    RESET_BOLETO_URL = '[PaymentInfo] - Reset Boleto URL',
    LOAD_PAYMENT_PROVIDER = '[PaymentInfo] - Load Payment Provider',
}

export const fetchPaymentHistory = createAction(
    Actions.FETCH_PAYMENT_HISTORY_ACTION,
);

export const loadPaymentInfo = createAction(
    Actions.LOAD_PAYMENT_INFO_ACTION,
);

export const upsertPaymentInfo = createAction(
    Actions.UPSERT_PAYMENT_INFO_ACTION,
    props<{ paymentInfo: IPaymentInfo[] }>(),
);

export const checkPaymentUpdatedStatus = createAction(
    Actions.CHECK_PAYMENT_UPDATED_STATUS,
    props<{
        currentPaymentInfo: IPaymentInfo[];
        updatedPaymentInfo: IPaymentInfo[];
    }>(),
);

export const handleUpdatedResponse = createAction(
    Actions.HANDLE_UPDATED_RESPONSE,
    props<{ updatedPaymentInfo: IPaymentInfo; }>(),
);

export const setPaymentStatus = createAction(
    Actions.SET_PAYMENT_STATUS_ACTION,
    props<{ paymentStatus: PaymentStatus }>(),
);

export const handlePaymentErrorStatus = createAction(
    Actions.PAYMENT_HANDLE_ERROR,
    props<{ price: number }>(),
);

export const setIsPaying = createAction(
    Actions.PAYMENT_SET_IS_PAYING,
    props<{ isPaying: boolean }>(),
);

export const handlePaymentRedirect = createAction(
    Actions.PAYMENT_HANDLE_REDIRECT,
);

export const allCashPaymentFailRedirectToCheckout = createAction(
    Actions.ALLCASH_PAYMENT_FAIL_REDIRECT_TO_CHECKOUT,
);

export const allCashPaymentSuccessRedirectToHome = createAction(
    Actions.ALLCASH_PAYMENT_SUCCESS_REDIRECT_TO_HOME,
);

export const handlePaymentStatus = createAction(
    Actions.HANDLE_PAYMENT_STATUS,
    props<{ payment: IPaymentInfo; }>(),
);

export const handleAccessToCheckout = createAction(
    Actions.HANDLE_ACCESS_TO_CHECKOUT,
    props<IAccessToCheckoutPayload>(),
);

export const handleAccessToTrialCheckout = createAction(
    Actions.HANDLE_ACCESS_TO_TRIAL_CHECKOUT,
);

export const navigateAfterResolvingPlans = createAction(
    Actions.NAVIGATE_AFTER_RESOLVING_PLANS,
    props<IAccessToCheckoutPayload>(),
);

export const setHasResolvedPlans = createAction(
    Actions.SET_HAS_RESOLVED_PLANS,
    props<{ hasResolvedPlans: boolean }>(),
);

export const resetIsPayingAfterDelay = createAction(
    Actions.RESET_IS_PAYING_AFTER_DELAY,
);

export const navigateAfterPayment = createAction(
    Actions.NAVIGATE_AFTER_PAYMENT,
);

export const updateUserAndLoadBoostPackages = createAction(
    Actions.UPDATE_USER_AND_LOAD_BOOST_PACKAGES,
    props<{ latestPayment: IPaymentInfo; }>(),
);

export const setBoletoUrl = createAction(
    Actions.SET_BOLETO_URL,
    props<{ boletoUrl: string }>(),
);

export const resetBoletoUrl = createAction(
    Actions.RESET_BOLETO_URL,
);

export const loadPaymentProvider = createAction(
    Actions.LOAD_PAYMENT_PROVIDER,
);
