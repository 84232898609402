<div
    class="radio-check-gradient"
    [ngClass]="getGradientModifierClass()"
>
    <input
        type="radio"
        [name]="name"
        [id]="id"
        [value]="value"
        [checked]="isRadioChecked()"
    >
    <span></span>
</div>
