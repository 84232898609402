<button
    class="user-button user-button__boost"
    type="button"
    aria-haspopup="true"
    aria-expanded="true"
    (click)="navigateToBoost()"
>
    <img
        class="user-button--icon"
        src="{{ getBoostButtonIcon() }}"
    >
    <div class="user-button--button-content">
        <div class="title">
            {{ 'modules.main.shared.menu-user-buttons.boost-title' | translate }}
        </div>
        <div class="description">
            {{ 'modules.main.shared.menu-user-buttons.boost-description' | translate }}
        </div>
    </div>
    <div class="new-feature">{{ 'common.new' | translate }}</div>
</button>
