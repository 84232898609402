import {
    Action,
    createReducer,
    on,
} from '@ngrx/store';

import * as PendingMessageActions from '@libs/store/pending-messages/actions';
import { IPendingMessage } from '@libs/store/pending-messages/interface';
import {
    IPendingMessageState,
    PendingMessageAdapter,
    PendingMessageInitialState,
} from '@libs/store/pending-messages/state';

const messageReducer = createReducer(PendingMessageInitialState,
    on(
        PendingMessageActions.upsertPengingMessages, (
            state: IPendingMessageState, { pendingMessages }: { pendingMessages: IPendingMessage[] },
        ): IPendingMessageState => {
            return PendingMessageAdapter.upsertMany(pendingMessages, state);
        },
    ),
    on(
        PendingMessageActions.deletePendingMessage,
        (state: IPendingMessageState, { uuid }: { uuid: string }): IPendingMessageState => {
            return PendingMessageAdapter.removeMany(
                (pendingMessage: IPendingMessage): boolean =>
                    pendingMessage.uuid === uuid,
                state,
            );
        },
    ),
);

export function reducer(
    state: IPendingMessageState,
    action: Action,
): IPendingMessageState {
    return messageReducer(state, action);
}
