<div class="dial-box rules-modal">
    <button
        class="close-button"
        (click)="decline()"
    >
        <i
            class="material-icons"
            aria-hidden="true"
        >
            &#xE5CD;
        </i>
    </button>
    <h2 class="title">
        {{ 'modules.main.shared.rules_modal.title' | translate }}
    </h2>
    <p class="subtext">
        {{ getDescription() | translate:{ gender: getUserGender() } }}
    </p>
    <p>
        {{ 'modules.main.shared.rules_modal.warning' | translate }}
    </p>
    <dl>
        <dt class="custom-radio-check">
            <input
                id="agreeCondition1"
                name="agreeCondition1"
                type="checkbox"
                [(ngModel)]="agreeCondition1"
                required
            >
            <label for="agreeCondition1">
                {{ 'modules.main.shared.rules_modal.checkbox1' | translate }}
            </label>
            <span
                class="required"
                *ngIf="canShowRequiredSign(agreeCondition1)"
                aria-hidden="true"
            >
                {{ 'common.required' | translate }}
            </span>
        </dt>
        <dd (click)="toggle(MODEL_1)">
            {{ 'modules.main.shared.rules_modal.checkbox_description1' | translate }}
        </dd>
        <dt class="custom-radio-check">
            <input
                id="agreeCondition2"
                name="agreeCondition2"
                type="checkbox"
                [(ngModel)]="agreeCondition2"
                required
            >
            <label for="agreeCondition2">
                {{ 'modules.main.shared.rules_modal.checkbox2' | translate:{ isBaby: isBaby() } }}
            </label>
            <span
                class="required"
                *ngIf="canShowRequiredSign(agreeCondition2)"
                aria-hidden="true"
            >
                {{ 'common.required' | translate }}
            </span>
        </dt>
        <dd (click)="toggle(MODEL_2)">
            {{ 'modules.main.shared.rules_modal.checkbox_description2' | translate }}
        </dd>
        <dt
            class="custom-radio-check"
            *ngIf="!isBaby()"
        >
            <input
                id="agreeCondition3"
                name="agreeCondition3"
                type="checkbox"
                [(ngModel)]="agreeCondition3"
                required
            >
            <label for="agreeCondition3">
                {{ 'modules.main.shared.rules_modal.checkbox3' | translate }}
            </label>
            <span
                class="required"
                *ngIf="canShowRequiredSign(agreeCondition3)"
                aria-hidden="true"
            >
                {{ 'common.required' | translate }}
            </span>
        </dt>
        <dd (click)="toggle(MODEL_3)">
            {{ 'modules.main.shared.rules_modal.checkbox_description3' | translate }}
        </dd>
    </dl>
    <div class="buttons-container">
        <button
            class="mp-main-button bt-shadow bt-md"
            [disabled]="!canAgree()"
            (click)="agree()"
        >
            {{ 'modules.main.shared.rules_modal.i_agree' | translate }}
        </button>
    </div>
</div>
