import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Config } from '@prince/config';
import { INewPasswordRequestBody } from '@prince/modules/initial/pages/new-password/services/new-password/interfaces/new-password-request-body.interface';
import { INewPasswordResponse } from '@prince/modules/initial/pages/new-password/services/new-password/interfaces/new-password-response.interface';

@Injectable({
    providedIn: 'root',
})
export class NewPasswordService {
    constructor(
        protected httpClient: HttpClient,
    ) {
        //
    }

    public newPassword(
        password: string,
        passwordConfirmation: string,
        token: string,
    ): Observable<HttpResponse<INewPasswordResponse>> {
        const endpoint: string = `${ Config.serverIp }change-password-auth`;
        const data: INewPasswordRequestBody = {
            password: password,
            password_confirmation: passwordConfirmation,
        };
        const headers: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${ token }`,
        });

        return this.httpClient.post(
            endpoint,
            data,
            {
                headers,
                observe: 'response',
            },
        );
    }
}
