import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { STATES_STR } from '@prince/modules/main/shared/modal-view/modal-view.animation';
import { ModalViewComponent } from '@prince/modules/main/shared/modal-view/modal-view.component';

@Injectable()
export class ModalViewReference implements OnDestroy {
    public componentInstance: ModalViewComponent = new ModalViewComponent(
        undefined,
        undefined,
        undefined,
    );
    protected subscriptions: Subscription[] = [];

    constructor(
        protected overlayReference: OverlayRef,
    ) {
        this.listenBackdropClick();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe(),
        );
        this.subscriptions = [];
    }

    diposeModalView(): void {
        this.overlayReference.dispose();
        this.componentInstance = new ModalViewComponent(undefined, undefined, undefined);
    }

    close(): void {
        this.subscriptions.push(
            this.componentInstance.toggleAnimationStateChanged
                .pipe(
                    filter((event: AnimationEvent): boolean => {
                        return event.toState === STATES_STR.CLOSED;
                    }),
                )
                .subscribe((): void => {
                    this.diposeModalView();
                }),
        );
    }

    protected listenBackdropClick(): void {
        if (
            this.overlayReference === undefined ||
            this.overlayReference.backdropClick() === undefined
        ) {
            return;
        }

        this.subscriptions.push(
            this.overlayReference.backdropClick()
                .subscribe((): void => {
                    this.componentInstance.close();
                }),
        );
    }
}
